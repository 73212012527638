import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Card } from "@material-ui/core";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import ListLoading from "module/common/ListLoading";
import { Fragment, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { AuthWizardContext, DeleteContext } from "context/Context";
import { BiCommentEdit, BiCommentX } from "react-icons/bi";
import { Link } from "react-router-dom";
import CommentInputFeild from "./Component/CommentInputFeild";
import { apiCall } from "helpers/apiCalls";
import { commentFormKeys } from "helpers/formKeys";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import ImagePreview from "module/common/ImagePreview/ImagePreview";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import useAxisproPermission from "hooks/useAxisproPermission";

const File = ({ icon, title, action, actionIcon, className, link, color }) => {
  return (
    <div
      className={classNames(
        "border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs--1",
        className
      )}
    >
      <FontAwesomeIcon icon={icon} className="fs-1" style={{ color: color }} />
      <span className="ms-2 me-3">{title}</span>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip style={{ position: "fixed" }}>{action}</Tooltip>}
      >
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-300 ms-auto"
          download
        >
          <FontAwesomeIcon icon={actionIcon} />
        </a>
      </OverlayTrigger>
    </div>
  );
};
const Comments = ({ id, comment_type }) => {
  const [downloadLoading, setdownloadLoading] = useState(false);
  const [formData, setFormData] = useState(commentFormKeys);
  const [onComment, setOnComment] = useState(false);
  const [onEdit, setOnEdit] = useState(null);
  const { dispatch } = useContext(DeleteContext);
  const [attachments, setAttachments] = useState([]);
  const [commentsList, setCommentList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const commentKeysCount = Object.keys(commentsList).length - 1;
  const countDisplay = commentKeysCount > 0 ? `${commentKeysCount}+` : "";
  const [formError, setFormError] = useState({});
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const axisProPermission = useAxisproPermission();
  let storeCommentPermission = true;
  let updateCommentPermission = true;
  let deleteCommentPermission = true;
  switch (comment_type) {
    case "task":
      storeCommentPermission = axisProPermission("store-task-comment");
      updateCommentPermission = axisProPermission("update-task-comment");
      deleteCommentPermission = axisProPermission("delete-task-comment");
      break;
    case "project":
      storeCommentPermission = axisProPermission("store-project-comment");
      updateCommentPermission = axisProPermission("update-project-comment");
      deleteCommentPermission = axisProPermission("delete-project-comment");
      break;
    case "ticket":
      storeCommentPermission = axisProPermission("store-ticket-comment");
      updateCommentPermission = axisProPermission("update-ticket-comment");
      deleteCommentPermission = axisProPermission("delete-ticket-comment");
      break;
    default:
      break;
  }
  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/comments",
      params: {
        filters: [{ column: "subject_id", operator: "=", value: id }],
      },
    });
    setCommentList(data);
    setIsLoading(false);
  };
  const resetData = () => {
    getData();
    setFormData({
      ...formData,
      comment: "",
      files: [],
    });
    setAttachments([]);
    handleCommentDistroy();
  };

  const validateCommentData = () => {
    const comment = formData.comment;
    const commentSizeInBytes =
      new Blob([JSON.stringify(comment)]).size / 1024 ** 2;
    if (commentSizeInBytes > 1) {
      showToast(
        "The file size is excessive! The file will be skipped.",
        "error"
      );
      setFormData({ ...formData, comment: "" });
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidComment = validateCommentData();
    if (!isValidComment) {
      return;
    }
    setOnComment(true);
    setFormError({});
    formData.subject_type = comment_type;
    formData.subject_id = id;
    formData._method = onEdit ? "PUT" : "POST";
    axios({
      method: `POST`,
      url: `${onEdit ? "crm/comments/" + onEdit : "crm/comments"}`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: setNewFormData(formData),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          resetData();
          setOnComment(false);
        } else {
          setOnComment(false);
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        setOnComment(false);
        if (error.response.status === 413) {
          showToast(
            "The comment size is excessive. The comment cannot be posted."
          );
        } else if (error.response.status === 422) {
          const validation_error = error?.response?.data?.data?.errors;
          if ("files" in validation_error) {
            showToast(validation_error.files[0], "error");
          }
        } else {
          console.log(error);
        }
      });
  };

  const handleFieldChange = (e, action) => {
    let newOldData = [];
    if (action) {
      const editor = document.querySelector(".ql-editor");
      if (editor) {
        const images = editor.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "200px";
          image.style.height = "auto";
        });
      }
      setFormData({
        ...formData,
        [action.name]: action.value,
      });
    } else {
      if (e.target.name === "files") {
        let fileItem = e.target.files[0];
        if (fileItem) {
          newOldData.push(fileItem);
          let fileSizeInMB = (fileItem?.size / Math.pow(1024, 2)).toFixed(2);
          let fileExtension = fileItem?.name.split(".").pop();
          let allowedExtensions = [
            "jpg",
            "jpeg",
            "png",
            "gif",
            "pdf",
            "doc",
            "docx",
            "ppt",
            "pptx",
            "csv",
            "odt",
            "xls",
            "xlsx",
            "txt",
            "zip",
            "rar",
            "tar",
            "mp3",
            "mp4",
            "avi",
            "wmv",
            "mov",
            "mkv",
          ].includes(fileExtension);
          if (!allowedExtensions) {
            showToast("The file format is invalid", "error");
            return;
          } else if (fileSizeInMB > 2) {
            showToast(
              "The file size is excessive! The file will be skipped",
              "error"
            );
            return;
          } else {
            setFormData({
              ...formData,
              [e.target.name]: [...formData.files, ...newOldData],
            });
            setAttachments((oldArray) => [...oldArray, ...newOldData]);
          }
        }
      }
    }
  };

  const handleEditComment = (e, comment) => {
    setOnEdit(comment.id);
    setFormData({
      ...formData,
      subject_type: comment_type,
      subject_id: id,
      comment: comment.comment,
    });
  };

  const handleDetachAttachment = (name) => {
    setAttachments(
      attachments.filter((attachment) => attachment.name !== name)
    );
    setFormData({
      ...formData,
      files: formData.files.filter((attachment) => attachment.name !== name),
    });
  };

  const handleCommentDistroy = () => {
    setOnEdit(null);
    setFormData({
      ...formData,
      comment: "",
      files: [],
    });
  };

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/comments/" + id,
        title: "Delete Comment",
        message: "Are you sure you want to delete this comment?",
        onSuccess: () => {
          const dataWithoutDeletedItem = commentsList.filter(
            (item) => item.id !== id
          );
          setCommentList(() => [...dataWithoutDeletedItem]);
          handleCommentDistroy();
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };
  const handleBulkDownload = async (id, type) => {
    setdownloadLoading(true);
    const data = await apiCall({
      url: `documents/zip-download/${type}/${id}`,
    });
    const link = document.createElement("a");
    if (Object.keys(data).length > 0) {
      link.href = data.download_url;
      link.setAttribute("download", "");
      link.click();
    }
    setdownloadLoading(false);
  };

  const handleImageClick = (event) => {
    const clickedImageUrl = event.target.src;
    setPreviewImage(clickedImageUrl);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  return (
    <>
      <div className="my-3 position-relative text-center">
        <hr className="position-absolute top-50 border-300 w-100 my-0" />
        <span className="position-relative bg-white dark__bg-card-dark">
          <Button
            size="sm"
            variant="outline-secondary"
            className="rounded-pill border-300"
          >
            Comments {countDisplay}
          </Button>
        </span>
      </div>
      <Row>
        <>
          {storeCommentPermission && !onEdit && (
            <CommentInputFeild
              handleCommentDistroy={handleCommentDistroy}
              formError={formError}
              handleFieldChange={handleFieldChange}
              formData={formData}
              handleSubmit={handleSubmit}
              onComment={onComment}
              attachments={attachments}
              handleDetachAttachment={handleDetachAttachment}
            />
          )}
        </>

        {Object.keys(commentsList).length > 0 ? (
          <>
            {commentsList.map((comment, key) => (
              <div key={key} className="">
                {comment?.commented_by?.id === user?.id || user?.is_admin ? (
                  <div className="d-flex justify-content-end">
                    {updateCommentPermission && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">Edit Comment</Tooltip>
                        }
                      >
                        <Button
                          variant="transparent"
                          size="sm"
                          className="custom-transperent-common-button p-0"
                          onClick={(e) => {
                            handleEditComment(e, comment);
                          }}
                        >
                          <BiCommentEdit size={18} />
                        </Button>
                      </OverlayTrigger>
                    )}
                    {deleteCommentPermission && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip id="button-tooltip-2">
                            Delete Comment
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="transparent"
                          size="sm"
                          className="custom-transperent-common-button p-0 ms-1"
                          onClick={() => {
                            handleDelete(comment.id);
                          }}
                        >
                          <BiCommentX size={18} />
                        </Button>
                      </OverlayTrigger>
                    )}
                  </div>
                ) : (
                  ""
                )}
                <div className="d-flex align-items-center justify-content-between ms-2 mb-3">
                  <Flex alignItems="center" className="gap-2">
                    <Link to="/support-desk/contact-details">
                      <Avatar src={comment?.commented_by?.image} size="2xl" />
                    </Link>
                    <div className="mb-0">
                      <Link className="fw-semi-bold mb-0 text-800">
                        {comment?.commented_by?.name}
                      </Link>

                      <Link className="mb-0 fs--1 d-block text-500">
                        {comment?.commented_by?.email}
                      </Link>
                    </div>
                  </Flex>
                  <div className="mb-0 fs--2 fs-sm--1 fw-semi-bold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
                    {comment?.commented_date}
                    <span className="mx-1">|</span>
                    <span className="fst-italic">
                      {comment?.commented_time}
                    </span>
                    <FontAwesomeIcon
                      icon="star"
                      className="ms-2 text-warning"
                    />
                  </div>
                </div>
                <div
                  className={`mb-3 pb-3 ms-2 d-flex flex-column ${
                    key !== Object.keys(commentsList).length - 1 &&
                    "border-bottom"
                  } `}
                >
                  {onEdit === comment?.id ? (
                    <div className="mt-2">
                      <CommentInputFeild
                        title="Reply"
                        bodyClassName="shadow-none"
                        handleFieldChange={handleFieldChange}
                        formData={formData}
                        handleSubmit={handleSubmit}
                        onComment={onComment}
                        attachments={attachments}
                        handleDetachAttachment={handleDetachAttachment}
                        handleCommentDistroy={handleCommentDistroy}
                        handleFiles={handleFieldChange}
                        formError={formError}
                        onEdit={onEdit}
                      />
                    </div>
                  ) : (
                    <>
                      <ShowHtmlContent
                        description={comment?.comment}
                        handleImageClick={handleImageClick}
                      />
                      {showPreview && previewImage && (
                        <ImagePreview
                          previewImage={previewImage}
                          handleClosePreview={handleClosePreview}
                        />
                      )}
                      {Object.keys(comment?.media)?.length > 0 && (
                        <Card className="p-x1 bg-light rounded-0 border">
                          <div className="d-inline-flex flex-column mt-3 ms-3">
                            {comment?.media?.map((media, key) => (
                              <Fragment key={key}>
                                <File
                                  title={`${media?.file_name} (${media?.human_readable_size})`}
                                  icon={
                                    media?.extension === "xlsx"
                                      ? "file-excel"
                                      : media?.extension === "pdf"
                                      ? "file-pdf"
                                      : media?.extension === "doc"
                                      ? "file-word"
                                      : media?.extension === "txt"
                                      ? "file-lines"
                                      : media?.extension === "csv"
                                      ? "file-csv"
                                      : media?.extension === "png" ||
                                        media?.extension === "jpeg"
                                      ? "file-image"
                                      : "file-archive"
                                  }
                                  color={
                                    media?.extension === "xlsx"
                                      ? "#007f3eb5"
                                      : media?.extension === "pdf"
                                      ? "#fb3939b8"
                                      : media?.extension === "doc"
                                      ? "#285293"
                                      : media?.extension === "txt"
                                      ? "#285293"
                                      : media?.extension === "csv"
                                      ? "#41a754cc"
                                      : media?.extension === "png" ||
                                        media?.extension === "jpeg"
                                      ? "#4f88e2"
                                      : "#4f88e2"
                                  }
                                  action="Download"
                                  actionIcon="arrow-down"
                                  className="mb-2"
                                  link={media?.file_url}
                                />
                              </Fragment>
                            ))}
                          </div>
                          <hr className="my-x1" />
                          <Row className="d-flex align-items-center justify-content-between">
                            <Col xs="auto">
                              <p className="fs--1 text-1000 mb-sm-0 font-sans-serif fw-medium mb-0 pb-3 ps-3">
                                <FontAwesomeIcon icon="link" className="me-2" />
                                {Object.keys(comment?.media).length} files
                                attached
                              </p>
                            </Col>
                            <Col xs="auto">
                              {downloadLoading ? (
                                <IconButton variant="falcon-default" size="sm">
                                  <Spinner size="sm" />
                                </IconButton>
                              ) : (
                                <>
                                  {Object.keys(comment?.media)?.length > 1 && (
                                    <IconButton
                                      icon="arrow-down"
                                      variant="falcon-default"
                                      size="sm"
                                      className=" mb-3 me-3 ms-3"
                                      onClick={() => {
                                        handleBulkDownload(
                                          comment.id,
                                          "COMMENT"
                                        );
                                      }}
                                    >
                                      <span className="ms-1">Download all</span>
                                    </IconButton>
                                  )}
                                </>
                              )}
                            </Col>
                          </Row>
                        </Card>
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}
          </>
        ) : isLoading ? (
          <ListLoading style={{ maxHeight: "55vh", overflow: "hidden" }} />
        ) : (
          <div className="d-flex justify-content-center">
            <span>No Comments Yet 😉</span>
          </div>
        )}
      </Row>
    </>
  );
};

export default Comments;
