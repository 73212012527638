import React, { useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const SelectCampaignStatus = ({
  handleFieldChange,
  name,
  error,
  value,
  defaultValue,
  style,
  placeholder,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setItems = async (inputValue) => {
    setLoading(true);
    try {
      const response = await axios.get("crm/campaign-status", {
        params: { name: inputValue },
      });
      const data = response.data?.data || [];
      return data.map((item) => ({ value: item, label: item }));
    } catch (error) {
      console.error("Error fetching campaign status:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async ({ inputValue }) => {
    const defaultOptionsData = await setItems(inputValue);
    setOptions(defaultOptionsData);
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setItems}
      isSearchable={false}
      defaultOptions={options}
      onFocus={handleFocus}
      isLoading={loading}
      isClearable
      name={name ? name : "status"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`custom-select ${
        error
          ? "form-control ps-0 py-0 is-invalid text-capitalize"
          : "text-capitalize"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

SelectCampaignStatus.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCampaignStatus;
