import SelectProject from "components/form/SelectProject";
import SelectTaskPriority from "components/form/SelectTaskPriority";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectUsers from "components/form/SelectUsers";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const taskFilters = {
  seq: {
    label: "Task No",
    operators: commonFilterOperators,
    active: true,
  },
  title: {
    label: "Title",
    operators: commonFilterOperators,
    active: true,
  },
  project_id: {
    label: "Project",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectProject
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  start_date: {
    label: "Start Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  end_date: {
    label: "End Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  assigned_to: {
    label: "Assigned",
    operators: [
      { name: "Equal", value: "=" },
      { name: "Not Equal", value: "!=" },
    ],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectUsers
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  status: {
    label: "Status",
    operators: [
      { name: "Equal", value: "=" },
      { name: "Not Equal", value: "!=" },
    ],
    Element: (item) =>
      item && (item.operator === "=" || item.operator === "!=") ? (
        <div className="w-100">
          <SelectTaskStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  priority: {
    label: "Priority",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectTaskPriority
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  created_by: {
    label: "Created By",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectUsers
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

export { taskFilters };
