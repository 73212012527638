import { React, useState } from "react";
import axios from "axios";
import { components } from "react-select";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import DropdownAddButton from "../Buttons/DropdownAddButton";
import AddCostCenter from "../Forms/AddCostCenter";

function SelectCostCenter({
  handleFieldChange,
  name,
  error,
  value,
  setFormData,
}) {
  const Translate = useAxisproTranslate();
  const [addCostCenter, setAddCostCenter] = useState({
    show: false,
    name: "",
  });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState("");
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setItems = async (name) => {
    setLoading(true);
    try {
      let response = await axios.get("finance/cost-centers/autocomplete", {
        params: { name: name },
      });
      let data =
        response && response.data && response.data.data
          ? response.data.data
          : [];
      if (data.length === 0) {
        setData(name);
        setOpen(true);
      } else {
        setOpen(false);
      }
      return data.map((item) => ({
        label: item.name,
        value: item.id,
      }));
    } finally {
      setLoading(false);
    }
  };

  const { MenuList, Option } = components;

  const handleOpen = () => {
    setAddCostCenter({
      show: true,
      name: data,
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && open) {
      e.preventDefault();
      setAddCostCenter({
        show: true,
        name: data,
      });
    }
  };

  const CustomMenuList = (props) => (
    <MenuList {...props}>
      <DropdownAddButton
        title={Translate("Add Cost Center")}
        show={handleOpen}
      />
      {loading ? (
        <div className="w-100 d-flex align-items-center justify-content-center p-3">
          Loading...
        </div>
      ) : (
        props.children
      )}
    </MenuList>
  );

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems("");
      setDefaultOptions(defaultOptionsData);
    }
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isClearable
        isSelected={true}
        isOptionSelected={(item) => item.value === value?.value}
        name={name ? name : "cost center"}
        value={value}
        placeholder=""
        onChange={handleFieldChange}
        onKeyDown={(e) => handleKeyPress(e)}
        components={{
          MenuList: CustomMenuList,
          Option,
        }}
        // components={
        //   getAcodaxPermissionSlug('CC', 'create')
        //     ? { MenuList: CustomMenuList, Option }
        //     : { MenuList: Option }
        // }
        className={error && "form-control ps-0 py-0 is-invalid"}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            fontFamily: "sans-serif",
            fontSize: "12px",
            borderRadius: "0px",
            textTransform: "capitalize",
            overflow: "hidden",
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: "0px",
            border: "1px solid #b5b5b5",
            height: "10px",
          }),
        }}
        menuPortalTarget={document.body}
      />
      <AddCostCenter
        show={addCostCenter.show}
        onHide={() =>
          setAddCostCenter({
            show: false,
            name: "",
          })
        }
        setFormData={setFormData}
        data={addCostCenter.name}
      />
    </>
  );
}

SelectCostCenter.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  children: PropTypes.any,
  setFormData: PropTypes.func,
};

export default SelectCostCenter;
