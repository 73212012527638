import React from "react";
import "./ImagePreview.scss";

const ImagePreview = ({ previewImage, handleClosePreview }) => {
  return (
    <>
      <div className="preview-overlay" onClick={handleClosePreview}>
        <img src={previewImage} alt="Preview" className="preview-image" />
      </div>
    </>
  );
};

export default ImagePreview;
