import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonClientFilterFormKeys } from "helpers/formKeys";
import SelectCustomer from "components/form/SelectCustomer";
import SelectCurrency from "components/form/SelectCurrency";

const CommonClientFilter = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(commonClientFilterFormKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const activeView = queryParams.get("view");
  const {
    customer_id,
    customer_id_ref,
    customer_code,
    customer_type,
    currency_code,
    currency_code_ref,
    status,
  } = allQueryParams;

  const handleFieldChange = (e, action) => {
    if (!action) {
      setFilterFields({ ...filterFields, [e.target.name]: e.target.value });
    } else {
      let newFormData;
      if (action.action === "select-option") {
        newFormData = {
          ...filterFields,
          [action.name]: e.value,
          [action.name + "_ref"]: e,
        };
      } else if (action.action === "clear") {
        newFormData = {
          ...filterFields,
          [action.name]: "",
          [action.name + "_ref"]: {
            label: "",
            value: "",
          },
        };
      } else if (action.action === "remove-value") {
        newFormData = {
          ...filterFields,
          [action.name]: e.map((item) => item.value),
          [action.name + "_ref"]: e,
        };
      }
      setFilterFields(newFormData);
    }
  };

  const handleFilterApply = () => {
    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          customer_id_ref:
            filterFields?.customer_id &&
            JSON.stringify(filterFields?.customer_id_ref),
          currency_code_ref:
            filterFields?.currency_code &&
            JSON.stringify(filterFields?.currency_code_ref),
          view: activeView && activeView,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      customer_id: customer_id ? customer_id : "",
      customer_id_ref: customer_id_ref ? JSON.parse(customer_id_ref) : "",
      customer_code: customer_code ? customer_code : "",
      customer_type: customer_type ? customer_type : "",
      currency_code: currency_code ? currency_code : "",
      currency_code_ref: currency_code_ref
        ? JSON.parse(customer_id_ref)
        : { label: "", value: "" },
      status: status ? status : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilter = () => {
    setFilterFields(commonClientFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Account</Form.Label>
              <Col>
                <SelectCustomer
                  name="customer_id"
                  value={filterFields.customer_id_ref}
                  handleFieldChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Account ID</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="customer_code"
                  onChange={handleFieldChange}
                  value={filterFields.customer_code}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Type</Form.Label>
              <Col>
                <Form.Select
                  name="customer_type"
                  onChange={handleFieldChange}
                  value={filterFields.customer_type}
                >
                  <option value="">All</option>
                  <option value="COMPANY">COMPANY</option>
                  <option value="INDIVIDUAL">INDIVIDUAL</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Currency</Form.Label>
              <Col>
                <SelectCurrency
                  name="currency_code"
                  value={filterFields.currency_code_ref}
                  handleFieldChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Status</Form.Label>
              <Col>
                <Form.Select
                  name="status"
                  onChange={handleFieldChange}
                  value={filterFields.status}
                >
                  <option value="">All</option>
                  <option value={0}>Active</option>
                  <option value={1}>Inactive</option>
                </Form.Select>
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2} className="d-flex justify-content-end">
          <Row>
            <Form.Group
              as={Col}
              md={2}
              className="d-flex justify-content-end w-100 p-0 pe-3"
            >
              <div className="d-flex align-items-end gap-2">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={handleFilterApply}
                  title="Apply Added Filters"
                >
                  Apply
                </Button>
                <Button
                  size="sm"
                  variant="transparent"
                  className="shadow-sm"
                  onClick={handleResetFilter}
                  title="Clear All Filters"
                >
                  <FcClearFilters size={18} />
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CommonClientFilter;
