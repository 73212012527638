import { React, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaCalendar } from "react-icons/fa";
import { FcFilledFilter } from "react-icons/fc";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { useSearchParams } from "react-router-dom";

function DateRangeFilter({
  filters,
  setFilters,
  label = "Date Range",
  labelClassName = "",
}) {
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");
  const [range, setRange] = useState({
    dateRange: "",
    specific_month: "",
  });

  useEffect(() => {
    if (filters?.date_range) {
      setRange((prev) => ({
        ...prev,
        dateRange: filters.date_range,
      }));
    } else {
      setRange((prev) => ({
        ...prev,
        dateRange: "",
      }));
    }
    if (filters?.month) {
      setRange((prev) => ({
        ...prev,
        specific_month: filters.month.toLowerCase(),
      }));
    } else {
      setRange((prev) => ({
        ...prev,
        specific_month: "january",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFormattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const calculateTodayRange = () => {
    const today = new Date();
    const formattedDate = getFormattedDate(today);
    return {
      ...filters,
      from_date: formattedDate,
      to_date: formattedDate,
      date_range: "today",
    };
  };

  const calculateThisWeekRange = () => {
    const today = new Date();
    const startDate = new Date(today);
    startDate.setDate(today.getDate() - today.getDay());
    const lastDate = new Date(today);
    lastDate.setDate(today.getDate() + (6 - today.getDay()));
    const formattedStartOfWeek = getFormattedDate(startDate);
    const formattedEndOfWeek = getFormattedDate(lastDate);
    return {
      ...filters,
      from_date: formattedStartOfWeek,
      to_date: formattedEndOfWeek,
      date_range: "this_week",
    };
  };

  const calculateThisMonthRange = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const lastDate = new Date(year, month + 1, 0).getDate();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month, lastDate);
    const formattedStartOfMonth = getFormattedDate(startDate);
    const formattedEndOfMonth = getFormattedDate(endDate);
    return {
      ...filters,
      from_date: formattedStartOfMonth,
      to_date: formattedEndOfMonth,
      date_range: "this_month",
    };
  };

  const calculateThisQuarterRange = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let quarterStartMonth;
    if (currentMonth >= 0 && currentMonth <= 2) {
      quarterStartMonth = 0;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      quarterStartMonth = 3;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      quarterStartMonth = 6;
    } else {
      quarterStartMonth = 9;
    }
    const startDate = new Date(today.getFullYear(), quarterStartMonth, 1);
    const formattedStartOfQuarter = getFormattedDate(startDate);
    const lastDate = new Date(today.getFullYear(), quarterStartMonth + 3, 0);
    const formattedEndOfQuarter = getFormattedDate(lastDate);
    return {
      ...filters,
      from_date: formattedStartOfQuarter,
      to_date: formattedEndOfQuarter,
      date_range: "this_quarter",
    };
  };

  const calculateThisHalfRange = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let halfStartMonth;
    if (currentMonth >= 0 && currentMonth <= 5) {
      halfStartMonth = 0;
    } else if (currentMonth >= 6 && currentMonth <= 12) {
      halfStartMonth = 6;
    }
    const startDate = new Date(today.getFullYear(), halfStartMonth, 1);
    const formattedStartOfHalf = getFormattedDate(startDate);
    const lastDate = new Date(today.getFullYear(), halfStartMonth + 6, 0);
    const formattedEndOfHalf = getFormattedDate(lastDate);
    return {
      ...filters,
      from_date: formattedStartOfHalf,
      to_date: formattedEndOfHalf,
      date_range: "this_half",
    };
  };

  const calculateThisYearRange = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const startDate = new Date(currentYear, 0, 1);
    const lastDate = new Date(currentYear, 11, 31);
    const formattedStartOfYear = getFormattedDate(startDate);
    const formattedEndOfYear = getFormattedDate(lastDate);
    return {
      ...filters,
      from_date: formattedStartOfYear,
      to_date: formattedEndOfYear,
      date_range: "this_year",
    };
  };

  const calculateYesterdayRange = () => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const formattedDate = getFormattedDate(yesterday);
    return {
      ...filters,
      from_date: formattedDate,
      to_date: formattedDate,
      date_range: "yesterday",
    };
  };

  const calculatePrevWeekRange = () => {
    const today = new Date();
    const currentDayOfWeek = today.getDay();
    const startOfWeek = new Date(today);
    const endOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - currentDayOfWeek - 7);
    endOfWeek.setDate(today.getDate() - currentDayOfWeek - 1);
    const formattedStartOfWeek = getFormattedDate(startOfWeek);
    const formattedEndOfWeek = getFormattedDate(endOfWeek);
    return {
      ...filters,
      from_date: formattedStartOfWeek,
      to_date: formattedEndOfWeek,
      date_range: "prev_week",
    };
  };

  const calculatePrevMonthRange = () => {
    const today = new Date();
    const firstDayOfCurrentMonth = new Date(
      today.getFullYear(),
      today.getMonth(),
      1
    );
    const prevMonthEndDate = new Date(firstDayOfCurrentMonth);
    prevMonthEndDate.setDate(0);
    const prevMonthStartDate = new Date(prevMonthEndDate);
    prevMonthStartDate.setDate(1);
    const formattedPrevMonthStartDate = getFormattedDate(prevMonthStartDate);
    const formattedPrevMonthEndDate = getFormattedDate(prevMonthEndDate);
    return {
      ...filters,
      from_date: formattedPrevMonthStartDate,
      to_date: formattedPrevMonthEndDate,
      date_range: "prev_month",
    };
  };

  const calculatePrevQuarterRange = () => {
    let today = new Date();
    let currentMonth = today.getMonth();
    let currentYear = today.getFullYear();
    let prevQuarterStartMonth;
    if (currentMonth >= 0 && currentMonth <= 2) {
      prevQuarterStartMonth = 9;
      currentYear--;
    } else if (currentMonth >= 3 && currentMonth <= 5) {
      prevQuarterStartMonth = 0;
    } else if (currentMonth >= 6 && currentMonth <= 8) {
      prevQuarterStartMonth = 3;
    } else {
      prevQuarterStartMonth = 6;
    }
    const prevQuarterStartDate = new Date(
      currentYear,
      prevQuarterStartMonth,
      1
    );
    const prevQuarterEndDate = new Date(currentYear, currentMonth, 0);
    const formattedPrevQuarterStartDate =
      getFormattedDate(prevQuarterStartDate);
    const formattedPrevQuarterEndDate = getFormattedDate(prevQuarterEndDate);
    return {
      ...filters,
      from_date: formattedPrevQuarterStartDate,
      to_date: formattedPrevQuarterEndDate,
      date_range: "prev_quarter",
    };
  };

  const calculatePrevHalfRange = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    let currentYear = today.getFullYear();
    let prevHalfStartMonth;
    let prevHalfEndMonth;
    if (currentMonth >= 0 && currentMonth <= 5) {
      prevHalfStartMonth = 6;
      prevHalfEndMonth = 11;
      currentYear--;
    } else {
      prevHalfStartMonth = 0;
      prevHalfEndMonth = 5;
    }
    const prevHalfStartDate = new Date(currentYear, prevHalfStartMonth, 1);
    const prevHalfEndDate = new Date(currentYear, prevHalfEndMonth + 1, 0);
    const formattedPrevHalfStartDate = getFormattedDate(prevHalfStartDate);
    const formattedPrevHalfEndDate = getFormattedDate(prevHalfEndDate);
    return {
      ...filters,
      from_date: formattedPrevHalfStartDate,
      to_date: formattedPrevHalfEndDate,
      date_range: "prev_half",
    };
  };

  const calculatePrevYearRange = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const prevYearStartDate = new Date(currentYear - 1, 0, 1);
    const prevYearEndDate = new Date(currentYear - 1, 11, 31);
    const formattedPrevYearStartDate = getFormattedDate(prevYearStartDate);
    const formattedPrevYearEndDate = getFormattedDate(prevYearEndDate);
    return {
      ...filters,
      from_date: formattedPrevYearStartDate,
      to_date: formattedPrevYearEndDate,
      date_range: "prev_year",
    };
  };
  const calculateTillDate = () => {
    const today = new Date();
    const formattedEndOfYear = getFormattedDate(today);
    return {
      ...filters,
      from_date: "2000-01-01",
      to_date: formattedEndOfYear,
      date_range: "till_date",
    };
  };

  const handleDateChange = (e) => {
    setRange({
      dateRange: e.target.value,
    });
    if (e.target.value === "today") {
      setFilters(calculateTodayRange());
    } else if (e.target.value === "this_week") {
      setFilters(calculateThisWeekRange());
    } else if (e.target.value === "this_month") {
      setFilters(calculateThisMonthRange());
    } else if (e.target.value === "this_quarter") {
      setFilters(calculateThisQuarterRange());
    } else if (e.target.value === "this_half") {
      setFilters(calculateThisHalfRange());
    } else if (e.target.value === "this_year") {
      setFilters(calculateThisYearRange());
    } else if (e.target.value === "yesterday") {
      setFilters(calculateYesterdayRange());
    } else if (e.target.value === "prev_week") {
      setFilters(calculatePrevWeekRange());
    } else if (e.target.value === "prev_month") {
      setFilters(calculatePrevMonthRange());
    } else if (e.target.value === "prev_quarter") {
      setFilters(calculatePrevQuarterRange());
    } else if (e.target.value === "prev_half") {
      setFilters(calculatePrevHalfRange());
    } else if (e.target.value === "prev_year") {
      setFilters(calculatePrevYearRange());
    } else if (e.target.value === "till_today") {
      setFilters(calculateTillDate());
    } else {
      setFilters({
        ...filters,
        date_range: "",
        from_date: "",
        to_date: "",
      });
    }
  };

  const handleSpecificMonth = (e) => {
    setRange((prev) => ({
      ...prev,
      specific_month: e.target.value,
    }));
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const today = new Date();
    const currentYear = today.getFullYear();
    const monthIndex = monthNames.findIndex(
      (name) => name.toLowerCase() === e.target.value.toLowerCase()
    );
    if (monthIndex === -1) {
      return null;
    }
    const startDate = new Date(currentYear, monthIndex, 1);
    const endDate = new Date(currentYear, monthIndex + 1, 0);
    const formattedStartDate = getFormattedDate(startDate);
    const formattedEndDate = getFormattedDate(endDate);
    setFilters((prev) => ({
      ...prev,
      from_date: formattedStartDate,
      to_date: formattedEndDate,
      date_range: "specific_month",
      month: e.target.value.toLowerCase(),
    }));
  };

  const handleFieldChange = (e, action) =>
    setFilters(generateFormDataHelper(e, action, filters));

  useEffect(() => {
    if (range.dateRange === "till_today") {
      const today = new Date();
      const formattedEndOfYear = getFormattedDate(today);

      setFilters((prev) => ({
        ...prev,
        from_date: "2000-01-01",
        to_date: formattedEndOfYear,
        date_range: "till_today",
      }));
    } else if (range.dateRange === "specific_month") {
      const today = new Date();
      const currentYear = today.getFullYear();
      const januaryStart = new Date(currentYear, 0, 1);
      const januaryEnd = new Date(currentYear, 0, 31);
      const formattedStartDate = getFormattedDate(januaryStart);
      const formattedEndDate = getFormattedDate(januaryEnd);
      setFilters((prev) => ({
        ...prev,
        from_date: formattedStartDate,
        to_date: formattedEndDate,
        date_range: "specific_month",
        month: "january",
      }));
    } else if (range.dateRange === "custom_date") {
      setFilters((prev) => ({
        ...prev,
        date_range: "custom_date",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.dateRange]);

  useEffect(() => {
    const jsonObject = JSON.parse(filterItems);
    if (jsonObject) {
      if (!filters.dateRange) {
        const today = new Date();
        const currentYear = today.getFullYear();
        const startDate = new Date(currentYear, 0, 2);
        const formattedStartOfYear = getFormattedDate(startDate);
        const formattedEndOfYear = getFormattedDate(today);
        setFilters((prev) => ({
          ...prev,
          from_date: jsonObject?.from_date ?? formattedStartOfYear,
          to_date: jsonObject?.to_date ?? formattedEndOfYear,
          date_range: jsonObject?.date_range ?? "till_today",
        }));
      }
      setRange({
        ...range,
        dateRange: jsonObject?.date_range ?? "till_today",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  return (
    <>
      <Form.Group>
        <Form.Label className={labelClassName}>{label}</Form.Label>
        <div className="d-flex flex-row">
          <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
            <FcFilledFilter size={10} className="text-white" />
          </div>
          <Form.Select
            onChange={handleDateChange}
            value={filters.date_range ? filters.date_range : ""}
          >
            <option value="">Select Date Range</option>
            <option value={"till_today"}>Till Today</option>
            <option value={"today"}>Today</option>
            <option value={"this_week"}>This Week</option>
            <option value={"this_month"}>This Month</option>
            <option value={"this_quarter"}>This Quarter</option>
            <option value={"this_half"}>This Half</option>
            <option value={"this_year"}>This Year</option>
            <option value={"yesterday"}>Yesterday</option>
            <option value={"prev_week"}>Previous Week</option>
            <option value={"prev_month"}>Previous Month</option>
            <option value={"prev_quarter"}>Previous Quarter</option>
            <option value={"prev_half"}>Previous Half</option>
            <option value={"prev_year"}>Previous Year</option>
            <option value={"specific_month"}>Specific Month</option>
            <option value={"custom_date"}>Custom Date</option>
          </Form.Select>
        </div>
      </Form.Group>
      {filters.date_range === "specific_month" ? (
        <Form.Group className="mt-3">
          <Form.Label className={labelClassName}>Select Month</Form.Label>
          <div className="d-flex flex-row">
            <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
              <FaCalendar size={10} className="text-white" />
            </div>
            <Form.Select
              onChange={handleSpecificMonth}
              value={range.specific_month}
            >
              <option value={"january"}>January</option>
              <option value={"february"}>February</option>
              <option value={"march"}>March</option>
              <option value={"april"}>April</option>
              <option value={"may"}>May</option>
              <option value={"june"}>June</option>
              <option value={"july"}>July</option>
              <option value={"august"}>August</option>
              <option value={"september"}>September</option>
              <option value={"october"}>October</option>
              <option value={"november"}>November</option>
              <option value={"december"}>December</option>
            </Form.Select>
          </div>
        </Form.Group>
      ) : (
        ""
      )}
      {filters.date_range === "custom_date" ? (
        <>
          <Form.Group className="mb-2 mt-2">
            <Form.Label className={labelClassName}>Date From</Form.Label>
            <div className="d-flex flex-row">
              <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
                <FaCalendar size={10} className="text-white" />
              </div>
              <AppDatePicker
                name={"from_date"}
                value={filters.from_date}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className={labelClassName}>Date To</Form.Label>
            <div className="d-flex flex-row">
              <div className="bg-secondary py-1 px-2 d-flex justify-content-center align-items-center">
                <FaCalendar size={10} className="text-white" />
              </div>
              <AppDatePicker
                name={"to_date"}
                value={filters.to_date}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
              />
            </div>
          </Form.Group>
        </>
      ) : (
        ""
      )}
    </>
  );
}

DateRangeFilter.propTypes = {
  setFilters: PropTypes.func,
  formData: PropTypes.any,
};

export default DateRangeFilter;
