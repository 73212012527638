import { React } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ActivityLoading from "./ActivityLoading";
import ActivityFetching from "module/common/Loading/ActivityFetching";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";

const CustomerDetailsTab = ({
  data,
  activity,
  activityLoading,
  setActivity,
  links,
  setLinks,
}) => {
  const loadNextPage = async (next_page_url) => {
    await axios
      .get(next_page_url)
      .then((res) => {
        setActivity([...activity, ...res.data.data.data]);
        setLinks(res.data.data);
      })
      .catch((error) => {
        console.log(error);
        showToast("Something went wrong please contact admin", "error");
      });
  };
  return (
    <Tabs
      defaultActiveKey={3}
      className="border-top mt-3"
      style={{ margin: "0 -1rem" }}
    >
      {data.address ? (
        <Tab eventKey={1} title="ADDRESS">
          <ul className="pt-4 px-0 m-0 customer-info-tab-content">
            <li className="d-flex flex-row">
              <FontAwesomeIcon
                icon="address-card"
                size="sm"
                className="me-2 mt-1"
                color="#00d27a"
              />
              <div>
                <h5>BILLING ADDRESS</h5>
                <p className="text-muted mt-2">{data.address}</p>
              </div>
            </li>
            <li className="d-flex flex-row">
              <FontAwesomeIcon
                icon="shipping-fast"
                size="sm"
                className="me-2 mt-1"
                color="#f5803e"
              />
              <div>
                <h5>BILLING ADDRESS</h5>
                <p className="text-muted mt-2">{data.address}</p>
              </div>
            </li>
          </ul>
        </Tab>
      ) : (
        ""
      )}
      {data && data.contacts && data.contacts.length > 0 ? (
        <Tab eventKey={2} title="CONTACT PERSONS">
          <ul className="pt-4 px-0 m-0 customer-info-tab-content">
            {data &&
              data.contacts &&
              data.contacts.map((item, key) => (
                <li className="d-flex flex-row" key={key}>
                  <FontAwesomeIcon
                    icon="user-circle"
                    size="sm"
                    className="me-2 mt-1"
                    color="#00d27a"
                  />
                  <div>
                    <h5>{item.contact_name}</h5>
                    <p className="text-muted mt-2">
                      <a href="mailto:mr.bipinks@gmail.com">
                        {item.contact_email}
                      </a>
                      <br />
                      <a href="tel:+0544706704">{item.contact_mobile}</a>
                      <br />
                      <small>{item.contact_address}</small>
                    </p>
                  </div>
                </li>
              ))}
          </ul>
        </Tab>
      ) : (
        ""
      )}
      {activity && activity.length > 0 ? (
        <Tab eventKey={3} title="ACTIVITY">
          {!activityLoading ? (
            <>
              <ul className="pt-4 px-0 m-0 customer-info-tab-content activity-tree">
                {activity &&
                  activity.map((item, key) => (
                    <li className="d-flex flex-row" key={key}>
                      <div className="icon-cover d-flex justify-content-center align-items-center shadow border">
                        <FontAwesomeIcon
                          icon="comment-dots"
                          size="sm"
                          color="#344050"
                        />
                      </div>
                      <Card className="flex-fill">
                        <Card.Body>
                          <p className="text-muted small mb-2">
                            {item.created_at}
                          </p>
                          <div className="d-flex gap-2">
                            <h6 className="m-0">{item.description}</h6>
                            <h6 className="m-0">-</h6>
                            <h6 className="m-0">{item.event}</h6>
                          </div>
                          <p className="text-muted mt-3 mb-0 d-flex gap-2">
                            {item.reference ? item.reference : ""} {item.event}
                            <b> - By {item.causer_name}</b>
                          </p>
                        </Card.Body>
                      </Card>
                    </li>
                  ))}
              </ul>
              {links.next_page_url ? (
                <ActivityFetching links={links} loadNextPage={loadNextPage} />
              ) : (
                ""
              )}
            </>
          ) : (
            <ActivityLoading />
          )}
        </Tab>
      ) : (
        ""
      )}
    </Tabs>
  );
};

CustomerDetailsTab.propTypes = {
  data: PropTypes.object,
  activity: PropTypes.any,
  activityLoading: PropTypes.bool,
  setActivity: PropTypes.func,
  links: PropTypes.any,
  setLinks: PropTypes.func,
};

export default CustomerDetailsTab;
