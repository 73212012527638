import { React, useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Accordion, Badge, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import { leadsFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import SelectLeadSource from "components/form/SelectLeadSource";
import removeRefData from "helpers/removeRefData";
import SelectUsers from "components/form/SelectUsers";
import ListLoading from "module/common/ListLoading";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import { SiGoogleadmob } from "react-icons/si";
import SelectIndustry from "components/form/SelectIndustry";
import { AuthWizardContext } from "context/Context";
import CreatableSelectCompany from "components/form/CreatableSelectCompany";
import SelectLeadPriority from "components/form/SelectLeadPriority";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import SelectPersonType from "components/form/SelectPersonType";
import SocialMedia from "module/common/ViewPages/Common/SocialMedia/SocialMedia";
import SelectCampaign from "components/form/SelectCampaign";
import MultiSelectCheckBoxProduct from "components/form/MultiSelectCheckBoxProduct";
import SelectBusinessCurrentStatus from "components/form/SelectBusinessCurrentStatus";

function LeadForm({ getData }) {
  const { user } = useContext(AuthWizardContext);
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [formData, setFormData] = useState({
    ...leadsFormKeys,
    lead_owner: user?.id,
    lead_owner_ref: { label: user?.name, value: user?.id },
  });
  const [formError, setFormError] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [selectedType, setSelectedType] = useState("company");
  const mode = queryParams.get("mode");
  const leadId = queryParams.get("id");
  const [activeKey, setActiveKey] = useState("0");
  const [infoErrorCount, setInfoErrorCount] = useState(0);
  const [contactErrorCount, setContactErrorCount] = useState(0);

  const handleTypeChange = (event) => {
    if (event.target.value === "company") {
      formData.is_company = 1;
      formData.contacts[0].contact_first_name = "";
    } else {
      formData.is_company = 0;
      formData.company_name = "";
      formData.industry = "";
      formData.annual_revenue = "";
      formData.no_of_employees = "";
      if (formData.title) {
        formData.contacts[0].contact_first_name = formData.title;
      } else {
        formData.contacts[0].contact_first_name = "";
      }
    }
    setSelectedType(event.target.value);
  };
  const getLeadData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: "crm/lead/" + leadId,
    });

    setFormData({
      ...formData,
      is_company: data.is_company,
      title: data.title,
      industry: data.industry,
      industry_ref: {
        label: data.industry,
        value: data.industry,
      },
      annual_revenue: data.annual_revenue,
      website: data.website,
      fax: data.fax,
      phone_no: data.phone_no,
      email: data.email,
      company_name: data.company_name,
      company_name_ref: {
        label: data.company_name,
        value: data.company_name,
      },
      lead_source: data.lead_source.id,
      lead_source_ref: {
        label: data.lead_source.source_name,
        value: data.lead_source.id,
      },
      product_id: data?.products?.map((product) => product.id),
      product_id_ref: data?.products?.map((product) => ({
        label: product.name,
        value: product.id,
      })),
      lead_owner_ref: {
        label: data.lead_owner_name,
        value: data.lead_owner_id,
      },
      lead_priority: data.lead_priority,
      lead_priority_ref: {
        label: data.lead_priority,
        value: data.lead_priority,
      },
      address: data.address,
      no_of_employees: data.no_of_employees,
      lead_owner: data.lead_owner_id,
      description: data.description,
      lead_status: data.lead_status_id,
      lead_status_ref: {
        label: data.lead_status,
        value: data.lead_status_id,
      },
      generated_on: data.generated_on,
      lead_assignee: data.lead_assignee_id,
      lead_assignee_ref: {
        label: data.lead_assignee_name,
        value: data.lead_assignee_id,
      },
      person_name: data.person_name,
      person_type: data.person_type,
      person_type_ref: {
        label: data.person_type,
        value: data.person_type,
      },
      social_media_links: data?.social_media_link,
      campaign_id: data?.campaign_id,
      campaign_id_ref: {
        label: data?.campaign_name,
        value: data?.campaign_id,
      },
      contacts:
        data?.primary_contact?.length > 0
          ? [
              {
                contact_id: data.primary_contact[0].id,
                contact_first_name: data.primary_contact[0].first_name,
                contact_last_name: data.primary_contact[0].last_name,
                contact_email: data.primary_contact[0].email,
                contact_phone_no: data.primary_contact[0].phone_no,
                contact_secondary_email:
                  data.primary_contact[0].secondary_email,
                contact_address: data.primary_contact[0].address,
                contact_city: data.primary_contact[0].city,
                contact_state: data.primary_contact[0].state,
                contact_country: data.primary_contact[0].country,
                contact_zip_code: data.primary_contact[0].zip_code,
                contact_website: data.primary_contact[0].website,
                is_primary_contact: 1,
              },
            ]
          : [],
      business_current_status: data?.business_current_status,
      business_current_status_ref: {
        label: data?.business_current_status,
        value: data?.business_current_status,
      },
    });
    setSelectedType(data.is_company ? "company" : "individual");
    setDataProcessing(false);
  };

  useEffect(() => {
    if (leadId) {
      getLeadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const handleFieldChange = (e, action, type) => {
    let newFormData;
    let value;
    let newContactFormData;

    const updateContactInfo = (key, contactKey, value) => ({
      ...formData,
      [key]: value,
      contacts: [{ ...formData.contacts[0], [contactKey]: value }],
    });

    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }

      if (type === "contact") {
        newFormData = {
          ...formData,
          contacts: [{ ...formData.contacts[0], [e.target.name]: value }],
        };
      } else {
        newFormData = {
          ...formData,
          [e.target.name]: value,
        };

        // autofill email and phone number to contacts
        if (e.target.name === "email" || e.target.name === "phone_no") {
          const contactKey =
            e.target.name === "email" ? "contact_email" : "contact_phone_no";
          newFormData = updateContactInfo(e.target.name, contactKey, value);
        }

        // autofill first name  to contacts
        if (e.target.name === "title" && formData.is_company === 0) {
          newFormData = updateContactInfo(
            e.target.name,
            "contact_first_name",
            value
          );
        }
      }
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        if (action.name === "company_name" && formData.is_company === 1) {
          newContactFormData = updateContactInfo(
            action.name,
            "contact_first_name",
            value
          );
          newFormData = {
            ...newContactFormData,
            [action.name]: value,
            [action.name + "_ref"]: e,
          };
        } else if (action.name === "product_id") {
          const labelArray = e.map((item) => item.value);
          newFormData = {
            ...formData,
            [action.name]: labelArray,
            [action.name + "_ref"]: e,
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: value,
            [action.name + "_ref"]: e,
          };
        }
      } else if (action.action === "create-option") {
        value = e.value;
        if (action.name === "company_name" && formData.is_company === 1) {
          newContactFormData = updateContactInfo(
            action.name,
            "contact_first_name",
            value
          );
        }
        newFormData = {
          ...newContactFormData,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      } else if (action.action === "clear") {
        if (action.name === "product_id") {
          newFormData = {
            ...formData,
            [action.name]: [],
            [action.name + "_ref"]: [],
          };
        } else {
          newFormData = {
            ...formData,
            [action.name]: "",
            [action.name + "_ref"]: {
              label: "",
              value: "",
            },
          };
        }
      } else if (action.action === "remove-value") {
        value = e.map((item) => item.value);
        newFormData = {
          ...formData,
          [action.name]: value,
          [action.name + "_ref"]: e,
        };
      }
    }
    setFormData(newFormData);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);
    axios({
      method: leadId ? "put" : "post",
      url: `crm/lead${leadId ? "/" + leadId : ""}`,
      headers: {
        Accept: "application/json",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(leadId ? -1 : "/leads/lead");
          getData();
          setOnSave(false);
        }
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formError).length > 0) {
      const companyFields = [
        "company_name",
        "industry",
        "title",
        "lead_source",
        "lead_priority",
        "lead_owner",
        "email",
        "phone_no",
        "annual_revenue",
      ];
      const individualFields = [
        "title",
        "lead_source",
        "lead_priority",
        "lead_owner",
        "email",
        "phone_no",
      ];
      const contactFields = [
        "contacts.0.contact_first_name",
        "contacts.0.contact_email",
        "contacts.0.contact_phone_no",
      ];

      const companyErrorCount = formData.is_company
        ? companyFields.filter((field) => formError[field]).length
        : 0;
      const individualErrorCount = !formData.is_company
        ? individualFields.filter((field) => formError[field]).length
        : 0;
      const contactErrorCount = contactFields.filter(
        (field) => formError[field]
      ).length;

      setActiveKey(
        companyErrorCount > 0 ? "0" : individualErrorCount > 0 ? "0" : "1"
      );
      setInfoErrorCount(
        formData.is_company ? companyErrorCount : individualErrorCount
      );
      setContactErrorCount(contactErrorCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  const handleToggle = (key) => {
    setActiveKey(key === activeKey ? null : key); // Toggle the active key
  };

  return (
    <>
      <Modal size="xl" show={mode ? true : false} onHide={handleCancel}>
        <Modal.Header closeButton>
          <SiGoogleadmob size={20} className="me-2 text-dark" />
          <span className="custom-module-header">{`${
            !leadId ? "NEW" : "UPDATE"
          } LEAD`}</span>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Accordion
              activeKey={activeKey}
              className="custom-accordion"
              flush
              onSelect={handleToggle}
            >
              <Accordion.Item eventKey="0" className="custom-accordion-item">
                <Accordion.Header
                  className="custom-accordion-header lead-form"
                  style={{ backgroundColor: "#CDD1D6" }}
                >
                  <span className="custom-header-text">
                    LEAD INFORMATION
                    {infoErrorCount > 0 && (
                      <Badge pill bg="danger" className="p-1 ms-1">
                        <span className="m-1">{infoErrorCount}</span>
                      </Badge>
                    )}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {!dataProcessing ? (
                    <>
                      <div className="mt-2 d-flex">
                        <Form.Check
                          type="radio"
                          label="Company"
                          name="type"
                          className=" me-3"
                          value="company"
                          checked={selectedType === "company"}
                          onChange={handleTypeChange}
                        />

                        <Form.Check
                          type="radio"
                          label="Individual"
                          name="type"
                          value="individual"
                          checked={selectedType === "individual"}
                          onChange={handleTypeChange}
                        />
                      </div>
                      <Row className="gy-2">
                        <Col md={12} lg={4}>
                          {selectedType === "company" && (
                            <Form.Group as={Col} xs={12}>
                              <Form.Label className="require-data">
                                Company
                              </Form.Label>
                              <Col>
                                <CreatableSelectCompany
                                  name="company_name"
                                  value={formData.company_name_ref}
                                  handleFieldChange={handleFieldChange}
                                  error={formError.company_name}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-flex p-0 m-0"
                                >
                                  <span className="ms-auto">
                                    {formError.company_name}
                                  </span>
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          )}
                          <Form.Group
                            as={Col}
                            xs={12}
                            className={selectedType === "company" ? "mt-2" : ""}
                          >
                            <Form.Label className="require-data">
                              Job Title
                            </Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="title"
                                onChange={handleFieldChange}
                                value={formData.title}
                                isInvalid={!!formError.title}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.title}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label
                              className={`${
                                !formData.email ? "require-data" : ""
                              }`}
                            >
                              Phone Number
                            </Form.Label>
                            <Col>
                              <PhoneNumberInput
                                name="phone_no"
                                onChange={handleFieldChange}
                                value={formData.phone_no}
                                isInvalid={!!formError.phone_no}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.phone_no}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Website</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="website"
                                onChange={handleFieldChange}
                                value={formData.website}
                                isInvalid={!!formError.website}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.website}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="require-data">
                              Lead Owner
                            </Form.Label>
                            <Col>
                              <SelectUsers
                                value={formData.lead_owner_ref}
                                name="lead_owner"
                                handleFieldChange={handleFieldChange}
                                error={formError.lead_owner}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.lead_owner}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Campaign</Form.Label>
                            <Col>
                              <SelectCampaign
                                name="campaign_id"
                                value={formData.campaign_id_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.campaign_id}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.campaign_id}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            xs={12}
                            className="mt-2"
                            controlId="exampleForm.ControlInput2"
                          >
                            <Form.Label>Business Current Status</Form.Label>
                            <Col>
                              <SelectBusinessCurrentStatus
                                value={formData?.business_current_status_ref}
                                name="business_current_status"
                                error={formError?.business_current_status}
                                handleFieldChange={handleFieldChange}
                              />
                              <Form.Control.Feedback type="invalid">
                                {formError?.business_current_status}
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={12} lg={4}>
                          {selectedType === "company" && (
                            <>
                              <Form.Group as={Col} xs={12}>
                                <Form.Label className="require-data">
                                  Industry
                                </Form.Label>
                                <Col>
                                  <SelectIndustry
                                    name="industry"
                                    value={formData.industry_ref}
                                    handleFieldChange={handleFieldChange}
                                    error={formError.industry}
                                  />
                                </Col>
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-flex p-0 m-0"
                                >
                                  <span className="ms-auto">
                                    {formError.industry}
                                  </span>
                                </Form.Control.Feedback>
                              </Form.Group>
                              <Row className="gx-2">
                                <Col xs={12} md={12} lg={6}>
                                  <Form.Group as={Col} xs={12} className="mt-2">
                                    <Form.Label className="">
                                      Annual Revenue
                                    </Form.Label>
                                    <Col>
                                      <Form.Control
                                        type="text"
                                        name="annual_revenue"
                                        onChange={handleFieldChange}
                                        value={formData.annual_revenue}
                                        isInvalid={!!formError.annual_revenue}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-flex p-0 m-0"
                                      >
                                        <span className="ms-auto">
                                          {formError.annual_revenue}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>
                                </Col>
                                <Col xs={12} md={12} lg={6}>
                                  <Form.Group as={Col} xs={12} className="mt-2">
                                    <Form.Label className="">
                                      No Of Employees
                                    </Form.Label>
                                    <Col>
                                      <Form.Control
                                        type="number"
                                        name="no_of_employees"
                                        onChange={handleFieldChange}
                                        value={formData.no_of_employees}
                                        isInvalid={!!formError.no_of_employees}
                                      />
                                      <Form.Control.Feedback
                                        type="invalid"
                                        className="d-flex p-0 m-0"
                                      >
                                        <span className="ms-auto">
                                          {formError.no_of_employees}
                                        </span>
                                      </Form.Control.Feedback>
                                    </Col>
                                  </Form.Group>
                                </Col>
                              </Row>
                            </>
                          )}
                          <Form.Group
                            as={Col}
                            xs={12}
                            className={selectedType === "company" ? "mt-2" : ""}
                          >
                            <Form.Label
                              className={`${
                                !formData.phone_no ? "require-data" : ""
                              }`}
                            >
                              Email
                            </Form.Label>
                            <Col>
                              <Form.Control
                                type="email"
                                name="email"
                                onChange={handleFieldChange}
                                value={formData.email}
                                isInvalid={!!formError.email}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.email}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} md={12} className="mt-2">
                            <Form.Label className="require-data">
                              Lead Source
                            </Form.Label>
                            <Col>
                              <SelectLeadSource
                                name="lead_source"
                                value={formData.lead_source_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.lead_source}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.lead_source}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Lead Status</Form.Label>
                            <Col>
                              <SelectLeadStatus
                                name="lead_status"
                                value={formData.lead_status_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.lead_status}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.lead_status}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Assignee</Form.Label>
                            <Col>
                              <SelectUsers
                                name="lead_assignee"
                                value={formData.lead_assignee_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.lead_assignee}
                              />
                            </Col>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-flex p-0 m-0"
                            >
                              <span className="ms-auto">
                                {formError.lead_assignee}
                              </span>
                            </Form.Control.Feedback>
                          </Form.Group>

                          {selectedType === "individual" && (
                            <Form.Group
                              as={Col}
                              xs={12}
                              md={12}
                              lg={12}
                              className="mt-2"
                            >
                              <Form.Label className="require-data">
                                Lead Priority
                              </Form.Label>
                              <Col>
                                <SelectLeadPriority
                                  name="lead_priority"
                                  value={formData.lead_priority_ref}
                                  handleFieldChange={handleFieldChange}
                                  error={formError.lead_priority}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-flex p-0 m-0"
                                >
                                  <span className="ms-auto">
                                    {formError.lead_priority}
                                  </span>
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          )}
                        </Col>
                        <Col md={12} lg={4}>
                          <Form.Group as={Col} xs={12}>
                            <Form.Label>Person Name</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="person_name"
                                onChange={handleFieldChange}
                                value={formData.person_name}
                                isInvalid={!!formError.person_name}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.person_name}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Person Type</Form.Label>
                            <Col>
                              <SelectPersonType
                                name="person_type"
                                value={formData.person_type_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.person_type}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.person_type}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Fax</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="fax"
                                onChange={handleFieldChange}
                                value={formData.fax}
                                isInvalid={!!formError.fax}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">{formError.fax}</span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          {selectedType === "company" && (
                            <Form.Group
                              as={Col}
                              xs={12}
                              md={12}
                              className="mt-2"
                            >
                              <Form.Label className="require-data">
                                Lead Priority
                              </Form.Label>
                              <Col>
                                <SelectLeadPriority
                                  name="lead_priority"
                                  value={formData.lead_priority_ref}
                                  handleFieldChange={handleFieldChange}
                                  error={formError.lead_priority}
                                />
                                <Form.Control.Feedback
                                  type="invalid"
                                  className="d-flex p-0 m-0"
                                >
                                  <span className="ms-auto">
                                    {formError.lead_priority}
                                  </span>
                                </Form.Control.Feedback>
                              </Col>
                            </Form.Group>
                          )}
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Products</Form.Label>
                            <Col>
                              <MultiSelectCheckBoxProduct
                                name="product_id"
                                value={formData.product_id_ref}
                                handleFieldChange={handleFieldChange}
                                error={formError.product_id}
                              />
                            </Col>
                            <Form.Control.Feedback
                              type="invalid"
                              className="d-flex p-0 m-0"
                            >
                              <span className="ms-auto">
                                {formError.product_id}
                              </span>
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Generated On</Form.Label>
                            <Col>
                              <AppDatePicker
                                name="generated_on"
                                yearPlaceholder="yyyy"
                                monthPlaceholder="mm"
                                dayPlaceholder="dd"
                                onChange={handleFieldChange}
                                value={formData.generated_on}
                                isInvalid={!!formError.generated_on}
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError.generated_on}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="gy-2 mt-2">
                        <Col md={12} lg={6}>
                          <SocialMedia
                            setFormData={setFormData}
                            formData={formData}
                          />
                        </Col>
                        <Col md={12} lg={6}>
                          <Form.Group as={Col} xs={12}>
                            <Form.Label className="">Address</Form.Label>
                            <Col>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="address"
                                onChange={handleFieldChange}
                                value={formData.address}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Description</Form.Label>
                            <Col>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                onChange={handleFieldChange}
                                value={formData.description}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <ListLoading
                      style={{ maxHeight: "40vh", overflow: "hidden" }}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="custom-accordion-item">
                <Accordion.Header className="custom-accordion-header lead-form">
                  <span className="custom-header-text">
                    CONTACT INFORMATION
                    {contactErrorCount > 0 && (
                      <Badge pill bg="danger" className="p-1 ms-1">
                        <span className="m-1">{contactErrorCount}</span>
                      </Badge>
                    )}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {!dataProcessing ? (
                    <>
                      <Row>
                        <Col>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="require-data">
                              First Name
                            </Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name={`contact_first_name`}
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={
                                  formData?.contacts[0]?.contact_first_name
                                }
                                isInvalid={
                                  !!formError[
                                    `contacts.${0}.contact_first_name`
                                  ]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {
                                    formError[
                                      `contacts.${0}.contact_first_name`
                                    ]
                                  }
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="require-data">
                              Email
                            </Form.Label>
                            <Col>
                              <Form.Control
                                type="email"
                                name="contact_email"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_email}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_email`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_email`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="require-data">
                              Phone Number
                            </Form.Label>
                            <Col>
                              <PhoneNumberInput
                                name="contact_phone_no"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_phone_no}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_phone_no`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_phone_no`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">State</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_state"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_state}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_state`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_state`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Zip Code</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_zip_code"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_zip_code}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_zip_code`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_zip_code`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Last Name</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_last_name"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_last_name}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_last_name`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_last_name`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">
                              Secondary Email
                            </Form.Label>
                            <Col>
                              <Form.Control
                                type="email"
                                name="contact_secondary_email"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={
                                  formData?.contacts[0]?.contact_secondary_email
                                }
                                isInvalid={
                                  !!formError[
                                    `contacts.${0}.contact_secondary_email`
                                  ]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {
                                    formError[
                                      `contacts.${0}.contact_secondary_email`
                                    ]
                                  }
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Country</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_country"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_country}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_country`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_country`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">City</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_city"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_city}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_city`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_city`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label className="">Website</Form.Label>
                            <Col>
                              <Form.Control
                                type="text"
                                name="contact_website"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_website}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_website`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_website`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group as={Col} xs={12} className="mt-2">
                            <Form.Label>Address</Form.Label>
                            <Col>
                              <Form.Control
                                as="textarea"
                                rows={3}
                                name="contact_address"
                                onChange={(e) => {
                                  handleFieldChange(e, null, "contact");
                                }}
                                value={formData?.contacts[0]?.contact_address}
                                isInvalid={
                                  !!formError[`contacts.${0}.contact_address`]
                                }
                              />
                              <Form.Control.Feedback
                                type="invalid"
                                className="d-flex p-0 m-0"
                              >
                                <span className="ms-auto">
                                  {formError[`contacts.${0}.contact_address`]}
                                </span>
                              </Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <ListLoading
                      style={{ maxHeight: "40vh", overflow: "hidden" }}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
          <Modal.Footer>
            {!dataProcessing ? (
              <>
                <Form.Group className="d-flex justify-content-end">
                  <SaveButton
                    handleSubmit={handleSubmit}
                    onSave={onSave}
                    id={leadId}
                  />
                </Form.Group>
                <Form.Group>
                  <CancelButton handleCancel={handleCancel} onSave={onSave} />
                </Form.Group>
              </>
            ) : (
              <div>
                <ListLoading
                  style={{
                    maxHeight: "9vh",
                    width: "20vh",
                    overflow: "hidden",
                  }}
                />
              </div>
            )}
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </>
  );
}
LeadForm.prototype = {
  getData: PropTypes.func,
};
export default LeadForm;
