import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { apiCall } from "helpers/apiCalls";
import useAxisproPermission from "hooks/useAxisproPermission";
import QuotationForm from "module/Quotation/QuotationForm/QuotationForm";
import ViewPagePrintButton from "module/common/ViewPages/Common/ViewPagePrintButton";
import React, { useEffect, useState } from "react";
import { Badge, Button, Card, Tab, Table, Tabs } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { Link, createSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";

const QuotationInfo = () => {
  const { itemId, quoteId } = useParams();
  const [loading, setLoading] = useState(false);
  const [quotation, setQuotation] = useState({});
  const axisProPermission = useAxisproPermission();
  const updatePermission = axisProPermission("update-opportunity-quotation");
  const exportPermission = axisProPermission("export-opportunity-quotation");

  const getQuotation = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `sales/sales-quote/${quoteId}`,
    });
    setQuotation(data);
    setLoading(false);
  };

  useEffect(() => {
    getQuotation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteId]);

  return (
    <Card className="h-100">
      <Card.Header className="border-bottom">
        <div className="d-flex justify-content-between align-items-center">
          <div className="mt-1 mb-1 fw-semibold">QUOTATION INFORMATION</div>
          <div className="d-flex gap-2">
            {updatePermission && (
              <Button
                variant="warning"
                className="text-white"
                size="sm"
                as={Link}
                to={`/opportunity/overview/${itemId}/quotation/${quoteId}?${createSearchParams(
                  {
                    quotation_id: quoteId,
                    mode: "edit",
                  }
                )}`}
              >
                <FaEdit size={15} />
              </Button>
            )}
            {exportPermission && (
              <ViewPagePrintButton
                variant="danger"
                className="text-white"
                style={{ boxShadow: "none" }}
                url={`sales/quotation/print/${quoteId}`}
                pageTitle="Sales Quotation print view"
              />
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <QuotationForm
          requiredData={{
            opportunity_id: quotation?.opportunity_id,
            opportunity_name: quotation?.opportunity_title,
            account_id: quotation?.customer_id,
            account_name: quotation?.customer_name,
          }}
          fetchData={getQuotation}
        />
        {!loading ? (
          <div className="h-100 d-flex flex-column">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-column gap-1">
                <div className="d-flex gap-2">
                  <span className="fw-semibold">Customer Name:</span>
                  <span className="text-capitalize">
                    {quotation?.customer_name}
                  </span>
                </div>
                <div className="d-flex gap-2">
                  <span className="fw-semibold">Transaction Date: </span>
                  <span>{quotation?.trans_date_formatted}</span>
                </div>
              </div>
              <div className="d-flex gap-2">
                <span className="fw-semibold">Reference: </span>
                <span>
                  <Badge bg="warning" size="sm">
                    {quotation?.reference}
                  </Badge>
                </span>
              </div>
            </div>
            <div className="mt-2">
              <Tabs
                defaultActiveKey="overview"
                id="uncontrolled-tab-example"
                className="mb-3"
              >
                <Tab eventKey="overview" title="Overview">
                  <div className="pt-2 pb-2 ps-3 pe-4 d-flex justify-content-between">
                    <div className="d-flex flex-column gap-2">
                      <div>
                        <span className="fw-semibold">Sales Type : </span>
                        <span className="text-capitalize">
                          {quotation?.sales_type_name}
                        </span>
                      </div>
                      <div>
                        <span className="fw-semibold">Note : </span>
                        <span className="text-capitalize">
                          {quotation?.memo}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column">
                      <span>
                        <label className="fw-semibold">Amount :</label>{" "}
                        {quotation?.amount_formatted}
                      </span>
                      <span>
                        <label className="fw-semibold">Total Tax :</label>{" "}
                        {quotation?.total_tax_formatted}
                      </span>
                      <span>
                        <label className="fw-semibold">Total Discount :</label>{" "}
                        {quotation?.total_discount_amount_formatted}
                      </span>
                    </div>
                  </div>
                  {Object.keys(quotation).length > 0 &&
                    Object.keys(quotation?.details)?.length > 0 && (
                      <div
                        className="mt-2"
                        style={{ maxHeight: "42vh", overflowY: "auto" }}
                      >
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr style={{ backgroundColor: "#686C96" }}>
                              <th className="fw-semibold text-white">#</th>
                              <th className="fw-semibold text-white">
                                ITEM CODE
                              </th>
                              <th className="fw-semibold text-white">
                                ITEM NAME
                              </th>
                              <th className="fw-semibold text-white">
                                QUANTITY
                              </th>
                              <th className="fw-semibold text-white">UNIT</th>
                              <th className="fw-semibold text-white">PRICE</th>
                              <th className="fw-semibold text-white">TAX</th>
                              <th className="fw-semibold text-white">
                                DISCOUNT
                              </th>
                              <th className="fw-semibold text-white">TOTAL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {quotation?.details?.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.stock_id}</td>
                                <td>{item.item_display_name}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unit_name}</td>
                                <td>{item.unit_price_formatted}</td>
                                <td>{item.unit_tax_formatted}</td>
                                <td>{item.discount_amount_formatted}</td>
                                <td>{item.line_total_formatted}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    )}
                </Tab>
              </Tabs>
            </div>
            <Card.Footer className="mt-auto">
              <div className="d-flex justify-content-between">
                <span>
                  <label className="fw-semibold">Created By:</label>{" "}
                  {quotation?.created_user_full_name}
                </span>
                <span>
                  <label className="fw-semibold">Created At:</label>{" "}
                  {quotation?.created_at}
                </span>
              </div>
            </Card.Footer>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100">
            <LoadingCommon loadingText="Loading Quotation Information" />
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default QuotationInfo;
