import React from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import { FaFilter } from "react-icons/fa";
import { createSearchParams, useNavigate, useParams } from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import ProjectDetailedReportForm from "module/Report/ReportFilterSection/FilterForm/ProjectDetailedReportForm";
import TaskDetailedReportForm from "module/Report/ReportFilterSection/FilterForm/TaskDetailedReportForm";
import TimeTrackerReportForm from "module/Report/ReportFilterSection/FilterForm/TimeTrackerReportForm";
import EmployeeTaskReportForm from "module/Report/ReportFilterSection/FilterForm/EmployeeTaskReportForm";
import EmployeeTaskSummaryReportForm from "module/Report/ReportFilterSection/FilterForm/EmployeeTaskSummaryReportForm";
import EmployeeLeadSummaryReportForm from "module/Report/ReportFilterSection/FilterForm/EmployeeLeadSummaryReportForm";
import OpportunityDetailedReportForm from "module/Report/ReportFilterSection/FilterForm/OpportunityDetailedReportForm";
import LeadDetailedReportForm from "module/Report/ReportFilterSection/FilterForm/LeadDetailedReportForm";
import LeadConversionReportForm from "module/Report/ReportFilterSection/FilterForm/LeadConversionReportForm";
import RunningOrCompletedTasksReportForm from "module/Report/ReportFilterSection/FilterForm/RunningOrCompletedTasksReportForm";
import SprintDetailedReportForm from "module/Report/ReportFilterSection/FilterForm/SprintDetailedReportForm";
import LeadSourceSummeryReportForm from "module/Report/ReportFilterSection/FilterForm/LeadSourceSummeryReportForm";
import LeadSourceSummeryItemReportForm from "module/Report/ReportFilterSection/FilterForm/LeadSourceSummeryItemReportForm";

const ReportFilterOffcanvas = ({
  offcanvasVisibility,
  handleFilterOffCanvas,
  filters,
  setFilters,
  handleClearFilters,
  url,
  columns,
}) => {
  const navigate = useNavigate();
  const { lead_source_id } = useParams();
  const handleFilterChange = (e, action) => {
    let newOldData;
    if (action) {
      newOldData = {
        ...filters,
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      newOldData = {
        ...filters,
        [e.target.name]: e.target.value,
      };
    }
    setFilters({ ...newOldData });
  };

  const handleApplyFilter = () => {
    if (!filters.date_range_for) {
      filters.from_date = "";
      filters.to_date = "";
    }

    const filteredObject = removeEmptyFields(filters);
    navigate(
      `/${url}?page=1&${createSearchParams({
        filters: JSON.stringify({
          ...filteredObject,
        }),
      })}`
    );
    handleFilterOffCanvas(false);
  };

  return (
    <div>
      <Offcanvas show={offcanvasVisibility}>
        <Offcanvas.Header className="border-bottom">
          <Offcanvas.Title className="fs-0 w-100">
            <div className="d-flex justify-content-between">
              <div className="d-flex align-items-center text-warning">
                <FaFilter size={18} />
                <span className="ms-2">Filters</span>
              </div>
              <Button
                variant="transparent"
                className="ms-auto fs--1 border-danger text-danger me-2"
                size="sm"
                onClick={handleClearFilters}
              >
                Reset Filters
              </Button>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form>
            {url.includes("project-report") ? (
              <ProjectDetailedReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("reports/task-report") ? (
              <TaskDetailedReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("time-tracker-report") ? (
              <TimeTrackerReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("employee-task-report") ? (
              <EmployeeTaskReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("employee-task-summary-report") ? (
              <EmployeeTaskSummaryReportForm
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("employee-lead-report") ? (
              <EmployeeLeadSummaryReportForm
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("opportunity-report") ? (
              <OpportunityDetailedReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("leads-report") ? (
              <LeadDetailedReportForm
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("lead-conversion-report") ? (
              <LeadConversionReportForm
                filters={filters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("lead-source-summary-report") &&
              !lead_source_id ? (
              <LeadSourceSummeryReportForm
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : url.includes("running-completed-tasks-report") ? (
              <RunningOrCompletedTasksReportForm
                columns={columns}
                filters={filters}
                handleFilterChange={handleFilterChange}
                setFilters={setFilters}
              />
            ) : url.includes("sprint-detailed-report") ? (
              <SprintDetailedReportForm
                columns={columns}
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            ) : (
              ""
            )}
            {lead_source_id && (
              <LeadSourceSummeryItemReportForm
                filters={filters}
                setFilters={setFilters}
                handleFilterChange={handleFilterChange}
              />
            )}
            <Form.Group controlId="formBasicEmail" className="mt-3">
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  className="me-2 mt-3"
                  onClick={() => handleFilterOffCanvas(false)}
                  variant="danger"
                >
                  Close
                </Button>
                <Button
                  size="sm"
                  className="mt-3"
                  variant="success"
                  onClick={handleApplyFilter}
                >
                  <FaFilter size={13} />
                  <span className="ms-1">Apply</span>
                </Button>
              </div>
            </Form.Group>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default ReportFilterOffcanvas;
