import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form, Button } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { labelsFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import ListLoading from "module/common/ListLoading";
import { FaCheck } from "react-icons/fa";
import { MdLabelImportant } from "react-icons/md";
import SelectLabelsSubjectType from "components/form/SelectLabelsSubjectType";

const LabelsForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(labelsFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const [colorPalettes, setColorPalettes] = useState({});
  const [loadingColor, setloadingColor] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { label, subject_type } = allQueryParams;
  const isParamsAvailable = label || subject_type;

  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleColorSelection = (color) => {
    setFormData({
      ...formData,
      colour: color,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/label${itemId ? "/" + itemId : ""}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          navigate(itemId ? -1 : "/settings/system-settings/labels");
          (!isParamsAvailable || itemId) && getData(itemId ? pageNumber : 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
              error?.response?.data?.data &&
              error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const getColorPalettes = async (page = 1) => {
    setloadingColor(true);
    const data = await apiCall({
      url: "crm/list-colours",
    });

    setColorPalettes(data);
    setloadingColor(false);
  };

  useEffect(() => {
    getColorPalettes();
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/label/" + itemId,
      params: {
        page: page,
      },
    });

    setFormData({
      label: data?.label,
      description: data?.description,
      colour: data?.colour,
      list_colours: data?.list_colours,
      subject_type: data?.subject_type,
      subject_type_ref: {
        label: data?.subject_type,
        value: data?.subject_type,
      },
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-1 pb-1">
          <MdLabelImportant size={28} className="me-1 text-dark" />
          <span className="custom-module-header text-uppercase">
            {itemId ? "UPDATE LABEL" : "CREATE LABEL"}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Label</Form.Label>
              <Form.Control
                type="text"
                name="label"
                onChange={handleFieldChange}
                value={formData?.label}
                placeholder="Enter Label Name"
                isInvalid={!!formError?.label}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.label}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                onChange={handleFieldChange}
                value={formData?.description}
                isInvalid={!!formError?.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Type</Form.Label>
              <SelectLabelsSubjectType
                value={formData?.subject_type_ref}
                name="subject_type"
                handleFieldChange={handleFieldChange}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.subject_type}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
              <Form.Label className="require-data">Color</Form.Label>
              <div>
                <Form.Control
                  className=""
                  type="color"
                  value={formData?.colour}
                  title="Choose your color"
                  onChange={handleFieldChange}
                  isInvalid={!!formError?.colour}
                  hidden
                />
                <div className="d-flex flex-wrap">
                  {!loadingColor ? (
                    Object.keys(colorPalettes).map((item, key) => (
                      <div key={key}>
                        <Button
                          className="d-inline me-2 mt-2 border-0"
                          size="sm"
                          onClick={() => {
                            handleColorSelection(colorPalettes[item]);
                          }}
                          style={{
                            width: "2.3rem",
                            height: "2.3rem",
                            backgroundColor: colorPalettes[item],
                          }}
                        >
                          {formData?.colour === colorPalettes[item] && (
                            <FaCheck size={15} />
                          )}
                        </Button>
                      </div>
                    ))
                  ) : (
                    <ListLoading
                      style={{ maxHeight: "8vh", overflow: "hidden" }}
                    />
                  )}
                </div>
                <Form.Control.Feedback type="invalid">
                  {formError?.colour}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default LabelsForm;