import { React, useEffect, useState } from "react";
import axios from "axios";
import { Badge, Image, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaCircle } from "react-icons/fa";

import "./ActivityTimeLine.scss";
import DefaultAvathar from "assets/img/Avathar/ProfileImage.jpg";
import ActivityLogLoader from "module/common/ViewPages/Common/Activity/Timeline/ActivityLogLoader";

const ActivityTimeLine = ({
  infoData,
  triggerActivityLog,
  itemId,
  height = "30rem",
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(infoData || { activity_log: [] });

  const fetchData = () => {
    setLoading(true);
    axios
      .get("activity-log", {
        params: {
          subject_id: itemId,
        },
      })
      .then((res) => {
        if (res.data.success) {
          const activityLogData = {
            activity_log: res?.data?.data?.data,
          };
          setData(activityLogData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!infoData) {
      fetchData();
    } else {
      setData(infoData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoData, itemId, triggerActivityLog]);

  return (
    <>
      {!loading ? (
        <Row>
          <div
            className="widget-wrap "
            style={{
              maxHeight: height,
              overflow: "hidden",
              overflowY: "scroll",
            }}
          >
            {data?.activity_log?.length > 0 ? (
              data?.activity_log.map((items, index) => {
                return (
                  <div className="activity-container-div" key={index}>
                    <div className="activity-container-wrapper d-flex justify-content-between align-item-center">
                      <div>
                        <span className="d-block">
                          <p className="fs--1 m-0 mb-1 text-uppercase text-danger fw-bold">
                            {items.created_at}
                          </p>
                        </span>
                        <span className="d-block pt-1">
                          <Badge bg="warning" text="dark" className="d-inline">
                            {items.event}
                          </Badge>
                          <p className="d-inline ps-2">{items.description}</p>
                        </span>
                        <span className="d-block pt-2">
                          <span className="d-block">
                            {items?.properties?.old?.status && (
                              <>
                                <FaCircle size={7} className="d-inline" />
                                <span className="d-inline ps-2">
                                  Status:
                                  <p className="d-inline text-decoration-line-through ms-1 me-2">
                                    {items?.properties?.old?.status.replace(
                                      "_",
                                      " "
                                    )}
                                  </p>
                                  {items?.properties?.attributes?.status.replace(
                                    "_",
                                    " "
                                  )}
                                </span>
                              </>
                            )}
                          </span>
                        </span>
                      </div>
                      <div className="d-flex flex-column justify-content-center align-items-center gap-2">
                        <span>
                          <Image
                            src={
                              items?.causer_image
                                ? items.causer_image
                                : DefaultAvathar
                            }
                            height={25}
                            width={25}
                            alt="?"
                            roundedCircle
                          />
                        </span>
                        <span className="custom-text-muted">
                          {items?.causer_name}
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center w-100 mt-5">No Activity Yet 😉</div>
            )}
          </div>
        </Row>
      ) : (
        <ActivityLogLoader height={height} items={10} />
      )}
    </>
  );
};
ActivityTimeLine.prototype = {
  data: PropTypes.any,
  triggerActivityLog: PropTypes.bool,
};

export default ActivityTimeLine;
