import { React, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";

const MultiSelectContacts = ({
  onChange,
  name,
  error,
  value,
  style,
  placeholder,
  excludeUser,
  assignee_id,
  assignees,
  itemId,
  type,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    loadOptions(value).then((loadedOptions) => {
      setOptions(loadedOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeUser]);
  const loadOptions = (inputValue) => {
    return new Promise(async (resolve) => {
      let response = await axios.get("crm/contacts", {
        params: {
          subject_type: type === "LEAD" ? "LEAD" : "OPPORTUNITY",
          subject_id: itemId,
        },
      });
      let data = response && response.data ? response.data.data : [];
      let mergedArray = [];
      if (assignees?.length > 0) {
        mergedArray = [...data.data, ...assignees];
        const assigneeIds = new Set(assignees.map((assignee) => assignee.id));
        mergedArray = mergedArray.filter((obj) => !assigneeIds.has(obj.id));
      } else {
        mergedArray = data.data;
      }

      const loadedOptions = mergedArray.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      resolve(loadedOptions);
    });
  };

  return (
    <Select
      isMulti
      cacheOptions
      options={options}
      loadOptions={loadOptions}
      defaultOptions
      isClearable
      name={name ? name : "Select"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={onChange}
      isSearchable={true}
      className={
        error
          ? "form-control ps-0 py-0 is-invalid text-uppercase"
          : "text-uppercase"
      }
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: "fit-content",
          minHeight: 32,
          ...style,
        }),
      }}
    ></Select>
  );
};

MultiSelectContacts.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  itemId: PropTypes.string,
};

export default MultiSelectContacts;
