import { React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import SelectCostCenter from "../Search/SelectCostCenter";

function CostCenterInputField({
  formData,
  handleCostCenter,
  formError,
  setFormData,
  required,
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-3">
      <Row>
        <Col xl={4} md={4} sm={12}>
          <Form.Label
            className={
              required
                ? "require-data m-0 d-flex align-items-center justify-content-start h-100"
                : "m-0 d-flex align-items-center justify-content-start h-100"
            }
          >
            {Translate("Cost Center")}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="flex-2">
              <SelectCostCenter
                value={formData.cost_center_id_ref}
                name={formData.cost_center_id_ref}
                handleFieldChange={handleCostCenter}
                setFormData={setFormData}
              />
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              {formError.cost_center_id ? (
                <FormErrorPopover
                  id="cost_center_id"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.cost_center_id}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

CostCenterInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleCostCenter: PropTypes.func,
  setFormData: PropTypes.func,
  required: PropTypes.bool,
};

export default CostCenterInputField;
