import React from "react";
import { Col, Form } from "react-bootstrap";
import DateRangePicker from "module/common/Filter/DateRangePicker";
import SelectLeadSource from "components/form/SelectLeadSource";

const LeadSourceSummeryReportForm = ({ filters, setFilters, handleFilterChange }) => {
    return (
        <div>
            <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="fs--1">Date Range</Form.Label>
                <DateRangePicker
                    formData={filters}
                    className={"custom-date-range-picker"}
                    setFormData={setFilters}
                    dateRange_form={{
                        to: "to_date",
                        from: "from_date",
                        range: "date_range",
                        month: "month",
                    }}
                />
            </Form.Group>
            <Form.Group controlId="formBasicEmail" className="mb-3">
                <Form.Label className="require-data">
                    Source
                </Form.Label>
                <Col>
                    <SelectLeadSource
                        name="lead_source_id"
                        value={filters.lead_source_id_ref}
                        handleFieldChange={handleFilterChange}
                    />
                </Col>
            </Form.Group>
        </div>
    );
};

export default LeadSourceSummeryReportForm;
