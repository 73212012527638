import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";

const AdvanceTable = ({
  tablebodyClassName,
  headerStyle,
  getTableProps,
  headers,
  page,
  prepareRow,
  headerClassName,
  rowClassName,
  tableProps,
  responsive = true,
}) => {
  const updatedHeaderStyle = { ...headerStyle };
  return (
    <Table {...getTableProps({ tableProps })} responsive={responsive}>
      <thead
        className={`${headerClassName} bg-200 text-900 text-nowrap align-middle`}
      >
        <tr className="custom-table-tr" style={{ width: "100%" }}>
          {headers.map((column, index) => (
            <th
              key={index}
              {...column.getHeaderProps(
                column.getSortByToggleProps(column.headerProps)
              )}
              style={{
                updatedHeaderStyle,
                width: column?.width ? column?.width : "",
              }}
              className="pe-3 fw-bold"
            >
              {column.render("Header")}
              {column.canSort ? (
                column.isSorted ? (
                  column.isSortedDesc ? (
                    <span className="sort desc" />
                  ) : (
                    <span className="sort asc" />
                  )
                ) : (
                  <span className="sort" />
                )
              ) : (
                ""
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className={tablebodyClassName}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr
              key={i}
              className={`${rowClassName} align-middle white-space-nowrap`}
              {...row.getRowProps()}
              style={{ width: "100%" }}
            >
              {row.cells.map((cell, index) => {
                return (
                  <td
                    key={index}
                    {...cell.getCellProps(cell.column.cellProps)}
                    className={`py-1`}
                  >
                    <div
                      style={{ whiteSpace: "break-spaces" }}
                      className={`${
                        !cell?.column?.Header
                          ? `d-flex align-items-center justify-content-end`
                          : ``
                      }`}
                    >
                      {cell.render("Cell")}
                    </div>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
AdvanceTable.propTypes = {
  getTableProps: PropTypes.func,
  headers: PropTypes.array,
  page: PropTypes.array,
  prepareRow: PropTypes.func,
  headerClassName: PropTypes.string,
  rowClassName: PropTypes.string,
  tableProps: PropTypes.object,
  responsive: PropTypes.bool,
};

export default AdvanceTable;
