import React from "react";
import { Card, Col } from "react-bootstrap";
import PropTypes from "prop-types";
import bg2 from "assets/img/icons/spot-illustrations/corner-2.png";
import Background from "components/common/Background";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";

function InfoTile({ dashboardData, loading, loadingBox = 4 }) {
  const data = Array.from({ length: loadingBox }, (_, index) => index + 1);

  return !loading &&
    dashboardData &&
    dashboardData.status_info &&
    dashboardData.status_info.length > 0
    ? dashboardData.status_info.map((info, key) => (
        <Col key={key}>
          <Card className="h-100 w-100 card-main" key={key}>
            <Background image={bg2} className="bg-card" />
            <Card.Body>
              <div className="d-grid justify-content-center align-items-center">
                <span className="d-flex justify-content-center">
                  {getTaskStatusWithProps(info.status, "icon")}
                </span>
                <span
                  className="fs-1 mt-2 text-capitalize text-center"
                  style={{ color: "rgb(1,0,1)" }}
                >
                  {info.status.replace("_", " ")}
                </span>
                <span className=" mt-2 d-flex justify-content-center">
                  {info.count} Tasks
                </span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))
    : data.map((key) => (
        <Col key={key}>
          <Card className="w-100 pb-1 pt-1 card-main">
            <Background image={bg2} className="bg-card" />
            <Card.Body>
              <DashboardTileLoader column={1} />
            </Card.Body>
          </Card>
        </Col>
      ));
}

InfoTile.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default InfoTile;
