import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";

import Background from "components/common/Background";
import SoftBadge from "components/common/SoftBadge";

function LeadStage({ data, label, icon, variant, image }) {
  return (
    <Card className="card-main" style={{ height: "6rem" }}>
      <Background image={image} className="bg-card" />
      <Card.Header className="p-2">
        <div className="ms-1 text-dark d-flex align-items-center">
          {icon} <span className="fs--1 mt-1">{label}</span>
        </div>
      </Card.Header>
      <Card.Body className="p-3 d-flex align-items-center justify-content-center">
        <SoftBadge
          bg={data ? variant : "secondary"}
          className="p-1 ps-3 pe-3 fs--1 text-capitalize mb-3 rounded-3"
        >
          {data ? data : "N/A"}
        </SoftBadge>
      </Card.Body>
    </Card>
  );
}

LeadStage.propTypes = {
  data: PropTypes.any,
  label: PropTypes.string,
  icon: PropTypes.any,
  variant: PropTypes.string,
  image: PropTypes.any,
};

export default LeadStage;
