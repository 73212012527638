import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import {
  Card,
  Col,
  Offcanvas,
  Row,
  Tab,
  Tabs,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FaBell, FaHashtag, FaUserAltSlash } from "react-icons/fa";
import { BiTask } from "react-icons/bi";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { tasksFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import { RedirectButton } from "module/common/Buttons/AppButtons";
import ActivityTimeLine from "../../common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";
import Collaborator from "../../common/ViewPages/Common/Collaborator/Collaborator";
import DigitalTimer from "module/common/Timer/DigitalTimer";
import ProfileImage from "assets/img/Avathar/profile.png";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import TimeSheetLog from "module/common/ViewPages/Common/TimeSheet/TimeSheetLog";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import { Link } from "react-router-dom";
import ListLoading from "module/common/ListLoading";
import TabLoading from "module/common/TabLoading";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import ImagePreview from "module/common/ImagePreview/ImagePreview";
import getTaskPriorityWithProps from "module/common/helpers/getTaskPriorityWithProps";
import { GrEdit } from "react-icons/gr";
import useAxisproPermission from "hooks/useAxisproPermission";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import TimerConfirm from "module/common/Timer/TimerConfirm";
import TaskStatusButton from "../common/TaskStatusButton";
import { CloseButton } from "module/common/Buttons/CommonButton";

const TaskInfo = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [infoData, setInfoData] = useState({});
  const { itemId, itemInfoId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const [statusUpdateFormData, setStatusUpdateFormData] =
    useState(tasksFormKeys);
  const [collabList, setCollabList] = useState({});
  const { dispatch, user } = useContext(AuthWizardContext);
  const [hasMatch, setHasMatch] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const location = useLocation();
  const renderFrom = location.pathname.split("/")[1];
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const [getData] = useOutletContext();
  const [updateTaskPermission] = ["update-task"].map((permission) =>
    axisProPermission(permission)
  );

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    if (itemId) {
      getTaskData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getTaskData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: `crm/task/${itemId}`,
    });
    setInfoData(data);
    setStatusUpdateFormData({
      ...statusUpdateFormData,
      project_id: data.project_id,
      title: data.title,
      description: data.description,
      start_date: data.start_date,
      end_date: data.end_date,
      assigned_to: data.assigned_to,
      collaborators: data.collaborators.map((item) => ({ id: item.user_id })),
      status: "done",
      labels: data.labels.map((item) => ({ id: item.id })),
    });
    setDataProcessing(false);
  };

  const handleCloseInfo = () => {
    navigate(-1);
  };

  const handleTimer = async (e) => {
    e.preventDefault();
    setLoadingTimer(true);
    setShow(false);

    try {
      const response = await axios.post(
        Object.keys(infoData.current_time_tracker).length > 0
          ? `crm/stop-timer/${itemId}`
          : `crm/start-timer/${itemId}`
      );

      if (response.data.success === true) {
        const res = await axios.get("crm/running-tasks-info");

        if (res.data.success === true) {
          const updatedData = {
            ...user,
            running_tasks_count: res.data.data.count,
          };
          dispatch({ type: "UPDATE", payload: { ...updatedData } });
        } else {
          handleRequestError();
        }

        if (response.data.data.end_time) {
          updateInfoData(response.data.data, true);
        } else {
          updateInfoData(response.data.data, false);
        }
      } else {
        handleRequestError();
      }
    } catch (error) {
      handleRequestError(error);
    } finally {
      setLoadingTimer(false);
    }
  };

  const updateInfoData = (data, hasEndTime) => {
    setInfoData((prevData) => ({
      ...prevData,
      total_time_tracked: data.task.total_time_tracked,
      current_time_tracker: hasEndTime
        ? []
        : [...prevData.current_time_tracker, data],
      time_trackers: hasEndTime
        ? prevData.time_trackers.map((tracker) => {
            if (tracker.id === data.id) {
              tracker.end_time = data.end_time;
              tracker.end_time_formatted = data.end_time_formatted;
              tracker.time_tracked = data.time_tracked;
            }
            return tracker;
          })
        : [...prevData.time_trackers, data],
    }));
  };

  const handleRequestError = (error) => {
    if (error && error.response.data.message) {
      const validationError = error.response.data.data?.errors;
      showToast(validationError.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
  };

  useLayoutEffect(() => {
    let assigneeMatch = false;
    let colabMatch = false;
    if (infoData.assigned_to) {
      assigneeMatch = infoData.assigned_to === user.id;
    }
    if (Object.keys(collabList).length > 0) {
      colabMatch = collabList.some((collab) => collab.user_id === user.id);
    }
    setHasMatch(assigneeMatch || colabMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collabList]);

  const handleImageClick = (event) => {
    const clickedImageUrl = event.target.src;
    setPreviewImage(clickedImageUrl);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  return (
    <Offcanvas
      style={{
        width: "70vh",
      }}
      show
      onHide={handleCloseInfo}
      placement="end"
    >
      <>
        {statusLoading && <LoadingOverlay />}
        <Offcanvas.Header className="border-bottom">
          <Offcanvas.Title className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center justify-content-between gap-2">
              <BiTask size={23} />
              <span className="fs-1">Task Overview</span>
              {!dataProcessing && (
                <RedirectButton route={`/tasks/list/overview/${itemId}`} />
              )}
            </div>
            <div>
              {updateTaskPermission && !dataProcessing && (
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      <strong className="fs--2">Edit Task</strong>
                    </Tooltip>
                  }
                >
                  <Button
                    variant="light"
                    size="sm"
                    className="me-2 shadow"
                    as={Link}
                    to={
                      renderFrom === "tasks"
                        ? `/tasks/list/edit/${itemId}?${commonSearchParams}`
                        : renderFrom === "sprints"
                        ? `/sprints/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                        : `/project/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                    }
                  >
                    <GrEdit size={18} className="text-secondary" />
                  </Button>
                </OverlayTrigger>
              )}
              <CloseButton handleClick={handleCloseInfo} />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Header
          className="border-bottom p-0"
          style={{
            backgroundColor: "#384251b5",
            color: "whitesmoke",
          }}
        >
          <Row className="m-0 flex-fill">
            {!dataProcessing ? (
              <>
                <Col md={2} sm={2} xs={2} className="border-end">
                  <div className="task-custom-table-header-div-crm d-flex align-items-center">
                    <span className="mb-1">
                      <FaHashtag size={18} />
                    </span>
                    <span className="fs-1">{infoData.task_number}</span>
                  </div>
                </Col>
                {infoData.assignee ? (
                  <Col
                    md={4}
                    sm={4}
                    xs={4}
                    className="border-end d-flex align-items-center justify-content-center"
                  >
                    <div className="task-custom-table-header-div-crm">
                      <Image
                        className="me-2"
                        src={
                          infoData.assignee && infoData.assignee.image
                            ? infoData.assignee.image
                            : ProfileImage
                        }
                        roundedCircle
                        alt="?"
                        height={35}
                        width={35}
                        loading="lazy"
                      />
                      <div className="d-flex flex-column">
                        <span className="">
                          {infoData.assignee && infoData.assignee.name}
                        </span>
                      </div>
                    </div>
                  </Col>
                ) : (
                  <Col md={4} sm={4} xs={4} className="border-end">
                    <div className="task-custom-table-header-div-crm">
                      <span className="text-mute">
                        <FaUserAltSlash size={18} className="me-1" /> Not
                        assigned
                      </span>
                    </div>
                  </Col>
                )}
                <Col
                  md={3}
                  sm={3}
                  xs={3}
                  className="d-flex align-items-center justify-content-center border-end"
                >
                  <div className="task-custom-table-header-div-crm mt-1">
                    <span className="me-1">
                      {getTaskPriorityWithProps(
                        infoData.priority,
                        "icon",
                        infoData.priority === "High" ? 20 : 25
                      )}
                    </span>
                    <span className="text-capitalize">
                      {infoData?.priority}
                    </span>
                  </div>
                </Col>
                <Col
                  md={3}
                  sm={3}
                  xs={3}
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="task-custom-table-header-div-crm mt-1">
                    <span className="text-mute">
                      {infoData.total_time_tracked}
                    </span>
                  </div>
                </Col>
              </>
            ) : (
              <TabLoading />
            )}
          </Row>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {!dataProcessing && Object.keys(infoData).length > 0 ? (
            <>
              <Row className="mb-3 gy-2">
                <Col md={6}>
                  <span className="d-flex gap-1 flex-wrap justify-content-start align-items-center h-100">
                    {Object.keys(infoData.labels).length > 0 &&
                      infoData.labels.map((labelItem, key) => (
                        <span
                          key={key}
                          className="badge p-2 rounded"
                          style={{
                            backgroundColor: labelItem.colour,
                            fontSize: "11px",
                            cursor: "pointer",
                          }}
                          title={labelItem.description}
                        >
                          {labelItem.label}
                        </span>
                      ))}
                  </span>
                </Col>
                <Col md={6}>
                  <div className="d-flex justify-content-end align-items-center">
                    {hasMatch && (
                      <div className="me-2">
                        <DigitalTimer
                          startTime={
                            infoData?.current_time_tracker[0]?.start_time
                          }
                          handleButtonVisibility={handleShow}
                          loadingTimerButton={loadingTimer}
                          timerRunning={
                            Object.keys(infoData?.current_time_tracker).length >
                            0
                              ? true
                              : false
                          }
                        />
                      </div>
                    )}
                    <TaskStatusButton
                      taskId={infoData.id}
                      status={infoData.status}
                      setStatusLoading={setStatusLoading}
                      setResponseData={setInfoData}
                      refreshFunction={getData}
                    />
                  </div>
                </Col>
              </Row>
              <Card body>
                <div className="d-grid gap-2">
                  <div>
                    <Link
                      className="custom-link-style-crm fs--1 text-uppercase fw-bolder"
                      to={`/project/info/${infoData.project_id}?tab=overview`}
                    >
                      {infoData.project_title}
                    </Link>
                    {infoData?.sprint && (
                      <Link
                        to={`/sprints/info/${
                          infoData?.sprint?.id
                        }?tab=tasklist&${createSearchParams({
                          status_filter: JSON.stringify(
                            defaultTaskStatusFilter
                          ),
                        })}`}
                        className="custom-link-style-crm ms-1"
                      >
                        (
                        <span className="custom-text-muted-crm">
                          Sprint :
                          <span className="text-capitalize">
                            {infoData?.sprint?.name}
                          </span>
                        </span>
                        )
                      </Link>
                    )}
                  </div>
                  <div className="">
                    <span className="fw-bold d-flex flex-row gap-2">
                      <div>
                        <FaBell size={22} color="#ffb23e" />
                      </div>
                      <div>{infoData.title}</div>
                    </span>
                  </div>
                </div>
                <div className="mt-3 d-flex">
                  <div className="">
                    <span className="custom-text-muted-crm">
                      Start Date :
                      {infoData.start_date_formatted
                        ? infoData.start_date_formatted
                        : "N/A"}
                    </span>
                  </div>
                  <div className="ms-auto">
                    <span className="custom-text-muted-crm">
                      End Date :
                      {infoData.end_date_formatted
                        ? infoData.end_date_formatted
                        : "N/A"}
                    </span>
                  </div>
                </div>
                <div className="pt-2 d-flex flex-column">
                  <span className="">
                    <u>Note</u>
                  </span>
                  <span className="fs--2 text-wrap preview-image-wrapper">
                    {showPreview && previewImage && (
                      <ImagePreview
                        previewImage={previewImage}
                        handleClosePreview={handleClosePreview}
                      />
                    )}
                    <ShowHtmlContent
                      description={infoData?.description}
                      handleImageClick={handleImageClick}
                    />
                  </span>
                </div>
                <div className="d-flex justify-content-end">
                  <span className="custom-text-muted-crm">
                    Created By :{infoData.created_user_full_name ?? "N/A"}
                  </span>
                </div>
              </Card>
              <Tabs
                id="common-custom-tabs-crm"
                defaultActiveKey="comments"
                className="mb-3 mt-3 common-custom-tabs-crm"
                justify
              >
                <Tab className="pb-3" eventKey="comments" title="Comments">
                  <Comments comment_type="task" id={itemId} />
                </Tab>
                <Tab
                  className="pb-3"
                  eventKey="collaborators"
                  title="Collaborators"
                >
                  <Collaborator
                    taskId={infoData.id}
                    scroll={false}
                    assignee_id={infoData.assigned_to}
                    setCollabList={setCollabList} //for collab list for return
                  />
                </Tab>
                <Tab className="pb-3" eventKey="activity" title="Activity">
                  <ActivityTimeLine infoData={infoData} />
                </Tab>
                {infoData.time_trackers.length > 0 && (
                  <Tab
                    className="pb-3"
                    eventKey="time_sheet"
                    title="Time Sheet"
                  >
                    <TimeSheetLog infoData={infoData} />
                  </Tab>
                )}
              </Tabs>
            </>
          ) : (
            <div className="h-100 d-flex align-items-center justify-content-center">
              <ListLoading />
            </div>
          )}
          <TimerConfirm
            show={show}
            handleClose={handleClose}
            infoData={infoData}
            handleTimer={handleTimer}
            timerRunningTask={infoData.id}
          />
        </Offcanvas.Body>
      </>
    </Offcanvas>
  );
};

export default TaskInfo;
