import { BsExclamationDiamondFill } from "react-icons/bs";
import {
  FcHighPriority,
  FcLowPriority,
  FcMediumPriority,
} from "react-icons/fc";
const getTaskPriorityWithProps = (priority, type, iconSize = 25) => {
  const priorityWithColor = {
    Low: { value: "#4caf50", icon: <FcLowPriority size={iconSize} /> },
    Medium: { value: "#ffc107", icon: <FcMediumPriority size={iconSize} /> },
    High: {
      value: "#ff7e4c",
      icon: <BsExclamationDiamondFill size={iconSize} color="#ff7e4c" />,
    },
    Critical: { value: "#f44336", icon: <FcHighPriority size={iconSize} /> },
  };

  switch (type) {
    case "icon":
      return priorityWithColor[priority]?.icon;
    default:
      return priorityWithColor[priority]?.value ?? "grey";
  }
};

export default getTaskPriorityWithProps;
