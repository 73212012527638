import React, { useEffect } from "react";
import logo from "assets/img/logos/Accodax/Asset 2.svg";
import "./asset/style/AuthScreen.scss";
import { Card } from "react-bootstrap";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import "./AuthOutlet.scss";
import { MdOutlineLockReset } from "react-icons/md";
const AuthOutlet = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const loginAs = queryParams.get("as");
  let token = searchParams.get("token");
  const location = useLocation();
  const isResetPassword = location.pathname.includes(
    "authentication/reset-password"
  );

  useEffect(() => {
    if (!loginAs && (!isResetPassword || !token)) {
      navigate(`/authentication/login?as=user`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleChange = (e) => {
    navigate(`${location.pathname}?as=${e.target.value}`);
  };

  return (
    <div className="auth-screen-wrapper d-flex flex-column justify-content-center align-items-center">
      <div className="brand-logo">
        <img
          src={logo}
          alt="logo"
          className="mb-3"
          style={{ width: "15rem" }}
        />
      </div>

      <Card className={`shadow p-3 form-container rounded px-0`}>
        <Card.Header className="d-flex flex-row border-bottom justify-content-around tab-area">
          <div className="container-custom-login">
            {!isResetPassword ? (
              <div className="switches-container-custom-login">
                <input
                  className="custom-login-switch"
                  type="radio"
                  id="switchUser"
                  name="switchPlan"
                  value="user"
                  checked={loginAs === "user"}
                  onChange={handleRoleChange}
                />
                <input
                  className="custom-login-switch"
                  type="radio"
                  id="switchClient"
                  name="switchPlan"
                  value="client"
                  checked={loginAs === "client"}
                  onChange={handleRoleChange}
                />
                <label className="label-custom-login" htmlFor="switchUser">
                  User
                </label>
                <label className="label-custom-login" htmlFor="switchClient">
                  Client
                </label>
                <div className="switch-wrapper-custom-login">
                  <div className="switch-custom-login">
                    <div className="slide-custom-login">User</div>
                    <div className="slide-custom-login">Client</div>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="w-100 d-flex justify-content-center align-items-center gap-1"
                style={{ color: "#6AABAC" }}
              >
                <MdOutlineLockReset size={27} />
                <span className="fs-1">Reset Password</span>
              </div>
            )}
          </div>
        </Card.Header>
        <Card.Body>
          <Outlet />
        </Card.Body>
      </Card>
    </div>
  );
};

export default AuthOutlet;
