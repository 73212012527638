import PropTypes from "prop-types";
import "./TimeSheetLog.scss";
import { Col, Row } from "react-bootstrap";

const TimeSheetLog = ({ infoData }) => {
  return (
    <Row
      className="d-flex flex-column mt-70 mb-70  "
      style={{ maxHeight: "30rem", overflow: "hidden", overflowY: "scroll" }}
    >
      <Col>
        <table className="table table-striped table-bordered">
          <thead>
            <tr className="table-secondary">
              <th className="m-0">Name</th>
              <th className="m-0">Start Time</th>
              <th className="m-0">End Time</th>
              <th className="m-0">Time Difference</th>
            </tr>
          </thead>
          <tbody>
            {infoData.time_trackers.map((items, index) => {
              return (
                <tr key={index}>
                  <td>{items.created_user_full_name ?? "--"}</td>
                  <td>{items.start_time_formatted ?? "--"}</td>
                  <td>{items.end_time_formatted ?? "--"}</td>
                  <td>{items.time_tracked ?? "--"}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};
TimeSheetLog.prototype = {
  infoData: PropTypes.any,
};
export default TimeSheetLog;
