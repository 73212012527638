import { AuthWizardContext } from "context/Context";
import generateIconComponent from "helpers/generateIconComponent";
import React, { useContext } from "react";
import { Accordion, ListGroup, Offcanvas } from "react-bootstrap";
import { AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";

const ReportOffcanvas = ({ show, handleCanvas }) => {
  const { user } = useContext(AuthWizardContext);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );

  const projectReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "project-report"
  );
  const projectDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/project-report"
    );
  const sprintDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/sprint-detailed-report"
    );

  const taskReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "task-report"
  );

  const taskDetailedReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/task-report"
  );

  const runningOrCompletedTasksReportPermission =
    taskReportPermissions?.children?.find(
      (item) => item?.to === "reports/running-completed-tasks-report"
    );

  const timeTrackerReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/time-tracker-report"
  );

  const employeeReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "employee-report"
  );

  const employeeTaskReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-report"
    );

  const employeeTaskSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-summary-report"
    );

  const employeeLeadSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-lead-report"
    );

  const leadReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "lead-report"
  );

  const leadDetailedReportPermission = leadReportPermissions?.children?.find(
    (item) => item?.to === "reports/leads-report"
  );

  const leadConversationReportPermission =
    leadReportPermissions?.children?.find(
      (item) => item?.to === "reports/lead-conversion-report"
    );

  const leadSourceReportPermission =
    leadReportPermissions?.children?.find(
      (item) => item?.to === "reports/lead-source-summary-report"
    );

  const opportunityReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "opportunity-report"
  );

  const opportunityDetailedReportPermission =
    opportunityReportPermissions?.children?.find(
      (item) => item?.to === "reports/opportunity-report"
    );

  return (
    <Offcanvas show={show} onHide={handleCanvas}>
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title className="fs-0 fw-bold">
          {generateIconComponent(reportPermissions?.icon, 27)}
          <span> {reportPermissions?.name}</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0">
        <Accordion
          defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
          alwaysOpen
          className="custom-acccordion-reports"
        >
          {projectReportPermissions && (
            <Accordion.Item
              eventKey="0"
              className="custom-acccordion-item-reports"
            >
              <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                <div className="d-flex align-items-center">
                  {generateIconComponent(
                    projectReportPermissions?.icon,
                    projectReportPermissions?.icon_size,
                    "#e63757"
                  )}
                  <span
                    className="text-uppercase fs--1 custom-acccordion-header-span-reports ms-2"
                    style={{ color: "#6F7275" }}
                  >
                    {projectReportPermissions?.name}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  <ListGroup.Item className="border-0">
                    <Link
                      className="d-flex align-items-center text-muted text-decoration-none"
                      to={`/${projectDetailedReportPermission?.to}`}
                    >
                      <AiOutlineStar size={20} />
                      <span className="ms-1 fs--6">
                        {projectDetailedReportPermission?.name}
                      </span>
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item className="border-0">
                    <Link
                      className="d-flex align-items-center text-muted text-decoration-none"
                      to={`/${sprintDetailedReportPermission?.to}`}
                    >
                      <AiOutlineStar size={20} />
                      <span className="ms-1 fs--6">
                        {sprintDetailedReportPermission?.name}
                      </span>
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {taskReportPermissions && (
            <Accordion.Item
              eventKey="1"
              className="custom-acccordion-item-reports"
            >
              <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                <div className="d-flex align-items-center">
                  {generateIconComponent(
                    taskReportPermissions?.icon,
                    taskReportPermissions?.icon_size,
                    "#9a5cfe"
                  )}
                  <span
                    className="text-uppercase fs--1 custom-acccordion-header-span-reports ms-2"
                    style={{ color: "#6F7275" }}
                  >
                    {taskReportPermissions?.name}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {taskDetailedReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${taskDetailedReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {taskDetailedReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {timeTrackerReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${timeTrackerReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {timeTrackerReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {runningOrCompletedTasksReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${runningOrCompletedTasksReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {runningOrCompletedTasksReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {employeeReportPermissions && (
            <Accordion.Item
              eventKey="2"
              className="custom-acccordion-item-reports"
            >
              <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                <div className="d-flex align-items-center">
                  {generateIconComponent(
                    employeeReportPermissions?.icon,
                    employeeReportPermissions?.icon_size,
                    "#27c6fd"
                  )}
                  <span
                    className="text-uppercase fs--1 custom-acccordion-header-span-reports ms-2"
                    style={{ color: "#6F7275" }}
                  >
                    {employeeReportPermissions?.name}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {employeeTaskReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${employeeTaskReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {employeeTaskReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {employeeTaskSummaryReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${employeeTaskSummaryReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {employeeTaskSummaryReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {employeeLeadSummaryReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${employeeLeadSummaryReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {employeeLeadSummaryReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          )}
          {opportunityReportPermissions && (
            <Accordion.Item
              eventKey="3"
              className="custom-acccordion-item-reports"
            >
              <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                <div className="d-flex align-items-center">
                  {generateIconComponent(
                    opportunityReportPermissions?.icon,
                    opportunityReportPermissions?.icon_size,
                    "#ff9751"
                  )}
                  <span
                    className="text-uppercase fs--1 custom-acccordion-header-span-reports ms-2"
                    style={{ color: "#6F7275" }}
                  >
                    {opportunityReportPermissions?.name}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                {opportunityDetailedReportPermission && (
                  <ListGroup>
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${opportunityDetailedReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {opportunityDetailedReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {leadReportPermissions && (
            <Accordion.Item
              eventKey="4"
              className="custom-acccordion-item-reports"
            >
              <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                <div className="d-flex align-items-center">
                  {generateIconComponent(
                    leadReportPermissions?.icon,
                    leadReportPermissions?.icon_size,
                    "#4d5969"
                  )}
                  <span
                    className="text-uppercase fs--1 custom-acccordion-header-span-reports ms-2"
                    style={{ color: "#6F7275" }}
                  >
                    {leadReportPermissions?.name}
                  </span>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <ListGroup>
                  {leadDetailedReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${leadDetailedReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {leadDetailedReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {leadConversationReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${leadConversationReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {leadConversationReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                  {leadSourceReportPermission && (
                    <ListGroup.Item className="border-0">
                      <Link
                        className="d-flex align-items-center text-muted text-decoration-none"
                        to={`/${leadSourceReportPermission?.to}`}
                      >
                        <AiOutlineStar size={20} />
                        <span className="ms-1 fs--6">
                          {leadSourceReportPermission?.name}
                        </span>
                      </Link>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          )}
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ReportOffcanvas;
