import { React, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";
import Invitation from "module/common/Invitation/Invitation";
import { Button } from "react-bootstrap";

const MultiSelectCollaborator = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  excludeUser,
  assignee_id,
}) => {
  const [showInvitation, setInvitation] = useState(false);
  const handleInvite = () => setInvitation(!showInvitation);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    loadOptions(value).then((loadedOptions) => {
      setOptions(loadedOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeUser]);

  const loadOptions = (inputValue) => {
    return new Promise(async (resolve) => {
      let response = await axios.get("user/users-autocomplete", {
        params: {
          exclude_user: typeof excludeUser === "string" ? excludeUser : "",
        },
      });
      let data = response && response.data ? response.data.data : [];

      let filteredUsers = [];

      if (typeof excludeUser === "object") {
        let tempExcludeUsers = new Set(
          excludeUser.map(({ user_id }) => user_id)
        );
        //add Assignee to the excluded list
        assignee_id && tempExcludeUsers.add(assignee_id);
        filteredUsers = data.filter(({ id }) => !tempExcludeUsers.has(id));
      } else {
        filteredUsers = data;
      }

      const loadedOptions = filteredUsers.map((item) => ({
        label: item.full_name,
        value: item.id,
      }));

      resolve(loadedOptions);
    });
  };

  const NoOptionsMessage = ({ inputValue }) => {
    value = inputValue;
    return (
      <div className="d-flex justify-content-between">
        <span className="">No options</span>
        <Button
          variant="transparant"
          className="p-0 custom-transperent-common-button"
          onClick={handleInvite}
          size="sm"
        >
          <span className="text-primary">Invite</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <Select
        isMulti
        cacheOptions
        options={options}
        loadOptions={loadOptions}
        defaultOptions
        isClearable
        name={name ? name : "Select"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        isSearchable={true}
        className={`custom-select text-capitalize ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: "fit-content",
            minHeight: 32,
            ...style,
          }),
        }}
        noOptionsMessage={NoOptionsMessage}
      />
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

MultiSelectCollaborator.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default MultiSelectCollaborator;
