import ListLoading from "module/common/ListLoading";
import { Button, Card, Col, Image, Row } from "react-bootstrap";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const ClientProfileSection = ({ client, isLoading }) => {
  const navigate = useNavigate();
  return (
    <>
      {!isLoading ? (
        <Card className="h-100 flex-fill">
          <Card.Header className="mt-2">
            <div className="d-flex align-items-center">
              <div
                className="text-light p-1 rounded-5 ps-2 pe-1 cursor-pointer"
                style={{ backgroundColor: "#E8EFFE" }}
                as={Button}
                onClick={() => {
                  navigate("/account/list");
                }}
              >
                <BiArrowBack size={18} style={{ color: "#3F74E4" }} />
              </div>
              <p className="mb-0 fw-bold ms-2">Account Overview</p>
            </div>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={12}>
                <div className="d-flex justify-content-start">
                  <Image
                    className="rounded-4"
                    width={"120vh"}
                    height={"120vh"}
                    src={client?.image}
                    loading="lazy"
                  />
                </div>
              </Col>
              <div className="client-name h1 fs-1 fw-bold mt-4 text-capitalize">
                {client?.name}
              </div>
              <div className="d-flex mt-3 align-items-center">
                <div className="fw-bold text-muted">Account Details</div>
              </div>
              <Row className="mt-2">
                <Col md={6} className="text-secondary">
                  Account ID
                </Col>
                <Col md={6} className="text-primary ">
                  {client?.customer_id}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6} className="text-secondary">
                  Email
                </Col>
                <Col md={6} className="text-primary ">
                  {client?.email}
                </Col>
              </Row>

              <Row className="mt-2">
                <Col md={6} className="text-secondary">
                  Phone Number
                </Col>
                <Col md={6} className="text-primary">
                  {client?.phone}
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md={6} className="text-secondary">
                  Emirates ID
                </Col>
                <Col md={6}>{client?.emirates_id ?? "--"}</Col>
              </Row>
              <Row className="mt-2">
                <Col md={6} className="text-secondary">
                  Address
                </Col>
                <Col md={6}>{client?.address ?? "--"}</Col>
              </Row>
              <div className="d-flex mt-3 align-items-center">
                <div className="fw-bold text-muted">Contacts</div>
              </div>
              <div
                style={{
                  height: "34vh",
                  maxHeight: "34vh",
                  overflow: "hidden",
                  overflowY: "scroll",
                }}
              >
                {client?.contacts?.length > 0 ? (
                  client?.contacts.map((items, index) => {
                    return (
                      <div key={index} className="border-bottom pb-3">
                        <Row className="mt-2">
                          <Col md={6} className="text-secondary">
                            Name
                          </Col>
                          <Col md={6}>{items.contact_name}</Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={6} className="text-secondary">
                            Phone
                          </Col>
                          <Col md={6}>{items.contact_mobile}</Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={6} className="text-secondary">
                            Email
                          </Col>
                          <Col md={6}> {items.contact_email}</Col>
                        </Row>
                        <Row className="mt-2">
                          <Col md={6} className="text-secondary">
                            Address
                          </Col>
                          <Col md={6}> {items.contact_address}</Col>
                        </Row>
                      </div>
                    );
                  })
                ) : (
                  <div className=" d-flex justify-content-center align-items-center">
                    <span>No Contact Added Yet &#128528;</span>
                  </div>
                )}
              </div>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <Card>
          <ListLoading style={{ height: "89vh", overflow: "hidden" }} />
        </Card>
      )}
    </>
  );
};
export default ClientProfileSection;
