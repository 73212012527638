import axios from "axios";
import FileController from "components/file-controller/FileController";
import { DeleteContext } from "context/Context";
import { attachmentsFormKeys } from "helpers/formKeys";
import setNewFormData from "helpers/setNewFormData";
import TabLoading from "module/common/TabLoading";
import { showToast } from "module/common/Toast/toast";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  OverlayTrigger,
  Popover,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { AiFillFileUnknown } from "react-icons/ai";
import {
  FaCloudDownloadAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const CommonAttachments = ({
  itemId,
  type,
  attachmentControl = true,
  itemsClassName = "",
}) => {
  const [formData, setFormData] = useState(attachmentsFormKeys);
  const [loading, setLoading] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);
  const [fileData, setFileData] = useState([]);
  const { dispatch } = useContext(DeleteContext);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: `/documents/${id}`,
        title: "Delete Attachment",
        message: "Are you sure you want to delete this Attachment?",
        onSuccess: () => {
          fetchData();
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`documents?subject_type=${type}&subject_id=${itemId}`)
      .then((res) => {
        if (res.data.success) {
          setFileData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFiles = (files) => {
    handleFieldChange({ target: { name: "files", value: files } });
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let fieldName;
    let fieldValue;
    if (!action) {
      value = e.target.value;

      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      fieldName = action.name.replace("_id", "_name");
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        fieldValue = e.label ? e.label : "";
      }
      if (action.action === "clear") {
        fieldValue = null;
      }
      newFormData = {
        ...formData,
        [fieldName]: fieldValue,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    formData.type = type;
    formData.id = itemId;
    e.preventDefault();
    axios({
      method: "post",
      url: `documents`,
      data: setNewFormData(formData),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (response.data.success === true) {
        fetchData();
        setResetFiles(!resetFiles);
        setFormData(attachmentsFormKeys);
        showToast(response.data.message, "success");
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      }
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <Card className="d-flex shadow-none">
          <Card.Header
            className={`${
              type === "OPPORTUNITY" ? "rounded-0 pb-2 pt-2" : "border-bottom"
            }`}
            style={{ backgroundColor: type === "OPPORTUNITY" ? "#d2d4e9" : "" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="">ATTACHMENTS INFORMATION</div>
            </div>
          </Card.Header>
          <Card.Body
            className={`rounded-0`}
            style={{
              height: type === "OPPORTUNITY" ? "64.7vh" : "72vh",
              overflow: "auto",
            }}
          >
            <ListGroup>
              {attachmentControl && (
                <ListGroupItem className="rounded-0">
                  <Form.Group controlId="formFile" className="mb-3">
                    {formData.files.length > 0 && (
                      <Form.Label>Recently Added Files</Form.Label>
                    )}
                    <FileController
                      limit={5}
                      limitFeature={true}
                      limitFileSizeInMB={5}
                      limitFileType={[
                        "jpg",
                        "png",
                        "jpeg",
                        "gif",
                        "pdf",
                        "excel",
                        "xlsx",
                        "docx",
                        "doc",
                        "xls",
                        "csv",
                      ]}
                      onChange={handleFiles}
                      className="my-3"
                      note="You can upload a maximum of 5 files, 5MB each"
                      clearFiles={resetFiles}
                    />

                    <Button
                      variant="success"
                      size="sm"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                      disabled={formData?.files?.length > 0 ? false : true}
                    >
                      Upload
                    </Button>
                  </Form.Group>
                </ListGroupItem>
              )}

              {fileData?.length > 0 && !loading ? (
                fileData.map((attachment, key) => (
                  <Fragment key={key}>
                    {key === 0 && (
                      <ListGroupItem className={`rounded-0 ${itemsClassName}`}>
                        <label>Existing Files</label>
                      </ListGroupItem>
                    )}
                    <ListGroupItem className={`rounded-0 ${itemsClassName}`}>
                      <div className="d-flex">
                        <div className="me-3">
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={
                              <Popover className="p-3">
                                <div className="d-flex flex-column">
                                  <span className="text-mute custom-text-muted-crm">
                                    File Size: {attachment.human_readable_size}
                                  </span>
                                  <span className="text-mute custom-text-muted-crm">
                                    Updated at: {attachment.updated_at}
                                  </span>
                                  <span className="text-mute custom-text-muted-crm">
                                    Last Updated: {attachment.last_updated}
                                  </span>
                                </div>
                              </Popover>
                            }
                          >
                            <Button
                              size="sm"
                              className="p-0 custom-transperent-common-button"
                              variant="transparent"
                            >
                              {attachment.file_name.split(".").pop() ===
                                "png" ||
                              attachment.file_name.split(".").pop() ===
                                "jpg" ? (
                                <FaFileImage size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "pdf" ? (
                                <FaFilePdf color="#fb3939b8" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                  "xls" ||
                                attachment.file_name.split(".").pop() ===
                                  "xlsx" ? (
                                <FaFileExcel color="#007f3eb5" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "csv" ? (
                                <FaFileCsv color="#41a754cc" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "doc" ? (
                                <FaFileWord color="#285293" size={38} />
                              ) : (
                                <AiFillFileUnknown size={38} />
                              )}
                            </Button>
                          </OverlayTrigger>
                        </div>
                        <div className="d-flex flex-column">
                          <span className="fw-bold">
                            {attachment.file_name.length > 65
                              ? attachment.file_name.substring(0, 65) +
                                "." +
                                attachment.file_name.split(".").pop()
                              : attachment.file_name}
                          </span>
                          <div>
                            <span className="text-mute custom-text-muted-crm">
                              {attachment.created_at}
                            </span>
                            <span className="text-mute custom-text-muted-crm ms-2">
                              {attachment.created}
                            </span>
                          </div>
                        </div>

                        <div className="ms-auto">
                          <Button
                            as={Link}
                            target="_blank"
                            to={attachment.file_url}
                            variant="transparent"
                            size="sm"
                            className="custom-transperent-common-button p-0"
                          >
                            <FaCloudDownloadAlt color="#718dd6" size={18} />
                          </Button>
                          {attachmentControl && (
                            <Button
                              variant="transparent"
                              size="sm"
                              className="custom-transperent-common-button p-0 ms-2"
                              onClick={() => {
                                handleDelete(attachment.id);
                              }}
                            >
                              <FaTrash color="#dc3939" />
                            </Button>
                          )}
                        </div>
                      </div>
                    </ListGroupItem>
                  </Fragment>
                ))
              ) : loading ? (
                <TabLoading />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center "
                  style={{ height: "10rem" }}
                >
                  <span className="">No Attachments Yet &#128521;</span>
                </div>
              )}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CommonAttachments;
