import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { invitationFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import { MdOutlineContactPhone } from "react-icons/md";
import ListLoading from "module/common/ListLoading";
import SelectRole from "components/form/SelectRole";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import generateFormDataHelper from "helpers/generateFormDataHelper";

const InvitationForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { invId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(invitationFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);
    axios({
      method: "put",
      url: `crm/invitation/${invId}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");

          navigate(invId ? -1 : "/invitation");
          (!filterItems || invId) && getData(pageNumber ?? 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (invId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/invitation/" + invId,
    });

    setFormData({
      email: data?.to_email,
      role_id: data?.role?.id,
      role_id_ref: {
        label: data?.role?.name,
        value: data?.role?.id,
      },
      expiring_time: data?.expiring_time,
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <MdOutlineContactPhone size={23} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {"EDIT INVITATIONS"}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleFieldChange}
                value={formData?.email}
                placeholder="Enter Email"
                isInvalid={!!formError?.email}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Role</Form.Label>
              <SelectRole
                name="role_id"
                handleFieldChange={handleFieldChange}
                value={formData?.role_id_ref}
                error={formError?.role_id}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.role_id}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Expiring Time</Form.Label>
              <AppTimePicker
                name="expiring_time"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                onChange={handleFieldChange}
                value={formData?.expiring_time}
                isInvalid={!!formError?.expiring_time}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.expiring_time}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={invId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InvitationForm;
