import React from "react";
import { Line } from "react-chartjs-2";

const LineChart = ({ data, options }) => {
  return (
    <Line
      data={data}
      options={options}
      style={{ MinHeight: "100%", minWidth: "100%" }}
    />
  );
};

export default LineChart;
