import SelectCustomer from "components/form/SelectCustomer";
import SelectDepartment from "components/form/SelectDepartment";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import SelectTicketStatus from "components/form/SelectTicketStatus";
import SelectUsers from "components/form/SelectUsers";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const ticketFilters = {
  title: {
    label: "Title",
    operators: commonFilterOperators,
    active: true,
  },
  customer_id: {
    label: "Customer",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectCustomer
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  ticket_type_id: {
    label: "Type",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectDepartment
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  priority: {
    label: "Priority",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) => (item && item.operator === "=" ? "SELECT" : "STRING"),
    options: [
      { label: "LOW", value: "low" },
      { label: "MEDIUM", value: "medium" },
      { label: "HIGH", value: "high" },
    ],
  },
  ticket_origin: {
    label: "Origin",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectTicketOrigin
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  assigned_to: {
    label: "Assginee",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectUsers
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  status: {
    label: "Status",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectTicketStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

const ticketTypeFilters = {
  title: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
  },
};

export { ticketFilters, ticketTypeFilters };
