import { React, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Dropdown } from "react-bootstrap";
import { AuthWizardContext } from "context/Context";
import Avatar from "components/common/Avatar";
import { showToast } from "module/common/Toast/toast";
import defaultImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import { RiAccountPinBoxFill } from "react-icons/ri";
import { BiLogOut } from "react-icons/bi";

const ProfileDropdown = () => {
  let navigate = useNavigate();
  const { dispatch, user } = useContext(AuthWizardContext);
  const handleLogout = () => {
    axios
      .post("auth/logout")
      .then((res) => {
        if (res.data.success === true) {
          // remove user data from localStorage
          localStorage.removeItem("AXIS_PRO_CRM_USER");
          // delete user details from context
          dispatch({ type: "DELETE" });
          showToast(res.data.message, "success");
          // redirect user to login screen
          navigate("/authentication/login");
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      })
      .catch((error) => {
        if (error && error.response && error.response.status === 401) {
          // remove user data from localStorage
          localStorage.removeItem("AXIS_PRO_CRM_USER");
          // delete user details from context
          dispatch({ type: "DELETE" });
          // redirect user to login screen
          navigate("/authentication/login");
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
      });
  };
  return (
    <Dropdown navbar={true} as="li" className="me-2">
      <Dropdown.Toggle
        bsPrefix="toggle"
        variant="transparent"
        className="custom-transperent-common-button p-0 shadow-none"
        title="User Profile"
      >
        {<Avatar src={user?.image ?? defaultImage} />}
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item className="d-flex gap-1 align-items-center">
            <RiAccountPinBoxFill style={{ width: "20px", height: "20px" }} />
            {user?.name ?? ""}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item
            className="d-flex gap-1 align-items-center"
            onClick={handleLogout}
          >
            <BiLogOut style={{ width: "20px", height: "20px" }} />
            Logout
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
