import React from "react";
import { Link, Outlet, createSearchParams, useParams } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import useAxisproPermission from "hooks/useAxisproPermission";
import Logo from "assets/img/logos/Accodax-3.png";
import "./Grid.scss";
import { RiEyeFill } from "react-icons/ri";
import { MdModeEdit } from "react-icons/md";
import SimpleBar from "simplebar-react";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";

function Grid({ getData, data, allQueryParams, handleDelete, renderFrom }) {
  const axisProPermission = useAxisproPermission();
  const { itemInfoId } = useParams();
  const fromProject = renderFrom === "project";
  const fromAccount = renderFrom === "account";

  return (
    <>
      <Outlet context={[getData]} />
      {!itemInfoId ? (
        data?.data?.length > 0 ? (
          <>
            <SimpleBar className="simplebar-style-with-pagination">
              <div className="d-flex gap-3 flex-wrap p-3">
                {data?.data?.map((el, index) => (
                  <Card
                    style={{
                      width: "16rem",
                      backgroundColor: "whitesmoke",
                    }}
                    className="common-grid-card-main-body"
                    key={index}
                  >
                    <Card.Header className="account-card-header p-0 ps-1 pe-4 mb-1 mt-2">
                      <Card.Img
                        variant="top"
                        className="account-card-header-img"
                        src={el?.logo ? el?.logo : Logo}
                      />
                    </Card.Header>
                    <Card.Body
                      className="common-grid-card-body
               d-flex align-items-center flex-column p-1 pt-2 text-decoration-none"
                      as={Link}
                      to={
                        fromProject
                          ? `info/${el.id}?tab=overview`
                          : `/account/overview/${el.id}?tab=overview`
                      }
                    >
                      <div className="p-2 mb-2 w-100 text-center">
                        <h1 className="fw-bolder mb-0 text-uppercase">
                          {fromProject ? el?.title : el?.name}
                        </h1>
                      </div>
                      <Row className="justify-content-center  w-100 mb-2 ">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 opacity-75">
                            {fromProject ? "Project Code:" : "Customer Id:"}
                          </h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          <h1 className="m-0 fw-bold ms-2">
                            {fromProject ? el?.project_code : el?.customer_id}
                          </h1>
                        </Col>
                      </Row>
                      <Row className="justify-content-center w-100 mb-2">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 opacity-75">
                            {fromProject ? "Start Date:" : "Email:"}
                          </h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          <h1 className="m-0 fw-bold">
                            {fromProject
                              ? el?.start_date_formated
                              : el?.email
                              ? el?.email
                              : "N/A"}
                          </h1>
                        </Col>
                      </Row>
                      <Row className="justify-content-center  w-100 mb-2">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 opacity-75">
                            {fromProject ? "End Date:" : "Phone:"}
                          </h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          <h1 className="m-0 fw-bold">
                            {fromProject ? el?.end_date_formated : el?.phone}
                          </h1>
                        </Col>
                      </Row>
                      <Row className="justify-content-center  w-100 mb-2">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 opacity-75">
                            {fromProject ? "Price:" : "Type:"}
                          </h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          <h1 className="m-0 fw-bold">
                            {fromProject
                              ? el?.price_formatted
                              : el?.customer_type}
                          </h1>
                        </Col>
                      </Row>
                      {fromAccount && (
                        <Row className="justify-content-center  w-100 mb-2">
                          <Col xs={5} className="p-0">
                            <h1 className="m-0 opacity-75">Currency:</h1>
                          </Col>
                          <Col xs={6} className="p-0">
                            <h1 className="m-0 fw-bold">
                              {el?.currency_code ? el?.currency_code : "N/A"}
                            </h1>
                          </Col>
                        </Row>
                      )}
                      <Row className="justify-content-center  w-100 mb-2">
                        <Col xs={5} className="p-0">
                          <h1 className="m-0 opacity-75">Status:</h1>
                        </Col>
                        <Col xs={6} className="p-0">
                          {fromProject ? (
                            <h1 className="m-0">
                              <Badge
                                className="text-uppercase rounded fw-bolder"
                                bg={"warning"}
                              >
                                {el?.status}
                              </Badge>
                            </h1>
                          ) : (
                            <h1 className="m-0">
                              <Badge
                                className="text-uppercase rounded fw-bolder"
                                bg={el?.inactive !== 0 ? "warning" : "success"}
                              >
                                {el?.inactive !== 0 ? "Inactive" : "Active"}
                              </Badge>
                            </h1>
                          )}
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer
                      style={{
                        backgroundColor: "#d5d5d5b8",
                      }}
                      className="p-1 d-flex align-items-center 
      justify-content-evenly mt-2 mb-2 rounded m-2"
                    >
                      {axisProPermission(
                        fromProject ? "show-project" : "show-customer"
                      ) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip style={{ fontSize: 11 }}>
                              Click here to view!
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="transparent"
                            className="text-dark  account-card-action-btn account-card-view"
                            as={Link}
                            to={
                              fromProject
                                ? `info/${el.id}?tab=overview`
                                : `/account/overview/${el.id}?tab=overview`
                            }
                          >
                            <RiEyeFill color="#4a79b7" size={18} />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {axisProPermission(
                        fromProject ? "update-project" : "update-customer"
                      ) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip style={{ fontSize: 11 }}>
                              Click here to Edit!
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="transparent"
                            className="account-card-action-btn account-card-edit"
                            as={Link}
                            to={
                              fromProject
                                ? `edit/${el?.id}?${createSearchParams({
                                    ...allQueryParams,
                                  })}`
                                : `/account/edit/${el?.id}?${createSearchParams(
                                    {
                                      ...allQueryParams,
                                      view: "grid",
                                    }
                                  )}`
                            }
                          >
                            <MdModeEdit color="#e96624" size={20} />
                          </Button>
                        </OverlayTrigger>
                      )}
                      {axisProPermission(
                        fromProject ? "delete-project" : "delete-customer"
                      ) && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip style={{ fontSize: 11 }}>
                              Click here to delete!
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="transparent"
                            className="account-card-action-btn account-card-delete"
                            onClick={() => handleDelete(el?.id)}
                          >
                            <FaTrash color="#d52525" size={15} />
                          </Button>
                        </OverlayTrigger>
                      )}
                    </Card.Footer>
                  </Card>
                ))}
              </div>
            </SimpleBar>
            <div className="border-top p-1 pt-3">
              {data && data.links && (
                <AppPagination
                  data={data}
                  url={fromProject ? "/project" : "/account/grid"}
                  search={allQueryParams}
                />
              )}
            </div>
          </>
        ) : (
          <LoadingScreenCover style={{ height: "70vh" }}>
            <h5 className="text-center text-secondary">
              {fromProject ? "Project" : "Account"} not found! Let's add it
              &#128578;
            </h5>
            <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
              {axisProPermission(
                fromProject ? "store-project" : "create-customer"
              ) && (
                <AddButton
                  to={
                    fromProject
                      ? `add?${createSearchParams({
                          ...allQueryParams,
                        })}`
                      : `/account/add?${createSearchParams({
                          ...allQueryParams,
                          view: "grid",
                        })}`
                  }
                  variant="success"
                  title={`Add new ${fromProject ? "project" : "account"}`}
                  label={fromProject ? "Add Project" : "Add Account"}
                >
                  {fromProject ? "Add Project" : "Add Account"}
                </AddButton>
              )}
            </div>
          </LoadingScreenCover>
        )
      ) : (
        ""
      )}
    </>
  );
}

export default Grid;
