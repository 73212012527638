import SelectCustomer from "components/form/SelectCustomer";
import SelectProject from "components/form/SelectProject";
import SelectProjectStatus from "components/form/SelectProjectStatus";
import ColumnSelector from "module/common/Filter/ColumnSelector";
import DateRangeFilter from "module/common/Filter/DateRangeFilter";
import React from "react";
import { Form } from "react-bootstrap";

const ProjectDetailedReportForm = ({
  columns,
  filters,
  setFilters,
  handleFilterChange,
}) => {
  return (
    <div>
      <div>
        <ColumnSelector
          columns={columns}
          filters={filters}
          setFilters={setFilters}
          defaultOption={true}
          value={filters.date_range_for}
        />
      </div>
      <div className="mt-3">
        <DateRangeFilter filters={filters} setFilters={setFilters} />
      </div>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Project</Form.Label>
        <SelectProject
          name="project_id"
          handleFieldChange={handleFilterChange}
          value={filters.project_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Client</Form.Label>
        <SelectCustomer
          name="customer_id"
          handleFieldChange={handleFilterChange}
          value={filters.customer_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Status</Form.Label>
        <SelectProjectStatus
          name="status"
          handleFieldChange={handleFilterChange}
          value={filters.status_ref}
        />
      </Form.Group>
    </div>
  );
};

export default ProjectDetailedReportForm;
