import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonLeadSourceFilterFormKeys } from "helpers/formKeys";

const CommonLeadSourceFilter = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(
    commonLeadSourceFilterFormKeys
  );
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { name } = allQueryParams;

  const handleFieldChange = (e, action) => {
    if (!action) {
      setFilterFields({ ...filterFields, [e.target.name]: e.target.value });
    }
  };

  const handleFilterApply = () => {
    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      name: name ? name : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleResetFilter = () => {
    setFilterFields(commonLeadSourceFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Name</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleFieldChange}
                  value={filterFields.name}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={10} className="d-flex justify-content-end">
          <Form.Group
            as={Col}
            md={2}
            className="d-flex justify-content-end w-100 p-0"
          >
            <div className="d-flex align-items-end gap-2">
              <Button
                size="sm"
                variant="warning"
                onClick={handleFilterApply}
                title="Apply Added Filters"
              >
                Apply
              </Button>
              <Button
                size="sm"
                variant="transparent"
                className="shadow-sm"
                onClick={handleResetFilter}
                title="Clear All Filters"
              >
                <FcClearFilters size={18} />
              </Button>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default CommonLeadSourceFilter;
