import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

function Activity({ triggerActivityLog, itemId }) {
  return (
    <Card className="card-main rounded-0 shadow-none h-100">
      <Card.Header className="border-bottom d-flex align-items-center">
        <span className="mb-0 fs--1">Activity</span>
      </Card.Header>
      <Card.Body
        style={{
          overflow: "none",
          overflowY: "scroll",
          height: "70vh",
        }}
      >
        <ActivityTimeLine
          triggerActivityLog={triggerActivityLog}
          itemId={itemId}
          height={"50rem"}
        />
      </Card.Body>
    </Card>
  );
}

Activity.propTypes = {
  data: PropTypes.any,
};

export default Activity;
