import ColumnSelector from "module/common/Filter/ColumnSelector";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectProject from "components/form/SelectProject";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { showToast } from "module/common/Toast/toast";
import { FcClearFilters } from "react-icons/fc";
import { commonSprintFilterFormKeys } from "helpers/formKeys";
import SelectSprintStatus from "components/form/SelectSprintStatus";
import DateRangePicker from "module/common/Filter/DateRangePicker";

const CommonSprintFilter = ({ renderFrom }) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const [filterFields, setFilterFields] = useState(commonSprintFilterFormKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];
  const {
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    project_id_ref,
    name,
    status,
    status_ref,
  } = allQueryParams;
  const activeTab = queryParams.get("tab");

  const handleFieldChange = (e, action) => {
    if (!action) {
      setFilterFields({ ...filterFields, [e.target.name]: e.target.value });
    } else {
      let newFormData;
      if (action.action === "select-option") {
        newFormData = {
          ...filterFields,
          [action.name]: e.value,
          [action.name + "_ref"]: e,
        };
      } else if (action.action === "clear") {
        newFormData = {
          ...filterFields,
          [action.name]: "",
          [action.name + "_ref"]: {
            label: "",
            value: "",
          },
        };
      } else if (action.action === "remove-value") {
        newFormData = {
          ...filterFields,
          [action.name]: e.map((item) => item.value),
          [action.name + "_ref"]: e,
        };
      }
      setFilterFields(newFormData);
    }
  };

  const handleFilterApply = () => {
    if (!filterFields.date_range_for && filterFields.date_range) {
      showToast(`Please Select Date Field`, "error");
      return;
    }

    if (filterFields.date_range === "custom_date" && !filterFields.from_date) {
      showToast(`Please Select From Date`, "error");
      return;
    } else if (
      filterFields.date_range === "custom_date" &&
      !filterFields.to_date
    ) {
      showToast(`Please Select To Date`, "error");
      return;
    }

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          project_id_ref:
            filterFields?.project_id &&
            JSON.stringify(filterFields?.project_id_ref),
          status_ref:
            filterFields?.status && JSON.stringify(filterFields?.status_ref),
          page: pageNumber ? pageNumber : 1,
          tab: activeTab ? activeTab : "",
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      project_id: project_id ? project_id : "",
      project_id_ref: project_id ? JSON.parse(project_id_ref) : "",
      status: status ? status : "",
      status_ref: status ? JSON.parse(status_ref) : "",
      name: name ? name : "",
      date_range: date_range ? date_range : "",
      date_range_for: date_range_for ? date_range_for : "",
      from_date: from_date ? from_date : "",
      to_date: to_date ? to_date : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilter = () => {
    setFilterFields(commonSprintFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3 pb-3">
        <Col md={2} className="">
          <Row>
            <Form.Group as={Col}>
              <ColumnSelector
                title="Date Field"
                labelClassName="fs--1"
                columns={columns}
                filters={filterFields}
                setFilters={setFilterFields}
                defaultOption={true}
                value={filterFields.date_range_for}
              />
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Date Range</Form.Label>
              <DateRangePicker
                formData={filterFields}
                className={"custom-date-range-picker"}
                setFormData={setFilterFields}
                dateRange_form={{
                  to: "to_date",
                  from: "from_date",
                  range: "date_range",
                  month: "month",
                }}
              />
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Name</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleFieldChange}
                  value={filterFields.name}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        {renderFrom !== "project" && (
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Project</Form.Label>
                <Col>
                  <SelectProject
                    name="project_id"
                    handleFieldChange={handleFieldChange}
                    value={filterFields.project_id_ref}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
        )}
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Status</Form.Label>
              <Col>
                <SelectSprintStatus
                  name="status"
                  value={filterFields.status_ref}
                  handleFieldChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col className="d-flex justify-content-end">
          <Row className="m-0">
            <Form.Group
              as={Col}
              md={2}
              className="d-flex justify-content-end w-100 p-0"
            >
              <div className="d-flex align-items-end gap-2">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={handleFilterApply}
                  title="Apply Added Filters"
                >
                  Apply
                </Button>
                <Button
                  size="sm"
                  variant="transparent"
                  className="shadow-sm"
                  onClick={handleResetFilter}
                  title="Clear All Filters"
                >
                  <FcClearFilters size={18} />
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CommonSprintFilter;
