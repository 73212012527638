import { React, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { Dropdown, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaDownload, FaFileExcel, FaFilePdf } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";

import { ExportContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import { showWarningToast } from "module/common/Toast/showWarningToast";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import EmailExport from "./EmailExport";
import formateData from "helpers/formateData";

function Export({
  className,
  url,
  initialMessage,
  successMessage,
  variant,
  formData,
  newExportMethod,
  enableSendMail,
  ...rest
}) {
  const { exportArray, dispatch } = useContext(ExportContext);
  const Translate = useAxisproTranslate();
  const [isExporting, setIsExporting] = useState(false);
  const [sendMail, setSendMail] = useState(false);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");

  const handleExport = (e, file_type) => {
    e.preventDefault();
    setIsExporting(true);
    showWarningToast(Translate(initialMessage));
    if (!newExportMethod) {
      axios
        .get(url, {
          params: {
            mode: file_type,
            ...formateData(formData),
            ...formateData(JSON.parse(filterItems)),
          },
        })
        .then((response) => {
          if (response.status === 200 && response.data && response.data.data) {
            // Add to export context
            dispatch({
              type: "ADD",
              payload: {
                url: url,
                file_type: file_type,
                initialMessage: Translate(initialMessage),
                successMessage: Translate(successMessage),
                ...response.data.data,
              },
            });
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
            setIsExporting(false);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
          setIsExporting(false);
        });
    } else {
      axios
        .post(
          url,
          { data: JSON.parse(filterItems), mode: file_type },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.status === 200 && response.data && response.data.data) {
            // Add to export context
            dispatch({
              type: "ADD",
              payload: {
                url: url,
                file_type: file_type,
                initialMessage: Translate(initialMessage),
                successMessage: Translate(successMessage),
                ...response.data.data,
              },
            });
          } else {
            showToast(
              `Something went wrong, please refresh the page and try again.`,
              "error"
            );
            setIsExporting(false);
          }
        })
        .catch((error) => {
          console.error(error);
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
          setIsExporting(false);
        });
    }
  };

  useEffect(() => {
    let exportItem = url
      ? exportArray.filter((item) => item.url === url && !item.status)
      : [];

    exportItem = exportItem.length > 0 ? exportItem[0] : null;
    setIsExporting(exportItem ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exportArray]);

  const handleSendEmail = () => {
    setSendMail(true);
  };

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          style={{ boxShadow: "none", borderColor: "transparent" }}
          id="dropdown-basic"
          disabled={isExporting}
          variant={variant ? variant : "transaparent"}
          className={`d-flex flex-row align-items-center px-2 ${className}`}
          {...rest}
        >
          {isExporting ? (
            <>
              <Spinner
                animation="border"
                className="me-1"
                style={{ width: 12, height: 12 }}
              />
              <span className="fs--1">{Translate("Exporting")}</span>
            </>
          ) : (
            <>
              <FaDownload className="me-1" size={10} />
              <span className="fs--1">{Translate("Export")}</span>
            </>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#" onClick={(e) => handleExport(e, "pdf")}>
            <div className="d-flex flex-row align-items-center">
              <FaFilePdf
                className="me-1"
                size={11}
                style={{ color: "#f40f02" }}
              />
              <span className="fw-bolder mb-0 small">{Translate("PDF")}</span>
            </div>
          </Dropdown.Item>
          <Dropdown.Item href="#" onClick={(e) => handleExport(e, "excel")}>
            <div className="d-flex flex-row align-items-center">
              <FaFileExcel
                className="me-1"
                size={11}
                style={{ color: "#1d6f42" }}
              />
              <span className="fw-bolder mb-0 small">{Translate("Excel")}</span>
            </div>
          </Dropdown.Item>
          {enableSendMail ? (
            <Dropdown.Item href="#" onClick={handleSendEmail}>
              <div className="d-flex flex-row align-items-center">
                <MdAttachEmail
                  className="me-1"
                  size={11}
                  style={{ color: "#1BA3E9" }}
                />
                <span className="fw-bolder mb-0 small">
                  {Translate("Send Mail")}
                </span>
              </div>
            </Dropdown.Item>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>
      <EmailExport
        showEmailModal={sendMail}
        handleCancel={() => setSendMail(false)}
        url={`${url}-sendmail`}
        filteredParams={formateData(formData)}
      />
    </>
  );
}

Export.propTypes = {
  to: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string.isRequired,
  initialMessage: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
  formData: PropTypes.object,
  variant: PropTypes.string,
  newExportMethod: PropTypes.any,
  enableSendMail: PropTypes.bool,
};

export default Export;
