import { React } from "react";
import { Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import { useBreakpoints } from "../../hooks/useBreakpoints";
import TotalPlaceholder from "./Loading/TotalPlaceholder";

function Total({
  grossTotal = undefined,
  subTotal,
  netDiscount,
  tax,
  taxType,
  updateLineItem,
}) {
  const Translate = useAxisproTranslate();
  const { breakpoints } = useBreakpoints();

  return (
    <Container fluid className="d-flex flex-column align-items-end">
      <div
        style={{
          backgroundColor: "whitesmoke",
          border: "1px dashed black",
          borderRadius: "5px",
          minWidth: "30vh",
        }}
        className="mt-2 pe-3 p-5"
      >
        {grossTotal !== undefined ? (
          <Row className="d-flex align-items-center justify-content-center">
            <Col
              xl={8}
              md={8}
              sm={8}
              className="d-flex align-items-center justify-content-end mb-2"
              style={{ flex: breakpoints.down("sm") ? "2" : "none" }}
            >
              <h5 className="total-component-word-style m-0">
                {Translate("Gross Total")} :
              </h5>
            </Col>
            <Col
              xl={4}
              md={4}
              sm={4}
              className="d-flex align-items-center justify-content-end mb-2"
              style={{ flex: breakpoints.down("sm") ? "1" : "none" }}
            >
              {!updateLineItem ? (
                <h5 className="total-component-amount-style m-0">
                  {grossTotal
                    ? grossTotal.toLocaleString(navigator.language, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : "0.00"}
                </h5>
              ) : (
                <TotalPlaceholder />
              )}
            </Col>
          </Row>
        ) : null}
        <Row className="d-flex align-items-center justify-content-center">
          <Col
            xl={8}
            md={8}
            sm={8}
            className="d-flex align-items-center justify-content-end mb-2"
            style={{ flex: breakpoints.down("sm") ? "2" : "none" }}
          >
            <h5 className="total-component-word-style m-0">
              {Translate("Discount")} (-) :
            </h5>
          </Col>
          <Col
            xl={4}
            md={4}
            sm={4}
            className="d-flex align-items-center justify-content-end mb-2"
            style={{ flex: breakpoints.down("sm") ? "1" : "none" }}
          >
            {!updateLineItem ? (
              <h5 className="total-component-amount-style m-0">
                {netDiscount
                  ? netDiscount.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </h5>
            ) : (
              <TotalPlaceholder />
            )}
          </Col>
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col
            xl={8}
            md={8}
            sm={8}
            className="d-flex align-items-center justify-content-end mb-2"
            style={{ flex: breakpoints.down("sm") ? "2" : "none" }}
          >
            <h5 className="total-component-word-style m-0">
              {taxType ? Translate("Tax (INC)") : Translate("Tax")}
              (+) :
            </h5>
          </Col>
          <Col
            xl={4}
            md={4}
            sm={4}
            className="d-flex align-items-center justify-content-end mb-2"
            style={{ flex: breakpoints.down("sm") ? "1" : "none" }}
          >
            {!updateLineItem ? (
              <h5 className="total-component-amount-style m-0">
                {tax
                  ? tax.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </h5>
            ) : (
              <TotalPlaceholder />
            )}
          </Col>
        </Row>
        <Row>
          <Col
            xl={8}
            md={8}
            sm={8}
            className="d-flex align-items-center justify-content-end"
            style={{ flex: breakpoints.down("sm") ? "2" : "none" }}
          >
            <h5 className="total-component-word-style m-0">
              {Translate("Net Total")} :
            </h5>
          </Col>
          <Col
            xl={4}
            md={4}
            sm={4}
            className="d-flex align-items-center justify-content-end"
            style={{ flex: breakpoints.down("sm") ? "1" : "none" }}
          >
            {!updateLineItem ? (
              <h5 className="total-component-amount-style m-0">
                {subTotal
                  ? subTotal.toLocaleString(navigator.language, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : "0.00"}
              </h5>
            ) : (
              <TotalPlaceholder />
            )}
          </Col>
        </Row>
      </div>
    </Container>
  );
}

Total.propTypes = {
  grossTotal: PropTypes.number,
  subTotal: PropTypes.any,
  netDiscount: PropTypes.any,
  tax: PropTypes.any,
  taxType: PropTypes.bool,
  setFormData: PropTypes.func,
  formData: PropTypes.object,
  handleFieldChange: PropTypes.func,
  formError: PropTypes.object,
  fileName: PropTypes.string,
  updateLineItem: PropTypes.any,
};

export default Total;
