import { React } from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useAxisproTranslate from 'hooks/useAxisproTranslate';

const Search = ({ className, value, onChange, placeholder }) => {
  const Translate = useAxisproTranslate();

  return (
    <div
      className={`d-flex flex-row align-items-center border border-1 ${className}`}
    >
      <div className="p-2">
        <FontAwesomeIcon icon="search" />
      </div>
      <input
        type="search"
        placeholder={placeholder ?? Translate('Search Settings')}
        className="border-0 w-100 align-self-stretch me-2"
        style={{ outline: 'none' }}
        onChange={onChange}
        {...(value?.length > 0
          ? {
              value: value
            }
          : null)}
      />
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

export default Search;
