import { React, useContext, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";
import { Card, Image } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaUserAltSlash } from "react-icons/fa";
import { AuthWizardContext } from "context/Context";
import {
  AddButton,
  DeleteButton,
  EditButton,
} from "module/common/Buttons/AppButtons";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import SimpleBar from "simplebar-react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import useAxisproPermission from "hooks/useAxisproPermission";
import "../ProjectTableView.scss";

function List({ getData, data, handleDelete }) {
  let user = useContext(AuthWizardContext);
  const [queryParams] = useSearchParams();
  const { itemInfoId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const axisProPermission = useAxisproPermission();

  const columns = [
    {
      accessor: "title",
      Header: "PROJECT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { title, id } = rowData.row.original;
        return (
          <Link
            to={
              axisProPermission("show-project")
                ? `info/${id}?tab=overview`
                : "#"
            }
            className={`text-muted ${
              axisProPermission("show-project")
                ? "text-decoration-underline"
                : "disabled-link text-decoration-none cursor-default"
            }`}
          >
            <span className="mb-0 fs--1">{title}</span>
          </Link>
        );
      },
    },
    ...(user?.user?.customer_id
      ? []
      : [
          {
            accessor: "name",
            Header: "CLIENT",
            headerProps: { className: "py-3 custom-header-text" },
            cellProps: {
              className: "py-3 text-uppercase custom-table-body-text",
            },
            Cell: (rowData) => {
              const { customer } = rowData.row.original;
              return (
                <div>
                  <Link
                    to={
                      axisProPermission("show-account")
                        ? `/account/overview/${customer?.id}?tab=overview`
                        : "#"
                    }
                    className={`text-muted ${
                      axisProPermission("show-account")
                        ? "text-decoration-underline"
                        : "disabled-link text-decoration-none cursor-default"
                    }`}
                  >
                    <span className="mb-0 fs--1"> {customer?.name}</span>
                  </Link>
                </div>
              );
            },
          },
        ]),
    {
      accessor: "members",
      Header: "MEMBERS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { members } = rowData.row.original;
        return (
          <div className="project-custom-thumbnail-container">
            {Object.keys(members ?? [])?.length > 0 ? (
              members.map((assignee, key) => (
                <a
                  key={key}
                  href="#/"
                  onClick={() => handleUserProfileArea(assignee.user_id)}
                  className="project-custom-profile-link-crm"
                  style={{
                    left: 26 * key,
                    zIndex: 6 - key,
                    cursor: "pointer",
                  }}
                  title={`${assignee.full_name}`}
                >
                  <Image
                    src={assignee.avatar}
                    roundedCircle
                    height={30}
                    width={30}
                    loading="lazy"
                  />
                </a>
              ))
            ) : (
              <span className="">
                <FaUserAltSlash size={18} className="me-1" />
                No Members
              </span>
            )}
          </div>
        );
      },
    },
    {
      accessor: "start_date",
      Header: "START DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { start_date_formated } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{start_date_formated}</h5>;
      },
    },
    {
      accessor: "end_date",
      Header: "END DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { end_date_formated } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{end_date_formated}</h5>;
      },
    },
    {
      accessor: "price",
      Header: "PRICE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { price_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{price_formatted}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <span
            className="badge p-2  project-custom-badge-crm"
            style={{
              backgroundColor:
                status === "hold"
                  ? "#f49025d4"
                  : status === "cancelled"
                  ? "#f2451cd9"
                  : status === "open"
                  ? "#4bc0c9"
                  : "#43bf57",
            }}
          >
            <span className="fw-bold text-uppercase">{status}</span>
          </span>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          axisProPermission("update-project") ||
          axisProPermission("delete-project")
            ? ""
            : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          axisProPermission("update-project") ||
          axisProPermission("delete-project")
            ? ""
            : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {axisProPermission("update-project") && (
              <EditButton
                route={`edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {axisProPermission("delete-project") && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const handleUserProfileArea = (id) => {
    setUserProfile(id);
    setShowUserProfileArea(true);
  };

  return (
    <>
      <Outlet context={[getData]} />
      {!itemInfoId ? (
        <>
          {data?.data?.length > 0 ? (
            <AdvanceTableWrapper columns={columns} data={data.data} sortable>
              <SimpleBar className="simplebar-style-with-pagination">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </SimpleBar>
              <Card.Footer className="border-top p-1 pt-3">
                {data && data.links && (
                  <AppPagination
                    data={data}
                    url="/project"
                    search={allQueryParams}
                  />
                )}
              </Card.Footer>
            </AdvanceTableWrapper>
          ) : (
            <LoadingScreenCover style={{ height: "70vh" }}>
              {user?.user?.customer_id ? (
                <h5 className="text-center text-secondary">
                  Currently there is no project! &#128578;
                </h5>
              ) : (
                <h5 className="text-center text-secondary">
                  Project not found! Let's add it &#128578;
                </h5>
              )}
              <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
                {axisProPermission("store-project") && (
                  <AddButton
                    route={`add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                    variant="success"
                    title="Add new project"
                    label={"Add Project"}
                  />
                )}
              </div>
            </LoadingScreenCover>
          )}
        </>
      ) : (
        ""
      )}
      <UserProfile
        show={showUserProfileArea}
        handleClose={() => setShowUserProfileArea(false)}
        id={userProfile}
      />
    </>
  );
}

List.propTypes = {
  projectId: PropTypes.string,
  setComponent: PropTypes.func,
};

export default List;
