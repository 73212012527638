import axios from "axios";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import FileController from "components/file-controller/FileController";
import SelectCustomer from "components/form/SelectCustomer";
import SelectProject from "components/form/SelectProject";
import SelectProjectPriority from "components/form/SelectProjectPriority";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import SelectTicketStatus from "components/form/SelectTicketStatus";
import SelectTicketType from "components/form/SelectTicketType";
import SelectUsers from "components/form/SelectUsers";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { ticketsFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { CloseButton, SaveButton } from "module/common/Buttons/CommonButton";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, { useContext, useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BiSupport } from "react-icons/bi";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const TicketForm = () => {
  let navigate = useNavigate();
  let user = useContext(AuthWizardContext);
  const [getData] = useOutletContext();
  const [formData, setFormData] = useState(ticketsFormKeys);
  const [customerId, setCustomerId] = useState({
    customer_id: "",
    customer_id_ref: { value: "", label: "" },
  });
  const [formError, setFormError] = useState({});
  const { itemId, itemInfoId } = useParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      if (action.action === "select-option") {
        if (action.name === "customer_id") {
          formData.project_id = itemInfoId ? formData.project_id : "";
          formData.project_id_ref = { label: "", value: "" };
        }
        value = e.value ? e.value : "";
      }
      if (action.action === "clear") {
        if (action.name === "customer_id") {
          formData.project_id = "";
          formData.project_id_ref = { label: "", value: "" };
        }
        value = "";
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleCustomerData = (e, action) => {
    if (action?.action === "select-option") {
      setFormData((prev) => ({
        ...prev,
        project_id: itemInfoId ? formData?.project_id : "",
        project_id_ref: { label: "", value: "" },
      }));
      setCustomerId({
        customer_id: e.value,
        customer_id_ref: e,
      });
    } else {
      setCustomerId({
        customer_id: "",
        customer_id_ref: { value: "", label: "" },
      });
    }
  };

  const handleFiles = (files) => {
    handleFieldChange({ target: { name: "files", value: files } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOnSave(true);
    setFormError({});

    axios({
      method: itemId ? "put" : "post",
      url: `crm/ticket${itemId ? "/" + itemId : ""}`,
      data: setNewFormData(removeRefData(formData, "object")),
      headers: {
        "Content-Type": !itemId ? "multipart/form-data" : "application/json",
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          getData();
          navigate(itemId || itemInfoId ? -1 : `/tickets/list?tab=all`);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/ticket/" + itemId,
    });

    setFormData({
      title: data.title,
      project_id: data?.project_id,
      project_id_ref: { label: data?.project, value: data?.project_id },
      ticket_type_id: data?.ticket_type_id,
      ticket_type_id_ref: {
        label: data?.ticket_type,
        value: data?.ticket_type_id,
      },
      priority: data?.priority,
      priority_ref: {
        label: data?.priority,
        value: data?.priority,
      },
      ticket_origin: data.ticket_origin,
      ticket_origin_ref: {
        label: data.ticket_origin,
        value: data.ticket_origin,
      },
      status: data?.status,
      status_ref: { label: data?.status, value: data?.status },
      description: data.description,
      assigned_to: data?.assigned_to,
      assigned_to_ref: { label: data?.assignee, value: data.assigned_to },
      ticket_date_time: data?.ticket_date_time ?? "",
      end_date_time: data?.end_date_time ? data?.end_date_time : "",
    });
    setCustomerId({
      customer_id: data?.customer_id,
      customer_id_ref: {
        label: data?.customer_name,
        value: data?.customer_id,
      },
    });
    setLoading(false);
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (itemInfoId) {
      setFormData((prev) => ({
        ...prev,
        project_id: itemInfoId,
        priority: "Medium",
        ticket_origin: "web",
        status: "new",
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  return (
    <>
      <Modal show size="lg">
        <Modal.Header>
          <Modal.Title className="w-100 d-flex justify-content-between">
            <div className="d-flex align-items-center pt-1 pb-1">
              <BiSupport size={28} className="me-2 text-dark" />
              <span className="custom-module-header text-uppercase">
                {itemId ? "UPDATE TICKET" : "CREATE TICKET"}
              </span>
            </div>
            <div className="d-flex justify-content-end">
              <CloseButton handleClick={() => navigate(-1)} />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            <Form>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="require-data">Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="title"
                      className="pt-1 pb-1"
                      onChange={handleFieldChange}
                      value={formData.title}
                      placeholder="Enter Name"
                      isInvalid={!!formError.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {itemInfoId ? null : (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">Project</Form.Label>
                      <SelectProject
                        name="project_id"
                        value={formData.project_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.project_id}
                        clientId={itemInfoId ? "" : customerId.customer_id}
                        defaultLoadOptions={formData.customer_id ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.project_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="require-data">
                      Ticket Type
                    </Form.Label>
                    <SelectTicketType
                      value={formData.ticket_type_id_ref}
                      name="ticket_type_id"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.ticket_type_id}
                      setFormData={setFormData}
                      useDefaultValue={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.ticket_type_id}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {itemInfoId && user?.user?.customer_id ? null : (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Ticket Origin</Form.Label>
                        <SelectTicketOrigin
                          value={formData.ticket_origin_ref}
                          name="ticket_origin"
                          handleFieldChange={handleFieldChange}
                          error={!!formError.ticket_origin}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.ticket_origin}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>Start Date Time</Form.Label>
                        <AppTimePicker
                          name="ticket_date_time"
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          onChange={handleFieldChange}
                          value={formData.ticket_date_time}
                          isInvalid={!!formError.ticket_date_time}
                        />
                        <Form.Control.Feedback type="is-invalid">
                          {formError.ticket_date_time}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                </Col>
                <Col md={6}>
                  {itemInfoId || user?.user?.customer_id ? null : (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">Client</Form.Label>
                      <SelectCustomer
                        name="customer_id"
                        value={customerId?.customer_id_ref}
                        error={formError?.customer_id}
                        handleFieldChange={handleCustomerData}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.customer_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  {itemInfoId && user?.user?.customer_id ? null : (
                    <>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Priority</Form.Label>
                        <SelectProjectPriority
                          name="priority"
                          value={formData.priority_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError.priority}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.priority}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label className="require-data">Status</Form.Label>
                        <SelectTicketStatus
                          value={formData.status_ref}
                          name="status"
                          handleFieldChange={handleFieldChange}
                          error={!!formError.status}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.status}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Assignee</Form.Label>
                        <SelectUsers
                          value={formData.assigned_to_ref}
                          name="assigned_to"
                          handleFieldChange={handleFieldChange}
                          error={formError.assigned_to}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.assigned_to}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput3"
                      >
                        <Form.Label>End Date</Form.Label>
                        <AppTimePicker
                          name="end_date_time"
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          onChange={handleFieldChange}
                          value={formData.end_date_time}
                          isInvalid={!!formError.end_date_time}
                        />
                        <Form.Control.Feedback type="is-invalid">
                          {formError.end_date_time}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      onChange={handleFieldChange}
                      value={formData.description}
                      isInvalid={!!formError.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {!itemId && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <FileController
                        limit={5}
                        limitFeature={true}
                        limitFileSizeInMB={5}
                        limitFileType={[
                          "jpg",
                          "png",
                          "jpeg",
                          "pdf",
                          "excel",
                          "xlsx",
                          "docx",
                          "doc",
                          "xls",
                        ]}
                        onChange={handleFiles}
                        className="my-3"
                        note="You can upload a maximum of 5 files, 5MB each"
                      />
                    </Form.Group>
                  )}

                  <Form.Group className="d-flex gap-1">
                    <SaveButton
                      onSave={onSave}
                      handleSubmit={handleSubmit}
                      id={itemId}
                    />
                    <BackButton
                      variant={"danger"}
                      size={"sm"}
                      disabled={onSave}
                    >
                      Cancel
                    </BackButton>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          ) : (
            <div className="mb-2">
              <ListLoading style={{ height: "53.1vh", overflow: "hidden" }} />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TicketForm;
