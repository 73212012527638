import Avatar from "components/common/Avatar";
import FalconCardHeader from "components/common/FalconCardHeader";
import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import profileImg from "assets/img/team/1.jpg";
import { Link } from "react-router-dom";

const ContactInfo = ({ data }) => {
  return (
    data && (
      <Card>
        <FalconCardHeader
          title="Contact Information"
          titleTag="h6"
          className="bg-light"
        />
        <Card.Body className="pt-0">
          <Row className="g-0 border-bottom align-items-sm-center align-items-xl-start">
            <Col xs={12} sm="auto" xl={12} className="">
              <Avatar src={data.image ?? profileImg} size="3xl" />
            </Col>
            <Col xs={12} sm="auto" xl={12} className="mb-3">
              <div className="d-flex flex-column mt-2">
                <span className="fw-semi-bold text-800 mb-0">{data.name}</span>
                {data?.roles?.length > 0 &&
                  data.roles.map((role, key) => (
                    <span key={key}>
                      {key >= 1 ? "," + role.name : role.name}
                    </span>
                  ))}
              </div>
            </Col>
          </Row>
          <Row className="g-0 justify-content-lg-between">
            <Col xs="auto" md={6} lg="auto" className="mt-3">
              <Row>
                <Col md="auto" className="">
                  <h6 className="mb-1">Email</h6>
                  <Link className="fs--1">{data.email}</Link>
                </Col>
                <Col md="auto" className="mb-4 mb-md-0 mb-xl-4">
                  <h6 className="mb-1">Phone Number</h6>
                  <Link className="fs--1">{data.phone}</Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  );
};

export default ContactInfo;
