const removeEmptyFields = (data, additionalField = "") => {
  if (typeof data === "object") {
    const keysArray = Object.keys(data);
    const filteredObject = keysArray.reduce((result, key) => {
      if (
        data[key] !== undefined &&
        data[key] !== null &&
        data[key] !== "" &&
        key !== additionalField
      ) {
        result[key] = data[key];
      }
      return result;
    }, {});
    return filteredObject;
  }
};

export default removeEmptyFields;
