import { React, useEffect, useState } from "react";
import axios from "axios";
import { Button, Card, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

import { AiOutlineCloseSquare } from "react-icons/ai";

function ErrorShowingPopup({ show, exceptionData, onHide }) {
  const [data, setData] = useState({
    message: "",
  });
  const [report, setReport] = useState(false);

  useEffect(() => {
    setReport(false);
    let string = JSON.stringify(exceptionData);
    setData({
      ...data,
      message: string,
    });
    // eslint-disable-next-line
  }, [exceptionData]);

  const handleSubmit = () => {
    onHide();
    axios({
      method: "post",
      url: "user/slack-report-bug",
      data: data,
    })
      .then((response) => {
        if (response.data.success === true) {
          setReport(true);
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <Modal show={show} size="lg">
      <Card>
        <Card.Header className="pb-3 pt-3 d-flex align-items-center border-bottom">
          <div>
            <h5 className="m-0 text-uppercase fs-1 text-dark">Exception !</h5>
            <p className="m-0 fs--1 text-mute bg-soft-secondary p-1">
              This popup will never appear in production mode
            </p>
          </div>
          <AiOutlineCloseSquare
            onClick={onHide}
            className="ms-auto text-danger"
            size={25}
            style={{ cursor: "pointer" }}
          />
        </Card.Header>
        <Card.Body className="p-4 pb-1 pt-1">
          <ul>
            {Object.entries(exceptionData).map(([key, value]) => (
              <li key={key}>
                <strong>{key}:</strong> {value}
              </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer>
          <Button
            size="sm"
            variant={report ? "success" : "danger"}
            style={{ boxShadow: "none" }}
            onClick={handleSubmit}
          >
            {report ? "Reported!" : "Report Bug"}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

ErrorShowingPopup.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  exceptionData: PropTypes.any,
};

export default ErrorShowingPopup;
