import React from "react";
import { Link } from "react-router-dom";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import PropTypes from "prop-types";
import { FaEye, FaPencilAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { RiFileTransferLine } from "react-icons/ri";
import { TbFilterDown, TbFilterUp, TbTrashFilled } from "react-icons/tb";
import { IoMdClose } from "react-icons/io";
import { FcClearFilters } from "react-icons/fc";

export const ViewButton = ({ to }) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        <Tooltip>
          <strong className="fs--2">View</strong>
        </Tooltip>
      }
    >
      <Button
        variant=""
        size="sm"
        className="me-1 ps-2 pe-2 rounded-3"
        style={{ boxShadow: "none", backgroundColor: "#5E4AD91C" }}
        as={Link}
        to={to}
      >
        <FaEye color="#5E4AD9" size={16} />
      </Button>
    </OverlayTrigger>
  );
};

ViewButton.propTypes = {
  to: PropTypes.string,
};

export const EditButton = ({ to }) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        <Tooltip>
          <strong className="fs--2">Edit</strong>
        </Tooltip>
      }
    >
      <Button
        variant=""
        size="sm"
        className="me-1 ps-2 pe-2 rounded-3"
        style={{
          boxShadow: "none",
          backgroundColor: "#4C8CEC1C",
        }}
        as={Link}
        to={to}
      >
        <FaPencilAlt color="#4C8CEC" size={14} />
      </Button>
    </OverlayTrigger>
  );
};

EditButton.propTypes = {
  to: PropTypes.string,
};

export const ConversionButton = ({ to, disable, toolTip1, toolTip2 }) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        disable ? (
          <Tooltip>
            <span className="text-success fs--2">
              {toolTip2} <TiTick />
            </span>
          </Tooltip>
        ) : (
          <Tooltip>
            <strong className="fs--2">{toolTip1}</strong>
          </Tooltip>
        )
      }
    >
      <Button
        variant=""
        className="p-1 me-1 mx-1 text-success"
        style={{
          boxShadow: "none",
        }}
        as={Link}
        to={to}
        disabled={disable}
      >
        <RiFileTransferLine size={18} />
      </Button>
    </OverlayTrigger>
  );
};

ConversionButton.propTypes = {
  to: PropTypes.string,
  disable: PropTypes.bool,
  toolTip1: PropTypes.string,
  toolTip2: PropTypes.string,
};

export const DeleteButton = ({ handleDelete, id }) => {
  return (
    <OverlayTrigger
      placement={"bottom"}
      overlay={
        <Tooltip>
          <strong className="fs--2">Delete</strong>
        </Tooltip>
      }
    >
      <Button
        variant="transparent"
        size="sm"
        className="me-1 ps-2 pe-2 rounded-3"
        style={{ boxShadow: "none", backgroundColor: "#FF5B5B1C" }}
        onClick={() => handleDelete(id)}
      >
        <TbTrashFilled size={18} color="#FF5B5B" />
      </Button>
    </OverlayTrigger>
  );
};

DeleteButton.propTypes = {
  handleDelete: PropTypes.func,
  id: PropTypes.string,
};

export const SaveButton = ({
  onSave,
  handleSubmit,
  id,
  className,
  disabled,
}) => {
  return (
    <Button
      size="sm"
      className={`w-10 d-flex align-items-center justify-content-center ${
        className ?? ""
      }`}
      type="button"
      variant={`${id ? "info" : "success"}`}
      disabled={onSave || disabled}
      onClick={handleSubmit}
    >
      {onSave ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="white"
            className="me-1"
          />
          <span className="fs--1">{id ? "Updating..." : "Saving..."}</span>
        </>
      ) : (
        <span>{id ? "Update" : "Save"}</span>
      )}
    </Button>
  );
};

SaveButton.propTypes = {
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  id: PropTypes.any,
};

export const SaveNewButton = ({
  onSave,
  handleSubmit,
  className,
  disabled,
}) => {
  return (
    <Button
      size="sm"
      className={`w-10 d-flex align-items-center justify-content-center ${
        className ?? ""
      }`}
      type="button"
      variant={`${"secondary"}`}
      disabled={onSave || disabled}
      onClick={handleSubmit}
    >
      {onSave ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="white"
            className="me-1"
          />
          <span className="fs--1">{"Saving..."}</span>
        </>
      ) : (
        <span>{"Save & New"}</span>
      )}
    </Button>
  );
};

SaveNewButton.propTypes = {
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  id: PropTypes.any,
};

export const CancelButton = ({ onSave, handleCancel }) => {
  return (
    <Button
      size="sm"
      type="button"
      variant="danger"
      className="w-10"
      onClick={handleCancel}
      disabled={onSave}
    >
      Cancel
    </Button>
  );
};

CancelButton.propTypes = {
  onSave: PropTypes.bool,
  handleCancel: PropTypes.func,
};

export const PrimarySaveButton = ({ onSave, handleSubmit, id }) => {
  return (
    <Button
      size="sm"
      className="w-10 d-flex align-items-center justify-content-center"
      type="button"
      variant={`${id ? "info" : "primary"}`}
      disabled={onSave}
      onClick={handleSubmit}
    >
      {onSave ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            variant="white"
            className="me-1"
          />
          <span className="fs--1">{id ? "Updating..." : "Saving..."}</span>
        </>
      ) : (
        <span>{id ? "Update" : "Save"}</span>
      )}
    </Button>
  );
};

PrimarySaveButton.propTypes = {
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  id: PropTypes.any,
};

export const CloseButton = ({ handleClick, title = "Back to list" }) => (
  <Button
    size="sm"
    variant="light"
    className="custom-transperent-common-button shadow"
    onClick={handleClick}
    title={title}
  >
    Close
    <IoMdClose size={18} color="#DB3232" />
  </Button>
);

export const FilterCommonButton = ({
  filterVisibility,
  setFilterVisibility,
}) => (
  <Button
    size="sm"
    variant={"light"}
    className="shadow"
    onClick={(e) => {
      setFilterVisibility(!filterVisibility);
    }}
    title={filterVisibility ? "Hide filter" : "Show filter"}
  >
    {filterVisibility ? (
      <TbFilterUp size={18} color="#f53838" />
    ) : (
      <TbFilterDown size={18} color="#0ece0e" />
    )}
  </Button>
);

export const FilterCommonResetButton = ({ handleRestFilter }) => (
  <Button
    size="sm"
    variant="light"
    title={"Clear filter"}
    onClick={handleRestFilter}
  >
    <FcClearFilters size={20} />
  </Button>
);
