import { Fragment, React, useEffect, useState } from "react";
import { Card, Placeholder } from "react-bootstrap";
import PropTypes from "prop-types";

function DashboardTileLoader({ column }) {
  const [colRef, setColRef] = useState([]);

  const addArrayItems = (count) => {
    let array = [];
    for (let index = 0; index < count; index++) {
      array.push(index);
    }
    return array;
  };

  useEffect(() => {
    if (Array.isArray(column)) {
    } else if (Number.isInteger(column) && column > 0) {
      setColRef(addArrayItems(column));
    }

    return () => {
      setColRef([]);
    };
  }, [column]);

  return (
    <Placeholder as={Card.Text} animation="glow" className="m-0">
      {colRef.map((key) => (
        <Fragment key={key}>
          <Placeholder
            xs={12}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            className="me-1"
            xs={4}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            xs={5}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            className="me-1"
            xs={5}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            xs={6}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            xs={3}
            size="md"
            className="me-1"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            className="me-1"
            xs={3}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
          <Placeholder
            xs={3}
            size="md"
            bg="secondary"
            style={{ opacity: "0.5" }}
          />
        </Fragment>
      ))}
    </Placeholder>
  );
}

DashboardTileLoader.propTypes = {
  column: PropTypes.any,
};

export default DashboardTileLoader;
