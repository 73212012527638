import React, { Fragment, useLayoutEffect, useState } from "react";
import "./TicketDashboard.scss";
import TicketDashboardHeader from "./TicketDashboardHeader";
import TicketDashboardContent from "./TicketDashboardContent";
import { Outlet, useLocation } from "react-router-dom";
import { apiCall } from "helpers/apiCalls";

const TicketDashboard = () => {
  const location = useLocation();
  const [headersLoading, setHeadersLoading] = useState(false);
  const [headersData, setHeadersData] = useState({});

  useLayoutEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setHeadersLoading(true);

    try {
      const resData = await apiCall({
        url: "crm/ticket-summary",
      });

      setHeadersData(resData);
      setHeadersLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="mt-3 mb-3">
      <Outlet context={[getData]} />
      {location.pathname !== "/tickets/list" &&
        !location.pathname.includes("overview") &&
        !location.pathname.includes("convert") &&
        !location.pathname.includes("/tickets/types") && (
          <>
            <TicketDashboardHeader
              headersData={headersData}
              loading={headersLoading}
            />
            <TicketDashboardContent
              graphData={headersData}
              loading={headersLoading}
            />
          </>
        )}
    </div>
  );
};

export default TicketDashboard;
