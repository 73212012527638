import AddButton from "components/add-button/AddButton";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import useAxisproPermission from "hooks/useAxisproPermission";
import React from "react";
import { createSearchParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

const List = ({ columns, clients, allQueryParams }) => {
  const axisProPermission = useAxisproPermission();
  return (
    <>
      {clients?.data?.length > 0 ? (
        <AdvanceTableWrapper columns={columns} data={clients.data} sortable>
          <SimpleBar className="simplebar-style-with-pagination">
            <AdvanceTable
              table
              headerClassName="text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tablebodyClassName="custom-table-body"
              tableProps={{
                size: "sm",
                className: "fs--1 mb-0 overflow-hidden custom-table",
              }}
            />
          </SimpleBar>
          <div className="border-top p-1 pt-3">
            {clients && clients.links && (
              <AppPagination
                data={clients}
                url="/account/list"
                search={allQueryParams}
              />
            )}
          </div>
        </AdvanceTableWrapper>
      ) : (
        <LoadingScreenCover style={{ height: "70vh" }}>
          <h5 className="text-center text-secondary">
            Account not found! Let's add it &#128578;
          </h5>
          <div className="d-flex flex-wrap align-items-center justify-content-center mt-3">
            {axisProPermission("create-customer") && (
              <AddButton
                to={`/account/add?${createSearchParams({
                  ...allQueryParams,
                  view: "list",
                })}`}
                variant="success"
                title="Add new account"
              >
                Add Account
              </AddButton>
            )}
          </div>
        </LoadingScreenCover>
      )}
    </>
  );
};

export default List;
