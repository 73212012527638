import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import useAxisproPermission from "hooks/useAxisproPermission";
import React from "react";
import { Card, Spinner } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const TicketOverviewHeader = ({
  handleDelete,
  handleTicketStatus,
  currentStatus,
  loading,
}) => {
  const { overviewId } = useParams();
  let itemId = overviewId;
  const navigate = useNavigate();
  const axisProPermission = useAxisproPermission();
  const [updateStatusPermission, deletePermission, updatePermission] = [
    "update-ticket-status",
    "delete-ticket",
    "update-ticket",
  ].map((permission) => axisProPermission(permission));

  return (
    <Card>
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigate(-1)}
          variant="falcon-default"
          size="sm"
          icon="arrow-left"
        />
        <Flex>
          <IconButton
            variant="falcon-default"
            size="sm"
            icon="shuffle"
            transform="shrink-2"
            as={Link}
            to={`/tickets/convert/${overviewId}`}
            className="me-2"
          >
            <span className="d-none d-md-inline-block ms-1">Convert</span>
          </IconButton>
          {updateStatusPermission && (
            <>
              {currentStatus !== "closed" &&
                (loading ? (
                  <Spinner size="sm" />
                ) : (
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="check"
                    transform="shrink-2"
                    className="mx-2"
                    onClick={handleTicketStatus}
                  >
                    <span className="d-none d-md-inline-block ms-1">Close</span>
                  </IconButton>
                ))}
            </>
          )}

          {updatePermission && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="pencil-alt"
              transform="shrink-2"
              className={`d-none d-sm-block ${
                currentStatus === "closed" ? "me-2" : "me-2"
              }`}
              as={Link}
              to={`/tickets/list/overview/update/${itemId}`}
            >
              <span className="d-none d-md-inline-block ms-1">Edit</span>
            </IconButton>
          )}
          {deletePermission && (
            <IconButton
              variant="falcon-default"
              size="sm"
              icon="trash-alt"
              transform="shrink-2"
              className={`d-none d-sm-block ${
                currentStatus === "closed" ? "ms-2" : ""
              }`}
              onClick={handleDelete}
            >
              <span className="d-none d-md-inline-block ms-1">Delete</span>
            </IconButton>
          )}
        </Flex>
      </Card.Header>
    </Card>
  );
};

export default TicketOverviewHeader;
