import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonDepartmentsFilterFormKeys } from "helpers/formKeys";

const CommonDepartmentsFilter = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(
    commonDepartmentsFilterFormKeys
  );
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { name } = allQueryParams;

  const handleFieldChange = (e) => {
    setFilterFields({ ...filterFields, [e.target.name]: e.target.value });
  };

  const handleFilterApply = () => {
    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      name: name ? name : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const handleResetFilter = () => {
    setFilterFields(commonDepartmentsFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Name</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleFieldChange}
                  value={filterFields.name}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col className="d-flex align-items-end justify-content-end">
          <Row className="m-0">
            <Form.Group as={Col} md={2} className="p-0">
              <div className="d-flex align-items-end gap-2">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={handleFilterApply}
                  title="Apply Added Filters"
                >
                  Apply
                </Button>
                <Button
                  size="sm"
                  variant="transparent"
                  className="shadow-sm"
                  onClick={handleResetFilter}
                  title="Clear All Filters"
                >
                  <FcClearFilters size={18} />
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CommonDepartmentsFilter;
