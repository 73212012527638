import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { AiFillPlusCircle } from "react-icons/ai";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import MeetingsForm from "./Form/MeetingsForm";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import SoftBadge from "components/common/SoftBadge";
import { DeleteContext } from "context/Context";
import useAxisproPermission from "hooks/useAxisproPermission";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import SimpleBar from "simplebar-react";

function Meetings({ type, itemId, overview }) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { overviewId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { dispatch } = useContext(DeleteContext);
  const axisProPermission = useAxisproPermission();
  const permissionObject = {
    lead: {
      store_meeting: axisProPermission("store-lead-meeting") && type === "LEAD",
      update_meeting:
        axisProPermission("update-lead-meeting") && type === "LEAD",
      delete_meeting:
        axisProPermission("delete-lead-meeting") && type === "LEAD",
    },
    opportunity: {
      store_meeting:
        axisProPermission("store-opportunity-meeting") &&
        type === "OPPORTUNITY",
      update_meeting:
        axisProPermission("update-opportunity-meeting") &&
        type === "OPPORTUNITY",
      delete_meeting:
        axisProPermission("delete-opportunity-meeting") &&
        type === "OPPORTUNITY",
    },
  };

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/meetings/" + id,
        title: "Delete Meeting",
        message: "Are you sure you want to delete this meeting?",
        onSuccess: () => {
          fetchData();
        },
      },
    });
  };

  const columns = [
    {
      accessor: "title",
      Header: "TITLE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { title } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{title}</h5>;
      },
    },
    {
      accessor: "start_time_formatted",
      Header: "START TIME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { start_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{start_time_formatted}</h5>;
      },
    },
    {
      accessor: "end_time_formatted",
      Header: "END TIME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { end_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{end_time_formatted}</h5>;
      },
    },
    {
      accessor: "related_to_object.first_name",
      Header: "HOST NAME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { host } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{host?.name}</h5>;
      },
    },
    {
      accessor: "related_to_object.phone_no",
      Header: "HOST PHONE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { host } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{host?.phone}</h5>;
      },
    },
    {
      accessor: "location",
      Header: "LOCATION",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{location}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            <SoftBadge bg={`${status === "Completed" ? "success" : "warning"}`}>
              {status.toUpperCase()}
            </SoftBadge>
          </h5>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: [
          permissionObject.lead.update_meeting,
          permissionObject.opportunity.update_meeting,
          permissionObject.lead.delete_meeting,
          permissionObject.opportunity.delete_meeting,
        ].some(Boolean)
          ? ""
          : "d-none",
      },
      disableSortBy: true,
      cellProps: {
        className: [
          permissionObject.lead.update_meeting,
          permissionObject.opportunity.update_meeting,
          permissionObject.lead.delete_meeting,
          permissionObject.opportunity.delete_meeting,
        ].some(Boolean)
          ? "text-end"
          : "text-end d-none",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {(permissionObject.lead.update_meeting ||
              permissionObject.opportunity.update_meeting) && (
              <EditButton
                route={`?${createSearchParams({
                  tab: "meeting",
                  add_meeting: true,
                  edit_meeting_id: id,
                })}`}
              />
            )}
            {(permissionObject.lead.delete_meeting ||
              permissionObject.opportunity.delete_meeting) && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/meetings",
      params: {
        related_to_type:
          type === "LEAD" || overview === "lead" ? "LEAD" : "OPPORTUNITY",
        related_to_id: overview === "lead" ? overviewId : itemId,
      },
    });
    setData(data.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  return (
    <>
      {!isLoading ? (
        <Row>
          <Col xs={12}>
            <Card className="d-flex flex-fill shadow-none">
              <Card.Header
                className={`${
                  type === "OPPORTUNITY"
                    ? "pt-2 pb-2"
                    : "border-bottom pb-3 pt-3 "
                } ps-2 rounded-0`}
                style={{
                  backgroundColor: type === "OPPORTUNITY" ? "#d2d4e9" : "",
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3">MEETING INFORMATION</div>
                  {((!overviewId && itemId) || type === "LEAD") && (
                    <MeetingsForm getData={fetchData} type={type} />
                  )}
                  {(permissionObject.lead.store_meeting ||
                    permissionObject.opportunity.store_meeting) && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            add_meeting: true,
                          })}`
                        );
                      }}
                    >
                      <AiFillPlusCircle size={22} className="text-primary" />
                    </div>
                  )}
                </div>
              </Card.Header>
              <Card.Body
                style={{
                  height:
                    type === "OPPORTUNITY"
                      ? "36.8rem"
                      : overview === "lead"
                      ? "100"
                      : "41rem",
                }}
                className={`p-0 rounded-0`}
              >
                {data.length > 0 ? (
                  <div className="mt-3">
                    <AdvanceTableWrapper columns={columns} data={data} sortable>
                      <SimpleBar>
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            size: "sm",
                            striped: true,
                            className: "fs--1 mb-0 overflow-hidden ",
                          }}
                          responsive={overview === "lead" ? false : true}
                        />
                      </SimpleBar>
                    </AdvanceTableWrapper>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    No meeting information! 🙂
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading style={{ height: "75vh", overflow: "hidden" }} />
      )}
    </>
  );
}

Meetings.propTypes = {
  data: PropTypes.any,
};

export default Meetings;
