import PropTypes from "prop-types";
import "./ShowHtmlContent.scss";
import { useRef } from "react";
const ShowHtmlContent = ({ description, handleImageClick }) => {
  const imageRef = useRef(null);
  return (
    <div
      className="setInnerHtml"
      ref={imageRef}
      onClick={handleImageClick}
      dangerouslySetInnerHTML={{
        __html: description?.replace("<p><br></p>", "").trim(),
      }}
    />
  );
};
ShowHtmlContent.prototype = {
  description: PropTypes.any,
};
export default ShowHtmlContent;
