import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { teamFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import SelectUsers from "components/form/SelectUsers";
import { IoIosPeople } from "react-icons/io";
import MutiSelectUsers from "components/form/MutiSelectUsers";
import ListLoading from "module/common/ListLoading";

const TeamForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(teamFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      if (action.action === "select-option") {
        if (action.name === "team_members") {
          const teamMemberArray = e.map((item) => {
            return { id: item.value };
          });
          value = teamMemberArray;
        } else {
          value = e.code ? e.code : e.value;
        }
      }

      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/team${itemId ? "/" + itemId : ""}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");

          navigate(itemId ? -1 : "/teams");
          itemId && getData(pageNumber ?? 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/team/" + itemId,
      params: {
        page: page,
      },
    });
    setFormData({
      name: data.name,
      description: data.description,
      team_lead_id: data.team_lead_id,
      team_lead_id_ref: {
        label: data.team_lead.first_name,
        value: data.team_lead_id,
      },
      team_members: data.members.map(({ team_member_id }) => {
        return { id: team_member_id };
      }),
      team_members_ref: data.members.map((item) => {
        return {
          label: item.team_member.name,
          value: item.team_member_id,
        };
      }),
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-1 pb-1">
          <IoIosPeople size={30} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {itemId ? "Update Team" : "Create Team"}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                className="require-data"
                style={{ fontSize: "13px", marginBottom: "10px" }}
              >
                Name
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleFieldChange}
                value={formData.name}
                placeholder="Enter Name"
                isInvalid={!!formError.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "13px", marginBottom: "10px" }}>
                Description
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                onChange={handleFieldChange}
                value={formData.description}
                placeholder="Enter Description"
                isInvalid={!!formError.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError.description}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label
                className="require-data"
                style={{ fontSize: "13px", marginBottom: "10px" }}
              >
                Team Lead
              </Form.Label>
              <SelectUsers
                name="team_lead_id"
                value={formData.team_lead_id_ref}
                handleFieldChange={handleFieldChange}
                error={formError.team_lead_id}
              />
              <Form.Control.Feedback type="invalid">
                {formError.team_lead_id}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label style={{ fontSize: "13px", marginBottom: "10px" }}>
                Team Members
              </Form.Label>
              <MutiSelectUsers
                name="team_members"
                handleFieldChange={handleFieldChange}
                value={formData.team_members_ref}
                assignee_id={formData.team_lead_id}
              />
              <Form.Control.Feedback type="invalid">
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TeamForm;
