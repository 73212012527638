import React, { Fragment, useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import is from "is_js";
import MainLayout from "./MainLayout";
import { toast, ToastContainer } from "react-toastify";
import AppContext from "context/Context";
import LandingPage from "LandingPage";
import RequireGuest from "helpers/RequireGuest";
import Error404 from "components/errors/Error404";
import { CloseButton } from "react-bootstrap";
import AuthOutlet from "module/Authentication/AuthOutlet";
import LoginForm from "module/Authentication/Page/Login/LoginForm";
import AccountRecoveryForm from "module/Authentication/Page/AccountRecovery/AccountRecoveryForm";
import ResetPasswordForm from "module/Authentication/Page/ResetPassword/ResetPasswordForm";
import RequireAuth from "helpers/RequireAuth";
import DeleteContextProvider from "providers/DeleteContextProvider";
import ExportContextProvider from "providers/ExportContextProvider";
import Dashboard from "module/Dashboard";
import EmployeeOutlet from "module/Employee/EmployeeOutlet";
import EmployeeTableView from "module/Employee/EmployeeTable/EmployeeTableView";
import Leads from "module/Leads/Leads";
import LeadTableView from "module/Leads/Lead/LeadTableView";
import LeadStatusTableView from "module/Leads/LeadStatus/LeadStatusTableView";
import LeadStatusForm from "module/Leads/LeadStatus/LeadStatusForm";
import CampaignTableView from "module/Campaign/CampaignTable/CampaignTableView";
import CampaignForm from "module/Campaign/CampaignForm";
import TeamTableView from "module/Teams/Team/TeamTable/TeamTableView";
import TeamForm from "module/Teams/Team/TeamForm";
import EmployeeProfile from "module/Employee/EmployeeProfile/EmployeeProfile";
import LeadSourceTableView from "module/Leads/LeadSource/LeadSourceTableView";
import LeadSourceForm from "module/Leads/LeadSource/LeadSourceForm";
import DepartmentTableView from "module/Department/DepartmentTable/DepartmentTableView";
import DepartmentForm from "module/Department/DepartmentForm";
import LeadOutlet from "module/Leads/Lead/LeadOutlet";
import DesignationTableView from "module/Designation/DesignationTable/DesignationTableView";
import DesignationForm from "module/Designation/DesignationForm";
import ProjectForm from "module/Project/ProjectForm/ProjectForm";
import TodoTableView from "module/Todo/TodoTable/TodoTableview";
import TodoForm from "module/Todo/TodoForm";
import EstimationTableView from "module/Estimation/EstimationTable/EstimationTableView";
import EstimationForm from "module/Estimation/EstimationForm";
import EstimationOutlet from "module/Estimation/EstimationOutlet";
import UserProfile from "module/common/ViewPages/Common/UserProfile/UserProfile";
import TaskForm from "module/Task/TaskForm/TaskForm";
import TaskInfo from "module/Task/TaskInfo/TaskInfo";
import TaskTable from "module/Task/TaskTable/TaskTable";
import TicketDashboard from "module/Tickets/TicketDashboard/TicketDashboard";
import TicketForm from "module/Tickets/TicketForm/TicketForm";
import TicketTabs from "module/Tickets/TicketTabs/TicketTabs";
import TicketTypeTable from "module/Tickets/TicketType/TicketTypeTable";
import TicketTypeForm from "module/Tickets/TicketType/TicketTypeForm";
import LabelsTable from "module/Labels/LabelsTable";
import LabelsForm from "module/Labels/LabelsForm";
import AnnouncementTableView from "module/Announcement/AnnouncementTableView";
import AnnouncementForm from "module/Announcement/AnnouncementForm";
import TaskOverview from "module/Task/TaskInfo/TaskOverview";
import TaskDashboard from "module/Task/TaskDashboard/TaskDashboard";
import Report from "module/Report/Report";
import ProjectInfo from "module/Project/ProjectInfo/ProjectInfo";
import TicketInfo from "module/Tickets/TicketInfo/TicketInfo";
import TicketOverView from "module/Tickets/TicketOveView/TicketOverView";
import LeadInfo from "module/Leads/Lead/LeadInfo/LeadInfo";
import ClientViewWrapper from "module/Client/ClientViewWrapper";
import ClientForm from "module/Client/ClientForm/ClientForm";
import ClientOutlet from "module/Client/ClientOutlet";
import ClientDashboard from "module/Client/ClientDashboard/ClientDashboard";
import TicketConversionForm from "module/Tickets/TicketConversion/TicketConversionForm";
import TeamInfo from "module/Teams/TeamInfo/TeamInfo";
import EmployeeOverview from "module/Employee/EmployeeOverview/EmployeeOverview";
import ClientOverView from "module/Client/ClientOverView/ClientOverView";
import Opportunity from "module/Opportunity/Opportunity";
import OpportunityForm from "module/Opportunity/OpportunityForm";
import OverView from "module/Opportunity/OverView/OverView";
import OpportunityOutlet from "module/Opportunity/OpportunityOutlet";
import EnquiryForm from "components/enquiry/EnquiryForm";
import Enquiry from "module/Enquiry/Enquiry";
import EnquiryInnerForm from "module/Enquiry/EnquiryInnerForm";
import ProjectReportView from "module/Report/ReportView/ProjectReportView";
import TasksReportView from "module/Report/ReportView/TasksReportView";
import Invitation from "module/Invitation/Invitation";
import InvitationRegister from "module/Invitation/InvitationRegister";
import InvitationForm from "module/Invitation/InvitationForm";
import LeadsReportView from "module/Report/ReportView/LeadsReportView";
import OpportunityReportView from "module/Report/ReportView/OpportunityReportView";
import TimeTrackerReportView from "module/Report/ReportView/TimeTrackerReportView";
import LeadConversionReport from "module/Report/ReportView/LeadConversionReport";
import EmployeeLeadSummaryReport from "module/Report/ReportView/EmployeeLeadSummaryReport";
import EmployeeTaskReport from "module/Report/ReportView/EmployeeTaskReport";
import EmployeeTaskSummaryReport from "module/Report/ReportView/EmployeeTaskSummaryReport";
import Error403 from "components/errors/Error403/Error403";
import RunningOrCompletedTasksReport from "module/Report/ReportView/RunningOrCompletedTasksReport";
import StoreProvider from "providers/StoreProvider";
import QuotationOverview from "module/Quotation/Overview/QuotationOverview";
import ProductsTableView from "module/Products/ProductsTableView";
import ProductsForm from "module/Products/ProductsForm";
import SprintForm from "module/Sprints/SprintForm/SprintForm";
import SprintOverview from "module/Sprints/SprintOverview/SprintOverview";
import SprintTable from "module/Sprints/SprintTable/SprintTable";
import SprintDetailedReport from "module/Report/ReportView/SprintDetailedReport";
import TaskOutlet from "module/Task/TaskOutlet";
import ProjectViewWrapper from "module/Project/ProjectTable/ProjectViewWrapper";
import LeadSourceSummeryReport from "module/Report/ReportView/LeadSourceSummeryReport";
import LeadSourceSummeryItemReport from "module/Report/ReportView/LeadSourceSummeryItemReport";
import LeadOffcanvas from "module/Leads/Lead/LeadInfo/LeadOffcanvas";
const Layout = () => {
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add("windows");
    }
    if (is.chrome()) {
      HTMLClassList.add("chrome");
    }
    if (is.firefox()) {
      HTMLClassList.add("firefox");
    }
  }, [HTMLClassList]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<LandingPage />} />

        {/*- ------------- Authentication ---------------------------  */}

        <Route
          path="authentication"
          element={
            <RequireGuest>
              <AuthOutlet />
            </RequireGuest>
          }
        >
          <Route index element={<LoginForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="account-recovery" element={<AccountRecoveryForm />} />
          <Route path="reset-password" element={<ResetPasswordForm />} />
        </Route>

        <Route
          path="/"
          element={
            <RequireAuth>
              <StoreProvider>
                <DeleteContextProvider>
                  <ExportContextProvider>
                    <MainLayout />
                  </ExportContextProvider>
                </DeleteContextProvider>
              </StoreProvider>
            </RequireAuth>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="employee" element={<EmployeeOutlet />}>
            <Route index element={<EmployeeTableView />} />
            <Route path="add" element={<EmployeeProfile />} />
            <Route path="edit/:itemId" element={<EmployeeProfile />} />
            <Route path="overview/:empId" element={<EmployeeOverview />} />
          </Route>
          <Route path="leads">
            <Route index element={<Leads />} />
            <Route path="lead" element={<LeadOutlet />}>
              <Route index element={<LeadTableView />} />
              <Route path="info/:overviewId" element={<LeadInfo />} />
            </Route>
            <Route path="lead-status" element={<LeadStatusTableView />}>
              <Route path="add" element={<LeadStatusForm />} />
              <Route path="edit/:itemId" element={<LeadStatusForm />} />
            </Route>
            <Route path="lead-source" element={<LeadSourceTableView />}>
              <Route path="add" element={<LeadSourceForm />} />
              <Route path="edit/:itemId" element={<LeadSourceForm />} />
            </Route>
          </Route>
          <Route path="teams" element={<TeamTableView />}>
            <Route path="add" element={<TeamForm />} />
            <Route path="info/:teamId" element={<TeamInfo />} />
            <Route path="edit/:itemId" element={<TeamForm />} />
          </Route>
          <Route path="campaign" element={<CampaignTableView />}>
            <Route path="add" element={<CampaignForm />} />
            <Route path="edit/:itemId" element={<CampaignForm />} />
          </Route>
          <Route path="department" element={<DepartmentTableView />}>
            <Route path="add" element={<DepartmentForm />} />
            <Route path="edit/:itemId" element={<DepartmentForm />} />
          </Route>
          <Route path="designation" element={<DesignationTableView />}>
            <Route path="add" element={<DesignationForm />} />
            <Route path="edit/:itemId" element={<DesignationForm />} />
          </Route>

          <Route path="project" element={<ProjectViewWrapper />}>
            <Route path="add" element={<ProjectForm />} />
            <Route path="edit/:itemId" element={<ProjectForm />} />
            <Route path="info/:itemInfoId" element={<ProjectInfo />}>
              <Route path="ticket/add" element={<TicketForm />} />
              <Route path="ticket/edit/:itemId" element={<TicketForm />} />
              <Route path="ticket/info/:itemId" element={<TicketInfo />} />
              <Route path="sprints/add" element={<SprintForm />} />
              <Route path="sprints/edit/:itemId" element={<SprintForm />} />
              <Route path="task/:itemId" element={<TaskInfo />} />
              <Route path="task/add" element={<TaskForm />} />
              <Route path="task/edit/:itemId" element={<TaskForm />} />
            </Route>
            <Route path="profile/:profileId" element={<UserProfile />} />
          </Route>

          <Route path="sprints" element={<SprintTable />}>
            <Route path="add" element={<SprintForm />} />
            <Route path="edit/:itemId" element={<SprintForm />} />
            <Route path="info/:itemInfoId" element={<SprintOverview />}>
              <Route path="task/:itemId" element={<TaskInfo />} />
              <Route path="task/add" element={<TaskForm />} />
              <Route path="task/edit/:itemId" element={<TaskForm />} />
            </Route>
          </Route>

          <Route path="tasks" element={<TaskOutlet />}>
            <Route path="dashboard" element={<TaskDashboard />}>
              <Route path="add" element={<TaskForm />} />
            </Route>
            <Route path="add" element={<TaskForm />} />
            <Route path="list" element={<TaskTable />}>
              <Route path="add" element={<TaskForm />} />
              <Route path="edit/:itemId" element={<TaskForm />} />
              <Route path="info/:itemId" element={<TaskInfo />} />
              <Route path="overview/:overviewId" element={<TaskOverview />}>
                <Route path="edit/:itemId" element={<TaskForm />} />
                <Route path="info/:itemId" element={<TicketInfo />} />
              </Route>
            </Route>
          </Route>
          <Route path="todo" element={<TodoTableView />}>
            <Route path="add" element={<TodoForm />} />
            <Route path="edit/:itemId" element={<TodoForm />} />
          </Route>
          <Route path="estimation" element={<EstimationOutlet />}>
            <Route index element={<EstimationTableView />} />
            <Route path="add" element={<EstimationForm />} />
            <Route path="edit/:itemId" element={<EstimationForm />} />
          </Route>

          <Route path="tickets" element={<TicketDashboard />}>
            <Route path="list" element={<TicketTabs />}>
              <Route path="add" element={<TicketForm />} />
              <Route path="edit/:itemId" element={<TicketForm />} />
              <Route path="info/:itemId" element={<TicketInfo />} />
              <Route path="profile/:profileId" element={<UserProfile />} />
              <Route path="overview/update/:itemId" element={<TicketForm />} />
              <Route path="overview/:overviewId" element={<TicketOverView />}>
                <Route path="add" element={<TodoForm />} />
              </Route>
            </Route>

            <Route path="add" element={<TicketForm />} />
            <Route path="edit/:itemId" element={<TicketForm />} />
            <Route
              path="convert/:ticketId"
              element={<TicketConversionForm />}
            />

            <Route path="types" element={<TicketTypeTable />}>
              <Route path="add" element={<TicketTypeForm />} />
              <Route path="edit/:itemId" element={<TicketTypeForm />} />
            </Route>
          </Route>

          <Route path="labels" element={<LabelsTable />}>
            <Route path="add" element={<LabelsForm />} />
            <Route path="edit/:itemId" element={<LabelsForm />} />
          </Route>
          <Route path="announcement" element={<AnnouncementTableView />}>
            <Route path="add" element={<AnnouncementForm />} />
            <Route path="edit/:itemId" element={<AnnouncementForm />} />
          </Route>
          <Route path="reports">
            <Route index element={<Report />} />
            <Route path="project-report" element={<ProjectReportView />} />
            <Route path="task-report" element={<TasksReportView />} />
            <Route
              path="employee-task-report"
              element={<EmployeeTaskReport />}
            />
            <Route
              path="employee-task-summary-report"
              element={<EmployeeTaskSummaryReport />}
            />
            <Route
              path="employee-lead-report"
              element={<EmployeeLeadSummaryReport />}
            />
            <Route path="leads-report" element={<LeadsReportView />} />
            <Route
              path="opportunity-report"
              element={<OpportunityReportView />}
            />
            <Route
              path="time-tracker-report"
              element={<TimeTrackerReportView />}
            />
            <Route
              path="lead-conversion-report"
              element={<LeadConversionReport />}
            />
            <Route
              path="lead-source-summary-report"
              element={<LeadSourceSummeryReport />}
            />
            <Route
              path="lead-source-summary-report/:lead_source_id"
              element={<LeadSourceSummeryItemReport />}
            />
            <Route
              path="running-completed-tasks-report"
              element={<RunningOrCompletedTasksReport />}
            />
            <Route
              path="sprint-detailed-report"
              element={<SprintDetailedReport />}
            />
          </Route>
          <Route path="account" element={<ClientOutlet />}>
            <Route index element={<ClientDashboard />} />
            <Route path="grid" element={<ClientViewWrapper />} />
            <Route path="list" element={<ClientViewWrapper />} />
            <Route path="add" element={<ClientForm />} />
            <Route path="edit/:customerId" element={<ClientForm />} />
            <Route path="overview/:client_id" element={<ClientOverView />} />
          </Route>
          <Route path="opportunity" in element={<OpportunityOutlet />}>
            <Route index element={<Opportunity />} />
            <Route path="add" element={<OpportunityForm />} />
            <Route path="edit/:itemId" element={<OpportunityForm />} />
            <Route path="overview/:itemId" element={<OverView />}>
              <Route path="lead/:overviewId" element={<LeadOffcanvas />} />
              <Route
                path="quotation/:quoteId"
                element={<QuotationOverview />}
              />
            </Route>
          </Route>
          <Route path="enquiries" element={<Enquiry />}>
            <Route path="edit/:enqId" element={<EnquiryInnerForm />} />
          </Route>
          <Route path="invitation" element={<Invitation />}>
            <Route path="edit/:invId" element={<InvitationForm />} />
          </Route>
          <Route path="products" element={<ProductsTableView />}>
            <Route path="add" element={<ProductsForm />} />
            <Route path="edit/:itemId" element={<ProductsForm />} />
          </Route>
          <Route path="settings">
            <Route path="system-settings">
              <Route path="labels" element={<LabelsTable />}>
                <Route path="add" element={<LabelsForm />} />
                <Route path="edit/:itemId" element={<LabelsForm />} />
              </Route>
            </Route>
            <Route path="lead-settings">
              <Route path="lead-status" element={<LeadStatusTableView />}>
                <Route path="add" element={<LeadStatusForm />} />
                <Route path="edit/:itemId" element={<LeadStatusForm />} />
              </Route>
              <Route path="lead-source" element={<LeadSourceTableView />}>
                <Route path="add" element={<LeadSourceForm />} />
                <Route path="edit/:itemId" element={<LeadSourceForm />} />
              </Route>
            </Route>
            <Route path="employee-settings">
              <Route path="department" element={<DepartmentTableView />}>
                <Route path="add" element={<DepartmentForm />} />
                <Route path="edit/:itemId" element={<DepartmentForm />} />
              </Route>
              <Route path="designation" element={<DesignationTableView />}>
                <Route path="add" element={<DesignationForm />} />
                <Route path="edit/:itemId" element={<DesignationForm />} />
              </Route>
            </Route>
            <Route path="ticket-settings">
              <Route path="types" element={<TicketTypeTable />}>
                <Route path="add" element={<TicketTypeForm />} />
                <Route path="edit/:itemId" element={<TicketTypeForm />} />
              </Route>
            </Route>
          </Route>
        </Route>
        {/*- ------------- Authentication ---------------------------  */}

        <Route path="enquiry" element={<EnquiryForm />} />
        <Route path="invitation/:id" element={<InvitationRegister />} />
        <Route path="errors/404" element={<Error404 />} />
        <Route path="*" element={<Error404 />} />
        <Route path="errors/403" element={<Error403 />} />
      </Routes>

      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </Fragment>
  );
};

export default Layout;
