import axios from "axios";
import { apiCall } from "helpers/apiCalls";
import { partnersFormKeys, partnersItemFormKeys } from "helpers/formKeys";
import setNewFormData from "helpers/setNewFormData";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { FaPlusCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import { v4 as uuid } from "uuid";

const Partners = ({ itemId }) => {
  const [formData, setFormData] = useState(partnersItemFormKeys);
  const [partnersFormData, setPartnersFormData] = useState({
    ...partnersFormKeys,
    opportunity_id: itemId,
  });
  const [existingPartners, setExistingPartners] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [loadingExisting, setLoadingExisting] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddPartner = () => {
    setPartnersFormData((prev) => ({
      ...prev,
      partners: [...prev.partners, { ...formData, id: uuid() }],
    }));
    setFormData(partnersItemFormKeys);
    setIsChanged(true);
  };

  const handleClearAll = () => {
    setFormData(partnersItemFormKeys);
  };

  const handleDeletePartner = (id, from) => {
    setIsChanged(true);
    if (from === "new") {
      setPartnersFormData((prev) => ({
        ...prev,
        partners: prev?.partners?.filter((item) => item.id !== id),
      }));
    } else {
      setExistingPartners((prev) => prev.filter((item) => item.id !== id));
    }
  };

  const getExistingPartners = async () => {
    setLoadingExisting(true);
    if (itemId) {
      const data = await apiCall({
        url: `crm/opportunity-list-partners`,
        params: {
          opportunity_id: itemId,
        },
      });
      setExistingPartners(data ? data : []);
    }
    setLoadingExisting(false);
  };

  useEffect(() => {
    getExistingPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreatePartners = (e) => {
    e.preventDefault();
    setOnSave(true);
    axios({
      method: "post",
      url: `crm/opportunity-add-update-partners`,
      data: setNewFormData({
        ...partnersFormData,
        partners: [...partnersFormData.partners, ...existingPartners],
      }),
    })
      .then((response) => {
        if (response.data.success === true) {
          setPartnersFormData({
            ...partnersFormKeys,
            opportunity_id: itemId,
            partners: [],
          });
          getExistingPartners();
          setIsChanged(false);
          showToast(response.data.message, "success");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormErrors({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <Row>
      <Col xs={12}>
        <Card className="d-flex shadow-none">
          <Card.Header
            className="rounded-0 pb-2 pt-2"
            style={{ backgroundColor: "#d2d4e9" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="">PARTNERS INFORMATION</div>
            </div>
          </Card.Header>
          <Card.Body
            className=" rounded-0"
            style={{
              height: "64.7vh",
              overflow: "auto",
            }}
          >
            <div className="border p-3">
              <Form id="partners" as={Row} className="mb-3 gy-3">
                <Form.Group as={Col} md={4} className="mt-2">
                  <Form.Label className="require-data">Partner Name</Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="name"
                      onChange={handleFieldChange}
                      value={formData?.name}
                      isInvalid={!!formErrors.name}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formErrors.name}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} md={4} className="mt-2">
                  <Form.Label className="require-data">Partner Role</Form.Label>
                  <Col>
                    <Form.Control
                      type="text"
                      name="role"
                      onChange={handleFieldChange}
                      value={formData?.role}
                      isInvalid={!!formErrors.role}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formErrors.role}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} md={4}>
                  <div className="d-flex h-100 align-items-end gap-2">
                    <Button
                      size="sm"
                      variant="transparent"
                      className="custom-transperent-common-button border-0 p-0"
                      title="Clear All"
                      disabled={formData.name || formData.role ? false : true}
                      onClick={handleClearAll}
                    >
                      <FaTimesCircle color="#ef2828" size={25} />
                    </Button>
                    <Button
                      size="sm"
                      variant="transparent"
                      className="custom-transperent-common-button border-0 p-0"
                      disabled={formData.name && formData.role ? false : true}
                      onClick={handleAddPartner}
                    >
                      <FaPlusCircle color="#41af41" size={25} />
                    </Button>
                  </div>
                </Form.Group>
              </Form>
              {partnersFormData?.partners?.length > 0 && (
                <ListGroup>
                  <ListGroupItem className={`rounded-0`}>
                    <span className="fw-semibold">NEW PARTNERS</span>
                  </ListGroupItem>
                  {partnersFormData?.partners?.map((item, index) => (
                    <ListGroupItem
                      className={`rounded-0 overflow-auto`}
                      key={index}
                    >
                      <div className="row">
                        <div className="d-flex flex-column justify-content-center col-md-4 col-sm-4">
                          <span className="text-mute custom-text-muted-crm">
                            Partner Name
                          </span>
                          <span className="fw-bold text-capitalize">
                            {item?.name}
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center col-md-4 col-sm-4">
                          <span className="text-mute custom-text-muted-crm">
                            Partner Role
                          </span>
                          <span className="fw-bold text-capitalize">
                            {item?.role}
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center align-items-center col-md-4 col-sm-4">
                          <Button
                            variant="transparent"
                            size="sm"
                            className="custom-transperent-common-button p-0 ms-2 ms-auto"
                            onClick={() => {
                              handleDeletePartner(item.id, "new");
                            }}
                          >
                            <FaTrash color="#dc3939" />
                          </Button>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              )}

              <div className="mt-2">
                <ListGroup>
                  <ListGroupItem className={`rounded-0`}>
                    <span className="fw-semibold">EXISTING PARTNERS</span>
                  </ListGroupItem>
                  {existingPartners?.length > 0 && !loadingExisting ? (
                    existingPartners?.map((item, key) => (
                      <ListGroupItem
                        className={`rounded-0 overflow-auto`}
                        key={key}
                      >
                        <div className="row">
                          <div className="d-flex flex-column justify-content-center col-md-4 col-sm-4">
                            <span className="text-mute custom-text-muted-crm">
                              Partner Name
                            </span>
                            <span className="fw-bold text-capitalize">
                              {item?.name}
                            </span>
                          </div>
                          <div className="d-flex flex-column justify-content-center col-md-4 col-sm-4">
                            <span className="text-mute custom-text-muted-crm">
                              Partner Role
                            </span>
                            <span className="fw-bold text-capitalize">
                              {item?.role}
                            </span>
                          </div>
                          <div className="d-flex flex-column justify-content-center align-items-center col-md-4 col-sm-4">
                            <Button
                              variant="transparent"
                              size="sm"
                              className="custom-transperent-common-button p-0 ms-2 ms-auto"
                              onClick={() => {
                                handleDeletePartner(item.id, "existing");
                              }}
                            >
                              <FaTrash color="#dc3939" />
                            </Button>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))
                  ) : (
                    <>
                      {loadingExisting ? (
                        <ListLoading
                          className="border border-top-0 rounded-0"
                          style={{
                            height: "20vh",
                            overflow: "hidden",
                          }}
                        />
                      ) : (
                        <ListGroupItem
                          className={`rounded-0 d-flex justify-content-center align-items-center`}
                          style={{ height: "20vh" }}
                        >
                          <span>
                            <BsDatabaseFillExclamation size={25} /> No Data
                            Found
                          </span>
                        </ListGroupItem>
                      )}
                    </>
                  )}
                  {!loadingExisting && (
                    <ListGroupItem
                      className={`rounded-0 d-flex justify-content-end border-0 mt-2`}
                    >
                      <Button
                        size="sm"
                        variant="success"
                        onClick={handleCreatePartners}
                        disabled={isChanged && !onSave ? false : true}
                      >
                        {onSave ? "Saving Changes..." : " Save Changes"}
                      </Button>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Partners;
