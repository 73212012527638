import { React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import AppDatePicker from "components/app-date-picker/AppDatePicker";

function DateInputField({
  title,
  name,
  value,
  error,
  formError,
  handleFieldChange,
  required,
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group
      as={Col}
      xxl={4}
      xl={6}
      md={6}
      sm={12}
      className={"mb-3"}
      controlId={"date"}
    >
      <Row>
        <Col xl={4} md={4} sm={12}>
          <Form.Label
            id="date"
            className={
              required
                ? "require-data m-0 d-flex align-items-center justify-content-start h-100"
                : "m-0 d-flex align-items-center justify-content-start h-100"
            }
          >
            {Translate(title)}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="flex-2">
              <AppDatePicker
                name={name}
                value={value}
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
              />
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              <FormErrorPopover
                id={name}
                totalErrorCount={Object.keys(formError).length}
                errorMessage={error}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

DateInputField.propTypes = {
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.any,
};

export default DateInputField;
