import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";

import { AiOutlineMenu } from "react-icons/ai";

import { apiCall } from "helpers/apiCalls";
import { timeTrackerReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import TimeTrackerReportFilterSection from "../ReportFilterSection/TimeTrackerReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";

const TimeTrackerReportView = () => {
  const [queryParams] = useSearchParams();
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [timeTrackerReport, setTimeTrackerReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(timeTrackerReportFilterKeys);

  const keysToCheck = [
    "date_range_for",
    "project_id",
    "account_id",
    "contributor_id",
    "task_id",
  ];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];

  const handleCanvas = () => setShow(!show);

  const getProjectReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    if (!parsedFilterItems.date_range_for) {
      parsedFilterItems.from_date = "";
      parsedFilterItems.to_date = "";
    }
    let additionalRemoveField = "date_range";
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems, additionalRemoveField)
    );

    try {
      const data = await apiCall({
        url: `crm/time-tracker-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setTimeTrackerReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}

            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/time-tracker-report"
                filters={filterFields}
                setFilters={setFilterFields}
                columns={columns}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/time-tracker-reports/export"}
                  successMessage={"Time Tracker Report Export Completed"}
                  initialMessage={"Time Tracker Report Export Started"}
                  enableSendMail
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <TimeTrackerReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
              filterValuesExist={filterValuesExist}
              columns={columns}
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            <span className="fs--1 align-self-center">{company_name}</span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Time Tracker Report
            </Link>
          </div>
          <div className="mt-4">
            {!dataProcessing ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#00185894",
                      color: "whitesmoke",
                    }}
                  >
                    <th className="fw-bold text-light pt-2 pb-2">#</th>
                    <th className="fw-bold text-light pt-2 pb-2">TASK</th>
                    <th className="fw-bold text-light pt-2 pb-2">EMPLOYEE</th>
                    <th className="fw-bold text-light pt-2 pb-2">PROJECT</th>

                    <th className="fw-bold text-light pt-2 pb-2" width="6%">
                      START TIME
                    </th>

                    <th className="fw-bold text-light pt-2 pb-2" width="5%">
                      END TIME
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2" width="7%">
                      TOTAL TIME
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(timeTrackerReport).length > 0 &&
                  timeTrackerReport.data.length > 0 ? (
                    timeTrackerReport.data.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td className="text-capitalize">
                          <Link
                            className="text-dark"
                            to={`/tasks/list/overview/${item.task_id}`}
                          >
                            {item.task_name}
                          </Link>
                        </td>
                        <td className="text-capitalize">{item.contributor}</td>
                        <td className="text-capitalize">{item.project_name}</td>
                        <td> {item.timer_start_time}</td>
                        <td> {item.timer_end_time}</td>
                        <td>{item.total_time_tracked}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={8} className="text-center">
                        No Records Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <div
                className="d-flex justify-content-center align-item-center"
                style={{ minHeight: "48vh" }}
              >
                <LoadingScreen message="Loading Time Tracker Report" />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {timeTrackerReport && timeTrackerReport.links && !dataProcessing && (
            <ReportPagination
              data={timeTrackerReport}
              url="/reports/time-tracker-report"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </>
  );
};

export default TimeTrackerReportView;
