import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonInvitationFilterFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import { showToast } from "module/common/Toast/toast";
import ColumnSelector from "module/common/Filter/ColumnSelector";
import DateRangePicker from "module/common/Filter/DateRangePicker";
import SelectRole from "components/form/SelectRole";

const CommonInvitationFilter = () => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const [filterFields, setFilterFields] = useState(commonInvitationFilterFormKeys);
    const allQueryParams = Object.fromEntries([...queryParams]);
    const { to_email, date_range, date_range_for, from_date, to_date, role_id } = allQueryParams;

    const columns = [
        { key: "start_date", value: "Start Date" },
        { key: "end_date", value: "End Date" },
    ];

    const handleFieldChange = (e, action) => {
        let newOldData;
        if (action) {
            newOldData = {
                ...filterFields,
                [action.name]: e ? e.value : null,
                [action.name + "_ref"]: e,
            };
        } else {
            if (e.target.name === "file") {
                newOldData = {
                    ...filterFields,
                    [e.target.name]: e.target.files[0],
                };
            } else {
                newOldData = {
                    ...filterFields,
                    [e.target.name]:
                        e.target.type === "checkbox"
                            ? !e.target.checked
                                ? 0
                                : 1
                            : e.target.value,
                };
            }
        }
        setFilterFields(newOldData);
    };

    const handleFilterApply = () => {
        if (!filterFields?.date_range_for && filterFields?.date_range) {
            showToast(`Please Select Date Field`, "error");
            return;
        }

        if (filterFields?.date_range === "custom_date" && !filterFields?.from_date) {
            showToast(`Please Select From Date`, "error");
            return;
        } else if (
            filterFields?.date_range === "custom_date" &&
            !filterFields?.to_date
        ) {
            showToast(`Please Select To Date`, "error");
            return;
        }

        let formDataWithoutRefKeys = removeRefData(filterFields);
        navigate(
            `?${createSearchParams(
                removeEmptyFields({
                    ...formDataWithoutRefKeys,
                    page: 1,
                })
            )}`
        );
    };

    useEffect(() => {
        setFilterFields((previous) => ({
            ...previous,
            date_range: date_range ? date_range : "",
            date_range_for: date_range_for ? date_range_for : "",
            from_date: from_date ? from_date : "",
            to_date: to_date ? to_date : "",
            to_email: to_email ? to_email : "",
            role_id: role_id ? role_id : "",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetFilter = () => {
        setFilterFields(commonInvitationFilterFormKeys);
    };

    return (
        <div className="p-3 border-bottom">
            <Row className="g-3">
                <Col md={2}>
                    <Row>
                        <Form.Group as={Col}>
                            <ColumnSelector
                                title="Date Field"
                                labelClassName="fs--1"
                                columns={columns}
                                filters={filterFields}
                                setFilters={setFilterFields}
                                defaultOption={true}
                                value={filterFields?.date_range_for}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="fs--1">Date Range</Form.Label>
                            <DateRangePicker
                                formData={filterFields}
                                className={"custom-date-range-picker"}
                                setFormData={setFilterFields}
                                dateRange_form={{
                                    to: "to_date",
                                    from: "from_date",
                                    range: "date_range",
                                    month: "month",
                                }}
                            />
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Form.Group as={Col} controlId="validationCustomEmail">
                            <Form.Label className="fs--1">Email</Form.Label>
                            <Col>
                                <Form.Control
                                    type="email"
                                    name="to_email"
                                    onChange={handleFieldChange}
                                    value={filterFields?.to_email}
                                />
                            </Col>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Form.Group className="mb-3" controlId="form.ControlInput2">
                            <Form.Label>Role</Form.Label>
                            <SelectRole
                                name="role_id"
                                handleFieldChange={handleFieldChange}
                                value={filterFields.role_id_ref}
                            />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0">
                <Form.Group
                    as={Col}
                    md={2}
                    className="d-flex justify-content-end w-100 p-0"
                >
                    <div className="d-flex align-items-end gap-2 mt-3">
                        <Button
                            size="sm"
                            variant="warning"
                            onClick={handleFilterApply}
                            title="Apply Added Filters"
                        >
                            Apply
                        </Button>
                        <Button
                            size="sm"
                            variant="transparent"
                            className="shadow-sm"
                            onClick={handleResetFilter}
                            title="Clear All Filters"
                        >
                            <FcClearFilters size={18} />
                        </Button>
                    </div>
                </Form.Group>
            </Row>
        </div>
    );
};

export default CommonInvitationFilter;
