import { React, useState } from "react";
import axios from "axios";
import { Button, Modal, Table } from "react-bootstrap";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsHandbagFill } from "react-icons/bs";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import KitLineItemForm from "./KitLineItemForm";
import KitLineItem from "./KitLineItem";
import TableLoading from "module/common/Loading/TableLoading";
import ItemDetails from "components/item-deatails/ItemDetails";
import { Link } from "react-router-dom";

const KitDetailModal = ({
  kit,
  kitName,
  readOnly,
  itemIsAdding,
  setModifiedKitFullDetails,
  formData,
  kitItems,
  setKitItems,
  batch,
  children,
}) => {
  readOnly = readOnly ? readOnly : false;
  const Translate = useAxisproTranslate();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(!show);
  const [value, setValue] = useState({ value: 0 });
  const [showItemDetailsArea, setShowItemDetailsArea] = useState(false);
  const handleEditItem = (data, index) => {
    const prevData = kit.kit_items[index];
    if (prevData) {
      data.edit_ref = true;
      kit[index] = data;
      setModifiedKitFullDetails((prev) => ({
        ...prev,
        ...kit,
      }));
    }
  };

  const clickOnItem = (id) => {
    setValue({ value: id });
    setShowItemDetailsArea(true);
  };
  const handleItemDetailsArea = () => {
    setShowItemDetailsArea(!showItemDetailsArea);
    setValue({ value: 0 });
  };
  const handleDeleteItem = (data, index) => {
    const updatedKit = { ...kit };
    const updatedKitItems = [...updatedKit.kit_items];

    if (index >= 0 && index < updatedKitItems.length) {
      updatedKitItems.splice(index, 1);

      updatedKit.kit_items = updatedKitItems;

      setModifiedKitFullDetails((prev) => ({
        ...prev,
        ...updatedKit,
      }));

      const deletedItem = kitItems.filter(
        (kitItem) => kitItem.stock_id !== data.stock_id
      );

      if (deletedItem && deletedItem.length > 0) {
        setKitItems(deletedItem);
      }
    }
  };

  const addToCart = (data, index) => {
    const prevData = kit.kit_items[index];
    if (prevData) {
      let newData = {
        iskit: 0,
        item_display_name: data.item_display_name,
        item_display_name_ref: data.item_display_name,
        line_total: 0,
        quantity: data.quantity,
        remove: true,
        sales_type_id: formData.sales_type_id,
        stock_id: data.stock_id,
        trans_date: formData.trans_date,
        unit_price: data.unit_price,
        batch_number: data.batch_number,
        batch_number_ref: data.batch_number_ref,
      };

      axios
        .post("sales/add-to-cart", newData)
        .then((response) => {
          if (response.data.data) {
            data.line_total_formatted = response.data.data.line_total_formatted;
            data.edit_ref = false;
            data.batch_number = response.data.data.batch_number;
            data.batch_number_ref = response.data.data.batch_number_ref;
            kit[index] = data;
            setModifiedKitFullDetails((prev) => ({
              ...prev,
              ...kit,
            }));

            const updatedKitItems = kitItems.map((kitItem) => {
              if (kit.kit_items && kit.kit_items.length > 0) {
                const matchingDataItem = kit.kit_items.find(
                  (dataItem) => dataItem.stock_id === kitItem.stock_id
                );
                if (matchingDataItem) {
                  return {
                    ...kitItem,
                    quantity: matchingDataItem.base_quantity_ref,
                    batch_number: matchingDataItem.batch_number
                      ? matchingDataItem.batch_number
                      : "",
                    batch_number_ref: matchingDataItem.batch_number_ref
                      ? matchingDataItem.batch_number_ref
                      : "",
                  };
                }
              }
              return kitItem;
            });

            setKitItems(updatedKitItems);
          } else {
            data.edit_ref = false;
            kit[index] = data;
            setModifiedKitFullDetails((prev) => ({
              ...prev,
              ...kit,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
          data.edit_ref = false;
          kit[index] = data;
          setModifiedKitFullDetails((prev) => ({
            ...prev,
            ...kit,
          }));
        });
    }
  };

  return (
    <>
      {!children ? (
        <Button
          size="sm"
          variant="transparent"
          className="p-0 me-1 text-primary"
          title="Check kit details"
          onClick={handleShow}
          style={{ boxShadow: "none" }}
        >
          <FontAwesomeIcon icon="eye" size="sm" />
        </Button>
      ) : (
        <Link onClick={handleShow}>{children}</Link>
      )}
      <Modal
        show={show}
        onHide={handleShow}
        className="kit-items-modal-cover"
        dialogClassName="kit-items-modal-view"
      >
        <Modal.Header closeButton>
          <div className="d-flex flex-row align-items-center text-dark">
            <BsHandbagFill style={{ fill: "red" }} size={20} className="me-2" />
            <p className="m-0 text-uppercase" style={{ fontSize: "1.1rem" }}>
              {kit?.item_display_name
                ? kit?.item_display_name
                : kitName
                ? kitName
                : ""}
            </p>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="text-muted mb-1" style={{ fontSize: "0.55rem" }}>
            {Translate("**TOTAL QUANTITY = KIT QUANTITY * ITEM QUANTITY")}
          </p>
          <Table
            striped
            bordered
            responsive
            hover={false}
            className="simple-table-animation"
          >
            <thead
              className="text-dark text-uppercase text-white"
              style={{ backgroundColor: "black" }}
            >
              <tr>
                <th
                  className="table-header-size text-center"
                  style={{ width: "40px" }}
                >
                  #
                </th>
                <th
                  className="table-header-size text-center"
                  style={{ width: "100px" }}
                >
                  {Translate("Item Code")}
                </th>
                <th
                  style={{ minWidth: "300px" }}
                  className="table-header-size text-start"
                >
                  {Translate("Item Name")}
                </th>
                {batch ? (
                  ""
                ) : (
                  <th
                    style={{ width: "150px" }}
                    className="table-header-size text-center"
                  >
                    {Translate("Batch")}
                  </th>
                )}
                <th
                  className="table-header-size text-center"
                  style={{ width: "100px" }}
                >
                  {Translate("Unit")}
                </th>
                {readOnly === false && (
                  <th
                    className="table-header-size text-center"
                    style={{ width: "100px" }}
                  >
                    {Translate("Quantity")}
                  </th>
                )}

                <th
                  className="table-header-size text-center"
                  style={{ width: "150px" }}
                >
                  {Translate("Total Quantity")}
                </th>
                <th
                  className="table-header-size text-center"
                  style={{ width: "150px" }}
                >
                  {Translate("Unit Price")}
                  {parseInt(kit.tax_included) ? (
                    <small> (Inc Tax)</small>
                  ) : null}
                </th>
                <th
                  className="table-header-size text-center"
                  style={{ minWidth: "120px" }}
                >
                  {Translate("Unit Tax")}
                </th>
                <th
                  className="table-header-size text-center"
                  style={{ width: "150px" }}
                >
                  {Translate("Discount")}
                </th>
                <th
                  className="table-header-size text-center"
                  style={{ minWidth: "150px" }}
                >
                  {Translate("Total")}
                </th>
                {readOnly === false && (
                  <th
                    style={{ width: "70px", display: "none" }}
                    className="table-header-size text-center"
                  >
                    {Translate("Action")}
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {itemIsAdding ? (
                <TableLoading row={1} column={10} />
              ) : kit && kit.length > 0 ? (
                kit.map((item, index) =>
                  !item.edit_ref ? (
                    <KitLineItem
                      key={index}
                      entry={item}
                      index={index}
                      handleEditItem={handleEditItem}
                      handleDeleteItem={handleDeleteItem}
                      batch={batch}
                      readOnly={readOnly}
                      clickOnItem={clickOnItem}
                    />
                  ) : (
                    <KitLineItemForm
                      key={index}
                      entry={item}
                      index={index}
                      quantity={kit.quantity}
                      addToCart={addToCart}
                      kit={kit}
                      setModifiedKitFullDetails={setModifiedKitFullDetails}
                      batch={batch}
                    />
                  )
                )
              ) : kit?.kit_items?.length > 0 ? (
                kit.kit_items.map((item, index) =>
                  !item.edit_ref ? (
                    <KitLineItem
                      key={index}
                      entry={item}
                      index={index}
                      handleEditItem={handleEditItem}
                      handleDeleteItem={handleDeleteItem}
                      batch={batch}
                      readOnly={readOnly}
                      clickOnItem={clickOnItem}
                    />
                  ) : (
                    <KitLineItemForm
                      key={index}
                      entry={item}
                      index={index}
                      quantity={kit.quantity}
                      addToCart={addToCart}
                      kit={kit}
                      setModifiedKitFullDetails={setModifiedKitFullDetails}
                      batch={batch}
                    />
                  )
                )
              ) : null}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      <ItemDetails
        show={showItemDetailsArea}
        onHide={handleItemDetailsArea}
        value={value}
        showItemDetailsArea={showItemDetailsArea}
      />
    </>
  );
};

KitDetailModal.propTypes = {
  kit: PropTypes.any,
  kitName: PropTypes.any,
  children: PropTypes.any,
  onTableItemChange: PropTypes.func,
  readOnly: PropTypes.bool,
  itemIsAdding: PropTypes.bool,
  setModifiedKitFullDetails: PropTypes.any,
  formData: PropTypes.any,
  kitItems: PropTypes.any,
  setKitItems: PropTypes.any,
  batch: PropTypes.bool,
};

export default KitDetailModal;
