import { AiOutlineDeploymentUnit } from "react-icons/ai";
import { BiSolidAnalyse } from "react-icons/bi";
import { FaBan } from "react-icons/fa";
import { GrInProgress } from "react-icons/gr";
import { IoIosBug } from "react-icons/io";
import { MdOutlineDescription, MdVerifiedUser } from "react-icons/md";
import { RiRefreshFill, RiTodoLine } from "react-icons/ri";
import { TbPlaceholder } from "react-icons/tb";
const getTaskStatusWithProps = (status, type, iconSize = 25) => {
  const statusWithColor = [
    {
      key: "to_do",
      value: "#9C8AFF",
      icon: <RiTodoLine color={"#9C8AFF"} size={iconSize} />,
    },
    {
      key: "in_progress",
      value: "#75CDFF",
      icon: <GrInProgress size={iconSize - 3} color={"#75CDFF"} />,
    },
    {
      key: "done",
      value: "#07bc0cb8",
      icon: <MdVerifiedUser size={iconSize} color={"#07bc0cb8"} />,
    },
    {
      key: "in_review",
      value: "#FC859D",
      icon: <BiSolidAnalyse size={iconSize + 2} color={"#FC859D"} />,
    },
    {
      key: "hold",
      value: "#FFA636",
      icon: <TbPlaceholder size={iconSize} color={"#FFA636"} />,
    },
    {
      key: "need_clarification",
      value: "#808080",
      icon: <MdOutlineDescription size={iconSize + 2} color={"#808080"} />,
    },
    {
      key: "bug",
      value: "#db3232",
      icon: <IoIosBug color={"#db3232"} size={iconSize + 2} />,
    },
    {
      key: "deployed",
      value: "#29C3BE",
      icon: <AiOutlineDeploymentUnit color={"#29C3BE"} size={iconSize} />,
    },
    {
      key: "cancelled",
      value: "#eb7936",
      icon: <FaBan color={"#eb7936"} size={iconSize} />,
    },
    {
      key: "reopened",
      value: "#b3ce68",
      icon: <RiRefreshFill color={"#b3ce68"} size={iconSize + 3} />,
    },
  ];

  if (status) {
    const foundObject = statusWithColor.find((obj) => obj.key === status);
    if (type === "icon") {
      return foundObject?.icon;
    } else {
      return status ? foundObject?.value ?? "grey" : foundObject?.icon;
    }
  } else {
    if (!type) {
      return statusWithColor;
    }
  }
};

export default getTaskStatusWithProps;
