import React, { useState } from "react";
import { Button, Card, Col, ListGroup, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import img1 from "../../../assets/img/icons/spot-illustrations/corner-1.png";
import img2 from "../../../assets/img/icons/spot-illustrations/corner-2.png";
import img3 from "../../../assets/img/icons/spot-illustrations/corner-3.png";
import Background from "components/common/Background";
import {
  AiOutlineFundProjectionScreen,
  AiOutlinePieChart,
  AiOutlineUserDelete,
} from "react-icons/ai";
import BarChart from "module/common/Charts/BarChart";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import GraphChart from "module/common/Charts/GraphChart";
import DoughnutChart from "module/common/Charts/DoughnutChart";
import ActivityLog from "module/common/ViewPages/Common/Activity/Timeline/ActivityLog";
import { useEffect } from "react";
import { apiCall } from "helpers/apiCalls";
import { BiUserCircle } from "react-icons/bi";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import { Link } from "react-router-dom";

const ClientDashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getDashboardData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/client-dashboard",
    });

    const statusData = await apiCall({
      url: "crm/project-status",
    });

    formatData(data, statusData);
    setIsLoading(false);
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatData = (data, statusData) => {
    let barChartLabels = [];
    let barDatasets = [];
    if (statusData.length > 0) {
      statusData.forEach((status, key) => {
        let dayWiseCount = [];
        Object.keys(data).length > 0 &&
          data.last_week_project_status_count[status].forEach((item) => {
            dayWiseCount.push(item.count);
            if (key === 0) {
              barChartLabels.push(item.day);
            }
          });

        barDatasets.push({
          label: status.toUpperCase(),
          data: dayWiseCount,
          backgroundColor:
            status === "cancelled"
              ? "#f2451cd9"
              : status === "hold"
              ? "#f49025d4"
              : status === "completed"
              ? "#43bf57"
              : "#4bc0c9",
          borderColor:
            status === "cancelled"
              ? "#f2451cd9"
              : status === "hold"
              ? "#f49025d4"
              : status === "completed"
              ? "#43bf57"
              : "#4bc0c9",
          borderWidth: 2,
        });
      });
    }
    setDashboardData({
      ...data,
      barLabels: barChartLabels,
      barDataSets: [...barDatasets],
    });
  };

  const dataBar = {
    labels: Object.keys(dashboardData).length > 0 && dashboardData.barLabels,
    datasets:
      Object.keys(dashboardData).length > 0 ? dashboardData.barDataSets : [],
  };

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Project Status",
        position: "top",
        align: "start",
        fontColor: "black",
        fontWeight: "bold",
      },
      subtitle: {
        display: true,
        text: "(Last Week)",
        fontSize: 14,
        fontColor: "gray",
        position: "top",
        align: "start",
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    elements: {
      bar: {
        borderRadius: 0,
      },
    },
  };

  const optionsGraph = {
    title: {
      text: "Accounts Sales Overview",
      subtext: "(Last 6 months)",
      left: "left",
      textStyle: {
        fontSize: 12,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    color: ["#B284BE"],
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: dashboardData?.sales_overview_graph
        ?.toReversed()
        .map((item) => item.month),
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        type: "line",
        data: dashboardData?.sales_overview_graph
          ?.toReversed()
          .map((item) => item.sales_count),
        smooth: true,
      },
    ],
  };

  const dataDoughnut = {
    labels:
      Object.keys(dashboardData).length > 0
        ? dashboardData.sales_type_wise_count.map(
            (item) => item.sales_type_name
          )
        : [],
    datasets: [
      {
        data:
          Object.keys(dashboardData).length > 0
            ? dashboardData.sales_type_wise_count.map((item) => item.count)
            : [],
        backgroundColor: [
          "#6baced",
          "#f59c64",
          "#c1f78b",
          "#64f0f5",
          "#b664f5",
        ],
        hoverBackgroundColor: [
          "#6baced",
          "#f59c64",
          "#c1f78b",
          "#64f0f5",
          "#b664f5",
        ],
      },
    ],
  };

  const optionsDoughnut = {
    cutout: "70%",

    plugins: {
      legend: {
        display: false,
        position: "bottom",
      },
      doughnutlabel: {
        labels: [
          {
            text: "Doughnut Chart",
            font: {
              size: 20,
            },
            color: "#000",
            position: "center",
          },
          {
            text: "Total: 41",
            font: {
              size: 16,
            },
            color: "#000",
            position: "inner",
          },
        ],
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  return (
    <>
      <Outlet />

      <div className="mb-3 mt-3">
        <Row className="gx-3 d-flex">
          <Col md={9}>
            <div className="w-100 d-flex gap-3 ">
              <Card
                className="w-100 pb-1 pt-1 card-main"
                style={{ minHeight: "5rem" }}
              >
                <Background image={img1} className="bg-card" />
                <Card.Body className="">
                  {!isLoading && Object.keys(dashboardData).length > 0 ? (
                    <Row className="">
                      <Col md={2}>
                        <span>
                          <BiUserCircle
                            size={35}
                            color="#fff"
                            style={{
                              backgroundColor: "#ff226c",
                              borderRadius: "5px",
                            }}
                          />
                        </span>
                      </Col>
                      <Col md={10}>
                        <div className="d-grid">
                          <span className="fw-bold fs-1">
                            {dashboardData.summary.all_clients_count}
                          </span>
                          <span>Total Accounts</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <DashboardTileLoader column={1} />
                  )}
                </Card.Body>
              </Card>
              <Card className="w-100 pb-1 pt-1 card-main">
                <Background image={img2} className="bg-card" />
                <Card.Body>
                  {!isLoading && Object.keys(dashboardData).length > 0 ? (
                    <Row className="">
                      <Col md={2}>
                        <span>
                          <AiOutlineFundProjectionScreen
                            size={35}
                            color="#fff"
                            style={{
                              backgroundColor: "#ff8922",
                              borderRadius: "5px",
                            }}
                          />
                        </span>
                      </Col>
                      <Col md={10}>
                        <div className="d-grid">
                          <span className="fw-bold fs-1">
                            {dashboardData.summary.all_projects_count}
                          </span>
                          <span>Total Projects</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <DashboardTileLoader column={1} />
                  )}
                </Card.Body>
              </Card>
              <Card className="w-100 pb-1 pt-1 card-main">
                <Background image={img3} className="bg-card" />
                <Card.Body>
                  {!isLoading && Object.keys(dashboardData).length > 0 ? (
                    <Row className="">
                      <Col md={2}>
                        <span>
                          <AiOutlineUserDelete
                            size={35}
                            color="#fff"
                            style={{
                              backgroundColor: "#3e8cff",
                              borderRadius: "5px",
                            }}
                          />
                        </span>
                      </Col>
                      <Col md={10}>
                        <div className="d-grid">
                          <span className="fw-bold fs-1">
                            {dashboardData.summary.inactive_clients_count}
                          </span>
                          <span>Inactive Accounts</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <DashboardTileLoader column={1} />
                  )}
                </Card.Body>
              </Card>
              <Card className="w-100 pb-1 pt-1 card-main">
                <Background image={img1} className="bg-card" />
                <Card.Body>
                  {!isLoading && Object.keys(dashboardData).length > 0 ? (
                    <Row className="">
                      <Col md={2}>
                        <span>
                          <AiOutlinePieChart
                            size={35}
                            color="#fff"
                            style={{
                              backgroundColor: "#1ccc77",
                              borderRadius: "5px",
                            }}
                          />
                        </span>
                      </Col>
                      <Col md={10}>
                        <div className="d-grid">
                          <span className="fw-bold fs-1">
                            {dashboardData.summary.completed_projects_count}
                          </span>
                          <span>Completed Projects</span>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <DashboardTileLoader column={1} />
                  )}
                </Card.Body>
              </Card>
            </div>
            <div className="mt-3">
              <Row className="gx-3 gy-3">
                <Col>
                  <Card className="rounded-1 d-flex h-100">
                    {!isLoading && Object.keys(dashboardData).length > 0 ? (
                      <>
                        <Card.Header className="fw-bold fs-0 border-bottom">
                          Recent Accounts
                        </Card.Header>
                        <Card.Body
                          className="p-0 flex-fill h-100"
                          style={{ maxHeight: "34vh", overflow: "auto" }}
                        >
                          <ListGroup horizontal>
                            <ListGroup.Item className="rounded-0 text-center w-25 border-0 text-light bg-secondary fw-bold border-end">
                              Account Id
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 text-center w-25 border-0 text-light border-end bg-secondary fw-bold">
                              Account Name
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 text-center w-25 border-0 text-light border-end bg-secondary fw-bold">
                              Email Address
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 text-center w-25 border-0 text-light border-end bg-secondary fw-bold">
                              Account Type
                            </ListGroup.Item>
                          </ListGroup>

                          {dashboardData.recent_clients
                            .slice(0, 10)
                            .map((item, key) => {
                              return (
                                <ListGroup horizontal key={key}>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 text-center border-bottom-0`}
                                  >
                                    {item.customer_id}
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 fw-bold text-center border-bottom-0`}
                                  >
                                    <Link
                                      className="text-decoration-none text-dark"
                                      to={`overview/${item.id}?tab=overview`}
                                    >
                                      {item.name}
                                    </Link>
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 text-center border-bottom-0`}
                                  >
                                    {item.email}
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 text-center border-bottom-0`}
                                  >
                                    {item.customer_type}
                                  </ListGroup.Item>
                                </ListGroup>
                              );
                            })}
                        </Card.Body>
                        <Card.Footer className="border-top">
                          <Button
                            size="sm"
                            variant="transperent"
                            className="d-flex justify-content-center custom-transperent-common-button"
                            as={Link}
                            to="/account/list"
                          >
                            <MdKeyboardDoubleArrowLeft size={20} /> More
                            <MdKeyboardDoubleArrowRight size={20} />
                          </Button>
                        </Card.Footer>
                      </>
                    ) : (
                      <div className="p-3">
                        <DashboardTileLoader column={5} />
                      </div>
                    )}
                  </Card>
                </Col>
                <Col className="d-flex">
                  <Card className="rounded-1 flex-fill">
                    {!isLoading && Object.keys(dashboardData).length > 0 ? (
                      <>
                        <Card.Header className="fw-bold fs-0 border-bottom">
                          Recent Projects
                        </Card.Header>
                        <Card.Body
                          className="p-0"
                          style={{ maxHeight: "34vh", overflow: "auto" }}
                        >
                          <ListGroup horizontal>
                            <ListGroup.Item className="rounded-0 w-25 border-0 text-center border-end text-light  bg-secondary fw-bold border-bottom ">
                              Project Name
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 w-25 border-0 text-center border-end border-bottom text-light  bg-secondary fw-bold">
                              Account
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 w-25 border-0 text-center border-end border-bottom text-light  bg-secondary fw-bold">
                              Deadline
                            </ListGroup.Item>
                            <ListGroup.Item className="rounded-0 w-25 border-0 text-center border-bottom text-light  bg-secondary fw-bold">
                              Status
                            </ListGroup.Item>
                          </ListGroup>
                          {dashboardData.recent_projects
                            .slice(0, 7)
                            .map((item, key) => {
                              return (
                                <ListGroup horizontal key={key}>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 fw-bold border-0 text-center border-end ${
                                      key < 6 ? "border-bottom" : ""
                                    }`}
                                  >
                                    <Link
                                      className="text-decoration-none text-dark"
                                      to={`/project/info/${item.id}?tab=overview`}
                                    >
                                      {item.title}
                                    </Link>
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 border-0 text-center border-end ${
                                      key < 6 ? "border-bottom" : ""
                                    }`}
                                  >
                                    {item?.customer?.name}
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 border-0 text-center border-end ${
                                      key < 6 ? "border-bottom" : ""
                                    }`}
                                  >
                                    {item.end_date_formated}
                                  </ListGroup.Item>
                                  <ListGroup.Item
                                    className={`rounded-0 w-25 text-center border-0 ${
                                      key < 6 ? "border-bottom" : ""
                                    }`}
                                  >
                                    <span
                                      className="badge p-2 custom-badge-crm"
                                      style={{
                                        backgroundColor:
                                          item.status === "cancelled"
                                            ? "#f2451cd9"
                                            : item.status === "hold"
                                            ? "#f49025d4"
                                            : item.status === "completed"
                                            ? "#43bf57"
                                            : "#4bc0c9",
                                      }}
                                    >
                                      <span className="fw-bold text-uppercase">
                                        {item.status}
                                      </span>
                                    </span>
                                  </ListGroup.Item>
                                </ListGroup>
                              );
                            })}
                        </Card.Body>
                        <Card.Footer className="border-top">
                          <Button
                            size="sm"
                            variant="transperent"
                            className="d-flex justify-content-center custom-transperent-common-button"
                            as={Link}
                            to="/project"
                          >
                            <MdKeyboardDoubleArrowLeft size={20} /> More
                            <MdKeyboardDoubleArrowRight size={20} />
                          </Button>
                        </Card.Footer>
                      </>
                    ) : (
                      <div className="p-3">
                        <DashboardTileLoader column={5} />
                      </div>
                    )}
                  </Card>
                </Col>
              </Row>
              <Row className="gx-3">
                <Col>
                  <Card className="rounded-1 mt-3">
                    <Card.Body>
                      {!isLoading && Object.keys(dashboardData).length > 0 ? (
                        <GraphChart options={optionsGraph} />
                      ) : (
                        <DashboardTileLoader column={4} />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
                <Col>
                  <Card className="rounded-1 mt-3">
                    <Card.Body>
                      {!isLoading && Object.keys(dashboardData).length > 0 ? (
                        <BarChart
                          options={optionsBar}
                          data={dataBar}
                          height={300}
                          width={"100%"}
                        />
                      ) : (
                        <DashboardTileLoader column={4} />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={3} className="flex-fill d-flex flex-column">
            <Card className="rounded-1">
              {!isLoading && (
                <Card.Header className="pb-0">
                  <span className="fw-bold">Sales Overview</span>
                  <br />
                  <span className="ms-3 fs--2">(Sales Type)</span>
                </Card.Header>
              )}
              <Card.Body className={!isLoading ? "pt-0" : " "}>
                {!isLoading && Object.keys(dashboardData).length > 0 ? (
                  <div className="d-flex justify-content-center">
                    <DoughnutChart
                      height={150}
                      width={150}
                      data={dataDoughnut}
                      options={optionsDoughnut}
                    />
                  </div>
                ) : (
                  <span className="pt-2">
                    <DashboardTileLoader column={2} />
                  </span>
                )}
              </Card.Body>
            </Card>
            <Card className="mt-3 flex-fill">
              {!isLoading && (
                <Card.Header className="fw-bold border-bottom">
                  Activity Log
                </Card.Header>
              )}
              <Card.Body
                className={!isLoading ? "pt-0" : " "}
                style={{
                  maxHeight: !isLoading ? "64.5vh" : "74vh",
                  overflow: "auto",
                }}
              >
                {!isLoading && Object.keys(dashboardData).length > 0 ? (
                  <ActivityLog data={dashboardData.activity_log} />
                ) : (
                  <DashboardTileLoader column={8} />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ClientDashboard;
