import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { FaFilter, FaTimes } from "react-icons/fa";
import PropTypes from "prop-types";
import FilterItem from "./FilterItem";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import { showToast } from "module/common/Toast/toast";

const FilterButton = ({
  size,
  variant,
  clearButtonVariant,
  disable,
  className,
  showText,
  filters,
  url,
  search,
  additionalParam,
  buttonGroupClassName,
  iconSize,
  iconColor,
  textColor,
  ...rest
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [activeFilters, setActiveFilters] = useState([]);
  const [options, setOptions] = useState([]);
  const [show, setShow] = useState(false);
  let filtersQueryParam = queryParams.get("filters");
  const statusFilter = queryParams.get("status_filter");

  const handleWindow = () => {
    setShow(!show);
  };

  const handleNewFilterItem = () => {
    setActiveFilters((prev) => [
      ...prev,
      {
        column: "",
        type: "",
        operator: "",
        value: "",
        value_ref: null,
      },
    ]);
  };

  const handleFilter = () => {
    let filtersArray = activeFilters.filter(
      (item) =>
        item.column &&
        item.column.length > 0 &&
        item.operator &&
        item.operator.length > 0 &&
        ((item.value && item.value.length > 0) ||
          (item.value_from &&
            item.value_from.length > 0 &&
            item.value_to &&
            item.value_to.length))
    );

    if (filtersArray.length < 1) {
      showToast(`Filetrs are empty, please check!`, "error");
    } else {
      filtersArray = filtersArray.reduce((itemsArray, item) => {
        itemsArray.push(JSON.stringify(item));
        return itemsArray;
      }, []);

      statusFilter
        ? navigate({
            pathname: "/" + url,
            search: `?${createSearchParams({
              ...(search ? search : null),
              filters: filtersArray.join(",,"),
              status_filter: statusFilter ? statusFilter : "",
              ...additionalParam,
            })}`,
          })
        : navigate({
            pathname: "/" + url,
            search: `?${createSearchParams({
              ...(search ? search : null),
              filters: filtersArray.join(",,"),
              ...additionalParam,
            })}`,
          });
      setShow(false);
    }
  };

  const handleClearFilters = () => {
    queryParams.delete("filters");
    search = search ?? {};
    if (typeof search === "object") {
      Object.keys(search).map((key) => queryParams.append(key, search[key]));
    }

    navigate(
      {
        pathname: "/" + url,
        search: `?${queryParams}`,
      },
      { replace: true }
    );
    setShow(false);
  };

  useEffect(() => {
    if (show && filters !== null && typeof filters === "object") {
      let objectKeys = Object.keys(filters);
      if (objectKeys.length > 0) {
        setOptions(objectKeys);
        let newActiverFiltersArray = [];
        if (filtersQueryParam) {
          newActiverFiltersArray = createFilterArray(
            filtersQueryParam,
            objectKeys
          );
        } else {
          let firstItem = filters[objectKeys[0]];
          newActiverFiltersArray.push({
            column: objectKeys[0],
            operator: firstItem.operator ?? "",
            type: firstItem.type ?? "string",
            value: firstItem.value ?? "",
            value_ref: firstItem.value_ref ?? null,
          });
        }

        setActiveFilters(newActiverFiltersArray);
      }
    }

    return () => {
      setActiveFilters([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, show]);

  return (
    <>
      <ButtonGroup
        aria-label="filter-button-group"
        className={
          `rounded-0 ` + buttonGroupClassName ? buttonGroupClassName : ""
        }
        {...rest}
      >
        <Button
          size={size ?? "sm"}
          variant={variant ?? "secondary"}
          className={`d-flex flex-row align-items-center me-0 ${className}`}
          disabled={disable}
          onClick={handleWindow}
          title="Advanced Filter"
        >
          <>
            <FaFilter
              className={showText ? "me-1" : ""}
              size={iconSize ?? 10}
              color={iconColor ?? ""}
            />
            {showText ? (
              <span className="fs--1" style={{ color: textColor ?? "" }}>
                {"Filter"}
              </span>
            ) : null}
          </>
        </Button>
        {filtersQueryParam && (
          <OverlayTrigger
            overlay={
              <Tooltip id="clear-filter-toolptip" className="small">
                Clear Advanced Filter
              </Tooltip>
            }
          >
            <Button
              size="sm"
              variant={clearButtonVariant ?? "danger"}
              className={`d-flex flex-row align-items-center px-1 position-absolute rounded-circle`}
              style={{
                top: "-5px",
                right: "-2px",
                width: "17px",
                height: "17px",
                zIndex: "1",
              }}
              disabled={disable}
              onClick={handleClearFilters}
            >
              <>
                <FaTimes size={10} />
              </>
            </Button>
          </OverlayTrigger>
        )}
      </ButtonGroup>

      {show ? (
        <Modal
          show={show}
          onHide={handleWindow}
          size="lg"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="h5 mb-0 d-flex flex-row">
              <FaFilter className="me-1 text-warning" />
              <span>ADVANCED FILTER OPTIONS</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0">
            <Table className="mb-0">
              <thead style={{ backgroundColor: "#E4E4FF85" }}>
                <tr className="fs--1 text-dark">
                  <th className="text-center py-2">OPTIONS</th>
                  <th className="text-center py-2">OPERATOR</th>
                  <th className="text-center py-2">VALUE</th>
                </tr>
              </thead>
              <tbody>
                {activeFilters.map((item, key) => (
                  <FilterItem
                    key={key}
                    index={key}
                    item={item}
                    options={options}
                    filters={filters}
                    setActiveFilters={setActiveFilters}
                  />
                ))}
              </tbody>
            </Table>
            {options &&
            Array.isArray(options) &&
            activeFilters.length < options.length ? (
              <Button
                className="w-100 border-top-0 border-end-0 border-start-0"
                style={{ backgroundColor: "#D9D9D9" }}
                variant=""
                size="sm"
                onClick={handleNewFilterItem}
              >
                <small className="fw-bold">**Add More Filters</small>
              </Button>
            ) : null}
            <div className="px-3 pt-2 d-flex justify-content-end">
              {activeFilters.length > 0 ? (
                <Button
                  variant="transparent"
                  size="sm"
                  style={{ boxShadow: "none" }}
                  onClick={handleClearFilters}
                >
                  <span className="small text-primary">Clear all filters</span>
                </Button>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0">
            <div className="d-flex justify-content-start w-100">
              <Button
                variant="outline-success"
                onClick={handleFilter}
                size="sm"
              >
                Filter
              </Button>
              <Button
                variant="outline-danger"
                onClick={handleWindow}
                size="sm"
                className="ms-1"
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
};

FilterButton.propTypes = {
  variant: PropTypes.string,
  clearButtonVariant: PropTypes.string,
  disable: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  showText: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  url: PropTypes.string.isRequired,
  search: PropTypes.object,
  buttonGroupClassName: PropTypes.string,
};

export default FilterButton;
