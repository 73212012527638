export const removeKitDetails = (data) => {
  const filteredArray = data.filter((item) => {
    if (item?.is_kit === 0) {
      return true;
    } else if (
      item?.is_kit === 1 &&
      item?.kit_items &&
      item?.kit_items.length > 0
    ) {
      return false;
    }
    return true;
  });
  return filteredArray;
};
