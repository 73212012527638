import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Card, Col, ListGroup, Row } from "react-bootstrap";
import { BsCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const ReportList = () => {
  const { user } = useContext(AuthWizardContext);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const projectReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "project-report"
  );
  const projectDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/project-report"
    );
  const sprintDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/sprint-detailed-report"
    );

  const taskReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "task-report"
  );

  const taskDetailedReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/task-report"
  );

  const runningOrCompletedTasksReportPermission =
    taskReportPermissions?.children?.find(
      (item) => item?.to === "reports/running-completed-tasks-report"
    );

  const timeTrackerReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/time-tracker-report"
  );

  const employeeReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "employee-report"
  );

  const employeeTaskReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-report"
    );

  const employeeTaskSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-summary-report"
    );

  const employeeLeadSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-lead-report"
    );

  const leadReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "lead-report"
  );

  const leadDetailedReportPermission = leadReportPermissions?.children?.find(
    (item) => item?.to === "reports/leads-report"
  );

  const leadConversationReportPermission =
    leadReportPermissions?.children?.find(
      (item) => item?.to === "reports/lead-conversion-report"
    );

  const leadSourceReportPermission =
    leadReportPermissions?.children?.find(
      (item) => item?.to === "reports/lead-source-summary-report"
    );

  const opportunityReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "opportunity-report"
  );

  const opportunityDetailedReportPermission =
    opportunityReportPermissions?.children?.find(
      (item) => item?.to === "reports/opportunity-report"
    );

  return (
    <div>
      <Card className="shadow-none">
        <Card.Body>
          <Row>
            {projectReportPermissions && (
              <Col md={3}>
                <span
                  className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports pb-2"
                  style={{
                    borderBottom: "1px dashed #6F7275",
                    color: "#6F7275",
                  }}
                >
                  {projectReportPermissions?.name}
                </span>
                <ListGroup className="mt-3">
                  {projectDetailedReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${projectDetailedReportPermission?.to}`}
                        >
                          {projectDetailedReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {sprintDetailedReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${sprintDetailedReportPermission?.to}`}
                        >
                          {sprintDetailedReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            )}
            {taskReportPermissions && (
              <Col md={3}>
                <span
                  className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports pb-2"
                  style={{
                    borderBottom: "1px dashed #6F7275",
                    color: "#6F7275",
                  }}
                >
                  {taskReportPermissions?.name}
                </span>
                <ListGroup className="mt-3">
                  {taskDetailedReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${taskDetailedReportPermission?.to}`}
                        >
                          {taskDetailedReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {timeTrackerReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${timeTrackerReportPermission?.to}`}
                        >
                          {timeTrackerReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {runningOrCompletedTasksReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${runningOrCompletedTasksReportPermission?.to}`}
                        >
                          {runningOrCompletedTasksReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            )}
            {employeeReportPermissions && (
              <Col md={3}>
                <span
                  className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports pb-2"
                  style={{
                    borderBottom: "1px dashed #6F7275",
                    color: "#6F7275",
                  }}
                >
                  {employeeReportPermissions?.name}
                </span>
                <ListGroup className="mt-3">
                  {employeeTaskReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${employeeTaskReportPermission?.to}`}
                        >
                          {employeeTaskReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {employeeTaskSummaryReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${employeeTaskSummaryReportPermission?.to}`}
                        >
                          {employeeTaskSummaryReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {employeeLeadSummaryReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${employeeLeadSummaryReportPermission?.to}`}
                        >
                          {employeeLeadSummaryReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            )}
            {leadReportPermissions && (
              <Col md={3}>
                <span
                  className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports pb-2"
                  style={{
                    borderBottom: "1px dashed #6F7275",
                    color: "#6F7275",
                  }}
                >
                  {leadReportPermissions?.name}
                </span>
                <ListGroup className="mt-3">
                  {leadDetailedReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${leadDetailedReportPermission?.to}`}
                        >
                          {leadDetailedReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {leadConversationReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${leadConversationReportPermission?.to}`}
                        >
                          {leadConversationReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                  {leadSourceReportPermission && (
                    <ListGroup.Item as="li" className="border-0 p-0 mt-2">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${leadSourceReportPermission?.to}`}
                        >
                          {leadSourceReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  )}
                </ListGroup>
              </Col>
            )}
          </Row>
          {opportunityReportPermissions && (
            <Row className="mt-3">
              <Col md={3}>
                <span
                  className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports pb-2"
                  style={{
                    borderBottom: "1px dashed #6F7275",
                    color: "#6F7275",
                  }}
                >
                  {opportunityReportPermissions?.name}
                </span>
                {opportunityDetailedReportPermission && (
                  <ListGroup className="mt-3">
                    <ListGroup.Item as="li" className="border-0 p-0">
                      <div className="d-flex align-items-center">
                        <BsCircleFill size={7} />
                        <Link
                          className="fw-bold custom-title-reports ms-1"
                          style={{ textDecoration: "none" }}
                          to={`/${opportunityDetailedReportPermission?.to}`}
                        >
                          {opportunityDetailedReportPermission?.name}
                        </Link>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                )}
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default ReportList;
