import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { FaTrash, FaUserAltSlash, FaUserTie } from "react-icons/fa";
import "./ClientForm.scss";
import { IoMdAddCircle, IoMdContact } from "react-icons/io";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import SelectSalesType from "components/form/SelectSalesType";
import SelectSalesman from "components/form/SelectSalesman";
import SelectCreditStatus from "components/form/SelectCreditStatus";
import SelectPaymentTerm from "components/form/SelectPaymentTerm";
import SelectTax from "components/form/SelectTax";
import SelectCurrency from "components/form/SelectCurrency";
import { AiFillCloseCircle } from "react-icons/ai";
import { clientContactFormKeys, clientFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { showToast } from "module/common/Toast/toast";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import removeRefData from "helpers/removeRefData";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import ListLoading from "module/common/ListLoading";
import ErrorAlert from "module/common/ErrorAlert";

const ClientForm = () => {
  const [contactFormVisibility, setContactFormVisibility] = useState(false);
  const [formData, setFormData] = useState(clientFormKeys);
  const [formError, setFormError] = useState({});
  let navigate = useNavigate();
  const { customerId } = useParams();
  const [contactFormData, setContactFormData] = useState(clientContactFormKeys);
  const [contactArray, setContactArray] = useState([]);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const activeView = queryParams.get("view");
  const allKeysHaveValues = Object.keys(contactFormData).every(
    (key) => contactFormData[key] !== ""
  );
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const hasTrueValue = contactArray.some((item) => item.isDeleted === false);

  useEffect(() => {
    if (customerId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  const fetchData = () => {
    setLoading(true);
    axios
      .get("sales/customers/" + customerId)
      .then((res) => {
        if (res.data.success === true) {
          setFormData({
            ...formData,
            address: res.data.data?.address ?? "",
            contact_person: res.data.data?.contact_person ?? "",
            credit_limit: res.data.data?.credit_limit ?? "",
            credit_status_id: res.data.data?.credit_status_id ?? "",
            credit_status_id_ref: {
              label: res.data.data?.credit_status_name ?? "",
              value: res.data.data?.credit_status_id ?? "",
            },
            currency_code: res.data.data?.currency_code ?? "",
            currency_code_ref: {
              label: res.data.data?.currency_code ?? "",
              value: res.data.data?.currency_code ?? "",
            },
            customer_type: res.data.data?.customer_type ?? "",

            email: res.data.data?.email ?? "",
            name: res.data.data?.name ?? "",
            notes: res.data.data?.notes ?? "",
            opening_balance: res.data.data?.opening_balance ?? "",
            phone: res.data.data?.phone ?? "",
            salesman_id: res.data.data?.salesman_id ?? "",
            salesman_id_ref: {
              label: res.data.data?.salesman_name ?? "",
              value: res.data.data?.salesman_id ?? "",
            },
            trade_license_no: res.data.data?.trade_license_no ?? "",
            trn_no: res.data.data?.trn_no ?? "",

            sales_type_id: res.data.data?.sales_type_id ?? "",
            sales_type_id_ref: {
              label: res.data.data?.sales_type_name ?? "",
              value: res.data.data?.sales_type_id ?? "",
            },
            tax_id: res.data.data?.tax_id ?? "",
            tax_id_ref: {
              label: res.data.data?.tax_name ?? "",
              value: res.data.data?.tax_id ?? "",
            },
            payment_term_id: res.data.data?.payment_term_id ?? "",
            payment_term_id_ref: {
              label: res.data.data?.payment_term_name ?? "",
              value: res.data.data?.payment_term_id ?? "",
            },
            username: res?.data?.data?.username ?? "",
          });
          setContactArray(res.data.data?.contacts ?? "");
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    axios({
      method: customerId ? "put" : "post",
      url: `sales/customers${customerId ? "/" + customerId : ""}`,
      data: removeRefData({
        ...formData,
        contacts: contactArray.filter((item) => !item.isDeleted),
      }),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          const newParams = { ...allQueryParams };
          delete newParams["view"];
          navigate(
            `/account/${activeView}?${createSearchParams({
              ...newParams,
              page: customerId && pageNumber !== null ? pageNumber : 1,
            })}`
          );
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          setContactArray(contactArray.filter((item) => !item.isDeleted));
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const handleContactFieldChange = (e, action) => {
    let name, value, itemsWithNewData;
    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
      itemsWithNewData = {
        ...contactFormData,
        [name]: value,
      };
    }
    if (itemsWithNewData) {
      setContactFormData(itemsWithNewData);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleContactAdd = () => {
    if (allKeysHaveValues) {
      setContactArray((prev) => [
        ...prev,
        {
          id: `tmp-${Math.random().toString(16).slice(2)}`,
          isDeleted: false,
          ...contactFormData,
        },
      ]);
      setContactFormData({
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
        contact_address: "",
      });
    }
  };

  const handleRemoveContact = (id) => {
    const updatedData = contactArray.map((item) => ({
      ...item,
      isDeleted: item.id === id ? true : item.isDeleted,
    }));
    setContactArray(updatedData);
  };

  const handleContactFormVisibility = () => {
    setContactFormVisibility(!contactFormVisibility);
    setContactFormData({
      contact_name: "",
      contact_email: "",
      contact_mobile: "",
      contact_address: "",
    });
  };

  return (
    <Card style={{ minHeight: "91vh" }} className="mt-3 mb-3">
      <Card.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <FaUserTie size={20} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {`${customerId ? "Update" : "Create"} Account`}
          </span>
        </div>
      </Card.Header>
      <Card.Body className="ps-2 pe-2">
        {Object.keys(formError).length > 0 && (
          <ErrorAlert setFormError={setFormError} formError={formError} />
        )}

        {!loading ? (
          <>
            <Row className="gx-3 d-flex">
              <Col md={8} xl={9}>
                <div className="border p-3">
                  <span className="custom-postioning-header-client text-uppercase">
                    Personal Information
                  </span>
                  <div>
                    <Card className="">
                      <Card.Body>
                        <Row className="p-0">
                          <Col xl={6} md={6} sm={12} className="mt-3">
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label className="require-data">
                                    Name
                                  </Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="name"
                                        onChange={handleFieldChange}
                                        value={formData.name}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.name ? (
                                        <FormErrorPopover
                                          id="name"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.name}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label className="require-data">
                                    Account Type
                                  </Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Select
                                        onChange={handleFieldChange}
                                        value={formData.customer_type}
                                        name="customer_type"
                                      >
                                        <option value="INDIVIDUAL">
                                          INDIVIDUAL
                                        </option>
                                        <option value="COMPANY">COMPANY</option>
                                      </Form.Select>
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.customer_type ? (
                                        <FormErrorPopover
                                          id="customer_type"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.customer_type}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Trade License No</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="trade_license_no"
                                        onChange={handleFieldChange}
                                        value={formData.trade_license_no}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.trade_license_no ? (
                                        <FormErrorPopover
                                          id="trade_license_no"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={
                                            formError.trade_license_no
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Opening Balance</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="opening_balance"
                                        onChange={handleFieldChange}
                                        value={formData.opening_balance}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.opening_balance ? (
                                        <FormErrorPopover
                                          id="opening_balance"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={
                                            formError.opening_balance
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col xl={6} md={6} sm={12} className="mt-3">
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label className="require-data">
                                    Phone Number
                                  </Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <PhoneNumberInput
                                        name="phone"
                                        onChange={handleFieldChange}
                                        value={formData.phone}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.phone ? (
                                        <FormErrorPopover
                                          id="phone"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.phone}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Email Address</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="email"
                                        name="email"
                                        onChange={handleFieldChange}
                                        value={formData.email}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.email ? (
                                        <FormErrorPopover
                                          id="email"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.email}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Contact Person</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="contact_person"
                                        onChange={handleFieldChange}
                                        value={formData.contact_person}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.contact_person ? (
                                        <FormErrorPopover
                                          id="contact_person"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={
                                            formError.contact_person
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3 me-3">
                                <Col xl={2} md={2} sm={12}>
                                  <Form.Label>Address</Form.Label>
                                </Col>
                                <Col xl={10} md={10} sm={12}>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    name="address"
                                    className="ms-1"
                                    onChange={handleFieldChange}
                                    value={formData.address}
                                  />

                                  <Col
                                    xxl={1}
                                    xl={2}
                                    md={2}
                                    sm={1}
                                    className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                  >
                                    {formError.address ? (
                                      <FormErrorPopover
                                        id="address"
                                        totalErrorCount={
                                          Object.keys(formError).length
                                        }
                                        errorMessage={formError.address}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
                <div className="border p-3 mt-3">
                  <span className="custom-postioning-header-client text-uppercase">
                    Sales Information
                  </span>
                  <div>
                    <Card className="">
                      <Card.Body>
                        <Row className="p-0">
                          <Col xl={6} md={6} sm={12} className="mt-3">
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Sales Type</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectSalesType
                                        name="sales_type_id"
                                        value={formData.sales_type_id_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.sales_type_id ? (
                                        <FormErrorPopover
                                          id="sales_type_id"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.sales_type_id}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Salesman</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectSalesman
                                        name="salesman_id"
                                        value={formData.salesman_id_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.salesman_id ? (
                                        <FormErrorPopover
                                          id="salesman_id"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.salesman_id}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3 ">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Payment Terms</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectPaymentTerm
                                        name="payment_term_id"
                                        value={formData.payment_term_id_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.payment_term_id ? (
                                        <FormErrorPopover
                                          id="payment_term_id"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={
                                            formError.payment_term_id
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label className="require-data">
                                    Currency Code
                                  </Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectCurrency
                                        name="currency_code"
                                        value={formData.currency_code_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.currency_code ? (
                                        <FormErrorPopover
                                          id="currency_code"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.currency_code}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                          <Col xl={6} md={6} sm={12} className="mt-3">
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Credit Limit</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="credit_limit"
                                        onChange={handleFieldChange}
                                        value={formData.credit_limit}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.credit_limit ? (
                                        <FormErrorPopover
                                          id="credit_limit"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.credit_limit}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Credit Status</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectCreditStatus
                                        name="credit_status_id"
                                        value={formData.credit_status_id_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.credit_status_id ? (
                                        <FormErrorPopover
                                          id="credit_status_id"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={
                                            formError.credit_status_id
                                          }
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>Tax Type</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <SelectTax
                                        name="tax_id"
                                        value={formData.tax_id_ref}
                                        handleFieldChange={handleFieldChange}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.tax_id ? (
                                        <FormErrorPopover
                                          id="tax_id"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.tax_id}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3">
                                <Col xl={4} md={4} sm={12}>
                                  <Form.Label>TRN Number</Form.Label>
                                </Col>
                                <Col xl={8} md={8} sm={12}>
                                  <Row>
                                    <Col xxl={11} xl={10} md={10} sm={11}>
                                      <Form.Control
                                        type="text"
                                        name="trn_no"
                                        onChange={handleFieldChange}
                                        value={formData.trn_no}
                                      />
                                    </Col>
                                    <Col
                                      xxl={1}
                                      xl={2}
                                      md={2}
                                      sm={1}
                                      className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                    >
                                      {formError.trn_no ? (
                                        <FormErrorPopover
                                          id="trn_no"
                                          totalErrorCount={
                                            Object.keys(formError).length
                                          }
                                          errorMessage={formError.trn_no}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Form.Group as={Col} xl={12} md={12} sm={12}>
                              <Row className="mb-3 me-3">
                                <Col xl={2} md={2} sm={12}>
                                  <Form.Label>Notes</Form.Label>
                                </Col>
                                <Col xl={10} md={10} sm={12}>
                                  <Form.Control
                                    as="textarea"
                                    rows={4}
                                    name="notes"
                                    onChange={handleFieldChange}
                                    value={formData.notes}
                                  />

                                  <Col
                                    xxl={1}
                                    xl={2}
                                    md={2}
                                    sm={1}
                                    className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                                  >
                                    {formError.notes ? (
                                      <FormErrorPopover
                                        id="notes"
                                        totalErrorCount={
                                          Object.keys(formError).length
                                        }
                                        errorMessage={formError.notes}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Col>
                                </Col>
                              </Row>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col md={4} xl={3} className="d-flex flex-column gap-3">
                <div className="p-3 border h-25">
                  <span className="custom-postioning-header-client text-uppercase">
                    ACCOUNT DETAILS
                  </span>
                  <Card>
                    <Card.Body>
                      <Form.Group as={Col} xl={12} md={12} sm={12}>
                        <Row className="mb-3">
                          <Col xl={4} md={4} sm={12}>
                            <Form.Label>Username</Form.Label>
                          </Col>
                          <Col xl={8} md={8} sm={12}>
                            <Row>
                              <Col xxl={11} xl={10} md={10} sm={11}>
                                <Form.Control
                                  type="text"
                                  name="username"
                                  onChange={handleFieldChange}
                                  value={formData.username}
                                />
                              </Col>
                              <Col
                                xxl={1}
                                xl={2}
                                md={2}
                                sm={1}
                                className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                              >
                                {formError.username ? (
                                  <FormErrorPopover
                                    id="username"
                                    totalErrorCount={
                                      Object.keys(formError).length
                                    }
                                    errorMessage={formError.username}
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Group>
                      <Form.Group as={Col} xl={12} md={12} sm={12}>
                        <Row className="mb-3">
                          <Col xl={4} md={4} sm={12}>
                            <Form.Label>Password</Form.Label>
                          </Col>
                          <Col xl={8} md={8} sm={12}>
                            <Row>
                              <Col xxl={11} xl={10} md={10} sm={11}>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  onChange={handleFieldChange}
                                  value={formData.password}
                                />
                              </Col>
                              <Col
                                xxl={1}
                                xl={2}
                                md={2}
                                sm={1}
                                className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                              >
                                {formError.password ? (
                                  <FormErrorPopover
                                    id="password"
                                    totalErrorCount={
                                      Object.keys(formError).length
                                    }
                                    errorMessage={formError.password}
                                  />
                                ) : (
                                  ""
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </div>
                <div className="p-3 border h-75">
                  <span className="custom-postioning-header-client text-uppercase">
                    ACCOUNT CONTACTS
                  </span>
                  <div style={{ maxHeight: "81vh", overflow: "auto" }}>
                    <Card>
                      <Card.Header className="pb-0 pt-0 d-flex">
                        <Button
                          variant="transparent"
                          className="text-success p-0 me-1 ms-auto custom-transperent-common-button"
                          onClick={() => {
                            handleContactFormVisibility();
                          }}
                        >
                          {contactFormVisibility ? (
                            <AiFillCloseCircle size={21} color="#ff7676" />
                          ) : (
                            <IoMdAddCircle size={23} color="#3cca3c" />
                          )}
                        </Button>
                      </Card.Header>
                      <Card.Body className="p-0">
                        <ListGroup className="d-grid gap-2 mt-2">
                          {contactFormVisibility && (
                            <ListGroup.Item>
                              <Form className="mt-3">
                                <Form.Group as={Col} xl={12} md={12} sm={12}>
                                  <Row className="mb-3">
                                    <Col xl={4} md={4} sm={12}>
                                      <Form.Label>Name</Form.Label>
                                    </Col>
                                    <Col xl={8} md={8} sm={12}>
                                      <Row>
                                        <Col xxl={11} xl={10} md={10} sm={11}>
                                          <Form.Control
                                            type="text"
                                            name="contact_name"
                                            onChange={handleContactFieldChange}
                                            value={contactFormData.contact_name}
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group as={Col} xl={12} md={12} sm={12}>
                                  <Row className="mb-3">
                                    <Col xl={4} md={4} sm={12}>
                                      <Form.Label>Contact Email</Form.Label>
                                    </Col>
                                    <Col xl={8} md={8} sm={12}>
                                      <Row>
                                        <Col xxl={11} xl={10} md={10} sm={11}>
                                          <Form.Control
                                            type="email"
                                            name="contact_email"
                                            onChange={handleContactFieldChange}
                                            value={
                                              contactFormData.contact_email
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group as={Col} xl={12} md={12} sm={12}>
                                  <Row className="mb-3">
                                    <Col xl={4} md={4} sm={12}>
                                      <Form.Label>Contact Number</Form.Label>
                                    </Col>
                                    <Col xl={8} md={8} sm={12}>
                                      <Row>
                                        <Col xxl={11} xl={10} md={10} sm={11}>
                                          <PhoneNumberInput
                                            name="contact_mobile"
                                            onChange={handleContactFieldChange}
                                            value={
                                              contactFormData.contact_mobile
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <Form.Group as={Col} xl={12} md={12} sm={12}>
                                  <Row className="mb-3">
                                    <Col xl={4} md={4} sm={12}>
                                      <Form.Label>Contact Address</Form.Label>
                                    </Col>
                                    <Col xl={8} md={8} sm={12}>
                                      <Row>
                                        <Col xxl={11} xl={10} md={10} sm={11}>
                                          <Form.Control
                                            as="textarea"
                                            rows={4}
                                            name="contact_address"
                                            onChange={handleContactFieldChange}
                                            value={
                                              contactFormData.contact_address
                                            }
                                          />
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Form.Group>
                                <span className="d-flex me-4">
                                  <Button
                                    size="sm"
                                    variant="success"
                                    className="mb-2 ms-auto"
                                    onClick={handleContactAdd}
                                    disabled={allKeysHaveValues ? false : true}
                                  >
                                    Add
                                  </Button>
                                </span>
                              </Form>
                            </ListGroup.Item>
                          )}
                          {contactArray?.length > 0 ? (
                            contactArray.map((item, key) =>
                              !item.isDeleted ? (
                                <ListGroup.Item
                                  className="border-top d-flex"
                                  style={{ backgroundColor: "whitesmoke" }}
                                  key={key}
                                >
                                  <span className="me-2">
                                    <IoMdContact size={40} />
                                  </span>
                                  <div>
                                    <p className="m-0 fw-bold">
                                      {item.contact_name}
                                    </p>
                                    <span className="d-flex align-items-center">
                                      <p className="m-0">
                                        {item.contact_email}
                                      </p>
                                      <span className="ms-2">
                                        {formError[
                                          `contacts.${key}.contact_email`
                                        ] ? (
                                          <FormErrorPopover
                                            id={`contacts.${key}.contact_email`}
                                            totalErrorCount={
                                              Object.keys(
                                                formError[
                                                  `contacts.${key}.contact_email`
                                                ]
                                              ).length
                                            }
                                            errorMessage={
                                              formError[
                                                `contacts.${key}.contact_email`
                                              ]
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </span>

                                    <span className="d-flex align-items-center">
                                      <p className="m-0">
                                        {item.contact_mobile}
                                      </p>
                                      <span className="ms-2">
                                        {formError[
                                          `contacts.${key}.contact_mobile`
                                        ] ? (
                                          <FormErrorPopover
                                            id={`contacts.${key}.contact_mobile`}
                                            totalErrorCount={
                                              Object.keys(
                                                formError[
                                                  `contacts.${key}.contact_mobile`
                                                ]
                                              ).length
                                            }
                                            errorMessage={
                                              formError[
                                                `contacts.${key}.contact_mobile`
                                              ]
                                            }
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </span>
                                    <span className="d-flex align-items-center">
                                      <p className="m-0">
                                        {item.contact_address}
                                      </p>
                                    </span>
                                  </div>
                                  <span className="ms-auto">
                                    <Button
                                      size="sm"
                                      variant="transparent"
                                      className="custom-transperent-common-button p-0"
                                      onClick={() => {
                                        handleRemoveContact(item.id);
                                      }}
                                    >
                                      <FaTrash />
                                    </Button>
                                  </span>
                                </ListGroup.Item>
                              ) : (
                                !hasTrueValue &&
                                key === 0 && (
                                  <ListGroup.Item
                                    className="border min-vh-10"
                                    key={key}
                                  >
                                    <div className="d-flex justify-content-center align-items-center h-100">
                                      <FaUserAltSlash size={25} />
                                      <span className="ms-1">
                                        No Contact Added Yet!
                                      </span>
                                    </div>
                                  </ListGroup.Item>
                                )
                              )
                            )
                          ) : (
                            <ListGroup.Item className="border min-vh-10">
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <FaUserAltSlash size={25} />
                                <span>No Contact Added Yet!</span>
                              </div>
                            </ListGroup.Item>
                          )}
                        </ListGroup>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
            <Card.Footer className="d-flex justify-content-start align-items-center">
              <Row>
                <Col md={8} className="d-flex gap-2">
                  <SaveButton
                    handleSubmit={handleSubmit}
                    onSave={onSave}
                    id={customerId}
                  />
                  <CancelButton handleCancel={handleCancel} />
                </Col>
              </Row>
            </Card.Footer>
          </>
        ) : (
          <ListLoading style={{ maxHeight: "82vh", overflow: "hidden" }} />
        )}
      </Card.Body>
    </Card>
  );
};

export default ClientForm;
