import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

function DataProcessing({ show }) {
  return (
    <Modal show={show} size="sm">
      <div className="d-flex flex-row p-2 align-items-center justify-content-center">
        <h6 className="text-center text-secondary m-0">Loading data</h6>
        <div className="d-flex">
          {['primary', 'secondary', 'success'].map(varient => (
            <Spinner
              key={varient}
              animation="grow"
              role="status"
              variant={varient}
              className="m-1"
              size="sm"
              style={{ height: '0.5rem', width: '0.5rem' }}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
}

DataProcessing.propTypes = {
  show: PropTypes.bool
};

export default DataProcessing;
