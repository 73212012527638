import { React } from "react";
import Select from "react-select";
import PropTypes from "prop-types";

const SelectCallType = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
}) => {
  const thisOptions = [
    {
      label: "Inbound",
      value: "Inbound",
    },
    {
      label: "Outbound",
      value: "Outbound",
    },
  ];

  return (
    <Select
      cacheOptions
      loadOptions={thisOptions}
      defaultOptions
      isClearable
      name={name ? name : "type"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`app-react-select${
        error ? " form-control ps-0 py-0 is-invalid" : ""
      }`}
      classNamePrefix="app-react-select"
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
      options={thisOptions}
    />
  );
};

SelectCallType.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCallType;
