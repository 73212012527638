import SelectTeam from "components/form/SelectTeam";
import SelectUsers from "components/form/SelectUsers";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];
const teamMemberFilters = {
  team_id: {
    label: "Team Name",
    operators: [{ name: "EQUAL", value: "=" }],
    active: true,
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectTeam
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

const teamFilters = {
  name: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
  },

  team_lead_id: {
    label: "Lead",
    operators: [{ name: "EQUAL", value: "=" }],
    active: true,
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectUsers
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};
export { teamFilters, teamMemberFilters };
