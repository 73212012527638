import { Fragment, React, useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import { TbCalculatorOff, TbEdit } from "react-icons/tb";
import SelectStock from "components/form/SelectStock";
import { MdOutlineDeleteOutline } from "react-icons/md";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";

function EstimationDetailsTable({
  entries,
  setFormData,
  detailsAction,
  setDetailsAction,
  newRowData,
  setNewRowData,
  editItemId,
  setEditItemId,
  formError,
}) {
  const [itemError, setItemError] = useState({});

  const validateFields = (newRowData) => {
    const errors = {};

    if (Number(newRowData.quantity) === 0) {
      errors.quantity = ["The quantity must exceed zero"];
    }

    if (Number(newRowData.amount) === 0) {
      errors.amount = ["The amount must exceed zero"];
    }

    return errors;
  };

  const handleAddOrUpdateItem = () => {
    const errors = validateFields(newRowData);

    if (Object.keys(errors).length === 0) {
      setItemError({});
      setFormData((prev) => {
        const updatedDetails = (prev.details || []).map((item) =>
          item.id === editItemId ? { ...newRowData } : item
        );

        if (editItemId) {
          return { ...prev, details: updatedDetails };
        }

        return {
          ...prev,
          details: [
            ...updatedDetails,
            {
              ...newRowData,
              id: `tmp-${Math.random().toString(16).slice(2)}`,
            },
          ],
        };
      });

      setNewRowData({
        id: "",
        title: "",
        description: "",
        amount: "",
        stock_id: "",
        stock_id_ref: { label: "", value: "" },
        quantity: "",
      });

      setEditItemId(null);
    } else {
      setItemError(errors);
    }
  };

  const handleRemoveItem = (id) => {
    setFormData((prev) => ({
      ...prev,
      details: prev.details.filter((item) => item.id !== id),
    }));
  };

  const handleEditItem = (id) => {
    setEditItemId(id);
    entries?.length > 0 &&
      entries
        .filter((item) => item.id === id)
        .map((filterdItem) =>
          setNewRowData((prev) => ({
            ...prev,
            ...filterdItem,
          }))
        );
    setDetailsAction(true);
  };

  const handleFieldChange = (e, action) => {
    let name, value, itemsWithNewData;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;

      itemsWithNewData = {
        ...newRowData,
        [name]: value && value,
      };
    } else if (action) {
      name = action.name;
      value = e;
      itemsWithNewData = {
        ...newRowData,
        [name]: value && value.value,
        [name + "_ref"]: value && value,
      };
    }

    if (itemsWithNewData) {
      setNewRowData(itemsWithNewData);
    }
  };

  useEffect(() => {
    formError?.details?.length > 0 && setDetailsAction(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  return (
    <>
      {formError?.details?.length > 0 && (
        <Alert variant="danger" className="rounded-0" dismissible>
          <ul className="ms-2 mb-0">
            {Object.keys(formError.details).map((key, index) => (
              <li key={index} className="mb-1">
                {formError.details[key]}
              </li>
            ))}
          </ul>
        </Alert>
      )}

      <Row>
        {detailsAction && (
          <Col className="border border-end-0 border-top-0" md={12}>
            <div className="row d-flex justify-content-between m-3">
              <Form.Group
                className="mb-3 col-md-2"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label className="require-data">Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleFieldChange}
                  value={newRowData.title}
                />
                <Form.Control.Feedback type="invalid">
                  {itemError.title}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-2"
                controlId="exampleForm.ControlInput2"
              >
                <Form.Label>Description</Form.Label>

                <Form.Control
                  as="textarea"
                  rows={1}
                  name="description"
                  onChange={handleFieldChange}
                  value={newRowData.description}
                  isInvalid={!!itemError.description}
                />

                <Form.Control.Feedback type="invalid">
                  {itemError.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-2"
                controlId="exampleForm.ControlInput3"
              >
                <Form.Label className="require-data">Amount</Form.Label>
                <Form.Control
                  type="number"
                  name="amount"
                  onChange={handleFieldChange}
                  value={newRowData.amount}
                  isInvalid={!!itemError.amount}
                />
                <Form.Control.Feedback type="invalid">
                  {itemError.amount}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-2"
                controlId="exampleForm.ControlInput4"
              >
                <Form.Label className="require-data">Stock</Form.Label>
                <SelectStock
                  value={
                    newRowData.edit === true
                      ? newRowData?.stock_id
                      : newRowData.stock_id_ref
                  }
                  handleFieldChange={handleFieldChange}
                  name="stock_id"
                />
                <Form.Control.Feedback type="invalid">
                  {itemError.stock_id}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="mb-3 col-md-2"
                controlId="exampleForm.ControlInput6"
              >
                <Form.Label className="require-data">Quantity</Form.Label>
                <Form.Control
                  type="number"
                  name="quantity"
                  onChange={handleFieldChange}
                  value={newRowData.quantity}
                  isInvalid={!!itemError.quantity}
                />
                <Form.Control.Feedback type="invalid">
                  {itemError.quantity}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group
                className="col-md-1"
                controlId="exampleForm.ControlInput7"
              >
                <div className="h-100 d-flex align-items-center">
                  <Button
                    className="mt-2"
                    size="sm"
                    variant={editItemId ? "warning" : "success"}
                    onClick={handleAddOrUpdateItem}
                    disabled={
                      !newRowData.title ||
                      !newRowData.amount ||
                      !newRowData.quantity ||
                      !newRowData.stock_id
                    }
                  >
                    {editItemId ? "Update" : "Add"}
                  </Button>
                </div>
              </Form.Group>
            </div>
          </Col>
        )}

        <Col className={`border-bottom`} md={12}>
          {entries?.length > 0 ? (
            <Table striped responsive hover size="md">
              <thead style={{ backgroundColor: "#bfbfbf" }}>
                <tr>
                  <th className="border-end fw-bold">#</th>
                  <th className="border-end fw-bold">Title</th>
                  <th className="border-end fw-bold">Description</th>
                  <th className="border-end fw-bold">Amount</th>
                  <th className="border-end fw-bold">Stock Id</th>
                  <th className="border-end fw-bold">Quantity</th>
                  <th className="border-end fw-bold">Action</th>
                </tr>
              </thead>
              <tbody>
                {entries.map((item, key) => (
                  <tr key={key}>
                    <td className="border-end">{key + 1}</td>
                    <td className="border-end">{item.title}</td>
                    <td className="border-end">{item.description}</td>
                    <td className="border-end">
                      <div className="d-flex justify-content-between">
                        <span>{item.amount}</span>
                        <span>
                          {`formError.${key}.amount` ? (
                            <FormErrorPopover
                              id="amount"
                              totalErrorCount={1}
                              errorMessage={formError[`details.${key}.amount`]}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="border-end">{item.stock_id}</td>
                    <td className="border-end">
                      <div className="d-flex justify-content-between">
                        <span>{item.quantity}</span>
                        <span>
                          {`formError.${key}.quantity` ? (
                            <FormErrorPopover
                              id="quantity"
                              totalErrorCount={1}
                              errorMessage={
                                formError[`details.${key}.quantity`]
                              }
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </td>
                    <td className="border-end" width="5%">
                      <div className="d-flex gap-2">
                        <Button
                          size="sm"
                          variant="transparent"
                          className="p-0 custom-transperent-common-button"
                          onClick={() => {
                            handleEditItem(item.id);
                          }}
                        >
                          <TbEdit color="#26BCFD" size={20} />
                        </Button>
                        <Button
                          size="sm"
                          variant="transparent"
                          className="p-0 custom-transperent-common-button"
                          onClick={() => handleRemoveItem(item.id)}
                        >
                          <MdOutlineDeleteOutline color="#E63757" size={20} />
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div className="no-data p-3 pt-4 mb-3">
              <TbCalculatorOff size={22} />
              <span className="ms-2">No Estimation Details Added Yet!</span>
            </div>
          )}
        </Col>
      </Row>
    </>
  );
}

EstimationDetailsTable.propTypes = {
  setFormData: PropTypes.func,
  entries: PropTypes.array,
};

export default EstimationDetailsTable;
