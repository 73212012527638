import { StoreContext } from "context/Context";
import InitStore from "helpers/InitStore";
import PropTypes from "prop-types";
import React, { useContext, useReducer } from "react";
import storeReduser from "reducers/storeReducer";

const StoreProvider = ({ children }) => {
  const [store, dispatch] = useReducer(storeReduser, useContext(StoreContext));

  const addIitemToStore = (key, payload) => {
    dispatch({ type: "ADD-ITEM", key: key, payload: payload });
  };

  const taxName = (taxId) => {
    let tax = store.taxes.filter((tax) => tax.id === parseInt(taxId));
    return tax.length ? (
      tax[0].name
    ) : (
      <span className="text-danger">Not found</span>
    );
  };

  const taxGroupName = (taxGroupId) => {
    let taxGroup = store.taxGroups.filter(
      (taxGroup) => taxGroup.id === parseInt(taxGroupId)
    );
    return taxGroup.length ? (
      taxGroup[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const unitName = (unitId) => {
    let unit = store.units.filter((unit) => unit.id === parseInt(unitId));
    return unit.length ? (
      unit[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const mbFlagName = (mbFlagId) => {
    let mbFlag = store.mbFlags.filter(
      (mbFlag) => mbFlag.id === parseInt(mbFlagId)
    );
    return mbFlag.length ? (
      mbFlag[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const categoryName = (categoryId) => {
    let category = store.categories.filter(
      (category) => category.id === parseInt(categoryId)
    );
    return category.length ? (
      category[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const supplierName = (supplierId) => {
    let supplier = store.suppliers.filter(
      (supplier) => supplier.id === parseInt(supplierId)
    );
    return supplier.length ? (
      supplier[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const salesTypeName = (salesTypeId) => {
    let salesType = store.salesTypes.filter(
      (salesType) => salesType.id === parseInt(salesTypeId)
    );
    return salesType.length ? (
      salesType[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const itemName = (key, id) => {
    let item = store.items.filter(
      (item) => parseInt(item[key]) === parseInt(id)
    );
    return item.length ? (
      item[0].item_name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const salesmanName = (salesmanId) => {
    let salesman = store.salesmans.filter(
      (salesman) => salesman.id === parseInt(salesmanId)
    );
    return salesman.length ? (
      salesman[0].salesman_name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const paymentTermName = (paymentTermId) => {
    let paymentTerm = store.paymentTerms.filter(
      (paymentTerm) => paymentTerm.id === parseInt(paymentTermId)
    );
    return paymentTerm.length ? (
      paymentTerm[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const currencyCode = (countryId) => {
    let country = store.countries.filter(
      (country) => country.id === parseInt(countryId)
    );
    return country.length ? (
      country[0].currency_code
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  const customerName = (customerId) => {
    let customer = store.customers.filter(
      (customer) => customer.id === parseInt(customerId)
    );
    return customer.length ? (
      customer[0].name
    ) : (
      <span className="text-danger small">Not found</span>
    );
  };

  return (
    <StoreContext.Provider
      value={{
        store,
        addIitemToStore,
        taxName,
        taxGroupName,
        unitName,
        mbFlagName,
        categoryName,
        supplierName,
        salesTypeName,
        itemName,
        salesmanName,
        paymentTermName,
        currencyCode,
        customerName,
      }}
    >
      <InitStore>{children}</InitStore>
    </StoreContext.Provider>
  );
};

StoreProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
export default StoreProvider;
