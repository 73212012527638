import SelectProject from "components/form/SelectProject";
import SelectSprintStatus from "components/form/SelectSprintStatus";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const sprintCommonFilters = {
  name: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
  },
  project_id: {
    label: "Project",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectProject
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  start_date_time: {
    label: "Start Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  end_date_time: {
    label: "End Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  reference: {
    label: "Reference",
    operators: commonFilterOperators,
    active: true,
  },
  status: {
    label: "Status",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectSprintStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

export { sprintCommonFilters };
