import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Avatar from "components/common/Avatar";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import React, { Fragment } from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { apiCall } from "helpers/apiCalls";
import Comments from "module/common/ViewPages/Common/Comments/Comments";

const File = ({ icon, title, action, actionIcon, className, link, color }) => {
  return (
    <div
      className={classNames(
        "border p-2 rounded-3 d-flex bg-white dark__bg-1000 fs--1",
        className
      )}
    >
      <FontAwesomeIcon icon={icon} className="fs-1" style={{ color: color }} />
      <span className="ms-2 me-3">{title}</span>
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip style={{ position: "fixed" }}>{action}</Tooltip>}
      >
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-300 ms-auto"
          download
        >
          <FontAwesomeIcon icon={actionIcon} />
        </a>
      </OverlayTrigger>
    </div>
  );
};

const TicketOverViewConversation = ({ data }) => {
  const { overviewId } = useParams();
  const [downloadLoading, setdownloadLoading] = useState(false);
  const handleBulkDownload = async (id, type) => {
    setdownloadLoading(true);
    const data = await apiCall({
      url: `documents/zip-download/${type}/${id}`,
    });
    const link = document.createElement("a");
    if (Object.keys(data).length > 0) {
      link.href = data.download_url;
      link.setAttribute("download", "");
      link.click();
    }
    setdownloadLoading(false);
  };

  return (
    <>
      <Card className="mt-3 flex-fill">
        <Card.Header className="bg-light">
          <span className="mb-0 text-capitalize fs-1">
            <FontAwesomeIcon
              icon={
                data.ticket_origin === "email"
                  ? "envelope"
                  : data.ticket_origin === "phone"
                  ? "mobile-screen-button"
                  : "globe"
              }
              className="me-2"
            />
            {data.title}
          </span>
        </Card.Header>
        <Card.Body>
          <div className="mb-4">
            {data?.created_user && (
              <div className="d-md-flex d-xl-inline-block d-xxl-flex align-items-center justify-content-between mb-x1">
                <Flex alignItems="center" className="gap-2">
                  <Link to="/support-desk/contact-details">
                    <Avatar src={data.created_user.image} size="2xl" />
                  </Link>
                  <p className="mb-0">
                    <Link
                      to="/support-desk/contact-details"
                      className="fw-semi-bold mb-0 text-800"
                    >
                      {data.created_user_full_name}
                    </Link>
                    <span className="fs--2 text-800 fw-normal mx-2">
                      via {data.ticket_origin}
                    </span>
                    <Link className="mb-0 fs--1 d-block text-500">
                      {data.created_user.email}
                    </Link>
                  </p>
                </Flex>
                <p className="mb-0 fs--2 fs-sm--1 fw-semi-bold mt-2 mt-md-0 mt-xl-2 mt-xxl-0 ms-5">
                  {data.created_at}
                  <FontAwesomeIcon icon="star" className="ms-2 text-warning" />
                </p>
              </div>
            )}

            <div className="mt-2">
              <h6 className="mb-3 fw-semi-bold text-1000">
                {data.description}
              </h6>
              {Object.keys(data.media).length > 0 && (
                <Card body className="p-x1 bg-light rounded-3 mt-3 rounded-0">
                  <div className="d-inline-flex flex-column">
                    {data.media.map((media, key) => (
                      <Fragment key={key}>
                        <File
                          title={`${media.file_name} (${media.human_readable_size})`}
                          icon={
                            media.extension === "xlsx"
                              ? "file-excel"
                              : media.extension === "pdf"
                              ? "file-pdf"
                              : media.extension === "doc"
                              ? "file-word"
                              : media.extension === "txt"
                              ? "file-lines"
                              : media.extension === "csv"
                              ? "file-csv"
                              : media.extension === "png" ||
                                media.extension === "jpeg"
                              ? "file-image"
                              : "file-archive"
                          }
                          color={
                            media.extension === "xlsx"
                              ? "#007f3eb5"
                              : media.extension === "pdf"
                              ? "#fb3939b8"
                              : media.extension === "doc"
                              ? "#285293"
                              : media.extension === "txt"
                              ? "#285293"
                              : media.extension === "csv"
                              ? "#41a754cc"
                              : media.extension === "png" ||
                                media.extension === "jpeg"
                              ? "#4f88e2"
                              : "#4f88e2"
                          }
                          action="Download"
                          actionIcon="arrow-down"
                          className="mb-2"
                          link={media.file_url}
                        />
                      </Fragment>
                    ))}
                  </div>
                  <hr className="my-x1" />
                  <Row className="flex-between-center gx-4 gy-2">
                    <Col xs="auto">
                      <p className="fs--1 text-1000 mb-sm-0 font-sans-serif fw-medium mb-0">
                        <FontAwesomeIcon icon="link" className="me-2" />
                        {Object.keys(data.media).length} files attached
                      </p>
                    </Col>
                    <Col xs="auto">
                      {downloadLoading ? (
                        <IconButton variant="falcon-default" size="sm">
                          <Spinner size="sm" />
                        </IconButton>
                      ) : (
                        <IconButton
                          icon="arrow-down"
                          variant="falcon-default"
                          size="sm"
                          onClick={() => {
                            handleBulkDownload(overviewId, "TICKET");
                          }}
                        >
                          <span className="ms-1">Download all</span>
                        </IconButton>
                      )}
                    </Col>
                  </Row>
                </Card>
              )}
            </div>
          </div>
          <Comments id={overviewId} comment_type="ticket" />
        </Card.Body>
      </Card>
    </>
  );
};

export default TicketOverViewConversation;
