import DigitalTimer from "module/common/Timer/DigitalTimer";
import React, { useContext, useEffect, useState } from "react";
import { Card, Dropdown, ListGroup, ListGroupItem } from "react-bootstrap";
import { FaShareSquare } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { AuthWizardContext } from "context/Context";
import ListLoading from "module/common/ListLoading";
import { apiCall } from "helpers/apiCalls";
import { RiTimerFlashLine } from "react-icons/ri";
import TimerConfirm from "module/common/Timer/TimerConfirm";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";

const TaskTimerSection = () => {
  const location = useLocation();
  const { user, dispatch } = useContext(AuthWizardContext);
  const [show, setShow] = useState(false);
  const [runningTasksDetails, setRunningTasksDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(false);

  const handleClose = (e) => {
    setShowTimer(false);
    e?.stopPropagation();
  };
  const handleShow = (e) => {
    setShowTimer(true);
    e?.stopPropagation();
  };

  const handleClick = async (event) => {
    setShow(!show);
    getData();
  };

  const getData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/running-tasks-info",
    });

    setRunningTasksDetails(data);
    setLoading(false);
  };

  useEffect(() => {
    setShow(false);
  }, [location.pathname]);

  const handleRequestError = (error) => {
    if (error && error.response.data.message) {
      const validationError = error.response.data.data?.errors;
      showToast(validationError.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
  };

  const handleTimer = async (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    setLoadingTimer(true);
    setShow(false);

    const runningTask = runningTasksDetails?.tasks[0];
    try {
      const response = await axios.post(
        Object.keys(runningTask.current_time_tracker).length > 0
          ? `crm/stop-timer/${runningTask?.id}`
          : `crm/start-timer/${runningTask?.id}`
      );

      if (response.data.success === true) {
        const res = await axios.get("crm/running-tasks-info");

        if (res.data.success === true) {
          const updatedData = {
            ...user,
            running_tasks_count: res.data.data.count,
          };
          dispatch({ type: "UPDATE", payload: { ...updatedData } });
          setShowTimer(false);
        }
      }
    } catch (error) {
      handleRequestError(error);
      const updatedData = {
        ...user,
        running_tasks_count: 0,
      };
      dispatch({ type: "UPDATE", payload: { ...updatedData } });
      setShowTimer(false);
    } finally {
      setLoadingTimer(false);
    }
  };

  return (
    <>
      {user?.running_tasks_count > 0 && (
        <Dropdown
          className="d-inline mx-2 shadow"
          align={{ sm: "start" }}
          onToggle={handleClick}
        >
          <Dropdown.Toggle
            id="dropdown-autoclose-true"
            variant="light"
            className="border"
          >
            <span className="fs--1 fw-bold">
              {user.running_tasks_count} Running{" "}
              {user.running_tasks_count > 1 ? "Tasks" : "Task"}
            </span>
            <RiTimerFlashLine size={25} color="#3eab37" className="ms-2" />
          </Dropdown.Toggle>
          <Dropdown.Menu
            className="dropdown-menu-card mt-2 p-0 rounded-0"
            style={{ width: "60vh" }}
          >
            <Dropdown.Item as={Card} className="p-0">
              <ListGroup>
                {!loading ? (
                  runningTasksDetails?.tasks?.map((item, key) => (
                    <ListGroupItem
                      className={`border-0 ${
                        key > 0 && "border-bottom rounded-0"
                      }`}
                      key={key}
                    >
                      <div className="d-flex flex-column">
                        <div className="d-flex">
                          <DigitalTimer
                            startTime={
                              item?.current_time_tracker[0]?.start_time
                            }
                            handleButtonVisibility={handleShow}
                            loadingTimerButton={loadingTimer}
                            timerRunning={
                              Object.keys(item?.current_time_tracker).length > 0
                                ? true
                                : false
                            }
                          />
                          <Link
                            className="ms-3 ms-auto"
                            to={`tasks/list/overview/${item.id}`}
                          >
                            <FaShareSquare />
                          </Link>
                        </div>
                        <span className="custom-text-muted-crm mt-2">
                          <span>Task</span>{" "}
                          <span className="fw-semibold">{`#${item.task_number}`}</span>
                        </span>
                        <span className="fs--1 fw-bold text-wrap">
                          {item.title}
                        </span>
                        <span className="custom-text-muted-crm mt-2">
                          Project
                        </span>
                        <span className="fs--1 fw-bold">
                          {item.project_title}
                        </span>
                      </div>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListLoading
                    style={{ maxHeight: "9vh", overflow: "hidden" }}
                  />
                )}
              </ListGroup>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {Object.keys(runningTasksDetails)?.length > 0 &&
        runningTasksDetails?.tasks?.length > 0 && (
          <TimerConfirm
            show={showTimer}
            handleClose={handleClose}
            loading={loadingTimer}
            handleTimer={handleTimer}
            timerRunningTask={runningTasksDetails?.tasks[0]?.id}
          />
        )}
    </>
  );
};

export default TaskTimerSection;
