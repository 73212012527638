import { React, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import { opportunityFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import removeRefData from "helpers/removeRefData";
import ErrorAlert from "module/common/ErrorAlert";
import SelectOpportunityStage from "components/form/SelectOpportunityStage";
import SelectCampaign from "components/form/SelectCampaign";
import SelectLead from "components/form/SelectLead";
import SelectCustomer from "components/form/SelectCustomer";
import SoftBadge from "components/common/SoftBadge";
import ListLoading from "module/common/ListLoading";
import { TbBulbFilled } from "react-icons/tb";
import SelectOpportunityType from "components/form/SelectOpportunityType";
import SelectTimeFrame from "components/form/SelectTimeFrame";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import SelectUsers from "components/form/SelectUsers";

function OpportunityForm({ getData }) {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [leadInfo, setLeadInfo] = useState();
  const [formData, setFormData] = useState(opportunityFormKeys);
  const [formError, setFormError] = useState({});
  const [dataProcessing, setDataProcessing] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const showModal = queryParams.get("opportunity_form_modal") ? true : false;
  const pageNumber = queryParams.get("page");
  const itemId = queryParams.get("item_id")
    ? queryParams.get("item_id")
    : undefined;
  const customerId = queryParams.get("customer_id")
    ? queryParams.get("customer_id")
    : undefined;
  const customerName = queryParams.get("customer_name")
    ? queryParams.get("customer_name")
    : undefined;
  const lead_id = queryParams.get("lead_id")
    ? queryParams.get("lead_id")
    : undefined;
  useEffect(() => {
    setFormData(opportunityFormKeys);
    if (itemId) {
      getOpportunityData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    if (lead_id) {
      getLeadInformation(lead_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead_id]);
  const getLeadInformation = async (lead_id) => {
    setDataProcessing(true);
    try {
      const data = await apiCall({
        url: "crm/lead/" + lead_id,
      });

      setLeadInfo(data);

      if (data) {
        setFormData({
          ...formData,
          lead_id: lead_id,
          lead_id_ref: { label: data?.title, value: data?.id },
        });
      }
      setDataProcessing(false);
      formData.customer_id = customerId;
      formData.customer_id_ref = { label: customerName, value: customerId };
    } catch (error) {
      console.log(error);
    }
  };
  const getOpportunityData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: "crm/opportunities/" + itemId,
    });

    setFormData({
      ...formData,
      title: data.title,
      stage: data.stage,
      stage_ref: { label: data.stage, value: data.stage },
      campaign_id: data.campaign_id,
      campaign_id_ref: { label: data.campaign_name, value: data.campaign_id },
      lead_id: data.lead_id,
      lead_id_ref: { label: data.lead_title, value: data.lead_id },
      description: data.description,
      customer_id: data.customer_id,
      customer_id_ref: { label: data.customer_name, value: data.customer_id },
      amount: parseFloat(data?.amount?.replace(/,/g, "")),
      type: data.type,
      type_ref: { label: data.type, value: data.type },
      estimator_id: data.estimator_id,
      estimator_id_ref: {
        label: data.estimator_name,
        value: data.estimator_id,
      },
      time_frame: data.time_frame,
      time_frame_ref: { label: data.time_frame, value: data.time_frame },
      expected_amount: parseFloat(data?.expected_amount?.replace(/,/g, "")),
      opportunity_created_at: data.opportunity_created_at,
      opening_date: data.opening_date,
      expected_closing_date: data.expected_closing_date,
      closing_date: data.closing_date,
      invoicing_probability_date: data.invoicing_probability_date,
      proposal_created_date: data.proposal_created_date,
      proposal_last_revision_date: data.proposal_last_revision_date,
      cost_amount: parseFloat(data?.cost_amount?.replace(/,/g, "")),
    });
    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleCancel = () => {
    setFormData(opportunityFormKeys);
    if (lead_id) {
      navigate("/opportunity");
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = () => {
    setFormError({});
    setOnSave(true);
    let formDataWithoutRefkeys = removeRefData(formData);
    axios({
      method: itemId ? "put" : "post",
      url: `crm/opportunities${itemId ? "/" + itemId : ""}`,
      headers: {
        Accept: "application/json",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          navigate("/opportunity");
          showToast(response.data.message, "success");
          getData();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error.response?.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <Modal className="mb-3 h-100 rounded-0" show={showModal} size="xl">
      <>
        <Modal.Header
          className="d-flex flex-row justify-content-between border-bottom gap-1"
          onHide={() => {
            navigate(`/opportunity${pageNumber ? "?page=" + pageNumber : ""}`);
          }}
        >
          {!dataProcessing ? (
            <div className="d-flex align-items-center pt-2 pb-2">
              <TbBulbFilled size={25} className="text-dark me-1" />
              <span className="custom-module-header text-uppercase">
                {itemId ? "Update Opportunity" : "Create Opportunity"}
              </span>
              {leadInfo && (
                <div>
                  Lead reference :
                  <SoftBadge bg="success">{leadInfo?.reference}</SoftBadge>{" "}
                </div>
              )}
              {leadInfo && (
                <div>
                  Title:
                  <span className="text-dark ms-2">{leadInfo?.title}</span>
                </div>
              )}
            </div>
          ) : (
            <ListLoading
              style={{
                maxHeight: "9vh",
                overflow: "hidden",
              }}
            />
          )}
        </Modal.Header>
        <Form>
          <Card.Body>
            {Object.keys(formError).length > 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ""
            )}
            {!dataProcessing ? (
              <>
                <Row className="gy-3">
                  <Col md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="require-data">Account</Form.Label>
                      <SelectCustomer
                        name="customer_id"
                        value={formData.customer_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label className="require-data">Stage</Form.Label>
                      <SelectOpportunityStage
                        name="stage"
                        value={formData.stage_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                    {formData.stage === "Closed Lost" && (
                      <Form.Group className="mt-3 ">
                        <Form.Label className="require-data">
                          Lost Reason
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          name="lost_reason"
                          onChange={handleFieldChange}
                          value={formData.lost_reason}
                        />
                      </Form.Group>
                    )}
                    <Form.Group className="mt-3">
                      <Form.Label>Expected Amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="expected_amount"
                        onChange={handleFieldChange}
                        value={formData.expected_amount}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Created Date</Form.Label>
                      <AppDatePicker
                        name="opportunity_created_at"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.opportunity_created_at}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Closing Date</Form.Label>
                      <AppDatePicker
                        name="closing_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.closing_date}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Estimator</Form.Label>
                      <SelectUsers
                        name="estimator_id"
                        value={formData.estimator_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="require-data">Type</Form.Label>
                      <SelectOpportunityType
                        name="type"
                        value={formData.type_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Campaign</Form.Label>
                      <SelectCampaign
                        name="campaign_id"
                        value={formData.campaign_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label>Actual Amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="amount"
                        onChange={handleFieldChange}
                        value={formData.amount}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Opening Date</Form.Label>
                      <AppDatePicker
                        name="opening_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.opening_date}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Proposal Created Date</Form.Label>
                      <AppDatePicker
                        name="proposal_created_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.proposal_created_date}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Invoicing Probability Date</Form.Label>
                      <AppDatePicker
                        name="invoicing_probability_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.invoicing_probability_date}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4} sm={12}>
                    <Form.Group>
                      <Form.Label className="require-data">Title</Form.Label>
                      <Form.Control
                        type="text"
                        name="title"
                        onChange={handleFieldChange}
                        value={formData.title}
                      />
                    </Form.Group>

                    <Form.Group className="mt-3">
                      <Form.Label>Lead</Form.Label>
                      <SelectLead
                        name="lead_id"
                        value={formData.lead_id_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Time Frame</Form.Label>
                      <SelectTimeFrame
                        name="time_frame"
                        value={formData.time_frame_ref}
                        handleFieldChange={handleFieldChange}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Expected Closing Date</Form.Label>
                      <AppDatePicker
                        name="expected_closing_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.expected_closing_date}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Proposal Last Revision Date</Form.Label>
                      <AppDatePicker
                        name="proposal_last_revision_date"
                        yearPlaceholder="yyyy"
                        monthPlaceholder="mm"
                        dayPlaceholder="dd"
                        onChange={handleFieldChange}
                        value={formData.proposal_last_revision_date}
                      />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Label>Cost Amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="cost_amount"
                        onChange={handleFieldChange}
                        value={formData.cost_amount}
                      />
                    </Form.Group>
                  </Col>
                  <Form.Group as={Col} xs={12} className="mt-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      name="description"
                      onChange={handleFieldChange}
                      value={formData.description}
                    />
                  </Form.Group>
                </Row>
              </>
            ) : (
              <ListLoading style={{ maxHeight: "55vh", overflow: "hidden" }} />
            )}
          </Card.Body>
          <Modal.Footer className="border-top d-flex flex-row gap-2 justify-content-end">
            {!dataProcessing ? (
              <>
                <Form.Group>
                  <CancelButton handleCancel={handleCancel} onSave={onSave} />
                </Form.Group>
                <Form.Group className="d-flex justify-content-end">
                  <SaveButton
                    handleSubmit={handleSubmit}
                    onSave={onSave}
                    id={itemId}
                  />
                </Form.Group>
              </>
            ) : (
              <ListLoading
                style={{
                  maxHeight: "9vh",
                  maxWidth: "20vh",
                  overflow: "hidden",
                }}
              />
            )}
          </Modal.Footer>
        </Form>
      </>
    </Modal>
  );
}
OpportunityForm.prototype = {
  getData: PropTypes.func,
};
export default OpportunityForm;
