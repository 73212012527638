import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import FalconCardHeader from "components/common/FalconCardHeader";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
function ProjectDescription({ description, loading }) {
  return (
    <Card className="card-main h-100" style={{ minHeight: "20vh" }}>
      <FalconCardHeader title="Project Description" titleTag="h6" />
      {description && !loading ? (
        <>
          <Card.Body
            className="pt-3"
            style={{ overflow: "none", overflowY: "scroll", height: "10rem" }}
          >
            <ShowHtmlContent description={description} />
          </Card.Body>
        </>
      ) : loading ? (
        <Card.Body>
          <DashboardTileLoader column={3} />
        </Card.Body>
      ) : (
        <div className="h-100 d-flex align-items-center justify-content-center">
          <h2 className="fs-1 m-0">No Description!</h2>
        </div>
      )}
    </Card>
  );
}

ProjectDescription.propTypes = {
  description: PropTypes.any,
};

export default ProjectDescription;
