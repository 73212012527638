import SoftBadge from "components/common/SoftBadge";
import { apiCall } from "helpers/apiCalls";
import useAxisproPermission from "hooks/useAxisproPermission";
import DoughnutChart from "module/common/Charts/DoughnutChart";
import LineChart from "module/common/Charts/LineChart";
import ListLoading from "module/common/ListLoading";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import { React, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
} from "react-bootstrap";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaCircle,
  FaPlusCircle,
  FaStopwatch,
  FaThList,
} from "react-icons/fa";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

const TicketDashboardContent = ({ graphData, loading }) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [loadingData, setLoadingData] = useState(false);
  const [criticalTickets, setCriticalTickets] = useState({});
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const [userProfile, setUserProfile] = useState();
  const axisProPermission = useAxisproPermission();
  const [listTicketPermission, storeTicketPermission] = [
    "list-ticket",
    "store-ticket",
  ].map((permission) => axisProPermission(permission));

  const data = {
    labels:
      Object.keys(graphData).length > 0
        ? Object.keys(graphData.origin_count)
        : [],
    datasets: [
      {
        data:
          Object.keys(graphData).length > 0
            ? Object.keys(graphData.origin_count).map(
                (item) => graphData.origin_count[item]
              )
            : [],
        backgroundColor: ["#FF90A6", "#88E2EB", "#D2A0F7"],
        hoverBackgroundColor: ["#FF90A6", "#88E2EB", "#D2A0F7"],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { padding: 10, boxPadding: 5 },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: "83%",
  };

  const dataLineChart = {
    labels:
      Object.keys(graphData).length > 0
        ? Object.keys(graphData.total_resolution_time)
        : [],
    datasets: [
      {
        label: "My Tickets",
        data:
          Object.keys(graphData).length > 0
            ? Object.keys(graphData.my_resolution_time).map(
                (item) => graphData.my_resolution_time[item].value
              )
            : [],
        fill: false,
        borderColor: "#97E6EB",
        tension: 0.5,
        pointStyle: false,
        borderWidth: 2,
      },
      {
        label: "Total Tickets",
        data:
          Object.keys(graphData).length > 0
            ? Object.keys(graphData.total_resolution_time).map(
                (item) => graphData.total_resolution_time[item].value
              )
            : [],
        fill: false,
        borderColor: "#D1DAD9",
        tension: 0.5,
        pointStyle: false,
        borderWidth: 2,
      },
    ],
  };

  const optionsLineChart = {
    responsive: true,
    layout: {
      padding: 0,
    },
    plugins: {
      title: {
        font: {
          size: 12,
          weight: "normal",
        },
        text: "Last 6 Month",
        color: "#818482",
        display: true,
        align: "start",
        position: "top",
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const getDataItem = async (page = 1) => {
    setLoadingData(true);
    try {
      const data = await apiCall({
        url: "crm/ticket",
        params: {
          page: page,
          filters: [
            {
              column: "priority",
              operator: "=",
              type: "string",
              value: "high",
              value_ref: "high",
            },
            {
              column: "status",
              operator: "!=",
              type: "string",
              value: "closed",
              value_ref: "closed",
            },
          ],
        },
      });

      setCriticalTickets(data);
      setLoadingData(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDataItem();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserProfileArea = (id) => {
    setUserProfile(id);
    setShowUserProfileArea(true);
  };

  return (
    <>
      <Row className="pe-2 ps-2 mt-3">
        <Col md={9} className="pe-1 ps-1  d-flex">
          <Card className="flex-fill">
            <Card.Header className="pb-1">
              <div className="d-flex align-items-center">
                <div className="ticket-larger-text  d-inline">
                  <span className="">
                    <FaStopwatch color="#FD7D5D" size={22} className="me-2" />
                  </span>
                  <span className="">Tickets</span>
                </div>
                <div className="d-inline ms-auto">
                  {listTicketPermission && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>Ticket List</strong>
                        </Tooltip>
                      }
                    >
                      <Button
                        size="sm"
                        variant="light"
                        as={Link}
                        to={`/tickets/list?${createSearchParams({
                          ...allQueryParams,
                          tab: "all",
                        })}`}
                      >
                        <FaThList />
                      </Button>
                    </OverlayTrigger>
                  )}
                  {storeTicketPermission && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">
                          <strong>Add Ticket</strong>
                        </Tooltip>
                      }
                    >
                      <Button
                        size="sm"
                        variant="light ms-2"
                        as={Link}
                        to={"/tickets/add"}
                      >
                        <FaPlusCircle />
                      </Button>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {!loadingData ? (
                <div className="mt-3">
                  {Object.keys(criticalTickets).length > 0 &&
                  Object.keys(criticalTickets.data).length > 0 ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr className="table-secondary fw-bold">
                          <th className="fw-bold">#</th>
                          <th className="fw-bold">Ticket</th>
                          <th className="fw-bold">Project</th>
                          <th className="fw-bold">Client</th>
                          <th className="fw-bold">Type</th>
                          <th className="fw-bold">Origin</th>
                          <th className="fw-bold">Assignee</th>
                          <th className="fw-bold">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {criticalTickets.data.map((ticket, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td className="text-capitalize">
                              <Link
                                className="text-decoration-none text-dark"
                                to={`/tickets/list/overview/${ticket.id}`}
                              >
                                {ticket.title}
                              </Link>
                            </td>
                            <td className="text-capitalize">
                              <Link
                                className="text-decoration-none text-dark"
                                to={`/project/info/${ticket.project_id}?tab=overview`}
                              >
                                {ticket.project?.title}
                              </Link>
                            </td>
                            <td className="text-capitalize">
                              <Link
                                className="text-decoration-none text-dark"
                                to={`/client/overview/${ticket.customer?.id}`}
                              >
                                {ticket.customer?.name}
                              </Link>
                            </td>
                            <td className="text-capitalize">
                              {ticket.ticket_type?.title}
                            </td>
                            <td className="text-capitalize">
                              {ticket.ticket_origin}
                            </td>
                            <td className="text-capitalize">
                              <Link
                                onClick={() =>
                                  handleUserProfileArea(ticket?.assignee?.id)
                                }
                                className="custom-link-style-crm"
                              >
                                {ticket?.assignee?.name}
                              </Link>
                            </td>
                            <td className="text-capitalize">
                              <SoftBadge
                                bg={`${
                                  ticket.status === "with_client_review"
                                    ? "warning"
                                    : ticket.status ===
                                      "awaiting_business_input"
                                    ? "info"
                                    : "success"
                                }`}
                                className="rounded-4 ps-2 pe-2"
                              >
                                {" "}
                                {ticket.status === "with_client_review"
                                  ? "Review"
                                  : ticket.status === "awaiting_business_input"
                                  ? "Await"
                                  : ticket.status}
                              </SoftBadge>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot className="border">
                        <tr>
                          <td colSpan={8}>
                            <span className="d-flex justify-content-center fw-bold">
                              <Button
                                variant="transparant"
                                size="sm"
                                className="custom-transperent-common-button"
                                as={Link}
                                to={`list?${createSearchParams({
                                  filters: JSON.stringify({
                                    column: "priority",
                                    operator: "=",
                                    type: "string",
                                    value: "high",
                                    value_ref: null,
                                  }),
                                })}&tab=all`}
                              >
                                <FaAngleDoubleLeft size={25} className="me-1" />
                                More
                                <FaAngleDoubleRight
                                  size={25}
                                  className="ms-1"
                                />
                              </Button>
                            </span>
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  ) : (
                    <div
                      className="d-flex justify-content-center align-items-center border"
                      style={{ minHeight: "65vh" }}
                    >
                      <h5 className="text-center text-secondary">
                        <p className="text-capitalize">
                          No Critical Tickets Available.
                        </p>
                      </h5>
                    </div>
                  )}
                </div>
              ) : (
                <div style={{ maxHeight: "65vh", overflow: "hidden" }}>
                  <ListLoading />
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={3} className="pe-1 ps-1">
          <Card style={{ minHeight: "30vh" }}>
            {!loading ? (
              <>
                <Card.Header className="ticket-larger-text">
                  <div className="d-flex align-items-center">
                    <span>Tickets by Origin</span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="d-flex justify-content-center">
                    <DoughnutChart
                      {...{
                        height: "10.5vh",
                        width: "10.5vh",
                        data: data,
                        options: options,
                      }}
                    />
                  </div>
                  <div className="d-flex pt-3">
                    <ListGroup
                      horizontal
                      className="w-100 justify-content-center"
                    >
                      {Object.keys(graphData).length > 0 &&
                        Object.keys(graphData.origin_count).map((item, key) => (
                          <ListGroupItem className="border-0 pe-1" key={key}>
                            <div className="d-grid ps-2 pe-3 border-end">
                              <span className="text-capitalize">{item}</span>
                              <span>
                                <FaCircle
                                  color={
                                    item === "email"
                                      ? "#FF90A6"
                                      : item === "web"
                                      ? "#88E2EB"
                                      : "#D2A0F7"
                                  }
                                  size={8}
                                />
                                {graphData.origin_count[item]}
                              </span>
                            </div>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </div>
                </Card.Body>
              </>
            ) : (
              <div style={{ maxHeight: "25vh", overflow: "hidden" }}>
                <ListLoading />
              </div>
            )}
          </Card>
          <Card className="mt-3" style={{ minHeight: "40vh" }}>
            {!loading ? (
              <>
                <Card.Header className="ticket-larger-text pb-0">
                  <div className="d-flex align-items-center">
                    <span>Average Resolution Time</span>
                  </div>
                </Card.Header>
                <Card.Body className="pt-2">
                  <div className="d-flex justify-content-center">
                    <LineChart
                      data={dataLineChart}
                      options={optionsLineChart}
                    />
                  </div>
                  <div className="d-flex mt-3">
                    <ListGroup
                      horizontal
                      className="w-100 justify-content-center"
                    >
                      <ListGroupItem className="border-0 pe-1">
                        <div className="d-grid ps-2 pe-3 border-end">
                          <span>My Tickets</span>
                          <span>
                            <FaCircle
                              color="#88E2EB"
                              size={8}
                              className="me-2"
                            />
                            {graphData.my_total_average_resolution_time}
                          </span>
                        </div>
                      </ListGroupItem>
                      <ListGroupItem className="border-0 ps-1">
                        <div className="d-grid ps-2 pe-3">
                          <span>Total Tickets</span>
                          <span>
                            <FaCircle
                              color="#b8c6c5"
                              size={8}
                              className="me-2"
                            />
                            {graphData.total_average_resolution_time}
                          </span>
                        </div>
                      </ListGroupItem>
                    </ListGroup>
                  </div>
                </Card.Body>
              </>
            ) : (
              <div style={{ maxHeight: "35vh", overflow: "hidden" }}>
                <ListLoading />
              </div>
            )}
          </Card>
        </Col>
      </Row>
      <UserProfile
        show={showUserProfileArea}
        handleClose={() => setShowUserProfileArea(false)}
        id={userProfile}
      />
    </>
  );
};

export default TicketDashboardContent;
