import { useContext } from "react";
import { AuthWizardContext } from "context/Context";

const useAxisproPermission = () => {
  const { user } = useContext(AuthWizardContext);
  const axisProPermission = (slug) => {
    if (
      user &&
      user.assigned_permissions &&
      user.assigned_permissions.some(
        (permission) => permission.permission_slug === slug
      )
    )
      return true;
    else return false;
  };

  return axisProPermission;
};

export default useAxisproPermission;
