import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Col, Form, Row, Modal } from "react-bootstrap";
import { FaPeopleArrows } from "react-icons/fa";
import { apiCall } from "helpers/apiCalls";
import { campaignFormKeys } from "helpers/formKeys";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { showToast } from "module/common/Toast/toast";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import BackButton from "components/common/BackButton";
import ListLoading from "module/common/ListLoading";
import SelectUsers from "components/form/SelectUsers";
import SelectProduct from "components/form/SelectProduct";
import SelectCampaignType from "components/form/SelectCampaignType";
import SelectCampaignStatus from "components/form/SelectCampaignStatus";
import removeRefData from "helpers/removeRefData";

function CampaignForm() {
  let navigate = useNavigate();
  const [getData] = useOutletContext();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const [formData, setFormData] = useState(campaignFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const {
    name,
    sponsor,
    type,
    status,
    product_id,
    date_range_for,
    from_date,
    to_date,
    assigned_to,
    location,
  } = allQueryParams;
  const isParamsAvailable = name || sponsor || type || status || product_id || date_range_for || from_date || to_date || assigned_to || location

  useEffect(() => {
    if (itemId) {
      getDataById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const convertToNumber = (value) => {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      return parseFloat(value.replace(/,/g, ""));
    } else {
      return NaN;
    }
  };

  const getDataById = async (page = 1) => {
    setDataProcessing(true);
    const data = await apiCall({
      url: "crm/campaign/" + itemId,
      params: {
        page: page,
      },
    });

    setFormData({
      ...formData,
      name: data?.name,
      start_date: data?.start_date,
      end_date: data?.end_date,
      sponsor: data?.sponsor,
      type: data?.type,
      type_ref: {
        label: data?.type,
        value: data?.type,
      },
      budget: convertToNumber(data?.budget),
      revenue: convertToNumber(data?.revenue),
      status: data?.status,
      status_ref: {
        label: data?.status,
        value: data?.status,
      },
      product_id: data?.product?.id,
      product_id_ref: {
        label: data?.product?.name,
        value: data?.product?.id,
      },
      target_audience_count: convertToNumber(data?.target_audience_count),
      actual_audience_count: convertToNumber(data?.actual_audience_count),
      target_audience_type: data?.target_audience_type,
      assigned_to: data?.assigned_to,
      assigned_to_ref: {
        label: data?.assigned_user_full_name,
        value: data?.assigned_to,
      },
      description: data?.description,
      actual_cost: convertToNumber(data?.actual_cost),
      expected_revenue: convertToNumber(data?.expected_revenue),
      expected_response_count: data?.expected_response_count,
      actual_response_count: data?.actual_response_count,
      expected_sales_count: data?.expected_sales_count,
      actual_sales_count: data?.actual_sales_count,
      expected_roi: convertToNumber(data?.expected_roi),
      actual_roi: convertToNumber(data?.actual_roi),
      parent_id: data?.parent_id,
      num_sent: data?.num_sent,
      location: data?.location,
    });
    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleSubmit = () => {
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefKeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/campaign${itemId ? "/" + itemId : ""}`,
      headers: {
        Accept: "application/json",
      },
      data: formDataWithoutRefKeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          navigate(itemId ? -1 : `/campaign`);
          (!isParamsAvailable || itemId) && getData(itemId ? pageNumber : 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data?.message) {
          const validation_error =
              error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error?.response?.data?.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <Modal
      show
      onHide={() => navigate(-1)}
      size="xl"
      backdrop="static"
      className=""
    >
      <Modal.Header closeButton className="mt-2 mb-2">
        <FaPeopleArrows size={25} className="me-2 text-dark" />
        <span className="custom-module-header">{`${!itemId ? "NEW" : "UPDATE"
          } CAMPAIGN`}</span>
      </Modal.Header>

      <Modal.Body>
        {!dataProcessing ? (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12} lg={6} xl={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="require-data">Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleFieldChange}
                    value={formData?.name}
                    placeholder="Enter Name"
                    isInvalid={!!formError?.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.name}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label className="require-data">Product</Form.Label>
                  <SelectProduct
                    value={formData?.product_id_ref}
                    name="product_id"
                    error={formError?.product_id}
                    handleFieldChange={handleFieldChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.product_id}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Assigned To</Form.Label>
                  <SelectUsers
                    value={formData?.assigned_to_ref}
                    name="assigned_to"
                    error={formError?.assigned_to}
                    handleFieldChange={handleFieldChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.assigned_to}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="gx-2">
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label className="require-data">Budget</Form.Label>
                      <Form.Control
                        type="number"
                        name="budget"
                        onChange={handleFieldChange}
                        value={formData?.budget === 0 ? "" : formData?.budget}
                        placeholder="Enter Budget"
                        isInvalid={!!formError?.budget}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.budget}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Actual Cost</Form.Label>
                      <Form.Control
                        type="number"
                        name="actual_cost"
                        onChange={handleFieldChange}
                        value={
                          formData?.actual_cost === 0
                            ? ""
                            : formData?.actual_cost
                        }
                        placeholder="Enter Actual Cost"
                        isInvalid={!!formError?.actual_cost}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.actual_cost}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="gx-2">
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Expected Response Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="expected_response_count"
                        onChange={handleFieldChange}
                        value={
                          formData?.expected_response_count === 0
                            ? ""
                            : formData.expected_response_count
                        }
                        placeholder="Enter Expected Response Count"
                        isInvalid={!!formError?.expected_response_count}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.expected_response_count}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Actual Response Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="actual_response_count"
                        onChange={handleFieldChange}
                        value={
                          formData?.actual_response_count === 0
                            ? ""
                            : formData.actual_response_count
                        }
                        placeholder="Enter Actual Response Count"
                        isInvalid={!!formError?.actual_response_count}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.actual_response_count}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6} xl={4}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label className="require-data">Type</Form.Label>
                  <SelectCampaignType
                    value={formData?.type_ref}
                    name="type"
                    error={formError?.type}
                    handleFieldChange={handleFieldChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.type}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label className="require-data">Sponsor</Form.Label>
                  <Form.Control
                    type="text"
                    name="sponsor"
                    onChange={handleFieldChange}
                    value={formData?.sponsor}
                    placeholder="Enter Sponsor"
                    isInvalid={!!formError?.sponsor}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.sponsor}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label className="require-data">Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    onChange={handleFieldChange}
                    value={formData?.location}
                    placeholder="Enter Location"
                    isInvalid={!!formError?.location}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.location}
                  </Form.Control.Feedback>
                </Form.Group>

                <Row className="gx-2">
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Expected Roi</Form.Label>
                      <Form.Control
                        type="number"
                        name="expected_roi"
                        onChange={handleFieldChange}
                        value={
                          formData?.expected_roi === 0
                            ? ""
                            : formData?.expected_roi
                        }
                        placeholder="Enter Expected Roi"
                        isInvalid={!!formError?.expected_roi}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.expected_roi}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Actual Roi</Form.Label>
                      <Form.Control
                        type="number"
                        name="actual_roi"
                        onChange={handleFieldChange}
                        value={
                          formData?.actual_roi === 0 ? "" : formData?.actual_roi
                        }
                        placeholder="Enter Actual Roi"
                        isInvalid={!!formError?.actual_roi}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.actual_roi}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="gx-2">
                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Expected Sales Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="expected_sales_count"
                        onChange={handleFieldChange}
                        value={
                          formData?.expected_sales_count === 0
                            ? ""
                            : formData?.expected_sales_count
                        }
                        placeholder="Enter Expected Sales Count"
                        isInvalid={!!formError?.expected_sales_count}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.expected_sales_count}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>

                  <Col xs={12} md={12} lg={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput3"
                    >
                      <Form.Label>Actual Sales Count</Form.Label>
                      <Form.Control
                        type="number"
                        name="actual_sales_count"
                        onChange={handleFieldChange}
                        value={
                          formData?.actual_sales_count === 0
                            ? ""
                            : formData.actual_sales_count
                        }
                        placeholder="Enter Expected Sales Count"
                        isInvalid={!!formError?.actual_sales_count}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.actual_sales_count}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={12} xl={4}>
                <Row>
                  <Col md={12} lg={6} xl={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput6"
                    >
                      <Form.Label className="require-data">Status</Form.Label>
                      <SelectCampaignStatus
                        value={formData?.status_ref}
                        name="status"
                        error={formError?.status}
                        handleFieldChange={handleFieldChange}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.status}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>
                        Target Audience Type
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="target_audience_type"
                        onChange={handleFieldChange}
                        value={formData?.target_audience_type}
                        placeholder="Enter Target Audience Type"
                        isInvalid={!!formError?.target_audience_type}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError?.target_audience_type}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Row className="gx-2">
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label className="require-data">
                            Start Date
                          </Form.Label>
                          <AppDatePicker
                            name="start_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            onChange={handleFieldChange}
                            value={formData?.start_date}
                            isInvalid={!!formError?.start_date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.start_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label className="require-data">
                            End Date
                          </Form.Label>
                          <AppDatePicker
                            name="end_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            onChange={handleFieldChange}
                            value={formData?.end_date}
                            isInvalid={!!formError?.end_date}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.end_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>

                  <Col md={12} lg={6} xl={12}>
                    <Row className="gx-2">
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Expected Revenue</Form.Label>
                          <Form.Control
                            type="number"
                            name="expected_revenue"
                            onChange={handleFieldChange}
                            value={
                              formData?.expected_revenue === 0
                                ? ""
                                : formData.expected_revenue
                            }
                            placeholder="Enter Expected Revenue"
                            isInvalid={!!formError?.expected_revenue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.expected_revenue}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label className="require-data">
                            Revenue
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name="revenue"
                            onChange={handleFieldChange}
                            value={formData?.revenue === 0 ? "" : formData?.revenue}
                            placeholder="Enter Revenue"
                            isInvalid={!!formError?.revenue}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.revenue}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="gx-2">
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Target Audience Count</Form.Label>
                          <Form.Control
                            type="number"
                            name="target_audience_count"
                            onChange={handleFieldChange}
                            value={formData?.target_audience_count === 0 ? "" : formData?.target_audience_count}
                            placeholder="Enter Target Audience Count"
                            isInvalid={!!formError?.target_audience_count}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.target_audience_count}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={12} lg={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Actual Audience Count</Form.Label>
                          <Form.Control
                            type="number"
                            name="actual_audience_count"
                            onChange={handleFieldChange}
                            value={formData?.actual_audience_count === 0 ? "" : formData?.actual_audience_count}
                            placeholder="Enter Actual Audience Count"
                            isInvalid={!!formError?.actual_audience_count}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError?.actual_audience_count}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6} xl={4}>
                <Row>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Number Of Email Sent</Form.Label>
                    <Form.Control
                      type="number"
                      name="num_sent"
                      onChange={handleFieldChange}
                      value={
                        formData?.num_sent === 0 ? "" : formData?.num_sent
                      }
                      placeholder="Enter Number Of Email Sent"
                      isInvalid={!!formError?.num_sent}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError?.num_sent}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group as={Col} xs={12} className="mt-2">
                  <Form.Label className="">Description</Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      onChange={handleFieldChange}
                      value={formData?.description}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="d-flex justify-content-end gap-1 mt-3">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </Form.Group>
          </Form>
        ) : (
          <ListLoading style={{ height: "52vh", overflow: "hidden" }} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default CampaignForm;