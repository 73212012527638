import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useState } from "react";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { AddAssigneeFormKeys } from "helpers/formKeys";
import AddAssigneForm from "module/Task/TaskForm/AddAssigneForm";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
const AddAssigneeModal = ({
  show,
  assignee_id,
  getData,
  taskId,
  handleClose,
  title = "ADD MEMBERS",
}) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(AddAssigneeFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const { overviewId } = useParams();
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    const data = {
      task_id: overviewId ? overviewId : taskId ? taskId : "",
      remove_assignee: assignee_id,
      new_assignee: formData.assinee,
    };
    axios({
      method: "post",
      url: `crm/task-assignees/update`,
      params: { ...data },
    })
      .then((response) => {
        if (response.data.success === true) {
          getData();
          showToast(response.data.message, "success");
          handleClose ? handleClose() : navigate(-1);
          setFormData(AddAssigneeFormKeys);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });

          if ("new_assignee" in validation_error) {
            showToast(validation_error.new_assignee[0], "error");
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };
  return (
    <Modal show={show}>
      <Modal.Header style={{ backgroundColor: "#EDF2F9" }}>
        <Modal.Title className="d-flex  align-items-center pt-2 pb-2">
          <FaUserPlus size={18} className="me-2 text-dark" />
          <div className="">{title}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddAssigneForm
          handleFieldChange={handleFieldChange}
          formData={formData}
          formError={formError}
          onSave={onSave}
          handleSubmit={handleSubmit}
          handleClose={handleClose}
        />
      </Modal.Body>
    </Modal>
  );
};
AddAssigneeModal.prototype = {
  show: PropTypes.bool,
  assignee_id: PropTypes.string,
  getData: PropTypes.func,
};
export default AddAssigneeModal;
