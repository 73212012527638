import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card, OverlayTrigger, Popover } from "react-bootstrap";
import qs from "qs";
import { apiCall } from "helpers/apiCalls";
import { FaCircle, FaUsers } from "react-icons/fa";
import { DeleteContext } from "context/Context";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AppPagination from "components/common/app-pagination/AppPagination";
import AddButton from "components/add-button/AddButton";
import ProfileImage from "../../../assets/img/Avathar/employee.avif";
import { RiMailSendLine } from "react-icons/ri";
import Invitation from "module/common/Invitation/Invitation";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import SimpleBar from "simplebar-react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonFilter from "components/filter/CommonFilter";
import { commonEmployeeFilterFormKeys } from "helpers/formKeys";

const EmployeeTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [showInvitation, setInvitation] = useState(false);
  const handleInvite = () => setInvitation(!showInvitation);
  const axisProPermission = useAxisproPermission();
  const actionSectionVisibility =
    axisProPermission("update-employee") || axisProPermission("delete-employee")
      ? ""
      : "d-none";
  const [filterVisibility, setFilterVisibility] = useState(true);
  const { full_name, designation_id, department_id, role_id, status } =
    allQueryParams;

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/employee/" + data,
        title: "Delete Employee",
        message: "Are you sure you want to delete this employee?",
        onSuccess: () => {
          const dataWithoutDeletedItem = employees.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/employee?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else {
            setEmployees((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "name",
      Header: "EMPLOYEE NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const {
          id,
          full_name,
          status,
          designation_name,
          company_name,
          office_number,
          fax,
          created_user_full_name,
          profile_pic,
        } = rowData.row.original;
        return (
          <div className="d-flex align-items-center">
            <OverlayTrigger
              placement="right"
              overlay={
                <Popover id="popover-basic">
                  <Popover.Header className="fs--1">
                    <strong>
                      {full_name}
                      <FaCircle
                        color={status === "Active" ? "#84cb66" : "#f55b5b"}
                        size={10}
                        className="ms-2"
                      />
                    </strong>
                    <br />
                    <span>{designation_name + " @ " + company_name}</span>
                  </Popover.Header>
                  <Popover.Body>
                    <span className="fs--2">
                      Official Contact : {office_number}
                    </span>
                    <br />
                    <span className="fs--2">Fax : {fax}</span>
                    <div className="mt-3 fs--2">
                      <span>Added By : {created_user_full_name}</span>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <Image
                className="custom-image-crm"
                src={profile_pic?.file_url ?? ProfileImage}
                roundedCircle
                alt="?"
                height={40}
                width={40}
              />
            </OverlayTrigger>
            <Link
              to={`overview/${id}?tab=gi`}
              style={{ textDecoration: "none" }}
            >
              <p className="m-2 text-capitalize custom-text-muted">
                {full_name}
              </p>
            </Link>
          </div>
        );
      },
    },
    {
      accessor: "designation_name",
      Header: "DESIGNATION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { designation_name } = rowData.row.original;
        return <div>{designation_name}</div>;
      },
    },
    {
      accessor: "department_name",
      Header: "DEPARTMENT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { department_name } = rowData.row.original;
        return <div>{department_name}</div>;
      },
    },
    {
      accessor: "roles",
      Header: "ROLES",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { roles } = rowData.row.original;
        return <div> {roles?.length > 0 ? roles[0].name : ""}</div>;
      },
    },
    {
      accessor: "email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <div>{email}</div>;
      },
    },
    {
      accessor: "phone",
      Header: "PHONE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { phone } = rowData.row.original;
        return <div>{phone}</div>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <div>
            <Badge bg={status === "Active" ? "success" : "warning"}>
              {status === "Active" ? " Active" : "Inactive"}
            </Badge>
          </div>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${actionSectionVisibility}`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${actionSectionVisibility}`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {axisProPermission("update-employee") && (
              <EditButton
                route={`/employee/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {axisProPermission("delete-employee") && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/employee",
      params: {
        page: page,
        full_name: full_name,
        designation_id: designation_id,
        department_id: department_id,
        role_id: role_id,
        status: status,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setEmployees(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    filterItems,
    full_name,
    designation_id,
    department_id,
    role_id,
    status,
  ]);

  return (
    <>
      <Card className="flex-fill mt-3 mb-3">
        <Card.Header className="p-0">
          <div className="d-flex flex-column justify-content-between">
            <div className="d-flex p-3">
              <div className="flex-grow-1 d-flex align-items-center">
                <FaUsers size={23} className="me-2 text-dark" />
                <span className="fs-1 text-uppercase">Employee Management</span>
              </div>
              {!isLoading &&
                Object.keys(employees).length > 0 &&
                Object.keys(employees.data).length > 0 && (
                  <>
                    <Button
                      size="sm"
                      className="me-1"
                      variant="secondary"
                      onClick={handleInvite}
                    >
                      Invite <RiMailSendLine />
                    </Button>
                    {axisProPermission("store-employee") && (
                      <AddButton
                        className="me-1"
                        to={`/employee/add?${createSearchParams({
                          ...allQueryParams,
                        })}`}
                      />
                    )}
                    <FilterCommonButton
                      filterVisibility={filterVisibility}
                      setFilterVisibility={setFilterVisibility}
                    />
                  </>
                )}
            </div>

            <div style={{ display: filterVisibility ? "block" : "none" }}>
              <CommonFilter filterKeys={commonEmployeeFilterFormKeys} />
            </div>
          </div>
        </Card.Header>
        <>
          {Object.keys(employees).length > 0 &&
          Object.keys(employees.data).length > 0 &&
          !isLoading ? (
            <>
              <AdvanceTableWrapper
                columns={columns}
                data={employees.data}
                sortable
              >
                <SimpleBar className="simplebar-style-with-pagination">
                  <Card.Body className="p-0 m-0">
                    <AdvanceTable
                      table
                      headerClassName="text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tablebodyClassName="custom-table-body"
                      tableProps={{
                        size: "sm",
                        className: "fs--1 mb-0 overflow-hidden custom-table",
                      }}
                    />
                  </Card.Body>
                </SimpleBar>
              </AdvanceTableWrapper>
              <Card.Footer className="border-top p-1 pt-3">
                {employees && employees.links && (
                  <AppPagination
                    data={employees}
                    url="/employee"
                    search={allQueryParams}
                  />
                )}
              </Card.Footer>
            </>
          ) : (
            <LoadingScreenCover>
              {isLoading ? (
                <LoadingCommon loadingText="Looking for Employees" />
              ) : (
                <h5 className="text-center text-secondary">
                  Employees not found! Let's add it &#128578;
                </h5>
              )}
              {!isLoading && (
                <div className="d-flex flex-wrap justify-content-center mt-3">
                  {axisProPermission("store-employee") && (
                    <Button
                      className="me-1"
                      size="sm"
                      as={Link}
                      to="/employee/add"
                    >
                      Add Employee
                    </Button>
                  )}
                </div>
              )}
            </LoadingScreenCover>
          )}
        </>
      </Card>
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

export default EmployeeTableView;
