import AppDatePicker from "components/app-date-picker/AppDatePicker";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import SelectCompany from "components/form/SelectCompany";
import SelectDepartment from "components/form/SelectDepartment";
import SelectDesignation from "components/form/SelectDesignation";
import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../EmployeeForm/employeeFormStyle.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import ErrorAlert from "module/common/ErrorAlert";
import SelectRole from "components/form/SelectRole";
import SelectTimeZone from "components/form/SelectTimeZone";
function EmployeeForm({
  section,
  formData,
  setFormData,
  formError,
  setAcFormError,
  acFormError,
  setGeFormError,
  geFormError,
  setOgFormError,
  ogFormError,
  setPreviewURL,
}) {
  const { itemId } = useParams();
  const [profileProgress, setProfileProgress] = useState(0);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let fieldName;
    let fieldValue;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else if (e.target.name === "profile_pic") {
        value = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreviewURL(reader.result);
        };
        reader.readAsDataURL(value);
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      fieldName = action.name.replace("_id", "_name");
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        fieldValue = e.label ? e.label : "";
      }
      if (action.action === "clear") {
        fieldValue = null;
      }
      newFormData = {
        ...formData,
        [fieldName]: fieldValue,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  useEffect(() => {
    let giProgress = 0;
    //progress for General Information
    formData.first_name && (giProgress += parseFloat(3.333333333));
    formData.middle_name && (giProgress += parseFloat(3.333333333));
    formData.last_name && (giProgress += parseFloat(3.333333333));
    formData.dob && (giProgress += parseFloat(3.333333333));
    formData.address && (giProgress += parseFloat(3.333333333));
    formData.phone && (giProgress += parseFloat(3.333333333));
    formData.office_number && (giProgress += parseFloat(3.333333333));
    formData.email && (giProgress += parseFloat(3.333333333));
    formData.fax && (giProgress += parseFloat(3.333333333));
    formData.gender && (giProgress += parseFloat(3.333333333));
    //progress for Organizational Information
    formData.designation_id && (giProgress += parseFloat(8.3325));
    formData.department_id && (giProgress += parseFloat(8.3325));
    formData.company_id && (giProgress += parseFloat(8.3325));
    formData.working_hrs && (giProgress += parseFloat(8.3325));
    //progress for Organizational Information
    formData.username && (giProgress += parseFloat(11.12));
    formData.password && (giProgress += parseFloat(11.11));
    formData.c_password && (giProgress += parseFloat(11.11));
    if (itemId) {
      giProgress += parseFloat(22.22);
    }
    setProfileProgress(giProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  return (
    <Form>
      <ProgressBar
        striped
        variant={
          profileProgress < 35
            ? "danger"
            : profileProgress < 100
            ? "warning"
            : "success"
        }
        now={profileProgress}
        key={1}
        className="mt-4 mb-3"
        animated
      />
      <Card.Body className="form-wrapper">
        <Row>
          {section === "General" ? (
            <>
              {Object.keys(geFormError).length > 0 ? (
                <ErrorAlert
                  setFormError={setGeFormError}
                  formError={geFormError}
                />
              ) : (
                ""
              )}
              <Row className="p-0">
                <span className=" text-uppercase"> Personal Information</span>
                <Col xl={6} md={6} sm={12} className="mt-3">
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">
                          First Name
                        </Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              type="text"
                              name="first_name"
                              onChange={handleFieldChange}
                              value={formData.first_name}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.first_name ? (
                              <FormErrorPopover
                                id="first_name"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.first_name}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">
                          Last Name
                        </Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              type="text"
                              name="last_name"
                              onChange={handleFieldChange}
                              value={formData.last_name}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.last_name ? (
                              <FormErrorPopover
                                id="last_name"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.last_name}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3 ">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>Address</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              name="address"
                              onChange={handleFieldChange}
                              value={formData.address}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.address ? (
                              <FormErrorPopover
                                id="address"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.address}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} sm={12} className="mt-3">
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>Middle Name</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              type="text"
                              name="middle_name"
                              onChange={handleFieldChange}
                              value={formData.middle_name}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.middle_name ? (
                              <FormErrorPopover
                                id="middle_name"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.middle_name}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">
                          Date of Birth
                        </Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <AppDatePicker
                              name="dob"
                              yearPlaceholder="yyyy"
                              monthPlaceholder="mm"
                              dayPlaceholder="dd"
                              onChange={handleFieldChange}
                              value={formData.dob}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.dob ? (
                              <FormErrorPopover
                                id="dob"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.dob}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>Gender</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Select
                              size="lg"
                              name="gender"
                              onChange={handleFieldChange}
                              value={formData.gender}
                            >
                              <option value="">Select</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </Form.Select>
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.middle_name ? (
                              <FormErrorPopover
                                id="middle_name"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.middle_name}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="p-0">
                <Col xl={6} md={6} sm={12} className="mt-3">
                  <span className=" text-uppercase">Contact Information</span>
                  <Form.Group as={Col} xl={12} md={12} sm={12} className="mt-3">
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">Phone</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <PhoneNumberInput
                              name="phone"
                              onChange={handleFieldChange}
                              value={formData.phone}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.phone ? (
                              <FormErrorPopover
                                id="phone"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.phone}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3 ">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">
                          Official
                        </Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <PhoneNumberInput
                              name="office_number"
                              onChange={handleFieldChange}
                              value={formData.office_number}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.office_number ? (
                              <FormErrorPopover
                                id="office_number"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.office_number}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col xl={6} md={6} sm={12}>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3 mt-5">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">Email</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              type="email"
                              name="email"
                              onChange={handleFieldChange}
                              value={formData.email}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.email ? (
                              <FormErrorPopover
                                id="email"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.email}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label>Fax</Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xxl={11} xl={10} md={10} sm={11}>
                            <Form.Control
                              type="text"
                              name="fax"
                              onChange={handleFieldChange}
                              value={formData.fax}
                            />
                          </Col>
                          <Col
                            xxl={1}
                            xl={2}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.fax ? (
                              <FormErrorPopover
                                id="fax"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.fax}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
            </>
          ) : section === "Organizational" ? (
            <>
              {Object.keys(ogFormError).length > 0 ? (
                <ErrorAlert
                  setFormError={setOgFormError}
                  formError={ogFormError}
                />
              ) : (
                ""
              )}
              <Col xl={6} md={6} sm={12}>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3 ">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Company</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <SelectCompany
                            name="company_id"
                            value={formData.company_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.company_id ? (
                            <FormErrorPopover
                              id="company_id"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.company_id}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">
                        Designation
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <SelectDesignation
                            name="designation_id"
                            value={formData.designation_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.designation_id ? (
                            <FormErrorPopover
                              id="designation_id"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.designation_id}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xl={6} md={6} sm={12}>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3 ">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">
                        Department
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <SelectDepartment
                            name="department_id"
                            value={formData.department_id_ref}
                            handleFieldChange={handleFieldChange}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.department_id ? (
                            <FormErrorPopover
                              id="department_id"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.department_id}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">
                        Working Hours
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <Form.Control
                            type="number"
                            name="working_hrs"
                            onChange={handleFieldChange}
                            value={formData.working_hrs}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.working_hrs ? (
                            <FormErrorPopover
                              id="working_hrs"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.working_hrs}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
            </>
          ) : section === "Account" ? (
            <>
              {Object.keys(acFormError).length > 0 ? (
                <ErrorAlert
                  setFormError={setAcFormError}
                  formError={acFormError}
                />
              ) : (
                ""
              )}
              <Col xl={6} md={6} sm={12}>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3 ">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Username</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <Form.Control
                            type="text"
                            name="username"
                            onChange={handleFieldChange}
                            value={formData.username}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.username ? (
                            <FormErrorPopover
                              id="username"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.username}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">
                        Confirm Password
                      </Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <Form.Control
                            type="password"
                            name="c_password"
                            onChange={handleFieldChange}
                            value={formData.c_password}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.c_password ? (
                            <FormErrorPopover
                              id="c_password"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.c_password}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Timezone</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <SelectTimeZone
                            name="time_zone"
                            handleFieldChange={handleFieldChange}
                            value={formData.time_zone_ref}
                            error={formError.time_zone}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.time_zone ? (
                            <FormErrorPopover
                              id="time_zone"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.time_zone}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Status</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={12} md={12} sm={12}>
                          <Form.Check
                            type="switch"
                            name="status"
                            id="custom-switch"
                            onChange={handleFieldChange}
                            checked={
                              formData.status === "Inactive" ? false : true
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
              </Col>
              <Col xl={6} md={6} sm={12}>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Password</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <Form.Control
                            type="password"
                            name="password"
                            onChange={handleFieldChange}
                            value={formData.password}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.password ? (
                            <FormErrorPopover
                              id="password"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.password}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group as={Col} xl={12} md={12} sm={12}>
                  <Row className="mb-3">
                    <Col xl={4} md={4} sm={12}>
                      <Form.Label className="require-data">Role</Form.Label>
                    </Col>
                    <Col xl={8} md={8} sm={12}>
                      <Row>
                        <Col xl={11} md={10} sm={11}>
                          <SelectRole
                            name="role_id"
                            handleFieldChange={handleFieldChange}
                            value={formData.role_id_ref}
                            error={formError.role_id}
                          />
                        </Col>
                        <Col
                          xl={1}
                          md={2}
                          sm={1}
                          className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                        >
                          {formError.role_id ? (
                            <FormErrorPopover
                              id="role_id"
                              totalErrorCount={Object.keys(formError).length}
                              errorMessage={formError.role_id}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form.Group>
                {!itemId && (
                  <Form.Group as={Col} xl={12} md={12} sm={12}>
                    <Row className="mb-3">
                      <Col xl={4} md={4} sm={12}>
                        <Form.Label className="require-data">
                          Profile Image
                        </Form.Label>
                      </Col>
                      <Col xl={8} md={8} sm={12}>
                        <Row>
                          <Col xl={11} md={10} sm={11}>
                            <Form.Control
                              type="file"
                              name="profile_pic"
                              onChange={handleFieldChange}
                            />
                            {formData?.profile_pic?.name && (
                              <Form.Label className="mt-1">
                                Selected File:
                                {formData?.profile_pic?.name}
                              </Form.Label>
                            )}
                          </Col>
                          <Col
                            xl={1}
                            md={2}
                            sm={1}
                            className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
                          >
                            {formError.profile_pic ? (
                              <FormErrorPopover
                                id="profile_pic"
                                totalErrorCount={Object.keys(formError).length}
                                errorMessage={formError.profile_pic}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form.Group>
                )}
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      </Card.Body>
    </Form>
  );
}

export default EmployeeForm;
