import { React, useState } from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';

function SelectTermsAndCondition({
  handleFieldChange,
  name,
  error,
  value,
  placeholder
}) {
  const [defaultOptions, setDefaultOptions] = useState([]);

  const setItems = async name => {
    let response = await axios.get(
      'settings/terms-and-conditions-autocomplete',
      {
        params: { name: name }
      }
    );
    let data =
      response && response.data && response.data.data && response.data.data
        ? response.data.data
        : [];
    return data.map(item => ({
      label: item.name,
      value: item.id
    }));
  };

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems('');
      setDefaultOptions(defaultOptionsData);
    }
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={name ? setItems : setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isClearable
        isSelected={true}
        isOptionSelected={item => item.value === value}
        name={name ? name : 'terms_and_conditions'}
        value={value}
        placeholder={placeholder ? placeholder : ''}
        onChange={handleFieldChange}
        className={error && 'form-control ps-0 py-0 is-invalid'}
        styles={{
          menuPortal: provided => ({ ...provided, zIndex: 9999 }),
          menu: provided => ({
            ...provided,
            zIndex: 9999,
            fontFamily: 'sans-serif',
            fontSize: '12px',
            borderRadius: '0px',
            textTransform: 'capitalize',
            overflow: 'hidden'
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: '0px',
            border: '1px solid #b5b5b5',
            height: '10px'
          })
        }}
        menuPortalTarget={document.body}
      ></AsyncSelect>
    </>
  );
}

SelectTermsAndCondition.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string
};

export default SelectTermsAndCondition;
