import React from "react";
import { FaUser } from "react-icons/fa";
import { SiSourcetree } from "react-icons/si";
import { FaBars } from "react-icons/fa";
import TileLink, { TileLinkCover } from "components/tile-link/TileLink";
import useAxisproPermission from "hooks/useAxisproPermission";

const Leads = () => {
  const axisProPermission = useAxisproPermission();
  let leadTilesArray = [];

  axisProPermission("list-lead-status") &&
    leadTilesArray.push({
      title: "Lead Status",
      to: "/leads/lead-status",
      Icon: FaBars,
    });

  axisProPermission("list-lead-source") &&
    leadTilesArray.push({
      title: "Lead Sources",
      to: "/leads/lead-source",
      Icon: SiSourcetree,
    });

  return (
    <>
      {leadTilesArray.length > 0 && (
        <>
          <div className="mb-0 mt-2">
            <h4 className="fs-2">Leads</h4>
          </div>
          <TileLinkCover>
            {leadTilesArray.map(({ title, to, Icon }, index) => (
              <TileLink key={index} title={title} to={to} Icon={Icon} />
            ))}
          </TileLinkCover>
        </>
      )}

      {axisProPermission("list-lead") && (
        <>
          <div className="mb-1 mt-3">
            <h4 className="fs-2">Manage Lead</h4>
          </div>
          <TileLinkCover>
            {[
              {
                title: "Lead",
                to: "/leads/lead",
                Icon: FaUser,
              },
            ].map(({ title, to, Icon }, index) => (
              <TileLink key={index} title={title} to={to} Icon={Icon} />
            ))}
          </TileLinkCover>{" "}
        </>
      )}
    </>
  );
};

export default Leads;
