import { React, useState } from "react";
import { Card } from "react-bootstrap";

import { BsPlus } from "react-icons/bs";
import { RiDeleteBinLine, RiFileExcel2Fill } from "react-icons/ri";
import { FaFilePdf } from "react-icons/fa";
import { FcDocument } from "react-icons/fc";
import { AiOutlineDownload } from "react-icons/ai";
import { FiMoreVertical } from "react-icons/fi";

import "./Attachments.scss";
import FileUpload from "./FileUpload";
import DeleteWarning from "../Warnings/DeleteWarning";

function AttachmentsTab({ id, fetchData, type, media }) {
  const [upload, setUpload] = useState(false);
  const [deleteModal, setDeleteMoadl] = useState(false);
  const [attachmentId, setAttachmentId] = useState();

  const createFile = (url, file_name) => {
    if (url) {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.error("download file url is missing");
    }
  };

  const handleDelete = (id) => {
    setAttachmentId(id);
    setDeleteMoadl(true);
  };

  return (
    <>
      <Card className="attachmnets-container">
        {media?.length > 0
          ? media.map((item, key) => (
              <Card
                style={{ height: "12rem", width: "12rem" }}
                key={key}
                className="attachments-card"
              >
                <Card.Body className="d-flex flex-column align-items-center justify-content-center">
                  {item.mime_type.split(".").pop() === "sheet" ? (
                    <RiFileExcel2Fill size={60} className="text-success" />
                  ) : item.mime_type.split(".").pop() === "application/pdf" ? (
                    <FaFilePdf size={60} className="text-danger" />
                  ) : item.mime_type === "application/msword" ? (
                    <FcDocument size={60} className="text-primary" />
                  ) : item.mime_type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    item?.mime_type === "application/octet-stream" ? (
                    <FcDocument size={60} className="text-primary" />
                  ) : (
                    <img
                      src={item.file_url}
                      alt="attachement"
                      width={133}
                      height={133}
                      className="png-images"
                    />
                  )}
                  {item.mime_type.split(".").pop() === "sheet" ? (
                    <p className="m-0 fs--2 text-dark fw-bold">EXCEL</p>
                  ) : item.mime_type.split(".").pop() === "application/pdf" ? (
                    <p className="m-0 fs--2 text-dark fw-bold">PDF</p>
                  ) : item.mime_type === "application/msword" ? (
                    <p className="m-0 fs--2 text-dark fw-bold">DOC</p>
                  ) : item.mime_type ===
                      "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                    item?.mime_type === "application/octet-stream" ? (
                    <p className="m-0 fs--2 text-dark fw-bold">DOCX</p>
                  ) : (
                    ""
                  )}
                  {item.mime_type.split(".").pop() === "sheet" ||
                  item.mime_type.split(".").pop() === "application/pdf" ||
                  item.mime_type === "application/msword" ||
                  item.mime_type ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                  item?.mime_type === "application/octet-stream" ? (
                    <p className="m-0 fs--2">
                      {item.file_name.substring(0, 12) + "..."}
                    </p>
                  ) : (
                    ""
                  )}
                </Card.Body>
                <div
                  className="attachments-hover-styles"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <AiOutlineDownload
                      className="text-white download-icon"
                      size={35}
                      title="Download"
                      onClick={() => createFile(item.file_url, item.file_name)}
                    />

                    <FiMoreVertical className="text-white" size={40} />
                    <RiDeleteBinLine
                      className="text-white delete-icon"
                      size={35}
                      title="Delete"
                      onClick={() => handleDelete(item.id)}
                    />
                  </div>
                  <p className="fs--1 text-white m-0">
                    {item.human_readable_size}
                  </p>
                  <p className="fs--1 text-white m-0">
                    {item.mime_type.split(".").pop() === "image/png"
                      ? "PNG"
                      : ""}
                  </p>
                </div>
              </Card>
            ))
          : null}
        <div
          className="attachments-adding-container"
          onClick={() => setUpload(true)}
        >
          <BsPlus size={60} className="text-secondary adding-icon" />
          <p>click here to add attachments</p>
        </div>
      </Card>
      <FileUpload
        show={upload}
        onHide={() => setUpload(false)}
        fetchData={fetchData}
        type={type}
        id={id}
      />
      <DeleteWarning
        show={deleteModal}
        id={attachmentId}
        onHide={() => setDeleteMoadl(false)}
        fetchData={fetchData}
        data={media}
        setKey={(e) => console.log(e)}
        heading={"Delete attachment"}
        message={"Are you sure? You want to delete this Attachment!"}
        type={"ATTACHMENT"}
        url="documents"
      />
    </>
  );
}

export default AttachmentsTab;
