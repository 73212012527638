import FilterButton from "components/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import React, { Fragment, useEffect, useState } from "react";
import { ListGroup, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { useParams, useSearchParams } from "react-router-dom";
import qs from "qs";
import AppPagination from "components/common/app-pagination/AppPagination";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import ListLoading from "module/common/ListLoading";
import { taskFilters } from "module/common/helpers/taskCommonFilters";

const TasksInfo = ({ userId, userName, type, filterVisible = true }) => {
  const [activeContent, setactiveContent] = useState("");
  const [filters] = useState(taskFilters);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState({});
  const { empId } = useParams();
  const { client_id } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const tab = queryParams.get("tab");

  const handleText = (id) => {
    if (activeContent === id) {
      setactiveContent("");
    } else {
      setactiveContent(id);
    }
  };

  useEffect(() => {
    tab === "tasks" && getTasksData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pageNumber, filterItems, tab]);

  const getTasksData = async (page = 1) => {
    setLoading(true);

    const params = {
      page: page,
      filters: filterItems
        ? removeRefData(createFilterArray(filterItems), "array")
        : "",
    };

    if (type === "client") {
      params.requested_from = "account";
      params.account_id = userId;
    } else if (type === "employee") {
      params.requested_from = "employee";
      params.employee_id = userId;
    }

    const tasksData = await apiCall({
      url: `crm/task`,
      params: params,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setTasks(tasksData);
    setLoading(false);
  };

  return (
    <div>
      <ListGroup className="mb-2">
        <ListGroup.Item className="rounded-0 pb-0 pt-0 d-flex justify-content-end">
          {filterVisible && (
            <FilterButton
              className="mb-2 mt-2 pt-2 pb-2 ms-1"
              variant="success"
              filters={filters}
              url={
                empId
                  ? `employee/overview/${empId}`
                  : `account/overview/${client_id}`
              }
              additionalParam={{ tab: tab }}
            />
          )}
        </ListGroup.Item>
      </ListGroup>
      <div
        className="table-wraper"
        style={{ maxHeight: "69.5vh", minHeight: "69.5vh", overflow: "auto" }}
      >
        <Table bordered size="sm">
          {Object.keys(tasks).length > 0 &&
          !loading &&
          Object.keys(tasks.data).length > 0 ? (
            tasks.data.map((item, key) => (
              <Fragment key={key}>
                <thead style={{ borderBottomColor: "white" }}>
                  <tr>
                    <th className="pb-0">
                      <span className="custom-text-muted-crm"></span>
                    </th>
                    <th className="pb-0 ps-2" style={{ width: "38%" }}>
                      <span className="custom-text-muted-crm">Title</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Project</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Label</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Start Date</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">End Date</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Status</span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderBottomColor: "white" }}>
                  <tr>
                    <td className="pb-0">{key + 1}</td>
                    <td className="pb-0 ps-2">
                      <span className="fw-bold">{item.title}</span>
                    </td>
                    <td className="pb-0 ps-2">{item.project_title}</td>
                    <td className="pb-0 ps-2">
                      <span className="d-flex gap-1 flex-wrap">
                        {item.labels.length > 0
                          ? item.labels.map((label, keyLabel) => (
                              <OverlayTrigger
                                key={keyLabel}
                                placement="bottom"
                                overlay={
                                  <Tooltip id="button-tooltip-2">
                                    {label.description}
                                  </Tooltip>
                                }
                              >
                                <span
                                  key={keyLabel}
                                  className="badge p-2"
                                  style={{
                                    backgroundColor: label.colour,
                                  }}
                                >
                                  {label.label}
                                </span>
                              </OverlayTrigger>
                            ))
                          : "N/A"}
                      </span>
                    </td>
                    <td className="pb-0 ps-2">{item.start_date_formatted}</td>
                    <td className="pb-0 ps-2">{item.end_date_formatted}</td>
                    <td className="pb-0 ps-2">
                      <span className="text-capitalize inline">
                        {item.status.replace("_", " ")}
                      </span>
                      <span className="inline">
                        {item.status === "to_do" ? (
                          <FaCircle
                            color={"#5890e7"}
                            size={10}
                            className="ms-2"
                          />
                        ) : item.status === "in_review" ? (
                          <FaCircle
                            color={"#e7a01b"}
                            size={10}
                            className="ms-2"
                          />
                        ) : item.status === "done" ? (
                          <FaCircle
                            color={"#84cb66"}
                            size={10}
                            className="ms-2"
                          />
                        ) : (
                          <FaCircle
                            color={"rgb(62 211 227)"}
                            size={10}
                            className="ms-2"
                          />
                        )}
                      </span>
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0">
                      <div
                        className="text-dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleText(item.id)}
                      >
                        <p className="text-wrap fs--2">
                          {activeContent === item?.id
                            ? item?.description
                              ? item?.description?.replace(/<(.|\n)*?>/g, "")
                              : ""
                            : item?.description
                            ? `${item?.description
                                ?.replace(/<(.|\n)*?>/g, "")
                                .substring(0, 75)}`
                            : ""}
                          {activeContent === item?.id &&
                            item?.description?.length >= 75 &&
                            " ...read less"}
                          {activeContent !== item?.id &&
                            item?.description?.length >= 75 &&
                            " ...read more"}
                        </p>
                      </div>
                    </td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                  </tr>
                </tbody>
              </Fragment>
            ))
          ) : !loading ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                    <BsDatabaseFillExclamation
                      size={40}
                      className="text-mute"
                    />
                    <span>No data found!</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody style={{ borderBottomColor: "white" }}>
              <tr>
                <td colSpan={7}>
                  <ListLoading
                    style={{ maxHeight: "65vh", overflow: "hidden" }}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="pt-2">
        <div className="d-flex justify-content-center">
          {tasks && tasks.links && (
            <AppPagination
              data={tasks}
              url={
                empId
                  ? `/employee/overview/${empId}`
                  : `/client/overview/${client_id}`
              }
              search={allQueryParams}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TasksInfo;
