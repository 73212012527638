import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";

import { AiOutlineMenu } from "react-icons/ai";

import { apiCall } from "helpers/apiCalls";
import { employeeTaskReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import EmployeeTaskReportFilterSection from "../ReportFilterSection/EmployeeTaskReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";

const EmployeeTaskReport = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const [employeeReport, setEmployeeReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(
    employeeTaskReportFilterKeys
  );

  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];

  const keysToCheck = [
    "date_range_for",
    "project_id",
    "customer_id",
    "user_id",
    "status",
  ];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const handleCanvas = () => setShow(!show);

  const getProjectReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    if (!parsedFilterItems.date_range_for) {
      parsedFilterItems.from_date = "";
      parsedFilterItems.to_date = "";
    }
    let additionalRemoveField = "date_range";
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems, additionalRemoveField)
    );

    try {
      const data = await apiCall({
        url: `crm/employee-task-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setEmployeeReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}

            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/employee-task-report"
                filters={filterFields}
                setFilters={setFilterFields}
                columns={columns}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/employee-task-report/export"}
                  successMessage={"Task Report Export Completed"}
                  initialMessage={"Task Report Export Started"}
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <EmployeeTaskReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
              filterValuesExist={filterValuesExist}
              columns={columns}
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            <span className="fs--1 align-self-center">{company_name}</span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Task Report
            </Link>
          </div>
          <div className="mt-4">
            {!dataProcessing ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#00185894",
                      color: "whitesmoke",
                    }}
                  >
                    <th className="fw-bold text-light pt-2 pb-2">
                      EMPLOYEE NAME
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">TASK</th>
                    <th className="fw-bold text-light pt-2 pb-2">PROJECT</th>
                    <th className="fw-bold text-light pt-2 pb-2">ACCOUNT</th>
                    <th className="fw-bold text-light pt-2 pb-2">START DATE</th>
                    <th className="fw-bold text-light pt-2 pb-2">END DATE</th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      WORKED TIME
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(employeeReport).length > 0 &&
                  employeeReport.data.length > 0 ? (
                    employeeReport.data.map((item, key) =>
                      item.length > 0 ? (
                        item.map((subItem, subKey) => (
                          <tr key={subKey}>
                            <td className="text-capitalize">
                              {subItem.employee_name}
                            </td>
                            <td className="text-capitalize">
                              {subItem.task_title}
                            </td>
                            <td>{subItem.project_title}</td>
                            <td>{subItem.customer_name}</td>
                            <td>{subItem.start_date}</td>
                            <td>{subItem.end_date}</td>
                            <td>{subItem.worked_time}</td>
                            <td className="text-capitalize">
                              {subItem.status?.replace("_", " ")}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr height="100px">
                          <td colSpan={8} className="text-center">
                            <span>No Data Found</span>
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr height="100px">
                      <td colSpan={8} className="text-center">
                        <span>No Data Found</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <div
                className="d-flex justify-content-center align-item-center"
                style={{ minHeight: "48vh" }}
              >
                <LoadingScreen message="Loading Employees Task Report" />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {employeeReport && employeeReport.links && !dataProcessing && (
            <ReportPagination
              data={employeeReport}
              url="/reports/employee-task-report"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default EmployeeTaskReport;
