import { Card, Tab, Tabs } from "react-bootstrap";
import ClentOverViewSection from "./ClentOverViewSection";
import ProjectsInfo from "module/Employee/EmployeeOverview/TabComponents/ProjectsInfo";
import { useNavigate, useSearchParams } from "react-router-dom";
import TasksInfo from "module/Employee/EmployeeOverview/TabComponents/TasksInfo";
import TicketInfo from "module/Employee/EmployeeOverview/TabComponents/TicketInfo";
import ListLoading from "module/common/ListLoading";
import useAxisproPermission from "hooks/useAxisproPermission";

const ClientTabSection = ({ tabData, isLoading }) => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const tab = queryParams.get("tab");
  const axisProPermission = useAxisproPermission();
  const [listTaskPermission, listProjectPermission, listTicketPermission] = [
    "list-task",
    "list-project",
    "list-ticket",
  ].map((permission) => axisProPermission(permission));
  const handleTabChange = (tab) => {
    navigate(`?tab=${tab}`);
  };
  return (
    <Card className="h-100 flex-fill">
      {!isLoading ? (
        <Card.Body className="m-0 p-0" style={{ backgroundColor: "#F9F9F9" }}>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            className="h-100"
          >
            <Tabs
              style={{ backgroundColor: "#FFFFFF" }}
              defaultActiveKey="overview"
              id="custom-empl-header-tabs"
              className="custom-empl-header-tabs p-2"
              variant="pills"
              onSelect={handleTabChange}
              activeKey={tab}
              justify
            >
              <Tab
                eventKey="overview"
                title={<span className="fw-bold">Overview</span>}
                className="custom-empl-header-tab h-100"
              >
                <div
                  style={{
                    maxHeight: "83vh",
                    height: "83vh",
                    overflowY: "scroll",
                  }}
                >
                  <ClentOverViewSection overviewData={tabData} />
                </div>
              </Tab>
              {listProjectPermission && (
                <Tab
                  eventKey="projects"
                  title={<span className="fw-bold">Projects</span>}
                  className="custom-empl-header-tab"
                >
                  <div className="bg-white mt-1">
                    <ProjectsInfo userId={tabData.account.id} type="client" />
                  </div>
                </Tab>
              )}
              {listTaskPermission && (
                <Tab
                  eventKey="tasks"
                  title={<span className="fw-bold">Tasks</span>}
                  className="custom-empl-header-tab"
                >
                  <div className="bg-white mt-1">
                    <TasksInfo type="client" userId={tabData.account.id} />
                  </div>
                </Tab>
              )}
              {listTicketPermission && (
                <Tab
                  eventKey="tickets"
                  title={<span className="fw-bold">Tickets</span>}
                  className="custom-empl-header-tab"
                >
                  <div className="bg-white mt-1">
                    <TicketInfo
                      userId={tabData.account.id}
                      type="client"
                      filterVisible={false}
                    />
                  </div>
                </Tab>
              )}
            </Tabs>
          </Tab.Container>
        </Card.Body>
      ) : (
        <ListLoading />
      )}
    </Card>
  );
};
export default ClientTabSection;
