import { apiCall } from "helpers/apiCalls";
import React, { useEffect, useState } from "react";
import { Button, Image, ListGroup } from "react-bootstrap";
import { MdAdd } from "react-icons/md";
import { TbRefresh } from "react-icons/tb";
import { useParams, useSearchParams } from "react-router-dom";
import profileImage from "../../../../assets/img/Avathar/ProfileImage.jpg";
import AppPagination from "components/common/app-pagination/AppPagination";
import ListLoading from "module/common/ListLoading";
import { Link } from "react-router-dom";
import useAxisproPermission from "hooks/useAxisproPermission";

const QuotationsList = () => {
  const [loading, setLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const { itemId, quoteId } = useParams();
  const [quotationList, setQuotationList] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const createPermission = axisProPermission("store-opportunity-quotation");

  const getQuotations = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "sales/sales-quote",
      params: {
        is_from_crm: 1,
        opportunity_id: itemId,
      },
    });
    setQuotationList(data);
    setLoading(false);
  };

  useEffect(() => {
    getQuotations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ListGroup className="m-0">
      <ListGroup.Item className="border pt-3 pb-3 rounded-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="fw-semibold">QUOTATIONS LIST</div>
          <div className="d-flex gap-2">
            {createPermission && (
              <Button
                variant="light"
                className="border"
                size="sm"
                as={Link}
                to={`/opportunity/overview/${itemId}/quotation/${quoteId}?mode=add`}
              >
                <MdAdd size={18} />
              </Button>
            )}

            <Button
              variant="light"
              className="border"
              size="sm"
              onClick={() => getQuotations()}
            >
              <TbRefresh size={18} />
            </Button>
          </div>
        </div>
      </ListGroup.Item>
      {!loading ? (
        <>
          <div
            style={{ height: "72vh", overflow: "auto" }}
            className="bg-white border-end border-start"
          >
            {quotationList?.data?.map((item, index) => (
              <ListGroup.Item
                key={index}
                className="border-0 border-bottom p-3 rounded-0 custom-link-style-crm"
                as={Link}
                to={`/opportunity/overview/${itemId}/quotation/${item.id}`}
              >
                <div className="d-flex gap-2 align-items-center">
                  <Image
                    src={profileImage}
                    alt="image"
                    height={30}
                    width={30}
                    roundedCircle
                  />
                  <div className="d-flex flex-column">
                    <span className="text-capitalize">
                      {item?.customer_name}
                    </span>
                    <span>{item?.trans_date_formatted}</span>
                  </div>
                  <div className="d-flex flex-column ms-auto">
                    <span>{item?.reference}</span>
                    <span>{item?.amount_formatted}</span>
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </div>
          <ListGroup.Item className="border rounded-0">
            {quotationList && quotationList?.links && (
              <AppPagination
                data={quotationList}
                url={`opportunity/overview/${itemId}/quotation/${quoteId}`}
                search={allQueryParams}
              />
            )}
          </ListGroup.Item>
        </>
      ) : (
        <div className="border border-top-0">
          <ListLoading style={{ maxHeight: "81vh", overflow: "hidden" }} />
        </div>
      )}
    </ListGroup>
  );
};

export default QuotationsList;
