import React from "react";
import { Form } from "react-bootstrap";

const ColumnSelector = ({
  title = "Column",
  columns,
  filters,
  setFilters,
  defaultOption = true,
  value,
  labelClassName = "",
}) => {
  return (
    <div>
      <Form.Group controlId="formBasicEmail">
        <Form.Label className={labelClassName}>{title}</Form.Label>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => {
            setFilters({ ...filters, date_range_for: e.target.value });
          }}
          value={value}
        >
          {defaultOption && <option value="">{`Select`}</option>}
          {columns?.map((column, key) => (
            <option key={key} value={column.key}>
              {column.value}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default ColumnSelector;
