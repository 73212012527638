import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  Image,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import TableHeader from "helpers/tableHeader";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import FilterButton from "components/filter/FilterButton";
import AddButton from "components/add-button/AddButton";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import { BsCalculator } from "react-icons/bs";
import { estimationFilters } from "module/common/helpers/estimationCommonFilters";
import { FaCircle, FaEye } from "react-icons/fa";
import CustomerDetailsModal from "../EstimationDetails/CustomerDetailsModal";
import ProfileImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import ExportButton from "components/export-button/ExportButton";

const EstimationTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [customerDetailShow, setcustomerDetailShow] = useState(false);
  const [estimations, setEstimations] = useState({});
  const [customerDetails, setcustomerDetails] = useState([]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [filters] = useState(estimationFilters);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission, showPermission] =
    [
      "store-estimation",
      "update-estimation",
      "delete-estimation",
      "show-estimation",
    ].map((permission) => axisProPermission(permission));
  const actionsPermission =
    updatePermission || deletePermission || showPermission;
  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/estimation/" + data,
        title: "Delete Estimation item",
        message: "Are you sure you want to delete this estimation?",
        onSuccess: () => {
          const dataWithoutDeletedItem = estimations.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/estimation?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setEstimations((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const handleShow = (data) => {
    setcustomerDetailShow(true);
    setcustomerDetails(data);
  };
  const handleClose = () => setcustomerDetailShow(false);
  const columns = [
    {
      accessor: "date",
      Header: "DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { date_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{date_formatted}</h5>;
      },
    },
    {
      accessor: "valid_until",
      Header: "VALID UNTIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { valid_until_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{valid_until_formatted}</h5>;
      },
    },
    {
      accessor: "notes",
      Header: "NOTES",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text w-25",
      },
      Cell: (rowData) => {
        const { notes } = rowData.row.original;
        return <h5 className="mb-0 fs--1 text-wrap">{notes}</h5>;
      },
    },
    {
      accessor: "customer",
      Header: "CLIENT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { customer, status } = rowData.row.original;
        return (
          <>
            <div className="d-flex align-items-center">
              <OverlayTrigger
                placement="right"
                overlay={
                  <Popover id="popover-basic">
                    <Popover.Header className="fs--1">
                      <span>
                        <strong className="text-capitalize">
                          {customer?.name}
                        </strong>
                        <FaCircle
                          color={
                            status === "open"
                              ? "#84cb66"
                              : status === "pending"
                              ? "#03f4fc"
                              : "#f55b5b"
                          }
                          size={10}
                          className="ms-2"
                        />
                      </span>
                    </Popover.Header>
                    <Popover.Body style={{ paddingTop: "8px" }}>
                      <span className="fs--2">Email : {customer?.email}</span>
                      <br />
                      <span className="fs--2">phone : {customer?.phone}</span>
                      <div className="mt-3 fs--2">
                        <span>Address : {customer?.address}</span>
                      </div>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Image
                  className="custom-image-crm"
                  src={ProfileImage}
                  roundedCircle
                  alt="?"
                  height={30}
                  width={30}
                />
              </OverlayTrigger>
              <Link
                className="m-2 custom-link-style-crm text-muted"
                to={`/client/overview/${customer?.id}?tab=overview`}
              >
                {customer?.name}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <Badge
            bg={
              status === "open"
                ? "success"
                : status === "pending"
                ? "info"
                : "danger"
            }
          >
            {status}
          </Badge>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        const { original } = rowData.row;
        return (
          <div className="d-flex gap-2">
            {showPermission && (
              <Button
                size="sm"
                variant="transparent"
                className={`custom-button-action p-0`}
                onClick={() => handleShow(original)}
                title="View"
              >
                <FaEye
                  color="#7062c9"
                  size={20}
                  className="custom-button-view-icon"
                />
              </Button>
            )}
            {updatePermission && (
              <EditButton
                route={`/estimation/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/estimation",
      params: {
        page: page,
        filters: filterItems
          ? removeRefData(createFilterArray(filterItems), "array")
          : null,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }).catch((error) => {
      return error;
    });

    setEstimations(data);
    setIsLoading(false);
  };
  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <>
      <Outlet context={[getData]} />
      {!isLoading && estimations?.data?.length > 0 ? (
        <AdvanceTableWrapper columns={columns} data={estimations.data} sortable>
          <Card className="flex-fill mb-3 mt-3 estimation-card">
            <Card.Header className="d-flex flex-row justify-content-between align-items-center border-bottom gap-1">
              <BsCalculator
                size={22}
                className="me-2 text-dark"
                style={{ marginTop: 2 }}
              />
              <TableHeader
                title={
                  <span className="custom-module-header">Estimation List</span>
                }
                table
              />
              <ExportButton
                className="me-1"
                url="crm/estimation-export"
                initialMessage={"Estimation export started"}
                successMessage={"Estimation export completed"}
                file_type={"pdf"}
                variant={"danger"}
              />
              <FilterButton
                className={"pt-2 pb-2"}
                filters={filters}
                url="estimation"
              />
              {createPermission && (
                <AddButton
                  to={`/estimation/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              )}
            </Card.Header>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0 ">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {estimations && estimations.links && (
                <AppPagination
                  data={estimations}
                  url="/estimation"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <LoadingScreenCover className="mb-3 mt-3">
          {isLoading ? (
            <LoadingCommon loadingText="Looking for Estimations" />
          ) : (
            <h5 className="text-center text-secondary">
              Estimation not found! Let's add it &#128578;
            </h5>
          )}
          {!isLoading && (
            <div className="d-flex flex-wrap justify-content-center mt-3">
              {createPermission && (
                <Button
                  className="m-1"
                  variant="success"
                  size="sm"
                  as={Link}
                  to={`/estimation/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                >
                  Add Estimation
                </Button>
              )}

              {filterItems ? (
                <FilterButton
                  showText
                  filters={filters}
                  url="estimation"
                  className="m-1"
                  variant="secondary"
                />
              ) : null}
            </div>
          )}
        </LoadingScreenCover>
      )}
      <CustomerDetailsModal
        customerDetailShow={customerDetailShow}
        handleClose={handleClose}
        customerDetails={customerDetails}
      />
    </>
  );
};

export default EstimationTableView;
