import React, { useContext, useState } from "react";
import { Button, Card } from "react-bootstrap";
import "./Report.scss";
import { BsFillGridFill } from "react-icons/bs";
import { FaListUl } from "react-icons/fa";
import ReportGrid from "./ReportGrid";
import ReportList from "./ReportList";
import generateIconComponent from "helpers/generateIconComponent";
import { AuthWizardContext } from "context/Context";
import { Navigate } from "react-router-dom";

function Report() {
  const [activeView, setActiveView] = useState("grid");
  const { user } = useContext(AuthWizardContext);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  return (
    <>
      {reportPermissions ? (
        <Card className="mb-3 mt-3">
          <Card.Header className="d-flex flex-row justify-content-between border-bottom">
            <div className="flex-grow-1 d-flex align-items-center">
              {generateIconComponent(reportPermissions?.icon, 27)}
              <span className="fs-1 text-uppercase ms-1">
                {reportPermissions?.name}
              </span>
            </div>
            <div className="d-flex gap-2">
              <Button
                variant=""
                size="sm"
                className="custom-report-view-button ps-2 pe-2 rounded-1"
                style={{
                  backgroundColor: activeView === "grid" ? "#544780" : "white",
                  color: activeView === "grid" ? "whitesmoke" : "#5E6E82",
                }}
                onClick={() => {
                  setActiveView("grid");
                }}
              >
                <BsFillGridFill size={18} />
              </Button>
              <Button
                variant=""
                size="sm"
                className="custom-report-view-button ps-2 pe-2 rounded-1"
                style={{
                  backgroundColor: activeView === "list" ? "#544780" : "white",
                  color: activeView === "list" ? "whitesmoke" : "#5E6E82",
                }}
                onClick={() => {
                  setActiveView("list");
                }}
              >
                <FaListUl size={18} />
              </Button>
            </div>
          </Card.Header>
          <Card.Body
            style={{ maxHeight: "82vh", minHeight: "82vh", overflow: "scroll" }}
            className="pt-1 ps-0 pe-0"
          >
            {activeView === "list" ? <ReportList /> : <ReportGrid />}
          </Card.Body>
        </Card>
      ) : (
        <Navigate to="/errors/403" />
      )}
    </>
  );
}

export default Report;
