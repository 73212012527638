import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { leadSourceSummeryItemReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { FcClearFilters } from "react-icons/fc";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import SelectLeadPriority from "components/form/SelectLeadPriority";
import SelectUsers from "components/form/SelectUsers";
import removeRefData from "helpers/removeRefData";

const LeadSourceSummeryItemReportFilterSection = ({ filters, setFilters }) => {
  const { lead_source_id } = useParams();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...filters,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...filters,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFilters(newFormData);
  };

  const handleFilterApply = () => {
    const filteredObject = removeEmptyFields(filters);
    let formDataWithoutRefKeys = removeRefData(filteredObject);

    navigate(
      `/reports/lead-source-summary-report/${lead_source_id}/?page=1&${createSearchParams(
        {
          filters: JSON.stringify({
            ...formDataWithoutRefKeys,
          }),
        }
      )}`
    );
  };

  useEffect(() => {
    if (filterItems) {
      const parsedFilterItems = JSON.parse(filterItems);
      setFilters({
        ...filters,
        ...parsedFilterItems,
      });
    } else {
      setFilters(leadSourceSummeryItemReportFilterKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  const handleResetFilter = () => {
    const parsedFilterItems = JSON.parse(filterItems);
    const newLeadSourceSummeryItemReportFilterKeys = {
      ...leadSourceSummeryItemReportFilterKeys,
      date_range: parsedFilterItems?.date_range
        ? parsedFilterItems?.date_range
        : "",
      date_range_for: parsedFilterItems?.date_range_for
        ? parsedFilterItems?.date_range_for
        : "",
      from_date: parsedFilterItems?.from_date
        ? parsedFilterItems?.from_date
        : "",
      to_date: parsedFilterItems?.to_date ? parsedFilterItems?.to_date : "",
    };

    const filteredObject = removeEmptyFields(
      removeRefData(newLeadSourceSummeryItemReportFilterKeys)
    );
    const parsedFilteredObject = JSON.stringify(filteredObject);
    navigate(
      `/reports/lead-source-summary-report/${lead_source_id}?${
        Object.keys(filteredObject).length > 0
          ? createSearchParams({ filters: parsedFilteredObject })
          : ""
      }`
    );
    setFilters(newLeadSourceSummeryItemReportFilterKeys);
  };

  return (
    <Container fluid>
      <Form>
        <Row className="g-3">
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Title</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={handleFieldChange}
                    value={filters.title}
                  />
                </Col>
              </Form.Group>
            </Row>
            <Row className="mt-3">
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Status</Form.Label>
                <Col>
                  <SelectLeadStatus
                    name="lead_status"
                    value={filters.lead_status_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Company</Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    name="company_name"
                    onChange={handleFieldChange}
                    value={filters.company_name}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Type</Form.Label>
                <Col>
                  <Form.Select
                    name="is_company"
                    onChange={handleFieldChange}
                    value={filters.is_company}
                  >
                    <option value="">All</option>
                    <option value={1}>Company</option>
                    <option value={0}>Individual</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Lead Owner</Form.Label>
                <Col>
                  <SelectUsers
                    name="lead_owner"
                    handleFieldChange={handleFieldChange}
                    value={filters.lead_owner_ref}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Converted</Form.Label>
                <Col>
                  <Form.Select
                    name="is_converted"
                    onChange={handleFieldChange}
                    value={filters.is_converted}
                  >
                    <option value="">All</option>
                    <option value="1">Converted</option>
                    <option value="0">Not Converted</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Row>
          </Col>
          <Col md={2}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Priority</Form.Label>
                <Col>
                  <SelectLeadPriority
                    name="lead_priority"
                    value={filters.lead_priority_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row className="m-0">
          <Form.Group
            as={Col}
            md={2}
            className="d-flex justify-content-end w-100 p-0"
          >
            <div className="d-flex align-items-end gap-2 mt-3">
              <Button
                size="sm"
                variant="warning"
                onClick={handleFilterApply}
                title="Apply Added Filters"
              >
                Apply
              </Button>
              <Button
                size="sm"
                variant="transparent"
                className="shadow-sm"
                onClick={handleResetFilter}
                title="Clear All Filters"
              >
                <FcClearFilters size={18} />
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </Container>
  );
};

export default LeadSourceSummeryItemReportFilterSection;
