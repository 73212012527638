import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card, Form } from "react-bootstrap";
import qs from "qs";
import { apiCall } from "helpers/apiCalls";
import { FaUserTie } from "react-icons/fa";
import { DeleteContext } from "context/Context";
import { Link } from "react-router-dom";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonClientFilter from "./SupportingComponets/CommonClientFilter";
import Grid from "module/common/ViewPages/Common/Grid/Grid";
import { BsGrid, BsList } from "react-icons/bs";
import List from "module/common/ViewPages/Common/List/List";

const ClientViewWrapper = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState({});
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [clientListFilter, setClientListFilter] = useState(0);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission, showPermission] =
    [
      "create-customer",
      "update-customer",
      "delete-customer",
      "show-customer",
    ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const [filterVisibility, setFilterVisibility] = useState(true);
  const { customer_id, customer_code, customer_type, currency_code, status } =
    allQueryParams;
  const isLoactionList = location.pathname === "/account/list";

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/sales/customers/" + data,
        title: "Delete Account",
        message: "Are you sure you want to delete this acccount?",
        onSuccess: () => {
          const dataWithoutDeletedItem = clients.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/account/list?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else {
            setClients((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "sales/customers",
      params: {
        created_from_crm: clientListFilter,
        page: page,
        customer_id: customer_id,
        customer_code: customer_code,
        customer_type: customer_type,
        currency_code: currency_code,
        status: status,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    }).catch((error) => {
      return error;
    });
    setClients(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    clientListFilter,
    customer_id,
    customer_code,
    customer_type,
    currency_code,
    status,
  ]);

  const handleClientList = (e) => {
    e.target.checked ? setClientListFilter(0) : setClientListFilter(1);
  };

  const columns = [
    {
      accessor: "name",
      Header: "ACCOUNT NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { id, name } = rowData.row.original;
        return showPermission ? (
          <Link to={`/account/overview/${id}?tab=overview`}>
            <h5 className="mb-0 fs--1">{name}</h5>
          </Link>
        ) : (
          <h5 className="mb-0 fs--1">{name}</h5>
        );
      },
    },
    {
      accessor: "customer_id",
      Header: "ACCOUNT ID",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { customer_id } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{customer_id}</h5>;
      },
    },
    {
      accessor: "email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{email ? email : "N/A"}</h5>;
      },
    },
    {
      accessor: "phone",
      Header: "PHONE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { phone } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{phone}</h5>;
      },
    },
    {
      accessor: "customer_type",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { customer_type } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{customer_type}</h5>;
      },
    },
    {
      accessor: "currency_code",
      Header: "CURRENCY",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { currency_code } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{currency_code}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { inactive } = rowData.row.original;
        return (
          <Badge bg={inactive === 0 ? "success" : "warning"}>
            {inactive === 0 ? " Active" : "Inactive"}
          </Badge>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {updatePermission && (
              <EditButton
                route={`/account/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                  view: "list",
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between align-items-center flex-wrap p-3 ${
              !filterVisibility ? "border-bottom" : ""
            }`}
          >
            <div className="d-flex align-items-center">
              <FaUserTie size={20} className="me-1 text-dark" />
              <span className="fs-1 text-uppercase">Account List</span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Form.Group className="d-flex align-items-center gap-2">
                <Form.Label className="fs--1 pt-2">
                  Show All Accounts
                </Form.Label>
                <Form.Check
                  type="switch"
                  className="custom-common-switch"
                  id="custom-switch"
                  size={"sm"}
                  name="unassigned"
                  onChange={handleClientList}
                  checked={clientListFilter === 0}
                />
              </Form.Group>
              {createPermission && (
                <AddButton
                  to={`/account/add?${createSearchParams({
                    ...allQueryParams,
                    view: isLoactionList ? "list" : "grid",
                  })}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
              <Button
                variant="transparent"
                size="sm"
                className="list-add-button"
                style={{ boxShadow: "none" }}
                title={"Grid view"}
                as={Link}
                to={`${
                  isLoactionList ? "/account/grid" : "/account/list"
                }?${createSearchParams({
                  ...allQueryParams,
                })}`}
              >
                {isLoactionList ? <BsGrid size={20} /> : <BsList size={20} />}
              </Button>
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonClientFilter />
          </div>
        </Card.Header>
        {!isLoading ? (
          isLoactionList ? (
            <List
              columns={columns}
              clients={clients}
              allQueryParams={allQueryParams}
            />
          ) : (
            <Grid
              data={clients}
              allQueryParams={allQueryParams}
              handleDelete={handleDelete}
              renderFrom={"account"}
            />
          )
        ) : (
          <LoadingScreenCover>
            <LoadingCommon loadingText="Looking for account" />
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default ClientViewWrapper;
