import {
  Card,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Tab,
  Tabs,
} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ProfileImage from "../../../../../assets/img/Avathar/employee.avif";
import "./UserProfile.scss";
import {
  FaBuilding,
  FaCodeBranch,
  FaEnvelopeSquare,
  FaPhoneSquareAlt,
} from "react-icons/fa";
import PieChart from "module/common/Charts/PieChart";
import GraphChart from "module/common/Charts/GraphChart";
import ActivityTimeLine from "../Activity/Timeline/ActivityTimeLine";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import TabLoading from "module/common/TabLoading";
import BarChart from "module/common/Charts/BarChart";

const UserProfile = () => {
  const handleClose = () => navigate(-1);
  let navigate = useNavigate();
  const { profileId } = useParams();
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `crm/assignee-details`,
      params: { assignee: profileId },
    });

    setUserData(data.summary);
    setLoading(false);
  };

  useEffect(() => {
    if (profileId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const transformString = (string) => {
    const reformedString = string.replace(
      /(_|^|\s+)(\w)/g,
      (_, spaceOrStart, letter) => spaceOrStart + letter.toUpperCase()
    );
    return reformedString.replace("_", " ");
  };

  const optionsPie = {
    title: {
      text: "Task Overview",
      left: "center",
      top: "40px",
    },
    color: ["#5470c6", "#91cc75", "#fac858", "#268bc7b3"],
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b}: {c} ({d}%)",
    },

    series: [
      {
        name: "Pie Chart",
        type: "pie",
        radius: "50%",
        center: ["50%", "60%"],
        data:
          Object.keys(userData).length > 0 &&
          userData.task_status_count.map((item) => {
            return {
              name: transformString(item.status),
              value: item.count,
            };
          }),
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };

  const dataBar = {
    labels: Object.keys(userData).length > 0 && userData.six_months_before,
    datasets: [
      {
        label: "Completed",
        data:
          Object.keys(userData).length > 0 &&
          userData.monthly_ticket_count.map((item) => item.tickets_closed),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
      {
        label: "Assigned",
        data:
          Object.keys(userData).length > 0 &&
          userData.monthly_ticket_count.map((item) => item.tickets_assigned),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
      },
    ],
  };

  const optionsBar = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "top",
      },
    },
  };

  const optionsGraph = {
    title: {
      text: "Project Overview",
      subtext: "(Tasks 6 months)",
      left: "left",
    },
    color: [
      "#0048BA",
      "#B0BF1A",
      "#7CB9E8",
      "#B284BE",
      "#DB2D43",
      "#C46210",
      "#9F2B68",
      "#F19CBB",
      "#3B7A57",
      "#FFBF00",
      "#9966CC",
      "#3DDC84",
    ],
    tooltip: {
      trigger: "axis",
    },
    xAxis: {
      type: "category",
      data: Object.keys(userData).length > 0 && userData.six_months_before,
    },
    yAxis: {
      type: "value",
    },
    series:
      Object.keys(userData).length > 0 &&
      userData.task_count_by_project.map((item) => {
        return {
          name: item.project,
          type: "line",
          data: item.data.map((graphValue) => graphValue.task_count),
        };
      }),
  };

  return (
    <Offcanvas
      show={profileId ? true : false}
      onHide={handleClose}
      placement="end"
      style={{
        width: "51vh",
      }}
    >
      <Offcanvas.Body className="p-0">
        {Object.keys(userData).length > 0 && !loading ? (
          <>
            <div className="project-custom-profile-section">
              <div className="project-custom-close-button d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="project-custom-profile-image">
                <div className="d-flex justify-content-center">
                  <Image
                    src={userData.user.image ?? ProfileImage}
                    roundedCircle
                    height={120}
                    width={120}
                  />
                </div>
                <div className="d-flex flex-column mt-2">
                  <span className="fw-bold d-flex justify-content-center">
                    {userData.user.name}
                  </span>
                  {userData?.user?.roles?.length > 0 && (
                    <span className="d-flex justify-content-center">
                      {userData?.user?.roles[0]?.name}
                    </span>
                  )}
                  {userData?.user?.branch && (
                    <span className="d-flex justify-content-center">
                      {userData.user.branch?.company_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <Tabs
              defaultActiveKey="info"
              id="custom-profile-tabs"
              className="custom-profile-tabs"
              variant="pills"
              justify
            >
              <Tab eventKey="info" title="Info" className="custom-profile-tab">
                <Card className="card-user-profile">
                  <Card.Body>
                    <div className="">
                      <ListGroup className="border-bottom rounded-0">
                        <ListGroupItem className="ps-0 pb-2 border-0  fw-bold">
                          Contact Informations
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-1 pt-1">
                          <FaPhoneSquareAlt size={16} className="me-1" />
                          Phone : {userData.user.phone}
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-3">
                          <FaEnvelopeSquare size={16} className="me-1" />
                          Email : {userData.user.email}
                        </ListGroupItem>
                      </ListGroup>
                      <ListGroup className="border-bottom rounded-0 pt-2">
                        <ListGroupItem className="ps-0 pb-2 border-0  fw-bold">
                          Organizational Information
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-1 pt-1">
                          <FaBuilding size={16} className="me-1" />
                          Organization : {userData?.user?.branch?.company_name}
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1">
                          <FaCodeBranch size={16} className="me-1" />
                          Branch : {userData?.user?.branch?.branch_name}
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-1 pt-1 pb-3">
                          <FaPhoneSquareAlt size={16} className="me-1" />
                          Official Phone :{" "}
                          {userData?.user?.branch?.official_phone}
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab
                eventKey="projects"
                title="Projects"
                className="custom-profile-tab"
              >
                <Card className="card-user-profile">
                  <Card.Body>
                    <ListGroup horizontal>
                      <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                        Active Projects : {userData.active_project}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                        Completed Projects : {userData.completed_project}
                      </ListGroupItem>
                    </ListGroup>
                    <div className="chart-section-user-profile mt-3">
                      <GraphChart options={optionsGraph} />
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab
                eventKey="tasks"
                title="Tasks"
                className="custom-profile-tab"
              >
                <Card className="card-user-profile">
                  <Card.Body>
                    <ListGroup horizontal>
                      <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                        Assigned Tasks : {userData.no_of_assigned_task}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                        Completed Tasks : {userData.no_of_completed_tasks}
                      </ListGroupItem>
                    </ListGroup>
                    <div className="chart-section-user-profile">
                      <PieChart
                        options={optionsPie}
                        height={300}
                        width={"100%"}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab
                eventKey="tickets"
                title="Tickets"
                className="custom-profile-tab"
              >
                <Card className="card-user-profile">
                  <Card.Body>
                    <ListGroup horizontal>
                      <ListGroupItem className="border-0 p-0 fw-bolder text-muted">
                        Assigned Tickets : {userData.no_of_assigned_tickets}
                      </ListGroupItem>
                      <ListGroupItem className="border-0 p-0 ms-auto fw-bolder text-muted">
                        Completed Tickets : {userData.no_of_completed_tickets}
                      </ListGroupItem>
                    </ListGroup>
                    <div className="chart-section-user-profile mt-3">
                      <BarChart options={optionsBar} data={dataBar} />
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab
                eventKey="activity"
                title="Activity"
                className="custom-profile-tab"
              >
                <ActivityTimeLine />
              </Tab>
            </Tabs>
          </>
        ) : loading ? (
          <>
            <TabLoading />
            <ListLoading style={{ height: "93vh" }} />
          </>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <span>No User Data Available</span>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default UserProfile;
