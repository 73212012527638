import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";

import { AiOutlineMenu } from "react-icons/ai";

import { apiCall } from "helpers/apiCalls";
import { AuthWizardContext } from "context/Context";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import ProjectDetailedReportFilterSection from "../ReportFilterSection/ProjectDetailedReportFilterSection";
import removeRefData from "helpers/removeRefData";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import removeEmptyFields from "helpers/removeEmptyFields";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";

const ProjectReportView = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const [projectReport, setProjectReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState({
    date_range: "",
    date_range_for: "",
    from_date: "",
    to_date: "",
    project_id: "",
    project_id_ref: "",
    customer_id: "",
    customer_id_ref: "",
    status: "",
    status_ref: { label: "", value: "" },
  });

  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];

  const keysToCheck = ["date_range_for", "project_id", "customer_id", "status"];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const handleCanvas = () => setShow(!show);

  const getProjectReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    if (!parsedFilterItems.date_range_for) {
      parsedFilterItems.from_date = "";
      parsedFilterItems.to_date = "";
    }
    let additionalRemoveField = "date_range";
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems, additionalRemoveField)
    );
    try {
      const data = await apiCall({
        url: `crm/project-detailed-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: qs.stringify,
      });

      setProjectReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}
            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/project-report"
                filters={filterFields}
                setFilters={setFilterFields}
                columns={columns}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/project-detailed-report/export"}
                  successMessage={"Project Detail Report Export Completed"}
                  initialMessage={"Project Detail Report Export Started"}
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="">
            <div className="border-bottom p-3">
              <ProjectDetailedReportFilterSection
                filters={filterFields}
                setFilters={setFilterFields}
                filterValuesExist={filterValuesExist}
                columns={columns}
              />
            </div>
            <div className="d-flex flex-column gap-2 mt-3">
              <span className="fs--1 align-self-center">{company_name}</span>
              <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
                Projects Detailed Report
              </Link>
            </div>
            <div className="mt-4">
              {!dataProcessing ? (
                <Table striped bordered hover responsive size="sm">
                  <thead>
                    <tr
                      style={{
                        backgroundColor: "#00185894",
                        color: "whitesmoke",
                      }}
                    >
                      <th className="fw-bold text-light pt-2 pb-2">#</th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        PROJECT NAME
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        CLIENT NAME
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        PROJECT PRICE
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        TOTAL MEMBERS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        RUNNING TASKS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        COMPLETED TASKS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        CANCELLED TASKS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        TODO TASKS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        TOTAL TASKS
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">
                        START DATE
                      </th>
                      <th className="fw-bold text-light pt-2 pb-2">END DATE</th>
                      <th className="fw-bold text-light pt-2 pb-2">STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(projectReport).length > 0 &&
                    projectReport.data.length > 0 ? (
                      projectReport.data.map((item, key) => (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td className="text-capitalize">
                            {item.project_title}
                          </td>
                          <td className="text-capitalize">
                            {item.customer_name}
                          </td>
                          <td>{item.project_price}</td>
                          <td>{item.project_members_count}</td>
                          <td>{item.currently_running_tasks_count}</td>
                          <td>{item.completed_tasks_count}</td>
                          <td>{item.cancelled_tasks_count}</td>
                          <td>{item.to_do_tasks_count}</td>
                          <td>{item.tasks_count}</td>
                          <td>{item.start_date}</td>
                          <td>{item.end_date}</td>
                          <td className="text-capitalize">{item.status}</td>
                        </tr>
                      ))
                    ) : (
                      <tr height="100px">
                        <td colSpan={13} className="text-center">
                          <span>No Data Found</span>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              ) : (
                <div
                  className="d-flex justify-content-center align-item-center"
                  style={{ minHeight: "51vh" }}
                >
                  <LoadingScreen message="Loading Projects Detailed Report.." />
                </div>
              )}
            </div>
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {projectReport && projectReport.links && !dataProcessing && (
            <ReportPagination
              data={projectReport}
              url="/reports/project-report"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default ProjectReportView;
