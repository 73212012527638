import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import { MdLabelImportant } from "react-icons/md";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonLabelsFilter from "./SupportingComponents.jsx/CommonLabelsFilter";
import ShowMore from "components/common/ShowMore";

const LabelsTable = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [labels, setLabels] = useState({});
  const [filterVisibility, setFilterVisibility] = useState(true);
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission] = [
    "store-label",
    "update-label",
    "delete-label",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const { label, subject_type } = allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/label/" + id,
        title: "Delete Label",
        message: "Are you sure you want to delete this label?",
        onSuccess: () => {
          const dataWithoutDeletedItem = labels?.data?.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/settings/system-settings/labels?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setLabels((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { label } = rowData?.row.original;
        return <h5 className="mb-0 fs--1">{label}</h5>;
      },
    },
    {
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { description, id } = rowData?.row.original;
        return <div className="mb-0 fs--1">
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={60}
            uniqueKey={id}
          />
        </div>;
      },
    },
    {
      accessor: "subject_type",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { subject_type } = rowData?.row.original;
        return <h5 className="mb-0 fs--1 text-capitalize">{subject_type}</h5>;
      },
    },
    {
      accessor: "colour",
      Header: "COLOUR",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { colour } = rowData?.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {
              <Button
                className="d-inline me-2 mt-2 rounded-circle border-0"
                size="sm"
                style={{
                  width: "1.5rem",
                  height: "1.5rem",
                  backgroundColor: colour,
                }}
              />
            }
          </h5>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData?.row.original;
        return (
          <div className="d-flex gap-2">
            {updatePermission && (
              <EditButton
                route={`/settings/system-settings/labels/edit/${id}?${createSearchParams(
                  {
                    ...allQueryParams,
                  }
                )}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page) => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: "crm/label",
        params: {
          page: page,
          label: label,
          subject_type: subject_type,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setLabels(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, label, subject_type]);

  return (
    <>
      <Outlet context={[getData]} />

      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between p-3 ${
              !filterVisibility ? "border-bottom" : ""
            }`}
          >
            <div className="d-flex  align-items-center">
              <MdLabelImportant size={28} className="me-2 text-dark" />
              <span className="custom-module-header">LABELS LIST</span>
            </div>
            <div className="d-flex gap-2">
              {createPermission && (
                <AddButton
                  to={`/settings/system-settings/labels/add?${createSearchParams(
                    {
                      ...allQueryParams,
                    }
                  )}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonLabelsFilter />
          </div>
        </Card.Header>
        {!isLoading && labels?.data?.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={labels?.data} sortable>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {labels?.links && (
                <AppPagination
                  data={labels}
                  url="/settings/system-settings/labels"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Labels" />
            ) : (
              <h5 className="text-center text-secondary">
                Labels not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {createPermission && (
                  <Button
                    className="m-1"
                    variant="success"
                    size="sm"
                    as={Link}
                    to={`/settings/system-settings/labels/add?${createSearchParams(
                      {
                        ...allQueryParams,
                      }
                    )}`}
                  >
                    Add Label
                  </Button>
                )}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default LabelsTable;
