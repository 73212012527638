import { apiCall } from "helpers/apiCalls";
import useAxisproPermission from "hooks/useAxisproPermission";
import ListLoading from "module/common/ListLoading";
import ActivityLog from "module/common/ViewPages/Common/Activity/Timeline/ActivityLog";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Card,
  Col,
  ListGroup,
  Row,
} from "react-bootstrap";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { FaBan } from "react-icons/fa";
import { MdPlaylistPlay } from "react-icons/md";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";

const OverviewTab = ({ data }) => {
  const { user_id, name, activity_log } = data;
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [tasks, setTasks] = useState({});
  const [projects, setProjects] = useState({});
  const [tickets, setTickets] = useState({});
  const [activeContent, setactiveContent] = useState("");
  const tab = queryParams.get("tab");
  const axisProPermission = useAxisproPermission();
  const overviewPermission =
    axisProPermission("list-task") ||
    axisProPermission("list-ticket") ||
    axisProPermission("list-project");

  const getData = async () => {
    setLoading(true);
    if (axisProPermission("list-task")) {
      const tasksData = await apiCall({
        url: `crm/task`,
        params: {
          requested_from: "employee",
          employee_id: user_id,
        },
      });
      setTasks(tasksData.data.slice(0, 3));
    }

    if (axisProPermission("list-ticket")) {
      const ticketsData = await apiCall({
        url: `crm/ticket`,
        params: {
          filters: [
            {
              column: "assigned_to",
              operator: "=",
              type: "string",
              value: user_id,
            },
          ],
        },
      });
      setTickets(ticketsData.data.slice(0, 3));
    }

    if (axisProPermission("list-project")) {
      const projectsData = await apiCall({
        url: `crm/project`,
        params: {
          requested_from: "employee",
          employee_id: user_id,
        },
      });
      setProjects(projectsData.data.slice(0, 3));
    }

    setLoading(false);
  };

  useEffect(() => {
    tab === "overview" && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  const handleText = (id) => {
    if (activeContent === id) {
      setactiveContent("");
    } else {
      setactiveContent(id);
    }
  };

  return (
    <div>
      <Row className="g-2 pt-3">
        <Col lg={8} md={6} style={{ maxHeight: "82.3vh", overflow: "auto" }}>
          <div className="d-flex flex-column gap-2 card border">
            {!overviewPermission && (
              <div
                className="d-flex justify-content-center align-items-center gap-2"
                style={{ height: "25vh" }}
              >
                <FaBan size={30} color="#ff6e6e" />
                <span className="fs-0">
                  No permissions are available to view this page.
                </span>
              </div>
            )}
            <Accordion
              className="custom-accordion d-flex flex-column gap-2"
              size="sm"
              defaultActiveKey={["0", "1", "2", "3"]}
              alwaysOpen
              style={{ backgroundColor: "#F5F6F8" }}
            >
              {axisProPermission("list-project") && (
                <Accordion.Item
                  eventKey="0"
                  className="custom-accordion-item border-0"
                >
                  <Accordion.Header className="custom-accordion-header d-flex">
                    <span className="fs-0 fw-bold">Latest Projects</span>
                    <Button
                      variant="transparent"
                      size="sm"
                      className="p-0 custom-transperent-common-button ms-2"
                      as={Link}
                      to={`/tasks/list?${createSearchParams({
                        filters: JSON.stringify({
                          column: "assigned_to",
                          operator: "=",
                          type: "string",
                          value: user_id,
                          value_ref: {
                            label: name,
                            value: user_id,
                          },
                        }),
                      })}`}
                    >
                      <MdPlaylistPlay size={22} />
                    </Button>
                  </Accordion.Header>
                  <Accordion.Body
                    className="custom-accordion-header pt-4 pb-4"
                    style={{ backgroundColor: "#F5F6F8" }}
                  >
                    <ListGroup className="d-flex gap-2">
                      {!loading && projects.length > 0 ? (
                        projects.map((item, key) => (
                          <ListGroup.Item
                            key={key}
                            as="li"
                            className="d-flex justify-content-between align-items-start rounded-1"
                          >
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{item.title}</div>
                              {item?.description && (
                                <span
                                  className="text-dark cursor-pointer"
                                  onClick={() => handleText(item.id)}
                                >
                                  <p className="text-wrap fs--2">
                                    {activeContent === item.id
                                      ? item?.description?.replace(
                                          /<(.|\n)*?>/g,
                                          ""
                                        )
                                      : `${item?.description
                                          ?.replace(/<(.|\n)*?>/g, "")
                                          .substring(0, 120)}`}
                                    {activeContent === item.id &&
                                      item?.description?.length >= 120 &&
                                      " ...read less"}
                                    {activeContent !== item.id &&
                                      item?.description?.length >= 120 &&
                                      " ...read more"}
                                  </p>
                                </span>
                              )}
                            </div>
                            <Badge
                              className="text-capitalize"
                              bg={
                                item.status === "open"
                                  ? "primary"
                                  : item.status === "completed"
                                  ? "success"
                                  : item.status === "hold"
                                  ? "warning"
                                  : "danger"
                              }
                              pill
                            >
                              {item.status}
                            </Badge>
                          </ListGroup.Item>
                        ))
                      ) : loading ? (
                        <ListLoading
                          style={{ maxHeight: "12.8vh", overflow: "hidden" }}
                        />
                      ) : (
                        <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                          <BsDatabaseFillExclamation
                            size={40}
                            className="text-mute"
                          />
                          <span>No data found!</span>
                        </div>
                      )}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {axisProPermission("list-task") && (
                <Accordion.Item
                  eventKey="1"
                  className="custom-accordion-item border-0"
                >
                  <Accordion.Header className="custom-accordion-header">
                    <span className="fs-0 fw-bold">Latest Tasks</span>
                    <Button
                      variant="transparent"
                      size="sm"
                      className="p-0 custom-transperent-common-button ms-2"
                      as={Link}
                      to={`/tasks/list?${createSearchParams({
                        filters: JSON.stringify({
                          column: "assigned_to",
                          operator: "=",
                          type: "string",
                          value: user_id,
                          value_ref: {
                            label: name,
                            value: user_id,
                          },
                        }),
                      })}`}
                    >
                      <MdPlaylistPlay size={22} />
                    </Button>
                  </Accordion.Header>
                  <Accordion.Body
                    className="custom-accordion-header pt-4"
                    style={{ backgroundColor: "#F5F6F8" }}
                  >
                    <ListGroup className="d-flex gap-2">
                      {!loading && tasks.length > 0 ? (
                        tasks.map((item, key) => (
                          <ListGroup.Item
                            key={key}
                            as="li"
                            className="d-flex justify-content-between align-items-start rounded-1"
                          >
                            <div className="ms-2 me-auto">
                              <div className="fw-bold">{item.title}</div>
                              {item?.description && (
                                <span className="fs--2">
                                  <span
                                    className="text-dark cursor-pointer"
                                    onClick={() => handleText(item.id)}
                                  >
                                    <p className="text-wrap fs--2">
                                      {activeContent === item.id
                                        ? item?.description?.replace(
                                            /<(.|\n)*?>/g,
                                            ""
                                          )
                                        : `${item?.description
                                            ?.replace(/<(.|\n)*?>/g, "")
                                            .substring(0, 120)}`}
                                      {activeContent === item.id &&
                                        item?.description?.length >= 120 &&
                                        " ...read less"}
                                      {activeContent !== item.id &&
                                        item?.description?.length >= 120 &&
                                        " ...read more"}
                                    </p>
                                  </span>
                                </span>
                              )}
                            </div>
                            <Badge
                              className="text-capitalize"
                              bg={
                                item.status === "done"
                                  ? "success"
                                  : item.status === "in_progress"
                                  ? "primary"
                                  : item.status === "in_review"
                                  ? "warning"
                                  : "secondary"
                              }
                              pill
                            >
                              {item?.status?.replace("_", " ")}
                            </Badge>
                          </ListGroup.Item>
                        ))
                      ) : loading ? (
                        <ListLoading
                          style={{ maxHeight: "12.8vh", overflow: "hidden" }}
                        />
                      ) : (
                        <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                          <BsDatabaseFillExclamation
                            size={40}
                            className="text-mute"
                          />
                          <span>No data found!</span>
                        </div>
                      )}
                    </ListGroup>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {axisProPermission("list-ticket") && (
                <Accordion.Item
                  eventKey="2"
                  className="custom-accordion-item border-0"
                >
                  <Accordion.Header className="custom-accordion-header">
                    <span className="fs-0 fw-bold">Latest Tickets</span>
                    <Button
                      variant="transparent"
                      size="sm"
                      as={Link}
                      to={`/tickets/list?${createSearchParams({
                        filters: JSON.stringify({
                          column: "assigned_to",
                          operator: "=",
                          type: "string",
                          value: user_id,
                          value_ref: {
                            label: name,
                            value: user_id,
                          },
                        }),
                      })}&tab=all`}
                      className="p-0 custom-transperent-common-button ms-2"
                    >
                      <MdPlaylistPlay size={22} />
                    </Button>
                  </Accordion.Header>
                  <Accordion.Body
                    className="custom-accordion-header"
                    style={{ backgroundColor: "#F5F6F8" }}
                  >
                    <div className="d-flex flex-column">
                      <div className="mt-2">
                        <ListGroup className="d-flex gap-2">
                          {!loading && tickets.length > 0 ? (
                            tickets.map((item, key) => (
                              <ListGroup.Item
                                key={key}
                                as="li"
                                className="d-flex justify-content-between align-items-start rounded-1"
                              >
                                <div className="ms-2 me-auto">
                                  <div className="fw-bold">{item.title}</div>
                                  <span className="fs--2">
                                    <span
                                      className="text-dark cursor-pointer"
                                      style={{ textDecoration: "none" }}
                                      onClick={() => handleText(item.id)}
                                    >
                                      <p className="text-wrap fs--2">
                                        {activeContent === item.id
                                          ? item?.description?.replace(
                                              /<(.|\n)*?>/g,
                                              ""
                                            )
                                          : `${item?.description
                                              ?.replace(/<(.|\n)*?>/g, "")
                                              .substring(0, 120)}`}
                                        {activeContent === item.id &&
                                          item.description.length >= 120 &&
                                          " ...read less"}
                                        {activeContent !== item.id &&
                                          item.description.length >= 120 &&
                                          " ...read more"}
                                      </p>
                                    </span>
                                  </span>
                                </div>
                                <Badge
                                  className="text-capitalize"
                                  bg={
                                    item.status === "new"
                                      ? "secondary"
                                      : item.status === "open"
                                      ? "primary"
                                      : item.status === "pending"
                                      ? "warning"
                                      : item.status ===
                                        "awaiting_business_input"
                                      ? "dark"
                                      : item.status === "with_client_review"
                                      ? "info"
                                      : item.status === "defered"
                                      ? "danger"
                                      : "success"
                                  }
                                  pill
                                >
                                  {item?.status?.replace(/_/g, " ")}
                                </Badge>
                              </ListGroup.Item>
                            ))
                          ) : loading ? (
                            <ListLoading
                              style={{
                                maxHeight: "12.8vh",
                                overflow: "hidden",
                              }}
                            />
                          ) : (
                            <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                              <BsDatabaseFillExclamation
                                size={40}
                                className="text-mute"
                              />
                              <span>No data found!</span>
                            </div>
                          )}
                        </ListGroup>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
            </Accordion>
          </div>
        </Col>
        <Col lg={4} md={6}>
          <Card
            style={{ maxHeight: "82.3vh", overflow: "auto" }}
            className="h-100 border"
          >
            <Card.Header className="fs-0 fw-bold border-bottom">
              Latest Activity
            </Card.Header>
            <Card.Body className="p-0 h-100">
              <ActivityLog data={activity_log} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default OverviewTab;
