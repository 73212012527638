import { React, useEffect, useState } from "react";
import axios from "axios";
import { Alert, Badge, Card, Container, Table } from "react-bootstrap";
import qs from "qs";
import PropTypes from "prop-types";

import { showToast } from "module/common/Toast/toast";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import ScrollToLoadMore from "components/scroll-to-load-more/ScrollToLoadMore";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";

function ContractTab({ customer_id }) {
  const Translate = useAxisproTranslate();
  const [data, setData] = useState([]);
  const [nextLink, setNextLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    if (customer_id) {
      setLoading(true);
      axios
        .get("/sales/contracts", {
          params: {
            customer_id: customer_id,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        })
        .then((response) => {
          if (response.data.success) {
            setData(response.data.data.data);
            setNextLink(response.data.data.links.next);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error) {
            showToast("Something went wrong please contact admin", "error");
            setLoading(false);
          }
        });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer_id]);

  const loadNextPage = async () => {
    if (nextLink && nextLink !== "") {
      await axios
        .get(nextLink)
        .then((res) => {
          setData([...data, ...res.data.data.data]);
          if (
            res?.data?.data?.links?.next !== null &&
            typeof res.data.data.links.next !== "undefined"
          ) {
            setNextLink(res.data.data.links.next);
          } else {
            setNextLink(null);
          }
        })
        .catch((error) => {
          if (error) {
            showToast("Something went wrong please contact admin", "error");
          }
        });
    }
  };

  return (
    <Card style={{ height: "79vh", overflow: "hidden", overflowY: "scroll" }}>
      <Container fluid className="p-0 reports-container-wrapper">
        {!loading ? (
          <Table striped hover responsive>
            <thead
              className="text-dark"
              style={{ backgroundColor: "rgb(237,243,248)" }}
            >
              <tr>
                <th className="ledger-report-table-heading">#</th>
                <th className="ledger-report-table-heading">
                  {Translate("CONTRACT DATE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("REFERENCE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("CONTRACT FROM")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("CONTRACT TO")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("TITLE")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("AMOUNT")}
                </th>
                <th className="ledger-report-table-heading">
                  {Translate("STATUS")}
                </th>
              </tr>
            </thead>
            {data && data.length > 0 ? (
              <>
                {data.map((item, index) => (
                  <tbody key={index}>
                    <tr className="report-column-custom-style">
                      <td>{index + 1}</td>
                      <td>{item?.contract_date_formatted ?? " - "}</td>
                      <td>{item.reference}</td>
                      <td>{item?.contract_from_date_formatted ?? " - "}</td>
                      <td>{item?.contract_to_date_formatted ?? " - "}</td>
                      <td>{item?.title ?? " - "}</td>
                      <td>{item?.amount_formatted ?? " - "}</td>
                      <td>
                        <Badge
                          bg={
                            item?.status === "active"
                              ? "success"
                              : item?.status === "hold"
                              ? "warning"
                              : item?.status === "renewed"
                              ? "info"
                              : "danger"
                          }
                          className="mb-0 fs--2 text-uppercase"
                        >
                          {item?.status}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {nextLink && nextLink !== null && data && data.length > 0 && (
                  <ScrollToLoadMore
                    row={3}
                    column={8}
                    nextLink={nextLink}
                    loadNextPage={loadNextPage}
                  />
                )}
              </>
            ) : (
              <tr className="alert-styles">
                <td colSpan={12}>
                  <Alert variant="warning">
                    {Translate(
                      "Currently there is no contracts created against this customer."
                    )}
                  </Alert>
                </td>
              </tr>
            )}
          </Table>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "60vh", width: "85vw" }}
          >
            <LoadingCommon loadingText="Looking for Contracts" />
          </div>
        )}
      </Container>
    </Card>
  );
}

ContractTab.propTypes = {
  customer_id: PropTypes.any,
};

export default ContractTab;
