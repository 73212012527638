import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";
import { AiOutlineMenu } from "react-icons/ai";

import { apiCall } from "helpers/apiCalls";
import { runningCompletedTasksReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import removeEmptyFields from "helpers/removeEmptyFields";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import RunnigOrCompletedTasksReportFilterSection from "../ReportFilterSection/RunnigOrCompletedTasksReportFilterSection";
import CloseButton from "../Common/CloseButton";
import Export from "../Common/Export";

const RunningOrCompletedTasksReport = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const [runningOrCompletedTasksReport, setRunningOrCompletedTasksReport] =
    useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(
    runningCompletedTasksReportFilterKeys
  );

  const columns = [
    { key: "task_start_date", value: "Task Start Date" },
    { key: "task_end_date", value: "Task End Date" },
    { key: "timer_start_date", value: "Timer Start Date" },
    { key: "timer_end_date", value: "Timer End Date" },
  ];

  const keysToCheck = [
    "date_range_for",
    "project_id",
    "customer_id",
    "employee_id",
    "status",
  ];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const handleCanvas = () => setShow(!show);

  const getTaskReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    if (!parsedFilterItems.date_range_for) {
      parsedFilterItems.from_date = "";
      parsedFilterItems.to_date = "";
    }
    let additionalRemoveField = "date_range";
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems, additionalRemoveField)
    );

    try {
      const data = await apiCall({
        url: `/crm/running-or-completed-tasks-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setRunningOrCompletedTasksReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTaskReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}
            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/running-completed-tasks-report"
                filters={filterFields}
                setFilters={setFilterFields}
                columns={columns}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/running-or-completed-tasks-report/export"}
                  successMessage={
                    "Running or Completed Task Report Export Completed"
                  }
                  initialMessage={
                    "Running or Completed Task Report Export Started"
                  }
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <RunnigOrCompletedTasksReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
              filterValuesExist={filterValuesExist}
              columns={columns}
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            <span className="fs--1 align-self-center">{company_name}</span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Running/Completed Tasks Report
            </Link>
          </div>
          <div className="mt-4">
            {!dataProcessing ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#00185894",
                      color: "whitesmoke",
                    }}
                  >
                    <th className="fw-bold text-light fs--2">SL.NO</th>
                    <th className="fw-bold text-light fs--2">TASK NAME</th>
                    <th className="fw-bold text-light fs--2">PROJECT NAME</th>
                    <th className="fw-bold text-light fs--2">CLIENT</th>
                    <th className="fw-bold text-light fs--2">EMPLOYEE</th>
                    <th className="fw-bold text-light fs--2" width="6%">
                      TASK START DATE
                    </th>
                    <th className="fw-bold text-light fs--2" width="6%">
                      TASK END DATE
                    </th>
                    <th className="fw-bold text-light fs--2" width="10%">
                      TIMER START FIRST
                    </th>
                    <th className="fw-bold text-light fs--2" width="10%">
                      TIMER LAST END
                    </th>
                    <th className="fw-bold text-light fs--2">TOTAL WORKED</th>
                    <th className="fw-bold text-light fs--2">STATUS</th>
                    <th className="fw-bold text-light fs--2">CREATED BY</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(runningOrCompletedTasksReport).length > 0 &&
                  runningOrCompletedTasksReport.data.length > 0 ? (
                    runningOrCompletedTasksReport.data.map((item, key) => (
                      <tr key={key}>
                        <td>{`#${item.seq_no}`}</td>
                        <td className="text-capitalize">
                          <Link
                            className="text-dark"
                            to={`/tasks/list/overview/${item.task_id}`}
                          >
                            {item.task}
                          </Link>
                        </td>
                        <td className="text-capitalize">
                          <Link
                            className="text-dark"
                            to={`/project/info/${item.project_id}?tab=overview`}
                          >
                            {item.project}
                          </Link>
                        </td>
                        <td className="text-capitalize">
                          <Link
                            className="text-dark"
                            to={`/client/overview/${item.customer_id}?tab=overview`}
                          >
                            {item.customer}
                          </Link>
                        </td>
                        <td className="text-capitalize">{item.employee}</td>
                        <td>{item.task_start_date}</td>
                        <td>
                          {item.task_end_date ? item.task_end_date : "N/A"}
                        </td>
                        <td>{item.timer_first_start_at}</td>
                        <td>{item.timer_last_end_at}</td>
                        <td>{item.total_worked_time}</td>
                        <td className="text-capitalize">
                          {item.status.replace("_", " ")}
                        </td>
                        <td>{item.created_by_username}</td>
                      </tr>
                    ))
                  ) : (
                    <tr height="100px">
                      <td colSpan={13} className="text-center">
                        <span>No Data Found</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <div
                className="d-flex justify-content-center align-item-center"
                style={{ minHeight: "48vh" }}
              >
                <LoadingScreen message="Loading Running/Completed Tasks Report.." />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {runningOrCompletedTasksReport &&
            runningOrCompletedTasksReport.links &&
            !dataProcessing && (
              <ReportPagination
                data={runningOrCompletedTasksReport}
                url="/reports/running-completed-tasks-report"
                search={allQueryParams}
              />
            )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default RunningOrCompletedTasksReport;
