import React from "react";
import PropTypes from "prop-types";
import { createSearchParams, Link } from "react-router-dom";
import { Dropdown, Pagination } from "react-bootstrap";

const ReportPagination = ({ data, url, size = "sm", search }) => {
  data = data.meta ? { ...data.meta } : data;
  let limitStart = data && data.current_page ? data.current_page - 1 : 0;
  let limitEnd =
    data && data.current_page
      ? data.current_page + (data.current_page > 1 ? 2 : 3)
      : 0;
  let lastPage = limitEnd - data.last_page;
  limitStart = lastPage > 0 ? limitStart - lastPage : limitStart;
  return data && data.links && data.total > 0 ? (
    <div className="d-flex justify-content-between w-100">
      <div className="d-flex align-items-center">
        <p className="fs--1 mb-0">
          Showing {data.from} - {data.to} of {data.total} results
        </p>
      </div>
      <div>
        {data.total > data.per_page && (
          <Pagination size="sm" className="m-0">
            {data.current_page > 1 && (
              <>
                <li className="page-item">
                  <Link
                    to={`${url}${
                      search
                        ? `?${createSearchParams({ ...search, page: 1 })}`
                        : 1
                    }`}
                    className="page-link"
                    role="button"
                    tabIndex="0"
                  >
                    «
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    to={`${url}${
                      search
                        ? `?${createSearchParams({
                            ...search,
                            page: data.current_page - 1,
                          })}`
                        : data.current_page - 1
                    }`}
                    className="page-link"
                    role="button"
                    tabIndex="0"
                  >
                    ‹
                  </Link>
                </li>
              </>
            )}

            <Dropdown>
              <Dropdown.Toggle
                size="sm"
                variant="transparent"
                className={`custom-dropdown-button-report-pagination pt-1 pb-1`}
                style={{ border: "#F0F4FA 0.1rem solid" }}
              >
                {data.current_page}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ minWidth: "10px" }}>
                {data.links.map((item, index) =>
                  parseInt(item.label) >= limitStart &&
                  parseInt(item.label) <= limitEnd &&
                  item.active ? (
                    <Dropdown.Item active key={index}>
                      {item.label}
                    </Dropdown.Item>
                  ) : (
                    !item.label.includes("&laquo;") &&
                    !item.label.includes("&raquo") && (
                      <Dropdown.Item
                        key={index}
                        as={Link}
                        to={`${url}${
                          search
                            ? `?${createSearchParams({
                                ...search,
                                page: item.label,
                              })}`
                            : item.label
                        }`}
                      >
                        {item.label}
                      </Dropdown.Item>
                    )
                  )
                )}
              </Dropdown.Menu>
            </Dropdown>
            {data.current_page < data.last_page && (
              <>
                <li className="page-item">
                  <Link
                    to={`${url}${
                      search
                        ? `?${createSearchParams({
                            ...search,
                            page: data.current_page + 1,
                          })}`
                        : data.current_page + 1
                    }`}
                    state={{ reload: true }}
                    className="page-link"
                    role="button"
                    tabIndex="0"
                  >
                    ›
                  </Link>
                </li>
                <li className="page-item">
                  <Link
                    to={`${url}${
                      search
                        ? `?${createSearchParams({
                            ...search,
                            page: data.last_page,
                          })}`
                        : data.last_page
                    }`}
                    state={{ reload: true }}
                    className="page-link"
                    role="button"
                    tabIndex="0"
                  >
                    »
                  </Link>
                </li>
              </>
            )}
          </Pagination>
        )}
      </div>
    </div>
  ) : (
    ""
  );
};

ReportPagination.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string.isRequired,
  onPageChange: PropTypes.func,
  size: PropTypes.string,
  search: PropTypes.object,
};

export default ReportPagination;
