import React from "react";
import "./LoadingCommon.scss";

const LoadingCommon = ({ loadingText }) => {
  return (
    <div className="loader-animated-wrapper">
      <div className="loader-animated">
        <div className="loader-animated-element one"></div>
        <div className="loader-animated-element two"></div>
        <div className="loader-animated-element three"></div>
        <div className="loader-animated-element four"></div>
      </div>
      {loadingText && (
        <div className="loader-animated-text fs--1 text-center">
          {loadingText}
        </div>
      )}
    </div>
  );
};

export default LoadingCommon;
