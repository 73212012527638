import React from 'react';
import { Placeholder } from 'react-bootstrap';

function TablePlaceholder() {
  return (
    <tr>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td style={{ height: '40px' }} className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td style={{ height: '40px' }} className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
      <td style={{ height: '40px' }} className="p-1">
        <Placeholder as="p" animation="glow" className="m-0">
          <Placeholder
            className="w-100"
            size="sm"
            bg="secondary"
            style={{ opacity: '0.01' }}
          />
        </Placeholder>
      </td>
    </tr>
  );
}

export default TablePlaceholder;
