import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import removeEmptyFields from "helpers/removeEmptyFields";
import { leadConversionReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import SelectUsers from "components/form/SelectUsers";

const LeadConversionReportFilterSection = ({
  filters,
  setFilters,
  filterValuesExist,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");

  useEffect(() => {
    if (filterItems) {
      const parsedFilterItems = JSON.parse(filterItems);
      setFilters({
        ...filters,
        ...parsedFilterItems,
      });
    } else {
      setFilters(leadConversionReportFilterKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  const handleFilterChange = (e, action) => {
    let newOldData;
    if (action) {
      newOldData = {
        ...filters,
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    }
    setFilters({ ...newOldData });
  };

  const handleApplyFilter = () => {
    const filteredObject = removeEmptyFields(filters);
    navigate(
      `/reports/lead-conversion-report?page=1&${createSearchParams({
        filters: JSON.stringify({
          ...filteredObject,
        }),
      })}`
    );
  };

  return (
    <Container fluid>
      <Form>
        <Row className="g-3">
          <Col md={2}>
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Owner</Form.Label>
              <SelectUsers
                name="owner_id"
                handleFieldChange={handleFilterChange}
                value={filters.owner_id_ref}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <div className="h-100 d-flex align-items-center">
              <Button
                size="sm"
                className=""
                variant="success"
                onClick={handleApplyFilter}
                disabled={!filterValuesExist ? true : false}
              >
                <FaFilter size={13} />
                <span className="ms-1">Apply</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default LeadConversionReportFilterSection;
