import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  Offcanvas,
  Row,
  Spinner,
} from "react-bootstrap";
import { RiTeamFill } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import { FaPlus, FaTimesCircle } from "react-icons/fa";
import MutiSelectUsers from "components/form/MutiSelectUsers";
import { apiCall } from "helpers/apiCalls";
import removeRefData from "helpers/removeRefData";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { teamFormKeys } from "helpers/formKeys";
import { DeleteContext } from "context/Context";
import ListLoading from "module/common/ListLoading";

const TeamInfo = () => {
  let navigate = useNavigate();
  const { teamId } = useParams();
  const [teamData, setTeamData] = useState({});
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(teamFormKeys);
  const { dispatch } = useContext(DeleteContext);
  const [onSave, setOnSave] = useState(false);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/team-member/" + id,
        title: "Remove Team Member",
        message: "Are you sure you want to get that member out of this team?",
        onSuccess: () => {
          const dataWithoutDeletedItem = teamData.members.filter(
            (item) => item.id !== id
          );

          setTeamData({
            ...teamData,
            members: dataWithoutDeletedItem,
          });
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  useEffect(() => {
    if (teamId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId]);

  const getData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `/crm/team/${teamId}`,
    });
    setTeamData(data);
    setFormData({
      ...formData,
      name: data.name,
      description: data.description,
      team_lead_id: data.team_lead_id,
      team_lead_id_ref: {
        label: data.team_lead.name,
        value: data.team_lead.id,
      },
      team_members: [],
      team_members_ref: [],
    });
    setLoading(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;

    if (action) {
      if (action.action === "select-option") {
        const labelArray = e.map((item) => {
          return { id: item.value };
        });
        value = labelArray;
      }
      if (action.action === "clear") {
        value = null;
      }
    }

    newFormData = {
      ...formData,
      [action.name]: value,
      [action.name + "_ref"]: e,
    };
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOnSave(true);

    let arrExtTeamMembers = teamData.members.map((item) => {
      return { id: item.team_member_id };
    });

    let formDataWithoutRefkeys = removeRefData({
      ...formData,
      team_members: [...formData.team_members, ...arrExtTeamMembers],
    });

    formDataWithoutRefkeys?.team_members?.length > 0 &&
      axios({
        method: "put",
        url: `crm/team/${teamId}`,
        data: formDataWithoutRefkeys,
      }).then((response) => {
        if (response.data.success === true) {
          setFormData({
            ...formData,
            team_members: [],
            team_members_ref: [],
          });
          getData();
          showToast(response.data.message, "success");
          setOnSave(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
    setOnSave(false);
  };

  return (
    <Offcanvas
      show
      onHide={() => navigate(-1)}
      placement="end"
      style={{
        backgroundColor: "#f8f9fa",
        color: "#333",
        width: "71vh",
      }}
    >
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
        style={{ backgroundColor: "#D5D9E7" }}
      >
        <div className="d-flex align-items-center pt-2 pb-1">
          <RiTeamFill size={25} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            Team Information
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <>
            <Card className="border">
              <Card.Body>
                <div className="d-flex flex-column ms-1">
                  <span className="custom-text-muted-crm">Title</span>
                  <span className="fs-1 mt-1 text-capitalize">
                    {teamData.name}
                  </span>
                  <span className="mt-2 custom-text-muted-crm">Note</span>
                  <span className="mt-1">{teamData.description}</span>
                </div>
              </Card.Body>
            </Card>
            <div>
              <ListGroup className="mt-2">
                <ListGroup.Item className="fw-bold mb-2 rounded-0">
                  <span className="d-flex fw-bold">Team Lead</span>
                </ListGroup.Item>
                <ListGroup.Item className="border-top">
                  <div className="">
                    <span className="d-flex justify-content-center">
                      <Image
                        className="border"
                        src={teamData?.team_lead?.image ?? ProfileImage}
                        roundedCircle
                        alt="?"
                        loading="lazy"
                        height={"70rem"}
                        width={"70rem"}
                      />
                    </span>
                    <div className="d-flex flex-column">
                      <span className="mt-2 fw-bold d-flex justify-content-center">
                        {teamData?.team_lead?.name}
                      </span>
                      <span className="custom-text-muted-crm d-flex justify-content-center">
                        {teamData?.team_lead?.email}
                      </span>
                      <span className="custom-text-muted-crm d-flex justify-content-center">
                        {teamData?.team_lead?.phone}
                      </span>
                      <span className="custom-text-muted-crm d-flex justify-content-center">
                        {teamData?.team_lead?.roles.map((item, key) =>
                          key > 0 ? item.name + "," : item.name
                        )}
                      </span>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item className="fw-bold rounded-0 mt-2 mb-2 d-flex align-items-center border-top">
                  <span className="">Team Members</span>
                </ListGroup.Item>
                <ListGroup.Item
                  className={
                    teamData?.members?.length > 0
                      ? "border-0 border-top border-end border-start"
                      : "border"
                  }
                >
                  <Row>
                    <Col md={12} className="ps-2 pe-0">
                      <div className="d-flex flex-row gap-2 align-items-center">
                        <span className="w-100">
                          <MutiSelectUsers
                            name="team_members"
                            handleFieldChange={handleFieldChange}
                            value={formData.team_members_ref}
                            excludeUser={teamData.members}
                            //   assignee_id={assignee_id}
                          />
                        </span>
                        <span>
                          <Button
                            size="sm"
                            variant="success"
                            className="me-2"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                            disabled={
                              formData.team_members.length > 0 ? false : true
                            }
                          >
                            {onSave ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              <FaPlus />
                            )}
                          </Button>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <div style={{ maxHeight: "37vh", overflow: "auto" }}>
                  {Object.keys(teamData).length > 0 &&
                  teamData.members.length > 0 ? (
                    teamData?.members?.map((item, key) => (
                      <ListGroup.Item key={key}>
                        <div className="d-flex align-items-center">
                          <Image
                            className="custom-image-crm"
                            src={item?.team_member?.image ?? ProfileImage}
                            roundedCircle
                            alt="?"
                            loading="lazy"
                            height={"35vh"}
                            width={"35vh"}
                          />
                          <div className="d-flex flex-column">
                            <span className="ms-1 fw-bold text-capitalize">
                              {item.team_member.name}
                            </span>
                            <span className="ms-1">
                              {item?.team_member?.roles.map((item, key) =>
                                key > 0 ? item.name + "," : item.name
                              )}
                            </span>
                          </div>
                          <span className="ms-auto">
                            <Button
                              size="sm"
                              variant="transperant"
                              className="custom-transperent-common-button p-0"
                              onClick={(e) => {
                                handleDelete(item.id);
                              }}
                            >
                              <FaTimesCircle color="#ff5b5b" />
                            </Button>
                          </span>
                        </div>
                      </ListGroup.Item>
                    ))
                  ) : (
                    <ListGroup.Item
                      className="border-top-0 d-flex justify-content-center align-items-center"
                      style={{ minHeight: "37vh" }}
                    >
                      <span>No Team Members Yet!</span>
                    </ListGroup.Item>
                  )}
                </div>
              </ListGroup>
            </div>
          </>
        ) : (
          <ListLoading style={{ minHeight: "89vh" }} />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TeamInfo;
