import { React, useEffect, useState } from "react";
import axios from "axios";
import { Offcanvas } from "react-bootstrap";
import PropTypes from "prop-types";
import "./CustomerDetails.css";
import CustomerDetailsTab from "./CustomerDetailsTab";
import OtherDetailsCollapse from "./OtherDetailsCollapse";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import { FaUserCog } from "react-icons/fa";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerDetails = ({ show, onHide, value, showCustomerDetailsArea }) => {
  const [data, setData] = useState({});
  const [activity, setActivity] = useState([]);
  const [links, setLinks] = useState({});
  const [loading, setLoading] = useState(false);
  const [activityLoading, setActivityLoading] = useState(false);

  const fetchData = async () => {
    await axios
      .get(`sales/customers/${value.value}`)
      .then((res) => {
        setData(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
    await axios
      .get(`activity-log`, { params: { customer_id: value.value } })
      .then((res) => {
        setActivity(res.data.data.data);
        setLinks(res.data.data);
        setActivityLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    setActivityLoading(true);
    if (showCustomerDetailsArea) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCustomerDetailsArea, value.value]);

  return (
    <Offcanvas show={show} placement="end" onHide={onHide}>
      <Offcanvas.Header
        closeButton
        style={{ boxShadow: "none" }}
        className="offcanvas-heading-style"
      >
        <div>
          <FaUserCog size={25} className="me-2" />
          Client Details
        </div>
      </Offcanvas.Header>
      {!loading && data !== "" ? (
        <Offcanvas.Body className="customer-details-offcanvas pt-0">
          <div className="customer-header d-flex flex-row align-items-center">
            <div className="profile-icon d-flex justify-content-center align-items-center me-2 shadow-sm">
              <Image
                className="profile-icon"
                src={data?.image}
                alt="customer-image"
              />
            </div>
            <div className="details">
              <div className="d-flex">
                <h5 className="mt-1 me-2 text-uppercase">{data.name}</h5>
                <Link
                  className="m-0"
                  to={`/account/overview/${value.value}?tab=overview`}
                >
                  <FontAwesomeIcon icon="external-link-alt" size="xs" />
                </Link>
              </div>
              <div className="d-flex mt-2">
                <h6 className="mb-0 text-muted">
                  {data.email}
                  {data.email ? <span className="me-1 mx-1">|</span> : ""}
                  {data.phone}
                </h6>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between pt-3 pb-2">
            <div className="me-2 py-2">
              <h6 className="text-muted text-uppercase">
                Outstanding Receivables
              </h6>
              <h5 className="fs--1 fw-bold text-warning">
                {data.total_outstanding_formatted}
              </h5>
            </div>
            <div className="py-2">
              <h6 className="text-muted text-uppercase">Unused Credits</h6>
              <h5 className="fs--1 fw-bold text-success">
                {data.unused_credits_formatted}
              </h5>
            </div>
          </div>
          {data.customer_type ||
          data.currency_code ||
          data.payment_term_name ||
          data.credit_status_name ? (
            <OtherDetailsCollapse data={data} />
          ) : (
            ""
          )}

          <CustomerDetailsTab
            data={data}
            activity={activity}
            activityLoading={activityLoading}
            setActivity={setActivity}
            links={links}
            setLinks={setLinks}
          />
        </Offcanvas.Body>
      ) : (
        <LoadingScreen />
      )}
    </Offcanvas>
  );
};

CustomerDetails.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  value: PropTypes.any,
  showCustomerDetailsArea: PropTypes.bool,
};

export default CustomerDetails;
