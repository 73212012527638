import React from "react";
import { Link } from "react-router-dom";
import { Card, Col } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Background from "components/common/Background";
import SoftBadge from "components/common/SoftBadge";

function InfoTile({ stat, ...rest }) {
  const {
    title,
    value,
    decimal,
    suffix,
    prefix,
    valueClassName,
    linkText,
    link,
    badgeText,
    badgeBg,
    image,
    className,
  } = stat;
  return (
    <Card
      className={classNames(className, "overflow-hidden card-main")}
      {...rest}
    >
      <Background image={image} className="bg-card" />
      <Card.Body className="position-relative">
        <Col className="d-flex w-100 justify-content-between">
          <h6>
            {title}
            {badgeText && (
              <SoftBadge bg={badgeBg} pill className="ms-2">
                {badgeText}
              </SoftBadge>
            )}
          </h6>
        </Col>
        <div
          className={classNames(
            valueClassName,
            "display-4 fs-4 mb-2 fw-normal font-sans-serif"
          )}
        >
          <CountUp
            start={0}
            end={value}
            duration={2.75}
            suffix={suffix}
            prefix={prefix}
            separator=","
            decimals={decimal ? 2 : 0}
            decimal="."
          />
        </div>
        <Link
          to={link}
          className="fw-semi-bold fs--1 text-nowrap"
          style={{ visibility: link ? "visible" : "hidden" }}
        >
          {linkText}
          <FontAwesomeIcon
            icon="angle-right"
            className="ms-1"
            transform="down-1"
          />
        </Link>
      </Card.Body>
    </Card>
  );
}

InfoTile.propTypes = {
  stat: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    decimal: PropTypes.bool,
    suffix: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    valueClassName: PropTypes.string,
    linkText: PropTypes.string,
    link: PropTypes.string,
    badgeText: PropTypes.string,
    badgeBg: PropTypes.string,
    image: PropTypes.string,
    add_members: PropTypes.bool,
    className: PropTypes.string,
  }),
};

export default InfoTile;
