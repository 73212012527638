import React from "react";
import { MdDoneAll } from "react-icons/md";
import { BiErrorAlt } from "react-icons/bi";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";

export const showToast = (message, type) => {
  toast(message, {
    theme: "light",
    position: "top-center",
    type: "default",
    autoClose: 2000,
    className: type === "success" ? "success" : "warnings",
    icon:
      type === "success" ? (
        <MdDoneAll size={20} className="text-success" />
      ) : (
        <BiErrorAlt size={20} className="text-danger" />
      ),
  });
};
