import SelectLeadSource from "components/form/SelectLeadSource";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import SelectUsers from "components/form/SelectUsers";
import React from "react";
import { Form } from "react-bootstrap";

const LeadDetailedReportForm = ({ filters, handleFilterChange }) => {
  return (
    <div>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Owner</Form.Label>
        <SelectUsers
          name="owner_id"
          handleFieldChange={handleFilterChange}
          value={filters.owner_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Source</Form.Label>
        <SelectLeadSource
          name="source_id"
          handleFieldChange={handleFilterChange}
          value={filters.source_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Status</Form.Label>
        <SelectLeadStatus
          name="lead_status"
          handleFieldChange={handleFilterChange}
          value={filters.lead_status_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Priority</Form.Label>
        <Form.Select
          size="md"
          name="priority"
          onChange={handleFilterChange}
          value={filters.priority}
        >
          <option value="">Select Priority</option>
          <option value="high">High</option>
          <option value="medium">Medium</option>
          <option value="low">Low</option>
        </Form.Select>
      </Form.Group>
    </div>
  );
};

export default LeadDetailedReportForm;
