import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import TicketOverviewHeader from "./TicketOverviewHeader";
import TicketOverViewConversation from "./TicketOverViewConversation";
import PropertiesFilteringForm from "./PropertiesFilteringForm";
import ContactInfo from "./ContactInfo";
import { useNavigate, useParams } from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import axios from "axios";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import useAxisproPermission from "hooks/useAxisproPermission";

const TicketOverView = () => {
  const { overviewId } = useParams();
  const [overviewData, setOverviewData] = useState({});
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const navigate = useNavigate();
  const [loadingStatusUpdate, setLoadingStatusUpdate] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [updatePermission] = ["update-ticket"].map((permission) =>
    axisProPermission(permission)
  );

  useEffect(() => {
    if (overviewId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await apiCall({
        url: `/crm/ticket/${overviewId}`,
      });
      setOverviewData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = () => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: overviewId,
        url: "/crm/ticket/" + overviewId,
        title: "Delete Ticket",
        message: "Are you sure you want to delete this Ticket?",
        onSuccess: () => {
          navigate("/tickets/list?tab=all");
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const handleTicketStatus = (e) => {
    e.preventDefault();
    setLoadingStatusUpdate(true);
    axios({
      method: "post",
      url: `crm/ticket-status/update`,
      params: { ticket_id: overviewId, status: "closed" },
    }).then((response) => {
      if (response.data.success === true) {
        setOverviewData(response.data.data);
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      }
    });
    setLoadingStatusUpdate(false);
  };

  return (
    <>
      {!loading && Object.keys(overviewData).length > 0 ? (
        <Row className="g-3 mb-3">
          <Col xxl={9} xl={8} className="d-flex flex-column">
            <TicketOverviewHeader
              handleDelete={handleDelete}
              handleTicketStatus={handleTicketStatus}
              currentStatus={overviewData.status}
              loading={loadingStatusUpdate}
            />
            <TicketOverViewConversation data={overviewData} />
          </Col>
          <Col xxl={3} xl={4}>
            <Row className="g-3 position-sticky top-0">
              {updatePermission && (
                <Col md={6} xl={12}>
                  <PropertiesFilteringForm
                    data={overviewData}
                    setOverviewData={setOverviewData}
                  />
                </Col>
              )}

              {overviewData.created_user && (
                <Col xs={12}>
                  <ContactInfo data={overviewData.created_user} />
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      ) : (
        <LoadingScreenCover style={{ height: "88.7vh" }}>
          {loading ? (
            <LoadingCommon loadingText="Looking for Projects Details" />
          ) : (
            <h5 className="text-center text-secondary">
              Project not found! Let's add it &#128578;
            </h5>
          )}
        </LoadingScreenCover>
      )}
    </>
  );
};

export default TicketOverView;
