import axios from "axios";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import SelectDocumentType from "components/form/SelectDocumentType";
import { DeleteContext } from "context/Context";
import { clientKYCFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  OverlayTrigger,
  Popover,
  Card,
  Row,
  Col,
} from "react-bootstrap";
import { AiFillFileUnknown } from "react-icons/ai";
import {
  FaCloudDownloadAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const ClientKyc = ({
  itemId,
  type,
  attachmentControl = true,
  itemsClassName = "",
}) => {
  const [formData, setFormData] = useState({
    ...clientKYCFormKeys,
    id: itemId,
    type: type,
  });
  const [loading, setLoading] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);
  const [fileData, setFileData] = useState([]);
  const { dispatch } = useContext(DeleteContext);
  const fileRef = useRef();
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: `/documents/${id}`,
        title: "Delete KYC Document",
        message: "Are you sure you want to delete this Document?",
        onSuccess: () => {
          fetchData();
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const fetchData = () => {
    if (itemId && type) {
      setLoading(true);
      axios
        .get(`documents?subject_type=${type}&subject_id=${itemId}&only_kyc=1`)
        .then((res) => {
          if (res.data.success) {
            setFileData(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let fieldName;
    let fieldValue;
    if (!action) {
      if (e.target.name === "files") {
        value = e.target.files[0];
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      fieldName = action.name.replace("_id", "_name");
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        fieldValue = e.label ? e.label : "";
      }
      if (action.action === "clear") {
        fieldValue = null;
      }
      newFormData = {
        ...formData,
        [fieldName]: fieldValue,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOnSave(true);
    setFormError({});
    axios({
      method: "post",
      url: `documents`,
      data: setNewFormData(removeRefData(formData)),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          fetchData();
          setResetFiles(!resetFiles);
          setFormData({ ...clientKYCFormKeys, id: itemId, type: type });
          fileRef.current.value = "";
          showToast(response.data.message, "success");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const handleClearFields = () => {
    setFormData({ ...clientKYCFormKeys, id: itemId, type: type });
    fileRef.current.value = "";
    setFormError({});
  };

  return (
    <Row>
      <Col xs={12}>
        <Card className="d-flex shadow-none">
          <Card.Header
            className="pb-2 pt-2 rounded-0"
            style={{ backgroundColor: "#d2d4e9" }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="">KYC INFORMATION</div>
            </div>
          </Card.Header>
          <Card.Body
            className="rounded-0"
            style={{
              height: "64.7vh",
              overflow: "auto",
            }}
          >
            <ListGroup>
              {attachmentControl && (
                <ListGroupItem className="rounded-0">
                  <Row className="g-3">
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label className="require-data">
                          Document Type
                        </Form.Label>
                        <SelectDocumentType
                          value={formData.document_type_ref}
                          name="document_type"
                          handleFieldChange={handleFieldChange}
                          error={!!formError.document_type}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.document_type}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label className="require-data">
                          Expiry Date
                        </Form.Label>
                        <AppDatePicker
                          name="expiry_date"
                          yearPlaceholder="yyyy"
                          monthPlaceholder="mm"
                          dayPlaceholder="dd"
                          onChange={handleFieldChange}
                          value={formData.expiry_date}
                          isInvalid={!!formError.expiry_date}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.expiry_date}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label className="require-data">
                          Attachment
                        </Form.Label>
                        <Form.Control
                          type="file"
                          name="files"
                          onChange={handleFieldChange}
                          ref={fileRef}
                          isInvalid={!!formError.files}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.files}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <div className="d-flex flex-row justify-content-end align-items-end gap-2 h-100 w-100">
                      <Button
                        variant="danger"
                        size="sm"
                        title="Clear Fields"
                        onClick={handleClearFields}
                        disabled={
                          formData.files instanceof File &&
                          formData.expiry_date &&
                          formData.document_type
                            ? false
                            : true
                        }
                      >
                        Clear
                      </Button>
                      <Button
                        variant="success"
                        size="sm"
                        onClick={(e) => {
                          handleSubmit(e);
                        }}
                        disabled={
                          formData.files instanceof File &&
                          formData.expiry_date &&
                          formData.document_type &&
                          !onSave
                            ? false
                            : true
                        }
                      >
                        {onSave ? "Loading..." : "Upload KYC"}
                      </Button>
                    </div>
                  </Row>
                </ListGroupItem>
              )}

              {fileData?.length > 0 && !loading ? (
                fileData.map((attachment, key) => (
                  <Fragment key={key}>
                    {key === 0 && (
                      <ListGroupItem className={`rounded-0 ${itemsClassName}`}>
                        <label>Existing Files</label>
                      </ListGroupItem>
                    )}
                    <ListGroupItem
                      className={`rounded-0 ${itemsClassName} overflow-auto`}
                    >
                      <div className="d-flex row gy-2 h-100">
                        <div className="d-flex align-items-center col-md-5 col-sm-5 h-100">
                          <OverlayTrigger
                            trigger={["hover", "focus"]}
                            placement="bottom"
                            overlay={
                              <Popover className="p-3">
                                <div className="d-flex flex-column">
                                  <span className="text-mute custom-text-muted-crm">
                                    File Size: {attachment.human_readable_size}
                                  </span>
                                  <span className="text-mute custom-text-muted-crm">
                                    Updated at: {attachment.updated_at}
                                  </span>
                                  <span className="text-mute custom-text-muted-crm">
                                    Last Updated: {attachment.last_updated}
                                  </span>
                                </div>
                              </Popover>
                            }
                          >
                            <Button
                              size="sm"
                              className="p-0 custom-transperent-common-button"
                              variant="transparent"
                            >
                              {attachment.file_name.split(".").pop() ===
                                "png" ||
                              attachment.file_name.split(".").pop() ===
                                "jpg" ? (
                                <FaFileImage size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "pdf" ? (
                                <FaFilePdf color="#fb3939b8" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                  "xls" ||
                                attachment.file_name.split(".").pop() ===
                                  "xlsx" ? (
                                <FaFileExcel color="#007f3eb5" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "csv" ? (
                                <FaFileCsv color="#41a754cc" size={38} />
                              ) : attachment.file_name.split(".").pop() ===
                                "doc" ? (
                                <FaFileWord color="#285293" size={38} />
                              ) : (
                                <AiFillFileUnknown size={38} />
                              )}
                            </Button>
                          </OverlayTrigger>
                          <div className="d-flex flex-column ms-2">
                            <span className="fw-bold">
                              {attachment.file_name.length > 65
                                ? attachment.file_name.substring(0, 65) +
                                  "." +
                                  attachment.file_name.split(".").pop()
                                : attachment.file_name}
                            </span>
                            <div>
                              <span className="text-mute custom-text-muted-crm">
                                {attachment.created_at}
                              </span>
                              <span className="text-mute custom-text-muted-crm ms-2">
                                {attachment.created}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column justify-content-center ms-auto col-md-3 col-sm-3">
                          <span className="text-mute custom-text-muted-crm">
                            Document Type
                          </span>
                          <span className="fw-bold text-capitalize">
                            {attachment?.document_type?.replace("_", " ")}
                          </span>
                        </div>
                        <div className="d-flex flex-column justify-content-center ms-auto col-md-2 col-sm-2">
                          <span className="text-mute custom-text-muted-crm">
                            Expiry
                          </span>
                          <span className="fw-bold">
                            {attachment?.expiry_date_formatted}
                          </span>
                        </div>
                        <div className="ms-auto col-md-1 col-sm-1 d-flex align-items-center justify-content-center">
                          <Button
                            as={Link}
                            target="_blank"
                            to={attachment.file_url}
                            variant="transparent"
                            size="sm"
                            className="custom-transperent-common-button p-0"
                          >
                            <FaCloudDownloadAlt color="#718dd6" size={18} />
                          </Button>
                          {attachmentControl && (
                            <Button
                              variant="transparent"
                              size="sm"
                              className="custom-transperent-common-button p-0 ms-2"
                              onClick={() => {
                                handleDelete(attachment.id);
                              }}
                            >
                              <FaTrash color="#dc3939" />
                            </Button>
                          )}
                        </div>
                      </div>
                    </ListGroupItem>
                  </Fragment>
                ))
              ) : loading ? (
                <ListLoading style={{ height: "46.5vh", overflow: "hidden" }} />
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center "
                  style={{ height: "10rem" }}
                >
                  <span className="">No KYC Document Added Yet &#128521;</span>
                </div>
              )}
            </ListGroup>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ClientKyc;
