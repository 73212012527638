import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card, Form, Row, Col } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { FaPlus } from "react-icons/fa";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import { leadsFilters } from "../common/helpers/leadsCommonFilters";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import FilterButton from "components/filter/FilterButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import LeadForm from "./LeadForm";
import { SiGoogleadmob } from "react-icons/si";
import { Link } from "react-router-dom";
import {
  DeleteButton,
  EditButton,
  ViewButton,
} from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import ExportButton from "components/export-button/ExportButton";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import {
  commonLeadFilterFormKeys,
  leadStatusEditFormKeys,
} from "helpers/formKeys";
import { TbStatusChange } from "react-icons/tb";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import CommonFieldEditForm from "module/common/Forms/CommonFieldEditForm";
import CommonFilter from "components/filter/CommonFilter";

const LeadTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [leads, setLeads] = useState({});
  const [filters] = useState(leadsFilters);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const mode = queryParams.get("mode");
  const axisProPermission = useAxisproPermission();
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [formData, setFormData] = useState(leadStatusEditFormKeys);

  const actionPermissions =
    axisProPermission("view-lead") ||
    axisProPermission("update-lead") ||
    axisProPermission("delete-lead");
  const [filterVisibility, setFilterVisibility] = useState(true);
  const [filterFields, setFilterFields] = useState({});

  const {
    title,
    company_name,
    is_company,
    lead_owner,
    is_converted,
    lead_source,
    lead_priority,
    lead_status,
    from_date,
    to_date,
    date_range_for,
    date_range,
  } = allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/lead/" + id,
        title: "Delete Lead",
        message: "Are you sure you want to delete this lead?",
        onSuccess: () => {
          const dataWithoutDeletedItem = leads.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/leads/lead?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setLeads((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleStatusEdit = (rowData) => {
    setFormData((prevData) => ({
      ...prevData,
      lead_id: rowData.id,
      status_id: rowData.lead_status_id,
      status_id_ref: {
        value: rowData.lead_status_id,
        label: rowData.lead_status,
      },
      description: "",
    }));
    setShowStatusModal(true);
  };

  const handleModalClose = () => {
    setFormData(leadStatusEditFormKeys);
    setShowStatusModal(false);
  };

  const columns = [
    {
      accessor: "title",
      Header: "TITLE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { title } = rowData.row.original;
        return (
          <Link
            to={
              axisProPermission("view-lead")
                ? `/leads/lead/info/${rowData.row.original.id}`
                : "#"
            }
            className={`custom-link-style-crm ${
              axisProPermission("view-lead") ? "" : "disabled-link"
            }`}
          >
            <span className="mb-0 fs--1">{title}</span>
          </Link>
        );
      },
    },
    {
      accessor: "company_name",
      Header: "COMPANY",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { company_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{company_name}</span>;
      },
    },
    {
      accessor: "email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <span className="mb-0 fs--1">{email}</span>;
      },
    },
    {
      accessor: "phone_no",
      Header: "CONTACT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { phone_no } = rowData.row.original;
        return <span className="mb-0 fs--1">{phone_no}</span>;
      },
    },
    {
      accessor: "is_company",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { is_company } = rowData.row.original;
        return (
          <span className="mb-0 fs--1">
            {is_company ? "Company" : "Individual"}
          </span>
        );
      },
    },
    {
      accessor: "lead_owner_name",
      Header: "LEAD OWNER",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { lead_owner_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_owner_name}</span>;
      },
    },
    {
      accessor: "product_name",
      Header: "PRODUCTS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { product_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{product_name}</span>;
      },
    },
    {
      accessor: "is_converted",
      Header: "CONVERTION STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { is_converted } = rowData.row.original;
        return (
          <div className="mb-0 fs--1 text-uppercase text-center">
            <Badge
              style={{ minWidth: "60px" }}
              bg={is_converted ? "success" : "secondary"}
            >
              {is_converted ? "CONVERTED" : "NOT CONVERTED"}
            </Badge>
          </div>
        );
      },
    },
    {
      accessor: "lead_source",
      Header: "SOURCE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { lead_source_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_source_name}</span>;
      },
    },
    {
      accessor: "lead_priority",
      Header: "PRIORITY",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3",
      },
      Cell: (rowData) => {
        const { lead_priority } = rowData.row.original;
        return (
          <div className="mb-0 fs--1 text-uppercase">
            <Badge style={{ minWidth: "60px" }} bg={"primary"}>
              {lead_priority?.replace("_", " ")}
            </Badge>
          </div>
        );
      },
    },
    {
      accessor: "lead_status_id",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3",
      },
      Cell: (rowData) => {
        const { lead_status } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_status}</span>;
      },
    },
    {
      accessor: "generated_on_formatted",
      Header: "GENERATED ON",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3",
      },
      Cell: (rowData) => {
        const { generated_on_formatted } = rowData.row.original;
        return (
          <span className="mb-0 fs--1">
            {generated_on_formatted ? generated_on_formatted : "N/A"}
          </span>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionPermissions ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionPermissions ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="custom-table-body-td-div-action d-flex justify-content-center align-items-center">
            {axisProPermission("view-lead") && (
              <ViewButton
                to={`/leads/lead/info/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}

            <Button
              size="sm"
              variant="transparent"
              className={`custom-button-action p-0`}
              title="Edit Status"
              onClick={() => {
                handleStatusEdit(rowData.row.original);
              }}
            >
              <TbStatusChange
                color="#569ffd"
                size={16}
                className="custom-button-status-edit-icon m-1"
              />
            </Button>
            {axisProPermission("update-lead") && (
              <EditButton
                className={"ms-2"}
                route={`/leads/lead?${createSearchParams({
                  ...allQueryParams,
                })}${filterItems || pageNumber ? "&" : ""}mode=edit&id=${id}`}
              />
            )}
            {axisProPermission("delete-lead") && (
              <DeleteButton
                className={"ms-2"}
                onClick={() => handleDelete(id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    setFilterFields({
      title: title,
      company_name: company_name,
      is_company: is_company,
      lead_owner: lead_owner,
      is_converted: is_converted,
      lead_source: lead_source,
      lead_priority: lead_priority,
      lead_status: lead_status,
      from_date: from_date,
      to_date: to_date,
      date_range_for: date_range_for,
    });
    try {
      const data = await apiCall({
        url: "crm/lead",
        params: {
          page: page ? page : 1,
          title: title,
          company_name: company_name,
          is_company: is_company,
          lead_owner: lead_owner,
          is_converted: is_converted,
          lead_source: lead_source,
          lead_priority: lead_priority,
          lead_status: lead_status,
          from_date: from_date,
          to_date: to_date,
          date_range_for: date_range ? "generated_on" : "",
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setLeads(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    filterItems,
    title,
    company_name,
    is_company,
    lead_owner,
    is_converted,
    lead_source,
    lead_priority,
    lead_status,
    from_date,
    to_date,
    date_range_for,
  ]);

  const dataForEditModal = {
    data: formData,
    id: formData.lead_id,
    apiUrl: "lead-update-status",
    heading: "UPDATE STATUS",
    close: handleModalClose,
    getData: getData,
    handleFieldChange: handleFieldChange,
  };

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center gap-2">
                <SiGoogleadmob size={22} className="text-dark" />
                <span className="fs-1 text-uppercase mt-1">Leads List</span>
              </div>
              <div className="d-flex align-items-center gap-1">
                <ExportButton
                  url="crm/lead-export"
                  initialMessage={"Lead export started"}
                  successMessage={"Lead export completed"}
                  file_type={"pdf"}
                  variant={"danger"}
                  filters={filterFields}
                />
                {axisProPermission("store-lead") && (
                  <Button
                    size="sm"
                    variant="success"
                    onClick={() => {
                      navigate(
                        `/leads/lead?${createSearchParams({
                          ...allQueryParams,
                        })}${filterItems || pageNumber ? "&" : ""}mode=add`
                      );
                    }}
                  >
                    <FaPlus size={10} />
                  </Button>
                )}
                <FilterCommonButton
                  filterVisibility={filterVisibility}
                  setFilterVisibility={setFilterVisibility}
                />
              </div>
            </div>
            <div style={{ display: filterVisibility ? "block" : "none" }}>
              <CommonFilter
                filterKeys={commonLeadFilterFormKeys}
                date_range_for="generated_on"
              />
            </div>
          </div>
        </Card.Header>
        {!isLoading && leads && leads.data && leads.data.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={leads.data} sortable>
            <Card.Body className="p-0">
              <SimpleBar className="simplebar-style-with-pagination">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle custom-table-row"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </SimpleBar>
            </Card.Body>

            <Card.Footer className="border-top p-1 pt-3">
              {leads && leads.links && (
                <AppPagination
                  data={leads}
                  url="/leads/lead"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Leads" />
            ) : (
              <h5 className="text-center text-secondary">
                Leads not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {axisProPermission("store-lead") && (
                  <Button
                    size="sm"
                    className={"m-1 ms-0"}
                    variant={"success"}
                    onClick={() => {
                      navigate(
                        `/leads/lead?${createSearchParams({
                          ...allQueryParams,
                        })}${filterItems || pageNumber ? "&" : ""}mode=add`
                      );
                    }}
                  >
                    NEW LEAD
                  </Button>
                )}

                {filterItems ? (
                  <FilterButton
                    showText
                    filters={filters}
                    url="leads/lead"
                    className="m-1"
                    variant="secondary"
                  />
                ) : null}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
      {!isLoading && mode && <LeadForm getData={getData} />}
      {showStatusModal && (
        <CommonFieldEditForm
          dataForEditModal={dataForEditModal}
          IconComponent={TbStatusChange}
        >
          <div>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Status</Form.Label>
                  <SelectLeadStatus
                    name="status_id"
                    value={dataForEditModal?.data?.status_id_ref}
                    handleFieldChange={dataForEditModal?.handleFieldChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </CommonFieldEditForm>
      )}
    </>
  );
};

export default LeadTableView;
