import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { FiAlertCircle } from 'react-icons/fi';

import useAxisproTranslate from 'hooks/useAxisproTranslate';
import ExportItemClearButton from 'components/export-bucket/ExportItemClearButton';
import ExportItemDownloadButton from 'components/export-bucket/ExportItemDownloadButton';
import ExportItemRetryButton from 'components/export-bucket/ExportItemRetryButton';

const DownloadLineItem = ({ className, item, dispatch, old }) => {
  const Translate = useAxisproTranslate();
  const fileType = item.file_type
    ? item.file_type
    : item.file_name
    ? item.file_name.split('.').pop()
    : null;

  return (
    <div className={`download-item border-bottom p-3 ${className}`}>
      <div className="title">
        <p
          className={`m-0 fw-bold title text-${
            item.status === 'SUCCESS' || item.status === 'COMPLETED'
              ? 'dark'
              : item.status === 'FAILED'
              ? 'danger'
              : 'warning'
          }`}
        >
          {item.status === 'SUCCESS' || item.status === 'COMPLETED'
            ? item.successMessage ?? `${item.title} Completed`
            : item.initialMessage ?? item.title}
        </p>
        {item.status === 'SUCCESS' || item.status === 'COMPLETED' ? (
          <div className="d-flex flex-row align-items-center mt-2">
            {fileType === 'pdf' ? (
              <FaFilePdf style={{ color: '#f40f02' }} />
            ) : ['xlsx', 'excel'].includes(fileType) ? (
              <FaFileExcel style={{ color: '#1d6f42' }} />
            ) : null}
            <p className="file-name ms-2 mb-0">{item.file_name}</p>
          </div>
        ) : item.status === 'FAILED' ? (
          <div className="d-flex flex-row align-items-center mt-2">
            <FiAlertCircle className="text-danger" />
            <p className="file-name ms-2 mb-0">{Translate('Faild!')}</p>
          </div>
        ) : null}
      </div>
      <div
        className={`action pt-2${
          item.status && item.status !== 'INITIATED'
            ? ' d-flex justify-content-end'
            : ''
        }`}
      >
        {item.status === 'SUCCESS' || item.status === 'COMPLETED' ? (
          <div className="d-flex flex-wrap w-100 mt-3">
            <div className="me-2 small">
              <p className="mb-0 text-dark d-flex flex-wrap">
                <span className="me-1">{Translate('Started at:')}</span>
                <span>{item.created_at}</span>
              </p>
              <p className="mb-0 text-dark d-flex flex-wrap">
                <span className="me-1">{Translate('Completed at:')}</span>
                <span>{item.updated_at}</span>
              </p>
            </div>
            <div className="d-flex flex-row my-auto ms-auto">
              <ExportItemDownloadButton
                url={item.file_url}
                file_name={item.file_name}
              />
              {dispatch ? (
                <ExportItemClearButton
                  exportArrayDispatch={dispatch}
                  item={item}
                  old={old}
                />
              ) : null}
            </div>
          </div>
        ) : item.status === 'FAILED' ? (
          <div className="d-flex flex-row">
            <ExportItemRetryButton exportArrayDispatch={dispatch} item={item} />
            <ExportItemClearButton
              exportArrayDispatch={dispatch}
              item={item}
              old={old}
            />
          </div>
        ) : (
          <div className="d-flex flex-row align-items-center">
            <Spinner
              animation="border"
              variant="info"
              style={{ width: 15, height: 15 }}
              className="me-2"
            />
            <span className="fs--1">{Translate('Exporting...')}</span>
          </div>
        )}
      </div>
    </div>
  );
};

DownloadLineItem.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  old: PropTypes.bool
};

export default DownloadLineItem;
