import { Fragment, React, useContext, useState } from "react";
import axios from "axios";
import "./assets/scss/style.scss";
import Layout from "./layouts/Layout";
import ErrorShowingPopup from "module/common/ErrorPopup/ErrorShowingPopup";
import { AuthWizardContext } from "context/Context";
import Error403Popup from "components/errors/Error403/Error403Popup";

const getAuthToken = () => {
  let userData = JSON.parse(localStorage.getItem("AXIS_PRO_CRM_USER"));
  return userData && userData.token ? userData.token : undefined;
};

// setting axios config
axios.interceptors.request.use(
  (config) => {
    if (!config.headers.settingBaseUrl) {
      config.withCredentials = true;
      axios.defaults.crossOrigin = true;
      axios.defaults.crossDomain = true;
      if (!config.headers["Content-Type"]) {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers["Accept"] = "application/json";
      config.headers["Authorization"] = `Bearer ${getAuthToken()}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const App = () => {
  const [error, setError] = useState(false);
  const [exceptionData, setExceptionData] = useState("");
  const currentUrl = window.location.href;
  const { lockScreen } = useContext(AuthWizardContext);
  const [permission, setPermission] = useState(false);

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error && error.response) {
        if (error.response.data || error.response.status) {
          if (error.response.status === 401) {
            if (localStorage.getItem("AXIS_PRO_CRM_USER")) {
              lockScreen(true);
            } else {
              window.location.href = currentUrl;
            }
          } else if (error.response.status === 403) {
            setPermission(true);
          } else {
            let data = null;
            let exceptionDetails = {};
            if (error?.response?.data?.data?.exception) {
              if (
                error?.response?.data?.data?.exception &&
                error?.response?.data?.data?.status === 500
              ) {
                data = error.response.data.data.exception;
                exceptionDetails.class = data.class;
              } else if (error.response.status === 500) {
                data = error.response.data;
                exceptionDetails.exception = data.exception;
              }
              if (data) {
                setError(true);
                setExceptionData({
                  ...exceptionDetails,
                  file: data.file,
                  line: data.line,
                  message: data.message,
                  request_url: data.request_url
                    ? data.request_url
                    : data.responseURL
                    ? data.responseURL
                    : "None",
                  request_method: data.request_method
                    ? data.request_method
                    : error.config.method
                    ? error.config.method
                    : "None",
                  frontend_url: currentUrl,
                });
              }
            }
          }
        }
      }
      return Promise.reject(error);
    }
  );

  return (
    <Fragment>
      <Layout />
      <ErrorShowingPopup
        show={error}
        exceptionData={exceptionData}
        onHide={() => setError(false)}
      />
      <Error403Popup show={permission} onHide={() => setPermission(false)} />
    </Fragment>
  );
};

export default App;
