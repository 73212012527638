import { React, useContext, useEffect, useReducer, useState } from "react";
import PropTypes from "prop-types";
import { apiCall } from "helpers/apiCalls";
import authReducer from "reducers/authReducer";
import { AuthWizardContext } from "context/Context";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import axios from "axios";
import { Alert } from "react-bootstrap";

const AuthProvider = ({ children }) => {
  const [loading, setloading] = useState(true);
  const [user, dispatch] = useReducer(
    authReducer,
    useContext(AuthWizardContext)
  );
  const [invalidHost, setInvalidHost] = useState(false);
  const urlObject = new URL(window.location.href);
  const lockScreen = () => {
    dispatch({ type: "UPDATE", payload: { ...user, lockScreen: true } });
  };

  const getUserInfo = async () => {
    if (
      (process.env.REACT_APP_API_DEVELOPMENT_URL &&
        process.env.REACT_APP_API_CUSTOM_MODE === "DEV") ||
      process.env.REACT_APP_API_CUSTOM_REQUEST_URL === urlObject.origin
    ) {
      axios.defaults.baseURL = process.env.REACT_APP_API_DEVELOPMENT_URL;
    } else {
      try {
        const apiEndPointDetails = await axios.get(
          process.env.REACT_APP_SET_API_URL,
          {
            headers: {
              withCredentials: true,
              settingBaseUrl: true,
            },
          }
        );

        if (!apiEndPointDetails?.data?.api_url) {
          console.error("Invalid Host.!");
          setInvalidHost(true);
          return;
        }

        axios.defaults.baseURL = apiEndPointDetails.data.api_url;
      } catch (error) {
        console.error("Invalid Host.!");
        setInvalidHost(true);
        return;
      }
    }

    let userData = JSON.parse(localStorage.getItem("AXIS_PRO_CRM_USER"));
    let data = null;
    if (userData) {
      try {
        // validate token
        data = await apiCall({
          url: "user/info",
          params: {
            app_name: "CRM",
          },
        });

        // update auth context
        userData.username = data.username;
        userData.name = data.name;
        dispatch({
          type: "UPDATE",
          payload: data,
        });
      } catch (error) {
        console.log(error);
      }
    }
    setloading(false);
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthWizardContext.Provider
      value={{
        user,
        dispatch,
        lockScreen,
      }}
    >
      {loading ? (
        <div
          style={{ width: "100%", minHeight: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          {invalidHost ? (
            <Alert variant="danger">Invalid host.!</Alert>
          ) : (
            <LoadingCommon />
          )}
        </div>
      ) : (
        children
      )}
    </AuthWizardContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
export default AuthProvider;
