import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Badge, Card, Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { employeeFormKeys } from "helpers/formKeys";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import { showToast } from "module/common/Toast/toast";
import DataProcessing from "module/common/DataProcessing";
import Image from "react-bootstrap/Image";
import EmployeeForm from "../EmployeeForm/EmployeeForm";
import "../EmployeeForm/employeeFormStyle.scss";
import "./EmployeeProfile.scss";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import removeEmptyFields from "helpers/removeEmptyFields";

function EmployeeProfile() {
  const [activeTab, setActiveTab] = useState("General");
  const [formData, setFormData] = useState(employeeFormKeys);
  const [formError, setFormError] = useState({});
  const [geFormError, setGeFormError] = useState({});
  const [acFormError, setAcFormError] = useState({});
  const [ogFormError, setOgFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const general_tap = useRef();
  const Organizational_Tap = useRef();
  const Account_Tap = useRef();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const [giIndicateErrors, setGiIndicateErrors] = useState(0);
  const [oiIndicateErrors, setOiIndicateErrors] = useState(0);
  const [aiIndicateErrors, setAiIndicateErrors] = useState(0);
  const [previewURL, setPreviewURL] = useState(null);

  const handleTabChange = (e) => {
    setActiveTab(e.target.name ? e.target.name : e.target.id);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = setNewFormData(
      removeRefData(removeEmptyFields(formData))
    );

    axios({
      method: itemId ? "put" : "post",
      url: `crm/employee${itemId ? "/" + itemId : ""}`,
      headers: {
        "Content-Type": itemId ? "application/json" : "multipart/form-data",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(itemId ? -1 : "/employee");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const checkTypeError = () => {
    setAcFormError({});
    setOgFormError({});
    setGeFormError({});
    const GIField = {
      first_name: "first_name",
      phone: "phone",
      office_number: "office_number",
      dob: "dob",
      email: "email",
      // Add more fields as needed
    };
    const OGField = {
      department_id: "department_id",
      designation_id: "designation_id",
      company_id: "company_id",
    };
    const acError = {
      username: "username",
      password: "password",
      c_password: "c_password",
      role_id: "role_id",
      time_zone: "time_zone",
    };
    Object.keys(formError).forEach((Item) => {
      if (Item in GIField) {
        setGeFormError((prev) => ({ ...prev, [Item]: formError[Item] }));
      } else if (Item in OGField) {
        setOgFormError((prev) => ({ ...prev, [Item]: formError[Item] }));
      } else if (Item in acError) {
        setAcFormError((prev) => ({ ...prev, [Item]: formError[Item] }));
      }
    });
  };
  const getData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: "crm/employee/" + itemId,
    });

    setPreviewURL(data?.profile_pic?.file_url ?? ProfileImage);

    setFormData({
      ...formData,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      dob: data.dob,
      gender: data.gender,
      username: data.username,
      password: data.password,
      c_password: data.c_password,
      designation_name: data.designation_name,
      designation_id: data.designation_id,
      designation_id_ref: {
        label: data.designation_name,
        value: data.designation_id,
      },
      department_name: data.department_name,
      department_id: data.department_id,
      department_id_ref: {
        label: data.department_name,
        value: data.department_id,
      },
      phone: data.phone,
      office_number: data.office_number,
      company_name: data.company_name,
      company_id: data.company_id,
      company_id_ref: {
        label: data.company_name,
        value: data.company_id,
      },
      fax: data.fax,
      email: data.email,
      address: data.address,
      working_hrs: data.working_hrs,
      role_id: data?.roles?.length > 0 ? data.roles[0].id : "",
      role_id_ref: {
        label: data?.roles?.length > 0 ? data.roles[0].name : "",
        value: data?.roles?.length > 0 ? data.roles[0].id : "",
      },
      time_zone: data.timezone,
      time_zone_ref: {
        label: data.timezone,
        value: data.timezone,
      },
      status: data.status,
    });
    setDataProcessing(false);
  };

  useEffect(() => {
    checkTypeError();
    let errorCountGi = 0;
    let errorCountOi = 0;
    let errorCountAi = 0;
    if (Object.keys(formError).length > 0) {
      //GI Error
      formError.first_name && (errorCountGi += 1);
      formError.last_name && (errorCountGi += 1);
      formError.phone && (errorCountGi += 1);
      formError.office_number && (errorCountGi += 1);
      formError.dob && (errorCountGi += 1);
      formError.email && (errorCountGi += 1);
      //OI Errors
      formError.department_id && (errorCountOi += 1);
      formError.designation_id && (errorCountOi += 1);
      formError.company_id && (errorCountOi += 1);
      formError.working_hrs && (errorCountOi += 1);
      //AI Errors
      formError.username && (errorCountAi += 1);
      formError.password && (errorCountAi += 1);
      formError.c_password && (errorCountAi += 1);
      formError.role_id && (errorCountAi += 1);
      formError.time_zone && (errorCountAi += 1);
    }

    setGiIndicateErrors(errorCountGi);
    setOiIndicateErrors(errorCountOi);
    setAiIndicateErrors(errorCountAi);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  useEffect(() => {
    if (giIndicateErrors) {
      general_tap.current.click();
    } else if (oiIndicateErrors) {
      Organizational_Tap.current.click();
    } else if (aiIndicateErrors) {
      Account_Tap.current.click();
    } else {
      setActiveTab(activeTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giIndicateErrors, oiIndicateErrors, aiIndicateErrors]);

  return (
    <Card className="flex-fill mt-3 mb-3">
      <DataProcessing show={dataProcessing} />
      <Card.Body>
        <Row>
          <Col md={3} xl={3} xxl={2}>
            <section className="profile-image-section p-0 d-flex justify-content-center">
              <Image
                className="custom-profile-image-crm"
                src={previewURL ?? ProfileImage}
                bsPrefix
                alt="?"
                roundedCircle
                height={140}
                width={140}
              />
            </section>
            <section className="profiel-content row p-0 pt-3">
              <span
                className="fs-1 mb-0 text-uppercase d-flex justify-content-center"
                style={{ fontSize: "18px" }}
              >
                {formData.first_name ? formData.first_name : "Name"}
              </span>
              <span className="d-flex justify-content-center">
                {formData.designation_name
                  ? formData.designation_name
                  : "Designation"}
              </span>
              <span className="d-flex justify-content-center mb-2">
                {formData.email ? formData.email : "Email"}
              </span>
            </section>
            <div className="orginisation-info mt-3">
              <span className="d-flex justify-content-center">
                {formData.department_name &&
                  "Department: " + formData.department_name}
              </span>
              <span className="d-flex justify-content-center mt-2">
                {formData.company_name &&
                  "Organization: " + formData.company_name}
              </span>
            </div>
          </Col>
          <Col md={9} xl={9} xxl={10}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="General">
              <Row>
                <Col sm={12}>
                  <Nav
                    variant="pills"
                    className="crm-employee-nav flex-row"
                    justify
                  >
                    <Nav.Item className="crm-employee-nav-item">
                      <Nav.Link
                        className="crm-employee-nav-link"
                        eventKey="General"
                        name="General"
                        ref={general_tap}
                        onClick={handleTabChange}
                      >
                        <div
                          className="d-inline"
                          id="General"
                          onClick={(e) => {
                            handleTabChange(e);
                          }}
                        >
                          General Information
                        </div>
                        {giIndicateErrors > 0 && (
                          <div className="d-inline">
                            <Badge
                              pill
                              bg="danger"
                              className="ms-1"
                              id="General"
                              onClick={(e) => {
                                handleTabChange(e);
                              }}
                            >
                              {giIndicateErrors}
                            </Badge>
                          </div>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="crm-employee-nav-item">
                      <Nav.Link
                        className="crm-employee-nav-link"
                        eventKey="Organizational"
                        name="Organizational"
                        ref={Organizational_Tap}
                        onClick={handleTabChange}
                      >
                        <div
                          className="d-inline"
                          id="Organizational"
                          onClick={(e) => {
                            handleTabChange(e);
                          }}
                        >
                          Organizational Information
                        </div>

                        {oiIndicateErrors > 0 && (
                          <div className="d-inline">
                            <Badge
                              pill
                              className="ms-1"
                              bg="danger"
                              id="Organizational"
                              onClick={(e) => {
                                handleTabChange(e);
                              }}
                            >
                              {oiIndicateErrors}
                            </Badge>
                          </div>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="crm-employee-nav-item">
                      <Nav.Link
                        className="crm-employee-nav-link"
                        eventKey="Account"
                        name="Account"
                        ref={Account_Tap}
                        onClick={handleTabChange}
                      >
                        <div
                          className="d-inline"
                          id="Account"
                          onClick={(e) => {
                            handleTabChange(e);
                          }}
                        >
                          Account Information
                        </div>

                        {aiIndicateErrors > 0 && (
                          <div className="d-inline">
                            <Badge
                              pill
                              className="ms-1"
                              bg="danger"
                              id="Account"
                              onClick={(e) => {
                                handleTabChange(e);
                              }}
                            >
                              {aiIndicateErrors}
                            </Badge>
                          </div>
                        )}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey={activeTab}>
                      <EmployeeForm
                        section={activeTab}
                        formData={formData}
                        setFormData={setFormData}
                        formError={formError}
                        setAcFormError={setAcFormError}
                        acFormError={acFormError}
                        setGeFormError={setGeFormError}
                        geFormError={geFormError}
                        setOgFormError={setOgFormError}
                        ogFormError={ogFormError}
                        setPreviewURL={setPreviewURL}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-top d-flex flex-row gap-2 justify-content-start">
        <Form.Group className="d-flex justify-content-end">
          <SaveButton handleSubmit={handleSubmit} onSave={onSave} id={itemId} />
        </Form.Group>
        <Form.Group>
          <CancelButton handleCancel={handleCancel} onSave={onSave} />
        </Form.Group>
      </Card.Footer>
    </Card>
  );
}

export default EmployeeProfile;
