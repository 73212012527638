const deleteReducer = (state, action) => {
    switch (action.type) {
      case 'CONFIG':
        return { ...action.payload };
  
      case 'RESET':
        return {};
  
      default:
        return state;
    }
  };
  export default deleteReducer;
  