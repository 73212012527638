import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

const LoadingScreen = ({ message }) => {
  return (
    <div className="d-flex flex-column m-auto">
      {message && <h6 className="text-center text-secondary">{message}</h6>}

      <div className="d-flex m-auto">
        {['primary', 'secondary', 'success'].map(varient => (
          <Spinner
            key={varient}
            animation="grow"
            variant={varient}
            className="m-1"
            size="sm"
          />
        ))}
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  message: PropTypes.string
};

export default LoadingScreen;
