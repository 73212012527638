import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { announcementFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import { FaTag } from "react-icons/fa";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import ListLoading from "module/common/ListLoading";

const AnnouncementForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(announcementFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { title, date_range_for, from_date, to_date, inactive } = allQueryParams;
  const isParamsAvailable = title || date_range_for || from_date || to_date || inactive

  const handleFieldChange = (e, action) => {
    let newOldData;
    if (action) {
      newOldData = {
        ...formData,
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    } else {
      if (e.target.name === "file") {
        newOldData = {
          ...formData,
          [e.target.name]: e.target.files[0],
        };
      } else if (e.target.name === "inactive") {
        newOldData = {
          ...formData,
          [e.target.name]: e.target.checked === false ? 0 : 1,
        };
      } else {
        newOldData = {
          ...formData,
          [e.target.name]:
            e.target.type === "checkbox"
              ? !e.target.checked
                ? 0
                : 1
              : e.target.value,
        };
      }
      setFormData(newOldData);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/announcement${itemId ? "/" + itemId : ""}`,
      data: formDataWithoutRefkeys,
      headers: {
        "Content-Type": !itemId ? "multipart/form-data" : "application/json",
      },
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          navigate(itemId ? -1 : `/announcement`);
          (!isParamsAvailable || itemId) && getData(itemId ? pageNumber : 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
              error?.response?.data?.data &&
              error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/announcement/" + itemId,
      params: {
        page: page,
      },
    });

    setFormData({
      title: data?.title,
      description: data?.description,
      start_date: data?.start_date,
      end_date: data?.end_date,
      file: data?.file,
      inactive: data?.inactive,
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <FaTag size={20} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {itemId ? "Update Announcement" : "Create Announcement"}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                onChange={handleFieldChange}
                value={formData?.title}
                placeholder="Enter Label Name"
                isInvalid={!!formError?.title}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.title}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="require-data">Start Date</Form.Label>
              <AppDatePicker
                name="start_date"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
                value={formData?.start_date}
                isInvalid={!!formError?.start_date}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.start_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label className="require-data">End Date</Form.Label>
              <AppDatePicker
                name="end_date"
                yearPlaceholder="yyyy"
                monthPlaceholder="mm"
                dayPlaceholder="dd"
                onChange={handleFieldChange}
                value={formData?.end_date}
                isInvalid={!!formError?.end_date}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.end_date}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label className="require-data">Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                onChange={handleFieldChange}
                value={formData?.description}
                isInvalid={!!formError?.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.description}
              </Form.Control.Feedback>
            </Form.Group>
            {!itemId && (
              <Form.Group controlId="formFile" className="mb-3">
                <Form.Label>File</Form.Label>
                <Form.Control
                  name="file"
                  type="file"
                  onChange={handleFieldChange}
                />
                <Form.Control.Feedback type="invalid">
                  {formError?.file}
                </Form.Control.Feedback>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Inactive</Form.Label>
              <Form.Check
                type="switch"
                name="inactive"
                className="custom-common-switch"
                id="custom-switch"
                onChange={handleFieldChange}
                checked={formData?.inactive === 0 ? false : true}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.inactive}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AnnouncementForm;
