import React, { useState } from "react";
import { Button, Card, Form, Image, Spinner } from "react-bootstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Banner from "../../assets/img/gallery/enquiryBanner.png";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { enquiryFormKeys } from "helpers/formKeys";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import PhoneNumberInput from "components/form/PhoneNumberInput";

const EnquiryForm = () => {
  const recaptchaRef = React.createRef();
  const [formData, setFormData] = useState(enquiryFormKeys);
  const [formError, setFormError] = useState({});
  const [responseMessage, setResponseMessage] = useState(null);
  const [onSave, setOnSave] = useState(false);

  const handleFieldChange = (e) =>
    setFormData(generateFormDataHelper(e, null, formData));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    setFormError({});
    setResponseMessage(null);

    setOnSave(true);
    axios({
      method: "post",
      url: `enquire`,
      data: {
        ...formData,
        recaptcha: captchaToken,
      },
    })
      .then((response) => {
        if (response.data.success === true) {
          setResponseMessage(response.data.message);
          setFormData({
            email: "",
            message: "",
            name: "",
            phone: "",
            recaptcha: "",
          });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <>
      <div className="min-vh-100">
        <Card className="pb-3">
          <Card.Header className="d-flex flex-column align-items-center justify-content-center">
            <span>
              <Image src={Banner} height={150} width={250} />
            </span>
            <span className="fs-1 fw-bold pt-3">ENQUIRY</span>
          </Card.Header>
          <Card.Body className="">
            <div className="">
              <div className="row justify-content-center">
                <div className="col-lg-9">
                  <Form>
                    <div className="row g-3">
                      <Form.Group className="col-md-6">
                        <Form.Label className="require-data">Name</Form.Label>
                        <Form.Control
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleFieldChange}
                          isInvalid={!!formError.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="col-md-6">
                        <Form.Label>Contact Number</Form.Label>
                        <PhoneNumberInput
                          name="phone"
                          onChange={handleFieldChange}
                          value={formData.phone}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="col-md-12">
                        <Form.Label className="require-data">Email</Form.Label>
                        <Form.Control
                          type="email"
                          className="form-control"
                          value={formData.email}
                          id="email"
                          name="email"
                          onChange={handleFieldChange}
                          isInvalid={!!formError.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <Form.Label className="require-data">
                          Message
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          className="form-control"
                          id="message"
                          name="message"
                          rows="5"
                          value={formData.message}
                          onChange={handleFieldChange}
                          isInvalid={!!formError.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="col-12">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          size="invisible"
                          ref={recaptchaRef}
                        />
                        {formError?.recaptcha && (
                          <span style={{ color: "#e63757", fontSize: "75%" }}>
                            {formError.recaptcha}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Label style={{ color: "green" }}>
                        {responseMessage}
                      </Form.Label>
                      <div className="col-12">
                        <div className="row">
                          <Form.Group className="col-md-6">
                            <Button
                              onClick={handleSubmit}
                              variant="dark"
                              className="w-50 fw-bold"
                            >
                              {onSave ? <Spinner size="sm" /> : "Send"}
                            </Button>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default EnquiryForm;
