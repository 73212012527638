import DoughnutChart from "module/common/Charts/DoughnutChart";
import ListLoading from "module/common/ListLoading";
import React, { useLayoutEffect, useState } from "react";
import { Card, Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";

const TicketDashboardHeader = ({ headersData, loading }) => {
  const [data, setData] = useState({});

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    cutout: "85%",
  };

  useLayoutEffect(() => {
    if (Object.keys(headersData).length > 0) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headersData]);

  const getChartData = () => {
    let labels = [];
    if (Object.keys(headersData).length > 0) {
      setData({
        datasets: [
          {
            data: Object.keys(headersData.status_count)
              .filter((items) => items !== "all")
              .map((item) => {
                let refArray = {
                  name: item,
                  value:
                    (headersData.status_count[item] /
                      headersData.total_ticket_count) *
                    100,
                  actual_value: headersData.status_count[item],
                };
                labels.push(
                  item === "awaiting_business_input"
                    ? "Await"
                    : item === "with_client_review"
                    ? "Review"
                    : item.charAt(0).toUpperCase() + item.slice(1)
                );
                return refArray;
              }),
            backgroundColor: [
              "#b0b0b0",
              "#0066ff",
              "#5cd65c",
              "#ff3333",
              "#ff99cc",
              "#ffcc00",
              "#8c1aff",
            ],
            hoverBackgroundColor: [
              "#b0b0b0",
              "#0066ff",
              "#5cd65c",
              "#ff3333",
              "#ff99cc",
              "#ffcc00",
              "#8c1aff",
            ],
          },
        ],
        labels: labels,
      });
    }
  };

  return (
    <Row className="pe-2 ps-2">
      <Col lg={5} md={12} className="pe-1 ps-1 d-flex">
        <Card className="flex-fill">
          <Card.Body>
            {Object.keys(headersData).length > 0 && !loading ? (
              <>
                <div className="d-flex">
                  <span className="fs--1 fw-bold">Total Tickets</span>
                  <span className="fw-bold ticket-total-span ticket-larger-text ms-2">
                    {headersData.total_ticket_count}
                  </span>
                </div>
                <div className="d-flex align-items-center mt-2">
                  {Object.keys(data).length > 0 && (
                    <div className="d-inline">
                      <DoughnutChart
                        {...{
                          height: "7.3vh",
                          width: "7.3vh",
                          data: data,
                          options: options,
                        }}
                      />
                    </div>
                  )}

                  <div className="d-inline overflow-auto">
                    <ListGroup
                      horizontal
                      className="text-nowrap custom-dashboard-list-group"
                    >
                      {Object.keys(headersData.status_count).map(
                        (item, key) => (
                          <ListGroupItem
                            className="border-0 border-end rounded-0 custom-dashboard-list-group-item"
                            key={key}
                          >
                            <div className="">
                              <span className="d-block ticket-custom-span-head text-capitalize">
                                {item === "awaiting_business_input"
                                  ? "Await"
                                  : item === "with_client_review"
                                  ? "Review"
                                  : item}
                              </span>
                              <span
                                className={`d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1 ${
                                  item === "new"
                                    ? "ticket-total-critical-content"
                                    : ""
                                }`}
                              >
                                {headersData.status_count[item]}
                              </span>
                            </div>
                          </ListGroupItem>
                        )
                      )}
                    </ListGroup>
                  </div>
                </div>
              </>
            ) : (
              <div style={{ maxHeight: "10.4vh", overflow: "hidden" }}>
                <ListLoading />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col lg={4} md={12} className="pe-1 ps-1 d-flex">
        <Card className="flex-fill">
          <Card.Body>
            {Object.keys(headersData).length > 0 && !loading ? (
              <>
                <Card.Title className="d-flex ticket-custom-sub-card-title">
                  <span className="fs--1 fw-bold">Open Tickets</span>
                </Card.Title>
                <div className="overflow-auto pt-2">
                  <ListGroup horizontal className=" text-nowrap">
                    <ListGroupItem className="border-0 ps-1">
                      <div className="border-end pe-3">
                        <span className="d-block ticket-custom-span-head">
                          Total Unassigned
                        </span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text ticket-total-critical-content fw-bold mt-1">
                          {headersData.unassigned_open_tickets}
                        </span>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-1">
                      <div className="border-end pe-3">
                        <span className="d-block ticket-custom-span-head">
                          Total Assigned
                        </span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1">
                          {headersData.assigned_open_tickets}
                        </span>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-1">
                      <div className="border-end pe-3">
                        <span className="d-block ticket-custom-span-head">
                          My Tickets
                        </span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1">
                          {headersData.my_tickets}
                        </span>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-1">
                      <div>
                        <span className="d-block">Created</span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1">
                          {headersData.tickets_by_me}
                        </span>
                      </div>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </>
            ) : (
              <div style={{ maxHeight: "10.4vh", overflow: "hidden" }}>
                <ListLoading />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
      <Col lg={3} md={12} className="pe-1 ps-1 d-flex">
        <Card className="flex-fill">
          <Card.Body>
            {!loading ? (
              <>
                <Card.Title className="d-flex ticket-custom-sub-card-title">
                  <span className="fs--1 fw-bold">SLA Warning</span>
                </Card.Title>
                <div className="overflow-auto pt-2">
                  <ListGroup horizontal className=" text-nowrap">
                    <ListGroupItem className="border-0 ps-1">
                      <div className="border-end pe-3">
                        <span className="d-block">Under SLA</span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text ticket-total-critical-content fw-bold mt-1">
                          175
                        </span>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-1">
                      <div className="border-end pe-3">
                        <span className="d-block">Within SLA</span>
                        <span className="d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1">
                          630
                        </span>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 ps-1">
                      <span className="d-block">Over SLA</span>
                      <span className="d-block d-flex justify-content-center ticket-larger-text fw-bold mt-1">
                        400
                      </span>
                    </ListGroupItem>
                  </ListGroup>
                </div>
              </>
            ) : (
              <div style={{ maxHeight: "10.4vh", overflow: "hidden" }}>
                <ListLoading />
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default TicketDashboardHeader;
