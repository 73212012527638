import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Tab, Tabs } from "react-bootstrap";
import "./TicketTabs.scss";
import TicketTable from "../TicketTable/TicketTable";
import FilterButton from "components/filter/FilterButton";
import {
  Link,
  Outlet,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import TabLoading from "module/common/TabLoading";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import qs from "qs";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AppPagination from "components/common/app-pagination/AppPagination";
import ListLoading from "module/common/ListLoading";
import { DeleteContext } from "context/Context";
import { ticketFilters } from "module/common/helpers/ticketsCommonFilters";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import removeEmptyFields from "helpers/removeEmptyFields";
import { AddButton } from "module/common/Buttons/AppButtons";
import ExportButton from "components/export-button/ExportButton";
import CommonTicketFilter from "../SupportingComponets/CommonTicketFilter";
import { BiSupport } from "react-icons/bi";

const TicketTabs = ({ projectId, renderFrom }) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [dataLoading, setDataLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const filterItems = queryParams.get("filters");
  const activeTab = queryParams.get(projectId ? "status" : "tab");
  let navigate = useNavigate();
  const pageNumber = queryParams.get("page");
  const { dispatch } = useContext(DeleteContext);
  const [filters] = useState(ticketFilters);
  const [statusLoading, setStatusLoading] = useState(false);
  const [ticketStatus, setTicketStatus] = useState({});
  const [filterFields, setFilterFields] = useState({});
  const location = useLocation();
  const axisProPermission = useAxisproPermission();
  const [storePermission] = ["store-ticket"].map((permission) =>
    axisProPermission(permission)
  );
  const [filterVisibility, setFilterVisibility] = useState(true);
  const {
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    assignee,
    priority,
    type,
    title,
    origin,
    status,
  } = allQueryParams;

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/ticket/" + data,
        title: "Delete Ticket",
        message: "Are you sure you want to delete this ticket?",
        onSuccess: () => {
          const dataWithoutDeletedItem = ticketData.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/tickets/list?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else {
            setTicketData((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            getDataItem(pageNumber);
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getDataItem = async (filterParamObject) => {
    setStatusLoading(true);
    try {
      const data = await apiCall({
        url: "crm/ticket-summary",
        params: removeEmptyFields({ ...filterParamObject, status: "" }),
      });

      setTicketStatus(data);
      setStatusLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!location.pathname.includes("overview")) {
      handleTabs(activeTab);
    }
    if (activeTab) {
      getData(pageNumber);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    filterItems,
    activeTab,
    date_range,
    date_range_for,
    title,
    project_id,
    priority,
    assignee,
    type,
    origin,
    status,
  ]);

  const getData = async (page = 1) => {
    setDataLoading(true);
    const dynamicFilterTemp = filterItems
      ? removeRefData(createFilterArray(filterItems), "array")
      : null;

    const filterParamObject = {
      page: page ? page : pageNumber,
      filters: dynamicFilterTemp,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      title: title,
      project_id: projectId ? projectId : project_id,
      assignee: assignee,
      priority: priority,
      type: type,
      status: activeTab && activeTab !== "all" ? activeTab : "",
      origin: origin,
    };
    setFilterFields(filterParamObject);
    getDataItem(filterParamObject);
    try {
      const data = await apiCall({
        url: "crm/ticket",
        params: removeEmptyFields(filterParamObject),
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setTicketData(data);
      setDataLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabs = (status) => {
    if (projectId) {
      navigate(`/project/info/${projectId}?tab=tickets&status=all`);
    } else {
      navigate(
        "/tickets/list?" +
          createSearchParams({
            ...allQueryParams,
            tab: status ? status : "all",
          })
      );
    }
  };

  return (
    <>
      <Outlet context={[getData]} />
      {!location.pathname.includes("overview") && (
        <Card>
          <Card.Header className="p-0">
            <div className="d-flex flex-row justify-content-between align-items-center gap-1 p-3">
              <div className="d-flex align-items-center">
                <BiSupport size={25} className="me-2 text-dark" />
                <span className="custom-module-header">TICKET LIST</span>
              </div>
              <div className="d-flex gap-1">
                {axisProPermission("export-ticket") && (
                  <ExportButton
                    url="crm/ticket-export"
                    initialMessage={"Ticket export started"}
                    successMessage={"Ticket export completed"}
                    file_type={"pdf"}
                    variant={"danger"}
                    filters={filterFields}
                  />
                )}
                {storePermission && (
                  <AddButton
                    className="shadow"
                    route={
                      projectId
                        ? `/project/info/${projectId}/ticket/add?tab=tickets&status=all`
                        : `add?${createSearchParams({
                            ...allQueryParams,
                          })}`
                    }
                    variant="success"
                    title="Add New Ticket"
                  />
                )}
                <FilterCommonButton
                  filterVisibility={filterVisibility}
                  setFilterVisibility={setFilterVisibility}
                />
              </div>
            </div>
            <div style={{ display: filterVisibility ? "block" : "none" }}>
              <CommonTicketFilter renderFrom={renderFrom} />
            </div>
          </Card.Header>
          <Card.Body className="p-0">
            {statusLoading ? (
              <>
                <TabLoading />
                <ListLoading />
              </>
            ) : (
              <Tabs
                id="ticket-custom-tabs"
                variant="pills"
                activeKey={activeTab}
                onSelect={handleTabs}
                className="ticket-custom-tabs"
                justify
              >
                {Object.keys(ticketStatus).length > 0 &&
                  Object.keys(ticketStatus.status_count).map((status, key) => (
                    <Tab
                      style={{ minHeight: "75vh" }}
                      key={key}
                      eventKey={status}
                      title={
                        <div className="d-grid">
                          <span className="fs-1">
                            {ticketStatus.status_count[status]}
                          </span>
                          <span className="text-capitalize">
                            {status === "awaiting_business_input"
                              ? "Await"
                              : status === "with_client_review"
                              ? "Review"
                              : status}
                          </span>
                        </div>
                      }
                      className="ticket-custom-tab w-100 overflow-auto"
                    >
                      {!dataLoading && Object.keys(ticketData).length > 0 ? (
                        (activeTab === status || projectId) && (
                          <TicketTable
                            data={ticketData}
                            handleDelete={handleDelete}
                            renderFrom={renderFrom}
                          />
                        )
                      ) : (
                        <LoadingScreenCover style={{ minHeight: "78vh" }}>
                          {dataLoading ? (
                            <LoadingCommon
                              loadingText={`Looking for ${
                                activeTab
                                  ? activeTab === "awaiting_business_input"
                                    ? "Await"
                                    : activeTab === "with_client_review"
                                    ? "Review"
                                    : activeTab === "all"
                                    ? ""
                                    : activeTab.charAt(0).toUpperCase() +
                                      activeTab.slice(1)
                                  : ""
                              } Tickets`}
                            />
                          ) : (
                            <h5 className="text-center text-secondary">
                              Ticket not found! Let's add it &#128578;
                            </h5>
                          )}
                          {!dataLoading && (
                            <div className="d-flex flex-wrap justify-content-center mt-3">
                              {storePermission && (
                                <Button
                                  className="m-1"
                                  size="sm"
                                  as={Link}
                                  to="/tickets/list/add"
                                >
                                  Add Ticket
                                </Button>
                              )}

                              {filterItems ? (
                                <FilterButton
                                  showText
                                  filters={filters}
                                  url="project"
                                  className="m-1"
                                  variant="warning"
                                />
                              ) : null}
                            </div>
                          )}
                        </LoadingScreenCover>
                      )}
                    </Tab>
                  ))}
              </Tabs>
            )}
          </Card.Body>
          {!dataLoading && (
            <Card.Footer className="p-1 pt-2">
              {ticketData && ticketData.links && (
                <AppPagination
                  data={ticketData}
                  url="/tickets/list"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          )}
        </Card>
      )}
    </>
  );
};

export default TicketTabs;
