import React, { useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

const CreatableSelectCompany = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const fetchOptions = async (inputValue) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `crm/lead-company-autocomplete?name=${inputValue}`
      );
      const data = response.data;
      const initialOptions = data?.data.map((item) => ({
        label: item.company_name,
        value: item.company_name,
      }));

      return initialOptions;
    } catch (error) {
      console.error("Error preloading options:", error);
    } finally {
      setLoading(false);
    }
  };

  const mergeArrays = (options, preloadedOptions) => {
    const optionsSet = new Set(options.map((item) => JSON.stringify(item)));
    const preloadedOptionsSet = new Set(
      preloadedOptions.map((item) => JSON.stringify(item))
    );
    const mergedOptionsSet = new Set([...optionsSet, ...preloadedOptionsSet]);
    const mergedArray = Array.from(mergedOptionsSet).map((item) =>
      JSON.parse(item)
    );

    return mergedArray;
  };

  const preloadOptions = async (rst = false) => {
    let loadedValue = value?.value ? value?.value : "";
    if (rst === true) {
      loadedValue = "";
    }
    const preloadedOptionsRes = await fetchOptions(loadedValue);
    const mergedArray =
      rst === true
        ? preloadedOptionsRes
        : mergeArrays(options, preloadedOptionsRes);
    setOptions(mergedArray);
  };

  const loadOptions = async (inputValue) => {
    setInputValue(inputValue);
    if (!inputValue.trim()) {
      return [];
    }
    const preloadedOptionsContainInput = options.some((option) =>
      option?.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (preloadedOptionsContainInput) {
      return options;
    }

    const fetchedOptions = await fetchOptions(inputValue);
    const mergedArray = mergeArrays(options, fetchedOptions);

    setOptions(mergedArray);
    return fetchedOptions;
  };

  const handleOptionsChange = async (e, action) => {
    handleFieldChange(e, action);
    if (action?.action === "clear") {
      setOptions([]);
      preloadOptions(true);
    }
  };

  return (
    <CreatableSelect
      cacheOptions
      allowCreateWhileLoading={false}
      loadOptions={loadOptions}
      options={options}
      isClearable
      name={name || "company"}
      value={value}
      inputValue={inputValue}
      placeholder={placeholder || ""}
      onChange={(e, action) => {
        handleOptionsChange(e, action);
      }}
      onMenuOpen={preloadOptions}
      isLoading={loading}
      className={error ? "form-control ps-0 py-0 is-invalid" : "ps-0 pe-0 py-0"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
      onInputChange={loadOptions}
    />
  );
};

CreatableSelectCompany.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default CreatableSelectCompany;
