import { React, useEffect, useState } from "react";
import {
  Card,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Tab,
  Tabs,
  //   Image,
  Row,
  Col,
  Button,
} from "react-bootstrap";

import {
  FaBuilding,
  FaEnvelopeSquare,
  FaInstalod,
  FaPhoneSquareAlt,
  FaUser,
} from "react-icons/fa";

import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import useAxisproPermission from "hooks/useAxisproPermission";
import axios from "axios";
import LeadStage from "./Overview/LeadStage";
import {
  MdBusinessCenter,
  MdOutlineIncompleteCircle,
  MdPriorityHigh,
  MdSource,
} from "react-icons/md";
import { FaRegShareSquare } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import Contacts from "module/common/ViewPages/Common/Contacts/Contacts";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import Calls from "module/common/ViewPages/Common/Calls/Calls";
import Meetings from "module/common/ViewPages/Common/Meetings/Meetings";
import Activity from "./Overview/Activity";
import bg1 from "assets/img/icons/spot-illustrations/corner-1.png";
import bg2 from "assets/img/icons/spot-illustrations/corner-3.png";
import bg3 from "assets/img/icons/spot-illustrations/corner-2.png";

function LeadOffcanvas() {
  const navigate = useNavigate();
  const [leadData, setLeadData] = useState({});
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const { overviewId } = useParams();

  const fetchData = async () => {
    setLoading(true);
    axios
      .get("crm/lead/" + overviewId)
      .then((res) => {
        if (res.data.success) {
          setLeadData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (overviewId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  return (
    <Offcanvas
      show={true}
      onHide={() => navigate(-1)}
      placement="end"
      style={{
        width: "70vh",
      }}
    >
      <Offcanvas.Body className="p-0">
        {Object.keys(leadData).length > 0 && !loading ? (
          <>
            <div>
              <div
                className="d-flex justify-content-between p-3 pb-0 pt-2
                align-items-center border-bottom"
              >
                <div className="d-flex align-items-center pt-1 pb-1">
                  <span className="custom-module-header text-uppercase">
                    {leadData.title}
                  </span>
                  <span className="ms-2 pb-2">
                    <Button
                      size="sm"
                      variant="transperant"
                      className="custom-transperent-common-button p-0"
                      as={Link}
                      to={`/leads/lead/info/${overviewId}`}
                    >
                      <FaRegShareSquare size={14} />
                    </Button>
                  </span>
                </div>
                <button
                  type="button"
                  className="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => navigate(-1)}
                ></button>
              </div>
              <div
                className="p-3 pt-4 border-bottom"
                style={{ backgroundColor: "#eaecfb" }}
              >
                <Row className="gy-3">
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.is_company ? "Company" : "Individual"}
                      label={"Lead Type"}
                      variant="info"
                      icon={
                        <MdOutlineIncompleteCircle
                          size={17}
                          className="me-2 text-mute"
                        />
                      }
                      image={bg1}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.lead_source_name}
                      label={"Lead Source"}
                      variant="success"
                      icon={<MdSource size={17} className="me-2 text-mute" />}
                      image={bg3}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.lead_status}
                      label={"Lead Status"}
                      variant={
                        leadData.lead_status === "Lead initiated"
                          ? "warning"
                          : leadData.lead_priority === "Not Qualified"
                          ? "danger"
                          : leadData.lead_priority === "Lost Lead"
                          ? "danger"
                          : leadData.lead_priority === "Contacted"
                          ? "info"
                          : "success"
                      }
                      icon={<FaInstalod size={17} className="me-2 text-mute" />}
                      image={bg2}
                    />
                  </Col>
                  <Col xs={6} md={4}>
                    <LeadStage
                      data={leadData.lead_priority}
                      label={"Lead Priority"}
                      variant={
                        leadData.lead_priority === "high"
                          ? "danger"
                          : leadData.lead_priority === "medium"
                          ? "warning"
                          : "primary"
                      }
                      icon={
                        <MdPriorityHigh size={17} className="me-2 text-mute" />
                      }
                      image={bg2}
                    />
                  </Col>
                  <Col xs={6} md={5}>
                    <LeadStage
                      data={leadData.business_current_status}
                      label={"Business Current Status"}
                      variant="success"
                      icon={
                        <MdBusinessCenter
                          size={17}
                          className="me-2 text-mute"
                        />
                      }
                      image={bg3}
                    />
                  </Col>
                </Row>
              </div>
            </div>
            <Tabs
              defaultActiveKey="info"
              className="custom-lead-offcanvas-header-tabs"
              variant="tabs"
              justify
            >
              <Tab
                eventKey="info"
                title={<span className="fw-bold">Info</span>}
              >
                <Card className="card-user-profile">
                  <Card.Body>
                    <div className="">
                      <ListGroup className="border rounded-0 p-2 pe-3 ps-3">
                        <ListGroupItem className="ps-0 pb-2 border-0 text-dark  fw-bold">
                          Lead Informations
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-2 pt-1 ">
                          <FaUser size={16} className="me-1" />
                          Lead Owner :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.lead_owner_name
                              ? leadData.lead_owner_name
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-2">
                          <FaPhoneSquareAlt size={16} className="me-1" />
                          Mobile Number :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.phone_no ? leadData.phone_no : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-2">
                          <FaEnvelopeSquare size={16} className="me-1" />
                          Email Address :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.email ? leadData.email : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-2">
                          <FaBuilding size={16} className="me-1" />
                          Company Name :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.company_name
                              ? leadData.company_name
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-2">
                          <FaBuilding size={16} className="me-1" />
                          Industry :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.industry ? leadData.industry : "N/A"}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                      <ListGroup className="border rounded-0 p-2 mt-3 pe-3 ps-3">
                        <ListGroupItem className="ps-0 pb-2 border-0 text-dark  fw-bold">
                          Primary Contact Information
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-2 pt-1">
                          <FaUser size={16} className="me-1" />
                          Name :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.primary_contact[0].full_name
                              ? leadData.primary_contact[0].full_name
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pt-1 pb-2">
                          <FaEnvelopeSquare size={16} className="me-1" />
                          Email Address :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.primary_contact[0].email
                              ? leadData.primary_contact[0].email
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="border-0 ps-0 pb-1 pt-1 pb-2">
                          <FaPhoneSquareAlt size={16} className="me-1" />
                          Contact Number :{" "}
                          <span className="text-dark fw-bold">
                            {leadData.primary_contact[0].phone_no
                              ? leadData.primary_contact[0].phone_no
                              : "N/A"}
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                    </div>
                  </Card.Body>
                </Card>
              </Tab>
              {axisProPermission("list-lead-contact") && (
                <Tab
                  eventKey="contacts"
                  title={<span className="fw-bold">Contacts</span>}
                >
                  <Contacts type="LEAD" itemId={overviewId} overview={"lead"} />
                </Tab>
              )}
              {axisProPermission("list-lead-call") && (
                <Tab
                  eventKey="calls"
                  title={<span className="fw-bold">Calls</span>}
                >
                  <Calls type="LEAD" itemId={overviewId} overview={"lead"} />
                </Tab>
              )}
              {axisProPermission("list-lead-meeting") && (
                <Tab
                  eventKey="meeting"
                  title={<span className="fw-bold">Meeting</span>}
                >
                  <Meetings type="LEAD" itemId={overviewId} overview={"lead"} />
                </Tab>
              )}
              <Tab
                eventKey="attachment"
                title={<span className="fw-bold">Attachment</span>}
              >
                <CommonAttachments type="LEAD" itemId={overviewId} />
              </Tab>
              <Tab
                eventKey="activity"
                title={<span className="fw-bold">Activity</span>}
              >
                <Activity data={leadData} />
              </Tab>
            </Tabs>
          </>
        ) : loading ? (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <LoadingScreen message="Fetching Data..." />
          </div>
        ) : (
          <div className="d-flex h-100 justify-content-center align-items-center">
            <span>No User Data Available</span>
          </div>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default LeadOffcanvas;
