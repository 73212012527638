import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { productsFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import ListLoading from "module/common/ListLoading";
import { FiPackage } from "react-icons/fi";

const ProductsForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(productsFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { name } = allQueryParams;

  const handleFieldChange = (e, action) =>
    setFormData(generateFormDataHelper(e, action, formData));

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/crm-product${itemId ? "/" + itemId : ""}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response?.data?.success === true) {
          showToast(response?.data?.message, "success");
          navigate(itemId ? -1 : "/products");
          (!name || itemId) && getData(itemId ? pageNumber : 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
              error?.response?.data?.data &&
              error?.response?.data?.data?.errors
              ? error?.response?.data?.data?.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/crm-product/" + itemId,
      params: {
        page: page,
      },
    });

    setFormData({
      name: data?.name,
      description: data?.description,
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <FiPackage size={24} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {itemId ? "Update Product" : "Create Product"}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body className="">
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleFieldChange}
                value={formData?.name}
                placeholder="Enter Name"
                isInvalid={!!formError?.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="description"
                onChange={handleFieldChange}
                value={formData?.description}
                isInvalid={!!formError?.description}
              />
              <Form.Control.Feedback type="invalid">
                {formError?.description}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ProductsForm;
