import { Card, ListGroup } from "react-bootstrap";
import SoftBadge from "components/common/SoftBadge";
import { BsDatabaseFillExclamation } from "react-icons/bs";
const ClientTaskSection = ({ taskData }) => {
  return (
    <Card
      className="h-100  p-0"
      style={{
        boxShadow: "none", // Set box shadow to none to hide it
      }}
    >
      <Card.Body className="p-0 w-100">
        <ListGroup className="d-flex flex-column gap-2">
          {taskData?.data?.length > 0 ? (
            taskData?.data.map(
              (items, index) =>
                index < 5 && (
                  <ListGroup.Item className="rounded-0 border" key={index}>
                    <div className="d-flex align-items-between align-items-center">
                      <div>
                        <span className="flex-1 fs--1 text-dark mb-0 ms-2 text-capitalize">
                          {items?.title}
                        </span>
                      </div>
                      <div className="ms-auto">
                        <SoftBadge
                          bg="success"
                          className="rounded-4 p-2 text-capitalize"
                        >
                          {items?.status?.replace("_", " ")}
                        </SoftBadge>
                      </div>
                    </div>
                  </ListGroup.Item>
                )
            )
          ) : (
            <ListGroup.Item className="rounded-0 border">
              <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                <BsDatabaseFillExclamation size={40} className="text-mute" />
                <span> No tasks found!</span>
              </div>
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
};
export default ClientTaskSection;
