import React from "react";
import SelectUsers from "components/form/SelectUsers";
import { Form } from "react-bootstrap";
import SelectLeadSource from "components/form/SelectLeadSource";

const EmployeeLeadSummaryReportForm = ({ filters, handleFilterChange }) => {
  return (
    <div>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Employee</Form.Label>
        <SelectUsers
          name="employee_id"
          handleFieldChange={handleFilterChange}
          value={filters.employee_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Lead Source</Form.Label>
        <SelectLeadSource
          name="lead_source_id"
          handleFieldChange={handleFilterChange}
          value={filters.lead_source_id_ref}
        />
      </Form.Group>
    </div>
  );
};

export default EmployeeLeadSummaryReportForm;
