import { React, useContext, useEffect, useState } from "react";
import {
  NavLink,
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Nav, Collapse } from "react-bootstrap";
import PropTypes from "prop-types";

import AppContext from "context/Context";
import classNames from "classnames";
import NavbarVerticalMenuItem from "./NavbarVerticalMenuItem";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import removeEmptyFields from "helpers/removeEmptyFields";

const CollapseItems = ({ route }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const handleClick = (nav) => {
    if (nav.to) {
      navigate("/" + nav.to);
      setOpen(true);
    } else {
      setOpen(!open);
    }
  };

  useEffect(() => {
    if (route.pathName?.length > 0 && pathname?.length > 0) {
      setOpen(!!pathname.toLowerCase()?.includes(route.pathName.toLowerCase()));
    } else {
      setOpen(
        pathname.split("/")[1].toLocaleLowerCase() ===
          route.name.toLocaleLowerCase()
          ? true
          : false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Nav.Item as="li">
      <Nav.Link
        onClick={() => handleClick(route)}
        className={classNames("dropdown-indicator cursor-pointer pb-0 pe-0", {
          "text-500": !route.active,
        })}
        aria-expanded={open}
      >
        <NavbarVerticalMenuItem route={route} open={open} />
      </Nav.Link>
      <Collapse in={open}>
        <Nav
          className={
            route.children && open
              ? "flex-column nav active-style-with-children"
              : "flex-column nav"
          }
          as="ul"
        >
          <NavbarVerticalMenu routes={route.children} />
        </Nav>
      </Collapse>
    </Nav.Item>
  );
};

CollapseItems.propTypes = {
  route: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.array.isRequired,
    active: PropTypes.bool,
    validatingName: PropTypes.string,
    subName1: PropTypes.string,
    subName2: PropTypes.string,
    subName3: PropTypes.string,
    subName4: PropTypes.string,
    subName5: PropTypes.string,
    subName6: PropTypes.string,
  }),
};

const NavbarVerticalMenu = ({ routes }) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const {
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    project_id_ref,
    sprint_id,
    sprint_id_ref,
    assignee,
    assignee_ref,
    priority,
    priority_ref,
    created_by,
    created_by_ref,
    task_no,
    title,
    unassigned,
  } = allQueryParams;
  //set preapplied search params
  const defaultSearchParams = {
    status_filter: defaultTaskStatusFilter.join("/"),
    date_range: date_range ? date_range : "",
    from_date: from_date ? from_date : "",
    to_date: to_date ? to_date : "",
    date_range_for: date_range_for ? date_range_for : "",
    project_id: project_id ? project_id : "",
    project_id_ref: project_id_ref ? project_id_ref : "",
    sprint_id: sprint_id ? sprint_id : "",
    sprint_id_ref: sprint_id_ref ? sprint_id_ref : "",
    assignee: assignee ? assignee : "",
    assignee_ref: assignee_ref ? assignee_ref : "",
    priority: priority ? priority : "",
    priority_ref: priority_ref ? priority_ref : "",
    created_by: created_by ? created_by : "",
    created_by_ref: created_by_ref ? created_by_ref : "",
    task_no: task_no ? task_no : "",
    title: title ? title : "",
    unassigned: unassigned ? unassigned : "",
    page: 1,
  };
  //added additional params to the default task route
  routes = routes?.map(({ to, ...rest }) => {
    let newTo;
    switch (to) {
      case "tasks/list":
        newTo = `tasks/list?${createSearchParams(
          removeEmptyFields(defaultSearchParams)
        )}`;
        break;
      case "account/list":
        newTo = `account/grid`;
        break;
      case "tickets/list":
        newTo = `tickets/list?tab=all`;
        break;
      default:
        newTo = to;
    }
    return { ...rest, to: newTo };
  });

  const {
    config: { showBurgerMenu },
    setConfig,
  } = useContext(AppContext);

  const handleNavItemClick = () => {
    if (showBurgerMenu) {
      setConfig("showBurgerMenu", !showBurgerMenu);
    }
  };
  return routes?.map((route, index) => {
    if (!route.children) {
      return (
        <div key={index} className="m-0">
          <Nav.Item
            as="li"
            key={route.name}
            onClick={handleNavItemClick}
            className="m-0"
          >
            <NavLink
              end={route.exact}
              to={route.to}
              state={{ open: route.to === "/authentication-modal" }}
              className={({ isActive }) =>
                isActive
                  ? "active active-style nav-link pb-0 pt-0"
                  : "nav-link pb-0"
              }
            >
              <NavbarVerticalMenuItem route={route} />
            </NavLink>
          </Nav.Item>
        </div>
      );
    }
    return (
      <div key={index}>
        <CollapseItems route={route} key={route.name} />
      </div>
    );
  });
};

NavbarVerticalMenu.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(NavbarVerticalMenuItem.propTypes))
    .isRequired,
};

export default NavbarVerticalMenu;
