import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { LeasAddContactFormKeys } from "helpers/formKeys";
import { FaUserPlus } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import { apiCall } from "helpers/apiCalls";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ContactForm from "./ContactForm";
const AddContact = ({ getData, type }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(LeasAddContactFormKeys);
  const [formError, setFormError] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [queryParams] = useSearchParams();
  const show = queryParams.get("add_contact") ? true : false;
  const edit_id = queryParams.get("edit_id");
  const [onSave, setOnSave] = useState(false);
  const { itemId, overviewId } = useParams();
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    formData.subject_id = overviewId ? overviewId : itemId;
    formData.subject_type =
      type === "LEAD" || overviewId ? "LEAD" : "OPPORTUNITY";
    const removeRefDataResults = removeRefData(formData);
    const newFormData = setNewFormData(removeRefDataResults);
    axios({
      method: edit_id ? "put" : "post",
      url: `crm/contacts${edit_id ? "/" + edit_id : ""}`,
      data: newFormData,
      ...(!edit_id && {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    })
      .then((response) => {
        setOnSave(false);
        if (response.data.success === true) {
          getData();
          showToast(response.data.message, "success");
          navigate(-1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        setOnSave(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
  };

  const fetchData = async (edit_id) => {
    setIsLoading(true);
    const data = await apiCall({
      url: `crm/contacts/${edit_id}`,
    });
    setFormData({
      ...formData,
      city: data?.city,
      country: data?.country,
      email: data?.email,
      first_name: data?.first_name,
      last_name: data?.last_name,
      phone_no: data?.phone_no,
      secondary_email: data?.secondary_email,
      state: data?.state,
      website: data?.website,
      zip_code: data?.zip_code,
      address: data?.address,
      is_primary: data?.is_primary_contact,
    });
    setIsLoading(false);
  };
  useEffect(() => {
    if (edit_id && show) {
      fetchData(edit_id);
    } else {
      setFormData({
        subject_type: "",
        subject_id: "",
        first_name: "",
        last_name: "",
        email: "",
        secondary_email: "",
        address: "",
        country: "",
        state: "",
        city: "",
        zip_code: "",
        website: "",
        phone_no: "",
        isCompany: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit_id]);

  return (
    <Modal show={show} size="lg" onHide={() => navigate(-1)}>
      <Modal.Header closeButton style={{ backgroundColor: "#EDF2F9" }}>
        <Modal.Title className="d-flex  align-items-center pt-2 pb-2">
          <FaUserPlus size={18} className="me-2 text-dark" />
          <div className="">{`${edit_id ? "EDIT" : "ADD"} CONTACTS`}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading ? (
          <ContactForm
            handleFieldChange={handleFieldChange}
            formData={formData}
            formError={formError}
            onSave={onSave}
            handleSubmit={handleSubmit}
          />
        ) : (
          <div className="d-flex" style={{ minHeight: "30vh" }}>
            <LoadingScreen message="Loading contact..." />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
AddContact.prototype = {
  show: PropTypes.bool,
};
export default AddContact;
