import { React, useState, useEffect } from "react";
import axios from "axios";
import { Card, Col, Form, Modal, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { HiUserAdd } from "react-icons/hi";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { showToast } from "../Toast/toast";
import { CancelButton, SaveButton } from "../Buttons/CommonButton";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import ErrorAlert from "../ErrorAlert";

function AddSalesman({ show, onHide, setFormData, data }) {
  const Translate = useAxisproTranslate();
  const [salesmanData, setSalesmanData] = useState({
    salesman_name: "",
    provision1: "",
    provision2: "",
    break_point: "",
  });
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});

  const handleFieldChange = (e) => {
    setSalesmanData({
      ...salesmanData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    setSalesmanData({
      ...salesmanData,
      terms: data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: "post",
      url: "sales/salesman",
      data: salesmanData,
    })
      .then((response) => {
        if (response.data.success === true) {
          setSalesmanData({
            salesman_name: "",
            provision1: "",
            provision2: "",
            break_point: "",
          });
          showToast(response.data.message, "success");
          onHide();
          setFormData((prev) => ({
            ...prev,
            salesman_id: response.data.data.id,
            salesman_id_ref: {
              label: response.data.data.salesman_name,
              value: response.data.data.id,
            },
          }));
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          console.log(error);
        }
        setOnSave(false);
      });
  };

  const handleCancel = () => {
    setSalesmanData({
      salesman_name: "",
      provision1: "",
      provision2: "",
      break_point: "",
    });
    onHide();
    setFormError({});
  };

  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      className="modal-with-overlay"
      onHide={handleCancel}
    >
      <Card className="p-0">
        <Modal.Header
          className="d-flex flex-row align-items-center border-bottom"
          closeButton
        >
          <div className="d-flex align-items-center">
            <HiUserAdd size={25} className="text-dark me-2 m-0" />
            <h5 className="modal-form-heading">{Translate("Add Salesman")}</h5>
          </div>
        </Modal.Header>
        <Form>
          <Card.Body className="pb-0">
            {!Object.keys(formError).length === 0 ? (
              <ErrorAlert setFormError={setFormError} formError={formError} />
            ) : (
              ""
            )}
            <Row>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate("Name")}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="text"
                          name="salesman_name"
                          onChange={handleFieldChange}
                          value={salesmanData.salesman_name}
                          autoFocus
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.salesman_name ? (
                          <FormErrorPopover
                            id="salesman_name"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.salesman_name}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate("Provision 1")}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={6} md={6} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="number"
                          min={0}
                          name="provision1"
                          onChange={handleFieldChange}
                          value={salesmanData.provision1}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.provision1 ? (
                          <FormErrorPopover
                            id="provision1"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.provision1}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate("Break Point")}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={6} md={6} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="number"
                          step="0"
                          min={0}
                          name="break_point"
                          onChange={handleFieldChange}
                          value={salesmanData.break_point}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.break_point ? (
                          <FormErrorPopover
                            id="break_point"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.break_point}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group as={Col} md={12} sm={12} className="mb-3">
                <Row>
                  <Col xl={4} md={4} sm={12}>
                    <Form.Label>{Translate("Provision 2")}</Form.Label>
                  </Col>
                  <Col xl={8} md={8} sm={12}>
                    <Row className="d-flex flex-row">
                      <Col xl={6} md={6} sm={11} className="pe-sm-0 flex-2">
                        <Form.Control
                          type="number"
                          min={0}
                          name="provision2"
                          onChange={handleFieldChange}
                          value={salesmanData.provision2}
                        />
                      </Col>
                      <Col
                        xl={1}
                        md={1}
                        sm={1}
                        className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0"
                      >
                        {formError.provision2 ? (
                          <FormErrorPopover
                            id="provision2"
                            totalErrorCount={Object.keys(formError).length}
                            errorMessage={formError.provision2}
                          />
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
            </Row>
          </Card.Body>
          <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
            <SaveButton handleSubmit={handleSubmit} onSave={onSave} />
            <CancelButton
              handleCancel={handleCancel}
              onSave={onSave}
              clear={true}
            />
          </Card.Footer>
        </Form>
      </Card>
    </Modal>
  );
}

AddSalesman.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func,
  setFormData: PropTypes.func,
  data: PropTypes.any,
};

export default AddSalesman;
