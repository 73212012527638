import { React } from "react";
import { Nav } from "react-bootstrap";
import ProfileDropdown from "components/navbar/top/ProfileDropdown";
import NotificationDropdown from "./NotificationDropdown";
import TaskTimerSection from "./TaskTimerSection";
import DownloadCenter from "components/download-center/DownloadCenter";

const TopNavRightSideNavItem = () => {
  return (
    <Nav
      navbar
      className="navbar-nav-icons ms-auto flex-row align-items-center gap-2"
      as="ul"
    >
      <TaskTimerSection />
      <NotificationDropdown />
      <DownloadCenter />
      <ProfileDropdown />
    </Nav>
  );
};

export default TopNavRightSideNavItem;
