import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { FiPackage } from "react-icons/fi";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonProductsFilter from "./SupportingComponents/CommonProductsFilter";
import ShowMore from "components/common/ShowMore";

const ProductsTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState({});
  const [filterVisibility, setFilterVisibility] = useState(true);
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission] = [
    "store-product",
    "update-product",
    "delete-product",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const { name } = allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/crm-product/" + id,
        title: "Delete Product",
        message: "Are you sure you want to delete this product?",
        onSuccess: () => {
          const dataWithoutDeletedItem = products.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/products?" +
              createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setProducts((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{name}</h5>;
      },
    },
    {
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return <div className="mb-0 fs--1">
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={60}
            uniqueKey={id}
          />
        </div>;
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${actionsPermission ? "" : "d-none"
          }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${actionsPermission ? "" : "d-none"
          }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2 align-items-center justify-content-center">
            {updatePermission && (
              <EditButton
                route={`/products/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton
                className={"ms-2"}
                onClick={() => handleDelete(id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page) => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: "crm/crm-product",
        params: {
          page: page,
          name: name
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setProducts(data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, name]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between p-3 ${!filterVisibility ? "border-bottom" : ""
              }`}
          >
            <div className="d-flex  align-items-center">
              <FiPackage size={24} className="me-1 text-dark" />
              <span className="fs-1 text-uppercase">Products List</span>
            </div>
            <div className="d-flex gap-2">
              {createPermission && (
                <AddButton
                  to={`/products/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonProductsFilter />
          </div>
        </Card.Header>
        {!isLoading &&
          Object.keys(products).length > 0 && products?.data?.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={products?.data} sortable>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {products && products?.links && (
                <AppPagination
                  data={products}
                  url="/products"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for products" />
            ) : (
              <h5 className="text-center text-secondary">
                Products not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {createPermission && (
                  <Button
                    className="m-1"
                    variant="success"
                    size="sm"
                    as={Link}
                    to={`/products/add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                  >
                    Add Product
                  </Button>
                )}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default ProductsTableView;
