import { React } from "react";
import PropTypes from "prop-types";

import useAxisproTranslate from "hooks/useAxisproTranslate";

function SalesTableHead({
  SO,
  SD,
  SI,
  //  validation,
  taxType,
}) {
  const Translate = useAxisproTranslate();

  return (
    <thead className="text-white" style={{ backgroundColor: "#686C96" }}>
      <tr>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "30px" }}
        >
          #
        </th>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "80px" }}
        >
          {Translate("Item Code")}
        </th>
        <th style={{ width: "350px" }} className="table-header-size text-white">
          {Translate("Item Name")}
        </th>
        {SD || SI ? (
          <th
            style={{ width: "150px" }}
            className="table-header-size text-center text-white"
          >
            {Translate("Batch")}
          </th>
        ) : null}
        <th
          className="table-header-size text-center text-white"
          style={{ width: "150px" }}
        >
          {Translate(
            SO
              ? "Ordering Qty"
              : SD
              ? "Delivering Qty"
              : SI
              ? "Invoicing Qty"
              : "Quantity"
          )}
        </th>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "80px" }}
        >
          {Translate("Unit")}
        </th>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "120px" }}
        >
          {Translate("Price")}
        </th>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "120px" }}
        >
          {taxType ? Translate("Tax (INC)") : Translate("Tax")}
        </th>
        {/* {validation ? null : ( */}
        <th
          className="table-header-size text-center text-white"
          style={{ width: "100px" }}
        >
          {Translate("Discount")}
        </th>
        {/* )} */}
        <th
          className="table-header-size text-center text-white"
          style={{ width: "150px" }}
        >
          {Translate("Total (AED)")}
        </th>
        <th
          className="table-header-size text-center text-white"
          style={{ width: "70px" }}
        >
          {Translate("Action")}
        </th>
      </tr>
    </thead>
  );
}

SalesTableHead.propTypes = {
  SO: PropTypes.bool,
  SD: PropTypes.bool,
  SI: PropTypes.bool,
  validation: PropTypes.bool,
  taxType: PropTypes.bool,
};

export default SalesTableHead;
