import { React, useEffect, useRef, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Form, Row, Col, Modal, Figure } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { projectFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import SelectProjectStatus from "components/form/SelectProjectStatus";
import SelectCustomer from "components/form/SelectCustomer";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import TextEditor from "module/common/TextEditor/TextEditor";
import ListLoading from "module/common/ListLoading";
import { AiOutlineCodeSandbox } from "react-icons/ai";

const ProjectForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const fileRef = useRef();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(projectFormKeys);
  const [formError, setFormError] = useState({});
  const [logoImg, setLogoImg] = useState("");
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        value = e.code ? e.code : e.value;
      } else if (action.action === "clear") {
        value = [];
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleFiles = (e) => {
    let value;
    if (e?.target?.type === "file" && e.target.files.length > 0) {
      value = e.target.files[0];
      setLogoImg(URL.createObjectURL(e.target.files[0]));
      setFormData((prev) => ({
        ...prev,
        project_logo: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData, "object");
    axios({
      method: "post",
      url: `crm/project${itemId ? "/" + itemId : ""}`,
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(itemId ? -1 : "/project");
          (!filterItems || itemId) && getData(pageNumber ?? 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/project/" + itemId,
    });

    setFormData({
      _method: "PUT",
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      title: data.title,
      customer_id: data.customer_id,
      customer_id_ref: {
        label: data.customer.name,
        value: data.customer_id,
      },
      start_date: data.start_date,
      end_date: data.end_date,
      price: data.price,
      status: data.status,
      status_ref: {
        label: data.status,
        value: data.status,
      },
      description: data.description,
    });
    if (data?.logo?.length > 0) {
      setLogoImg(data?.logo?.[0]?.file_url);
    }
    setLoading(false);
  };

  return (
    <Modal
      show
      onHide={() => navigate(-1)}
      size="lg"
      backdrop="static"
      className=""
    >
      <Modal.Header closeButton className="mt-2 mb-2">
        <AiOutlineCodeSandbox size={25} className="me-1 text-dark" />
        <span className="custom-module-header">{`${
          !itemId ? "NEW" : "UPDATE"
        } PROJECT`}</span>
      </Modal.Header>

      <Modal.Body>
        {!loading ? (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="require-data">Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={handleFieldChange}
                    value={formData.title}
                    placeholder="Enter Name"
                    isInvalid={!!formError.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.title}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label className="require-data">Start Date</Form.Label>
                  <AppDatePicker
                    name="start_date"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData.start_date}
                    isInvalid={!!formError.start_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.start_date}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-2"
                  controlId="exampleForm.ControlInput5"
                >
                  <Form.Label className="require-data">Price</Form.Label>
                  <Form.Control
                    type="number"
                    name="price"
                    onChange={handleFieldChange}
                    value={formData.price}
                    placeholder="Enter Name"
                    isInvalid={!!formError.price}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.price}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label className="require-data">Client</Form.Label>
                  <SelectCustomer
                    name="customer_id"
                    value={formData.customer_id_ref}
                    error={formError.customer_id}
                    handleFieldChange={handleFieldChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.customer_id}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label className="require-data">End Date</Form.Label>
                  <AppDatePicker
                    name="end_date"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData.end_date}
                    isInvalid={!!formError.end_date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.end_date}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput6"
                >
                  <Form.Label className="require-data">Status</Form.Label>
                  <SelectProjectStatus
                    value={formData.status_ref}
                    name="status"
                    handleFieldChange={handleFieldChange}
                    error={!!formError.status}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.status}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Figure
                  onClick={() => fileRef.current.click()}
                  style={{
                    minHeight: "185px",
                    maxWidth: "200px",
                    border: !logoImg && "1px dashed #d6d6d6",
                  }}
                  className={
                    !logoImg &&
                    "bg-white d-flex justify-content-center align-items-center cursor-pointer"
                  }
                >
                  {logoImg ? (
                    <Figure.Image
                      thumbnail
                      height={500}
                      width={200}
                      alt="145x190"
                      src={logoImg}
                      style={{
                        border: "1px dashed #d6d6d6",
                        maxHeight: "185px",
                        maxWidth: "300px",
                      }}
                      className="cursor-pointer"
                    />
                  ) : (
                    <p className="text-muted fs--1 m-0">Choose image</p>
                  )}
                  <Form.Control
                    type="file"
                    name="project_logo"
                    ref={fileRef}
                    onChange={handleFiles}
                    hidden
                  />
                </Figure>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Description</Form.Label>
                  <TextEditor
                    name="description"
                    handleFieldChange={handleFieldChange}
                    value={formData.description}
                    isInvalid={!!formError.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="d-flex justify-content-end gap-1 mt-3">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={itemId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </Form.Group>
          </Form>
        ) : (
          <ListLoading style={{ height: "52vh", overflow: "hidden" }} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ProjectForm;
