import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Card, Form, Row, Col } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import {
  AddButton,
  DeleteButton,
  EditButton,
  ViewButton,
} from "module/common/Buttons/AppButtons";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import OpportunityForm from "./OpportunityForm";
import { Link } from "react-router-dom";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { TbBulbFilled } from "react-icons/tb";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import ExportButton from "components/export-button/ExportButton";
import { MdMoveUp } from "react-icons/md";
import {
  commonOpportunityFilterFormKeys,
  opportunityStageChangeFormKeys,
} from "helpers/formKeys";
import SelectOpportunityStage from "components/form/SelectOpportunityStage";
import CommonFieldEditForm from "module/common/Forms/CommonFieldEditForm";
import CommonFilter from "components/filter/CommonFilter";

const Opportunity = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [opportunity, setOpportunity] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [filterFields, setFilterFields] = useState({});
  const [filterVisibility, setFilterVisibility] = useState(true);
  const {
    date_range,
    date_range_for,
    from_date,
    to_date,
    title,
    type,
    customer_id,
    stage,
    lead_id,
    campaign_id,
  } = allQueryParams;

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [formData, setFormData] = useState(opportunityStageChangeFormKeys);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/opportunities/" + id,
        title: "Delete Opportunity",
        message: "Are you sure you want to delete this opportunity?",
        onSuccess: () => {
          const dataWithoutDeletedItem = opportunity.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/opportunity?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setOpportunity((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleStageEdit = (rowData) => {
    setFormData((prevData) => ({
      ...prevData,
      opportunity_id: rowData.id,
      stage_to: rowData.stage,
      stage_to_ref: {
        value: rowData.stage,
        label: rowData.stage,
      },
      description: "",
    }));
    setShowStatusModal(true);
  };

  const handleModalClose = () => {
    setFormData(opportunityStageChangeFormKeys);
    setShowStatusModal(false);
  };

  const columns = [
    {
      accessor: "title",
      Header: "TITLE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { title, id } = rowData.row.original;
        return (
          <Link
            className="mb-0 text-uppercase fs--1 text-dark"
            to={`/opportunity/overview/${id}?tab=overview`}
          >
            {title?.length > 50 ? title.substring(0, 30) + "..." : title}
          </Link>
        );
      },
    },
    {
      accessor: "type",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { type } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{type ? type : "N/A"}</h5>;
      },
    },
    {
      accessor: "customer",
      Header: "ACCOUNT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { customer_name, customer_id } = rowData.row.original;
        return (
          <Link to={`/client/overview/${customer_id}?tab=overview`}>
            <h5 className="mb-0 fs--1">{customer_name}</h5>
          </Link>
        );
      },
    },
    {
      accessor: "stage",
      Header: "STAGE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { stage } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{stage}</h5>;
      },
    },
    {
      accessor: "lead",
      Header: "LEAD",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { lead_title } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{lead_title}</h5>;
      },
    },
    {
      accessor: "campaign",
      Header: "CAMPAIGN",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { campaign_name } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            {campaign_name ? campaign_name : "N/A"}
          </h5>
        );
      },
    },
    {
      accessor: "probability_percentage",
      Header: "PROBABILITY (%)",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { probability_percentage } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1 text-uppercase text-center">
            {probability_percentage}
          </h5>
        );
      },
    },
    {
      accessor: "amount",
      Header: "ACTUAL AMOUNT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { amount } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1 text-uppercase text-center">
            {amount ? amount : 0}
          </h5>
        );
      },
    },
    {
      accessor: "opening_date",
      Header: "OPENING DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { opening_date_formatted } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1 text-uppercase">
            {opening_date_formatted ? opening_date_formatted : "N/A"}
          </h5>
        );
      },
    },
    {
      accessor: "closing_date",
      Header: "CLOSING DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { closing_date_formatted } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1 text-uppercase">
            {closing_date_formatted ? closing_date_formatted : "N/A"}
          </h5>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: { className: "py-3 custom-table-head-th-action" },
      disableSortBy: true,
      cellProps: {
        className: "custom-table-body-td-action",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2 justify-content-center align-items-center">
            <ViewButton
              to={`/opportunity/overview/${id}?${createSearchParams({
                ...allQueryParams,
                tab: "overview",
              })}`}
            />
            <Button
              size="sm"
              variant="transparent"
              className={`custom-button-action p-0`}
              title="Edit Stage"
              onClick={() => {
                handleStageEdit(rowData.row.original);
              }}
            >
              <MdMoveUp
                color="C738BD"
                size={16}
                className="custom-button-stage-edit-icon m-1"
              />
            </Button>
            {axisProPermission("update-opportunity") && (
              <EditButton
                route={`/opportunity?${createSearchParams({
                  ...allQueryParams,
                  opportunity_form_modal: true,
                  item_id: id,
                })}`}
              />
            )}
            {axisProPermission("delete-opportunity") && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    setFilterFields({
      date_range: date_range,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      title: title,
      type: type,
      customer_id: customer_id,
      stage: stage,
      lead_id: lead_id,
      campaign_id: campaign_id,
    });
    const data = await apiCall({
      url: "crm/opportunities",
      params: {
        page: page,
        date_range: date_range,
        date_range_for: date_range_for,
        from_date: from_date,
        to_date: to_date,
        title: title,
        type: type,
        customer_id: customer_id,
        stage: stage,
        lead_id: lead_id,
        campaign_id: campaign_id,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setOpportunity(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    date_range,
    date_range_for,
    from_date,
    to_date,
    title,
    type,
    customer_id,
    stage,
    lead_id,
    campaign_id,
  ]);

  const dataForEditModal = {
    id: formData.opportunity_id,
    data: formData,
    getData: getData,
    apiUrl: "opportunity/update-stage",
    heading: "UPDATE STAGE",
    handleFieldChange: handleFieldChange,
    close: handleModalClose,
  };

  return (
    <>
      <Outlet context={[getData]} />

      <>
        <OpportunityForm getData={getData} />
        <Card className="flex-fill mb-3 mt-3">
          <Card.Header
            className={`p-0 ${!filterVisibility ? "border-bottom" : ""}`}
          >
            <div className="d-flex justify-content-between align-items-center p-3">
              <div className="d-flex align-items-center gap-2">
                <TbBulbFilled size={25} className="text-dark" />
                <span className="fs-1 text-uppercase">Opportunity List</span>
              </div>
              <div className="d-flex gap-1">
                <ExportButton
                  url="crm/opportunity-export"
                  initialMessage={"Opportunity export started"}
                  successMessage={"Opportunity export completed"}
                  file_type={"pdf"}
                  variant={"danger"}
                  filters={filterFields}
                />
                {axisProPermission("store-opportunity") && (
                  <AddButton
                    route={`/opportunity?${createSearchParams({
                      ...allQueryParams,
                      opportunity_form_modal: true,
                    })}`}
                    variant="success"
                    title="Add new opportunity"
                  />
                )}
                <FilterCommonButton
                  filterVisibility={filterVisibility}
                  setFilterVisibility={setFilterVisibility}
                />
              </div>
            </div>
            <div style={{ display: filterVisibility ? "block" : "none" }}>
              <CommonFilter filterKeys={commonOpportunityFilterFormKeys} />
            </div>
          </Card.Header>
          {!isLoading && opportunity?.data?.length > 0 ? (
            <AdvanceTableWrapper
              columns={columns}
              data={opportunity.data}
              sortable
            >
              <SimpleBar className="simplebar-style-with-pagination">
                <Card.Body className="p-0">
                  <AdvanceTable
                    table
                    headerClassName="text-nowrap align-middle"
                    rowClassName="align-middle white-space-nowrap"
                    tablebodyClassName="custom-table-body"
                    tableProps={{
                      size: "sm",
                      className: "fs--1 mb-0 overflow-hidden custom-table",
                    }}
                  />
                </Card.Body>
              </SimpleBar>
              <Card.Footer className="border-top p-1 pt-3">
                {opportunity && opportunity.links && (
                  <AppPagination
                    data={opportunity}
                    url="/opportunity"
                    search={allQueryParams}
                  />
                )}
              </Card.Footer>
            </AdvanceTableWrapper>
          ) : (
            <LoadingScreenCover>
              {isLoading ? (
                <LoadingCommon loadingText="Looking for Opportunity" />
              ) : (
                <h5 className="text-center text-secondary">
                  Opportunity not found! Let's add it &#128578;
                </h5>
              )}
              {!isLoading && (
                <div className="d-flex flex-wrap justify-content-center mt-3">
                  {axisProPermission("store-opportunity") && (
                    <AddButton
                      className="mt-2 mb-2"
                      route={`/opportunity/add?opportunity_form_modal=true&${
                        filterItems
                          ? createSearchParams({
                              ...allQueryParams,
                            })
                          : ""
                      }`}
                      variant="success"
                      title="Add Opportunity"
                      label="Add Opportunity"
                    />
                  )}
                </div>
              )}
            </LoadingScreenCover>
          )}
        </Card>
        {showStatusModal && (
          <CommonFieldEditForm
            dataForEditModal={dataForEditModal}
            IconComponent={MdMoveUp}
          >
            <div>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Stage</Form.Label>
                    <SelectOpportunityStage
                      name="stage_to"
                      value={dataForEditModal?.data?.stage_to_ref}
                      handleFieldChange={handleFieldChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </CommonFieldEditForm>
        )}
      </>
    </>
  );
};

export default Opportunity;
