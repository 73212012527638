import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { ticketTypeFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";

const CommonTicketTypeFilter = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(ticketTypeFormKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { title } = allQueryParams;

  const handleFieldChange = (e, action) => {
    let newFormData;
    if (!action) {
      newFormData = {
        ...filterFields,
        [e.target.name]: e.target.value,
      };
    }
    setFilterFields(newFormData);
  };

  const handleFilterApply = () => {
    let formDataWithoutRefKeys = removeRefData(filterFields);
    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...formDataWithoutRefKeys,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      title: title ? title : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilter = () => {
    setFilterFields(ticketTypeFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Title</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleFieldChange}
                  value={filterFields?.title}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
      </Row>
      <Row className="m-0">
        <Form.Group
          as={Col}
          md={2}
          className="d-flex justify-content-end w-100 p-0"
        >
          <div className="d-flex align-items-end gap-2 mt-3">
            <Button
              size="sm"
              variant="warning"
              onClick={handleFilterApply}
              title="Apply Added Filters"
            >
              Apply
            </Button>
            <Button
              size="sm"
              variant="transparent"
              className="shadow-sm"
              onClick={handleResetFilter}
              title="Clear All Filters"
            >
              <FcClearFilters size={18} />
            </Button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default CommonTicketTypeFilter;
