import React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'react-bootstrap';

function QuantityInputField({
  onChange,
  value,
  name,
  unit,
  onKeyDown,
  ...rest
}) {
  const handleChange = e => {
    if (unit === 'Pieces' || unit === 'Nos' || unit === 'Kit') {
      let inputValue = e.target.value;
      let pattern = /^\d*$/;
      if (pattern.test(inputValue)) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <Form.Control
      {...rest}
      type="number"
      id="quantity"
      onKeyDown={onKeyDown}
      name={name}
      onChange={handleChange}
      value={value}
    />
  );
}

QuantityInputField.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.any,
  unit: PropTypes.string,
  onKeyDown: PropTypes.func
};

export default QuantityInputField;
