import React from "react";
import { Link } from "react-router-dom";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  FaEdit,
  FaEye,
  FaPlayCircle,
  FaPlus,
  FaRegStopCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RiTimerFlashLine } from "react-icons/ri";

export const AddButton = ({ route, title, variant, label, className }) => {
  return (
    <Button
      size="sm"
      className={className ?? className + "m-1 ms-0"}
      variant={variant}
      title={title}
      as={Link}
      to={route}
    >
      {label ? label : <FaPlus size={10} />}
    </Button>
  );
};

AddButton.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export const ReferenceButton = ({ title, route, variant, label }) => {
  return (
    <Button
      variant={variant}
      className="custom-button-action"
      size="sm"
      as={Link}
      to={route}
      title={label}
    >
      <span className="d-block text-capitalize">{title}</span>
    </Button>
  );
};

ReferenceButton.propTypes = {
  route: PropTypes.string,
  title: PropTypes.string,
  variant: PropTypes.string,
  label: PropTypes.string,
};

export const EditButton = ({ route, className, title = "" }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      as={Link}
      to={route}
      title="Edit"
    >
      <div className="d-flex justify-content-center align-items-center">
        <FaEdit
          color="#f18615cc"
          className="custom-button-edit-icon m-1"
          size={15}
        />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

EditButton.propTypes = {
  route: PropTypes.string,
};

export const DeleteButton = ({ onClick, className, title = "" }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      onClick={onClick}
      title="Delete"
    >
      <div className="d-flex justify-content-center align-items-center">
        <FaTimesCircle
          color="#f16969"
          className="custom-button-delete-icon m-1"
          size={15}
        />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func,
};

export const RedirectButton = ({ route }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className="p-0 d-flex align-items-center justify-content-center custom-button-action"
      as={Link}
      to={route}
      title="Redirect"
    >
      <FontAwesomeIcon
        icon="external-link-alt"
        className="mb-1 text-primary"
        size="xs"
        cursor={"pointer"}
      />
    </Button>
  );
};

RedirectButton.propTypes = {
  route: PropTypes.string,
};

export const TimerButton = ({ handleTimer, loadingTimer, timerRunning }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className="custom-transperent-common-button p-0"
      title={timerRunning ? `Stop Timer` : `Start Timer`}
      onClick={handleTimer}
    >
      {loadingTimer ? (
        <Spinner
          animation="border"
          variant={timerRunning ? "danger" : "success"}
          size="sm"
        />
      ) : (
        <>
          {timerRunning ? (
            <FaRegStopCircle color="#d94444" size={25} />
          ) : (
            <FaPlayCircle color="#24a324bf" size={25} />
          )}
        </>
      )}
    </Button>
  );
};

TimerButton.propTypes = {
  variant: PropTypes.string,
  title: PropTypes.string,
  handleTimer: PropTypes.func,
  loadingTimer: PropTypes.bool,
};

export const ViewButton = ({ to, className }) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      as={Link}
      to={to}
      title="View"
    >
      <FaEye
        color="#7062c9"
        size={16}
        className="custom-button-view-icon m-1"
      />
    </Button>
  );
};

ViewButton.propTypes = {
  to: PropTypes.string,
};

export const TimerActionButton = ({
  onClick,
  timerStatus,
  className,
  title = "",
}) => {
  return (
    <Button
      size="sm"
      variant="transparent"
      className={`custom-button-action p-0 ${className ? className : ""}`}
      onClick={onClick}
      title={timerStatus ? "Stop Timer" : "Start Timer"}
    >
      <div className="d-flex justify-content-center align-items-center">
        <RiTimerFlashLine
          color={timerStatus ? "#f16969" : "#24a324bf"}
          className="m-1"
          size={17}
        />
        {title && <span className="ms-2 fw-semibold mt-1">{title}</span>}
      </div>
    </Button>
  );
};
