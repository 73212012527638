import axios from "axios";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import SelectProject from "components/form/SelectProject";
import SelectSprintStatus from "components/form/SelectSprintStatus";
import { apiCall } from "helpers/apiCalls";
import { sprintFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import { SaveButton } from "module/common/Buttons/CommonButton";
import ListLoading from "module/common/ListLoading";
import TextEditor from "module/common/TextEditor/TextEditor";
import { showToast } from "module/common/Toast/toast";
import React, { useEffect, useState } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { BsCalendar2Range } from "react-icons/bs";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

const SprintForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(sprintFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  const { itemId, itemInfoId } = useParams(); //itemInfoId is refered to projectId
  const [loading, setLoading] = useState(false);
  const [getData] = useOutletContext();

  const handleFieldChange = (e, action) => {
    if (action?.action === "text-editor") {
      setFormData({
        ...formData,
        [action.name]: action.value,
      });
    } else {
      setFormData(generateFormDataHelper(e, action, formData));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    axios({
      method: itemId ? "put" : "post",
      url: `crm/sprint${itemId ? "/" + itemId : ""}`,
      data: removeRefData(formData),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(itemInfoId ? -1 : `/sprints`);
          getData();
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const getEditData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `/crm/sprint/${itemId}`,
    });

    setFormData({
      ...formData,
      name: data.name,
      description: data.description,
      start_date_time: data.start_date_time,
      end_date_time: data.end_date_time,
      status: data.status,
      status_ref: { value: data.status, label: data.status },
      project_id: data.project_id,
      project_id_ref: { value: data.project_id, label: data.project_title },
      remark: data.remarks,
    });
    setLoading(false);
  };

  useEffect(() => {
    if (itemId) {
      getEditData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      project_id: itemInfoId,
    }));
  }, [itemInfoId]);

  return (
    <div>
      <Modal
        size="lg"
        show={true}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center">
              <BsCalendar2Range size={20} className="me-2 text-dark" />
              <span className="custom-module-header">
                {itemId ? "UPDATE" : "CREATE"} SPRINT
              </span>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <ListLoading style={{ height: "72vh", overflow: "hidden" }} />
          ) : (
            <Form id="sprintForm">
              <Row>
                <Form.Group as={Col} xs={12} md={12} className="mb-3">
                  <Form.Label className="require-data">Name</Form.Label>
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="name"
                      onChange={handleFieldChange}
                      value={formData.name}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.name}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} className="mb-3">
                  <Form.Label className="require-data">Start At</Form.Label>
                  <Col>
                    <AppTimePicker
                      name="start_date_time"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.start_date_time}
                      isInvalid={!!formError.start_date_time}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">
                        {formError.start_date_time}
                      </span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6} className="mb-3">
                  <Form.Label className="require-data">End At</Form.Label>
                  <Col>
                    <AppTimePicker
                      name="end_date_time"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.end_date_time}
                      isInvalid={!!formError.end_date_time}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.end_date_time}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                {itemInfoId ? null : (
                  <Form.Group as={Col} xs={12} md={6} className="mb-3">
                    <Form.Label className="require-data">Project</Form.Label>
                    <Col>
                      <SelectProject
                        name="project_id"
                        value={formData.project_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.project_id}
                      />
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-flex p-0 m-0"
                      >
                        <span className="ms-auto">{formError.project_id}</span>
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                )}

                <Form.Group as={Col} xs={12} md={6} className="mb-3">
                  <Form.Label className="require-data">Status</Form.Label>
                  <Col>
                    <SelectSprintStatus
                      name="status"
                      value={formData.status_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.status}
                    />
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-flex p-0 m-0"
                    >
                      <span className="ms-auto">{formError.status}</span>
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Row>
              <Form.Group as={Row} xs={12}>
                <Form.Label>Description</Form.Label>
                <Col>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="description"
                    className="mb-3"
                    onChange={handleFieldChange}
                    value={formData.description}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-flex p-0 m-0"
                  >
                    <span className="ms-auto">{formError.description}</span>
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput2"
                as={Row}
              >
                <Form.Label>Remarks</Form.Label>
                <TextEditor
                  name="remark"
                  className="mb-3"
                  allowMedia={false}
                  handleFieldChange={handleFieldChange}
                  value={formData.remark}
                  isInvalid={!!formError.remark}
                />
                <Form.Control.Feedback type="invalid">
                  {formError.remark}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="d-flex justify-content-end gap-1 mt-3">
                <SaveButton
                  handleSubmit={handleSubmit}
                  onSave={onSave}
                  id={itemId}
                />
                {!onSave && (
                  <BackButton
                    variant={"danger"}
                    size={"sm"}
                    onClick={() => navigate(-1)}
                  >
                    Cancel
                  </BackButton>
                )}
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SprintForm;
