import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { BiDoughnutChart, BiEditAlt, BiLabel, BiTask } from "react-icons/bi";
import {
  Link,
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
  useLocation,
  Outlet,
} from "react-router-dom";
import ProfileImage from "assets/img/Avathar/profile.png";
import { AiOutlineNodeCollapse } from "react-icons/ai";
import { FaEdit, FaEye, FaPlus, FaUserGraduate } from "react-icons/fa";
import Collaborator from "../../common/ViewPages/Common/Collaborator/Collaborator";
import "./TaskOverview.scss";
import { TbMapPinFilled, TbRefreshDot } from "react-icons/tb";
import Labels from "../../common/ViewPages/Common/Labels/Labels";
import { MdOutlineAutoGraph } from "react-icons/md";
import ActivityTimeLine from "../../common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";
import { apiCall } from "helpers/apiCalls";
import DoughnutChart from "module/common/Charts/DoughnutChart";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { AuthWizardContext } from "context/Context";
import DigitalTimer from "module/common/Timer/DigitalTimer";
import ShowHtmlContent from "components/common/showHtmlContent/ShowHtmlContent";
import TimeSheetLog from "module/common/ViewPages/Common/TimeSheet/TimeSheetLog";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import AddAssigneeModal from "../TaskDashboard/Common/AddAssigneeModal";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import PieChart from "module/common/Charts/PieChart";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import ImagePreview from "module/common/ImagePreview/ImagePreview";
import getTaskPriorityWithProps from "module/common/helpers/getTaskPriorityWithProps";
import useAxisproPermission from "hooks/useAxisproPermission";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import TimerConfirm from "module/common/Timer/TimerConfirm";

const TaskOverview = () => {
  const { overviewId, itemInfoId, itemId } = useParams();
  const [queryParams] = useSearchParams();
  const [taskData, setTaskData] = useState({});
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [collabList, setCollabList] = useState({});
  const { dispatch, user } = useContext(AuthWizardContext);
  const [hasMatch, setHasMatch] = useState(false);
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [reloadLabels, setReloadLabels] = useState(false);
  const [statusArray, setStatusArray] = useState([]);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const AddAssigne = queryParams.get("add_assinee_modal") ? true : false;
  const [show, setShow] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const axisProPermission = useAxisproPermission();
  const location = useLocation();
  const renderFrom = location.pathname.split("/")[1];
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const [
    updateTaskPermission,
    updateTaskAssigneePermission,
    showProjectPermission,
    showSprintPermission,
    updateTaskStatusPermission,
  ] = [
    "update-task",
    "update-task-assignee",
    "show-project",
    "show-sprint",
    "update-task-status",
  ].map((permission) => axisProPermission(permission));

  const handleClose = () => setShow(false);

  const handleShow = () => {
    setShow(true);
  };

  const options = {
    title: false,
    legend: {
      show: false,
    },
    series: [
      {
        name: "Pie Chart",
        type: "pie",
        radius: "60%",
        center: ["50%", "50%"],
        data: taskData?.contributions?.map((item, key) => ({
          value: item.contribution_in_seconds,
          name: item.name,
        })),
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        label: {
          show: true,
          formatter: function (params) {
            const hours = Math.floor(params.data.value / 3600);
            const minutes = Math.floor((params.data.value % 3600) / 60);
            const remainingSeconds = params.data.value % 60;

            const formattedHours = String(hours).padStart(2, "0");
            const formattedMinutes = String(minutes).padStart(2, "0");
            const formattedSeconds = String(remainingSeconds).padStart(2, "0");

            return `${params.data.name} \n ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
          },
        },
      },
    ],
  };

  const optionsDoughnut = {
    cutout: "60%",

    plugins: {
      legend: {
        display: false,
        // position: "bottom",
      },
      doughnutlabel: {
        labels: [
          {
            text: "Doughnut Chart",
            font: {
              size: 20,
            },
            color: "#000",
            position: "center",
          },
          {
            text: "Total: 41",
            font: {
              size: 16,
            },
            color: "#000",
            position: "inner",
          },
        ],
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
      },
    },
  };

  const dataDoughnut = {
    labels: taskData?.contributions?.map((item, key) => item.name),
    datasets: [
      {
        data: taskData?.contributions?.map(
          (item, key) => item.contribution_percentage
        ),
      },
    ],
  };

  const hasNonZeroValue = dataDoughnut?.datasets[0]?.data?.some(
    (value) => value !== 0
  );

  useEffect(() => {
    if (overviewId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId]);

  const getData = async () => {
    setLoading(true);

    const data = await apiCall({
      url: `crm/task/${overviewId}`,
    });

    setFormData({ task_id: data.id, status: data.status });
    setTaskData(data);

    const statusData = await apiCall({
      url: `crm/task-status`,
    });
    setStatusArray(statusData);

    setLoading(false);
  };

  useLayoutEffect(() => {
    let assigneeMatch,
      colabMatch = false;
    if (taskData.assigned_to) {
      assigneeMatch = taskData.assigned_to === user.id;
    }
    if (Object.keys(collabList).length > 0) {
      colabMatch = collabList.some((collab) => collab.user_id === user.id);
    }
    setHasMatch(assigneeMatch || colabMatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collabList]);

  const handleTimer = async (e) => {
    e.preventDefault();
    handleClose();
    setLoadingTimer(true);

    try {
      const startOrStopTimerUrl =
        Object.keys(taskData.current_time_tracker).length > 0
          ? `crm/stop-timer/${overviewId}`
          : `crm/start-timer/${overviewId}`;

      const response = await axios.post(startOrStopTimerUrl);
      const { data } = response;

      if (data.success === true) {
        const runningTasksData = await axios.get("crm/running-tasks-info");
        const runningTasks = runningTasksData.data;

        if (runningTasks.success === true) {
          let updatedData = {
            ...user,
            running_tasks_count: runningTasks.data.count,
          };

          dispatch({ type: "UPDATE", payload: { ...updatedData } });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }

        if (data.data.end_time) {
          // Update task data when stopping the timer
          setTaskData((prevState) => ({
            ...prevState,
            total_time_tracked: data.data.task.total_time_tracked,
            current_time_tracker: [],
            running_status: runningTasks.data.count > 0 ? true : false,
            time_trackers: prevState.time_trackers.map((tracker) => {
              if (tracker.id === data.data.id) {
                tracker.end_time = data.data.end_time;
                tracker.end_time_formatted = data.data.end_time_formatted;
                tracker.time_tracked = data.data.time_tracked;
              }
              return tracker;
            }),
          }));
        } else {
          // Update task data when starting the timer
          setTaskData((prevState) => ({
            ...prevState,
            running_status: runningTasks.data.count > 0 ? true : false,
            current_time_tracker: [
              ...prevState.current_time_tracker,
              data.data,
            ],
            time_trackers: [...prevState.time_trackers, data.data],
          }));
        }

        setLoadingTimer(false);
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setLoadingTimer(false);
      }
    } catch (error) {
      handleTimerError(error);
    }
  };

  const handleTimerError = (error) => {
    if (error.response.data && error.response.data.message) {
      const validation_error =
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.errors
          ? error.response.data.data.errors
          : null;
      showToast(validation_error.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
    setLoadingTimer(false);
  };

  const handleStatusUpdate = (e, status) => {
    e.preventDefault();
    setStatusLoading(true);
    if (status && status !== formData.status) {
      axios({
        method: "post",
        url: `crm/task-status/update`,
        params: { task_id: formData.task_id, status: status },
      })
        .then((response) => {
          if (response.data.success === true) {
            showToast(response.data.message, "success");
            setStatusLoading(false);
            setTaskData(response.data.data);
            setFormData({
              ...formData,
              status: response.data.data.status,
            });
          } else {
            setStatusLoading(false);
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            if ("collaborator_working" in validation_error) {
              showToast(validation_error.collaborator_working[0], "error");
            } else if ("task_id" in validation_error) {
              showToast(validation_error.task_id[0], "error");
            } else if ("status" in validation_error) {
              showToast(validation_error.status[0], "error");
            } else {
              showToast(
                "Something went wrong, please refresh the page and try again.",
                "error"
              );
            }
            setFormData((prevState) => ({
              ...formData,
              status: prevState.status,
            }));
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
          setStatusLoading(false);
        });
    }
  };

  const handleImageClick = (event) => {
    const clickedImageUrl = event.target.src;
    setPreviewImage(clickedImageUrl);
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
    setPreviewImage(null);
  };

  useEffect(() => {
    if (Object.keys(taskData)?.length > 0) {
      const isTaskRunning = user.running_tasks_count > 0;
      setTaskData((prevState) => ({
        ...prevState,
        running_status: isTaskRunning,
        current_time_tracker: isTaskRunning
          ? prevState.current_time_tracker
          : [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return !loading ? (
    <>
      <Outlet context={[getData]} />
      <Row className="gx-1 mt-3" style={{ maxHeight: "100vh" }}>
        {statusLoading && <LoadingOverlay />}
        {Object.keys(taskData)?.length > 0 && (
          <>
            <Col md={9} className="d-flex mb-3">
              <Card className="flex-fill h-100">
                <TimerConfirm
                  show={show}
                  handleClose={handleClose}
                  handleTimer={handleTimer}
                  loading={loadingTimer}
                  timerRunningTask={taskData.id}
                />
                <Card.Header className=" border-bottom rounded-0">
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex">
                      <div>
                        <BiTask size={25} />
                      </div>
                      <div className="fs-1 ms-1 mt-1 d-flex flex-column">
                        <span className="text-wrap">{taskData.title}</span>
                        <span className="fs--2">
                          #{taskData.task_number} Created by{" "}
                          <Link>{taskData.created_user_full_name}</Link>,{" "}
                          {taskData.created_at_diff}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-end text-muted fs--2">
                      {updateTaskPermission && (
                        <Button
                          variant="falcon-default"
                          size="sm"
                          className="me-2 "
                          as={Link}
                          to={
                            renderFrom === "tasks"
                              ? `/tasks/list/overview/${overviewId}/edit/${overviewId}?${commonSearchParams}`
                              : renderFrom === "sprints"
                              ? `/sprints/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                              : `/project/info/${itemInfoId}/task/edit/${itemId}?${commonSearchParams}`
                          }
                        >
                          <span className="d-flex">
                            <span>
                              <BiEditAlt size={18} />
                            </span>
                            <span className="ms-2">Edit</span>
                          </span>
                        </Button>
                      )}
                      {taskData.ticket_id && (
                        <Button
                          variant="falcon-default"
                          size="sm"
                          className="ps-2"
                          as={Link}
                          to={`/tasks/list/overview/${overviewId}/info/${taskData.ticket_id}?${commonSearchParams}`}
                        >
                          <div className="d-flex">
                            <span>
                              <FaEye size={18} />
                            </span>
                            <span className="ms-2">Ticket</span>
                          </div>
                        </Button>
                      )}
                    </div>
                  </div>
                </Card.Header>
                <Card.Body>
                  <ListGroup>
                    <ListGroupItem className="border-0 d-flex align-items-center task-overview-content-li">
                      <div className="task-custom-div d-flex flex-column gap-1">
                        <span
                          className="fs--1 d-flex flex-row mb-2"
                          style={{ fontWeight: "549" }}
                        >
                          <AddAssigneeModal
                            show={AddAssigne}
                            assignee_id={taskData.assigned_to}
                            getData={getData}
                          />
                          <FaUserGraduate className="me-1" size={17} />
                          <span>
                            ASSIGNED TO
                            {updateTaskAssigneePermission && (
                              <>
                                {taskData.assigned_to ? (
                                  <FaEdit
                                    onClick={() => {
                                      navigate(
                                        `?${createSearchParams({
                                          ...allQueryParams,
                                          add_assinee_modal: true,
                                        })}`
                                      );
                                    }}
                                    className="ms-2  cursor-pointer"
                                  />
                                ) : (
                                  ""
                                )}
                              </>
                            )}
                          </span>
                        </span>
                        {taskData.assigned_to ? (
                          <div className="d-flex align-items-center">
                            <Image
                              className="me-2"
                              src={taskData.assignee.image ?? ProfileImage}
                              roundedCircle
                              alt="?"
                              height={50}
                              width={50}
                              loading="lazy"
                            />

                            <div className="d-flex flex-column">
                              <span className="fs--1">
                                {taskData.assignee.name}
                              </span>
                              <span className="fs--2">
                                {taskData.assignee.roles.map(
                                  (item) => item.name
                                )}
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="d-flex justify-content-center align-items-center h-100">
                              <span className="">
                                No Assignee Yet &#128521;
                              </span>
                              <Button
                                title="Add New Assignee"
                                onClick={() => {
                                  navigate(
                                    `?${createSearchParams({
                                      ...allQueryParams,
                                      add_assinee_modal: true,
                                    })}`
                                  );
                                }}
                                style={{ height: "25px" }}
                                size="sm"
                                className="bg-success d-flex align-items-center justify-content-center border-0 ms-3"
                              >
                                <FaPlus size={12} />
                              </Button>
                            </div>
                            <div></div>
                          </>
                        )}
                      </div>
                      {hasMatch && (
                        <div className="ms-auto">
                          <DigitalTimer
                            startTime={
                              taskData?.current_time_tracker[0]?.start_time
                            }
                            handleButtonVisibility={handleShow}
                            loadingTimerButton={loadingTimer}
                            timerRunning={taskData?.running_status}
                          />
                        </div>
                      )}
                    </ListGroupItem>
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <Row>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">Project</span>
                            {showProjectPermission ? (
                              <Link
                                className="custom-link-style-crm text-muted text-uppercase"
                                to={`/project/info/${taskData.project_id}?tab=overview`}
                              >
                                {taskData.project_title}
                              </Link>
                            ) : (
                              <span>{taskData.project_title}</span>
                            )}
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1 text-capitalize">
                            <span className="fw-bold">Sprint</span>
                            {taskData?.sprint ? (
                              <>
                                {showSprintPermission ? (
                                  <Link
                                    to={`/sprints/info/${
                                      taskData?.sprint?.id
                                    }?tab=tasklist&${createSearchParams({
                                      status_filter: JSON.stringify(
                                        defaultTaskStatusFilter
                                      ),
                                    })}`}
                                    className="custom-link-style-crm text-muted ms-1"
                                  >
                                    {taskData?.sprint?.name}
                                  </Link>
                                ) : (
                                  <span>{taskData?.sprint?.name}</span>
                                )}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">Start Date</span>
                            <p>
                              {taskData.start_date_formatted
                                ? taskData.start_date_formatted
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">End Date</span>
                            <p>
                              {taskData.end_date_formatted
                                ? taskData.end_date_formatted
                                : "N/A"}
                            </p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">Total Time Spend</span>
                            <p>{taskData.total_time_tracked}</p>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="d-flex flex-column gap-1">
                            <span className="fw-bold">Priority</span>
                            <div>
                              <span>
                                {getTaskPriorityWithProps(
                                  taskData.priority,
                                  "icon",
                                  20
                                )}
                              </span>
                              <span className="ms-1">
                                {taskData.priority ? taskData.priority : "N/A"}
                              </span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <div className="task-custom-div d-flex flex-column gap-1">
                        <span className="fw-bold">Description</span>
                        <div>
                          {showPreview && previewImage && (
                            <ImagePreview
                              previewImage={previewImage}
                              handleClosePreview={handleClosePreview}
                            />
                          )}
                          <ShowHtmlContent
                            description={taskData.description}
                            handleImageClick={handleImageClick}
                          />
                        </div>
                      </div>
                    </ListGroupItem>

                    <ListGroupItem className="border-0 task-overview-content-li">
                      <div className="d-flex flex-column gap-1">
                        <span className="fw-bold">Status</span>
                        <ListGroup
                          horizontal
                          className="task-overview-status-list-group d-flex flex-wrap gap-2"
                        >
                          {statusArray.map((status, key) => (
                            <ListGroup.Item
                              key={key}
                              as={updateTaskStatusPermission ? Button : "div"}
                              className={`task-overview-status-list-item custom-button-task-status ${
                                formData.status === status ? "active ms-2" : ""
                              } ${
                                !updateTaskStatusPermission ? "rounded-0" : ""
                              }`}
                              style={{
                                backgroundColor: "#9aaac1",
                              }}
                              onClick={(e) => {
                                updateTaskStatusPermission &&
                                  handleStatusUpdate(e, status);
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="fw-bold text-capitalize fs--1  text-light">
                                  {status.replace("_", " ")}
                                </div>
                                {formData.status === status && (
                                  <TbMapPinFilled
                                    className="ms-1 task-overview-status-icon"
                                    size={20}
                                  />
                                )}
                              </div>
                            </ListGroup.Item>
                          ))}
                        </ListGroup>
                      </div>
                    </ListGroupItem>
                    <ListGroupItem className="border-0 task-overview-content-li">
                      <Tabs
                        id="common-custom-tabs-crm"
                        defaultActiveKey="comments"
                        className="mb-3 mt-3 common-custom-tabs-crm "
                      >
                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="comments"
                          title="Comments"
                        >
                          <Comments comment_type="task" id={overviewId} />
                        </Tab>

                        <Tab
                          className="pb-3 task-overview-content-tab"
                          eventKey="activity"
                          title="Activity Log"
                        >
                          <Col md={8}>
                            <ActivityTimeLine infoData={taskData} />
                          </Col>
                        </Tab>
                        {taskData.time_trackers.length > 0 && (
                          <Tab
                            className="pb-3 task-overview-content-tab"
                            eventKey="time_sheet"
                            title="Time Sheet"
                          >
                            <TimeSheetLog infoData={taskData} />
                          </Tab>
                        )}
                      </Tabs>
                    </ListGroupItem>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Col>
            <Col md={3} className="mb-3">
              <Row>
                <Col md={12}>
                  <Card className="rounded-3 h-100">
                    <Card.Header className="fs-1 pb-0">
                      <AiOutlineNodeCollapse className="me-1" /> Collaborators
                    </Card.Header>
                    <Card.Body>
                      <Collaborator
                        scroll={true}
                        taskId={overviewId}
                        assignee_id={taskData.assigned_to}
                        setCollabList={setCollabList} //for collab list for return
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mt-1">
                <Col md={12}>
                  <Card className="rounded-3">
                    <Card.Header className="fs-1 pb-0 d-flex">
                      <div>
                        <BiLabel className="me-1" /> Labels
                      </div>
                      <div className="ms-auto">
                        <Button
                          size="sm"
                          variant="transparent"
                          className="p-0 custom-transperent-common-button"
                          onClick={() => {
                            setReloadLabels(!reloadLabels);
                          }}
                        >
                          <TbRefreshDot size={20} />
                        </Button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Labels taskiId={overviewId} refresh={reloadLabels} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              {hasNonZeroValue && (
                <Row className="mt-1">
                  <Col md={12}>
                    <Card className="rounded-3">
                      <Card.Header className="fs-1 pb-0">
                        <BiDoughnutChart className="me-1" size={25} />
                        Task Contribution <span className="fs--1">(%)</span>
                      </Card.Header>
                      <Card.Body className="d-flex justify-content-center ">
                        <DoughnutChart
                          height={150}
                          width={150}
                          data={dataDoughnut}
                          options={optionsDoughnut}
                        />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}

              <Row className="mt-1">
                <Col md={12}>
                  <Card className="rounded-3">
                    <Card.Header className="fs-1 pb-0">
                      <MdOutlineAutoGraph className="me-1" size={25} />
                      Task Engagement <span className="fs--1">(Hours)</span>
                    </Card.Header>
                    <Card.Body
                      style={{ width: "100%", height: "250px" }}
                      className="p-0 pie-chart-container"
                    >
                      <PieChart
                        options={options}
                        height={"100%"}
                        width={"100%"}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </>
  ) : (
    <Card className="mt-3 mb-3">
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "89vh" }}
      >
        <LoadingCommon loadingText="Loading Task Overview" />
      </div>
    </Card>
  );
};

export default TaskOverview;
