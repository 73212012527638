import ColumnSelector from "module/common/Filter/ColumnSelector";
import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectProject from "components/form/SelectProject";
import SelectUsers from "components/form/SelectUsers";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { showToast } from "module/common/Toast/toast";
import { FcClearFilters } from "react-icons/fc";
import SelectProjectPriority from "components/form/SelectProjectPriority";
import SelectTicketType from "components/form/SelectTicketType";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import { AuthWizardContext } from "context/Context";
import { commonTicketFilterFormKeys } from "helpers/formKeys";
import DateRangePicker from "module/common/Filter/DateRangePicker";

const CommonTicketFilter = ({ renderFrom }) => {
  const navigate = useNavigate();
  let user = useContext(AuthWizardContext);
  let loggedUsCustomer = user?.user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const [filterFields, setFilterFields] = useState(commonTicketFilterFormKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];
  const columnsForCustomer = [{ key: "start_date", value: "Created Date" }];
  const activeTab = queryParams.get("tab");
  const {
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    project_id_ref,
    assignee,
    assignee_ref,
    priority,
    priority_ref,
    type,
    type_ref,
    title,
    origin,
    origin_ref,
  } = allQueryParams;

  const handleFieldChange = (e, action) => {
    if (!action) {
      let value;
      if (e.target.name === "unassigned") {
        value = e.target.checked ? 1 : 0;
      } else {
        value = e.target.value;
      }
      setFilterFields({ ...filterFields, [e.target.name]: value });
    } else {
      let newFormData;
      if (action.action === "select-option") {
        if (action.name === "status_filter") {
          const labelArray = e.map((item) => item.value);
          newFormData = {
            ...filterFields,
            [action.name]: labelArray,
            [action.name + "_ref"]: e,
          };
        } else {
          newFormData = {
            ...filterFields,
            [action.name]: e.value,
            [action.name + "_ref"]: e,
          };
        }
      } else if (action.action === "clear") {
        if (action.name === "status_filter") {
          newFormData = {
            ...filterFields,
            [action.name]: [],
            [action.name + "_ref"]: [],
          };
        } else {
          newFormData = {
            ...filterFields,
            [action.name]: "",
            [action.name + "_ref"]: {
              label: "",
              value: "",
            },
          };
        }
      } else if (action.action === "remove-value") {
        newFormData = {
          ...filterFields,
          [action.name]: e.map((item) => item.value),
          [action.name + "_ref"]: e,
        };
      }
      setFilterFields(newFormData);
    }
  };

  const handleFilterApply = () => {
    if (!filterFields.date_range_for && filterFields.date_range) {
      showToast(`Please Select Date Field`, "error");
      return;
    }

    if (filterFields.date_range === "custom_date" && !filterFields.from_date) {
      showToast(`Please Select From Date`, "error");
      return;
    } else if (
      filterFields.date_range === "custom_date" &&
      !filterFields.to_date
    ) {
      showToast(`Please Select To Date`, "error");
      return;
    }

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          project_id_ref:
            filterFields?.project_id &&
            JSON.stringify(filterFields?.project_id_ref),
          assignee_ref:
            filterFields?.assignee &&
            JSON.stringify(filterFields?.assignee_ref),
          priority_ref:
            filterFields?.priority &&
            JSON.stringify(filterFields?.priority_ref),
          origin_ref:
            filterFields?.origin && JSON.stringify(filterFields?.origin_ref),
          type_ref:
            filterFields?.type && JSON.stringify(filterFields?.type_ref),
          page: pageNumber ? pageNumber : 1,
          tab: activeTab,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      project_id: project_id ? project_id : "",
      project_id_ref: project_id ? JSON.parse(project_id_ref) : "",
      assignee: assignee ? assignee : "",
      assignee_ref: assignee ? JSON.parse(assignee_ref) : "",
      priority: priority ? priority : "",
      priority_ref: priority
        ? JSON.parse(priority_ref)
        : { label: "", value: "" },
      title: title ? title : "",
      date_range: date_range ? date_range : "",
      date_range_for: date_range_for ? date_range_for : "",
      from_date: from_date ? from_date : "",
      to_date: to_date ? to_date : "",
      type: type ? type : "",
      type_ref: type ? JSON.parse(type_ref) : "",
      origin: origin ? origin : "",
      origin_ref: origin ? JSON.parse(origin_ref) : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResetFilter = () => {
    setFilterFields(commonTicketFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2} className="">
          <Row>
            <Form.Group as={Col}>
              <ColumnSelector
                title="Date Field"
                labelClassName="fs--1"
                columns={loggedUsCustomer ? columnsForCustomer : columns}
                filters={filterFields}
                setFilters={setFilterFields}
                defaultOption={true}
                value={filterFields.date_range_for}
              />
            </Form.Group>
          </Row>
          {loggedUsCustomer ? null : (
            <Row className="mt-3">
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Priority</Form.Label>
                <Col>
                  <SelectProjectPriority
                    name="priority"
                    value={filterFields.priority_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Date Range</Form.Label>
              <DateRangePicker
                formData={filterFields}
                className={"custom-date-range-picker"}
                setFormData={setFilterFields}
                dateRange_form={{
                  to: "to_date",
                  from: "from_date",
                  range: "date_range",
                  month: "month",
                }}
              />
            </Form.Group>
          </Row>
          {loggedUsCustomer ? null : (
            <Row className="mt-3">
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Assignee</Form.Label>
                <Col>
                  <SelectUsers
                    name="assignee"
                    value={filterFields.assignee_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
        </Col>
        <Col md={2}>
          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Title</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleFieldChange}
                  value={filterFields.title}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        {renderFrom ? null : (
          <Col md={2}>
            <Row className="mb-3">
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Project</Form.Label>
                <Col>
                  <SelectProject
                    name="project_id"
                    handleFieldChange={handleFieldChange}
                    value={filterFields.project_id_ref}
                  />
                </Col>
              </Form.Group>
            </Row>
          </Col>
        )}
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Ticket Type</Form.Label>
              <Col>
                <SelectTicketType
                  name="type"
                  value={filterFields.type_ref}
                  handleFieldChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          {loggedUsCustomer ? null : (
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">Ticket Origin</Form.Label>
                <Col>
                  <SelectTicketOrigin
                    name="origin"
                    value={filterFields.origin_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mt-3 m-0">
        <Form.Group
          as={Col}
          md={2}
          className="d-flex justify-content-end w-100 p-0"
        >
          <div className="d-flex align-items-end gap-2">
            <Button
              size="sm"
              variant="warning"
              onClick={handleFilterApply}
              title="Apply Added Filters"
            >
              Apply
            </Button>
            <Button
              size="sm"
              variant="transparent"
              className="shadow-sm"
              onClick={handleResetFilter}
              title="Clear All Filters"
            >
              <FcClearFilters size={18} />
            </Button>
          </div>
        </Form.Group>
      </Row>
    </div>
  );
};

export default CommonTicketFilter;
