import SoftBadge from "components/common/SoftBadge";
import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  FaBuilding,
  FaGlobeAmericas,
  FaInstalod,
  FaRegBuilding,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import {
  MdBusinessCenter,
  MdLocalPhone,
  MdOutgoingMail,
  MdOutlineIncompleteCircle,
  MdOutlineLocationOn,
  MdPriorityHigh,
  MdSource,
} from "react-icons/md";
import LeadStage from "./LeadStage";
import bg1 from "assets/img/icons/spot-illustrations/corner-1.png";
import bg2 from "assets/img/icons/spot-illustrations/corner-3.png";
import bg3 from "assets/img/icons/spot-illustrations/corner-2.png";
import { BsGraphUpArrow } from "react-icons/bs";

function Overview({ data }) {
  return (
    <Row className="d-flex flex-column min-vh-75">
      <Col md={12}>
        <Card className="lead-information-wrapper shadow-none h-100">
          <Card.Header>
            <div className="ms-2 d-flex justify-content-between mt-2">
              <div>
                <SoftBadge bg="success">{data.reference}</SoftBadge>
              </div>
              <div className="me-2"> Created At : {data.created_at}</div>
            </div>
          </Card.Header>
          <Card.Body className="min-vh-75">
            <Row className="gy-3">
              <Col xs={6} md={3}>
                <LeadStage
                  data={data.is_company ? "Company" : "Individual"}
                  label={"Lead Type"}
                  variant="info"
                  icon={
                    <MdOutlineIncompleteCircle
                      size={17}
                      className="me-2 text-mute"
                    />
                  }
                  image={bg1}
                />
              </Col>
              <Col xs={6} md={3}>
                <LeadStage
                  data={data.lead_source_name}
                  label={"Lead Source"}
                  variant="success"
                  icon={<MdSource size={17} className="me-2 text-mute" />}
                  image={bg3}
                />
              </Col>
              <Col xs={6} md={3}>
                <LeadStage
                  data={data.lead_status}
                  label={"Lead Status"}
                  variant={
                    data.lead_status === "Lead initiated"
                      ? "warning"
                      : data.lead_priority === "Not Qualified"
                      ? "danger"
                      : data.lead_priority === "Lost Lead"
                      ? "danger"
                      : data.lead_priority === "Contacted"
                      ? "info"
                      : "success"
                  }
                  icon={<FaInstalod size={17} className="me-2 text-mute" />}
                  image={bg2}
                />
              </Col>
              <Col xs={6} md={3}>
                <LeadStage
                  data={data.lead_priority}
                  label={"Lead Priority"}
                  variant={
                    data.lead_priority === "high"
                      ? "danger"
                      : data.lead_priority === "medium"
                      ? "warning"
                      : "primary"
                  }
                  icon={<MdPriorityHigh size={17} className="me-2 text-mute" />}
                  image={bg2}
                />
              </Col>
              <Col xs={6} md={3}>
                <LeadStage
                  data={data.business_current_status}
                  label={"Business Current Status"}
                  variant="success"
                  icon={
                    <MdBusinessCenter size={17} className="me-2 text-mute" />
                  }
                  image={bg3}
                />
              </Col>
            </Row>
            <Row className="mt-3 gy-3">
              <Col md={12} sm={12} className="">
                <div className="d-flex gap-3">
                  <div className="border w-50">
                    <div className="border-bottom p-3">
                      <span className="">Lead Information</span>
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                      <div className="d-flex flex-column p-2">
                        {data?.lead_owner_name && (
                          <LeadSingleDetails
                            label={"Lead Owner"}
                            data={data.lead_owner_name}
                            variant={"warning"}
                            icon={<FaUserTie size={13} />}
                          />
                        )}
                        {data?.phone_no && (
                          <LeadSingleDetails
                            label={"Contact Number"}
                            data={data.phone_no}
                            variant={"success"}
                            icon={<MdLocalPhone size={13} />}
                          />
                        )}
                        {data?.email && (
                          <LeadSingleDetails
                            label={"Email Address"}
                            data={data.email}
                            variant={"danger"}
                            icon={<MdOutgoingMail size={13} />}
                          />
                        )}
                        {data?.website && (
                          <LeadSingleDetails
                            label={"Website"}
                            data={data.website}
                            variant={"primary"}
                            icon={<FaGlobeAmericas size={13} />}
                          />
                        )}
                        {data?.no_of_employees && data?.no_of_employees >= 0 ? (
                          <LeadSingleDetails
                            label={"Annual Revenue"}
                            data={data.annual_revenue}
                            variant={"info"}
                            icon={<BsGraphUpArrow size={13} />}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="d-flex flex-column p-2">
                        {data?.company_name && (
                          <LeadSingleDetails
                            label={"Company Name"}
                            data={data.company_name}
                            variant={"primary"}
                            icon={<FaRegBuilding size={13} />}
                          />
                        )}

                        {data?.industry && (
                          <LeadSingleDetails
                            label={"Industry"}
                            data={data.industry}
                            variant={"secondary"}
                            icon={<FaBuilding size={13} />}
                          />
                        )}
                        {data?.fax && (
                          <LeadSingleDetails
                            label={"Fax"}
                            data={data.fax}
                            variant={"danger"}
                            icon={<FaBuilding size={13} />}
                          />
                        )}
                        {data?.no_of_employees && data?.no_of_employees >= 0 ? (
                          <LeadSingleDetails
                            label={"No Of Employees"}
                            data={data.no_of_employees}
                            variant={"warning"}
                            icon={<FaUsers size={13} />}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="border w-50">
                    <div className="border-bottom p-3">
                      <span className="">Primary Contact Information</span>
                    </div>
                    <div className="d-flex flex-row flex-wrap">
                      <div className="d-flex flex-column p-2 pb-0">
                        {data?.primary_contact[0]?.full_name && (
                          <LeadSingleDetails
                            label={"Name"}
                            data={data.primary_contact[0].full_name}
                            variant={"warning"}
                            icon={<FaUserTie size={13} />}
                          />
                        )}
                        {data?.primary_contact[0]?.email && (
                          <LeadSingleDetails
                            label={"Email Address"}
                            data={data.primary_contact[0].email}
                            variant={"danger"}
                            icon={<MdOutgoingMail size={13} />}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column p-2 pb-0">
                        {data?.primary_contact[0]?.phone_no && (
                          <LeadSingleDetails
                            label={"Contact Number"}
                            data={data.primary_contact[0].phone_no}
                            variant={"success"}
                            icon={<MdLocalPhone size={13} />}
                          />
                        )}
                        {data?.primary_contact[0]?.secondary_email && (
                          <LeadSingleDetails
                            label={"Secondary Email"}
                            data={data.primary_contact[0].secondary_email}
                            variant={"info"}
                            icon={<MdOutgoingMail size={13} />}
                          />
                        )}
                      </div>
                      <div className="d-flex flex-column p-2 pt-0">
                        {data?.primary_contact[0] && (
                          <LeadSingleDetails
                            label={"Location"}
                            data={`${
                              data?.primary_contact[0].city
                                ? `${data.primary_contact[0].city} ,`
                                : ""
                            } ${
                              data?.primary_contact[0].state
                                ? `${data.primary_contact[0].state} ,`
                                : ""
                            } ${
                              data?.primary_contact[0].country
                                ? `${data.primary_contact[0].country} ,`
                                : ""
                            } ${
                              data?.primary_contact[0].zip_code
                                ? `${data.primary_contact[0].zip_code}`
                                : ""
                            }`}
                            variant={"warning"}
                            icon={<MdOutlineLocationOn size={13} />}
                          />
                        )}

                        {data?.primary_contact[0]?.address && (
                          <LeadSingleDetails
                            label={"Address"}
                            data={data.primary_contact[0].address}
                            variant={"dark"}
                            icon={<MdOutlineLocationOn size={13} />}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12} className="">
                <div className="border">
                  <div className="border-bottom p-3">
                    <span className="">Description</span>
                  </div>
                  <div className="p-3">
                    <span>{data?.description}</span>
                  </div>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}

Overview.propTypes = {
  data: PropTypes.any,
};

export default Overview;

const LeadSingleDetails = ({ label, data, variant, icon }) => {
  return (
    <Container fluid className="p-2">
      <div className="d-flex align-items-center gap-3">
        <div>
          <SoftBadge bg={variant} className="p-2 rounded-2">
            {icon}
          </SoftBadge>
        </div>
        <div>
          <span
            className="lead-information text-center"
            style={{ color: "rgb(97,111,166)" }}
          >
            {label}
          </span>
          <h6 className="lead-information text-dark">{data}</h6>
        </div>
      </div>
    </Container>
  );
};
