import React, { useState } from "react";
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaFilter, FaTimes } from "react-icons/fa";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ReportFilterOffcanvas from "./ReportFilterOffcanvas";
import {
  leadSourceSummeryItemReportFilterKeys,
  leadSourceSummeryReportFilterKeys,
} from "module/common/helpers/reportsCommonFilters";
import removeRefData from "helpers/removeRefData";
import removeEmptyFields from "helpers/removeEmptyFields";

const ReportFilterButton = ({
  search,
  url,
  filters,
  setFilters,
  columns = {},
}) => {
  const { lead_source_id } = useParams();
  const [queryParams] = useSearchParams();
  let filtersQueryParam = queryParams.get("filters");
  const navigate = useNavigate();
  const [offcanvasVisibility, setOffcanvasVisibility] = useState(false);

  const handleFilterOffCanvas = (status = false) =>
    setOffcanvasVisibility(status);

  const isButtonNeeded =
    url === `reports/lead-source-summary-report` ||
    url === `reports/lead-source-summary-report/${lead_source_id}`;

  const handleClearFilters = () => {
    if (url === `reports/lead-source-summary-report`) {
      const parsedFilterItems = JSON.parse(filtersQueryParam);
      const newLeadSourceSummeryReportFilterKeys = {
        ...leadSourceSummeryReportFilterKeys,
        date_range: parsedFilterItems?.date_range
          ? parsedFilterItems?.date_range
          : "",
        date_range_for: parsedFilterItems?.date_range_for
          ? parsedFilterItems?.date_range_for
          : "",
        from_date: parsedFilterItems?.from_date
          ? parsedFilterItems?.from_date
          : "",
        to_date: parsedFilterItems?.to_date ? parsedFilterItems?.to_date : "",
      };
      const filteredObject = removeEmptyFields(
        removeRefData(newLeadSourceSummeryReportFilterKeys)
      );
      const parsedFilteredObject = JSON.stringify(filteredObject);
      navigate(
        `/reports/lead-source-summary-report?${
          Object.keys(filteredObject).length > 0
            ? createSearchParams({ filters: parsedFilteredObject })
            : ""
        }`,
        { replace: true }
      );
    } else if (url === `reports/lead-source-summary-report/${lead_source_id}`) {
      const parsedFilterItems = JSON.parse(filtersQueryParam);
      const newLeadSourceSummeryItemReportFilterKeys = {
        ...leadSourceSummeryItemReportFilterKeys,
        date_range: parsedFilterItems?.date_range
          ? parsedFilterItems?.date_range
          : "",
        date_range_for: parsedFilterItems?.date_range_for
          ? parsedFilterItems?.date_range_for
          : "",
        from_date: parsedFilterItems?.from_date
          ? parsedFilterItems?.from_date
          : "",
        to_date: parsedFilterItems?.to_date ? parsedFilterItems?.to_date : "",
      };
      const filteredObject = removeEmptyFields(
        removeRefData(newLeadSourceSummeryItemReportFilterKeys)
      );
      const parsedFilteredObject = JSON.stringify(filteredObject);
      navigate(
        `/reports/lead-source-summary-report/${lead_source_id}?${
          Object.keys(filteredObject).length > 0
            ? createSearchParams({ filters: parsedFilteredObject })
            : ""
        }`,
        { replace: true }
      );
    } else {
      queryParams.delete("filters");
      search = search ?? {};
      if (typeof search === "object") {
        Object.keys(search).map((key) => queryParams.append(key, search[key]));
      }
      navigate(
        {
          pathname: "/" + url,
          search: `?${queryParams}`,
        },
        { replace: true }
      );
    }
  };

  return (
    <div>
      <ButtonGroup aria-label="filter-button-group" className={`rounded-0 `}>
        <Button
          variant="transparent"
          className=" shadow-none text-uppercase"
          onClick={() => handleFilterOffCanvas(true)}
        >
          <div className="text-warning">
            <FaFilter />
            <span className="fs--1 ms-1">Filter</span>
          </div>
        </Button>
        {filtersQueryParam && !isButtonNeeded && (
          <OverlayTrigger
            overlay={
              <Tooltip id="clear-filter-toolptip" className="small">
                Reset Filters
              </Tooltip>
            }
          >
            <Button
              size="sm"
              variant={"danger"}
              className={`d-flex flex-row align-items-center px-1 position-absolute rounded-circle`}
              style={{
                top: "-5px",
                right: "-2px",
                width: "17px",
                height: "17px",
                zIndex: "1",
              }}
              onClick={handleClearFilters}
            >
              <>
                <FaTimes size={10} />
              </>
            </Button>
          </OverlayTrigger>
        )}
      </ButtonGroup>
      <ReportFilterOffcanvas
        offcanvasVisibility={offcanvasVisibility}
        handleFilterOffCanvas={handleFilterOffCanvas}
        filters={filters}
        setFilters={setFilters}
        handleClearFilters={handleClearFilters}
        url={url}
        columns={columns}
      />
    </div>
  );
};

export default ReportFilterButton;
