import ColumnSelector from "module/common/Filter/ColumnSelector";
import React, { useContext } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectProject from "components/form/SelectProject";
import SelectUsers from "components/form/SelectUsers";
import SelectTaskPriority from "components/form/SelectTaskPriority";
import MutiSelectCheckBoxTaskStatus from "components/form/MutiSelectCheckBoxTaskStatus";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { showToast } from "module/common/Toast/toast";
import { FcClearFilters } from "react-icons/fc";
import { commonTaskFilterFormKeys } from "helpers/formKeys";
import { AuthWizardContext } from "context/Context";
import DateRangePicker from "module/common/Filter/DateRangePicker";
import SelectSprint from "components/form/SelectSprint";

const CommonTaskFilter = ({ filterFields, setFilterFields, renderFrom }) => {
  const navigate = useNavigate();
  let user = useContext(AuthWizardContext);
  let loggedUsCustomer = user?.user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const pageNumber = queryParams.get("page");
  const columns = [
    { key: "start_date", value: "Start Date" },
    { key: "end_date", value: "End Date" },
  ];
  const activeTab = queryParams.get("tab");

  const handleFieldChange = (e, action) => {
    if (!action) {
      let value;
      if (e.target.name === "unassigned") {
        value = e.target.checked ? 1 : 0;
      } else {
        value = e.target.value;
      }
      setFilterFields({ ...filterFields, [e.target.name]: value });
    } else {
      let newFormData;
      if (action.action === "select-option") {
        if (action.name === "status_filter") {
          const labelArray = e.map((item) => item.value);
          newFormData = {
            ...filterFields,
            [action.name]: labelArray,
            [action.name + "_ref"]: e,
          };
        } else {
          newFormData = {
            ...filterFields,
            [action.name]: e.value,
            [action.name + "_ref"]: e,
          };
        }
      } else if (action.action === "clear") {
        if (action.name === "status_filter") {
          newFormData = {
            ...filterFields,
            [action.name]: [],
            [action.name + "_ref"]: [],
          };
        } else {
          newFormData = {
            ...filterFields,
            [action.name]: "",
            [action.name + "_ref"]: {
              label: "",
              value: "",
            },
          };
        }
      } else if (action.action === "remove-value") {
        newFormData = {
          ...filterFields,
          [action.name]: e.map((item) => item.value),
          [action.name + "_ref"]: e,
        };
      }
      setFilterFields(newFormData);
    }
  };

  const handleFilterApply = () => {
    if (!filterFields.date_range_for && filterFields.date_range) {
      showToast(`Please Select Date Field`, "error");
      return;
    }

    if (filterFields.date_range === "custom_date" && !filterFields.from_date) {
      showToast(`Please Select From Date`, "error");
      return;
    } else if (
      filterFields.date_range === "custom_date" &&
      !filterFields.to_date
    ) {
      showToast(`Please Select To Date`, "error");
      return;
    }

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          status_filter:
            filterFields?.status_filter &&
            filterFields?.status_filter?.join("/"),
          status_filter_ref: "",
          project_id_ref:
            filterFields?.project_id &&
            JSON.stringify(filterFields?.project_id_ref),
          sprint_id_ref:
            filterFields?.sprint_id &&
            JSON.stringify(filterFields?.sprint_id_ref),
          assignee_ref:
            filterFields?.assignee &&
            JSON.stringify(filterFields?.assignee_ref),
          priority_ref:
            filterFields?.priority &&
            JSON.stringify(filterFields?.priority_ref),
          created_by_ref:
            filterFields?.created_by &&
            JSON.stringify(filterFields?.created_by_ref),
          unassigned: filterFields?.unassigned ? 1 : "",
          page: pageNumber ? pageNumber : 1,
          tab: activeTab,
        })
      )}`
    );
  };

  const handleResetFilter = () => {
    setFilterFields(commonTaskFilterFormKeys);
  };

  return (
    <>
      <div className="p-3 border-bottom">
        <Row>
          <Col sm={2} className="mb-3">
            <ColumnSelector
              title="Date Field"
              labelClassName="fs--1"
              columns={columns}
              filters={filterFields}
              setFilters={setFilterFields}
              defaultOption={true}
              value={filterFields.date_range_for}
            />
          </Col>
          <Col sm={2} className="mb-3">
            <Form.Group>
              <Form.Label className="fs--1">Date Range</Form.Label>
              <DateRangePicker
                formData={filterFields}
                className={"custom-date-range-picker"}
                setFormData={setFilterFields}
                dateRange_form={{
                  to: "to_date",
                  from: "from_date",
                  range: "date_range",
                  month: "month",
                }}
              />
            </Form.Group>
          </Col>
          <Col sm={2} className="mb-3">
            <Form.Group>
              <Form.Label className="fs--1">Task No</Form.Label>
              <Col>
                <Form.Control
                  type="number"
                  name="task_no"
                  onChange={handleFieldChange}
                  value={filterFields.task_no}
                />
              </Col>
            </Form.Group>
          </Col>
          <Col sm={2} className="mb-3">
            <Form.Group>
              <Form.Label className="fs--1">Title</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="title"
                  onChange={handleFieldChange}
                  value={filterFields.title}
                />
              </Col>
            </Form.Group>
          </Col>
          {renderFrom === "project" || renderFrom === "sprint" ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Project</Form.Label>
                <Col>
                  <SelectProject
                    name="project_id"
                    handleFieldChange={handleFieldChange}
                    value={filterFields.project_id_ref}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {renderFrom === "sprint" || renderFrom === "project" ? (
            ""
          ) : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Sprint</Form.Label>
                <Col>
                  <SelectSprint
                    name="sprint_id"
                    handleFieldChange={handleFieldChange}
                    value={filterFields.sprint_id_ref}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {loggedUsCustomer ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Assignee</Form.Label>
                <Col>
                  <SelectUsers
                    name="assignee"
                    value={filterFields.assignee_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {loggedUsCustomer ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Priority</Form.Label>
                <Col>
                  <SelectTaskPriority
                    name="priority"
                    value={filterFields.priority_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {loggedUsCustomer ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Created By</Form.Label>
                <Col>
                  <SelectUsers
                    name="created_by"
                    value={filterFields.created_by_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {loggedUsCustomer ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Status</Form.Label>
                <Col>
                  <MutiSelectCheckBoxTaskStatus
                    name="status_filter"
                    value={filterFields.status_filter_ref}
                    handleFieldChange={handleFieldChange}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          {loggedUsCustomer ? null : (
            <Col sm={2} className="mb-3">
              <Form.Group>
                <Form.Label className="fs--1">Unassigned</Form.Label>
                <Col>
                  <Form.Check
                    type="switch"
                    className="custom-common-switch mt-1"
                    id="custom-switch"
                    size={"sm"}
                    name="unassigned"
                    onChange={handleFieldChange}
                    checked={filterFields.unassigned}
                  />
                </Col>
              </Form.Group>
            </Col>
          )}
          <Row className="m-0">
            <Form.Group
              as={Col}
              md={2}
              className="d-flex justify-content-end w-100 p-0"
            >
              <div className="d-flex align-items-end gap-2">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={handleFilterApply}
                  title="Apply Added Filters"
                >
                  Apply
                </Button>
                <Button
                  size="sm"
                  variant="transparent"
                  className="shadow-sm"
                  onClick={handleResetFilter}
                  title="Clear All Filters"
                >
                  <FcClearFilters size={18} />
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Row>
      </div>
    </>
  );
};

export default CommonTaskFilter;
