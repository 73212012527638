import React from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Offcanvas,
  Row,
} from "react-bootstrap";
import { FaCircle, FaMoneyBillAlt } from "react-icons/fa";
import {
  TbAddressBook,
  TbCheck,
  TbInfoCircle,
  TbMail,
  TbPhone,
  TbShoppingCart,
  TbUsers,
} from "react-icons/tb";
import ProfileImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import { BsCalculator } from "react-icons/bs";

const CustomerDetailsModal = ({
  customerDetailShow,
  handleClose,
  customerDetails,
}) => {
  const { details, customer, status } = customerDetails;
  return (
    <>
      <Offcanvas
        show={customerDetailShow}
        onHide={handleClose}
        placement="end"
        style={{ width: "80vh" }}
        className="customer_detail_offcanvas"
      >
        <Offcanvas.Header
          closeButton
          className="d-flex flex-row justify-content-between border-bottom gap-1"
        >
          <div className="d-flex align-items-center pt-2 pb-2">
            <BsCalculator size={22} className="me-2 text-dark" />
            <span className="custom-module-header text-uppercase">
              Estimation Details
            </span>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ padding: "25px 10px 0px 10px" }}>
          <Row>
            <Col>
              <Card body className="shadow-none">
                <ListGroup
                  horizontal
                  className="d-flex flex-wrap border rounded-0 pt-2 pb-2"
                >
                  <ListGroup.Item className="border-0">
                    <Image
                      className="custom-image-crm"
                      src={customer?.image ? customer.image : ProfileImage}
                      roundedCircle
                      alt="?"
                    />
                  </ListGroup.Item>
                  <ListGroup.Item className="flex-fill border-0">
                    <span style={{ fontSize: "16px" }} className="fw-bold">
                      {customer?.name}
                      <FaCircle
                        color={
                          status === "open"
                            ? "#84cb66"
                            : status === "pending"
                            ? "#03f4fc"
                            : "#f55b5b"
                        }
                        size={9}
                        className="ms-2 mb-1"
                      />
                    </span>
                    <div className="mt-1">
                      <TbMail size={18} color="#EA4335" />
                      <span className="ps-2">{customer?.email}</span>
                    </div>
                    <div className="mt-3">
                      <span>
                        <TbUsers className="me-2" size={17} color="#1565C0" />
                        Customer Type
                      </span>
                      <p className="fw-bold ps-4 text-capitalize">
                        {customer?.customer_type
                          ? customer.customer_type?.toLowerCase()
                          : "- - -"}
                      </p>
                    </div>
                    <div className="d-flex flex-column">
                      <span>
                        <TbShoppingCart
                          className="me-2"
                          size={20}
                          color="#FF9800"
                        />
                        Sales Type
                      </span>
                      <span className="fw-bold ps-4 text-capitalize">
                        {customer?.sales_type_name
                          ? customer.sales_type_name
                          : "- - -"}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="flex-fill border-0">
                    <div className="d-flex flex-column h-100">
                      <div className="d-flex align-items-center mt-3 pt-1">
                        <TbPhone size={18} color="#009688" />
                        <span className="ms-2">{customer?.phone}</span>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <span>
                          <TbAddressBook
                            className="me-2"
                            size={20}
                            color="#1E88E5"
                          />
                          Address
                        </span>
                        <span className="fw-bold ps-4 text-capitalize">
                          {customer?.address ? customer.address : "- - -"}
                        </span>
                      </div>
                      <div className="d-flex flex-column mt-3">
                        <span>
                          <FaMoneyBillAlt
                            className="me-2"
                            size={17}
                            color="#9FA8DA"
                          />
                          Tax Name
                        </span>
                        <span className="fw-bold ps-4 text-capitalize">
                          {customer?.tax_name
                            ? customer.tax_name?.toLowerCase()
                            : "---"}
                        </span>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup
                  horizontal
                  className="d-flex flex-wrap border rounded-0 pt-2 pb-2 mt-3"
                >
                  <ListGroup.Item className="flex-fill border-0">
                    <div className="d-flex flex-column gap-1">
                      <span className="details_title custom-text-muted-crm">
                        Date
                      </span>
                      <span
                        style={{ color: "#616668", fontSize: "17px" }}
                        className="fw-bold"
                      >
                        <span style={{ fontSize: "15px" }}>
                          {customerDetails?.date_formatted}
                        </span>
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="flex-fill border-0">
                    <div className="d-flex flex-column gap-1">
                      <span className="details_title custom-text-muted-crm">
                        Valid Until
                      </span>
                      <span
                        style={{ color: "#616668", fontSize: "17px" }}
                        className="fw-bold"
                      >
                        <span style={{ fontSize: "15px" }}>
                          {customerDetails?.valid_until_formatted}
                        </span>
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="flex-fill border-0">
                    <div className="d-flex flex-column gap-1">
                      <span className="details_title custom-text-muted-crm">
                        Note
                      </span>
                      <span
                        style={{ color: "#616668", fontSize: "17px" }}
                        className="fw-bold"
                      >
                        <span style={{ fontSize: "15px" }}>
                          {customerDetails?.notes}
                        </span>
                      </span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>

                {details && details.length > 0 && (
                  <>
                    <div className="mt-3 d-flex align-items-center">
                      <TbInfoCircle size={20} className="text-secondary" />
                      <span
                        style={{
                          fontSize: "16px",
                          letterSpacing: "0.7px",
                        }}
                        className="fw-bold ms-1"
                      >
                        Details
                      </span>
                    </div>
                    <div className="mt-2">
                      {details?.map((customer_detail, index) => (
                        <ListGroup horizontal key={index}>
                          <ListGroupItem
                            style={{ minWidth: "25%" }}
                            className={`rounded-0 ${
                              index > 0 ? "border-top-0" : ""
                            }`}
                          >
                            <div className="d-flex flex-column gap-1">
                              <span className="details_title custom-text-muted-crm">
                                Title
                              </span>
                              <span
                                style={{ color: "#616668", fontSize: "17px" }}
                                className="fw-bold"
                              >
                                <span style={{ fontSize: "15px" }}>
                                  {customer_detail?.title}
                                </span>
                              </span>
                            </div>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ minWidth: "25%" }}
                            className={`rounded-0 ${
                              index > 0 ? "border-top-0" : ""
                            }`}
                          >
                            <div className="d-flex flex-column gap-1">
                              <span className="details_title custom-text-muted-crm">
                                Stock
                              </span>
                              <div
                                style={{ color: "#616668", fontSize: "17px" }}
                                className="fw-bold"
                              >
                                {customer_detail.stock_id
                                  ? customer_detail.stock_id
                                  : "N/A"}
                              </div>
                            </div>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ minWidth: "25%" }}
                            className={`rounded-0 ${
                              index > 0 ? "border-top-0" : ""
                            }`}
                          >
                            <div className="d-flex flex-column gap-1">
                              <span className="details_title custom-text-muted-crm">
                                Amount
                              </span>
                              <span
                                style={{ color: "#616668", fontSize: "17px" }}
                                className="fw-bold"
                              >
                                <span style={{ fontSize: "15px" }}>
                                  {customer_detail?.amount}
                                </span>
                              </span>
                            </div>
                          </ListGroupItem>
                          <ListGroupItem
                            style={{ minWidth: "25%" }}
                            className={`rounded-0 ${
                              index > 0 ? "border-top-0" : ""
                            }`}
                          >
                            <div className="d-flex flex-column gap-1">
                              <span className="details_title custom-text-muted-crm">
                                Quantity
                              </span>
                              <div
                                style={{ color: "#616668", fontSize: "17px" }}
                                className="fw-bold"
                              >
                                <span style={{ fontSize: "15px" }}>
                                  {customer_detail?.quantity}
                                </span>
                              </div>
                            </div>
                          </ListGroupItem>
                        </ListGroup>
                      ))}
                    </div>
                  </>
                )}

                <div className="mt-4">
                  <ButtonGroup
                    className="w-100 border rounded-0"
                    style={{ backgroundColor: "#e9e9e9" }}
                  >
                    <Button
                      className="custom-transperent-common-button"
                      variant={status === "open" ? "success" : "transperent"}
                    >
                      {status === "open" && (
                        <TbCheck className="text-light me-1" size={18} />
                      )}
                      Open
                    </Button>
                    <Button
                      className="custom-transperent-common-button"
                      variant={status === "pending" ? "warning" : "transperent"}
                    >
                      {status === "pending" && (
                        <TbCheck className="text-light me-1" size={18} />
                      )}
                      Pending
                    </Button>
                    <Button
                      className="custom-transperent-common-button"
                      variant={status === "closed" ? "danger" : "transperent"}
                    >
                      {status === "closed" && (
                        <TbCheck className="text-light me-1" size={18} />
                      )}
                      Closed
                    </Button>
                  </ButtonGroup>
                </div>
              </Card>
            </Col>
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default CustomerDetailsModal;
