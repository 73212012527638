import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { AiFillPlusCircle } from "react-icons/ai";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import SoftBadge from "components/common/SoftBadge";
import { DeleteContext } from "context/Context";
import ListLoading from "module/common/ListLoading";
import CallsForm from "./Form/CallsForm";
import useAxisproPermission from "hooks/useAxisproPermission";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import SimpleBar from "simplebar-react";

function Calls({ type, itemId, overview }) {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const [data, setData] = useState([]);
  const { overviewId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const permissions = ["store", "update", "delete"];
  const permissionObject = {};
  permissions.forEach((permission) => {
    permissionObject[`lead_${permission}_calls`] =
      axisProPermission(`${permission}-lead-call`) && type === "LEAD";
    permissionObject[`opportunity_${permission}_calls`] =
      axisProPermission(`${permission}-opportunity-call`) &&
      type === "OPPORTUNITY";
  });

  const columns = [
    {
      accessor: "purpose",
      Header: "PURPOSE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { purpose } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{purpose}</h5>;
      },
    },
    {
      accessor: "start_time_formatted",
      Header: "START TIME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { start_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{start_time_formatted}</h5>;
      },
    },
    {
      accessor: "end_time_formatted",
      Header: "END TIME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { end_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{end_time_formatted}</h5>;
      },
    },
    {
      accessor: "related_to_object.first_name",
      Header: "CONTACT TO NAME",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { call_to_contact } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{call_to_contact?.first_name}</h5>;
      },
    },
    {
      accessor: "related_to_object.phone_no",
      Header: "CONTACT TO PHONE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { call_to_contact } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{call_to_contact?.phone_no}</h5>;
      },
    },
    {
      accessor: "type",
      Header: "TYPE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { type } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{type}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <h5 className="mb-0 fs--1">
            <SoftBadge bg={`${status === "Completed" ? "success" : "warning"}`}>
              {status}
            </SoftBadge>
          </h5>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: [
          permissionObject.lead_update_calls,
          permissionObject.opportunity_update_calls,
          permissionObject.lead_delete_calls,
          permissionObject.opportunity_delete_calls,
        ].some(Boolean)
          ? ""
          : "d-none",
      },
      disableSortBy: true,
      cellProps: {
        className: [
          permissionObject.lead_update_calls,
          permissionObject.opportunity_update_calls,
          permissionObject.lead_delete_calls,
          permissionObject.opportunity_delete_calls,
        ].some(Boolean)
          ? "text-end"
          : "text-end d-none",
      },

      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {(permissionObject.lead_update_calls ||
              permissionObject.opportunity_update_calls) && (
              <EditButton
                route={`?${createSearchParams({
                  ...allQueryParams,
                  add_call: true,
                  edit_id: id,
                })}`}
              />
            )}
            {(permissionObject.lead_delete_calls ||
              permissionObject.opportunity_delete_calls) && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];
  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`crm/calls?related_to_id=${itemId}&related_to_type=${type}`)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/calls/" + id,
        title: "Delete Call",
        message: "Are you sure you want to delete this Call info?",
        onSuccess: () => {
          fetchData();
        },
      },
    });
  };

  return (
    <>
      {!isLoading ? (
        <Row>
          <Col xs={12}>
            <Card className="d-flex shadow-none">
              <Card.Header
                className={` ${
                  type === "OPPORTUNITY"
                    ? "pb-2 pt-2"
                    : "border-bottom pb-3 pt-3"
                } rounded-0 ps-2`}
                style={{
                  backgroundColor: type === "OPPORTUNITY" ? "#d2d4e9" : "",
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3"> CALL INFORMATION</div>
                  {((!overviewId && itemId) || type === "LEAD") && (
                    <CallsForm getData={fetchData} type={type} />
                  )}
                  {(permissionObject.lead_store_calls ||
                    permissionObject.opportunity_store_calls) && (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            add_call: true,
                          })}`
                        );
                      }}
                    >
                      <AiFillPlusCircle size={22} className="text-primary" />
                    </div>
                  )}
                </div>
              </Card.Header>

              <Card.Body
                className={`p-0 rounded-0`}
                style={{
                  height:
                    type === "OPPORTUNITY"
                      ? "36.8rem"
                      : overview === "lead"
                      ? "100"
                      : "41rem",
                }}
              >
                {data.data?.length > 0 ? (
                  <div className="mt-3">
                    <AdvanceTableWrapper
                      columns={columns}
                      data={data.data}
                      sortable
                    >
                      <SimpleBar>
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            size: "sm",
                            striped: true,
                            className: "fs--1 mb-0  overflow-hidden",
                          }}
                          responsive={overview === "lead" ? false : true}
                        />
                      </SimpleBar>
                    </AdvanceTableWrapper>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    No call Information! 🙂
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading />
      )}
    </>
  );
}

Calls.propTypes = {
  data: PropTypes.any,
};

export default Calls;
