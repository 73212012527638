import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonTodoFilter from "../SupportingComponents/CommonTodoFilter";
import ShowMore from "components/common/ShowMore";

const TodoTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [todos, setTodos] = useState({});
  const pageNumber = queryParams.get("page");
  const [filterVisibility, setFilterVisibility] = useState(true);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission] = [
    "store-todo",
    "update-todo",
    "delete-todo",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const { title, status } = allQueryParams;

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/crm-todo/" + data,
        title: "Delete Todo item",
        message: "Are you sure you want to delete this todo?",
        onSuccess: () => {
          const dataWithoutDeletedItem = todos?.data?.filter(
            (todo) => todo.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/todo?" +
              createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setTodos((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "title",
      Header: "TITLE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { title } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{title}</h5>;
      },
    },
    {
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return <div className="mb-0 fs--1">
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={60}
            uniqueKey={id}
          />
        </div>;


      },
    },
    {
      accessor: "label",
      Header: "LABEL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { label } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{label}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <Badge
            bg={
              status === "done"
                ? "success"
                : status === "to_do"
                  ? "secondary"
                  : ""
            }
          >
            {status.replace("_", " ")}
          </Badge>
        );
      },
    },

    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${actionsPermission ? "" : "d-none"
          }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${actionsPermission ? "" : "d-none"
          }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {updatePermission && (
              <EditButton
                route={`/todo/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page) => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: "crm/crm-todo",
        params: {
          page: page,
          title: title,
          status: status,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setTodos(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, title, status]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between p-3 ${!filterVisibility ? "border-bottom" : ""
              }`}
          >
            <div className="d-flex  align-items-center">
              <FontAwesomeIcon
                icon="list-check"
                size={"xl"}
                className="me-2 text-dark"
              />
              <span className="fs-1 text-uppercase">Todo List</span>
            </div>
            <div className="d-flex gap-2">
              {createPermission && (
                <AddButton
                  to={`/todo/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonTodoFilter />
          </div>
        </Card.Header>
        {!isLoading && todos?.data?.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={todos?.data} sortable>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {todos && todos?.links && (
                <AppPagination
                  data={todos}
                  url="/todo"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for todos" />
            ) : (
              <h5 className="text-center text-secondary">
                Todo not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {createPermission && (
                  <Button
                    className="m-1"
                    variant="success"
                    size="sm"
                    as={Link}
                    to={`/todo/add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                  >
                    Add Todo
                  </Button>
                )}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default TodoTableView;
