import { useState } from "react";
import { Link } from "react-router-dom";

const ShowMore = ({ content, contentLimit = 40, uniqueKey, contentRoute }) => {
  const [showMoreContent, setShowMoreContent] = useState();
  const [readmoreStatus, setReadmoreStatus] = useState(false);
  const isExpanded = showMoreContent === uniqueKey && readmoreStatus;
  const displayText = isExpanded ? content : content?.slice(0, contentLimit);
  const toggleText = isExpanded ? " ...Show less" : " ...Read more";

  const handleShowMore = (id) => {
    if (showMoreContent === id) {
      setReadmoreStatus(!readmoreStatus);
    } else {
      setReadmoreStatus(true);
    }
    setShowMoreContent(id);
  };

  return (
    <div className="pt-1">
      {displayText && (
        <>
          {contentRoute ? (
            <Link className="text-decoration-none text-muted" to={contentRoute}>
              {displayText}
            </Link>
          ) : (
              <span className="text-decoration-none text-muted">{displayText}</span>
          )}
        </>
      )}
      {content?.length > contentLimit && (
        <span
          onClick={() => handleShowMore(uniqueKey)}
          className="custom-clickable-link-crm"
        >
          {toggleText}
        </span>
      )}
    </div>
  );
};

export default ShowMore;
