import { React, useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Col, Offcanvas, Row, Form, Button } from "react-bootstrap";
import { BiTask } from "react-icons/bi";
import { apiCall } from "helpers/apiCalls";
import {
  CloseButton,
  SaveButton,
  SaveNewButton,
} from "module/common/Buttons/CommonButton";
import { tasksFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import SelectUsers from "components/form/SelectUsers";
import MutiSelectTaskLabel from "../../../components/form/MutiSelectTaskLabel";
import removeRefData from "helpers/removeRefData";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectProject from "components/form/SelectProject";
import ListLoading from "module/common/ListLoading";
import MultiSelectCollaborator from "components/form/MultiSelectCollaborator";
import TextEditor from "module/common/TextEditor/TextEditor";
import removeEmptyFields from "helpers/removeEmptyFields";
import SelectTaskPriority from "components/form/SelectTaskPriority";
import AppTimePicker from "components/app-time-picker/AppTimePicker";
import SelectSprint from "components/form/SelectSprint";
import useAxisproPermission from "hooks/useAxisproPermission";

function TaskForm() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState(tasksFormKeys);
  const [queryParams] = useSearchParams();
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);
  let { itemId, itemInfoId, overviewId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const pageNumber = queryParams.get("page");
  const statusFilter = queryParams.get("status_filter");
  const location = useLocation();
  const [getData] = useOutletContext();
  const renderFrom = location.pathname.split("/")[1];
  const allQueryParams = Object.fromEntries([...queryParams]);
  const commonSearchParams = {
    ...allQueryParams,
    tab: "tasklist",
    status_filter: statusFilter ?? "",
    page: itemId ? pageNumber : 1,
  };
  const projectId = queryParams.get("project");
  const [onSaveNew, setOnSaveNew] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [
    updateTaskStatusPermission,
    updateTaskLabelPermission,
    updateTaskAssigneePermission,
  ] = ["update-task-status", "update-task-label", "update-task-assignee"].map(
    (permission) => axisProPermission(permission)
  );

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: `crm/task/${itemId}`,
    });
    const labelArray = data.labels.map((item) => ({
      label: item.label,
      value: item.id,
    }));

    const collabArray = data.collaborators.map((item) => ({
      label: item.user.name,
      value: item.user.id,
    }));

    setFormData({
      ...formData,
      project_id: data.project_id,
      project_id_ref: {
        label: data.project_title,
        value: data.project_id,
      },
      title: data.title,
      description: data.description,
      start_date: data.start_date,
      end_date: data.end_date,
      assigned_to: data.assigned_to,
      assigned_to_ref: {
        label: data.assignee
          ? data.assignee.first_name + " " + data.assignee.last_name
          : "",
        value: data.assignee ? data.assigned_to : "",
      },
      status: data.status,
      status_ref: { label: data.status.replace("_", " "), value: data.status },
      labels: labelArray.map((item) => ({ id: item.value })),
      labels_ref: labelArray,
      collaborators: collabArray.map((item) => ({ id: item.value })),
      collaborators_ref: collabArray,
      priority: data.priority,
      priority_ref: {
        label: data.priority,
        value: data.priority,
      },
      sprint_id: data?.sprint_id ?? "",
      sprint_id_ref: data?.sprint_id
        ? {
            label: data?.sprint_name,
            value: data?.sprint_id,
          }
        : "",
    });
    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      const editor = document.querySelector(".ql-editor");
      if (editor) {
        const images = editor.querySelectorAll("img");
        images.forEach((image) => {
          image.style.width = "150px";
          image.style.height = "auto";
        });
      }

      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        if (action.name === "labels" || action.name === "collaborators") {
          const labelArray = e.map((item) => ({ id: item.value }));
          value = labelArray;
        } else {
          value = e.code ? e.code : e.value;
        }
      } else if (action.action === "clear") {
        action.name === "sprint_id" || action.name === "assigned_to"
          ? (value = "")
          : (value = []);
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleNavigation = (saveAndNew) => {
    let navigatePath = "";
    if (renderFrom === "project") {
      navigatePath = `/project/info/${itemInfoId}${
        saveAndNew ? "/task/add" : ""
      }`;
    } else if (renderFrom === "sprints") {
      navigatePath = `/sprints/info/${itemInfoId}${
        saveAndNew ? "/task/add" : ""
      }`;
    } else {
      if (location.pathname.includes("list/overview")) {
        navigatePath = `/tasks/list/overview/${overviewId}`;
      } else if (location.pathname.includes("tasks/dashboard")) {
        navigatePath = `/tasks/dashboard`;
        delete commonSearchParams.page;
        delete commonSearchParams.tab;
      } else {
        navigatePath = `/tasks/list${saveAndNew ? "/add" : ""}`;
      }
    }

    const queryParamsTemp = createSearchParams(
      removeEmptyFields(commonSearchParams)
    );
    const navigateUrl = `${navigatePath}?${queryParamsTemp}${
      saveAndNew && projectId ? `&project=${projectId}` : ""
    }`;

    navigate(navigateUrl);
  };

  const handleSubmit = (e, saveAndNew) => {
    e.preventDefault();
    setFormError({});
    saveAndNew ? setOnSaveNew(true) : setOnSave(true);
    const formDataWithoutRefkeys = removeRefData({
      ...formData,
      ...(renderFrom === "project" && { project_id: itemInfoId }),
      ...(renderFrom === "sprints" && {
        sprint_id: itemInfoId,
        project_id: projectId ? projectId : formData.project_id,
      }),
      description: formData?.description?.replace("<p><br></p>", ""),
    });
    axios({
      method: itemId ? "put" : "post",
      url: `crm/task${itemId ? "/" + itemId : ""}`,
      data: removeEmptyFields(formDataWithoutRefkeys),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          handleNavigation(saveAndNew);
          saveAndNew && setFormData(tasksFormKeys);
          getData(!itemId ? 1 : pageNumber);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        saveAndNew ? setOnSaveNew(false) : setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });

          if ("task_id" in validation_error) {
            showToast(validation_error.task_id[0], "error");
          } else if ("task_update_permission" in validation_error) {
            showToast(validation_error.task_update_permission[0], "error");
          } else {
            showToast(error.response.data.message, "error");
          }
        } else {
          if (error.response.status === 413) {
            showToast(
              "The task description size is excessive. The task cannot be created."
            );
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        }
        saveAndNew ? setOnSaveNew(false) : setOnSave(false);
      });
  };

  return (
    <Offcanvas
      show
      placement="end"
      style={{
        width: window.innerWidth < 772 ? "100%" : "50%",
      }}
      {...{
        name: "Enable body scrolling",
        scroll: true,
        backdrop: true,
      }}
    >
      <Offcanvas.Header className="border-bottom">
        <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <span>
              <BiTask size={25} />
            </span>
            <span className="ms-2 fs-1 mt-1">
              {itemId ? "UPDATE TASK" : "CREATE TASK"}
            </span>
          </div>
          <CloseButton handleClick={() => handleNavigation()} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <>
        {dataProcessing ? (
          <ListLoading />
        ) : (
          <Offcanvas.Body>
            <Form
              style={{ minHeight: "100%" }}
              id="task-form"
              onFocus={() => setFormError({})}
              className="h-100 d-flex flex-column"
            >
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput2"
                  >
                    <Form.Label className="require-data">Title</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="title"
                      onChange={handleFieldChange}
                      value={formData.title}
                      isInvalid={!!formError.title}
                      onClick={() => setFormError({})}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  {renderFrom === "tasks" && (
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="require-data">Project</Form.Label>
                      <SelectProject
                        name="project_id"
                        value={formData.project_id_ref}
                        handleFieldChange={handleFieldChange}
                        error={formError.project_id}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.project_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>Assignee</Form.Label>
                    <SelectUsers
                      name="assigned_to"
                      value={formData.assigned_to_ref}
                      handleFieldChange={handleFieldChange}
                      error={formError.assigned_to}
                      disabled={!updateTaskAssigneePermission}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.assigned_to}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Collaborator</Form.Label>
                    <MultiSelectCollaborator
                      name="collaborators"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.collaborators}
                      value={formData.collaborators_ref}
                      excludeUser={formData.assigned_to}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.collaborators}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Label</Form.Label>
                    <MutiSelectTaskLabel
                      name="labels"
                      handleFieldChange={handleFieldChange}
                      error={!!formError.label}
                      value={formData.labels_ref}
                      disabled={!updateTaskLabelPermission}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formError.label}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col md={6} sm={12}>
                  <Row className="gx-2">
                    <Col lg={6} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data">Status</Form.Label>
                        <SelectTaskStatus
                          value={formData.status_ref}
                          name="status"
                          handleFieldChange={handleFieldChange}
                          error={!!formError.status}
                          disabled={!updateTaskStatusPermission}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.status}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6} md={12}>
                      <Form.Group className="mb-3">
                        <Form.Label>Priority</Form.Label>
                        <SelectTaskPriority
                          name="priority"
                          value={formData.priority_ref}
                          handleFieldChange={handleFieldChange}
                          error={formError.priority}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.priority}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">Start Date</Form.Label>
                    <AppTimePicker
                      name="start_date"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.start_date}
                      isInvalid={!!formError.start_date}
                      showDefaultDate={true}
                    />
                    <Form.Control.Feedback type="is-invalid">
                      {formError.start_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="require-data">End Date</Form.Label>
                    <AppTimePicker
                      name="end_date"
                      yearPlaceholder="yyyy"
                      monthPlaceholder="mm"
                      dayPlaceholder="dd"
                      hourPlaceholder="hh"
                      minutePlaceholder="mm"
                      onChange={handleFieldChange}
                      value={formData.end_date}
                      isInvalid={!!formError.end_date}
                      showDefaultDate={true}
                    />
                    <Form.Control.Feedback type="is-invalid">
                      {formError.end_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {renderFrom !== "sprints" && (
                    <Form.Group className="mb-3">
                      <Form.Label>Sprint</Form.Label>
                      <SelectSprint
                        value={formData.sprint_id_ref}
                        name="sprint_id"
                        handleFieldChange={handleFieldChange}
                        error={!!formError.sprint_id}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formError.sprint_id}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput3"
                  >
                    <Form.Label>Description</Form.Label>
                    <TextEditor
                      name="description"
                      handleFieldChange={handleFieldChange}
                      value={formData.description}
                      isInvalid={!!formError.description}
                    />
                    <Form.Control.Feedback type="is-invalid">
                      {formError.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex gap-1 mt-auto pb-3">
                <SaveButton
                  handleSubmit={handleSubmit}
                  onSave={onSave}
                  id={itemId}
                />
                {!itemId && (
                  <SaveNewButton
                    handleSubmit={(e) => handleSubmit(e, true)}
                    onSave={onSaveNew}
                  />
                )}
                <Button
                  variant={"danger"}
                  size={"sm"}
                  onClick={() => handleNavigation()}
                >
                  Cancel
                </Button>
              </div>
            </Form>
          </Offcanvas.Body>
        )}
      </>
    </Offcanvas>
  );
}

export default TaskForm;
