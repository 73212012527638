import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import axios from "axios";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import ListLoading from "module/common/ListLoading";
import useAxisproPermission from "hooks/useAxisproPermission";
import SimpleBar from "simplebar-react";
import ShowMore from "components/common/ShowMore";

function StageHistory({ type, itemId, stageData }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const axisProPermission = useAxisproPermission();
  const permissions = ["store", "update", "delete"];
  const permissionObject = {};
  permissions.forEach((permission) => {
    permissionObject[`lead_${permission}_calls`] =
      axisProPermission(`${permission}-lead-call`) && type === "LEAD";
    permissionObject[`opportunity_${permission}_calls`] =
      axisProPermission(`${permission}-opportunity-call`) &&
      type === "OPPORTUNITY";
  });

  const columns = [
    {
      width: "35%",
      accessor: "stage",
      Header: "STAGE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { stage, id } = rowData.row.original;
        return (
          <ShowMore
            content={stage ? stage : "N/A"}
            contentLimit={25}
            uniqueKey={id}
          />
        );
      },
    },
    {
      width: "45%",
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return (
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={35}
            uniqueKey={id}
          />
        );
      },
    },
    {
      width: "20%",
      accessor: "probablity",
      Header: "PROBABILITY",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { probablity } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{probablity}</h5>;
      },
    },
  ];

  const fetchData = () => {
    setIsLoading(true);
    axios
      .get(`crm/opportunity/update-stage-history/${itemId}`)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageData]);

  return (
    <>
      {!isLoading ? (
        <Row>
          <Col xs={12}>
            <Card className="d-flex shadow-none">
              <Card.Header
                className={` ${
                  type === "OPPORTUNITY"
                    ? "pb-2 pt-2"
                    : "border-bottom pb-3 pt-3"
                } rounded-0 ps-2`}
                style={{
                  backgroundColor: type === "OPPORTUNITY" ? "#d2d4e9" : "",
                }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3">STAGE HISTORY</div>
                </div>
              </Card.Header>

              <Card.Body
                className={`p-0 rounded-0`}
                style={{
                  height: type === "OPPORTUNITY" ? "36.8rem" : "41rem",
                }}
              >
                {data.data?.length > 0 ? (
                  <div className="mt-3">
                    <AdvanceTableWrapper
                      columns={columns}
                      data={data.data}
                      sortable
                    >
                      <SimpleBar>
                        <AdvanceTable
                          table
                          headerClassName="bg-200 text-900 text-nowrap align-middle"
                          rowClassName="align-middle white-space-nowrap"
                          tableProps={{
                            size: "sm",
                            striped: true,
                            className: "fs--1 mb-0  overflow-hidden",
                          }}
                          responsive={false}
                        />
                      </SimpleBar>
                    </AdvanceTableWrapper>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    No Stage History! 🙂
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading />
      )}
    </>
  );
}

StageHistory.propTypes = {
  data: PropTypes.any,
};

export default StageHistory;
