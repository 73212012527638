const handleTableItem = (data = {}) => {
  const returnItem = (item) => {
    if (item?.removeThisKeys && Array.isArray(item.removeThisKeys)) {
      let removableKeys = ["removeThisKeys", ...item.removeThisKeys];
      let itemkeys = item && typeof item === "object" ? Object.keys(item) : [];
      // eslint-disable-next-line
      removableKeys.map((key) => {
        if (itemkeys.includes(key)) {
          delete item[key];
        }
      });
    }
    if (!item?.has_batch_inventory) {
      delete item.batch_number;
      delete item.batch_number_ref;
    }
    return item;
  };

  if (
    data?.data &&
    ["ADD", "REMOVE", "UPDATE", "UPDATE-MULTIPLE", "REPLACE"].includes(
      data.method
    ) &&
    typeof data?.setFormData === "function"
  ) {
    data.setFormData((prev) => {
      let details;
      switch (data.method) {
        case "ADD":
          if (data?.data?.has_batch_inventory !== 1) {
            delete data.data.batch_number;
            delete data.data.batch_number_ref;
          }
          details = prev.details
            ? [
                ...prev.details,
                returnItem({ ...data.data, id_ref: prev.details.length + 1 }),
              ]
            : [data.data];
          break;

        case "REMOVE":
          details = prev.details.filter(
            (item) => item.id_ref !== data.data.id_ref
          );
          break;

        case "UPDATE":
          details = prev.details.map((item) => {
            return item.id_ref === data.data.id_ref
              ? returnItem({ ...item, ...data.data })
              : item;
          });
          break;

        case "UPDATE-MULTIPLE":
          details = prev.details.map((item) => {
            let newData = data.data.find(
              (newItem) => newItem.id_ref === item.id_ref
            );

            return newData ? returnItem({ ...item, ...newData }) : item;
          });
          break;

        case "REPLACE":
          details = prev.details.map((item) => {
            return item.id_ref === data.data.id_ref
              ? returnItem(data.data)
              : item;
          });
          break;

        default:
          details = [];
          break;
      }
      let newData = {
        ...prev,
        details: details,
      };
      if (
        data.handleSaveDraftData &&
        typeof data.handleSaveDraftData === "function" &&
        data.saveToDraft === true
      ) {
        data.handleSaveDraftData({ draftableData: newData });
      }
      return newData;
    });
  } else {
    console.error("invalid data");
  }
};

export default handleTableItem;
