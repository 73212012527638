import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FiPlus } from 'react-icons/fi';

const AddButton = ({ to, size, children, variant, className, ...rest }) => {
  return (
    <Button
      as={Link}
      to={to}
      size={size ?? 'sm'}
      variant={variant ?? 'success'}
      {...rest}
      className={`px-2 ${className}`}
    >
      {children ?? <FiPlus size={15} />}
    </Button>
  );
};

AddButton.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.string,
  to: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string
};

export default AddButton;
