import { AuthWizardContext } from "context/Context";
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";

const LandingPage = () => {
  const { user } = useContext(AuthWizardContext);

  return user ? (
    user?.customer_id ? (
      <Navigate to="/project" replace={true} />
    ) : (
      <Navigate to="/dashboard" replace={true} />
    )
  ) : (
    <Navigate to="/authentication/login" replace={true} />
  );
};

export default LandingPage;
