import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import FilterButton from "components/filter/FilterButton";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { BsCalendar2Range } from "react-icons/bs";
import { sprintCommonFilters } from "module/common/helpers/sprintCommonFilters";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonSprintFilter from "../SupportingComponets/CommonSprintFilter";
import ShowMore from "components/common/ShowMore";

const SprintTable = ({ projectId, renderFrom }) => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [sprintsList, setSprintsList] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [filters] = useState(sprintCommonFilters);
  const { itemInfoId } = useParams();
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission, showPermission] =
    ["store-sprint", "update-sprint", "delete-sprint", "show-sprint"].map(
      (permission) => axisProPermission(permission)
    );
  const actionsPermission = updatePermission || deletePermission;
  const [filterVisibility, setFilterVisibility] = useState(true);
  const { from_date, to_date, date_range_for, project_id, name, status } =
    allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/sprint/" + id,
        title: "Delete Sprint",
        message: "Are you sure you want to delete this sprint?",
        onSuccess: () => {
          const dataWithoutDeletedItem = sprintsList.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/sprints?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setSprintsList((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      width: "16.5%",
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text text-wrap",
      },
      Cell: (rowData) => {
        const { name, id } = rowData.row.original;
        return (
          <>
            <ShowMore
              content={name ? name : "N/A"}
              contentLimit={25}
              uniqueKey={id}

              contentRoute={
                showPermission && !projectId
                  ? `/sprints/info/${id}?${createSearchParams({
                    status_filter: defaultTaskStatusFilter.join("/"),
                    tab: "tasklist",
                    page: 1,
                  })}`
                  : `/project/info/${projectId}?${createSearchParams({
                    status_filter: defaultTaskStatusFilter.join("/"),
                    sprint_id: id,
                    sprint_id_ref: JSON.stringify({ value: id, label: name }),
                    tab: "tasklist",
                    page: 1,
                  })}`
              }
            />
          </>

        );
      },
    },
    {
      width: "23%",
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text text-wrap",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return (
          <>
            <ShowMore
              content={description ? description : "N/A"}
              contentLimit={35}
              uniqueKey={id}
            />
          </>
        );
      },
    },
    {
      width: "12.5%",
      accessor: "project_title",
      Header: "PROJECT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { project_title } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{project_title}</h5>;
      },
    },
    {
      width: "11%",
      accessor: "start_date_time_formatted",
      Header: "START AT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { start_date_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{start_date_time_formatted}</h5>;
      },
    },
    {
      width: "11.5%",
      accessor: "end_date_time_formatted",
      Header: "END AT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { end_date_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{end_date_time_formatted}</h5>;
      },
    },
    {
      width: "11%",
      accessor: "reference",
      Header: "REFERENCE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { reference } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{reference}</h5>;
      },
    },
    {
      width: "8.5%",
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{status}</h5>;
      },
    },
    {
      width: "5%",
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action py-3 ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex justify-content-center">
            {updatePermission && (
              <EditButton
                route={
                  projectId
                    ? `/project/info/${projectId}/sprints/edit/${id}?${createSearchParams(
                        {
                          ...allQueryParams,
                        }
                      )}`
                    : `/sprints/edit/${id}?${createSearchParams({
                        ...allQueryParams,
                      })}`
                }
              />
            )}
            {deletePermission && (
              <DeleteButton
                className={"ms-2"}
                onClick={() => handleDelete(id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: "crm/sprint",
        params: {
          project_id: projectId ? projectId : project_id ? project_id : "",
          page: page,
          from_date: from_date,
          to_date: to_date,
          date_range_for: date_range_for,
          name: name,
          status: status,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setSprintsList(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    from_date,
    to_date,
    date_range_for,
    project_id,
    name,
    status,
  ]);

  return (
    <>
      <Outlet context={[getData]} />
      {!itemInfoId || projectId ? (
        <>
          <Card className="flex-fill mb-3 mt-3">
            <Card.Header className="p-0">
              <div
                className={`d-flex justify-content-between align-items-center p-3 ${
                  !filterVisibility ? "border-bottom" : ""
                }`}
              >
                <div className="d-flex align-items-center">
                  <BsCalendar2Range size={22} className="me-2 text-dark" />
                  <span className="fs-1 text-uppercase">SPRINTS LIST</span>
                </div>
                <div className="d-flex gap-2">
                  {createPermission && (
                    <AddButton
                      to={
                        projectId
                          ? `/project/info/${projectId}/sprints/add?${createSearchParams(
                              {
                                ...allQueryParams,
                              }
                            )}`
                          : `/sprints/add?${createSearchParams({
                              ...allQueryParams,
                            })}`
                      }
                    />
                  )}
                  <FilterCommonButton
                    filterVisibility={filterVisibility}
                    setFilterVisibility={setFilterVisibility}
                  />
                </div>
              </div>
              <div style={{ display: filterVisibility ? "block" : "none" }}>
                <CommonSprintFilter renderFrom={renderFrom} />
              </div>
            </Card.Header>
            {!isLoading && sprintsList?.data?.length > 0 ? (
              <AdvanceTableWrapper
                columns={columns}
                data={sprintsList.data}
                sortable
              >
                <SimpleBar className="simplebar-style-with-pagination">
                  <Card.Body className="p-0">
                    <AdvanceTable
                      table
                      headerClassName="text-nowrap align-middle"
                      rowClassName="align-middle white-space-nowrap"
                      tablebodyClassName="custom-table-body"
                      tableProps={{
                        size: "sm",
                        className: "fs--1 mb-0 overflow-hidden custom-table",
                      }}
                    />
                  </Card.Body>
                </SimpleBar>
                <Card.Footer className="border-top p-1 pt-3">
                  {sprintsList?.links && (
                    <AppPagination
                      data={sprintsList}
                      url="/sprints"
                      search={allQueryParams}
                    />
                  )}
                </Card.Footer>
              </AdvanceTableWrapper>
            ) : (
              <LoadingScreenCover style={{ height: projectId ? "70vh" : "" }}>
                {isLoading ? (
                  <LoadingCommon loadingText="Looking for Sprints" />
                ) : (
                  <h5 className="text-center text-secondary">
                    Sprint not found! Let's add it &#128578;
                  </h5>
                )}
                {!isLoading && (
                  <div className="d-flex flex-wrap justify-content-center mt-3">
                    {createPermission && (
                      <Button
                        className="m-1"
                        variant="success"
                        size="sm"
                        as={Link}
                        to={`/sprints/add?${createSearchParams({
                          ...allQueryParams,
                        })}`}
                      >
                        Add Sprint
                      </Button>
                    )}
                    {filterItems ? (
                      <FilterButton
                        showText
                        filters={filters}
                        url="sprints"
                        className="m-1"
                        variant="secondary"
                      />
                    ) : null}
                  </div>
                )}
              </LoadingScreenCover>
            )}
          </Card>
        </>
      ) : null}
    </>
  );
};

export default SprintTable;
