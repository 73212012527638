import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonLabelsFilterFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import SelectLabelsSubjectType from "components/form/SelectLabelsSubjectType";

const CommonLabelsFilter = () => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const [filterFields, setFilterFields] = useState(commonLabelsFilterFormKeys);
    const allQueryParams = Object.fromEntries([...queryParams]);
    const { label, subject_type } = allQueryParams;

    const handleFieldChange = (e, action) => {
        let newFormData;
        let value;
        if (!action) {
            newFormData = {
                ...filterFields,
                [e.target.name]: e.target.value,
            };
        } else {
            if (action.action === "select-option") {
                value = e.code ? e.code : e.value;
            }
            newFormData = {
                ...filterFields,
                [action.name]: value,
                [action.name + "_ref"]: e,
            };
        }
        setFilterFields(newFormData);
    };

    const handleFilterApply = () => {
        let formDataWithoutRefKeys = removeRefData(filterFields);
        navigate(
            `?${createSearchParams(
                removeEmptyFields({
                    ...formDataWithoutRefKeys,
                    page: 1,
                })
            )}`
        );
    };

    useEffect(() => {
        setFilterFields((previous) => ({
            ...previous,
            label: label ? label : "",
            subject_type: subject_type ? subject_type : "",
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleResetFilter = () => {
        setFilterFields(commonLabelsFilterFormKeys);
    };

    return (
        <div className="p-3 border-bottom">
            <Row className="g-3">
                <Col md={2}>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="fs--1">Label</Form.Label>
                            <Col>
                                <Form.Control
                                    type="text"
                                    name="label"
                                    onChange={handleFieldChange}
                                    value={filterFields?.label}
                                />
                            </Col>
                        </Form.Group>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Form.Group as={Col}>
                            <Form.Label className="require-data">Type</Form.Label>
                            <SelectLabelsSubjectType
                                value={filterFields?.subject_type_ref}
                                name="subject_type"
                                handleFieldChange={handleFieldChange}
                            />
                        </Form.Group>
                    </Row>
                </Col>
            </Row>
            <Row className="m-0">
                <Form.Group
                    as={Col}
                    md={2}
                    className="d-flex justify-content-end w-100 p-0"
                >
                    <div className="d-flex align-items-end gap-2 mt-3">
                        <Button
                            size="sm"
                            variant="warning"
                            onClick={handleFilterApply}
                            title="Apply Added Filters"
                        >
                            Apply
                        </Button>
                        <Button
                            size="sm"
                            variant="transparent"
                            className="shadow-sm"
                            onClick={handleResetFilter}
                            title="Clear All Filters"
                        >
                            <FcClearFilters size={18} />
                        </Button>
                    </div>
                </Form.Group>
            </Row>
        </div>
    );
};

export default CommonLabelsFilter;