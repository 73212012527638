import { React, useState } from "react";
import { Button, Col, Collapse, Row, Badge } from "react-bootstrap";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAxisproTranslate from "hooks/useAxisproTranslate";

const ItemDetailsCollapse = ({ data }) => {
  const Translate = useAxisproTranslate();
  const [open, setOpen] = useState(
    data?.product_type === "service" ? true : false
  );

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="customer-more-info-collapse"
        aria-expanded={open}
        variant="white"
        size="small"
        className="px-0 text-primary d-flex flex-row align-items-center"
        style={{ border: "none", outLine: "none", boxShadow: "none" }}
      >
        <small>{Translate("Details")}</small>
        <FontAwesomeIcon
          icon={open ? "caret-down" : "caret-right"}
          className="ms-1"
        />
      </Button>
      <Collapse in={open}>
        <Row
          id="customer-more-info-collapse"
          className="mt-2"
          style={{ fontSize: ".8rem", fontWeight: "600" }}
        >
          {data?.product_type_name ? (
            <>
              <Col
                sm={6}
                md={6}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Product Type")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={6} md={6} lg={7} className="mb-2 text-dark">
                {data?.product_type_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.category_name ? (
            <>
              <Col
                sm={6}
                md={6}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Category")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={6} md={6} lg={7} className="mb-2 text-dark">
                {data?.category_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.sub_category_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Sub Category")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.sub_category_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.brand_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Brand")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.brand_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.product_type === "service" ? (
            ""
          ) : (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Returnable")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.is_returnable ? (
                  <Badge bg="success">{Translate("Yes")}</Badge>
                ) : (
                  <Badge bg="danger">{Translate("No")}</Badge>
                )}
              </Col>
            </>
          )}
          {data?.product_type === "service" ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Item Type")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.item_type_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.tax_group_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Tax Group")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.tax_group_name}
              </Col>
            </>
          ) : (
            ""
          )}
          {data?.unit_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                {Translate("Unit Name")}
                <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data?.unit_name}
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      </Collapse>
    </>
  );
};

ItemDetailsCollapse.propTypes = {
  data: PropTypes.any,
};

export default ItemDetailsCollapse;
