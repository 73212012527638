import Background from "components/common/Background";
import SoftBadge from "components/common/SoftBadge";
import { Card, Col, ListGroup, ProgressBar, Row } from "react-bootstrap";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import corner1 from "assets/img/icons/spot-illustrations/corner-1.png";
import { BsDatabaseFillExclamation } from "react-icons/bs";
const ProjectCard = ({ projectData }) => {
  return (
    <Row>
      {projectData?.data?.length > 0 ? (
        projectData.data.map(
          (items, index) =>
            index < 6 && (
              <Col xl={4} lg={6} className="p-0 m-0 mt-2" key={index}>
                <Card className="m-2 rounded-1">
                  <Card.Body>
                    <Background image={corner1} className="p-card bg-card" />
                    <div className="d-flex w-100 align-items-center justify-content-between">
                      <AiOutlineFundProjectionScreen
                        size={25}
                        className="text-dark"
                      />
                      <SoftBadge
                        bg="success"
                        className="ps-3 pe-3 text-capitalize"
                      >
                        {items?.status}
                      </SoftBadge>
                    </div>
                    <div className="mt-3">
                      <div className="fw-bold h1">{items?.title}</div>
                      <div className="mt-1">
                        <ProgressBar
                          now={index * 10}
                          label={`${index * 10}%`}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )
        )
      ) : (
        <Col className="p-0 m-0 mt-2">
          <Card className="rounded-1 shadow-none">
            <Card.Body>
              <ListGroup.Item className="rounded-0 border">
                <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                  <BsDatabaseFillExclamation size={40} className="text-mute" />
                  <span> No projects found!</span>
                </div>
              </ListGroup.Item>
            </Card.Body>
          </Card>
        </Col>
      )}
    </Row>
  );
};
export default ProjectCard;
