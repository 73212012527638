import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { AiFillPlusCircle } from "react-icons/ai";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import { DeleteContext } from "context/Context";
import { DeleteButton, ViewButton } from "module/common/Buttons/AppButtons";
import QuotationForm from "module/Quotation/QuotationForm/QuotationForm";
import { FaEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import useAxisproPermission from "hooks/useAxisproPermission";

const Quotations = ({ opportunityData }) => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission, showPermission] =
    ["create-sq", "update-sq", "delete-sq", "show-sq"].map((permission) =>
      axisProPermission(permission)
    );
  const actionsPermission =
    updatePermission || deletePermission || showPermission;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/sales/sales-quote/" + id,
        title: "Delete Quotation",
        message: "Are you sure you want to delete this quotation?",
        onSuccess: () => {
          fetchData();
        },
      },
    });
  };

  const columns = [
    {
      accessor: "trans_date",
      Header: "DATE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2 text-uppercase",
      },
      Cell: (rowData) => {
        const { trans_date_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{trans_date_formatted}</h5>;
      },
    },
    {
      accessor: "reference",
      Header: "REFERENCE",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { reference } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{reference}</h5>;
      },
    },
    {
      accessor: "customer_name",
      Header: "CUSTOMER",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { customer_name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{customer_name}</h5>;
      },
    },
    {
      accessor: "amount",
      Header: "AMOUNT",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { amount } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{amount}</h5>;
      },
    },
    {
      accessor: "memo",
      Header: "NOTES",
      headerProps: { className: "pe-1" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { memo } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{memo ? memo : "N/A"}</h5>;
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: { className: `pe-1 ${actionsPermission ? "" : "d-none"}` },
      disableSortBy: true,
      cellProps: {
        className: `py-2 ${actionsPermission ? "" : "d-none"}`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex justify-content-end align-items-center gap-2">
            {showPermission && (
              <ViewButton
                to={`/opportunity/overview/${opportunityData?.id}/quotation/${id}`}
              />
            )}
            {updatePermission && (
              <Button
                size="sm"
                variant="transparent"
                className={`custom-button-action p-0`}
                as={Link}
                to={`/opportunity/overview/${
                  opportunityData?.id
                }?${createSearchParams({
                  ...allQueryParams,
                  mode: "edit",
                  quotation_id: id,
                })}`}
                title="Edit"
              >
                <FaEdit
                  color="#f18615cc"
                  className="custom-button-edit-icon"
                  size={18}
                />
              </Button>
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    if (opportunityData?.id) {
      try {
        const data = await apiCall({
          url: "sales/sales-quote",
          params: {
            is_from_crm: 1,
            opportunity_id: opportunityData?.id,
          },
        });
        setData(data.data);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opportunityData]);

  return (
    <>
      <QuotationForm
        requiredData={{
          opportunity_id: opportunityData?.id,
          opportunity_name: opportunityData?.title,
          account_id: opportunityData?.customer_id,
          account_name: opportunityData?.customer_name,
        }}
        fetchData={fetchData}
      />
      {!isLoading ? (
        <Row>
          <Col xs={12}>
            <Card className="d-flex flex-fill shadow-none">
              <Card.Header
                className={`ps-2 pb-2 pt-2 rounded-0`}
                style={{ backgroundColor: "#d2d4e9" }}
              >
                <div className="d-flex align-items-center justify-content-between ">
                  <div className="ms-3">QUOTATIONS INFORMATION</div>
                  {createPermission && (
                    <Button
                      variant="transparent"
                      className="custom-button-action p-0"
                      as={Link}
                      to={`/opportunity/overview/${
                        opportunityData?.id
                      }?${createSearchParams({
                        ...allQueryParams,
                        mode: "add",
                      })}`}
                    >
                      <AiFillPlusCircle size={22} className="text-primary" />
                    </Button>
                  )}
                </div>
              </Card.Header>
              <Card.Body
                style={{
                  height: "36.8rem",
                  overflow: "auto",
                }}
                className={`p-0  rounded-0`}
              >
                {data.length > 0 ? (
                  <div className="mt-3">
                    <AdvanceTableWrapper columns={columns} data={data} sortable>
                      <AdvanceTable
                        table
                        headerClassName="bg-200 text-900 text-nowrap align-middle"
                        rowClassName="align-middle white-space-nowrap"
                        tableProps={{
                          size: "sm",
                          striped: true,
                          className: "fs--1 mb-0 overflow-hidden ",
                        }}
                      />
                    </AdvanceTableWrapper>
                  </div>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    No quotations information! 🙂
                  </div>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <ListLoading style={{ height: "75vh", overflow: "hidden" }} />
      )}
    </>
  );
};

Quotations.propTypes = {
  data: PropTypes.any,
};

export default Quotations;
