import { React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";

function EmailInputField({
  formData,
  formError,
  handleFieldChange,
  required,
  readOnly,
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className={"mb-3"}>
      <Row>
        <Col xl={4} md={4} sm={12}>
          <Form.Label
            className={
              required
                ? "require-data m-0 d-flex align-items-center justify-content-start h-100"
                : "m-0 d-flex align-items-center justify-content-start h-100"
            }
          >
            {Translate("Contact Email")}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="flex-2">
              {readOnly ? (
                <Form.Control value={formData.email} disabled />
              ) : (
                <Form.Control
                  type="email"
                  name="email"
                  onChange={(e) => handleFieldChange(e)}
                  value={formData.email}
                />
              )}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              <FormErrorPopover
                id="email"
                totalErrorCount={Object.keys(formError).length}
                errorMessage={formError.email}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

EmailInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default EmailInputField;
