import BackButton from "components/common/BackButton";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import { SaveButton } from "module/common/Buttons/CommonButton";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
const ContactForm = ({
  handleFieldChange,
  formData,
  formError,
  onSave,
  handleSubmit,
}) => {
  const [queryParams] = useSearchParams();
  const edit_id = queryParams.get("edit_id");
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">First Name</Form.Label>
            <Form.Control
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleFieldChange}
              isInvalid={!!formError.first_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.first_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Phone Number</Form.Label>
            <PhoneNumberInput
              name="phone_no"
              onChange={handleFieldChange}
              value={formData.phone_no}
              isInvalid={!!formError.phone_no}
            />
            <Form.Control.Feedback type="invalid">
              {formError.phone_no}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleFieldChange}
              isInvalid={!!formError.email}
            />
            <Form.Control.Feedback type="invalid">
              {formError.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={formData.country}
              onChange={handleFieldChange}
              isInvalid={!!formError.country}
            />
            <Form.Control.Feedback type="invalid">
              {formError.country}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={handleFieldChange}
              isInvalid={!!formError.city}
            />
            <Form.Control.Feedback type="invalid">
              {formError.city}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              name="last_name"
              value={formData.last_name}
              onChange={handleFieldChange}
              isInvalid={!!formError.last_name}
            />
            <Form.Control.Feedback type="invalid">
              {formError.first_name}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Website</Form.Label>
            <Form.Control
              type="text"
              name="website"
              value={formData.website}
              onChange={handleFieldChange}
              isInvalid={!!formError.website}
            />
            <Form.Control.Feedback type="invalid">
              {formError.website}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Second Email</Form.Label>
            <Form.Control
              type="email"
              name="secondary_email"
              value={formData.secondary_email}
              onChange={handleFieldChange}
              isInvalid={!!formError.secondary_email}
            />
            <Form.Control.Feedback type="invalid">
              {formError.secondary_email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={handleFieldChange}
              isInvalid={!!formError.state}
            />
            <Form.Control.Feedback type="invalid">
              {formError.state}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Zip Code</Form.Label>
            <Form.Control
              name="zip_code"
              value={formData.zip_code}
              onChange={handleFieldChange}
              isInvalid={!!formError.zip_code}
            />
            <Form.Control.Feedback type="invalid">
              {formError.zip_code}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Form.Group className="mb-3">
          <Form.Label>Address</Form.Label>
          <Form.Control
            as="textarea"
            rows={5}
            name="address"
            onChange={handleFieldChange}
            value={formData.address}
          />
          <Form.Control.Feedback type="invalid">
            {formError.address}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end gap-1 mt-3">
        <SaveButton
          handleSubmit={handleSubmit}
          onSave={onSave}
          id={edit_id ?? ""}
        />
        {!onSave && (
          <BackButton variant={"danger"} size={"sm"}>
            Cancel
          </BackButton>
        )}
      </Form.Group>
    </Form>
  );
};
ContactForm.prototype = {
  handleFieldChange: PropTypes.func,
  formData: PropTypes.any,
  formError: PropTypes.any,
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
};
export default ContactForm;
