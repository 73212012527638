import PropTypes from 'prop-types';
import FalconCardHeader from 'components/common/FalconCardHeader';
import Flex from 'components/common/Flex';
import { randomBTClass } from 'helpers/utils';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TileLink = ({ title, to, Icon }) => {
  return (
    <div className="tile-link" style={{ minHeight: '160px' }}>
      <Link to={to} className="text-decoration-none">
        <Card className="h-100">
          <FalconCardHeader
            title={title}
            titleTag="h6"
            className="py-2"
            light
          />

          {Icon && (
            <Card.Body as={Flex} alignItems="end">
              <Icon size="25" className={`text-${randomBTClass()}`} />
            </Card.Body>
          )}
        </Card>
      </Link>
    </div>
  );
};

TileLink.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  Icon: PropTypes.any.isRequired
};

export const TileLinkCover = ({ children }) => {
  return <Flex wrap="wrap tile-link-row">{children}</Flex>;
};

TileLinkCover.propTypes = {
  children: PropTypes.any
};

export default TileLink;
