import React, { useEffect, useState } from "react";
import { Image, ListGroup, Table } from "react-bootstrap";
import ProfileImage from "../../../../assets/img/Avathar/employee.avif";
import { Link, useParams, useSearchParams } from "react-router-dom";
import FilterButton from "components/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import AppPagination from "components/common/app-pagination/AppPagination";
import { projectFilters } from "module/common/helpers/projectCommonFilter";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import qs from "qs";
import ListLoading from "module/common/ListLoading";
import { BsDatabaseFillExclamation } from "react-icons/bs";

const ProjectsInfo = ({ userId, filterVisible = true, type }) => {
  const { empId, client_id } = useParams();
  const [projects, setProjects] = useState({});
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [filters] = useState(projectFilters);
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const [loading, setLoading] = useState(false);
  const tab = queryParams.get("tab");

  useEffect(() => {
    tab === "projects" && getProjectData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pageNumber, filterItems, tab]);

  const getProjectData = async (page = 1) => {
    setLoading(true);

    const params = {
      page: page,
      filters: filterItems
        ? removeRefData(createFilterArray(filterItems), "array")
        : "",
    };

    if (type === "client") {
      params.requested_from = "account";
      params.account_id = userId;
    } else if (type === "employee") {
      params.requested_from = "employee";
      params.employee_id = userId;
    }

    try {
      const projectsData = await apiCall({
        url: `crm/project`,
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });
      setProjects(projectsData);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <ListGroup className="mb-2">
          <ListGroup.Item className="rounded-0 pb-0 pt-0 d-flex justify-content-end">
            {filterVisible && (
              <FilterButton
                className="ms-1 mb-2 mt-2 pb-2 pt-2"
                variant="success"
                filters={filters}
                url={
                  type === "client"
                    ? `account/overview/${client_id}`
                    : `employee/overview/${empId}`
                }
                additionalParam={{ tab: tab }}
              />
            )}
          </ListGroup.Item>
        </ListGroup>
        <div
          className="table-wraper"
          style={{ maxHeight: "69.5vh", minHeight: "69.5vh", overflow: "auto" }}
        >
          <Table striped bordered hover responsive size="md">
            <thead>
              <tr>
                <th className="fs--1 fw-bold">#</th>
                <th className="fs--1 fw-bold">Project</th>
                <th className="fs--1 fw-bold">Client</th>
                <th className="fs--1 fw-bold">Status</th>
                <th className="fs--1 fw-bold">Start Date</th>
                <th className="fs--1 fw-bold">End Date</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(projects).length > 0 &&
              !loading &&
              Object.keys(projects.data).length > 0 ? (
                projects.data.map((item, key) => (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>
                      <Link
                        to={`/project/info/${item.id}?tab=overview`}
                        className="custom-link-style-crm"
                      >
                        {item.title}
                      </Link>
                    </td>
                    <td>
                      <div className="d-flex">
                        <Link to={`/client/overview/${item.customer.id}`}>
                          <Image
                            src={ProfileImage}
                            roundedCircle
                            height={40}
                            width={40}
                          />
                          <span className="align-self-center ms-1 text-dark">
                            {item.customer.name}
                          </span>
                        </Link>
                      </div>
                    </td>
                    <td>
                      <span
                        className="badge p-2  project-custom-badge-crm"
                        style={{
                          backgroundColor:
                            item.status === "hold"
                              ? "#f49025d4"
                              : item.status === "cancelled"
                              ? "#f2451cd9"
                              : item.status === "open"
                              ? "#4bc0c9"
                              : "#43bf57",
                        }}
                      >
                        <span className="fw-bold text-uppercase">
                          {item.status}
                        </span>
                      </span>
                    </td>
                    <td>{item.start_date_formated}</td>
                    <td>{item.end_date_formated}</td>
                  </tr>
                ))
              ) : !loading ? (
                <tr>
                  <td colSpan={7}>
                    <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                      <BsDatabaseFillExclamation
                        size={40}
                        className="text-mute"
                      />
                      <span>No data found!</span>
                    </div>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={7}>
                    <ListLoading
                      style={{ maxHeight: "60vh", overflow: "hidden" }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <div className="pt-2">
          <div className="d-flex justify-content-center">
            {projects && projects.links && (
              <AppPagination
                data={projects}
                url={`/employee/overview/${empId}`}
                search={allQueryParams}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsInfo;
