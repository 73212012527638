import { React } from "react";
import { Row, Col } from "react-bootstrap";

import { weather } from "data/dashboard/default";

import WelcomeTile from "./WelcomeTile";
import TotalCountTile from "./TotalCountTile/TotalCountTile";
import ProjectOverview from "./ProjectOverview";
import Revenue from "./RevenueTile/Revenue";
import MostLeads from "./MostLeads/MostLeads";
import CrmStats from "./CrmStats";
import DealForecastBar from "./DealForecastBar";
import DealStorageFunnel from "./DealStorageFunnel/DealStorageFunnel";
import TicketsTile from "./TicketTile/TicketsTile";
import DealVsGoal from "./DealvsGoal/DealVsGoal";
import DealForeCast from "./DealForcast/DealForeCast";
import Weather from "./Weather";
import RecentLeads from "./RecentLeads";
import LeadConversation from "./LeadConversion/LeadConversation";

const Dashboard = () => {
  return (
    <>
      <Row className="g-2 mb-2 mt-2">
        <Col lg={4} xl={4} xxl={4} sm={12}>
          <WelcomeTile />
        </Col>
        <Col lg={8} xl={8} xxl={8} sm={12}>
          <TotalCountTile />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col xxl={9}>
          <CrmStats />
          <Revenue />
        </Col>
        <Col xxl={3}>
          <MostLeads />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col lg={5}>
          <LeadConversation />
        </Col>
        <Col lg={7}>
          <RecentLeads />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col md={12} xxl={8}>
          <DealForecastBar />
        </Col>
        <Col xxl={4}>
          <DealStorageFunnel />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col xxl={12}>
          <TicketsTile />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col xxl={6}>
          <DealVsGoal />
        </Col>
        <Col xxl={6}>
          <DealForeCast />
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col lg={4} md={12}>
          <ProjectOverview />
        </Col>
        <Col lg={4} md={12}>
          <Weather data={weather} />
        </Col>
        <Col lg={4} md={12}>
          <Weather data={weather} />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
