import AppTimePicker from "components/app-time-picker/AppTimePicker";
import BackButton from "components/common/BackButton";
import MultiSelectContacts from "components/form/MultiSelectContacts";
import SelectMeetingStatus from "components/form/SelectMeetingStatus";
import SelectUsers from "components/form/SelectUsers";
import { SaveButton } from "module/common/Buttons/CommonButton";
import PropTypes from "prop-types";
import { Col, Form, Row } from "react-bootstrap";
const AddMeeting = ({
  handleFieldChange,
  formData,
  formError,
  onSave,
  handleSubmit,
  itemId,
  type,
}) => {
  return (
    <Form>
      <Row>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Title</Form.Label>
            <Form.Control
              type="text"
              name="title"
              value={formData.title}
              onChange={handleFieldChange}
              isInvalid={!!formError.title}
            />
            <Form.Control.Feedback type="invalid">
              {formError.title}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Start Time</Form.Label>
            <AppTimePicker
              name="start_time"
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={handleFieldChange}
              value={formData.start_time}
              isInvalid={!!formError.start_time}
            />
            <Form.Control.Feedback type="invalid">
              {formError.start_time}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Host</Form.Label>
            <SelectUsers
              name="host_user_id"
              value={formData.host_user_id_ref}
              handleFieldChange={handleFieldChange}
              error={!!formError.host_user_id}
            />
            <Form.Control.Feedback type="invalid">
              {formError.host_user_id}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Participant</Form.Label>
            <MultiSelectContacts
              name="participants"
              value={formData.participants_ref}
              onChange={handleFieldChange}
              itemId={itemId}
              type={type}
              error={!!formError.participants}
            />
            <Form.Control.Feedback type="invalid">
              {formError.participants}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Status</Form.Label>
            <SelectMeetingStatus
              name="status"
              value={formData.status_ref}
              handleFieldChange={handleFieldChange}
              error={!!formError.status}
            />

            <Form.Control.Feedback type="invalid">
              {formError.status}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Location</Form.Label>
            <Form.Control
              name="location"
              value={formData.location}
              onChange={handleFieldChange}
              isInvalid={!!formError.location}
            />
            <Form.Control.Feedback type="invalid">
              {formError.location}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label className="require-data">End Time</Form.Label>
            <AppTimePicker
              name="end_time"
              yearPlaceholder="yyyy"
              monthPlaceholder="mm"
              dayPlaceholder="dd"
              hourPlaceholder="hh"
              minutePlaceholder="mm"
              onChange={handleFieldChange}
              value={formData.end_time}
              isInvalid={!!formError.end_time}
            />
            <Form.Control.Feedback type="invalid">
              {formError.end_time}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label className="require-data">Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              name="description"
              onChange={handleFieldChange}
              value={formData.description}
              isInvalid={!!formError.description}
            />
            <Form.Control.Feedback type="invalid">
              {formError.description}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Form.Group className="d-flex justify-content-end gap-1 mt-3">
        <SaveButton handleSubmit={handleSubmit} onSave={onSave} />
        {!onSave && (
          <BackButton variant={"danger"} size={"sm"}>
            Cancel
          </BackButton>
        )}
      </Form.Group>
    </Form>
  );
};
AddMeeting.prototype = {
  handleFieldChange: PropTypes.func,
  formData: PropTypes.any,
  formError: PropTypes.any,
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  itemId: PropTypes.string,
};
export default AddMeeting;
