import SelectCustomer from "components/form/SelectCustomer";
const estimationFilters = {
  date: {
    label: "Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  valid_until: {
    label: "Valid Until",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  customer_id: {
    label: "Client",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectCustomer
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  status: {
    label: "Status",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) => (item && item.operator === "=" ? "SELECT" : "STRING"),
    options: [
      { label: "Pending", value: "pending" },
      { label: "Open", value: "open" },
      { label: "Closed", value: "closed" },
    ],
  },
};

export { estimationFilters };
