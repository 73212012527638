const paramsArrayBuilder = (data) => {
  let result = {};
  Object.keys(data)
    .filter((key) => !key.endsWith("_ref"))
    .forEach((keyName) => {
      if (Array.isArray(data[keyName])) {
        data[keyName].forEach((item, index) => {
          result[`${keyName}[${index}]`] = item;
        });
      } else {
       result[keyName] = data[keyName];
      }
    });

  return result;
};
export default paramsArrayBuilder;
