import { React, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const SelectProject = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  clientId,
  renderFrom,
}) => {
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const setItems = async (value) => {
    setLoading(true);
    try {
      let response = await axios.get("crm/project-autocomplete", {
        params: { title: value, customer_id: clientId },
      });
      let data = response && response.data ? response.data.data : [];
      return data.map((item) => ({
        label: item.title,
        value: item.id,
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleFocus = async ({ inputValue }) => {
    setDefaultOptions([]);
    if (clientId || renderFrom !== "tickets") {
      const defaultOptionsData = await setItems(inputValue);
      setDefaultOptions(defaultOptionsData);
    }
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setItems}
      defaultOptions={defaultOptions}
      onFocus={handleFocus}
      isLoading={loading}
      isClearable
      name={name ? name : "project"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`custom-select ${
        error
          ? "form-control ps-0 py-0 is-invalid text-capitalize"
          : "text-capitalize"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

SelectProject.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectProject;
