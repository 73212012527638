import SelectSocialMediaType from "components/form/SelectSocialMediaType";
import removeRefData from "helpers/removeRefData";
import getSocialIcon from "module/common/helpers/getSocialIcons";
import React, { useState } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FaTimesCircle } from "react-icons/fa";
import { IoMdAddCircle } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";
import { v4 as uuid } from "uuid";

const SocialMedia = ({ setFormData, formData }) => {
  const [socialMediaData, setSocialMediaData] = useState({});
  const [isUrlValid, setIsUrlValid] = useState(true);

  const handleSocialMediaChange = (e, action) => {
    let socialMediaDataTemp;
    if (action) {
      socialMediaDataTemp = {
        type: e?.value,
        type_ref: e,
      };
    } else {
      socialMediaDataTemp = {
        link: e?.target?.value,
      };
    }
    setSocialMediaData({
      ...socialMediaData,
      ...socialMediaDataTemp,
      id: uuid(),
    });
  };

  const handleAddSocialMedia = () => {
    // Regular expression for URL validation
    const urlPattern = new RegExp(
      "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?"
    );
    let isUrlValid = urlPattern.test(socialMediaData?.link);
    setIsUrlValid(isUrlValid);
    if (isUrlValid) {
      setFormData({
        ...formData,
        social_media_links: [
          ...formData.social_media_links,
          removeRefData(socialMediaData),
        ],
      });
      setSocialMediaData({
        type: "",
        type_ref: {
          label: "",
          value: "",
        },
        link: "",
      });
    }
  };

  const handleSocialMediaDeletion = (id) => {
    setFormData({
      ...formData,
      social_media_links: formData.social_media_links.filter(
        (item) => item.id !== id
      ),
    });
  };

  const handleClearSocialMediaForm = () => {
    setSocialMediaData({
      type: "",
      type_ref: {
        label: "",
        value: "",
      },
      link: "",
    });
    setIsUrlValid(true);
  };

  return (
    <>
      <Row className="gx-2 gy-2">
        <Col md={4}>
          <Form.Group as={Col} xs={12}>
            <Form.Label>Media</Form.Label>
            <Col>
              <SelectSocialMediaType
                name="social_media_type"
                value={socialMediaData.type_ref}
                handleFieldChange={handleSocialMediaChange}
                showText={false}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col md={6}>
          <Form.Group as={Col} xs={12}>
            <Form.Label>Social Media Link</Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                rows={1}
                name="social_media_links"
                onChange={handleSocialMediaChange}
                value={socialMediaData.link}
                isInvalid={!isUrlValid}
              />
              {!isUrlValid && (
                <Form.Control.Feedback
                  type="invalid"
                  className="d-flex p-0 m-0"
                >
                  <span className="ms-auto">Please enter a valid Link</span>
                </Form.Control.Feedback>
              )}
            </Col>
          </Form.Group>
        </Col>
        <Col md={2} className="d-flex flex-column align-self-end">
          <div
            className={`d-flex align-self-baseline ${
              !isUrlValid ? "mb-3" : ""
            }`}
          >
            <Button
              size="sm"
              variant="transparent"
              className="p-1 custom-transperent-common-button border-0"
              title="Clear Fields"
              onClick={handleClearSocialMediaForm}
              disabled={
                socialMediaData.type || socialMediaData.link ? false : true
              }
            >
              <FaTimesCircle color="#ff5050" size={17} />
            </Button>

            <Button
              size="sm"
              variant="transparent"
              className="p-1 custom-transperent-common-button  border-0"
              title="Add"
              onClick={handleAddSocialMedia}
              disabled={
                socialMediaData.type && socialMediaData.link ? false : true
              }
            >
              <IoMdAddCircle color="#55d455" size={22} />
            </Button>
          </div>
        </Col>
        <Col xs={12}>
          <div className="mt-2 d-flex flex-column gap-2">
            {formData?.social_media_links?.map((item, key) => (
              <InputGroup key={key}>
                <InputGroup.Text className="border-0">
                  {getSocialIcon(item.type, 25)}
                </InputGroup.Text>
                <Form.Control
                  as={"textarea"}
                  value={item.link}
                  readOnly
                  style={{
                    resize: "none",
                    textDecorationLine: "underline",
                  }}
                  className="border-0 shadow-none pt-3 ps-0"
                />
                <InputGroup.Text className="border-0 bg-white">
                  <Button
                    variant="transparent"
                    size="sm"
                    className="p-0 custom-transperent-common-button ms-2"
                    onClick={() => handleSocialMediaDeletion(item.id)}
                  >
                    <MdDeleteForever color="#ff5050" size={25} />
                  </Button>
                </InputGroup.Text>
              </InputGroup>
            ))}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SocialMedia;
