import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { FaSourcetree } from "react-icons/fa";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonLeadSourceFilter from "./SupportingComponets/CommonLeadSourceFilter";
import ShowMore from "components/common/ShowMore";

const LeadSourceTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [leadSource, setLeadSource] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [createPermission, updatePermission, deletePermission] = [
    "store-lead-source",
    "update-lead-source",
    "delete-lead-source",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const [filterVisibility, setFilterVisibility] = useState(true);
  const { name } = allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/lead-source/" + id,
        title: "Delete Lead Source",
        message: "Are you sure you want to delete this lead source?",
        onSuccess: () => {
          const dataWithoutDeletedItem = leadSource.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/settings/lead-settings/lead-source?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setLeadSource((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "source_name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { source_name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{source_name}</h5>;
      },
    },
    {
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return <div className="mb-0 fs--1">
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={60}
            uniqueKey={id}
          />
        </div>;
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <>
            {updatePermission && (
              <EditButton
                route={`/settings/lead-settings/lead-source/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton
                className={"ms-2"}
                onClick={() => handleDelete(id)}
              />
            )}
          </>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/lead-source",
      params: {
        page: page,
        name: name,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setLeadSource(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems, name]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div className="d-flex justify-content-between align-items-center p-3">
            <div className="d-flex align-items-center">
              <FaSourcetree size={22} className="me-2 text-dark" />
              <span className="fs-1 text-uppercase">Leads Source List</span>
            </div>
            <div className="d-flex gap-2">
              {createPermission && (
                <AddButton
                  to={`/settings/lead-settings/lead-source/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonLeadSourceFilter />
          </div>
        </Card.Header>
        {!isLoading && leadSource?.data?.length > 0 ? (
          <AdvanceTableWrapper
            columns={columns}
            data={leadSource.data}
            sortable
          >
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {leadSource && leadSource.links && (
                <AppPagination
                  data={leadSource}
                  url="/settings/lead-settings/lead-source"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Lead Source" />
            ) : (
              <h5 className="text-center text-secondary">
                Lead Source not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {createPermission && (
                  <Button
                    className="m-1"
                    variant="success"
                    size="sm"
                    as={Link}
                      to={`/settings/lead-settings/lead-source/add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                  >
                    Add Lead Source
                  </Button>
                )}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default LeadSourceTableView;
