import axios from "axios";
import SelectCustomer from "components/form/SelectCustomer";
import SelectProject from "components/form/SelectProject";
import SelectTicketOrigin from "components/form/SelectTicketOrigin";
import SelectTicketStatus from "components/form/SelectTicketStatus";
import SelectTicketType from "components/form/SelectTicketType";
import SelectUsers from "components/form/SelectUsers";
import removeEmptyFields from "helpers/removeEmptyFields";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import useAxisproPermission from "hooks/useAxisproPermission";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";

const PropertiesFilteringForm = ({ data, setOverviewData }) => {
  const [formData, setFormData] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [updatePermission] = ["update-ticket"].map((permission) =>
    axisProPermission(permission)
  );

  const loadFormData = () => {
    setLoadForm(true);
    Object.keys(data).length > 0 &&
      setFormData({
        title: data.title,
        customer_id: data.customer_id,
        customer_id_ref: {
          label: data?.customer_name,
          value: data?.customer_id,
        },
        project_id: data.project_id,
        project_id_ref: { label: data?.project, value: data.project_id },
        ticket_type_id: data.ticket_type_id,
        ticket_type_id_ref: {
          label: data?.ticket_type,
          value: data.ticket_type_id,
        },
        priority: data.priority,
        priority_ref: {
          label: data.priority.toUpperCase(),
          value: data.priority,
        },
        ticket_origin: data.ticket_origin,
        ticket_origin_ref: {
          label: data.ticket_origin,
          value: data.ticket_origin,
        },
        status: data.status,
        status_ref: { label: data.status, value: data.status },
        description: data.description,
        assigned_to: data?.assigned_to,
        assigned_to_ref: {
          label: data?.assignee,
          value: data?.assigned_to,
        },
        ticket_date_time: data.ticket_date_time,
        end_date_time: data.end_date_time,
      });
    setLoadForm(false);
  };

  useEffect(() => {
    loadFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let fieldName;
    let fieldValue;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "Active" : "Inactive";
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      fieldName = action.name.replace("_id", "_name");
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        fieldValue = e.label ? e.label : "";
      }
      if (action.action === "clear") {
        fieldValue = null;
      }
      newFormData = {
        ...formData,
        [fieldName]: fieldValue,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setOnSave(true);

    axios({
      method: "put",
      url: `crm/ticket/${data.id}`,
      data: setNewFormData(
        removeRefData(removeEmptyFields(formData), "object")
      ),
    }).then((response) => {
      if (response.data.success === true) {
        setOverviewData(response.data.data);
        showToast(response.data.message, "success");
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      }
      setOnSave(false);
    });
  };

  return (
    <Card>
      <Card.Header className="bg-light">
        <h6 className="mb-0">Properties</h6>
      </Card.Header>
      <Card.Body>
        {!loadForm ? (
          <Form>
            <div className="mb-2 mt-n2">
              <Form.Label className="mb-1 fs--1">Status</Form.Label>
              <SelectTicketStatus
                value={formData.status_ref}
                name="status"
                handleFieldChange={handleFieldChange}
                isDisabled={!updatePermission}
              />
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 mt-2 fs--1">Customer</Form.Label>
              <SelectCustomer
                name="customer_id"
                value={formData?.customer_id_ref}
                handleFieldChange={handleFieldChange}
                isDisabled={!updatePermission}
              />
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 mt-2 fs--1">Assignee</Form.Label>
              <SelectUsers
                value={formData.assigned_to_ref}
                name="assigned_to"
                handleFieldChange={handleFieldChange}
                disabled={!updatePermission}
              />
            </div>
            <div className="mb-2">
              <Form.Label className="mb-1 mt-2 fs--1">Project</Form.Label>
              <SelectProject
                name="project_id"
                value={formData.project_id_ref}
                handleFieldChange={handleFieldChange}
                isDisabled={!updatePermission}
              />
            </div>
            <div>
              <Form.Label className="mb-1 mt-2 fs--1">Type</Form.Label>
              <SelectTicketType
                value={formData.ticket_type_id_ref}
                name="ticket_type_id"
                handleFieldChange={handleFieldChange}
                isDisabled={!updatePermission}
              />
            </div>
            <div>
              <Form.Label className="mb-1 mt-2 fs--1">Origin</Form.Label>
              <SelectTicketOrigin
                value={formData.ticket_origin_ref}
                name="ticket_origin"
                handleFieldChange={handleFieldChange}
                isDisabled={!updatePermission}
              />
            </div>
          </Form>
        ) : (
          <ListLoading style={{ maxHeight: "32vh", overflow: "hidden" }} />
        )}
      </Card.Body>
      {!loadForm && (
        <Card.Footer className="border-top border-200 py-x1">
          <Button
            varient="primary"
            className="w-100"
            onClick={handleSubmit}
            disabled={!updatePermission}
          >
            {onSave ? <Spinner size="sm" /> : "Update"}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};

export default PropertiesFilteringForm;
