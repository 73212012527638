import { React, useContext, useState } from "react";
import { useSearchParams } from "react-router-dom/dist";
import axios from "axios";
import { Button, Modal, Form, Row, Col, Card } from "react-bootstrap";
import PropTypes from "prop-types";

import { AiOutlineSend } from "react-icons/ai";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import { AuthWizardContext } from "context/Context";
import { showToast } from "module/common/Toast/toast";
import formateData from "helpers/formateData";

function EmailExport({ url, showEmailModal, handleCancel, filteredParams }) {
  const Translate = useAxisproTranslate();
  const { user } = useContext(AuthWizardContext);
  const [onSendEmail, setOnSendEmail] = useState(false);
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");
  const [formData, setFormData] = useState({
    email: user?.email,
    mode: "pdf",
    pdf: true,
    excel: false,
    note: "",
  });
  const [validationError, setValidationError] = useState(false);

  const sendEmail = () => {
    if (formData.email && formData.mode) {
      setOnSendEmail(true);
      axios
        .get(url, {
          params: {
            email: formData.email,
            note: formData.note,
            mode:
              formData.pdf && formData.excel
                ? "both"
                : formData.excel
                ? "excel"
                : formData.pdf
                ? "pdf"
                : "",
            ...filteredParams,
            ...formateData(JSON.parse(filterItems)),
          },
        })
        .then((response) => {
          showToast(response.data.message, "success");
          handleCancel();
          setOnSendEmail(false);
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors &&
            error.response.data.data.errors.email
          ) {
            setOnSendEmail(false);
          }
        });
    } else {
      setValidationError(true);
    }
  };

  const handleFieldChange = (e) => {
    if (
      e.target.type === "checkbox" &&
      (e.target.name === "pdf" || e.target.name === "excel")
    ) {
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: formData[e.target.name] ? false : true,
      }));
    } else {
      setValidationError(false);
      setFormData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const closeModel = () => {
    setFormData({
      mode: "pdf",
      pdf: true,
      email: "",
      note: "",
    });
    setValidationError(false);
    handleCancel();
  };

  return (
    <Modal
      show={showEmailModal}
      backdrop="static"
      className="modal-with-overlay"
    >
      <Card>
        <Card.Header className="fs-1 text-uppercase text-dark d-flex align-items-center border-bottom">
          <AiOutlineSend className="me-1" />
          {Translate("send email")}
        </Card.Header>
        <Card.Body>
          <Form.Group as={Row}>
            <Col xs={6}>
              <Form.Check
                type="checkbox"
                label="PDF"
                name="pdf"
                className="text-dark"
                value={formData.pdf}
                checked={formData.pdf}
                onChange={(e) => handleFieldChange(e)}
              />
            </Col>
            <Col xs={6}>
              <Form.Check
                type="checkbox"
                name="excel"
                label="Excel"
                className="text-dark"
                value={formData.excel}
                checked={formData.excel}
                onChange={(e) => handleFieldChange(e)}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              <Form.Label className="text-dark">
                {Translate("Email Address")}
              </Form.Label>
              <Form.Control
                type="email"
                name="email"
                required
                value={formData.email}
                onChange={(e) => handleFieldChange(e)}
              />
              {validationError ? (
                <p className="text-danger m-0 fs--2">
                  {"Email Address is required!"}
                </p>
              ) : (
                ""
              )}
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="d-flex flex-row mt-3">
            <Col xl={11} md={11} sm={11} className="pe-sm-0 flex-2">
              <Form.Label className="text-dark">
                {Translate("Notes")}
              </Form.Label>
              <Form.Control
                as="textarea"
                name="note"
                required
                value={formData.notes}
                onChange={(e) => handleFieldChange(e)}
              />
            </Col>
          </Form.Group>
        </Card.Body>
        <Card.Footer className="d-flex gap-1">
          <Button
            size="sm"
            className="ms-auto"
            variant="success"
            disabled={onSendEmail}
            onClick={sendEmail}
          >
            {onSendEmail ? (
              <span className="fs--1">{Translate("Sending")}...</span>
            ) : (
              <span>{Translate("Send Email")}</span>
            )}
          </Button>
          <Button variant="danger" size="sm" onClick={closeModel}>
            {Translate("Cancel")}
          </Button>
        </Card.Footer>
      </Card>
    </Modal>
  );
}

EmailExport.propTypes = {
  url: PropTypes.string,
  showEmailModal: PropTypes.bool,
  handleCancel: PropTypes.func,
  filteredParams: PropTypes.any,
};

export default EmailExport;
