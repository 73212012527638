import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import qs from "qs";
import Grid from "module/common/ViewPages/Common/Grid/Grid";
import List from "./View/List";
import { GRID, LIST } from "./View/constants";
import ProjectTableHeader from "./ProjectTableHeader";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";

function ProjectTable({ projectId }) {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [component, setComponent] = useState(GRID);
  const activeView = queryParams.get("view");
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState({});
  const { itemInfoId } = useParams();
  const { from_date, to_date, date_range_for, project_id, title, account_id, status } =
    allQueryParams;
  const pageNumber = queryParams.get("page");
  const { dispatch } = useContext(DeleteContext);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/project/" + id,
        title: "Delete Project",
        message: "Are you sure you want to delete this project?",
        onSuccess: async () => {
          const dataWithoutDeletedItem = projects?.data?.filter(
            (account) => account.id !== id
          );
          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/project?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setProjects((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getData = async (page = 1) => {
    setIsLoading(true);
    try {
      if (!itemInfoId) {
        const data = await apiCall({
          url: "crm/project",
          params: {
            page: page,
            requested_from: "project_list",
            from_date: from_date,
            to_date: to_date,
            date_range_for: date_range_for,
            title: title,
            project_id: project_id,
            account_id: account_id,
            status: status,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params);
          },
        });
        setProjects(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    from_date,
    to_date,
    date_range_for,
    title,
    project_id,
    account_id,
    status,
  ]);

  useEffect(() => {
    if (activeView === LIST) {
      setComponent(LIST);
    } else {
      setComponent(GRID);
    }
  }, [activeView]);

  const ComponentToRender = {
    [GRID]: Grid,
    [LIST]: List,
  }[component];

  return (
    <ProjectTableHeader>
      {isLoading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "82vh" }}
        >
          <LoadingCommon loadingText="Looking for Projects" />
        </div>
      ) : (
        <ComponentToRender
          projectId={projectId}
          getData={getData}
          data={projects}
          allQueryParams={allQueryParams}
          handleDelete={handleDelete}
          renderFrom={"project"}
        />
      )}
    </ProjectTableHeader>
  );
}

ProjectTable.propTypes = {
  projectId: PropTypes.string,
};

export default ProjectTable;
