import axios from "axios";
import { resetPasswordFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { showToast } from "module/common/Toast/toast";
import React, { useState } from "react";
import { Alert, Button, Form, InputGroup, Spinner } from "react-bootstrap";
import { FaArrowRight, FaLock } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import passwordResetSuccessImage from "../../asset/images/password-success.jpg";
import { IoMdInformationCircleOutline } from "react-icons/io";

const ResetPasswordForm = () => {
  const [searchParams] = useSearchParams();
  const [saving, setIsSaving] = useState(false);
  const [formData, setFormData] = useState(resetPasswordFormKeys);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errors, setErrors] = useState({});
  let token = searchParams.get("token");
  const requestedAs = searchParams.get("requested_by");

  const handleFieldChange = (e) =>
    setFormData(generateFormDataHelper(e, null, formData));

  const handleSubmit = (e) => {
    e.preventDefault();
    let validatedErrors = {};
    setErrors({});

    if (!formData.password) validatedErrors.password = "Password is required";
    if (!formData.c_password)
      validatedErrors.c_password = "Confirm password is required";
    if (formData.password && formData.c_password !== formData.password)
      validatedErrors.c_password =
        "Password and Confirm password does not match!";
    if (Object.keys(validatedErrors).length > 0)
      return setErrors(validatedErrors);
    setIsSaving(true);
    axios
      .post(
        requestedAs === "customer"
          ? "reset-customer-password"
          : "reset-password",
        { ...formData, token: token }
      )
      .then((response) => {
        setIsSaving(false);
        if (response.data.success !== true)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );

        setSuccessMessage(response.data.message);
      })
      .catch((error) => {
        setIsSaving(false);
        if (!error.response.data || !error.response.data.message)
          return showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );

        const validation_error =
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.errors
            ? error.response.data.data.errors
            : null;

        if (validation_error) setErrors(validation_error);

        if (error.response.data.message)
          showToast(error.response.data.message, "error");
      });
  };

  return successMessage ? (
    <div className="d-flex flex-column align-items-center mail-success text-center">
      <div>
        <img
          src={passwordResetSuccessImage}
          alt="email success"
          loading="lazy"
          className="w-100"
        />
      </div>
      <p className="title text-dark fw-bold my-3">Success!</p>
      <p>{successMessage}</p>
      <Button
        className="rounded d-flex flex-row justify-content-center align-items-center w-100"
        size="sm"
        as={Link}
        to={`/authentication/login?as=${
          requestedAs === "customer" ? "client" : "user"
        }`}
        style={{
          maxWidth: "280px",
          backgroundColor: "#6AABAC",
          borderColor: "#6AABAC",
        }}
      >
        <span>Log in</span>
        <FaArrowRight className="ms-1" size={11} />
      </Button>
    </div>
  ) : (
    <Form onSubmit={handleSubmit}>
      {Object.keys(errors).length > 0 ? (
        <Form.Group className="mb-3">
          <Alert variant="danger">
            <ul className="ms-2 mb-0">
              {Object.keys(errors).map((key, index) => (
                <li key={index} className="mb-1">
                  {errors[key]}
                </li>
              ))}
            </ul>
          </Alert>
        </Form.Group>
      ) : null}

      <Form.Group className="mb-3">
        <div className="m-0 text-muted info d-flex align-items-center gap-1">
          <IoMdInformationCircleOutline size={16} color="#ff7916" />
          <span>Enter a new password and proceed.</span>
        </div>
      </Form.Group>

      <Form.Group className="mb-3" controlId="password">
        <Form.Label>New password</Form.Label>
        <InputGroup
          className={` rounded shadow py-1 ${
            formData.password.length > 0 && !errors.password
              ? "is-valid"
              : errors.password
              ? "is-invalid"
              : ""
          }`}
        >
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaLock size={10} />
          </InputGroup.Text>
          <Form.Control
            type="password"
            className="border-0"
            placeholder="Enter new password"
            name="password"
            onChange={handleFieldChange}
            value={formData.password}
            isValid={formData.password.length > 0 && !errors.password}
            isInvalid={!!errors.password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="mb-3" controlId="confirmPassword">
        <Form.Label>Confirm password</Form.Label>
        <InputGroup
          className={` rounded shadow py-1 ${
            formData.c_password.length > 0 && !errors.c_password
              ? "is-valid"
              : errors.c_password
              ? "is-invalid"
              : ""
          }`}
        >
          <InputGroup.Text className="border-0 bg-transparent px-2">
            <FaLock size={10} />
          </InputGroup.Text>
          <Form.Control
            type="password"
            className="border-0"
            placeholder="Confirm new password"
            name="c_password"
            onChange={handleFieldChange}
            value={formData.c_password}
            isValid={
              formData.c_password.length > 0 &&
              !errors.c_password &&
              formData.password &&
              formData.password === formData.c_password
            }
            isInvalid={!!errors.c_password}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="d-flex flex-row">
        <Button
          type="submit"
          className="rounded w-100 py-2 d-flex flex-row justify-content-center align-items-center"
          disabled={saving}
          style={{ backgroundColor: "#6AABAC", borderColor: "#6AABAC" }}
        >
          {saving ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
                style={{ width: "12px", height: "12px" }}
              />
              <span className="fs--1">Validating...</span>
            </>
          ) : (
            <>
              <span>Proceed</span>
            </>
          )}
        </Button>
      </Form.Group>

      <Form.Group className="mt-3 d-flex justify-content-center align-items-center">
        <Button
          className={`d-flex flex-row align-items-center rounded py-1 ${"border-0"} `}
          size="sm"
          variant={"outline-secondary"}
          style={{ boxShadow: "none" }}
          as={Link}
          to={`/authentication/login?as=${
            requestedAs === "customer" ? "client" : "user"
          }`}
        >
          <FaLock size={13} />
          <span className="ms-1 small">Login</span>
        </Button>
      </Form.Group>
    </Form>
  );
};

export default ResetPasswordForm;
