import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Button, Image, ListGroup } from "react-bootstrap";
import SimpleBarReact from "simplebar-react";
import PropTypes from "prop-types";

import { FaPlus, FaTimesCircle, FaUsersSlash } from "react-icons/fa";

import { apiCall } from "helpers/apiCalls";
import { addProjectMembers } from "helpers/formKeys";
import { DeleteContext } from "context/Context";
import useAxisproPermission from "hooks/useAxisproPermission";
import Flex from "components/common/Flex";
import AddMembersModal from "module/Project/ProjectModal/AddMembersModal";
import AvatarImage from "../../../../assets/img/Avathar/ProfileImage.jpg";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import ListLoading from "module/common/ListLoading";

const ProjectMembers = ({
  projectId,
  setDataProcessing,
  dataProcessing,
  membersList,
  setMembersList,
}) => {
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [formData, setFormData] = useState(addProjectMembers);
  const { dispatch } = useContext(DeleteContext);
  const axisProPermission = useAxisproPermission();

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/delete-project-member/" + id,
        title: "Remove Member",
        message:
          "Are you sure you want to get that member out of this project?",
        onSuccess: () => {
          const dataWithoutDeletedItem = membersList.filter(
            (item) => item.id !== id
          );
          setMembersList([...dataWithoutDeletedItem]);
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const handleUserProfileArea = (id) => {
    setUserProfile(id);
    setShowUserProfileArea(true);
  };

  const getData = async () => {
    setDataProcessing(true);
    try {
      const data = await apiCall({
        url: `crm/project-member-list`,
        params: {
          filters: [{ column: "project_id", operator: "=", value: projectId }],
        },
      });
      setMembersList(data.data);
      setFormData({
        project_id: projectId,
        members: data?.data.map((item) => ({ member_id: item?.members?.id })),
        members_ref: [],
      });
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    projectId && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <AddMembersModal
        setFormData={setFormData}
        formData={formData}
        members={membersList}
        setMembers={setMembersList}
        getData={getData}
      />
      <ListGroup
        className="h-100 shadow-sm"
        style={{ backgroundColor: "white" }}
      >
        <ListGroup.Item className="pt-3 pb-3 border-0 border-bottom rounded-0">
          <div className="d-flex">
            <div>
              <h6 className="mb-0 fw-semi-bold text-muted">Members</h6>
              <span className="fs--2 mb-0 text-500">Manage team members</span>
            </div>
            {axisProPermission("store-project-members") && (
              <div className="ms-auto">
                <Button
                  className="btn btn-sm btn-falcon-primary"
                  onClick={() => {
                    navigate(
                      `?${createSearchParams({
                        ...allQueryParams,
                      })}&add_members=true`
                    );
                  }}
                >
                  <FaPlus />
                </Button>
              </div>
            )}
          </div>
        </ListGroup.Item>
        <SimpleBarReact style={{ height: "18rem", overflowX: "hidden" }}>
          {membersList?.length > 0 && !dataProcessing ? (
            membersList.map((member, index) => (
              <ListGroup.Item
                key={index}
                className="border-0 border-bottom rounded-0"
              >
                <Flex
                  alignItems="center"
                  className="position-relative flex-wrap"
                >
                  <Image
                    className={`status`}
                    height={30}
                    width={30}
                    src={member?.image ? member.image : AvatarImage}
                    alt={member?.full_name}
                  />
                  <div className="flex-1 ms-3 w-25">
                    <h6 className="mb-0 fw-semi-bold">
                      <div
                        className="text-dark"
                        style={{ textDecoration: "none", cursor: "pointer" }}
                        title={`Click here to view ${member?.full_name} details`}
                        onClick={() => handleUserProfileArea(member?.user_id)}
                      >
                        {member?.full_name}
                      </div>
                    </h6>
                    <p className="fs--2 mb-0 text-500">{member?.role}</p>
                  </div>
                  {axisProPermission("delete-project-members") && (
                    <div>
                      <Button
                        size="sm"
                        variant="transparent"
                        className="custom-button-action"
                        onClick={() => {
                          handleDelete(member?.id);
                        }}
                      >
                        <FaTimesCircle color="#ff5252" />
                      </Button>
                    </div>
                  )}
                </Flex>
              </ListGroup.Item>
            ))
          ) : (
            <ListGroup.Item className="h-100 border-0">
              {dataProcessing ? (
                <ListLoading style={{ height: "28vh", overflow: "hidden" }} />
              ) : (
                <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                  <FaUsersSlash size={40} className="text-mute" />
                  <span>No members found!</span>
                </div>
              )}
            </ListGroup.Item>
          )}
        </SimpleBarReact>
      </ListGroup>
      <UserProfile
        show={showUserProfileArea}
        handleClose={() => setShowUserProfileArea(false)}
        id={userProfile}
      />
    </>
  );
};

ProjectMembers.propTypes = {
  data: PropTypes.array,
};

export default ProjectMembers;
