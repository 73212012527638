import { React, useContext, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";

import { AuthWizardContext, StoreContext } from "context/Context";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";

const InitStore = ({ children }) => {
  const [loadComplete, setLoadComplete] = useState(false);
  const { user } = useContext(AuthWizardContext);
  const { addIitemToStore } = useContext(StoreContext);

  const getCurrentDate = () => {
    const current = new Date();
    const currentDate = `${current.getFullYear()}-${(
      "0" +
      (current.getMonth() + 1)
    ).slice(-2)}-${("0" + current.getDate()).slice(-2)}`;
    addIitemToStore("currentDate", currentDate);
  };

  const globalConfigurations = () => {
    addIitemToStore("globalConfigurations", {
      base_currency: user?.branch?.base_currency ?? "AED",
    });
  };

  const init = async () => {
    if (!loadComplete) {
      getCurrentDate();
      globalConfigurations();
      setLoadComplete(true);
    }
  };

  useEffect(() => {
    init();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !loadComplete ? (
    <Container
      className="d-flex align-items-center justify content-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <LoadingScreen />
    </Container>
  ) : (
    children
  );
};

InitStore.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InitStore;
