import { React, useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import PropTypes from "prop-types";
import { showToast } from "module/common/Toast/toast";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { TbArrowsShuffle } from "react-icons/tb";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import SelectCurrency from "components/form/SelectCurrency";
import SelectCustomer from "components/form/SelectCustomer";
import { apiCall } from "helpers/apiCalls";
import SelectContact from "components/form/SelectContact";
import { leadConversionFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import ListLoading from "module/common/ListLoading";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import {
  MdOutlineEmail,
  MdOutlineLowPriority,
  MdOutlineSubtitles,
} from "react-icons/md";
import {
  FaRegAddressCard,
  FaRegStickyNote,
  FaRegUserCircle,
} from "react-icons/fa";
import { SiProgress } from "react-icons/si";
import { AiFillBuild } from "react-icons/ai";
import { BiBuildingHouse } from "react-icons/bi";
import { FiPhoneCall } from "react-icons/fi";
import setNewFormData from "helpers/setNewFormData";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import SelectOpportunityType from "components/form/SelectOpportunityType";
import SelectTimeFrame from "components/form/SelectTimeFrame";

function ConvertModal() {
  const { overviewId } = useParams();
  const [formError, setFormError] = useState({});
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const convertStatus = queryParams.get("convert");
  const showModal = convertStatus ? true : false;
  const [formData, setFormData] = useState(leadConversionFormKeys);
  const [activeKey, setActiveKey] = useState("1");
  const [accountErrorCount, setAccountErrorCount] = useState(0);
  const [oppoertunityErrorCount, setOpportunityErrorCount] = useState(0);
  const [contactErrorCount, setContactErrorCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [leadData, setLeadData] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({ account: {}, opportunity: {}, contact: {} });
    setAccountErrorCount(0);
    setOpportunityErrorCount(0);

    setLoading(true);
    const refRemovedFormData = removeRefData(formData);
    axios({
      method: "post",
      url: `crm/convert-lead`,
      data: setNewFormData(refRemovedFormData),
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          formResetHandler();
          navigate(
            `/opportunity/overview/${response?.data?.data?.opportunity?.id}?converted=true&tab=overview`
          );
          setLoading(false);
        } else {
          showToast(response.data.message, "error");
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });

          if ("lead_id" in validation_error) {
            showToast(validation_error.lead_id[0], "error");
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setLoading(false);
      });
  };

  const formResetHandler = () => {
    setActiveKey("1");
    setAccountErrorCount(0);
    setContactErrorCount(0);
    setFormError({});
    setFormData({
      is_existing_account: 0,
      account_name: "",
      currency_code: "",
      currency_code_ref: { label: "", value: "" },
      customer_id: "",
      customer_id_ref: { label: "", value: "" },
      is_existing_contact: 1,
      first_name: "",
      phone_no: "",
      contact_id: "",
      contact_id_ref: { label: "", value: "" },
      lead_id: "",
      stage: "",
    });
  };

  const handleRadioChange = (event) => {
    const type = event.target.value;
    let updatedFormData = {};

    switch (type) {
      case "new_account":
        updatedFormData = {
          customer_id: "",
          customer_id_ref: { label: "", value: "" },
          is_existing_account: 0,
        };
        break;

      case "existing_account":
        updatedFormData = {
          account_name: "",
          currency_code: "",
          currency_code_ref: { label: "", value: "" },
          is_existing_account: 1,
        };
        break;

      case "new_contact":
        updatedFormData = {
          contact_id: "",
          contact_id_ref: { label: "", value: "" },
          is_existing_contact: 0,
        };
        break;

      case "existing_contact":
        updatedFormData = {
          first_name: "",
          phone_no: "",
          is_existing_contact: 1,
        };
        break;

      default:
        // Handle other cases or provide a default behavior
        return;
    }

    setFormData((prevFormData) => ({ ...prevFormData, ...updatedFormData }));
  };

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const fetchData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/lead/" + overviewId,
    });

    setFormData({
      ...formData,
      title: data.title,
      lead_id: overviewId,
      stage: "Qualification",
      account_name: data.is_company ? data.company_name : data.title,
      contact_id:
        data.primary_contact.length > 0 ? data.primary_contact[0].id : "",
      contact_id_ref:
        data.primary_contact.length > 0
          ? {
              label: data.primary_contact[0].full_name,
              value: data.primary_contact[0].id,
            }
          : { label: "", value: "" },
    });

    setLeadData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (convertStatus) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertStatus]);

  const handleToggle = (key) => {
    setActiveKey(key === activeKey ? null : key);
  };

  useEffect(() => {
    if (Object.keys(formError).length > 0) {
      const accountFields = ["account_name", "currency_code", "customer_id"];
      const contactFields = ["first_name", "phone_no", "contact_id"];

      const accountErrorCount = accountFields.filter(
        (field) => formError[field]
      ).length;

      const contactErrorCount = contactFields.filter(
        (field) => formError[field]
      ).length;

      setActiveKey(
        accountErrorCount > 0 ? "1" : contactErrorCount > 0 ? "2" : "1"
      );
      setAccountErrorCount(accountErrorCount);
      setContactErrorCount(contactErrorCount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formError]);

  return (
    <Modal
      size="lg"
      show={showModal}
      onHide={() => {
        formResetHandler();
        navigate(-1);
      }}
    >
      <Modal.Header closeButton>
        <div className="d-flex justify-content-between w-100">
          <div className="d-flex align-items-center">
            <TbArrowsShuffle size={20} className="me-2 text-dark" />
            <span className="custom-module-header">Convert Lead</span>
          </div>
          <div>
            {loading ? (
              <ListLoading
                style={{
                  maxHeight: "3.8vh",
                  width: "20vh",
                  overflow: "hidden",
                }}
              />
            ) : (
              <span
                className="badge p-2 rounded-start-3"
                style={{
                  backgroundColor: "#4bc0c9",
                }}
              >
                <span className="text-uppercase">
                  Reference : {leadData.reference}
                </span>
              </span>
            )}
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Accordion
          defaultActiveKey={["0", "1"]}
          className="custom-accordion"
          alwaysOpen
        >
          <Accordion.Item eventKey="0" className="custom-accordion-item pb-2">
            <Accordion.Header
              className="custom-accordion-header lead-form"
              style={{ backgroundColor: "#CDD1D6" }}
            >
              <span className="custom-header-text">
                LEAD DETAILS
                {oppoertunityErrorCount > 0 && (
                  <Badge pill bg="danger" className="p-1 ms-1">
                    <span className="m-1">{oppoertunityErrorCount}</span>
                  </Badge>
                )}
              </span>
            </Accordion.Header>
            <Accordion.Body className="custom-accordion-body border border-top p-0">
              {!loading ? (
                <Row>
                  <Col md={4} className="">
                    <div className="p-3">
                      <div className="d-flex align-items-center gap-2">
                        <MdOutlineSubtitles
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#ffd8df" }}
                          color="#e63757"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">Title</span>
                          <span>{leadData.title ? leadData.title : "N/A"}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <MdOutlineEmail
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#ceefff" }}
                          color="#3cb9f5"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">Email</span>
                          <span>{leadData.email ? leadData.email : "N/A"}</span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <FiPhoneCall
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#fff5b4" }}
                          color="#c4b138"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">Phone</span>
                          <span>
                            {leadData.phone_no ? leadData.phone_no : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <FaRegAddressCard
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#d7e4fd" }}
                          color="#3c62f5"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">
                            Address
                          </span>
                          <span>
                            {leadData.address ? leadData.address : "N/A"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="p-3">
                      <div className="d-flex align-items-center gap-2">
                        <AiFillBuild
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#ffe0c5" }}
                          color="#cb8040"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">
                            Status
                          </span>
                          <span className="text-capitalize">
                            {leadData.lead_status
                              ? leadData.lead_status
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <MdOutlineLowPriority
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#ffc6bf" }}
                          color="#cb4e40"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">
                            Priority
                          </span>
                          <span className="text-capitalize">
                            {leadData.lead_priority
                              ? leadData.lead_priority
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center gap-2 mt-2">
                        <FaRegUserCircle
                          className="rounded-2 p-1"
                          style={{ backgroundColor: "#d7d7d7" }}
                          color="#626262"
                          size={25}
                        />
                        <div className="d-flex flex-column">
                          <span className="custom-text-muted fs--2">Owner</span>
                          <span className="text-capitalize">
                            {leadData.lead_owner_name
                              ? leadData.lead_owner_name
                              : "N/A"}
                          </span>
                        </div>
                      </div>
                      {leadData.description ? (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          <FaRegStickyNote
                            className="rounded-2 p-1"
                            style={{ backgroundColor: "#dad4f6" }}
                            color="#5131d2"
                            size={25}
                          />
                          <div className="d-flex flex-column">
                            <span className="custom-text-muted fs--2">
                              Description
                            </span>
                            <span>
                              {leadData.description
                                ? leadData.description
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="p-3">
                      {leadData.company_name ? (
                        <div className="d-flex align-items-center gap-2">
                          <BiBuildingHouse
                            className="rounded-2 p-1"
                            style={{ backgroundColor: "#f5deff" }}
                            color="#bf3cf5"
                            size={25}
                          />
                          <div className="d-flex flex-column">
                            <span className="custom-text-muted fs--2">
                              Company Name
                            </span>
                            <span>
                              {leadData.company_name
                                ? leadData.company_name
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {leadData.industry ? (
                        <div className="d-flex align-items-center gap-2 mt-2">
                          <SiProgress
                            className="rounded-2 p-1"
                            style={{ backgroundColor: "#c9ffe8" }}
                            color="#45c28c"
                            size={25}
                          />
                          <div className="d-flex flex-column">
                            <span className="custom-text-muted fs--2">
                              Industry
                            </span>
                            <span>
                              {leadData.industry ? leadData.industry : "N/A"}
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              ) : (
                <ListLoading
                  style={{ maxHeight: "18vh", overflow: "hidden" }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1" className="custom-accordion-item pb-2">
            <Accordion.Header
              className="custom-accordion-header lead-form"
              style={{ backgroundColor: "#CDD1D6" }}
            >
              <span className="custom-header-text">
                OPPORTUNITY DETAILS
                {oppoertunityErrorCount > 0 && (
                  <Badge pill bg="danger" className="p-1 ms-1">
                    <span className="m-1">{oppoertunityErrorCount}</span>
                  </Badge>
                )}
              </span>
            </Accordion.Header>
            <Accordion.Body className="custom-accordion-body border border-top p-0">
              {!loading ? (
                <Row>
                  <Col md={6}>
                    <div className="p-3">
                      <Form.Group as={Col} xs={12}>
                        <Form.Label className="require-data">Title</Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            name="title"
                            onChange={handleFieldChange}
                            value={formData.title}
                            isInvalid={!!formError.title}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">{formError.title}</span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Time Frame
                        </Form.Label>
                        <Col>
                          <SelectTimeFrame
                            name="opportunity_time_frame"
                            value={formData.opportunity_time_frame_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.opportunity_time_frame}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.opportunity_time_frame}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Created Date
                        </Form.Label>
                        <Col>
                          <AppDatePicker
                            name="opportunity_created_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            onChange={handleFieldChange}
                            value={formData.opportunity_created_date}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.opportunity_created_date}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="p-3">
                      <Form.Group as={Col} xs={12}>
                        <Form.Label className="require-data">Type</Form.Label>
                        <Col>
                          <SelectOpportunityType
                            name="opportunity_type"
                            value={formData.opportunity_type_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.opportunity_type}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.opportunity_type}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Expected Amount
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="number"
                            name="expected_amount"
                            onChange={handleFieldChange}
                            value={formData.expected_amount}
                            isInvalid={!!formError.expected_amount}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.expected_amount}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Expected Closing Date
                        </Form.Label>
                        <Col>
                          <AppDatePicker
                            name="expected_closing_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            onChange={handleFieldChange}
                            value={formData.expected_closing_date}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.expected_closing_date}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : (
                <ListLoading
                  style={{ maxHeight: "18vh", overflow: "hidden" }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Accordion
          activeKey={activeKey}
          className="custom-accordion"
          flush
          onSelect={handleToggle}
        >
          <Accordion.Item eventKey="1" className="custom-accordion-item pb-2">
            <Accordion.Header
              className="custom-accordion-header lead-form"
              style={{ backgroundColor: "#CDD1D6" }}
            >
              <span className="custom-header-text">
                ACCOUNT
                {accountErrorCount > 0 && (
                  <Badge pill bg="danger" className="p-1 ms-1">
                    <span className="m-1">{accountErrorCount}</span>
                  </Badge>
                )}
              </span>
            </Accordion.Header>
            <Accordion.Body className="custom-accordion-body border border-top p-0">
              {!loading ? (
                <Row>
                  <Col md={6} className="border-end">
                    <div className="p-3">
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label="New"
                          name="account"
                          className="me-3"
                          value="new_account"
                          checked={
                            formData.is_existing_account === 0 ? "checked" : ""
                          }
                          onChange={handleRadioChange}
                          inline
                        />
                      </div>
                      <Form.Group as={Col} xs={12} className="">
                        <Form.Label className="require-data">
                          Account Name
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            name="account_name"
                            onChange={handleFieldChange}
                            value={formData.account_name}
                            isInvalid={!!formError.account_name}
                            disabled={
                              formData.is_existing_account === 1 ? true : false
                            }
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.account_name}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Currency Code
                        </Form.Label>
                        <Col>
                          <SelectCurrency
                            name="currency_code"
                            value={formData.currency_code_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.currency_code}
                            isDisabled={
                              formData.is_existing_account === 1 ? true : false
                            }
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.currency_code}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="p-3">
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label="Existing"
                          name="account"
                          className="me-3"
                          value="existing_account"
                          checked={
                            formData.is_existing_account === 1 ? "checked" : ""
                          }
                          onChange={handleRadioChange}
                          inline
                        />
                      </div>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label className="require-data">Client</Form.Label>
                        <Col>
                          <SelectCustomer
                            name="customer_id"
                            value={formData.customer_id_ref}
                            handleFieldChange={(e, action) => {
                              handleFieldChange(e, action, "account");
                            }}
                            error={formError.customer_id}
                            isDisabled={
                              formData.is_existing_account === 1 ? false : true
                            }
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.customer_id}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : (
                <ListLoading
                  style={{ maxHeight: "18vh", overflow: "hidden" }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2" className="custom-accordion-item pb-2">
            <Accordion.Header
              className="custom-accordion-header lead-form"
              style={{ backgroundColor: "#CDD1D6" }}
            >
              <span className="custom-header-text">
                CONTACT INFORMATION
                {contactErrorCount > 0 && (
                  <Badge pill bg="danger" className="p-1 ms-1">
                    <span className="m-1">{contactErrorCount}</span>
                  </Badge>
                )}
              </span>
            </Accordion.Header>
            <Accordion.Body className="custom-accordion-body border border-top p-0">
              {!loading ? (
                <Row>
                  <Col md={6}>
                    <div className="p-3">
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label="Existing"
                          name="contact"
                          className="me-3"
                          value="existing_contact"
                          checked={
                            formData.is_existing_contact === 1 ? "checked" : ""
                          }
                          onChange={handleRadioChange}
                        />
                      </div>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label className="require-data">
                          Contact
                        </Form.Label>
                        <Col>
                          <SelectContact
                            name="contact_id"
                            handleFieldChange={(e, action) => {
                              handleFieldChange(e, action, "contact");
                            }}
                            value={formData.contact_id_ref}
                            error={formError.contact_id}
                            isDisabled={
                              formData.is_existing_contact === 1 ? false : true
                            }
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.contact_id}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col md={6} className="border-end">
                    <div className="p-3">
                      <div className="d-flex">
                        <Form.Check
                          type="radio"
                          label="New"
                          name="is_existing_contact"
                          className="me-3"
                          value="new_contact"
                          checked={
                            formData.is_existing_contact === 0 ? "checked" : ""
                          }
                          onChange={handleRadioChange}
                        />
                      </div>
                      <Form.Group as={Col} xs={12} className="">
                        <Form.Label className="require-data">
                          First Name
                        </Form.Label>
                        <Col>
                          <Form.Control
                            type="text"
                            name="first_name"
                            onChange={(e, action) => {
                              handleFieldChange(e, action, "contact");
                            }}
                            value={formData.first_name}
                            isInvalid={!!formError.first_name}
                            disabled={formData.is_existing_contact === 1}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.first_name}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label className="require-data">
                          Phone Number
                        </Form.Label>
                        <Col>
                          <PhoneNumberInput
                            name="phone_no"
                            onChange={(e, action) => {
                              handleFieldChange(e, action, "contact");
                            }}
                            value={formData.phone_no}
                            isInvalid={!!formError.phone_no}
                            isDisabled={formData.is_existing_contact === 1}
                          />
                          <Form.Control.Feedback
                            type="invalid"
                            className="d-flex p-0 m-0"
                          >
                            <span className="ms-auto">
                              {formError.phone_no}
                            </span>
                          </Form.Control.Feedback>
                        </Col>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              ) : (
                <ListLoading
                  style={{ maxHeight: "18vh", overflow: "hidden" }}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        {!loading ? (
          <>
            <Button
              variant="danger"
              size="sm"
              onClick={() => {
                formResetHandler();
                navigate(-1);
              }}
            >
              Close
            </Button>
            <Button variant="success" size="sm" onClick={handleSubmit}>
              Convert
            </Button>
          </>
        ) : (
          <div>
            <ListLoading
              style={{
                maxHeight: "9vh",
                width: "20vh",
                overflow: "hidden",
              }}
            />
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

ConvertModal.propTypes = {
  data: PropTypes.any,
};

export default ConvertModal;
