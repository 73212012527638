import { React, useState, useEffect } from "react";
import { Card, ListGroup } from "react-bootstrap";
import { RiTimerFlashLine } from "react-icons/ri";
import { TimerButton } from "../Buttons/AppButtons";

function DigitalTimer({
  startTime,
  handleButtonVisibility,
  loadingTimerButton = false,
  timerRunning = false,
  timerButtonVisibility = true,
}) {
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [dayElapsed, setDayElapsed] = useState(0);

  useEffect(() => {
    if (startTime) {
      const startDateTime = new Date(startTime + " UTC");
      const interval = setInterval(() => {
        const currentTime = new Date();
        const elapsedDays = Math.floor(
          (currentTime - startDateTime) / (1000 * 60 * 60 * 24)
        );
        setDayElapsed(elapsedDays);
        const elapsedTime = currentTime - startDateTime;
        setTimeElapsed(elapsedTime);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    } else {
      setDayElapsed(0);
      setTimeElapsed(0);
    }
  }, [startTime]);

  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(milliseconds / (1000 * 60 * 60)) % 24;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <Card>
      <Card.Body className="p-0 rounded-0 shadow border">
        <ListGroup horizontal>
          <ListGroup.Item
            className={`rounded-0 pt-0 pb-0 ps-2 pe-2 custom-digital-timer border-0 ${
              dayElapsed === 0 ? "custom-digital-timer-day-wrapper" : ""
            }`}
          >
            <RiTimerFlashLine
              size={25}
              color={dayElapsed === 0 ? " #fff" : "#2b3139"}
            />
          </ListGroup.Item>
          {startTime && (
            <>
              {dayElapsed > 0 && (
                <ListGroup.Item className="rounded-0 pt-0 pb-0 ps-2 pe-2 custom-digital-timer custom-digital-timer-day-wrapper border-0">
                  <div className="timer-container d-flex flex-column justify-content-center align-items-center">
                    <span className="fw-bolder custom-digital-timer-day">
                      {dayElapsed}
                    </span>
                    <span className="custom-text-muted-crm custom-digital-timer-day-header">
                      {dayElapsed > 1 ? "DAYS" : "DAY"}
                    </span>
                  </div>
                </ListGroup.Item>
              )}
              <ListGroup.Item className="rounded-0 pt-0 pb-0 ps-2 pe-2 custom-digital-timer border-0">
                <div className="timer-container d-flex flex-column justify-content-center align-items-center">
                  <span className="fw-bolder">{formatTime(timeElapsed)}</span>
                  <span className="custom-text-muted-crm">HOURS</span>
                </div>
              </ListGroup.Item>
            </>
          )}
          {timerButtonVisibility && (
            <ListGroup.Item
              className={`rounded-0 ${
                startTime ? "pt-0 pb-0 ps-2 pe-2" : ""
              } custom-digital-timer custom-digital-timer-button-wrapper  border-start border-0`}
            >
              <TimerButton
                variant={"danger"}
                handleTimer={handleButtonVisibility}
                loadingTimer={loadingTimerButton}
                title={"Stop Timer"}
                timerRunning={timerRunning}
              />
            </ListGroup.Item>
          )}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

export default DigitalTimer;
