import ExportButton from "components/export-button/ExportButton";
import useAxisproPermission from "hooks/useAxisproPermission";
import { AddButton } from "module/common/Buttons/AppButtons";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import {
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { GRID, LIST } from "./View/constants";
import { BsGrid, BsList } from "react-icons/bs";
import CommonProjectFilter from "../SupportingComponets/CommonProjectFilter";
import { Link } from "react-router-dom";
import { commonProjectFilterFormKeys } from "helpers/formKeys";

const ProjectTableHeader = ({ children }) => {
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [filterVisibility, setFilterVisibility] = useState(true);
  const activeView = queryParams.get("view");
  const { itemInfoId } = useParams();
  const [filterFields, setFilterFields] = useState(commonProjectFilterFormKeys);
  return (
    <Card className="mb-3 mt-3">
      <Card.Header className={`p-0 ${itemInfoId ? "d-none" : ""}`}>
        <div
          className={`d-flex justify-content-between align-items-center p-3 ${
            !filterVisibility ? "border-bottom" : ""
          }`}
        >
          <div className="d-flex align-items-center">
            <AiOutlineCodeSandbox size={30} className="text-dark" />
            <span className="fs-1 text-uppercase">PROJECT LIST</span>
          </div>
          <div className="d-flex gap-1">
            {axisProPermission("export-project") && (
              <ExportButton
                url="crm/project-export"
                initialMessage={"Project export started"}
                successMessage={"Project export completed"}
                file_type={"pdf"}
                variant={"danger"}
                filters={filterFields}
              />
            )}
            {axisProPermission("store-project") && (
              <AddButton
                route={`/project/add?${createSearchParams({
                  ...allQueryParams,
                })}`}
                variant="success"
              />
            )}
            <FilterCommonButton
              filterVisibility={filterVisibility}
              setFilterVisibility={setFilterVisibility}
            />
            <Button
              variant="transparent"
              size="sm"
              className="list-add-button"
              style={{ boxShadow: "none" }}
              title={"Grid view"}
              as={Link}
              to={`/project?${createSearchParams({
                ...allQueryParams,
                view: activeView === LIST ? GRID : LIST,
              })}`}
            >
              {activeView === LIST ? (
                <BsGrid size={20} />
              ) : (
                <BsList size={20} />
              )}
            </Button>
          </div>
        </div>
        <div style={{ display: filterVisibility ? "block" : "none" }}>
          <CommonProjectFilter
            filterFields={filterFields}
            setFilterFields={setFilterFields}
          />
        </div>
      </Card.Header>
      <Card.Body className="p-0">{children}</Card.Body>
    </Card>
  );
};

export default ProjectTableHeader;
