export const removeEmptyCustomField = data => {
  for (const key in data) {
    if (data[key] instanceof File) {
      continue;
    }
    if (data[key] && typeof data[key] === 'object') {
      data[key] = removeEmptyCustomField(data[key]);

      if (Object.keys(data[key]).length === 0) {
        delete data[key];
      }
    }
  }
  return data;
};
