import { React, useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import Select from "react-select";

const MultiSelectProjectMembers = ({
  onChange,
  name,
  error,
  value,
  style,
  placeholder,
  excludeUser,
  assignee_id,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    loadOptions(value).then((loadedOptions) => {
      setOptions(loadedOptions);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludeUser]);

  const loadOptions = (inputValue) => {
    return new Promise(async (resolve) => {
      let response = await axios.get("user/users-autocomplete", {
        params: {
          exclude_user: typeof excludeUser === "string" ? excludeUser : "",
        },
      });
      let data = response && response.data ? response.data.data : [];

      let filteredUsers = [];

      if (typeof excludeUser === "object") {
        let tempExcludeUsers = new Set(
          excludeUser.map(({ members }) => members?.user_id)
        );
        //add Assignee to the excluded list
        assignee_id && tempExcludeUsers.add(assignee_id);
        filteredUsers = data.filter(({ id }) => !tempExcludeUsers.has(id));
      } else {
        filteredUsers = data;
      }

      const loadedOptions = filteredUsers.map((item) => ({
        label: item.first_name,
        value: item.id,
      }));

      resolve(loadedOptions);
    });
  };

  return (
    <Select
      isMulti
      cacheOptions
      options={options}
      loadOptions={loadOptions}
      defaultOptions
      isClearable
      name={name ? name : "Select"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={onChange}
      isSearchable={true}
      className={
        error
          ? "form-control ps-0 py-0 is-invalid text-uppercase"
          : "text-uppercase"
      }
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: "fit-content",
          minHeight: 32,
          ...style,
        }),
      }}
    ></Select>
  );
};

MultiSelectProjectMembers.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default MultiSelectProjectMembers;
