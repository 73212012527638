import React from "react";
import SelectDepartment from "components/form/SelectDepartment";
import SelectDesignation from "components/form/SelectDesignation";
import SelectRole from "components/form/SelectRole";
import SelectLeadStatus from "components/form/SelectLeadStatus";
import SelectUsers from "components/form/SelectUsers";
import SelectLeadSource from "components/form/SelectLeadSource";
import SelectLeadPriority from "components/form/SelectLeadPriority";
import ColumnSelector from "module/common/Filter/ColumnSelector";
import SelectCampaignType from "components/form/SelectCampaignType";
import SelectProduct from "components/form/SelectProduct";
import SelectCampaignStatus from "components/form/SelectCampaignStatus";
import SelectOpportunityType from "components/form/SelectOpportunityType";
import SelectCustomer from "components/form/SelectCustomer";
import SelectOpportunityStage from "components/form/SelectOpportunityStage";
import SelectLead from "components/form/SelectLead";
import SelectCampaign from "components/form/SelectCampaign";

const componentMap = {
  designation: SelectDesignation,
  department: SelectDepartment,
  role: SelectRole,
  lead_status: SelectLeadStatus,
  lead_source: SelectLeadSource,
  lead_priority: SelectLeadPriority,
  date_range_for: ColumnSelector,
  camapign_type: SelectCampaignType,
  product: SelectProduct,
  users: SelectUsers,
  campaign_status: SelectCampaignStatus,
  opportunity_type: SelectOpportunityType,
  account: SelectCustomer,
  opportunity_stage: SelectOpportunityStage,
  lead: SelectLead,
  campaign: SelectCampaign,
};

const getCustomComponent = (componentName, name, value, handleFieldChange) => {
  const Component = componentMap[componentName?.replace("_id", "")];
  return Component ? (
    <Component
      name={`${name ?? componentName}`}
      value={value}
      handleFieldChange={handleFieldChange}
    />
  ) : null;
};

export default getCustomComponent;
