import React, { useEffect } from "react";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { FaCalendar, FaExclamationCircle, FaTimes } from "react-icons/fa";

const AppTimePicker = ({
  onChange,
  isInvalid,
  name,
  value,
  showDefaultDate,
  className,
  ...rest
}) => {
  const getFormatedDate = (date) => {
    let formatedDate;
    if (date) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      formatedDate = date
        ? date.getFullYear() +
          "-" +
          ("0" + (date.getMonth() + 1)).slice(-2) +
          "-" +
          ("0" + date.getDate()).slice(-2) +
          " " +
          hours +
          ":" +
          minutes +
          ":" +
          seconds
        : null;
    }
    return formatedDate;
  };

  const handleFieldChange = (date, name) => {
    onChange({ target: { name: name, value: getFormatedDate(date) } });
  };

  useEffect(() => {
    if (!value && showDefaultDate) {
      onChange({ target: { name: name, value: getFormatedDate(new Date()) } });
    }
  });

  return (
    <div>
      <DateTimePicker
        className={`custom-date-time-picker${className ? " " + className : ""}${
          isInvalid ? " is-invalid " : ""
        }`}
        onChange={(date) => handleFieldChange(date, name)}
        value={
          value && value ? new Date(value) : showDefaultDate ? new Date() : null
        }
        calendarClassName={"custom-date-time-picker-calendar"}
        clearIcon={value ? <FaTimes size={12} color="#b6c1d2" /> : null}
        format="dd-MM-y h:mm aa"
        disableClock={true}
        calendarIcon={
          isInvalid ? (
            <FaExclamationCircle size={12} color="#dc3545" />
          ) : !value ? (
            <FaCalendar size={12} color="#b6c1d2" />
          ) : null
        }
        {...rest}
      />
    </div>
  );
};

export default AppTimePicker;
