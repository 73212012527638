import React from "react";
import PropTypes from "prop-types";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

import Flex from "components/common/Flex";
import SoftBadge from "components/common/SoftBadge";
import generateIconComponent from "helpers/generateIconComponent";

const NavbarVerticalMenuItem = ({ route, open }) => {
  return (
    <div className={route.children && open ? "active-style-with-children" : ""}>
      <Flex alignItems="center gap-1">
        {route?.icon && (
          <span className="nav-link-icon ms-3 p-1">
            {generateIconComponent(route.icon, route.size, "#DDDDDE")}
          </span>
        )}
        <span
          className={
            route.icon
              ? "nav-link-text ps-1 hover-style"
              : "hover-style nav-link-text ps-1 ms-3"
          }
        >
          {route.name}
        </span>
        {route.badge && (
          <SoftBadge pill bg={route.badge.type} className="ms-2">
            {route.badge.text}
          </SoftBadge>
        )}
        {route.subIcon && (
          <span className="nav-link-icon ms-auto me-2">
            <MdOutlineKeyboardArrowRight size={17} />
          </span>
        )}
      </Flex>
    </div>
  );
};

// prop-types
const routeShape = {
  active: PropTypes.bool,
  name: PropTypes.string.isRequired,
  to: PropTypes.string,
  icon: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
};
routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
NavbarVerticalMenuItem.propTypes = {
  route: PropTypes.shape(routeShape).isRequired,
};

export default React.memo(NavbarVerticalMenuItem);
