import { React, useContext, useEffect, useState } from "react";
import { Link, createSearchParams, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";
import { AiOutlineMenu } from "react-icons/ai";
import { leadSourceSummeryReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";
import ProgressBar from 'react-bootstrap/ProgressBar';
import LeadSourceSummeryReportFilterSection from "../ReportFilterSection/LeadSourceSummeryReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import removeRefData from "helpers/removeRefData";

const LeadSourceSummeryReport = () => {
    const { user } = useContext(AuthWizardContext);
    const company_name = user?.branch?.company_name ?? "";
    const [queryParams] = useSearchParams();
    const [leadSourceSummeryReport, setLeadSourceSummeryReport] = useState({});
    const allQueryParams = Object.fromEntries([...queryParams]);
    const filterItems = queryParams.get("filters");
    const pageNumber = queryParams.get("page");
    const [dataProcessing, setDataProcessing] = useState(false);
    const reportPermissions = user?.menu_links?.find(
        (item) => item?.to === "reports"
    );
    const [show, setShow] = useState(false);
    const [filterFields, setFilterFields] = useState(
        leadSourceSummeryReportFilterKeys
    );
    const handleCanvas = () => setShow(!show);
    const getSourceSummeryReportData = async (page = 1) => {
        setDataProcessing(true);
        const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
        const filteredObject = removeRefData(removeEmptyFields(parsedFilterItems));
        try {
            const data = await apiCall({
                url: `crm/lead-source-summary-report`,
                params: {
                    page: page ? page : 1,
                    ...filteredObject,
                },
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                },
            });

            setLeadSourceSummeryReport(data);
            setDataProcessing(false);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSourceSummeryReportData(pageNumber);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, filterItems]);

    return (
        <div>
            <Card className="mt-3 mb-3">
                <Card.Header className="p-0 shadow-sm">
                    <div className="d-flex">
                        {reportPermissions && (
                            <div className="pe-2 border-end">
                                <Button
                                    className="border-0"
                                    size="lg"
                                    variant="light"
                                    style={{
                                        backgroundColor: "white",
                                        outline: "none",
                                        boxShadow: "none",
                                    }}
                                    onClick={handleCanvas}
                                >
                                    <AiOutlineMenu className="text-dark" />
                                </Button>
                            </div>
                        )}

                        <div className="border-end d-flex align-items-center">
                            <ReportFilterButton
                                url="reports/lead-source-summary-report"
                                filters={filterFields}
                                setFilters={setFilterFields}
                            />
                        </div>
                        <div className="d-flex ms-auto">
                            <div className="py-2  h-100 d-flex border-start">
                                <Export
                                    url={"crm/lead-source-summary-report/export"}
                                    successMessage={"Lead Source Summary Report Export Completed"}
                                    initialMessage={"Lead Source Summary Report Export Started"}
                                />
                            </div>
                            <CloseButton />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body
                    className="p-0"
                    style={{
                        maxHeight: "78.5vh",
                        minHeight: "78.5vh",
                        overflow: "scroll",
                    }}
                >
                    <div className="border-bottom p-3">
                        <LeadSourceSummeryReportFilterSection
                            filters={filterFields}
                            setFilters={setFilterFields}
                        />
                    </div>
                    <div className="d-flex flex-column gap-2 mt-3">
                        <span className="fs--1 align-self-center">{company_name}</span>
                        <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
                            Lead Source Summery Report
                        </Link>
                    </div>
                    {leadSourceSummeryReport?.data?.length > 0 && <div className="d-flex gap-1 align-items-end p-0 px-3 mt-2">
                        <span className="text-success fs-3">{leadSourceSummeryReport?.data[0]?.total_leads}</span>
                        <span>Leads</span>
                    </div>}
                    <div className="mt-2">
                        {!dataProcessing && leadSourceSummeryReport?.data?.length > 0 ? (
                            <Table striped bordered hover responsive size="sm">
                                <thead>
                                    <tr
                                        style={{
                                            backgroundColor: "#00185894",
                                            color: "whitesmoke",
                                        }}
                                    >
                                        <th className="fw-bold text-light pt-2 pb-2">Source</th>
                                        <th className="fw-bold text-light pt-2 pb-2">
                                            LEADS
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.keys(leadSourceSummeryReport).length > 0 &&
                                        leadSourceSummeryReport.data.length > 0 ? (
                                        leadSourceSummeryReport.data.map((item, key) => (
                                            <tr key={key}>
                                                <td className="text-capitalize">
                                                    <Link
                                                        className="text-dark"
                                                        to={`/reports/lead-source-summary-report/${item.lead_source_id}?${filterItems ? createSearchParams({ filters: filterItems }) : ''}`}
                                                    >
                                                        {item.lead_source}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <div className="d-flex align-items-center gap-1">
                                                        <span>{item.count}</span>
                                                        <ProgressBar variant="warning" now={item.lead_origin_rate} style={{ height: '16px', width: '100%' }} />
                                                        <span>{item.lead_origin_rate}</span>
                                                        <span> %</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr height="100px">
                                            <td colSpan={7} className="text-center">
                                                <span>No Data Found</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        ) : (
                            <>
                                {dataProcessing ? (
                                    <div
                                        className="d-flex justify-content-center align-item-center"
                                        style={{ minHeight: "48vh" }}
                                    >
                                        <LoadingScreen message="Loading Leads Report" />
                                    </div>) : (
                                    <>
                                        <h5 className="text-center text-secondary mt-2">
                                            Leads not found!
                                        </h5>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Card.Body>
                <Card.Footer
                    className="border-top d-flex align-items-center w-100"
                    style={{ height: dataProcessing ? "5.5vh" : "5.5vh" }}
                >
                    {leadSourceSummeryReport &&
                        leadSourceSummeryReport.links &&
                        !dataProcessing && (
                            <ReportPagination
                                data={leadSourceSummeryReport}
                                url="/reports/lead-source-summary-report"
                                search={allQueryParams}
                            />
                        )}
                </Card.Footer>
            </Card>
            <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
        </div>
    );
};

export default LeadSourceSummeryReport;
