import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { showToast } from "module/common/Toast/toast";
import { FcClearFilters } from "react-icons/fc";
import getInitializedFilters from "./getInitializedFilters";
import DateRangePicker from "module/common/Filter/DateRangePicker";
const CommonFilter = ({ filterKeys, date_range_for }) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(filterKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const filterItems = JSON.stringify(
    typeof allQueryParams === "object" ? allQueryParams : {}
  );
  const filter = getInitializedFilters(filterFields, setFilterFields);
  const handleFilterApply = () => {
    if (
      !filterFields.date_range_for &&
      !date_range_for &&
      filterFields.date_range
    ) {
      showToast(`Please Select Date Field`, "error");
      return;
    }

    if (filterFields.date_range === "custom_date" && !filterFields.from_date) {
      showToast(`Please Select From Date`, "error");
      return;
    } else if (
      filterFields.date_range === "custom_date" &&
      !filterFields.to_date
    ) {
      showToast(`Please Select To Date`, "error");
      return;
    }

    Object.keys(filterFields).forEach((key) => {
      if (key.endsWith("_ref") && typeof filterFields[key] === "object") {
        if (Object.keys(removeEmptyFields(filterFields[key]))?.length === 0) {
          filterFields[key] = "";
        } else {
          filterFields[key] = JSON.stringify(filterFields[key]);
        }
      }
    });

    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    let filterFields = JSON.parse(filterItems);
    Object.keys(filterFields).forEach((key) => {
      if (key.endsWith("_ref")) {
        filterFields[key] = JSON.parse(filterFields[key]);
      }
    });

    setFilterFields((previous) => ({
      ...previous,
      ...filterFields,
    }));
    return () => {
      setFilterFields(filterKeys);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  const handleResetFilter = () => {
    setFilterFields(filterKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        {filter.map((item, index) => (
          <Col md={2} key={index}>
            <Row>
              <Form.Group as={Col}>
                <Form.Label className="fs--1">{item.label}</Form.Label>
                <Col>
                  {item.type === "text" ? (
                    <Form.Control
                      type={item.type}
                      name={item.name}
                      onChange={item.onChange}
                      value={item.value}
                    />
                  ) : item.type === "custom-select" ? (
                    item.component
                  ) : item.type === "select" ? (
                    <Form.Select
                      name={item.name}
                      onChange={item.onChange}
                      value={item.value}
                    >
                      {item.options.map((option, key) => (
                        <option value={option.value} key={key}>
                          {option.label}
                        </option>
                      ))}
                    </Form.Select>
                  ) : item.type === "date-range" ? (
                    <DateRangePicker
                      formData={filterFields}
                      className={"custom-date-range-picker"}
                      setFormData={setFilterFields}
                      dateRange_form={{
                        to: "to_date",
                        from: "from_date",
                        range: "date_range",
                        month: "month",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Row>
          </Col>
        ))}
        {filter?.length !== 5 && <Col className="flex-grow-1" />}
        <Col md={2}>
          <Form.Group
            as={Col}
            md={2}
            className="d-flex justify-content-end align-items-end h-100 w-100"
          >
            <div className="d-flex align-items-end gap-2">
              <Button
                size="sm"
                variant="warning"
                onClick={handleFilterApply}
                title="Apply Added Filters"
              >
                Apply
              </Button>
              <Button
                size="sm"
                variant="transparent"
                className="shadow-sm"
                onClick={handleResetFilter}
                title="Clear All Filters"
              >
                <FcClearFilters size={18} />
              </Button>
            </div>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export default CommonFilter;
