import { React } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const SelectTodoStatus = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
}) => {
  const setItems = async (value) => {
    let response = await axios.get("crm/todo-status", {
      params: { name: value },
    });
    let data = response && response?.data ? response?.data.data : [];
    return data.map((item) => ({
      label: item?.replace("_", " "),
      value: item,
    }));
  };

  const updatedValue = {
    label: value?.label?.replace("_", " "),
    value: value?.value,
  };

  return (
    <AsyncSelect
      cacheOptions
      loadOptions={setItems}
      defaultOptions
      isClearable
      name={name ? name : "department"}
      value={updatedValue}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={
        error
          ? "form-control ps-0 py-0  border-0 shadow-none  is-invalid text-uppercase"
          : "text-uppercase"
      }
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: 32,
          minHeight: 32,
          ...style,
        }),
      }}
    />
  );
};

SelectTodoStatus.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectTodoStatus;
