import { AuthWizardContext } from "context/Context";
import generateIconComponent from "helpers/generateIconComponent";
import React, { useContext } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ReportGrid = () => {
  const { user } = useContext(AuthWizardContext);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const projectReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "project-report"
  );
  const projectDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/project-report"
    );
  const sprintDetailedReportPermission =
    projectReportPermissions?.children?.find(
      (item) => item?.to === "reports/sprint-detailed-report"
    );

  const taskReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "task-report"
  );

  const taskDetailedReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/task-report"
  );

  const runningOrCompletedTasksReportPermission =
    taskReportPermissions?.children?.find(
      (item) => item?.to === "reports/running-completed-tasks-report"
    );

  const timeTrackerReportPermission = taskReportPermissions?.children?.find(
    (item) => item?.to === "reports/time-tracker-report"
  );

  const employeeReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "employee-report"
  );

  const employeeTaskReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-report"
    );

  const employeeTaskSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-task-summary-report"
    );

  const employeeLeadSummaryReportPermission =
    employeeReportPermissions?.children?.find(
      (item) => item?.to === "reports/employee-lead-report"
    );

  const leadReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "lead-report"
  );

  const leadDetailedReportPermission = leadReportPermissions?.children?.find(
    (item) => item?.to === "reports/leads-report"
  );

  const leadConversationReportPermission =
    leadReportPermissions?.children?.find(
      (item) => item?.to === "reports/lead-conversion-report"
    );

  const leadSourceReportPermission = leadReportPermissions?.children?.find(
    (item) => item?.to === "reports/lead-source-summary-report"
  );

  const opportunityReportPermissions = reportPermissions?.children?.find(
    (item) => item?.slug === "opportunity-report"
  );

  const opportunityDetailedReportPermission =
    opportunityReportPermissions?.children?.find(
      (item) => item?.to === "reports/opportunity-report"
    );

  return (
    <div>
      <Row className="gx-2">
        {projectReportPermissions && (
          <Col className="border-dashed-end border-dashed-bottom">
            <Accordion
              defaultActiveKey={["0"]}
              alwaysOpen
              className="custom-acccordion-reports"
            >
              <Accordion.Item
                eventKey="0"
                className="custom-acccordion-item-reports"
              >
                <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                  <span
                    className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports"
                    style={{ color: "#6F7275" }}
                  >
                    {projectReportPermissions?.name}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {projectDetailedReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            projectDetailedReportPermission?.icon,
                            projectDetailedReportPermission?.icon_size,
                            "#e63757"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-project-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${projectDetailedReportPermission?.to}`}
                          >
                            {projectDetailedReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                    {sprintDetailedReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            sprintDetailedReportPermission?.icon,
                            sprintDetailedReportPermission?.icon_size,
                            "#29bbe7"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-sprint-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${sprintDetailedReportPermission?.to}`}
                          >
                            {sprintDetailedReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        )}
        {taskReportPermissions && (
          <Col className="border-dashed-bottom">
            <Accordion
              defaultActiveKey={["1"]}
              alwaysOpen
              className="custom-acccordion-reports"
            >
              <Accordion.Item
                eventKey="1"
                className="custom-acccordion-item-reports"
              >
                <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                  <span
                    className="text-uppercase fs-0 fw-bold custom-acccordion-header-span-reports"
                    style={{ color: "#6F7275" }}
                  >
                    {taskReportPermissions?.name}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {taskDetailedReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            taskDetailedReportPermission?.icon,
                            taskDetailedReportPermission?.icon_size,
                            "#9a5cfe"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-task-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${taskDetailedReportPermission?.to}`}
                          >
                            {taskDetailedReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                    {runningOrCompletedTasksReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            runningOrCompletedTasksReportPermission?.icon,
                            runningOrCompletedTasksReportPermission?.icon_size,
                            "#3db6d2"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-running-completed-tasks-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${runningOrCompletedTasksReportPermission?.to}`}
                          >
                            {runningOrCompletedTasksReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {timeTrackerReportPermission && (
                    <Row className="mt-3">
                      <Col md={4}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            timeTrackerReportPermission?.icon,
                            timeTrackerReportPermission?.icon_size,
                            "#5e6f12"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-task-tracker-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${timeTrackerReportPermission?.to}`}
                          >
                            {timeTrackerReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        )}
      </Row>
      <Row className="gx-2">
        {employeeReportPermissions && (
          <Col className="border-dashed-end border-dashed-bottom">
            <Accordion
              defaultActiveKey={["2"]}
              alwaysOpen
              className="custom-acccordion-reports"
            >
              <Accordion.Item
                eventKey="2"
                className="custom-acccordion-item-reports"
              >
                <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                  <span
                    className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports"
                    style={{ color: "#6F7275" }}
                  >
                    {employeeReportPermissions?.name}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {employeeTaskReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            employeeTaskReportPermission?.icon,
                            employeeTaskReportPermission?.icon_size,
                            "#27c6fd"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-employee-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${employeeTaskReportPermission?.to}`}
                          >
                            {employeeTaskReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                    {employeeTaskSummaryReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            employeeTaskSummaryReportPermission?.icon,
                            employeeTaskSummaryReportPermission?.icon_size,
                            "#da452f"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-employee-task-summary-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${employeeTaskSummaryReportPermission?.to}`}
                          >
                            {employeeTaskSummaryReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {employeeLeadSummaryReportPermission && (
                    <Row className="mt-3">
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            employeeLeadSummaryReportPermission?.icon,
                            employeeLeadSummaryReportPermission?.icon_size,
                            "#0fa947"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-employee-lead-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${employeeLeadSummaryReportPermission?.to}`}
                          >
                            {employeeLeadSummaryReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        )}
        {opportunityReportPermissions && (
          <Col className="border-dashed-bottom">
            <Accordion
              defaultActiveKey={["3"]}
              alwaysOpen
              className="custom-acccordion-reports"
            >
              <Accordion.Item
                eventKey="3"
                className="custom-acccordion-item-reports"
              >
                <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                  <span
                    className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports"
                    style={{ color: "#6F7275" }}
                  >
                    {opportunityReportPermissions?.name}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {opportunityDetailedReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            opportunityDetailedReportPermission?.icon,
                            opportunityDetailedReportPermission?.icon_size,
                            "#ff9751"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-opportunity-report mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${opportunityDetailedReportPermission?.to}`}
                          >
                            {opportunityDetailedReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        )}
      </Row>
      {leadReportPermissions && (
        <Row className="gx-2">
          <Col className="border-dashed-bottom border-dashed-end" md={6}>
            <Accordion
              defaultActiveKey={["4"]}
              alwaysOpen
              className="custom-acccordion-reports"
            >
              <Accordion.Item
                eventKey="4"
                className="custom-acccordion-item-reports"
              >
                <Accordion.Header className="custom-acccordion-header-reports border-dashed-bottom">
                  <span
                    className="text-uppercase fw-bold fs-0 custom-acccordion-header-span-reports"
                    style={{ color: "#6F7275" }}
                  >
                    Leads Reports
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {leadDetailedReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            leadDetailedReportPermission?.icon,
                            leadDetailedReportPermission?.icon_size,
                            "#4d5969"
                          )}
                          <Link
                            className="custom-title-reports fs--1 mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${leadDetailedReportPermission?.to}`}
                          >
                            {leadDetailedReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                    {leadConversationReportPermission && (
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            leadConversationReportPermission?.icon,
                            leadConversationReportPermission?.icon_size,
                            "#1d2c0778"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-lead-conversion-report fs--1 mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${leadConversationReportPermission?.to}`}
                          >
                            {leadConversationReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    )}
                  </Row>
                  {leadSourceReportPermission && (
                    <Row className="mt-3">
                      <Col md={6}>
                        <div className="d-flex align-items-center">
                          {generateIconComponent(
                            leadSourceReportPermission?.icon,
                            leadSourceReportPermission?.icon_size,
                            "#F7C600"
                          )}
                          <Link
                            className="custom-title-reports custom-title-link-animation-lead-source-summery-report fs--1 mt-1 ms-1"
                            style={{ textDecoration: "none", color: "#6F7275" }}
                            to={`/${leadSourceReportPermission?.to}`}
                          >
                            {leadSourceReportPermission?.name}
                          </Link>
                        </div>
                      </Col>
                    </Row>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ReportGrid;
