import { AuthWizardContext } from 'context/Context';
import { useContext } from 'react';

const useAxisproTranslate = () => {
  const { user } = useContext(AuthWizardContext);
  const Translate = word => user?.translations?.[word.toLowerCase()] ?? word;
  return Translate;
};

export default useAxisproTranslate;
