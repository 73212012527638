import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, OverlayTrigger, ProgressBar, Tooltip } from "react-bootstrap";
import {
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrash,
} from "react-icons/fa";
import { AiFillFileUnknown } from "react-icons/ai";

const FileUploadItem = ({
  fileItem,
  index,
  setFilesArray,
  onChange,
  limitFeature,
}) => {
  const [uploadPercentage] = useState(Math.floor(Math.random() * 100));
  const [uplaodCompleted] = useState(false);
  let fileExtension = fileItem.file.name.split(".").pop();

  const handleRemove = (e, index) => {
    e.preventDefault();
    setFilesArray((prev) => {
      let newArrayResult = prev.filter(
        (item, Itemindex) => item && index !== Itemindex
      );
      onChange(
        newArrayResult.reduce((initalArray, item) => {
          if (item.isValid) {
            initalArray.push(item.file);
          }
          return initalArray;
        }, [])
      );
      return newArrayResult;
    });
  };

  return (
    <div
      className={`mb-2 d-flex flex-row align-items-center p-2 ${
        !fileItem.isValid ? "border border-danger" : "border"
      }`}
      style={!fileItem.isValid ? { backgroundColor: "#fff8f8" } : null}
    >
      <div className="file-item-icon me-2">
        {fileExtension === "jpg" ||
        fileExtension === "jpeg" ||
        fileExtension === "png" ? (
          <FaFileImage color="#417ad1bd" size={38} />
        ) : fileExtension === "pdf" ? (
          <FaFilePdf color="#fb3939b8" size={38} />
        ) : fileExtension === "xlsx" || fileExtension === "xls" ? (
          <FaFileExcel color="#007f3eb5" size={38} />
        ) : fileExtension === "csv" ? (
          <FaFileCsv color="#48ad1ebd" size={38} />
        ) : fileExtension === "doc" ? (
          <FaFileWord color="#285293" size={38} />
        ) : (
          <AiFillFileUnknown size={38} />
        )}
      </div>
      <div className="file-item-details flex-fill">
        <p className="file-item-name mb-0 text-muted">
          {fileItem.file.name}
          {!limitFeature && fileItem.isValid ? (
            <span className="ms-1 small text-warning fw-bold">
              ({uploadPercentage}%)
            </span>
          ) : null}
          <br />
          <span className="small fw-bold text-primary">
            {fileItem.sizeInMB} MB
          </span>
          <br />
        </p>
        {!fileItem.isValid && fileItem.errors
          ? fileItem.errors.map((error, key) => (
              <p className="small text-danger mb-0" key={key}>
                {error}
              </p>
            ))
          : null}
        {!limitFeature && fileItem.isValid ? (
          <ProgressBar
            animated
            variant={uplaodCompleted ? "success" : "primary"}
            now={uploadPercentage}
            style={{ height: "7px" }}
          />
        ) : null}
      </div>
      <div className="file-item-actions ms-2">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip style={{ fontSize: "10px" }}>Remove Item</Tooltip>}
        >
          <Button
            variant="transperant"
            size="sm"
            className="py-0 px-1 text-center custom-transperent-common-button"
            onClick={(e) => handleRemove(e, index)}
          >
            <FaTrash color="#ff4d4d" />
          </Button>
        </OverlayTrigger>
      </div>
    </div>
  );
};

FileUploadItem.propTypes = {
  fileItem: PropTypes.object,
  index: PropTypes.number,
  setFilesArray: PropTypes.func,
  onChange: PropTypes.func,
  limitFeature: PropTypes.bool,
};
export default FileUploadItem;
