import React from "react";
import SelectUsers from "components/form/SelectUsers";
import { Form } from "react-bootstrap";

const LeadConversionReportForm = ({ filters, handleFilterChange }) => {
  return (
    <div>
      <Form.Group controlId="formBasicEmail" className="mb-3">
        <Form.Label>Owner</Form.Label>
        <SelectUsers
          name="owner_id"
          handleFieldChange={handleFilterChange}
          value={filters.owner_id_ref}
        />
      </Form.Group>
    </div>
  );
};

export default LeadConversionReportForm;
