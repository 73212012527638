import React from "react";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as echarts from "echarts/core";
import ReactEChartsCore from "echarts-for-react/lib/core";
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import { getColor } from "helpers/utils";
import SoftBadge from "components/common/SoftBadge";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";

function ProjectInfo({ projectAnalytics, loading }) {
  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    CanvasRenderer,
    LegendComponent,
  ]);

  const getOptions = (data) => ({
    series: [
      {
        type: "gauge",
        startAngle: 90,
        endAngle: -270,
        radius: "90%",
        pointer: {
          show: false,
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor("primary"),
          },
        },
        axisLine: {
          lineStyle: {
            width: 8,
            color: [[1, getColor("200")]],
          },
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        data: [
          {
            value: data,
            detail: {
              fontSize: "20px",
              offsetCenter: ["2%", "4%"],
            },
          },
        ],
        detail: {
          width: 50,
          height: 14,
          fontSize: 28,
          fontWeight: 500,
          fontFamily: "poppins",
          color: getColor("500"),
          formatter: "{value}%",
        },
      },
    ],
  });

  return (
    <Card className=" card-main " style={{ height: "246px" }}>
      <div
        className="w-100 text-center"
        style={{
          marginTop: "20px",
          fontFamily: "sans-serif",
          fontSize: "15px",
          fontWeight: "700",
          color: getColor("gray-800"),
        }}
      >
        Project Analytics
      </div>
      <Card.Body>
        {!loading ? (
          <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column justify-content-center align-items-center w-25">
              <div className="w-100 mb-2">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-2"
                    className="text-success me-1"
                  />
                  <span className="tex-muted me-1" style={{ fontSize: "12px" }}>
                    Start Date
                  </span>
                </div>
                <div className="ms-2">
                  <SoftBadge bg="info" pill>
                    {projectAnalytics?.formatted_project_start_date}
                  </SoftBadge>
                </div>
              </div>
              <div className="w-100 mb-2">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-2"
                    className="text-success me-1"
                  />
                  <span className="tex-muted me-1" style={{ fontSize: "12px" }}>
                    End Date
                  </span>
                </div>
                <div className="ms-2">
                  <SoftBadge
                    bg={
                      projectAnalytics?.is_passed_end_date &&
                      projectAnalytics?.project_status === "open"
                        ? "danger"
                        : "dark"
                    }
                    pill
                  >
                    {projectAnalytics?.formatted_project_end_date}
                  </SoftBadge>
                </div>
              </div>
              <div className="w-100">
                <div className="d-flex">
                  <FontAwesomeIcon
                    icon="check"
                    transform="shrink-2"
                    className="text-success me-1"
                  />
                  <div
                    className="tex-muted me-1  "
                    style={{ fontSize: "12px" }}
                  >
                    Status
                  </div>
                </div>
                <div className="ms-2 text-capitalize">
                  <SoftBadge
                    bg={
                      projectAnalytics?.project_status === "open"
                        ? "success"
                        : projectAnalytics?.project_status === "close"
                        ? "danger"
                        : "primary"
                    }
                    pill
                  >
                    {projectAnalytics?.project_status}
                  </SoftBadge>
                </div>
              </div>
            </div>
            <div className="w-75">
              <ReactEChartsCore
                echarts={echarts}
                option={getOptions(projectAnalytics?.task_percentage)}
                style={{ height: "10rem", width: "100%" }}
              />
            </div>
          </div>
        ) : (
          <DashboardTileLoader column={2} />
        )}
      </Card.Body>
    </Card>
  );
}

export default ProjectInfo;
