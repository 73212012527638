import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
const Error403 = () => {
  return (
    <Card className="text-center d-flex" style={{ minHeight: "100vh" }}>
      <Card.Body className="p-5 d-flex flex-column">
        <div className="my-auto">
          <div className="display-1 text-300 fs-error">403</div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
            Permission denied
          </p>
        </div>
        <div className="mt-auto">
          <hr />
          <p>
            You do not have permission to access this page, contact your system
            administrator.
          </p>
          <Link className="btn btn-primary btn-sm mt-3" to="/dashboard">
            <FontAwesomeIcon icon={faHome} className="me-2" />
            Take me home
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default Error403;
