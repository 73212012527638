import axios from "axios";
import SelectRole from "components/form/SelectRole";
import { invitationFormKeys } from "helpers/formKeys";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import { useState } from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { showToast } from "../Toast/toast";

const Invitation = ({ showInvitation, handleClose }) => {
  const [formData, setFormData] = useState(invitationFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);

  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);
    axios({
      method: "POST",
      url: `crm/invitation`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          handleClose();
          setFormData(invitationFormKeys);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <Modal show={showInvitation} onHide={handleClose}>
      <Modal.Header closeButton style={{ backgroundColor: "#EDF2F9" }}>
        <Modal.Title className="fs-0">Invite User</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="form.ControlInput1">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter Email"
              onChange={handleFieldChange}
              value={formData.email}
              isInvalid={!!formError.email}
              autoFocus
            />
            <Form.Control.Feedback type="invalid">
              {formError.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="form.ControlInput2">
            <Form.Label>Role</Form.Label>
            <SelectRole
              name="role_id"
              handleFieldChange={handleFieldChange}
              value={formData.role_id_ref}
              error={formError.role_id}
            />
            <Form.Control.Feedback type="invalid">
              {formError.role_id}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose} size="sm">
          Close
        </Button>
        <Button
          size="sm"
          className={`w-10 d-flex align-items-center justify-content-center`}
          type="button"
          variant={`success`}
          disabled={onSave}
          onClick={handleSubmit}
        >
          {onSave ? (
            <>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="white"
                className="me-1"
              />
              <span className="fs--1">{"Inviting..."}</span>
            </>
          ) : (
            <span>{"Invite"}</span>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Invitation;
