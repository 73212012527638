import { React, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import ReactSelectAddButton from "components/add-button/ReactSelectAddButton";
import BatchNumberPopupForm from "./popup-forms/BatchNumberPopupForm";

const SelectBatch = ({
  handleFieldChange,
  name,
  error,
  value,
  stock_id,
  keyName,
  style,
  placeholder,
  withAddButton,
  ...rest
}) => {
  const [showFormWindow, setShowFormWindow] = useState(false);
  const setItems = async (value) => {
    let response = await axios.get("inventory/batches-autocomplete", {
      params: { batch_number: value, stock_id: stock_id },
    });
    let data = response?.data ? response.data.data : [];
    return data.map((item) => ({
      label: item.batch_number_display,
      value: keyName ? item[keyName] : item.id,
    }));
  };

  return (
    <>
      <AsyncSelect
        key={JSON.stringify(stock_id)}
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable
        name={name ? name : "batch_id"}
        isSelected={true}
        isOptionSelected={value ? (item) => item.label === value.label : ""}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={error && "form-control ps-0 py-0 is-invalid"}
        components={
          withAddButton
            ? {
                Menu: (props) => (
                  <>
                    <ReactSelectAddButton
                      props={props}
                      setShowFormWindow={() => setShowFormWindow(true)}
                      title="Add Batch"
                    />
                  </>
                ),
              }
            : null
        }
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
        {...rest}
        menuPortalTarget={document.body}
      />

      {withAddButton ? (
        <BatchNumberPopupForm
          name={name ?? "batch_id"}
          labelName="batch_number"
          keyName={keyName ?? "id"}
          show={showFormWindow}
          onHide={() => setShowFormWindow(false)}
          setData={handleFieldChange}
        />
      ) : null}
    </>
  );
};

SelectBatch.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  stock_id: PropTypes.string,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  withAddButton: PropTypes.bool,
};

export default SelectBatch;
