import { useLocation as UseLocation } from "react-router-dom";
import getCustomComponent from "./getCustomComponent";
import { MODULES } from "constants/Constants";

const getInitializedFilters = (filterFields, setFilterFields, module) => {
  const location = UseLocation();
  const accessLocation = module ?? location.pathname?.split("/")[1];

  const handleFieldChange = (e, action) => {
    const newFormData = { ...filterFields };

    if (!action) {
      newFormData[e.target.name] = e.target.value;
    } else {
      const { name, action: actionType } = action;

      if (actionType === "select-option") {
        if (name === "status_filter") {
          newFormData[name] = e.map((item) => item.value);
        } else {
          newFormData[name] = e.value;
        }
        newFormData[name + "_ref"] = e;
      } else if (actionType === "clear") {
        newFormData[name] = name === "status_filter" ? [] : "";
        newFormData[name + "_ref"] =
          name === "status_filter" ? [] : { label: "", value: "" };
      } else if (actionType === "remove-value") {
        newFormData[name] = e.map((item) => item.value);
        newFormData[name + "_ref"] = e;
      }
    }

    setFilterFields(newFormData);
  };

  const filtersConfig = {
    [MODULES.EMPLOYEE]: [
      { label: "Name", name: "full_name", type: "text" },
      {
        label: "Designation",
        type: "custom-select",
        name: "designation_id",
        componentKey: "designation",
      },
      {
        label: "Department",
        name: "department_id",
        type: "custom-select",
        componentKey: "department",
      },
      { label: "Role", type: "custom-select", componentKey: "role_id" },
      {
        label: "Status",
        name: "status",
        type: "select",
        options: [
          { label: "All", value: "" },
          { label: "Active", value: "Active" },
          { label: "Inactive", value: "Inactive" },
        ],
      },
    ],
    [MODULES.LEAD]: [
      { label: "Title", name: "title", type: "text" },
      // {
      //   label: "Date Field",
      //   name: "date_range_for",
      //   type: "select",
      //   options: [{ value: "generated_on", label: "Generated On" }],
      // },
      {
        label: "Generated On",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Company", name: "company_name", type: "text" },
      {
        label: "Lead Owner",
        name: "lead_owner",
        type: "custom-select",
        componentKey: "users",
      },
      {
        label: "Converted",
        name: "is_converted",
        type: "select",
        options: [
          { label: "All", value: "" },
          { label: "Converted", value: 1 },
          { label: "Not Converted", value: 0 },
        ],
      },
      {
        label: "Lead Source",
        type: "custom-select",
        componentKey: "lead_source",
      },
      {
        label: "Priority",
        type: "custom-select",
        componentKey: "lead_priority",
      },
      { label: "Status", type: "custom-select", componentKey: "lead_status" },
    ],
    [MODULES.CAMPAIGN]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Name", name: "name", type: "text" },
      {
        label: "Type",
        type: "custom-select",
        name: "type",
        componentKey: "camapign_type",
      },
      { label: "Sponsor", name: "sponsor", type: "text" },
      {
        label: "Product",
        name: "product_id",
        type: "custom-select",
        componentKey: "product",
      },
      {
        label: "Assigned To",
        name: "assigned_to",
        type: "custom-select",
        componentKey: "users",
      },
      { label: "Location", name: "location", type: "text" },
      {
        label: "Status",
        name: "status",
        type: "custom-select",
        componentKey: "campaign_status",
      },
    ],
    [MODULES.OPPORTUNITY]: [
      {
        label: "Date Field",
        name: "date_range_for",
        type: "select",
        options: [
          { value: "", label: "Select" },
          { value: "opening_date", label: "Opening Date" },
          { value: "closing_date", label: "Closing Date" },
        ],
      },
      {
        label: "Date Range",
        type: "date-range",
        options: [
          { value: "start_date", label: "Start Date" },
          { value: "end_date", label: "End Date" },
        ],
      },
      { label: "Title", name: "title", type: "text" },
      {
        label: "Type",
        name: "type",
        type: "custom-select",
        componentKey: "opportunity_type",
      },
      {
        label: "Account",
        name: "customer_id",
        type: "custom-select",
        componentKey: "account",
      },
      {
        label: "Stage",
        name: "stage",
        type: "custom-select",
        componentKey: "opportunity_stage",
      },
      {
        label: "Lead",
        name: "lead_id",
        type: "custom-select",
        componentKey: "lead",
      },
      {
        label: "Campaign",
        name: "campaign_id",
        type: "custom-select",
        componentKey: "campaign",
      },
    ],
  };

  const choosedFilter = (filtersConfig[accessLocation] || []).map((filter) => {
    const { componentKey, columns, name, ...rest } = filter;
    return {
      ...rest,
      name: name ?? componentKey,
      value: filterFields?.[filter.name],
      onChange: (e) => handleFieldChange(e),
      component: componentKey
        ? getCustomComponent(
            componentKey,
            name,
            filterFields?.[`${name ?? componentKey}_ref`],
            handleFieldChange
          )
        : undefined,
    };
  });

  return choosedFilter;
};

export default getInitializedFilters;
