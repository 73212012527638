import { Card, Col, Row } from "react-bootstrap";
import ClientProfileSection from "../Component/ClientProfileSection";
import ClientTabSection from "../Component/ClientTabSection";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { apiCall } from "helpers/apiCalls";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";

const ClientOverView = () => {
  const [accountOverview, setAccountOverview] = useState({});
  const { client_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const getData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/crm-account-overview",
      params: { customer_id: client_id },
    });
    setAccountOverview(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id]);

  return (
    <>
      {Object.keys(accountOverview).length > 0 && !isLoading ? (
        <Row className="g-3 m-0 mb-3">
          <Col md={3}>
            <Row className="g-3">
              <ClientProfileSection
                client={accountOverview?.account}
                isLoading={isLoading}
              />
            </Row>
          </Col>
          <Col md={9} className="d-flex flex-column ">
            <ClientTabSection tabData={accountOverview} isLoading={isLoading} />
          </Col>
        </Row>
      ) : isLoading ? (
        <Card
          className="mt-3 mb-3 d-flex align-items-center justify-content-center"
          style={{ minHeight: "89vh" }}
        >
          <LoadingCommon loadingText="Loading Overview" />
        </Card>
      ) : (
        "No data found"
      )}
    </>
  );
};
export default ClientOverView;
