import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import { RiMailSendFill } from "react-icons/ri";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonInvitationFilter from "./SupportingComponents/CommonInvitationFilter";

const Invitation = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [invitation, setInvitation] = useState({});
  const [filterVisibility, setFilterVisibility] = useState(true);
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { to_email, date_range_for, from_date, to_date, role_id } = allQueryParams;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/invitation/" + id,
        title: "Delete Invitation",
        message: "Are you sure you want to delete this invitation?",
        onSuccess: () => {
          const dataWithoutDeletedItem = invitation.data.filter(
            (invitation) => invitation.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/invitation?" +
              createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setInvitation((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "to_email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { to_email } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{to_email}</h5>;
      },
    },
    {
      accessor: "role",
      Header: "ROLE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { role } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{role?.name}</h5>;
      },
    },
    {
      accessor: "expiring_time_formatted",
      Header: "EXPIRY TIME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { expiring_time_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{expiring_time_formatted}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{status}</h5>;
      },
    },

    {
      accessor: "none",
      Header: "",
      headerProps: { className: "py-3 custom-table-head-th-action" },
      disableSortBy: true,
      cellProps: {
        className: "custom-table-body-td-action",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            <EditButton
              route={`/invitation/edit/${id}?${createSearchParams({
                ...allQueryParams,
              })}`}
            />
            <DeleteButton onClick={() => handleDelete(id)} />
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/invitation",
      params: {
        page: page,
        to_email: to_email,
        date_range_for: date_range_for,
        from_date: from_date,
        to_date: to_date,
        role_id: role_id,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setInvitation(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    to_email,
    date_range_for,
    from_date,
    to_date,
    role_id
  ]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between p-3 ${!filterVisibility ? "border-bottom" : ""
              }`}
          >
            <div className="d-flex  align-items-center">
              <RiMailSendFill size={22} className="me-2 text-dark" />
              <span className="fs-1 text-uppercase">Invitations List</span>
            </div>
            <div className="d-flex gap-2">
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonInvitationFilter />
          </div>
        </Card.Header>
        {!isLoading && invitation?.data?.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={invitation.data} sortable>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {invitation?.links && (
                <AppPagination
                  data={invitation}
                  url="/invitation"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Invitation" />
            ) : (
              <h5 className="text-center text-secondary">
                Invitation not found! Let's add it &#128578;
              </h5>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default Invitation;