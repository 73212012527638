import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import FilterButton from "components/filter/FilterButton";
import AddButton from "components/add-button/AddButton";
import { IoIosPeople } from "react-icons/io";
import { teamFilters } from "../../common/helpers/teamsCommonFilters";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import CommonTeamsFilter from "../SupportingComponets/CommonTeamsFilter";
import ShowMore from "components/common/ShowMore";

const TeamTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [teams, setTeams] = useState({});
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [filters] = useState(teamFilters);
  const [filterVisibility, setFilterVisibility] = useState(true);
  const { name, team_lead_id } = allQueryParams;

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/team/" + data,
        title: "Delete team item",
        message: "Are you sure you want to delete this team?",
        onSuccess: () => {
          const dataWithoutDeletedItem = teams.data.filter(
            (account) => account.id !== data
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/crm/team?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setTeams((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { name, id } = rowData.row.original;
        return (
          <Link to={`info/${id}`}>
            <h5 className="mb-0 fs--1">{name}</h5>
          </Link>
        );
      },
    },
    {
      accessor: "description",
      Header: "DESCRIPTION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { description, id } = rowData.row.original;
        return <div className="mb-0 fs--1">
          <ShowMore
            content={description ? description : "N/A"}
            contentLimit={60}
            uniqueKey={id}
          />
        </div>;
      },
    },
    {
      accessor: "lead_name",
      Header: "LEAD",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { first_name, last_name } = rowData.row.original.team_lead;
        return <h5 className="mb-0 fs--1">{first_name + " " + last_name}</h5>;
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: { className: "py-3 custom-table-head-th-action" },
      disableSortBy: true,
      cellProps: {
        className: "custom-table-body-td-action",
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            <EditButton
              route={`/teams/edit/${id}?${createSearchParams({
                ...allQueryParams,
              })}`}
            />
            <DeleteButton onClick={() => handleDelete(id)} />
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/team",
      params: {
        page: page,
        name: name,
        team_lead_id: team_lead_id,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setTeams(data);
    setIsLoading(false);
  };
  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, name, team_lead_id]);

  return (
    <>
      <Outlet context={[getData]} />

      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between align-items-center p-3 ${
              !filterVisibility ? "border-bottom" : ""
            }`}
          >
            <div className="d-flex align-items-center gap-2">
              <IoIosPeople size={30} className="text-dark" />
              <span className="fs-1 text-uppercase">Teams List</span>
            </div>
            <div className="d-flex gap-2">
              <FilterButton
                className={"pt-2 pb-2"}
                filters={filters}
                url="teams"
              />
              <AddButton
                to={`/teams/add?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonTeamsFilter />
          </div>
        </Card.Header>
        {!isLoading && teams && teams.data && teams.data.length > 0 ? (
          <AdvanceTableWrapper columns={columns} data={teams.data} sortable>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {teams && teams.links && (
                <AppPagination
                  data={teams}
                  url="/teams"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Teams" />
            ) : (
              <h5 className="text-center text-secondary">
                Team not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                <Button
                  variant="success"
                  className="m-1"
                  size="sm"
                  as={Link}
                  to="/teams/add"
                >
                  Add Team
                </Button>
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default TeamTableView;
