const { default: SoftBadge } = require("components/common/SoftBadge");
const { Row, ProgressBar, Col } = require("react-bootstrap");
const { MdAttachMoney, MdOutlineDateRange, MdOutlineAccessTime, MdOutlineCalculate } = require("react-icons/md");


const ColumnItem = ({ title, value, show = true, icon }) => {
  return (
    show && (
      <Col md={3} sm={4} xs={6}>
        <div className="d-flex">
          <div className="me-1 text-secondary">
            {icon}
          </div>
          <div >
            <div className="text-dark">{title}</div>
            <div className="text-dark fw-bold">{value}</div>
          </div>
        </div>
      </Col>
    )
  );
};

const GeneralInfo = ({ data }) => {
  const columnData = {
    opportunity: [
      {
        icon: <MdAttachMoney size={16} />,
        title: "Expected Amount:",
        value: data?.expected_amount,
        show: data?.expected_amount ? true : false,
      },
      {
        icon: <MdAttachMoney size={16} />,
        title: "Actual Amount:",
        value: data?.amount,
        show: data?.amount ? true : false,
      },
      {
        icon: <MdOutlineAccessTime size={16} />,
        title: "Time Frame:",
        value: data?.time_frame,
        show: data?.time_frame ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Created Date:",
        value: data?.opportunity_created_at_formatted,
        show: data?.opportunity_created_at_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Opening Date:",
        value: data?.opening_date_formatted,
        show: data?.opening_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Expected Closing Date:",
        value: data?.expected_closing_date_formatted,
        show: data?.expected_closing_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Closing Date:",
        value: data?.closing_date_formatted,
        show: data?.closing_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Proposal Created Date:",
        value: data?.proposal_created_date_formatted,
        show: data?.proposal_created_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Proposal Last Revision Date:",
        value: data?.proposal_last_revision_date_formatted,
        show: data?.proposal_last_revision_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineDateRange size={16} />,
        title: "Invoicing Probability Date:",
        value: data?.invoicing_probability_date_formatted,
        show: data?.invoicing_probability_date_formatted ? true : false,
      },
      {
        icon: <MdOutlineCalculate size={16} />,
        title: "Estimator:",
        value: data?.estimator_name,
        show: data?.estimator_name ? true : false,
      },
      {
        title: "Lost Reason:",
        value: data?.lost_reason,
        show: data?.lost_reason ? true : false,
      },
    ],
    campaign: [
      {
        title: "Name:",
        value: data?.campaign_name,
      },
      {
        title: "Status:",
        value: data?.campaign_status,
        badge: true,
      },
      {
        title: "Type:",
        value: data?.campaign_type,
      },
      {
        title: "Revenue:",
        value: data?.campaign_revenue,
      },
      {
        title: "Start Date:",
        value: data?.campaign_start_date_formatted,
      },
      {
        title: "End Date:",
        value: data?.campaign_end_date_formatted,
      },
      {
        title: "Sponsor:",
        value: data?.campaign_sponsor,
      },
    ],
  };

  return (
    <Row className="h-100  border-top-0 border-bottom-0">
      <Col
        md={12}
        className="text-muted p-3 p-3 pt-2 pb-2 rounded-0"
        style={{ backgroundColor: "#d2d4e9" }}
      >
        OPPORTUNITY INFORMATION
      </Col>
      <Col className=" text-muted p-3">
        <div className="text-end d-flex align-items-center justify-content-end">
          {data?.type && (
            <>
              <div className="text-dark me-2">Type: </div>
              <SoftBadge bg="warning" className="text-uppercase p-2">
                {data?.type}
              </SoftBadge>
            </>
          )}
        </div>
        <div className="d-flex">
          <div className="w-100">
            <div className="text-dark">Probability</div>
            <div className="text-dark fw-bold mt-2 ">
              <ProgressBar
                now={data?.probability_percentage}
                variant="success"
                label={`${data?.probability_percentage}%`}
              />
            </div>
            <div className="mt-0 pt-0 ">
              There is a {data?.probability_percentage}% chance of occurrence
              within a 100% probability
            </div>
            {data?.description && (
              <>
                <div className="text-dark  mt-3">Description:</div>
                <div className="text-dark fw-bold">{data?.description}</div>
              </>
            )}
            <Row className="mt-2 gy-3">
              {columnData.opportunity.map((item, index) => (
                <ColumnItem key={index} {...item} show={item.show} />
              ))}
            </Row>
          </div>
        </div>
      </Col>
      <Col
        md={12}
        className="mt-2 text-muted p-3 pt-2 pb-2 rounded-0"
        style={{ backgroundColor: "#d2d4e9" }}
      >
        CAMPAIGN INFORMATION
      </Col>
      <Col className="text-muted pb-3">
        {data?.campaign_id ? (
          <Row className="m-3 gy-3">
            {columnData.campaign.map((item, index) => (
              <ColumnItem key={index} {...item} />
            ))}
          </Row>
        ) : (
          <div
            className="d-flex mt-5 mb-5 align-items-center w-100 justify-content-center"
            style={{ height: "17vh" }}
          >
            No Records !
          </div>
        )}
      </Col>
    </Row>
  );
};
export default GeneralInfo;
