import { React } from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";

import useAxisproTranslate from "hooks/useAxisproTranslate";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";

function MemoInputField({
  value,
  formError,
  error,
  title,
  name,
  handleFieldChange,
  row,
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group className="mb-3" controlId="memo">
      <Form.Label>{Translate(title)}</Form.Label>
      <div className="d-flex flex-row align-items-center">
        <div className="flex-fill">
          <Form.Control
            type="text"
            as="textarea"
            name={name}
            rows={row ? row : 10}
            onChange={handleFieldChange}
            value={value}
          />
        </div>
        <FormErrorPopover
          id={name}
          totalErrorCount={Object.keys(formError).length}
          errorMessage={error}
        />
      </div>
    </Form.Group>
  );
}

MemoInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  handleFieldChange: PropTypes.func,
  title: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  row: PropTypes.any,
};

export default MemoInputField;
