import { React } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";

const SelectTicketOrigin = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
  isDisabled,
}) => {
  const setItems = async (value) => {
    let response = await axios.get("crm/ticket-origin", {
      params: { name: value },
    });
    let data = response && response.data ? response.data.data : [];

    return data.map((item) => ({
      label: item,
      value: item,
    }));
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable
        isDisabled={isDisabled}
        name={name ? name : "project_status"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error
            ? "form-control ps-0 py-0 is-invalid text-capitalize"
            : "text-capitalize"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
      />
    </>
  );
};

SelectTicketOrigin.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectTicketOrigin;
