import React from "react";
import { Col, Row } from "react-bootstrap";
import QuotationsList from "./SupportingComponents/QuotationsList";
import QuotationInfo from "./SupportingComponents/QuotationInfo";

const QuotationOverview = () => {
  return (
    <div className="mt-3 mb-3">
      <Row className="">
        <Col lg={3} md={4} className="">
          <QuotationsList />
        </Col>
        <Col lg={9} md={8} className="flex-fill border p-0">
          <QuotationInfo />
        </Col>
      </Row>
    </div>
  );
};

export default QuotationOverview;
