import React from "react";
import {
  Card,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";

import { FaUsers } from "react-icons/fa";

import ProfileImage from "assets/img/Avathar/profile.png";
import Comments from "module/common/ViewPages/Common/Comments/Comments";

function CommentTab({ project, membersList }) {
  return (
    <Row className="gx-2">
      <Col className="mt-3" md={8}>
        <Card className="h-100" style={{ minHeight: "80.7vh" }}>
          <Card.Body>
            <Comments comment_type="project" id={project.id} />
          </Card.Body>
        </Card>
      </Col>
      <Col className="mt-3" md={4}>
        <Row className="position-sticky top-0 gx-1">
          <Card className="h-100">
            <Card.Header className="border-bottom fw-bolder">
              <FaUsers size={22} className="me-1" /> Participants
            </Card.Header>
            <Card.Body className="p-0">
              <ListGroup>
                {(membersList ?? []).length > 0 ? (
                  membersList.map((participant, key) => (
                    <ListGroupItem className="rounded-0 border-0" key={key}>
                      <div className="row gap-2">
                        <div className="col-md-1">
                          <Image
                            className="custom-image-crm"
                            src={
                              participant.image
                                ? participant.image
                                : ProfileImage
                            }
                            roundedCircle
                            alt="?"
                            height={40}
                            width={40}
                          />
                        </div>
                        <div className="col pt-1">
                          <span className="ms-2 fw-bolder">
                            {participant.first_name +
                              " " +
                              participant.last_name}
                          </span>
                          <span className="ms-2 d-flex align-items-center fs--1">
                            {participant?.role ?? ""}
                          </span>
                        </div>
                      </div>
                    </ListGroupItem>
                  ))
                ) : (
                  <ListGroupItem className="pt-4 pb-4 rounded-0 border-0">
                    No Participants Yet
                  </ListGroupItem>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Row>
      </Col>
    </Row>
  );
}

export default CommentTab;
