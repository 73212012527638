import axios from "axios";
import { apiCall } from "helpers/apiCalls";
import useAxisproPermission from "hooks/useAxisproPermission";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { FaCircle } from "react-icons/fa";
import { TbStatusChange } from "react-icons/tb";

const TaskStatusButton = ({
  taskId,
  status,
  setStatusLoading,
  setResponseData,
  refreshFunction,
}) => {
  const [statusArray, setStatusArray] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [updateTaskStatusPermission] = ["update-task-status"].map(
    (permission) => axisProPermission(permission)
  );

  useEffect(() => {
    const getStatusData = async () => {
      setLoadingDropdown(true);
      const statusData = await apiCall({
        url: `crm/task-status`,
      });
      setStatusArray(statusData);
      setLoadingDropdown(false);
    };
    getStatusData();
  }, [taskId]);

  const handleStatusUpdate = (e, status) => {
    e.preventDefault();
    setStatusLoading(true);
    if (status) {
      axios({
        method: "post",
        url: `crm/task-status/update`,
        params: { task_id: taskId, status: status },
      })
        .then((response) => {
          if (response.data.success === true) {
            setResponseData(response.data.data);
            setStatusLoading(false);
            refreshFunction();
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
            setStatusLoading(false);
          }
        })
        .catch((error) => {
          if (error?.response?.data && error.response.data?.message) {
            const validation_error =
              error.response.data &&
              error.response.data.data &&
              error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            if ("collaborator_working" in validation_error) {
              showToast(validation_error.collaborator_working[0], "error");
            } else if ("task_id" in validation_error) {
              showToast(validation_error.task_id[0], "error");
            } else if ("status" in validation_error) {
              showToast(validation_error.status[0], "error");
            } else {
              showToast(
                "Something went wrong, please refresh the page and try again.",
                "error"
              );
            }
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
          setStatusLoading(false);
        });
    }
  };

  return (
    <>
      {updateTaskStatusPermission ? (
        <DropdownButton
          id="dropdown-basic-button"
          className="custom-dropdown-button shadow border p-1"
          variant="transparent"
          title={
            <span className="text-capitalize">
              {getTaskStatusWithProps(status, "icon", 20)}
              <span className="ms-1">{status.replace("_", " ")}</span>
            </span>
          }
        >
          {loadingDropdown ? (
            <ListLoading style={{ height: "170px", overflow: "hidden" }} />
          ) : (
            statusArray
              .filter((item) => item !== status)
              .map((status, key) => (
                <Dropdown.Item
                  key={key}
                  onClick={(e) => {
                    handleStatusUpdate(e, status);
                  }}
                  className="text-capitalize"
                >
                  <FaCircle
                    className="me-1"
                    color={getTaskStatusWithProps(status)}
                    size={10}
                  />
                  {status.replace("_", " ")}
                </Dropdown.Item>
              ))
          )}
        </DropdownButton>
      ) : (
        <Button
          size="lg"
          variant="transparent"
          className="custom-transperent-common-button shadow"
        >
          <span className="text-capitalize">
            <TbStatusChange color={getTaskStatusWithProps(status)} size={20} />
            <span className="ms-1">{status.replace("_", " ")}</span>
          </span>
        </Button>
      )}
    </>
  );
};

export default TaskStatusButton;
