import { Button, Table } from "react-bootstrap";
import "./TicketTable.scss";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import Image from "react-bootstrap/Image";
import { FaCircle } from "react-icons/fa";
import {
  Link,
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CustomerDetails from "components/customer-details/CustomerDetails";
import { useContext, useState } from "react";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { AuthWizardContext } from "context/Context";
import { BiShuffle } from "react-icons/bi";
import ShowMore from "components/common/ShowMore";

const TicketTable = ({ data, handleDelete, renderFrom }) => {
  const [queryParams] = useSearchParams();
  let user = useContext(AuthWizardContext);
  let loggedAsCustomer = user?.user?.customer_id ? true : false;
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { itemInfoId } = useParams();
  const activeTab = queryParams.get("tab");
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [customerData, setCustomerData] = useState({});
  const axisProPermission = useAxisproPermission();
  const [updatePermission, deletePermission, showPermission, showCustomer] = [
    "update-ticket",
    "delete-ticket",
    "show-ticket",
    "show-customer",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;

  const handleCustomerDetailsArea = (name, id) => {
    setCustomerData({ label: name, value: id });
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  return (
    <>
      {data &&
      Object.keys(data).length > 0 &&
      Object.keys(data.data).length > 0 ? (
        <>
          <Table hover size="sm" className="ticket-custom-table mt-1">
            <thead className="ticket-custom-table-head">
              <tr className="ticket-custom-table-head-tr">
                <th className="ticket-custom-table-head-th-status p-0" />
                <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                  Ticket
                </th>
                {renderFrom ? null : (
                  <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                    Project
                  </th>
                )}
                {renderFrom ? null : (
                  <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                    Client
                  </th>
                )}
                <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                  Type
                </th>
                {loggedAsCustomer ? null : (
                  <>
                    <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                      Origin
                    </th>
                    <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold">
                      Priority
                    </th>
                    <th className="ticket-custom-table-head-th ps-2 fs--1 text-uppercase fw-bold">
                      Assignee
                    </th>
                  </>
                )}
                <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold text-center">
                  {loggedAsCustomer ? "Created Date" : "Start Date"}
                </th>
                {loggedAsCustomer ? null : (
                  <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold text-center">
                    End Date
                  </th>
                )}
                <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold text-center">
                  Status
                </th>
                {actionsPermission && (
                  <th className="ticket-custom-table-head-th fs--1 text-uppercase fw-bold text-center">
                    Actions
                  </th>
                )}
              </tr>
            </thead>
            <tbody className="ticket-custom-table-body">
              {data.data.map((ticket, key) => (
                <tr
                  key={key}
                  className="ticket-custom-table-body-tr"
                  style={{
                    backgroundColor:
                      ticket.priority === "high" ? "#fff2f2" : "",
                  }}
                >
                  <td
                    className="ticket-custom-table-body-td-status p-0"
                    style={{
                      backgroundColor:
                        ticket.priority === "high" ? "rgb(255 114 114)" : "",
                    }}
                  />
                  <td
                    className="ticket-custom-table-body-td"
                    style={{ width: "25%" }}
                  >
                    <Button
                      variant="transparent"
                      className="custom-transperent-common-button p-0"
                      size="sm"
                      as={Link}
                      to={
                        renderFrom === "project"
                          ? `/project/info/${itemInfoId}/ticket/info/${
                              ticket.id
                            }?${createSearchParams({
                              ...allQueryParams,
                            })}`
                          : `info/${ticket.id}?${createSearchParams({
                              ...allQueryParams,
                            })}`
                      }
                      disabled={!showPermission}
                    >
                      <span className="d-block left-align-custom-span fw-bold text-start">
                        {ticket.title}
                      </span>
                    </Button>
                    <ShowMore
                      content={ticket?.description}
                      contentLimit={30}
                      uniqueKey={ticket?.id}
                      contentRoute={
                        renderFrom === "project"
                          ? `/project/info/${itemInfoId}/ticket/info/${
                              ticket?.id
                            }?${createSearchParams({
                              ...allQueryParams,
                            })}`
                          : `info/${ticket?.id}?${createSearchParams({
                              ...allQueryParams,
                            })}`
                      }
                    />
                  </td>
                  {renderFrom ? null : (
                    <td className="ticket-custom-table-body-td">
                      {ticket?.project ?? "-"}
                    </td>
                  )}
                  {renderFrom ? null : (
                    <td className="ticket-custom-table-body-td ps-0">
                      <div
                        className={`d-flex align-items-center ${
                          showCustomer ? "custom-clickable-link-crm " : ""
                        }`}
                        onClick={() => {
                          showCustomer &&
                            handleCustomerDetailsArea(
                              ticket?.customer_name ?? "",
                              ticket?.customer_id ?? ""
                            );
                        }}
                      >
                        <Image
                          className="custom-image-crm"
                          src={
                            ticket?.customer_image
                              ? ticket?.customer_image
                              : ProfileImage
                          }
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"35vh"}
                          width={"35vh"}
                        />

                        <p className="ps-1 m-0">
                          {ticket?.customer_name ?? "-"}
                        </p>
                      </div>
                    </td>
                  )}
                  <td className="ticket-custom-table-body-td">
                    {ticket?.ticket_type ? ticket.ticket_type : "N/A"}
                  </td>
                  {loggedAsCustomer ? null : (
                    <>
                      <td className="ticket-custom-table-body-td text-capitalize">
                        {ticket.ticket_origin ? ticket.ticket_origin : "N/A"}
                      </td>
                      <td className="ticket-custom-table-body-td">
                        {ticket.priority ? (
                          <>
                            <FaCircle
                              color={
                                ticket.priority === "low"
                                  ? "#77cd8e"
                                  : ticket.priority === "medium"
                                  ? "#fba86b"
                                  : "#fd8282"
                              }
                              size={8}
                              className="me-1"
                            />
                            {ticket.priority}
                          </>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td className="ticket-custom-table-body-td ps-0">
                        {ticket?.assigned_to ? (
                          <Link
                            key={key}
                            to={`/tickets/list/profile/${
                              ticket?.assigned_to
                            }?${createSearchParams({
                              ...allQueryParams,
                            })}`}
                            className="custom-link-style-crm"
                          >
                            <div className="d-flex">
                              <span className="d-inline">
                                <Image
                                  className="custom-image-crm"
                                  src={
                                    ticket?.assignee_image
                                      ? ticket?.assignee_image
                                      : ProfileImage
                                  }
                                  roundedCircle
                                  alt="?"
                                  loading="lazy"
                                  height={"35vh"}
                                  width={"35vh"}
                                />
                              </span>
                              <span className="d-inline p-0 ms-2 d-flex align-items-center">
                                <p className="m-0">{ticket?.assignee}</p>
                              </span>
                            </div>
                          </Link>
                        ) : (
                          <div className="ms-2"> - </div>
                        )}
                      </td>
                    </>
                  )}
                  <td className="ticket-custom-table-body-td text-center">
                    {ticket?.ticket_date_formated}
                  </td>
                  {loggedAsCustomer ? null : (
                    <td className="ticket-custom-table-body-td text-center">
                      {ticket?.end_date_formated
                        ? ticket?.end_date_formated
                        : " - "}
                    </td>
                  )}
                  <td className="ticket-custom-table-body-td text-center text-capitalize">
                    {ticket.status === "awaiting_business_input"
                      ? "Await"
                      : ticket.status === "with_client_review"
                      ? "Review"
                      : ticket.status}
                  </td>
                  {actionsPermission && (
                    <td className="ticket-custom-table-body-td">
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        {!loggedAsCustomer && (
                          <Button
                            variant="transparent"
                            size="sm"
                            as={Link}
                            to={`/tickets/convert/${ticket.id}`}
                            title="Convert"
                            className="custom-button-action p-0"
                          >
                            <BiShuffle
                              color="#404A57"
                              className="custom-button-convert-icon m-1"
                              size={17}
                            />
                          </Button>
                        )}

                        {updatePermission && (
                          <EditButton
                            route={
                              renderFrom === "project"
                                ? `/project/info/${itemInfoId}/ticket/edit/${ticket.id}?tab=tickets&status=all`
                                : `edit/${ticket.id}?${createSearchParams({
                                    ...allQueryParams,
                                  })}`
                            }
                          />
                        )}
                        {deletePermission && (
                          <DeleteButton
                            onClick={() => handleDelete(ticket.id)}
                          />
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          <CustomerDetails
            show={showCustomerDetailsArea}
            onHide={handleCustomerDetailsArea}
            value={customerData}
            showCustomerDetailsArea={showCustomerDetailsArea}
          />
        </>
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "78vh" }}
        >
          {loggedAsCustomer ? (
            <h5 className="text-center text-secondary">
              <p>Tickets not found! Let's add it &#128578;</p>
            </h5>
          ) : (
            <h5 className="text-center text-secondary">
              <p>
                {activeTab !== "all" &&
                  (activeTab === "awaiting_business_input"
                    ? "Await "
                    : activeTab === "with_client_review"
                    ? "Review "
                    : activeTab + " ")}
                Tickets not found! Let's add it &#128578;
              </p>
            </h5>
          )}
        </div>
      )}
    </>
  );
};

export default TicketTable;
