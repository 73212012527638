import axios from "axios";
import { apiCall } from "helpers/apiCalls";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import { employeeFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import useAxisproPermission from "hooks/useAxisproPermission";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  ListGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import { AiFillCloseCircle, AiOutlineFieldTime } from "react-icons/ai";
import {
  BsBuildingFill,
  BsDatabaseFillExclamation,
  BsFillBuildingsFill,
} from "react-icons/bs";
import { FaUserCircle, FaUserGraduate } from "react-icons/fa";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { MdOutlineLockReset } from "react-icons/md";
import { SiCircleci } from "react-icons/si";
import { TbPassword } from "react-icons/tb";
import { useParams } from "react-router-dom";

const GeneralInformation = ({ userId, data }) => {
  const [openForm, setOpenForm] = useState(false);
  const [formData, setFormData] = useState(employeeFormKeys);
  const [formError, setFormError] = useState({});
  const { empId } = useParams();
  const [onSave, setOnSave] = useState(false);
  const defaultFilter = `{"column":"status","type":"string","operator":"=","value":"in_progress"}`;
  const [inProgressTasks, setInProgressTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();

  useEffect(() => {
    axisProPermission("list-task") && getTaskData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const getTaskData = async (page = 1) => {
    setLoading(true);
    try {
      const currentTasksData = await apiCall({
        url: `crm/task`,
        params: {
          page: page,
          requested_from: "employee",
          employee_id: userId,
          filters: removeRefData(
            createFilterArray(`${defaultFilter}`),
            "array"
          ),
        },
      });
      setInProgressTasks(currentTasksData?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    axios({
      method: "put",
      url: `crm/employee/${empId}`,
      data: formData,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          setFormData({
            ...formData,
            password: "",
            c_password: "",
          });
          setOpenForm(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error?.response?.data && error?.response?.data?.message) {
          const validation_error =
            error?.response?.data &&
            error.response.data?.data &&
            error.response.data.data?.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });

          if ("time_zone" in validation_error) {
            showToast(validation_error.time_zone[0], "error");
          } else {
            showToast(error.response.data.message, "error");
          }
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    setFormData({
      ...formData,
      first_name: data.first_name,
      middle_name: data.middle_name,
      last_name: data.last_name,
      dob: data.dob,
      username: data.username,
      designation_name: data.designation_name,
      designation_id: data.designation_id,
      designation_id_ref: {
        label: data.designation_name,
        value: data.designation_id,
      },
      department_name: data.department_name,
      department_id: data.department_id,
      department_id_ref: {
        label: data.department_name,
        value: data.department_id,
      },
      phone: data.phone,
      office_number: data.office_number,
      company_name: data.company_name,
      company_id: data.company_id,
      company_id_ref: {
        label: data.company_name,
        value: data.company_id,
      },
      fax: data.fax,
      email: data.email,
      address: data.address,
      working_hrs: data.working_hrs,
      role_id: data?.roles?.length > 0 ? data.roles[0].id : "",
      role_id_ref: {
        label: data?.roles?.length > 0 ? data.roles[0].name : "",
        value: data?.roles?.length > 0 ? data.roles[0].id : "",
      },
      time_zone: data.timezone,
      time_zone_ref: {
        label: data.timezone,
        value: data.timezone,
      },
      status: data.status,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleFieldChange = (e, action) => {
    let newFormData;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    }
    setFormData(newFormData);
  };

  return (
    <div
      className="mt-2 d-flex flex-column gap-4"
      style={{ minHeight: "78vh" }}
    >
      <div className="organizational-data">
        <Row className="gx-2 border-top">
          <Col lg={6} md={12} sm={12}>
            <span className="custom-postioning-header-employee-overview fs--1 fw-bold">
              Organizational Information
            </span>
            <div className="mt-2">
              <Row className="gx-2">
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <BsFillBuildingsFill size={33} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Comapny
                        </span>
                        <span className="custom-text-muted">
                          {data.company_name}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <BsBuildingFill size={33} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Department
                        </span>
                        <span className="custom-text-muted">
                          {data.department_name}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="mt-2">
              <Row className="gx-2 d-flex">
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <FaUserGraduate size={34} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Designation
                        </span>
                        <span className="custom-text-muted">
                          {data.designation_name}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <AiOutlineFieldTime size={35} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Working Hour
                        </span>
                        <span className="custom-text-muted">
                          {data.working_hrs}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      <div className="organizational-data">
        <Row className="gx-2 border-top">
          <Col lg={6} md={12} sm={12}>
            <span className="custom-postioning-header-employee-overview fs--1 fw-bold">
              Account Information
            </span>
            <div className="mt-2">
              <Row className="gx-2">
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <FaUserCircle size={35} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Username
                        </span>
                        <span className="custom-text-muted">
                          {data.username}
                        </span>
                      </div>
                    </div>
                  </Card>
                </Col>
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <SiCircleci size={33} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Status
                        </span>
                        <span className="custom-text-muted">{data.status}</span>
                      </div>
                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <div className="mt-2">
              <Row className="gx-2 d-flex">
                <Col lg={6} md={12} sm={12}>
                  <Card body className="mt-3 mb-3">
                    <div className="d-flex flex-row">
                      <span>
                        <MdOutlineLockReset size={34} />
                      </span>
                      <div className="d-flex flex-column ms-2">
                        <span className="custom-text-muted fw-bold">
                          Change Password
                        </span>
                        <span className="custom-text-muted">
                          <TbPassword size={22} />
                          <TbPassword size={22} />
                          <TbPassword size={22} />
                        </span>
                      </div>

                      <span className="ms-auto">
                        <Button
                          size="sm"
                          variant="transperent"
                          className="custom-transperent-common-button p-0"
                          onClick={() => {
                            setOpenForm(!openForm);
                          }}
                        >
                          {!openForm ? (
                            <IoIosArrowDropdownCircle size={20} />
                          ) : (
                            <AiFillCloseCircle size={20} color="#ff6666" />
                          )}
                        </Button>
                      </span>
                    </div>
                  </Card>
                  {openForm && (
                    <Card body className="mt-3 mb-3">
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data custom-text-muted fw-bold">
                          New Password
                        </Form.Label>
                        <Form.Control
                          type="password"
                          name="password"
                          onChange={handleFieldChange}
                          value={formData.password}
                          isInvalid={!!formError.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="require-data custom-text-muted fw-bold">
                          Confirm Password
                        </Form.Label>

                        <Form.Control
                          type="password"
                          name="c_password"
                          onChange={handleFieldChange}
                          value={formData.c_password}
                          isInvalid={!!formError.c_password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {formError.c_password}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <div className="d-flex">
                          <Button
                            size="sm"
                            variant="success"
                            className="ms-auto"
                            onClick={(e) => {
                              handleSubmit(e);
                            }}
                            disabled={
                              formData.password && formData.c_password
                                ? false
                                : true
                            }
                          >
                            {onSave ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              " Change Password"
                            )}
                          </Button>
                        </div>
                      </Form.Group>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>

      {axisProPermission("list-task") && (
        <div className="organizational-data">
          <Row className="gx-2 border-top">
            <Col lg={12} md={12} sm={12}>
              <span className="custom-postioning-header-employee-overview fs--1 fw-bold">
                Tasks In Progress
              </span>
              <div
                className="mt-4 d-flex flex-column gap-2"
                style={{ maxHeight: "41.5vh", overflow: "auto" }}
              >
                {!loading && inProgressTasks.length > 0 ? (
                  inProgressTasks.map((task, key) => (
                    <ListGroup
                      key={key}
                      className="rounded-0"
                      horizontal
                      style={{
                        border: task.is_passed_end_date
                          ? "2px solid #ffacb6"
                          : "1px solid #80808047",
                      }}
                    >
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start border-0 w-50"
                      >
                        <div className="ms-2 me-auto">
                          <span className="custom-text-muted-crm">Task</span>
                          <div className="fw-bold">{task.title}</div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start border-0 w-25"
                      >
                        <div className="ms-2 me-auto">
                          <span className="custom-text-muted-crm">Project</span>
                          <div className="fw-bold">{task.project?.title}</div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start border-0 w-25"
                      >
                        <div className="ms-2 me-auto">
                          <span className="custom-text-muted-crm">
                            Start Date
                          </span>
                          <div className="fw-bold">
                            {task.start_date_formatted}
                          </div>
                        </div>
                      </ListGroup.Item>
                      <ListGroup.Item
                        as="li"
                        className="d-flex justify-content-between align-items-start border-0 w-25"
                      >
                        <div className="ms-2 me-auto">
                          <span className="custom-text-muted-crm">
                            End Date
                          </span>
                          <div className="fw-bold">
                            {task.end_date_formatted}
                          </div>
                        </div>
                        <Badge
                          className="rounded-0 text-capitalize"
                          bg="success"
                          pill
                        >
                          {task.status.replace("_", " ")}
                        </Badge>
                      </ListGroup.Item>
                    </ListGroup>
                  ))
                ) : loading ? (
                  <ListLoading style={{ height: "26vh", overflow: "hidden" }} />
                ) : (
                  <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                    <BsDatabaseFillExclamation
                      size={40}
                      className="text-mute"
                    />
                    <span>No data found!</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default GeneralInformation;
