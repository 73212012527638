import { React, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import Invitation from "module/common/Invitation/Invitation";
import { Button } from "react-bootstrap";
const SelectUsers = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  style,
  placeholder,
  disabled,
}) => {
  const [showInvitation, setInvitation] = useState(false);
  const handleInvite = () => setInvitation(!showInvitation);

  const setItems = async (value) => {
    let response = await axios.get("user/users-autocomplete", {
      params: { name: value },
    });
    let data = response && response.data ? response.data.data : [];
    return data.map((item) => ({
      label: item.first_name + " " + item.last_name,
      value: keyName ? item[keyName] : item.id,
    }));
  };

  const NoOptionsMessage = () => {
    return (
      <div className="d-flex justify-content-between">
        <span>No options</span>
        <Button
          variant="transparant"
          className="p-0 custom-transperent-common-button"
          onClick={handleInvite}
          size="sm"
        >
          <span className="text-primary">Invite</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions
        isClearable
        isDisabled={disabled}
        name={name ? name : "user"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            height: 32,
            minHeight: 32,
            ...style,
          }),
        }}
        noOptionsMessage={NoOptionsMessage}
      />
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

SelectUsers.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectUsers;
