import { showToast } from "../Toast/toast";

export const discountValidations = (
  discount_amount,
  unit_price,
  discount_percent,
  discountMethod,
  message
) => {
  if (parseFloat(discount_amount) > parseFloat(unit_price) && !discountMethod) {
    showToast(message, "error");
    return false;
  } else if (parseFloat(discount_percent) > 100 && discountMethod) {
    showToast(message, "error");
    return false;
  } else {
    return true;
  }
};

export const unitPriceValidations = (unit_price, message) => {
  if (parseFloat(unit_price) > 0) {
    return true;
  } else {
    showToast(message, "error");
    return false;
  }
};

export const quantityValidations = (quantity, message) => {
  if (parseFloat(quantity) > 0) {
    return true;
  } else {
    showToast(message, "error");
    return false;
  }
};

export const paymentAmountValidation = (amount) => {
  if (parseFloat(amount) > 0 || amount === "") {
    return true;
  }
  showToast("Payment amount must be graterthan zero", "error");
  return false;
};

export const checkKitAlreadySelectedOrNot = (kit, value, message) => {
  const valueExists = kit.some((item) => item.kit_id === value);
  if (valueExists) {
    showToast(message, "error");
    return false;
  } else {
    return true;
  }
};

export const hasBatchInventoryOrNot = (hasBatch, batch_number) => {
  if (hasBatch) {
    if (batch_number) {
      return true;
    } else {
      showToast(
        "The Item has batch and must be select a batch number!",
        "error"
      );
      return false;
    }
  } else {
    return true;
  }
};

export const descriptionField = (description) => {
  if (description === "") {
    showToast("You must enter item description field!", "error");
    return false;
  } else {
    return true;
  }
};
