import React from 'react';
import { Card, Placeholder } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ReportinLineItemLoading = ({ colSpan }) => {
  return (
    <tr>
      <td colSpan={colSpan ?? 3}>
        <Placeholder as={Card.Text} animation="glow">
          <Placeholder xs={7} /> <Placeholder xs={4} />
          <Placeholder xs={4} /> <Placeholder xs={6} />
          <Placeholder xs={8} />
        </Placeholder>
      </td>
    </tr>
  );
};

ReportinLineItemLoading.propTypes = {
  colSpan: PropTypes.number
};

export default ReportinLineItemLoading;
