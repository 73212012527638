import axios from "axios";
import FileController from "components/file-controller/FileController";
import { DeleteContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import { attachmentsFormKeys } from "helpers/formKeys";
import setNewFormData from "helpers/setNewFormData";
import TabLoading from "module/common/TabLoading";
import { showToast } from "module/common/Toast/toast";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Form,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { AiFillFileUnknown } from "react-icons/ai";
import {
  FaCloudDownloadAlt,
  FaFileCsv,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFileWord,
  FaTrash,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const Attachments = ({
  itemId,
  type,
  attachmentControl = true,
  itemsClassName = "",
  fetchUrl,
}) => {
  const [formData, setFormData] = useState(attachmentsFormKeys);
  const [attachments, setAttachments] = useState({});
  const [loading, setLoading] = useState(false);
  const [resetFiles, setResetFiles] = useState(false);
  const { dispatch } = useContext(DeleteContext);

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: `/documents/${id}`,
        title: "Delete Attachment",
        message: "Are you sure you want to delete this Attachment?",
        onSuccess: () => {
          const dataWithoutDeletedItem = attachments.filter(
            (item) => item.id !== id
          );
          setAttachments(() => [...dataWithoutDeletedItem]);
          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const handleFiles = (files) => {
    handleFieldChange({ target: { name: "files", value: files } });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `${fetchUrl}`,
    });
    setAttachments(data);
    setFormData({
      id: itemId,
      type: type,
      files: [],
    });
    setLoading(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    let fieldName;
    let fieldValue;
    if (!action) {
      value = e.target.value;

      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      fieldName = action.name.replace("_id", "_name");
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
        fieldValue = e.label ? e.label : "";
      }
      if (action.action === "clear") {
        fieldValue = null;
      }
      newFormData = {
        ...formData,
        [fieldName]: fieldValue,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios({
      method: "post",
      url: `documents`,
      data: setNewFormData(formData),
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((response) => {
      if (response.data.success === true) {
        getData();
        setResetFiles(!resetFiles);
        showToast(response.data.message, "success");
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
      }
    });
  };

  return (
    <ListGroup>
      {attachmentControl && (
        <ListGroupItem className="rounded-0">
          <Form.Group controlId="formFile" className="mb-3">
            {formData.files.length > 0 && (
              <Form.Label>Recently Added Files</Form.Label>
            )}
            <FileController
              limit={5}
              limitFeature={true}
              limitFileSizeInMB={5}
              limitFileType={[
                "jpg",
                "png",
                "jpeg",
                "gif",
                "pdf",
                "excel",
                "xlsx",
                "docx",
                "doc",
                "xls",
                "csv",
              ]}
              onChange={handleFiles}
              className="my-3"
              note="You can upload a maximum of 5 files, 5MB each"
              clearFiles={resetFiles}
            />

            <Button
              variant="success"
              size="sm"
              onClick={(e) => {
                handleSubmit(e);
              }}
              disabled={formData.files.length > 0 ? false : true}
            >
              Upload
            </Button>
          </Form.Group>
        </ListGroupItem>
      )}

      {attachments.length > 0 && !loading ? (
        attachments.map((attachment, key) => (
          <Fragment key={key}>
            {key === 0 && (
              <ListGroupItem className={`rounded-0 ${itemsClassName}`}>
                <label>Existing Files</label>
              </ListGroupItem>
            )}
            <ListGroupItem className={`rounded-0 ${itemsClassName}`}>
              <div className="d-flex">
                <div className="me-3">
                  <OverlayTrigger
                    trigger={["hover", "focus"]}
                    placement="bottom"
                    overlay={
                      <Popover className="p-3">
                        <div className="d-flex flex-column">
                          <span className="text-mute custom-text-muted-crm">
                            File Size: {attachment.human_readable_size}
                          </span>
                          <span className="text-mute custom-text-muted-crm">
                            Updated at: {attachment.updated_at}
                          </span>
                          <span className="text-mute custom-text-muted-crm">
                            Last Updated: {attachment.last_updated}
                          </span>
                        </div>
                      </Popover>
                    }
                  >
                    <Button
                      size="sm"
                      className="p-0 custom-transperent-common-button"
                      variant="transparent"
                    >
                      {attachment.file_name.split(".").pop() === "png" ||
                      attachment.file_name.split(".").pop() === "jpg" ? (
                        <FaFileImage size={38} />
                      ) : attachment.file_name.split(".").pop() === "pdf" ? (
                        <FaFilePdf color="#fb3939b8" size={38} />
                      ) : attachment.file_name.split(".").pop() === "xls" ||
                        attachment.file_name.split(".").pop() === "xlsx" ? (
                        <FaFileExcel color="#007f3eb5" size={38} />
                      ) : attachment.file_name.split(".").pop() === "csv" ? (
                        <FaFileCsv color="#41a754cc" size={38} />
                      ) : attachment.file_name.split(".").pop() === "doc" ? (
                        <FaFileWord color="#285293" size={38} />
                      ) : (
                        <AiFillFileUnknown size={38} />
                      )}
                    </Button>
                  </OverlayTrigger>
                </div>
                <div className="d-flex flex-column">
                  <span className="fw-bold">
                    {attachment.file_name.length > 65
                      ? attachment.file_name.substring(0, 65) +
                        "." +
                        attachment.file_name.split(".").pop()
                      : attachment.file_name}
                  </span>
                  <div>
                    <span className="text-mute custom-text-muted-crm">
                      {attachment.created_at}
                    </span>
                    <span className="text-mute custom-text-muted-crm ms-2">
                      {attachment.created}
                    </span>
                  </div>
                </div>

                <div className="ms-auto">
                  <Button
                    as={Link}
                    target="_blank"
                    to={attachment.file_url}
                    variant="transparent"
                    size="sm"
                    className="custom-transperent-common-button p-0"
                  >
                    <FaCloudDownloadAlt color="#718dd6" size={18} />
                  </Button>
                  {attachmentControl && (
                    <Button
                      variant="transparent"
                      size="sm"
                      className="custom-transperent-common-button p-0 ms-2"
                      onClick={() => {
                        handleDelete(attachment.id);
                      }}
                    >
                      <FaTrash color="#dc3939" />
                    </Button>
                  )}
                </div>
              </div>
            </ListGroupItem>
          </Fragment>
        ))
      ) : loading ? (
        <TabLoading />
      ) : (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: "9vh" }}
        >
          <span className="">No Attachments Yet &#128521;</span>
        </div>
      )}
    </ListGroup>
  );
};

export default Attachments;
