const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const enquiryFilters = {
  name: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
  },
  phone: {
    label: "Phone",
    operators: commonFilterOperators,
    active: true,
  },
  email: {
    label: "Email",
    operators: commonFilterOperators,
    active: true,
  },
  created_at: {
    label: "Enquired Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
};

export { enquiryFilters };
