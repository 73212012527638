import { React } from "react";
import { Card, Col, Row } from "react-bootstrap";

import TicketsSingleTile from "./TicketsSingleTile";

function TicketsTile() {
  return (
    <Card className="h-md-100 card-main">
      <Card.Body>
        <Row>
          <Col lg={3} md={6} sm={12}>
            <TicketsSingleTile
              label={"Total Tickets Completed"}
              number={"40"}
              variant={"text-success"}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <TicketsSingleTile
              label={"Partially Completed Tickets"}
              number={"30"}
              variant={"text-warning"}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <TicketsSingleTile
              label={"Tickets Yet to Start"}
              number={"30"}
              variant={"text-dark"}
            />
          </Col>
          <Col lg={3} md={6} sm={12}>
            <TicketsSingleTile
              label={"Pending Tickets"}
              number={"40"}
              variant={"text-danger"}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TicketsTile;
