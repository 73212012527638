import SelectCustomer from "components/form/SelectCustomer";
import SelectProject from "components/form/SelectProject";
import SelectProjectStatus from "components/form/SelectProjectStatus";
import SelectTaskStatus from "components/form/SelectTaskStatus";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const projectReportFilters = {
  title: {
    label: "Project Name",
    operators: commonFilterOperators,
    active: true,
  },
  customer_id: {
    label: "Client",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectCustomer
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  start_date: {
    label: "Start Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  end_date: {
    label: "End Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  status: {
    label: "Status",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectProjectStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

const taskReportFilters = {
  title: {
    label: "Task Name",
    operators: commonFilterOperators,
    active: true,
  },
  project_id: {
    label: "Project Name",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectProject
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  start_date: {
    label: "Start Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  end_date: {
    label: "End Date",
    type: "date",
    operators: [
      { name: "EQUAL", value: "=" },
      { name: "GREATER THAN", value: ">" },
      { name: "LESS THAN", value: "<" },
      { name: "BETWEEN", value: "BETWEEN" },
    ],
    active: true,
    Element: "DATE",
  },
  status: {
    label: "Status",
    operators: [
      { name: "Equal", value: "=" },
      { name: "Not Equal", value: "!=" },
    ],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectTaskStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

/////////Report New Filter Form Keys

const timeTrackerReportFilterKeys = {
  date_range: "",
  date_range_for: "",
  from_date: "",
  to_date: "",
  task_id: "",
  task_id_ref: { label: "", value: "" },
  contributor_id: "",
  contributor_id_ref: { label: "", value: "" },
  account_id: "",
  account_id_ref: { label: "", value: "" },
  project_id: "",
  project_id_ref: { label: "", value: "" },
};

const employeeTaskReportFilterKeys = {
  date_range: "",
  from_date: "",
  to_date: "",
  date_range_for: "",
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  project_id: "",
  project_id_ref: { label: "", value: "" },
  user_id: "",
  user_id_ref: { label: "", value: "" },
  status: "",
  status_ref: { label: "", value: "" },
};
const employeeTaskSummaryReportFilterKeys = {
  employee_id: "",
  employee_id_ref: { label: "", value: "" },
};

const employeeLeadSummaryReportFilterKeys = {
  employee_id: "",
  employee_id_ref: { label: "", value: "" },
  lead_source_id: "",
  lead_source_id_ref: { label: "", value: "" },
};

const oppornunityDetailedReportReportFilterKeys = {
  date_range: "",
  date_range_for: "",
  closing_date: "",
  lead_id: "",
  lead_id_ref: { label: "", value: "" },
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  campaign_id: "",
  campaign_id_ref: { label: "", value: "" },
  stage: "",
  stage_ref: { label: "", value: "" },
};

const leadDetailedReportFilterKeys = {
  owner_id: "",
  owner_id_ref: { label: "", value: "" },
  source_id: "",
  source_id_ref: { label: "", value: "" },
  stage_id: "",
  stage_id_ref: { label: "", value: "" },
  lead_status: "",
  lead_status_ref: { label: "", value: "" },
  assignee_id: "",
  assignee_id_ref: { label: "", value: "" },
  priority: "",
  priority_ref: { label: "", value: "" },
};

const leadConversionReportFilterKeys = {
  owner_id: "",
  owner_id_ref: { label: "", value: "" },
};

const leadSourceSummeryReportFilterKeys = {
  date_range: "",
  date_range_for: "created_at",
  from_date: "",
  to_date: "",
  lead_source_id: "",
  lead_source_id_ref: { label: "", value: "" },
};


const leadSourceSummeryItemReportFilterKeys = {
  date_range: "",
  date_range_for: "created_at",
  from_date: "",
  to_date: "",
  title: "",
  company_name: "",
  is_company: "",
  lead_owner: "",
  lead_owner_ref: { value: "", label: "" },
  is_converted: "",
  lead_source: "",
  lead_source_ref: { value: "", label: "" },
  lead_priority: "",
  lead_priority_ref: { value: "", label: "" },
  lead_status: "",
  lead_status_ref: { value: "", label: "" },
};

const runningCompletedTasksReportFilterKeys = {
  date_range: "",
  from_date: "",
  to_date: "",
  date_range_for: "",
  customer_id: "",
  customer_id_ref: { label: "", value: "" },
  project_id: "",
  project_id_ref: { label: "", value: "" },
  employee_id: "",
  employee_id_ref: { label: "", value: "" },
  status: "",
  status_ref: { label: "", value: "" },
};

const sprintDetailedReportFilterKeys = {
  date_range: "",
  from_date: "",
  to_date: "",
  date_range_for: "",
  project_id: "",
  project_id_ref: { label: "", value: "" },
  status: "",
  status_ref: { label: "", value: "" },
  reference: "",
};

export {
  projectReportFilters,
  taskReportFilters,
  ///////Report New Filter Form Keys
  timeTrackerReportFilterKeys,
  employeeTaskReportFilterKeys,
  employeeTaskSummaryReportFilterKeys,
  employeeLeadSummaryReportFilterKeys,
  oppornunityDetailedReportReportFilterKeys,
  leadDetailedReportFilterKeys,
  leadConversionReportFilterKeys,
  runningCompletedTasksReportFilterKeys,
  sprintDetailedReportFilterKeys,
  leadSourceSummeryReportFilterKeys,
  leadSourceSummeryItemReportFilterKeys
};
