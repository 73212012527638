import SelectProject from "components/form/SelectProject";
import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import DateRangeFilter from "../../common/Filter/DateRangeFilter";
import ColumnSelector from "../../common/Filter/ColumnSelector";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { FaFilter } from "react-icons/fa";
import removeEmptyFields from "helpers/removeEmptyFields";
import { sprintDetailedReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import SelectSprintStatus from "components/form/SelectSprintStatus";

const SprintDetailedReportFilterSection = ({
  filters,
  setFilters,
  filterValuesExist,
  columns,
}) => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const filterItems = queryParams.get("filters");

  useEffect(() => {
    if (filterItems) {
      const parsedFilterItems = JSON.parse(filterItems);
      setFilters({
        ...filters,
        ...parsedFilterItems,
      });
    } else {
      setFilters(sprintDetailedReportFilterKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterItems]);

  const handleFilterChange = (e, action) => {
    let newOldData;
    if (action) {
      newOldData = {
        ...filters,
        [action.name]: e ? e.value : null,
        [action.name + "_ref"]: e,
      };
    }
    setFilters({ ...newOldData });
  };

  const handleApplyFilter = () => {
    if (!filters.date_range_for) {
      filters.from_date = "";
      filters.to_date = "";
    }
    const filteredObject = removeEmptyFields(filters);
    navigate(
      `/reports/sprint-detailed-report?page=1&${createSearchParams({
        filters: JSON.stringify({
          ...filteredObject,
        }),
      })}`
    );
  };

  return (
    <Container fluid>
      <Form>
        <Row className="g-3">
          <Col md={2}>
            <ColumnSelector
              columns={columns}
              filters={filters}
              setFilters={setFilters}
              defaultOption={true}
              value={filters.date_range_for}
            />
          </Col>
          <Col md={2}>
            <DateRangeFilter
              label="Date Range"
              filters={filters}
              setFilters={setFilters}
            />
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Project</Form.Label>
              <SelectProject
                name="project_id"
                handleFieldChange={handleFilterChange}
                value={filters.project_id_ref}
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Status</Form.Label>
              <SelectSprintStatus
                name="status"
                handleFieldChange={handleFilterChange}
                value={filters.status_ref}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <div className="h-100 d-flex align-items-center">
              <Button
                size="sm"
                className=""
                variant="success"
                onClick={handleApplyFilter}
                disabled={!filterValuesExist ? true : false}
              >
                <FaFilter size={13} />
                <span className="ms-1">Apply</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default SprintDetailedReportFilterSection;
