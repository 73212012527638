import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  ListGroup,
  Offcanvas,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FaBell, FaTicketAlt } from "react-icons/fa";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./TicketInfo.scss";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import ProfileImageShe from "../../../assets/img/Avathar/she.jpg";
import Image from "react-bootstrap/Image";
import Attachments from "module/common/ViewPages/Common/Attachments/Attachments";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import { TbMapPinFilled } from "react-icons/tb";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import { Link } from "react-router-dom";
import { BsBoxArrowUpRight } from "react-icons/bs";
import Comments from "module/common/ViewPages/Common/Comments/Comments";
import useAxisproPermission from "hooks/useAxisproPermission";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

const TicketInfo = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const containsProject = location.pathname.includes("project/info");
  const handleClose = () => navigate(-1);
  const { itemId } = useParams();
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [ticketStatus, setTicketStatus] = useState([]);
  const [key, setKey] = useState("comments");
  const axisProPermission = useAxisproPermission();
  const [updateStatusPermission] = ["update-ticket-status"].map((permission) =>
    axisProPermission(permission)
  );

  useEffect(() => {
    if (itemId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const getDataItem = async (page = 1) => {
    const ticketStatusData = await apiCall({
      url: "crm/ticket-status",
    });

    setTicketStatus(ticketStatusData);
    setLoading(true);
    const data = await apiCall({
      url: "crm/ticket/" + itemId,
    });
    setTicketData(data);
    setLoading(false);
  };

  const handleTicketStatus = (e, id, status) => {
    e.preventDefault();
    if (status && status !== ticketData.status) {
      axios({
        method: "post",
        url: `crm/ticket-status/update`,
        params: { ticket_id: itemId, status: status },
      }).then((response) => {
        if (response.data.success === true) {
          setTicketData(response.data.data);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
    }
  };

  return (
    <Offcanvas
      show={true}
      onHide={handleClose}
      placement="end"
      style={{
        width: "71vh",
      }}
    >
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-1 pb-1">
          <FaTicketAlt size={30} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            Ticket Overview
          </span>
          {/* containsProject is boolean is used to check project/info in route - If there need to hide the below button - No way to pass anything to this component - so this is implemented now */}
          <span className="ms-2">
            {!containsProject && (
              <Button
                size="sm"
                variant="transperant"
                className="custom-transperent-common-button p-0"
                as={Link}
                to={`/tickets/list/overview/${itemId}`}
              >
                <BsBoxArrowUpRight />
              </Button>
            )}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {!loading && Object.keys(ticketData).length > 0 ? (
          <>
            <Card>
              <Card.Header
                className="rounded-0 ticket-custom-overview-card-header"
                style={{
                  backgroundColor:
                    ticketData?.priority === "high" ? "#ff00000f" : "#fff",
                }}
              >
                <div className="d-grid">
                  <span className="fw-bold text-capitalize d-flex">
                    <span>
                      <FaBell size={18} color="#8b8989" className="me-2" />
                      {ticketData?.title}
                    </span>
                    <span className="ms-auto">
                      <Badge
                        bg={
                          ticketData?.priority === "high"
                            ? "danger"
                            : ticketData?.priority === "medium"
                            ? "warning"
                            : "success"
                        }
                        className="ps-3 pe-3"
                      >
                        {ticketData?.priority}
                      </Badge>
                    </span>
                  </span>
                  <span className="pt-2">{ticketData?.description}</span>
                </div>
              </Card.Header>
              <Card.Body className="border rounded-0 p-0">
                <ListGroup key="md" horizontal="md" className="d-flex">
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        PROJECT
                      </span>
                      <span className="fw-bold text-capitalize">
                        {ticketData?.project ?? ""}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        Client
                      </span>
                      <span className="fw-bold text-capitalize">
                        <Image
                          className="custom-image-crm"
                          src={
                            ticketData?.customer_image
                              ? ticketData?.customer_image
                              : ProfileImage
                          }
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"30vh"}
                          width={"30vh"}
                        />
                        <span className="ms-1">
                          {ticketData?.customer_name ?? ""}
                        </span>
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        Assignee
                      </span>
                      <span className="fw-bold text-capitalize">
                        <Image
                          className="custom-image-crm"
                          src={
                            ticketData?.assignee_image
                              ? ticketData?.assignee_image
                              : ProfileImageShe
                          }
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"30vh"}
                          width={"30vh"}
                        />
                        <span className="ms-1">{ticketData?.assignee}</span>
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="rounded-0 border-0 border-end flex-fill">
                    <div className="d-grid gap-1 mb-2">
                      <span className="custom-text-muted-crm fw-bold">
                        Ticket Type
                      </span>
                      <span className="fw-bold text-capitalize">
                        {ticketData?.ticket_type}
                      </span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
            <ListGroup
              key="sm"
              horizontal
              className="my-2 d-flex flex-wrap gap-2"
            >
              {ticketStatus?.length > 0 &&
                ticketStatus.map((status, key) => (
                  <ListGroup.Item
                    key={key}
                    className={`ticket-status-${status} rounded-0 border-start flex-fill d-flex justify-content-center  fw-bold text-capitalize`}
                    style={{
                      backgroundColor:
                        status === "open"
                          ? "#ddf4ff"
                          : status === "new"
                          ? "#ded9ff9c"
                          : status === "pending"
                          ? "#fffa9787"
                          : status === "awaiting_business_input"
                          ? "#ffeca1b0"
                          : status === "with_client_review"
                          ? "#fde1cb"
                          : status === "defered"
                          ? "#ffb6b6"
                          : status === "closed"
                          ? "#cfffc8"
                          : "#fff",
                      minWidth: "108px",
                      maxWidth: "100px",
                    }}
                    onClick={(e) => {
                      updateStatusPermission &&
                        handleTicketStatus(e, ticketData.id, status);
                    }}
                  >
                    {status === "awaiting_business_input"
                      ? "Await"
                      : status === "with_client_review"
                      ? "Review"
                      : status}
                    {ticketData.status === status && (
                      <TbMapPinFilled
                        className="ms-1"
                        color="#ff3939c4"
                        size={20}
                      />
                    )}
                  </ListGroup.Item>
                ))}
            </ListGroup>
            <div className="d-flex">
              <div className="p-2 flex-grow-1">
                <span className="custom-text-muted-crm">Ticket Orign : </span>
                <span className="fs--2 text-capitalize">
                  {ticketData?.ticket_origin}
                </span>
              </div>
              <div className="p-2">
                <span className="custom-text-muted-crm">Last Activity: </span>
                <span className="fs--2">{ticketData?.updated_at}</span>
              </div>
            </div>
            <div className="ticket-tabs-container">
              <Tabs
                defaultActiveKey="comments"
                id="ticket-custom-tabs"
                className="mb-3 ticket-custom-tabs"
                fill
                variant="pills"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab
                  eventKey="comments"
                  title="Comments"
                  className="ticket-custom-tab ticket-custom-tab-overview"
                >
                  <Comments id={itemId} comment_type="ticket" />
                </Tab>
                <Tab
                  eventKey="activity"
                  title="Activity"
                  className="ticket-custom-tab ticket-custom-tab-overview"
                >
                  {key === "activity" && <ActivityTimeLine itemId={itemId} />}
                </Tab>
                <Tab
                  eventKey="attachments"
                  title="Attachments"
                  className="ticket-custom-tab ticket-custom-tab-overview"
                >
                  <Attachments
                    itemId={ticketData.id}
                    type="TICKET"
                    commentsList={ticketData}
                    fetchUrl={`crm/ticket-attachments/${ticketData.id}`}
                  />
                </Tab>
              </Tabs>
            </div>
          </>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default TicketInfo;
