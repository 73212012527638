import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  ListGroup,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { Image } from "react-bootstrap/esm";
import { FaCircle, FaList } from "react-icons/fa";
import {
  Link,
  createSearchParams,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ProfileImage from "../../../../assets/img/Avathar/profile.png";
import FilterButton from "components/filter/FilterButton";
import { apiCall } from "helpers/apiCalls";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import qs from "qs";
import { ticketFilters } from "module/common/helpers/ticketsCommonFilters";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import ListLoading from "module/common/ListLoading";
import AppPagination from "components/common/app-pagination/AppPagination";

const TicketInfo = ({ userId, userName, type, filterVisible = true }) => {
  const [queryParams] = useSearchParams();
  const [activeContent, setactiveContent] = useState("");
  const pageNumber = queryParams.get("page");
  const tab = queryParams.get("tab");
  const filterItems = queryParams.get("filters");
  const [filters] = useState(ticketFilters);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState({});
  const { empId } = useParams();
  const allQueryParams = Object.fromEntries([...queryParams]);

  const defaultFilter =
    type === "client"
      ? `{"column":"customer_id","type":"string","operator":"=","value":"${userId}"}`
      : `{"column":"assigned_to","type":"string","operator":"=","value":"${userId}"}`;

  const handleText = (id) => {
    if (activeContent === id) {
      setactiveContent("");
    } else {
      setactiveContent(id);
    }
  };

  useEffect(() => {
    tab === "tickets" && getTicketsData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, pageNumber, filterItems, tab]);

  const getTicketsData = async (page = 1) => {
    setLoading(true);
    const ticketsData = await apiCall({
      url: `crm/ticket`,
      params: {
        page: page,
        filters: filterItems
          ? removeRefData(
              createFilterArray(`${filterItems},,${defaultFilter}`),
              "array"
            )
          : removeRefData(createFilterArray(`${defaultFilter}`), "array"),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setTickets(ticketsData);
    setLoading(false);
  };

  return (
    <div>
      <ListGroup className="mb-2">
        <ListGroup.Item className="rounded-0 pb-0 pt-0 d-flex justify-content-end">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="button-tooltip-2">Ticket List</Tooltip>}
          >
            <Button
              className="ticket-custom-add mt-2 mb-2"
              size="sm"
              variant="light"
              as={Link}
              to={`/tickets/list?tab=all&${createSearchParams({
                filters: JSON.stringify({
                  column: "assigned_to",
                  operator: "=",
                  type: "string",
                  value: userId,
                  value_ref: {
                    label: userName,
                    value: userId,
                  },
                }),
              })}`}
            >
              <FaList />
            </Button>
          </OverlayTrigger>
          {filterVisible && (
            <FilterButton
              className="mb-2 mt-2 ms-1"
              variant="success"
              filters={filters}
              url={`employee/overview/${empId}`}
              additionalParam={{ tab: tab }}
            />
          )}
        </ListGroup.Item>
      </ListGroup>
      <div
        className="table-wraper"
        style={{ maxHeight: "69.5vh", minHeight: "69.5vh", overflow: "auto" }}
      >
        <Table bordered size="sm">
          {Object.keys(tickets).length > 0 &&
          !loading &&
          Object.keys(tickets.data).length > 0 ? (
            tickets.data.map((item, key) => (
              <Fragment key={key}>
                <thead style={{ borderBottomColor: "white" }}>
                  <tr>
                    <th className="pb-0">
                      <span className="custom-text-muted-crm"></span>
                    </th>
                    <th className="pb-0 ps-2" style={{ width: "38%" }}>
                      <span className="custom-text-muted-crm">Ticket</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Project</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Client</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Type</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Priority</span>
                    </th>
                    <th className="pb-0 ps-2">
                      <span className="custom-text-muted-crm">Status</span>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ borderBottomColor: "white" }}>
                  <tr>
                    <td className="pb-0">{key + 1}</td>
                    <td className="pb-0 ps-2">
                      <span className="fw-bold">{item.title}</span>
                    </td>
                    <td className="pb-0 ps-2">{item?.project}</td>
                    <td className="pb-0 ps-2">
                      <div className="d-flex align-items-center  custom-clickable-link-crm">
                        <Image
                          className="custom-image-crm"
                          src={item?.customer_image ?? ProfileImage}
                          roundedCircle
                          alt="?"
                          loading="lazy"
                          height={"35vh"}
                          width={"35vh"}
                        />

                        <p className="ps-1 m-0 text-capitalize">
                          {item?.customer_name}
                        </p>
                      </div>
                    </td>
                    <td className="pb-0 ps-2">{item?.ticket_type}</td>
                    <td className="pb-0 ps-2 text-capitalize">
                      {item.priority ? (
                        <>
                          <FaCircle
                            color={
                              item.priority === "low"
                                ? "#77cd8e"
                                : item.priority === "medium"
                                ? "#fba86b"
                                : "#fd8282"
                            }
                            size={8}
                            className="me-1"
                          />
                          <span> {item.priority}</span>
                        </>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="pb-0 ps-2">
                      <span
                        className="badge p-2  project-custom-badge-crm"
                        style={{
                          backgroundColor:
                            item.status === "open"
                              ? "#76ccf4"
                              : item.status === "new"
                              ? "#a092fd9c"
                              : item.status === "pending"
                              ? "#fdcd2ac2"
                              : item.status === "awaiting_business_input"
                              ? "#ffaa0bb0"
                              : item.status === "with_client_review"
                              ? "#ffad6d"
                              : item.status === "defered"
                              ? "#f78989"
                              : item.status === "closed"
                              ? "#6cd75d"
                              : "#fff",
                        }}
                      >
                        <span className="fw-bold text-uppercase">
                          {item.status === "awaiting_business_input"
                            ? "Await"
                            : item.status === "with_client_review"
                            ? "Review"
                            : item.status}
                        </span>
                      </span>
                    </td>
                  </tr>
                  <tr className="border-bottom">
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0">
                      <div
                        className="text-dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleText(item.id)}
                      >
                        <p className="text-wrap fs--2">
                          {activeContent === item.id
                            ? item.description.replace(/<(.|\n)*?>/g, "")
                            : `${item.description
                                .replace(/<(.|\n)*?>/g, "")
                                .substring(0, 75)}`}
                          {activeContent === item.id &&
                            item.description.length >= 75 &&
                            " ...read less"}
                          {activeContent !== item.id &&
                            item.description.length >= 75 &&
                            " ...read more"}
                        </p>
                      </div>
                    </td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                    <td className="pb-0 pt-0"></td>
                  </tr>
                </tbody>
              </Fragment>
            ))
          ) : !loading ? (
            <tbody>
              <tr>
                <td colSpan={7}>
                  <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
                    <BsDatabaseFillExclamation
                      size={40}
                      className="text-mute"
                    />
                    <span>No data found!</span>
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody style={{ borderBottomColor: "white" }}>
              <tr>
                <td colSpan={7}>
                  <ListLoading
                    style={{ maxHeight: "65vh", overflow: "hidden" }}
                  />
                </td>
              </tr>
            </tbody>
          )}
        </Table>
      </div>
      <div className="pt-2">
        <div className="d-flex justify-content-center">
          {tickets && tickets.links && (
            <AppPagination
              data={tickets}
              url={`/employee/overview/${empId}`}
              search={allQueryParams}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketInfo;
