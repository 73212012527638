import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { FcClearFilters } from "react-icons/fc";
import { commonTeamsFilterFormKeys } from "helpers/formKeys";
import { showToast } from "module/common/Toast/toast";
import SelectUsers from "components/form/SelectUsers";

const CommonTeamsFilter = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const [filterFields, setFilterFields] = useState(commonTeamsFilterFormKeys);
  const allQueryParams = Object.fromEntries([...queryParams]);
  const { name, team_lead_id, team_lead_id_ref } = allQueryParams;

  const handleFieldChange = (e, action) => {
    if (!action) {
      setFilterFields({ ...filterFields, [e.target.name]: e.target.value });
    } else {
      let newFormData;
      if (action.action === "select-option") {
        newFormData = {
          ...filterFields,
          [action.name]: e.value,
          [action.name + "_ref"]: e,
        };
      } else if (action.action === "clear") {
        newFormData = {
          ...filterFields,
          [action.name]: "",
          [action.name + "_ref"]: {
            label: "",
            value: "",
          },
        };
      } else if (action.action === "remove-value") {
        newFormData = {
          ...filterFields,
          [action.name]: e.map((item) => item.value),
          [action.name + "_ref"]: e,
        };
      }
      setFilterFields(newFormData);
    }
  };

  const handleFilterApply = () => {
    if (!filterFields.date_range_for && filterFields.date_range) {
      showToast(`Please Select Date Field`, "error");
      return;
    }

    if (filterFields.date_range === "custom_date" && !filterFields.from_date) {
      showToast(`Please Select From Date`, "error");
      return;
    } else if (
      filterFields.date_range === "custom_date" &&
      !filterFields.to_date
    ) {
      showToast(`Please Select To Date`, "error");
      return;
    }
    navigate(
      `?${createSearchParams(
        removeEmptyFields({
          ...filterFields,
          team_lead_id_ref:
            filterFields?.team_lead_id &&
            JSON.stringify(filterFields?.team_lead_id_ref),

          page: 1,
        })
      )}`
    );
  };

  useEffect(() => {
    setFilterFields((previous) => ({
      ...previous,
      name: name ? name : "",
      team_lead_id: team_lead_id ? team_lead_id : "",
      team_lead_id_ref: team_lead_id_ref ? JSON.parse(team_lead_id_ref) : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, team_lead_id]);

  const handleResetFilter = () => {
    setFilterFields(commonTeamsFilterFormKeys);
  };

  return (
    <div className="p-3 border-bottom">
      <Row className="g-3">
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Name</Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleFieldChange}
                  value={filterFields.name}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col md={2}>
          <Row>
            <Form.Group as={Col}>
              <Form.Label className="fs--1">Lead</Form.Label>
              <Col>
                <SelectUsers
                  name="team_lead_id"
                  value={filterFields.team_lead_id_ref}
                  handleFieldChange={handleFieldChange}
                />
              </Col>
            </Form.Group>
          </Row>
        </Col>
        <Col className="d-flex align-items-end justify-content-end">
          <Row className="m-0">
            <Form.Group as={Col} md={2} className="p-0">
              <div className="d-flex align-items-end gap-2">
                <Button
                  size="sm"
                  variant="warning"
                  onClick={handleFilterApply}
                  title="Apply Added Filters"
                >
                  Apply
                </Button>
                <Button
                  size="sm"
                  variant="transparent"
                  className="shadow-sm"
                  onClick={handleResetFilter}
                  title="Clear All Filters"
                >
                  <FcClearFilters size={18} />
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CommonTeamsFilter;
