import ListLoading from "module/common/ListLoading";
import React from "react";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { BsBookmarkStar } from "react-icons/bs";
import {
  FaRegCalendarMinus,
  FaRegCalendarPlus,
  FaUserCircle,
} from "react-icons/fa";
import { HiOutlineStatusOnline } from "react-icons/hi";
import {
  MdDescription,
  MdOutlineDateRange,
  MdOutlineSubtitles,
} from "react-icons/md";
import ShowMore from "components/common/ShowMore";
const SprintInfo = ({ sprintData, loading }) => {
  return loading ? (
    <ListLoading style={{ height: "79vh", overflow: "hidden" }} />
  ) : (
    <div className="d-flex flex-column gap-3 h-100">
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <MdOutlineSubtitles size={24} className="text-secondary" />
          <span className="custom-text-muted-crm">TITLE</span>
        </div>
        <span className="fw-bold">{sprintData?.name}</span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <MdDescription size={18} className="text-secondary" />
          <span className="custom-text-muted-crm">DESCRIPTION</span>
        </div>
        <span className="fw-bold">
          <ShowMore
            content={sprintData?.description}
            contentLimit={70}
            uniqueKey={sprintData?.id}
          />
        </span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <AiOutlineCodeSandbox size={18} className="text-secondary" />
          <span className="custom-text-muted-crm">PROJECT</span>
        </div>
        <span className="fw-bold">{sprintData?.project_title}</span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <FaRegCalendarPlus size={15} className="text-secondary" />
          <span className="custom-text-muted-crm">START DATE</span>
        </div>
        <span className="fw-bold">
          {sprintData?.start_date_time_formatted
            ? sprintData?.start_date_time_formatted
            : "N/A"}
        </span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <FaRegCalendarMinus size={15} className="text-secondary" />
          <span className="custom-text-muted-crm">END DATE</span>
        </div>
        <span className="fw-bold">
          {sprintData?.end_date_time_formatted
            ? sprintData?.end_date_time_formatted
            : "N/A"}
        </span>
      </div>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center gap-1">
          <HiOutlineStatusOnline size={18} className="text-secondary" />
          <span className="custom-text-muted-crm">STATUS</span>
        </div>
        <span className="fw-bold">
          {sprintData?.status ? sprintData?.status : "N/A"}
        </span>
      </div>
      {sprintData?.remarks?.replace("<p><br></p>", "")?.trim()?.length > 0 && (
        <div className="d-flex flex-column gap-2">
          <div className="d-flex align-items-center gap-1">
            <BsBookmarkStar size={18} className="text-secondary" />
            <span className="custom-text-muted-crm">Remarks</span>
          </div>
          <span
            className="fw-bold"
            dangerouslySetInnerHTML={{
              __html: sprintData?.remarks?.replace("<p><br></p>", "").trim(),
            }}
          />
        </div>
      )}
      <div className="d-flex justify-content-between mt-auto">
        <div className="d-flex flex-column gap-1">
          <div className="d-flex align-items-center gap-1">
            <FaUserCircle size={18} className="text-secondary" />
            <span className="custom-text-muted-crm">CREATED BY</span>
          </div>
          <span className="fw-bold text-capitalize">
            {sprintData?.created_user_full_name}
          </span>
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex align-items-center gap-1">
            <MdOutlineDateRange size={18} className="text-secondary" />
            <span className="custom-text-muted-crm">CREATED AT</span>
          </div>
          <span className="fw-bold">{sprintData?.created_date}</span>
        </div>
      </div>
    </div>
  );
};

export default SprintInfo;
