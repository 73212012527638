import { Card, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import { leadAddCallFormKeys } from "helpers/formKeys";
import { FaPhoneAlt } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import { apiCall } from "helpers/apiCalls";
import removeRefData from "helpers/removeRefData";
import AddCalls from "./AddCalls";
import ListLoading from "module/common/ListLoading";
const CallsForm = ({ getData, type }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState(leadAddCallFormKeys);
  const [formError, setFormError] = useState({});
  const [queryParams] = useSearchParams();
  const show = queryParams.get("add_call") ? true : false;
  const edit_id = queryParams.get("edit_id");
  const [onSave, setOnSave] = useState(false);
  const { itemId, overviewId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const handleFieldChange = (e, action) => {
    setFormData(generateFormDataHelper(e, action, formData));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);
    formData.related_to_id = overviewId ? overviewId : itemId;
    formData.related_to_type =
      type === "LEAD" || overviewId ? "LEAD" : "OPPORTUNITY";
    const removeRefDataResults = removeRefData(formData);
    const newFormData = setNewFormData(removeRefDataResults);
    axios({
      method: edit_id ? "put" : "post",
      url: `crm/calls${edit_id ? "/" + edit_id : ""}`,
      data: newFormData,
      ...(!edit_id && {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }),
    })
      .then((response) => {
        setOnSave(false);
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          getData();
          navigate(-1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      })
      .catch((error) => {
        setOnSave(false);
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
  };

  const fetchData = async (edit_id) => {
    setIsLoading(true);
    const data = await apiCall({
      url: `crm/calls/${edit_id}`,
    });

    setFormData({
      call_to: data?.call_to,
      call_to_ref: {
        label:
          data.call_to_contact.phone_no +
          " - " +
          data.call_to_contact.first_name,
        value: data?.call_to,
      },
      end_time: data?.end_time,
      start_time: data?.start_time,
      out_call_result: data?.out_call_result,
      out_call_result_ref: {
        label: data.out_call_result,
        value: data.out_call_result,
      },
      purpose: data.purpose,
      purpose_ref: { label: data.purpose, value: data.purpose },
      status: data.status.toUpperCase(),
      status_ref: {
        label: data.status.toUpperCase(),
        value: data.status.toUpperCase(),
      },
      type: data.type,
      type_ref: { label: data.type, value: data.type },
    });

    setIsLoading(false);
  };
  useEffect(() => {
    if (edit_id && show) {
      fetchData(edit_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit_id]);

  return (
    <Modal show={show} size="lg" onHide={() => navigate(-1)}>
      <Card.Header className="rounded-0" style={{ backgroundColor: "#EDF2F9" }}>
        <Card.Title className="d-flex  align-items-center pt-2 pb-2">
          <FaPhoneAlt size={18} className="me-2 text-dark" />
          <div className="">{edit_id ? "EDIT" : "ADD"} CALL INFO</div>
        </Card.Title>
      </Card.Header>
      <Card className="p-3">
        {!isLoading ? (
          <AddCalls
            handleFieldChange={handleFieldChange}
            formData={formData}
            formError={formError}
            onSave={onSave}
            handleSubmit={handleSubmit}
            itemId={overviewId ? overviewId : itemId}
            type={type}
          />
        ) : (
          <ListLoading style={{ height: "35vh", overflow: "hidden" }} />
        )}
      </Card>
    </Modal>
  );
};

export default CallsForm;
