export const DateFormatVaildation = (date) => {
  const dateFormat = /^\d{4}-\d{2}-\d{2}$/;

  if (dateFormat.test(date)) {
    const [year, month, day] = date.split("-");

    const numericYear = parseInt(year, 10);
    const numericMonth = parseInt(month, 10);
    const numericDay = parseInt(day, 10);

    if (
      numericYear >= 1000 &&
      numericYear <= 9999 &&
      numericMonth >= 1 &&
      numericMonth <= 12 &&
      numericDay >= 1 &&
      numericDay <= 31
    ) {
      return true;
    }
  }

  return false;
};
