import React from "react";
import { BiCheckboxSquare } from "react-icons/bi";
import { BsDatabaseFillExclamation } from "react-icons/bs";
import { Link } from "react-router-dom";

const ActivityLog = ({ data }) => {
  return (
    <div className="h-100">
      <div className="d-flex justify-content-center h-100">
        <div className="main-card mb-3 card-custom-timeline">
          {data.length > 0 ? (
            <div
              className="vertical-timeline-custom vertical-timeline--animate-custom vertical-timeline--one-column"
              style={{ visibility: data.length > 0 ? "visible" : "hidden" }}
            >
              {data.map((item, key) => (
                <div
                  key={key}
                  className="vertical-timeline-item vertical-timeline-element-custom"
                >
                  <span className="vertical-timeline-element-icon-custom bounce-in">
                    <BiCheckboxSquare
                      className="badge-dot-xl-custom"
                      color={
                        item.event === "updated"
                          ? "orange"
                          : item.event === "created"
                          ? "green"
                          : "blue"
                      }
                    />
                  </span>
                  <div className="vertical-timeline-element-content bounce-in">
                    <h4 className="timeline-title">{item.description}</h4>
                    <p>
                      On <Link>{item.created_at_date}</Link>
                    </p>
                    <span className="vertical-timeline-element-date">
                      {item.created_at_time}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
              <BsDatabaseFillExclamation size={40} className="text-mute" />
              <span>No data found!</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;
