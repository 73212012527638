import { createContext } from "react";
import { settings } from "config";
const AppContext = createContext(settings);
export const AuthWizardContext = createContext();
export const DeleteContext = createContext({});
export const ExportContext = createContext([]);
export const StoreContext = createContext({
  companyPreferences: [],
  units: [],
  mbFlags: [],
  categories: [],
  brands: [],
  manufacturer: [],
  items: [],
  exchangeRates: [],
  fiscalYears: [],
  taxes: [],
  taxGroups: [],
  taxItems: [],
  salesAreas: [],
  salesTypes: [],
  settingsDropdownId: 0,
  suppliers: [],
  supplierContacts: [],
  shippingCompanies: [],
  prices: [],
  paymentTerms: [],
  modules: [],
  bankAccounts: [],
  costCenters: [],
  chartOfAccounts: [],
  salesQuotations: [],
  currency: [],
  journalEntries: [],
  userData: [],
  currentDate: [],
  latestVersion: [],
  paymentVoucher: [],
  transactionTypes: [],
  transactionTypesIsLoaded: false,
  transactionReference: [],
  dashboard_draggable: false,
  chartOfAccountsTypes: ["class", "group", "ledger", "subledger"],
  voucherTypes: ["DEBIT", "CREDIT"],
  personTypes: ["CUSTOMER", "SUPPLIER", "MISCELLANEOUS"],
  unitItems: [],
  globalConfigurations: {},
});

export default AppContext;
