import { React, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaDownload } from "react-icons/fa";

const ExportItemDownloadButton = ({ url, file_name }) => {
  const [loading, setLoading] = useState(false);

  const createFile = async (url) => {
    if (url) {
      setLoading(true);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", file_name);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setLoading(false);
    } else {
      console.error("download file url is missing");
    }
  };

  return (
    <Button
      variant="success"
      size="sm"
      className="d-flex flex-row justify-content-center align-items-center mt-2 me-2"
      disabled={loading}
      onClick={() => createFile(url)}
    >
      {loading ? (
        <Spinner
          animation="border"
          className="me-1"
          style={{ width: 12, height: 12 }}
        />
      ) : (
        <>
          <FaDownload size={10} />
          <span>Download</span>
        </>
      )}
    </Button>
  );
};

ExportItemDownloadButton.propTypes = {
  url: PropTypes.string.isRequired,
  file_name: PropTypes.string.isRequired,
};

export default ExportItemDownloadButton;
