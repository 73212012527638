import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";

import { AiOutlineMenu } from "react-icons/ai";

import { leadConversionReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import LeadConversionReportFilterSection from "../ReportFilterSection/LeadConversionReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";

const LeadConversionReport = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const [leadConversionReport, setLeadConversionReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(
    leadConversionReportFilterKeys
  );

  const keysToCheck = ["owner_id"];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const handleCanvas = () => setShow(!show);

  const getProjectReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    const filteredObject = removeRefData(removeEmptyFields(parsedFilterItems));
    try {
      const data = await apiCall({
        url: `crm/lead-convertion-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setLeadConversionReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}

            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/lead-conversion-report"
                filters={filterFields}
                setFilters={setFilterFields}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/lead-convertion-report/export"}
                  successMessage={"Lead Conversion Report Export Completed"}
                  initialMessage={"Lead Conversion Report Export Started"}
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <LeadConversionReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
              filterValuesExist={filterValuesExist}
            />
          </div>
          <div className="d-flex flex-column gap-2 mt-3">
            <span className="fs--1 align-self-center">{company_name}</span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Lead Conversion Report
            </Link>
          </div>
          <div className="mt-4">
            {!dataProcessing ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#00185894",
                      color: "whitesmoke",
                    }}
                  >
                    <th className="fw-bold text-light pt-2 pb-2">#</th>
                    <th className="fw-bold text-light pt-2 pb-2">OWNER</th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      OWNER EMAIL
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      OWNER CONTACT
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      TOTAL CONVERTED
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      TOTAL LEADS
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      CONVERTION RATE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(leadConversionReport).length > 0 &&
                  leadConversionReport.data.length > 0 ? (
                    leadConversionReport.data.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td className="text-capitalize">{item.owner_name}</td>
                        <td>{item.owner_email}</td>
                        <td>{item.owner_phone}</td>
                        <td>{item.converted_leads_count}</td>
                        <td>{item.owners_total_leads}</td>
                        <td>{item.conversion_rate}</td>
                      </tr>
                    ))
                  ) : (
                    <tr height="100px">
                      <td colSpan={7} className="text-center">
                        <span>No Data Found</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <div
                className="d-flex justify-content-center align-item-center"
                style={{ minHeight: "48vh" }}
              >
                <LoadingScreen message="Loading Time Tracker Report" />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {leadConversionReport &&
            leadConversionReport.links &&
            !dataProcessing && (
              <ReportPagination
                data={leadConversionReport}
                url="/reports/lead-conversion-report"
                search={allQueryParams}
              />
            )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default LeadConversionReport;
