import BackButton from "components/common/BackButton";
import MultiSelectProjectMembers from "components/form/MultiSelectProjectMembers";
import { SaveButton } from "module/common/Buttons/CommonButton";
import PropTypes from "prop-types";
import { Form, Row } from "react-bootstrap";
const AddMemberForm = ({
  handleFieldChange,
  formData,
  formError,
  onSave,
  handleSubmit,
  members,
}) => {
  return (
    <Form>
      <Row>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label className="require-data">Members</Form.Label>
          <MultiSelectProjectMembers
            name="members"
            onChange={handleFieldChange}
            value={formData?.members_ref}
            placeholder="Select Members"
            excludeUser={members}
            isInvalid={!!formError.members}
          />
          <Form.Control.Feedback type="invalid">
            {formError.members}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Form.Group className="d-flex justify-content-end gap-1 mt-3">
        <SaveButton
          handleSubmit={handleSubmit}
          onSave={onSave}
          disabled={formData.members_ref.length === 0}
        />
        {!onSave && (
          <BackButton variant={"danger"} size={"sm"}>
            Cancel
          </BackButton>
        )}
      </Form.Group>
    </Form>
  );
};
AddMemberForm.prototype = {
  handleFieldChange: PropTypes.func,
  formData: PropTypes.any,
  formError: PropTypes.any,
  onSave: PropTypes.bool,
  handleSubmit: PropTypes.func,
  assignees: PropTypes,
};
export default AddMemberForm;
