import axios from "axios";
import AppDatePicker from "components/app-date-picker/AppDatePicker";
import SelectCustomer from "components/form/SelectCustomer";
import { apiCall } from "helpers/apiCalls";
import { estimationFormKeys } from "helpers/formKeys";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import DataProcessing from "module/common/DataProcessing";
import { showToast } from "module/common/Toast/toast";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { BsCalculator } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import EstimationDetailsTable from "./EstimationDetails/EstimationDetailsTable";
import SelectEstimationStatus from "components/form/SelectEstimationStatus";
import ErrorAlert from "module/common/ErrorAlert";
import { TbListDetails } from "react-icons/tb";
import { MdAddCircle } from "react-icons/md";
import { FaTimesCircle } from "react-icons/fa";

function EstimationForm() {
  const [formData, setFormData] = useState(estimationFormKeys);
  const [formError, setFormError] = useState({});
  let navigate = useNavigate();
  const { itemId } = useParams();
  const [dataProcessing, setDataProcessing] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [detailsAction, setDetailsAction] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [newRowData, setNewRowData] = useState({
    id: "",
    title: "",
    description: "",
    amount: "",
    stock_id: "",
    stock_id_ref: { label: "", value: "" },
    quantity: "",
  });

  useEffect(() => {
    if (itemId) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);
  const getData = async (page = 1) => {
    setDataProcessing(true);
    const data = await apiCall({
      url: "crm/estimation/" + itemId,
      params: {
        page: page,
      },
    });
    let estimationData = {
      date: data?.date,
      valid_until: data?.valid_until,
      notes: data.notes,
      status: data.status,
      status_ref: {
        label: data.status,
        value: data.status,
      },
      customer_id: data.customer_id,
      customer_id_ref: {
        label: data.customer.name,
        value: data.customer_id,
      },
    };

    estimationData.details = data.details.map((item) => ({
      id: item.id,
      stock_id: item.stock_id,
      stock_id_ref: { label: item.stock_id, value: item.stock_id },
      title: item.title,
      description: item.description,
      unit_id: item.unit_id,
      amount: item.amount,
      quantity: item.quantity,
    }));

    setFormData(estimationData);
    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      if (e.target.name === "status") {
        value = e.target.checked ? "active" : "inactive";
      } else {
        value = e.target.value;
      }
      newFormData = {
        ...formData,
        [e.target.name]: value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    setFormError({});
    setOnSave(true);
    axios({
      method: itemId ? "put" : "post",
      url: `crm/estimation${itemId ? "/" + itemId : ""}`,
      headers: {
        Accept: "application/json",
      },
      data: formData,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(`/estimation`, {
            replace: true,
          });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
          showToast(error.response.data.message, "error");
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  const handleDetailsVisibility = () => {
    setDetailsAction(!detailsAction);
    setNewRowData({
      id: "",
      title: "",
      description: "",
      amount: "",
      stock_id: "",
      stock_id_ref: { label: "", value: "" },
      quantity: "",
    });
    setEditItem(null);
  };

  return (
    <Card className="flex-fill mb-3 mt-3">
      <Card.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <BsCalculator size={22} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {itemId ? "Update" : "Create"} Estimation
          </span>
        </div>
      </Card.Header>
      <DataProcessing show={dataProcessing} />
      <Form className="min-vh-75">
        <Card.Body className="form-wrapper">
          {!Object.keys(formError).length === 0 ? (
            <ErrorAlert setFormError={setFormError} formError={formError} />
          ) : (
            ""
          )}
          <Row className="gx-6">
            <Col xl={4} md={6} sm={12}>
              <Row className="mb-3 gx-0">
                <Col xl={3} md={4} sm={12}>
                  <Form.Label className="require-data">Date</Form.Label>
                </Col>
                <Col xl={9} md={8} sm={12}>
                  <AppDatePicker
                    name="date"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData?.date}
                    isInvalid={!!formError.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.date}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3 gx-0">
                <Col xl={3} md={4} sm={12}>
                  <Form.Label className="require-data">Client</Form.Label>
                </Col>
                <Col xl={9} md={8} sm={12}>
                  <SelectCustomer
                    name="customer_id"
                    value={formData?.customer_id_ref}
                    error={formError?.customer_id}
                    handleFieldChange={handleFieldChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.customer_id}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
            <Col xl={4} md={6} sm={12}>
              <Row className="mb-3 gx-0">
                <Col xl={3} md={4} sm={12}>
                  <Form.Label className="require-data">Valid Until</Form.Label>
                </Col>
                <Col xl={9} md={8} sm={12}>
                  <AppDatePicker
                    name="valid_until"
                    yearPlaceholder="yyyy"
                    monthPlaceholder="mm"
                    dayPlaceholder="dd"
                    onChange={handleFieldChange}
                    value={formData?.valid_until}
                    isInvalid={!!formError.valid_until}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.valid_until}
                  </Form.Control.Feedback>
                </Col>
              </Row>
              <Row className="mb-3 gx-0">
                <Col xl={3} md={4} sm={12}>
                  <Form.Label className="require-data">Status</Form.Label>
                </Col>
                <Col xl={9} md={8} sm={12}>
                  <SelectEstimationStatus
                    value={formData.status_ref}
                    name="status"
                    handleFieldChange={handleFieldChange}
                    error={!!formError.status}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.status}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
            <Col xl={4} md={6} sm={12}>
              <Row className="mb-3 gx-0">
                <Col xl={3} md={4} sm={12}>
                  <Form.Label className="require-data">Notes</Form.Label>
                </Col>
                <Col xl={9} md={8} sm={12}>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    name="notes"
                    onChange={handleFieldChange}
                    value={formData.notes}
                    placeholder="Enter Notes"
                    isInvalid={!!formError.notes}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError.notes}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
        <div className="">
          <Card className="shadow-none">
            <Card.Header className="fs--3 border-top border-bottom rounded-0">
              <div className="d-flex justify-content-start align-items-center">
                <span>
                  <TbListDetails size={18} className="me-1" /> ESTIMATION
                  DETAILS
                </span>
                <span className="ms-3">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-1`}>
                        {detailsAction ? (
                          <span>Close Details</span>
                        ) : (
                          <span>Add Details</span>
                        )}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="transparent"
                      className={`${
                        detailsAction ? "text-danger" : "text-success"
                      } p-0`}
                      style={{ cursor: "pointer", boxShadow: "none" }}
                      onClick={handleDetailsVisibility}
                    >
                      {detailsAction ? (
                        <FaTimesCircle size={19} />
                      ) : (
                        <MdAddCircle size={23} />
                      )}
                    </Button>
                  </OverlayTrigger>
                </span>
              </div>
            </Card.Header>
            <Card.Body className="rounded-0 p-0">
              <EstimationDetailsTable
                entries={formData.details}
                setFormData={setFormData}
                detailsAction={detailsAction}
                setDetailsAction={setDetailsAction}
                newRowData={newRowData}
                setNewRowData={setNewRowData}
                editItemId={editItem}
                setEditItemId={setEditItem}
                formError={formError}
              />
            </Card.Body>
          </Card>
        </div>
      </Form>
      <Card.Footer className="d-flex flex-row gap-2 justify-content-start border-top">
        <Form.Group className="d-flex justify-content-end">
          <SaveButton handleSubmit={handleSubmit} onSave={onSave} id={itemId} />
        </Form.Group>
        <Form.Group>
          <CancelButton handleCancel={handleCancel} onSave={onSave} />
        </Form.Group>
      </Card.Footer>
    </Card>
  );
}

export default EstimationForm;
