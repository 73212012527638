import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  Image,
  Row,
  Spinner,
} from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import ProfileImage from "../../../assets/img/Avathar/profile.png";
import ProfileImageShe from "../../../assets/img/Avathar/she.jpg";
import { BiShuffle } from "react-icons/bi";
import Attachments from "module/common/ViewPages/Common/Attachments/Attachments";
import SelectProject from "components/form/SelectProject";
import SelectTaskStatus from "components/form/SelectTaskStatus";
import SelectUsers from "components/form/SelectUsers";
import MultiSelectCollaborator from "components/form/MultiSelectCollaborator";
import MutiSelectTaskLabel from "components/form/MutiSelectTaskLabel";
import TextEditor from "module/common/TextEditor/TextEditor";
import BackButton from "components/common/BackButton";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import { tasksFormKeys } from "helpers/formKeys";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import removeRefData from "helpers/removeRefData";
import AppTimePicker from "components/app-time-picker/AppTimePicker";

const TicketConversionForm = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [formData, setFormData] = useState(tasksFormKeys);
  const [formError, setFormError] = useState({});
  const [onSave, setOnSave] = useState(false);

  const getTicketData = async () => {
    setFormError({});
    setLoading(true);
    const data = await apiCall({
      url: "crm/ticket/" + ticketId,
    });

    setTicketData(data);
    setFormData({
      ...formData,
      project_id: data.project_id,
      project_id_ref: {
        label: data?.project,
        value: data.project_id,
      },
      title: data.title,
      description: data.description,
      start_date: data.ticket_date_time,
      end_date: data.end_date_time,
      assigned_to: data.assigned_to,
      assigned_to_ref: {
        label: data?.assignee ?? "",
        value: data?.assigned_to ?? "",
      },
      status: "to_do",
      status_ref: { label: "To Do", value: "to_do" },
    });

    setLoading(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (
        action.action === "select-option" ||
        action.action === "remove-value"
      ) {
        if (action.name === "labels" || action.name === "collaborators") {
          const labelArray = e.map((item) => ({ id: item.value }));
          value = labelArray;
        } else {
          value = e.code ? e.code : e.value;
        }
      } else if (action.action === "clear") {
        value = [];
      } else if (action.action === "text-editor") {
        value = action.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  useEffect(() => {
    getTicketData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData({
      ...formData,
      ticket_id: ticketId,
    });

    axios({
      method: "post",
      url: `crm/task`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");
          navigate(`/tasks/list/overview/${response.data.data.id}`);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  return (
    <div className="d-flex flex-column" style={{ height: "91vh" }}>
      {!loading && Object.keys(ticketData).length > 0 ? (
        <>
          <Card>
            <Card.Header className="d-flex">
              <IconButton
                onClick={() => navigate(-1)}
                variant="falcon-default"
                size="sm"
                icon="arrow-left"
              />
              <IconButton variant="falcon-default" size="sm" className="ms-3">
                <span className="fw-bold">Ticket</span>
                <span
                  className="badge ms-2"
                  style={{
                    backgroundColor:
                      ticketData.status === "open"
                        ? "#76ccf4"
                        : ticketData.status === "new"
                        ? "#a092fd9c"
                        : ticketData.status === "pending"
                        ? "#fdcd2ac2"
                        : ticketData.status === "awaiting_business_input"
                        ? "#ffaa0bb0"
                        : ticketData.status === "with_client_review"
                        ? "#ffad6d"
                        : ticketData.status === "defered"
                        ? "#f78989"
                        : ticketData.status === "closed"
                        ? "#6cd75d"
                        : "#fff",
                  }}
                >
                  <span className="fw-bold text-capitalize">
                    {ticketData.status === "awaiting_business_input"
                      ? "Await"
                      : ticketData.status === "with_client_review"
                      ? "Review"
                      : ticketData.status}
                  </span>
                </span>
                <BiShuffle size={20} className="ms-2 me-2" />
                <span className="fw-bold">Task</span>
                <Badge
                  bg={
                    formData.status === "to_do"
                      ? "primary"
                      : formData.status === "in_progress"
                      ? "info"
                      : formData.status === "done"
                      ? "success"
                      : "warning"
                  }
                  text="white"
                  className="ms-2 text-capitalize"
                >
                  {formData.status.replace("_", " ")}
                </Badge>
              </IconButton>
            </Card.Header>
          </Card>
          <Row className="g-3 mt-0 flex-fill d-flex">
            <Col md={5} className="flex-fill">
              <Card className="h-100">
                <Card.Header className="fw-bold border-bottom ps-3">
                  <Card.Title className="d-flex justify-content-between">
                    <span>Title</span>
                    <span className="ms-auto">
                      <Badge
                        bg={
                          ticketData.priority === "high"
                            ? "danger"
                            : ticketData.priority === "medium"
                            ? "warning"
                            : "success"
                        }
                        className="ps-3 pe-3 text-capitalize"
                      >
                        {ticketData.priority}
                      </Badge>
                    </span>
                  </Card.Title>
                  {ticketData.title}
                </Card.Header>
                <Card.Body className="p-0">
                  <div className="d-flex flex-column p-3">
                    <span className="fs--2">Description</span>
                    <span className="">
                      {ticketData.description ? ticketData.description : "N/A"}
                    </span>
                  </div>
                  <div className="mt-2">
                    <div className="d-flex flex-row">
                      <div className="p-3 border border-start-0 border-bottom-0 w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Project</span>
                          <span className="fw-bold text-capitalize">
                            {ticketData?.project ?? ""}
                          </span>
                        </div>
                      </div>
                      <div className="p-3 border-top w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Client</span>
                          <span>
                            <Image
                              className="custom-image-crm"
                              src={ticketData?.customer_image ?? ProfileImage}
                              roundedCircle
                              alt="?"
                              loading="lazy"
                              height={"30vh"}
                              width={"30vh"}
                            />
                            <span className="ms-1 fw-bold text-capitalize">
                              {ticketData?.customer_name}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-3 border-top border-end border-bottom w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Assignee</span>
                          <span>
                            <Image
                              className="custom-image-crm"
                              src={
                                ticketData?.assignee_image ?? ProfileImageShe
                              }
                              roundedCircle
                              alt="?"
                              loading="lazy"
                              height={"30vh"}
                              width={"30vh"}
                            />
                            <span className="ms-1 fw-bold text-capitalize">
                              {ticketData?.assignee}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="p-3 border-top border-bottom w-50">
                        <div className="d-flex flex-column">
                          <span className="fs--2">Ticket Type</span>
                          <span className="ms-1 fw-bold text-capitalize">
                            {ticketData?.ticket_type ?? ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="m-3 d-flex justify-content-between">
                    <div className="d-flex flex-column">
                      <span className="fs--2">Origin</span>
                      <span className="ms-1 fw-bold text-capitalize">
                        {ticketData.ticket_origin}
                      </span>
                    </div>
                    <div className="d-flex flex-column">
                      <span className="fs--2">Last Activity</span>
                      <span className="ms-1 fw-bold text-capitalize">
                        {ticketData.updated_at}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{ maxHeight: "29vh", overflow: "auto" }}
                    className="mb-3"
                  >
                    <Attachments
                      itemId={ticketId}
                      type="TICKET"
                      fetchUrl={`crm/ticket-attachments/${ticketData.id}`}
                      attachmentControl={false}
                      itemsClassName="border-start-0 border-end-0"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={7} className="d-flex flex-column">
              <Card className="flex-fill">
                <Card.Body>
                  <Form id="task-form" onFocus={() => setFormError({})}>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput2"
                        >
                          <Form.Label className="require-data">
                            Title
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={4}
                            name="title"
                            onChange={handleFieldChange}
                            value={formData.title}
                            isInvalid={!!formError.title}
                            onClick={() => setFormError({})}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.title}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Label className="require-data">
                            Project
                          </Form.Label>
                          <SelectProject
                            name="project_id"
                            value={formData.project_id_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.project_id}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.project_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            Start Date
                          </Form.Label>
                          <AppTimePicker
                            name="start_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            onChange={handleFieldChange}
                            value={formData.start_date}
                            isInvalid={!!formError.start_date}
                            showDefaultDate={true}
                          />
                          <Form.Control.Feedback type="is-invalid">
                            {formError.start_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            End Date
                          </Form.Label>
                          <AppTimePicker
                            name="end_date"
                            yearPlaceholder="yyyy"
                            monthPlaceholder="mm"
                            dayPlaceholder="dd"
                            hourPlaceholder="hh"
                            minutePlaceholder="mm"
                            onChange={handleFieldChange}
                            value={formData.end_date}
                            isInvalid={!!formError.end_date}
                            showDefaultDate={true}
                          />
                          <Form.Control.Feedback type="is-invalid">
                            {formError.end_date}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label className="require-data">
                            Status
                          </Form.Label>
                          <SelectTaskStatus
                            value={formData.status_ref}
                            name="status"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.status}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.status}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Assignee</Form.Label>
                          <SelectUsers
                            name="assigned_to"
                            value={formData.assigned_to_ref}
                            handleFieldChange={handleFieldChange}
                            error={formError.assigned_to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.assigned_to}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col x={6}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label>Collaborator</Form.Label>
                          <MultiSelectCollaborator
                            name="collaborators"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.collaborators}
                            value={formData.collaborators_ref}
                            excludeUser={formData.assigned_to}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Label</Form.Label>
                          <MutiSelectTaskLabel
                            name="labels"
                            handleFieldChange={handleFieldChange}
                            error={!!formError.label}
                            value={formData.labels_ref}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.label}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput3"
                        >
                          <Form.Label className="require-data">
                            Description
                          </Form.Label>
                          <TextEditor
                            name="description"
                            handleFieldChange={handleFieldChange}
                            value={formData.description}
                            isInvalid={!!formError.description}
                          />
                          <Form.Control.Feedback type="invalid">
                            {formError.description}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Card.Body>
                <Card.Footer>
                  <div className="d-flex gap-1 pt-3">
                    <Button
                      variant="success"
                      size="sm"
                      onClick={(e) => {
                        handleSubmit(e);
                      }}
                    >
                      {onSave ? (
                        <Spinner style={{ width: "18px", height: "18px" }} />
                      ) : (
                        " Convert"
                      )}
                    </Button>
                    <BackButton variant={"danger"} size={"sm"}>
                      Cancel
                    </BackButton>
                  </div>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <ListLoading />
      )}
    </div>
  );
};

export default TicketConversionForm;
