import React, { useContext, useEffect, useState } from "react";
import {
  Outlet,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
  Link,
} from "react-router-dom";
import qs from "qs";
import {
  Button,
  Card,
  Dropdown,
  DropdownButton,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { FaAngleDown, FaCircle, FaUserEdit } from "react-icons/fa";
import { BiTask } from "react-icons/bi";
import "./TaskTable.scss";
import { apiCall } from "helpers/apiCalls";
import { taskFilters } from "module/common/helpers/taskCommonFilters";
import { DeleteContext, AuthWizardContext } from "context/Context";
import {
  AddButton,
  DeleteButton,
  EditButton,
  TimerActionButton,
} from "module/common/Buttons/AppButtons";
import FilterButton from "components/filter/FilterButton";
import removeRefData from "helpers/removeRefData";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import UserProfile from "module/common/Offcanvas/UserProfile/UserProfile";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import LoadingOverlay from "components/common/LoadingOverlay/LoadingOverlay";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";
import useAxisproPermission from "hooks/useAxisproPermission";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import { TbFilterDown, TbFilterUp } from "react-icons/tb";
import CommonTaskFilter from "./SupportingComponents/CommonTaskFilter";
import { commonTaskFilterFormKeys } from "helpers/formKeys";
import ExportButton from "components/export-button/ExportButton";
import AddAssigneeModal from "../TaskDashboard/Common/AddAssigneeModal";
import TimerConfirm from "module/common/Timer/TimerConfirm";

const TaskTable = ({
  mainCardClassName = "",
  showHeader = true,
  renderFrom = "task_list",
  renderFromId,
}) => {
  let navigate = useNavigate();
  let { user, dispatch: authDispatch } = useContext(AuthWizardContext);
  let loggedUsCustomer = user?.user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const { overviewId, itemInfoId } = useParams();
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState({});
  const [filters] = useState(taskFilters);
  const dataProcessStatus =
    Object.keys(tasks).length > 0 && Object.keys(tasks.data).length > 0;
  const [userProfile, setUserProfile] = useState();
  const [showUserProfileArea, setShowUserProfileArea] = useState(false);
  const [statusArray, setStatusArray] = useState([]);
  const [statusLoading, setStatusLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const commonSearchParams = createSearchParams({ ...allQueryParams });
  const activeRoute =
    renderFrom === "task_list"
      ? "/tasks/list"
      : renderFrom === "project"
      ? `/project/info/${itemInfoId}`
      : `/sprints/info/${itemInfoId}`;
  const [mainFilterVisibility, setMainFilterVisibility] = useState(true);
  const {
    status_filter,
    date_range,
    from_date,
    to_date,
    date_range_for,
    project_id,
    project_id_ref,
    sprint_id,
    sprint_id_ref,
    assignee,
    assignee_ref,
    priority,
    priority_ref,
    created_by,
    created_by_ref,
    task_no,
    title,
    unassigned,
    month,
  } = allQueryParams;
  const [filterFields, setFilterFields] = useState(commonTaskFilterFormKeys);
  const [
    createPermission,
    updatePermission,
    deletePermission,
    showPermission,
    showProjectPermission,
    showSprintPermission,
    updateTaskStatusPermission,
  ] = [
    "store-task",
    "update-task",
    "delete-task",
    "show-task",
    "show-project",
    "show-sprint",
    "update-task-status",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const [assigneeUpdateObj, setAssigneeUpdateObj] = useState({
    status: false,
    assigneeId: null,
    task_id: null,
  });
  const [loadingTimer, setLoadingTimer] = useState(false);
  const [show, setShow] = useState(false);
  const [timerRunningTask, setTimerRunningTask] = useState({});
  const handleClose = () => {
    setShow(false);
    setTimerRunningTask({});
  };
  const handleTimerConfirm = (task) => {
    setShow(true);
    setTimerRunningTask(task);
  };

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/task/" + id,
        title: "Delete Task",
        message: "Are you sure you want to delete this task?",
        onSuccess: () => {
          const dataWithoutDeletedItem = tasks.data.filter(
            (account) => account.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              `${activeRoute}?${createSearchParams({
                ...allQueryParams,
                page: pageNumber - 1,
              })}`
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setTasks((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getApiData = async (page) => {
    const statusFilterTemp = status_filter ? status_filter.split("/") : [];
    const dynamicFilterTemp = filterItems
      ? removeRefData(createFilterArray(filterItems), "array")
      : null;

    const filterParamObject = {
      page: page ? page : pageNumber,
      filters: dynamicFilterTemp,
      status_filter: statusFilterTemp,
      project_id: project_id,
      sprint_id: sprint_id,
      assignee: assignee,
      priority: priority,
      created_by: created_by,
      task_no: task_no,
      title: title,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      unassigned: unassigned,
    };
    filterParamObject.requested_from = renderFrom;
    if (renderFrom === "project") {
      filterParamObject.project_id = itemInfoId;
    } else if (renderFrom === "sprint") {
      filterParamObject.sprint_id = itemInfoId;
    }

    const data = await apiCall({
      url: "crm/task",
      params: filterParamObject,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });

    return data;
  };

  const getData = async (page) => {
    setIsLoading(true);
    try {
      let data = await getApiData(page);
      if (data?.data?.length < 1 && pageNumber > 1) {
        data = await getApiData(pageNumber - 1);
      }
      setTasks(data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    status_filter,
    date_range_for,
    project_id,
    sprint_id,
    assignee,
    priority,
    created_by,
    task_no,
    title,
    date_range,
    unassigned,
    month,
    to_date,
    from_date,
  ]);

  useEffect(() => {
    const getDataItem = async () => {
      const data = await apiCall({
        url: "crm/task-status",
      });
      setStatusArray(data);
    };
    getDataItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const statusFilterTemp = status_filter ? status_filter.split("/") : [];
    setFilterFields((previous) => ({
      ...previous,
      status_filter: statusFilterTemp,
      status_filter_ref: statusFilterTemp.map((item) => ({
        label: item.replace("_", " "),
        value: item,
      })),
      project_id: project_id ? project_id : "",
      project_id_ref: project_id ? JSON.parse(project_id_ref) : "",
      sprint_id: sprint_id ? sprint_id : "",
      sprint_id_ref: sprint_id ? JSON.parse(sprint_id_ref) : "",
      assignee: assignee ? assignee : "",
      assignee_ref: assignee ? JSON.parse(assignee_ref) : "",
      priority: priority ? priority : "",
      priority_ref: priority
        ? JSON.parse(priority_ref)
        : { label: "", value: "" },
      created_by_ref: created_by ? JSON.parse(created_by_ref) : "",
      task_no: task_no ? task_no : "",
      title: title ? title : "",
      date_range: date_range ? date_range : "",
      date_range_for: date_range_for ? date_range_for : "",
      from_date: from_date ? from_date : "",
      to_date: to_date ? to_date : "",
      unassigned: unassigned ? parseInt(unassigned) : "",
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status_filter]);

  const handleUserProfileArea = (id) => {
    setUserProfile(id);
    setShowUserProfileArea(true);
  };

  const handleStatusUpdate = async (e, status, id) => {
    e.preventDefault();
    setStatusLoading(true);
    if (status) {
      try {
        const response = await axios({
          method: "post",
          url: `crm/task-status/update`,
          params: { task_id: id, status: status },
        });

        if (response.data.success === true) {
          showToast(response.data.message, "success");
          let updatedListData = await getApiData(pageNumber);
          if (updatedListData?.data?.length < 1 && pageNumber > 1) {
            updatedListData = await getApiData(pageNumber - 1);
          }
          setTasks(updatedListData);
          setStatusLoading(false);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setStatusLoading(false);
      } catch (error) {
        let errorMessage =
          "Something went wrong, please refresh the page and try again.";
        let showToastError = true;
        if (error?.response?.data?.message) {
          const validationError = error?.response?.data?.data?.errors;
          if (validationError) {
            if (validationError.collaborator_working) {
              showToast(validationError.collaborator_working[0], "error");
              showToastError = false;
            } else if (validationError.task_id) {
              showToast(validationError.task_id[0], "error");
              showToastError = false;
            } else if (validationError.status) {
              showToast(validationError.status[0], "error");
              showToastError = false;
            }
          }
        }
        if (showToastError) {
          showToast(errorMessage, "error");
        }

        setStatusLoading(false);
      }
    }
  };

  const generateLink = (taskId) => {
    let link = "";
    switch (renderFrom) {
      case "sprint":
        link = `/sprints/info/${itemInfoId}/task/${taskId}`;
        break;
      case "project":
        link = `/project/info/${itemInfoId}/task/${taskId}`;
        break;
      default:
        link = `/tasks/list/info/${taskId}`;
        break;
    }

    const queryParamsTemp = createSearchParams(allQueryParams);
    return `${link}?${queryParamsTemp}`;
  };

  const handleAssigneeUpdate = (id, task_id) => {
    setAssigneeUpdateObj({ status: true, assigneeId: id, task_id: task_id });
  };

  const handleCloseAssigneeUpdate = () => {
    setAssigneeUpdateObj({ status: false, assigneeId: "", task_id: "" });
  };

  const handleTimerError = (error) => {
    if (error.response.data && error.response.data.message) {
      const validation_error =
        error.response.data &&
        error.response.data.data &&
        error.response.data.data.errors
          ? error.response.data.data.errors
          : null;
      showToast(validation_error.task_id[0]);
    } else {
      showToast(
        "Something went wrong, please refresh the page and try again.",
        "error"
      );
    }
    setLoadingTimer(false);
  };

  const handleTimer = async () => {
    setLoadingTimer(true);

    try {
      const startOrStopTimerUrl = timerRunningTask.running_status
        ? `crm/stop-timer/${timerRunningTask.id}`
        : `crm/start-timer/${timerRunningTask.id}`;

      const response = await axios.post(startOrStopTimerUrl);
      const { data } = response;

      if (data.success === true) {
        const runningTasksData = await axios.get("crm/running-tasks-info");
        const runningTasks = runningTasksData.data;
        if (runningTasks.success === true) {
          handleClose();
          let updatedData = {
            ...user,
            running_tasks_count: runningTasks?.data?.count,
            running_task_id: data?.data?.task_id,
          };
          authDispatch({ type: "UPDATE", payload: { ...updatedData } });
        } else {
          showToast(
            `Something went wrong, please refresh the page and try again.`,
            "error"
          );
        }
        setLoadingTimer(false);
      } else {
        showToast(
          "Something went wrong, please refresh the page and try again.",
          "error"
        );
        setLoadingTimer(false);
      }
    } catch (error) {
      handleClose();
      handleTimerError(error);
    }
  };

  useEffect(() => {
    if (dataProcessStatus) {
      setTasks((prevState) => ({
        ...prevState,
        data: tasks.data.map((task) => {
          if (task.id === user.running_task_id) {
            return {
              ...task,
              running_status: user.running_tasks_count > 0 ? true : false,
            };
          }
          return task;
        }),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const removeHtmlTagsAndReplaceWithSpace = (str) => {
    if (!str) return str;
    return str
      .replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
  };

  return (
    <>
      <Outlet context={[getData]} />
      {!overviewId && (
        <Card
          className={`flex-fill  ${
            renderFrom !== "sprint" && "mb-3 mt-3"
          } ${mainCardClassName}`}
        >
          {statusLoading && <LoadingOverlay />}

          <Card.Header className="p-0">
            <div className="d-flex justify-content-between flex-wrap border-bottom p-3">
              {showHeader && (
                <div className="fs-1 text-uppercase text-dark d-flex justify-content-start align-items-center gap-1">
                  <BiTask size={25} />
                  Task Management
                </div>
              )}

              <div
                className={`d-flex justify-content-end align-items-center gap-1 ${
                  showHeader ? "" : "w-100"
                }`}
                style={{ visibility: isLoading ? "hidden" : "visible" }}
              >
                {loggedUsCustomer ? null : (
                  <ExportButton
                    url="crm/task-export"
                    initialMessage={"Task export started"}
                    successMessage={"Task export completed"}
                    file_type={"pdf"}
                    variant={"danger"}
                    filters={filterFields}
                  />
                )}
                {createPermission && (
                  <AddButton
                    className="shadow"
                    route={
                      renderFrom === "sprint"
                        ? `/sprints/info/${itemInfoId}/task/add?${commonSearchParams}${
                            renderFrom === "sprint"
                              ? `&project=${renderFromId}`
                              : ""
                          }`
                        : renderFrom === "project"
                        ? `/project/info/${itemInfoId}/task/add?${commonSearchParams}`
                        : `add?${commonSearchParams}`
                    }
                    variant="success"
                    title="Add new task"
                  />
                )}

                <Button
                  size="sm"
                  variant={"light"}
                  className="shadow"
                  onClick={(e) => {
                    setMainFilterVisibility(!mainFilterVisibility);
                  }}
                  title={mainFilterVisibility ? "Hide filter" : "Show filter"}
                >
                  {mainFilterVisibility ? (
                    <TbFilterUp size={18} color="#f53838" />
                  ) : (
                    <TbFilterDown size={18} color="#0ece0e" />
                  )}
                </Button>
              </div>
            </div>

            <div style={{ display: mainFilterVisibility ? "block" : "none" }}>
              <CommonTaskFilter
                filterFields={filterFields}
                setFilterFields={setFilterFields}
                renderFrom={renderFrom}
              />
            </div>
          </Card.Header>
          <>
            <SimpleBar
              className={
                tasks && tasks.meta && tasks.meta.last_page === 1
                  ? "simplebar-style-in-tab-view-without-pagination"
                  : "simplebar-style-in-tab-view"
              }
            >
              <Card.Body className="p-0">
                <TimerConfirm
                  show={show}
                  handleClose={handleClose}
                  loading={loadingTimer}
                  handleTimer={handleTimer}
                  timerRunningTask={timerRunningTask.id}
                />
                <AddAssigneeModal
                  show={assigneeUpdateObj.status}
                  assignee_id={assigneeUpdateObj.assigneeId}
                  getData={getData}
                  taskId={assigneeUpdateObj.task_id}
                  handleClose={handleCloseAssigneeUpdate}
                  title="UPDATE ASSIGNEE"
                />
                <Table striped hover className="task-custom-table-crm">
                  <tbody className="task-custom-table-body-crm">
                    {!isLoading && dataProcessStatus ? (
                      tasks.data.map((task, index) => (
                        <tr key={index}>
                          <td width={"1%"}>#{task.task_number}</td>
                          <td style={{ maxWidth: "40vh" }}>
                            <span className="custom-text-muted-crm">Title</span>
                            <br />
                            <div className="d-flex flex-column gap-1">
                              {showPermission ? (
                                <Link
                                  className="text-muted text-decoration-underline"
                                  to={generateLink(task.id)}
                                >
                                  <span className="fw-bold">
                                    {task.title.length > 15
                                      ? `${task.title.slice(0, 15)}...`
                                      : task.title}
                                  </span>
                                </Link>
                              ) : (
                                <span className="fw-bold">
                                  {task.title.length > 15
                                    ? `${task.title.slice(0, 15)}...`
                                    : task.title}
                                </span>
                              )}

                              <span className="fs--2 text-wrap">
                                {removeHtmlTagsAndReplaceWithSpace(
                                  task?.description
                                )?.length > 15
                                  ? `${removeHtmlTagsAndReplaceWithSpace(
                                      task?.description
                                    )?.slice(0, 15)}...`
                                  : removeHtmlTagsAndReplaceWithSpace(
                                      task?.description
                                    )}
                              </span>
                            </div>
                          </td>
                          {loggedUsCustomer ||
                          renderFrom === "project" ||
                          renderFrom === "sprint" ? null : (
                            <td>
                              <span className="custom-text-muted-crm">
                                Project
                              </span>
                              <br />
                              <span className="text-uppercase">
                                {showProjectPermission ? (
                                  <Link
                                    className="custom-link-style-crm"
                                    to={`/project/info/${task?.project_id}?tab=overview`}
                                  >
                                    {task?.project_title}
                                  </Link>
                                ) : (
                                  <span>{task?.project_title}</span>
                                )}
                              </span>
                            </td>
                          )}
                          {loggedUsCustomer ||
                          renderFrom === "sprint" ? null : (
                            <td>
                              <span className="custom-text-muted-crm">
                                Sprint
                              </span>
                              <br />
                              <span className="text-uppercase">
                                {task?.sprint_id ? (
                                  <>
                                    {showSprintPermission ? (
                                      <Link
                                        className="custom-link-style-crm"
                                        to={`/sprints/info/${
                                          task?.sprint_id
                                        }?tab=tasklist&${createSearchParams({
                                          status_filter:
                                            defaultTaskStatusFilter.join("/"),
                                        })}`}
                                      >
                                        {task?.sprint_name.length > 15
                                          ? `${task?.sprint_name.slice(
                                              0,
                                              15
                                            )}...`
                                          : task?.sprint_name}
                                      </Link>
                                    ) : (
                                      <span>
                                        {task?.sprint_name.length > 15
                                          ? `${task?.sprint_name.slice(
                                              0,
                                              15
                                            )}...`
                                          : task?.sprint_name}
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </span>
                            </td>
                          )}
                          {loggedUsCustomer ? null : (
                            <td>
                              <span className="custom-text-muted-crm">
                                <span>Assignee</span>
                                <Button
                                  size="sm"
                                  variant="transparent"
                                  className="custom-button-action p-0"
                                  onClick={() => {
                                    handleAssigneeUpdate(
                                      task?.assignee?.id,
                                      task.id
                                    );
                                  }}
                                  title="Edit Assignee"
                                >
                                  <FaUserEdit
                                    size={15}
                                    className="ms-1"
                                    color="#bda49a"
                                  />
                                </Button>
                              </span>
                              <br />
                              <span className="text-uppercase">
                                <div
                                  className="cursor-pointer"
                                  onClick={() =>
                                    handleUserProfileArea(task?.assignee?.id)
                                  }
                                >
                                  {task?.assignee?.name ?? "Unassigned"}
                                </div>
                              </span>
                            </td>
                          )}
                          <td>
                            <div>
                              <span className="custom-text-muted-crm">
                                Label
                              </span>
                              <br />
                              <span className="d-flex flex-wrap gap-1">
                                {Object.keys(task.labels).length > 0
                                  ? task.labels.map((labelItem, itemKey) => (
                                      <OverlayTrigger
                                        key={itemKey}
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="button-tooltip-2">
                                            {labelItem.description}
                                          </Tooltip>
                                        }
                                      >
                                        <span
                                          className="badge p-2"
                                          style={{
                                            backgroundColor: labelItem.colour,
                                          }}
                                        >
                                          {labelItem.label}
                                        </span>
                                      </OverlayTrigger>
                                    ))
                                  : "N/A"}
                              </span>
                            </div>
                          </td>
                          <td width={"6%"}>
                            <span className="custom-text-muted-crm">
                              Start Date
                            </span>
                            <br />
                            <span>
                              {task.start_date_formatted
                                ? task.start_date_formatted
                                : "N/A"}
                            </span>
                          </td>
                          <td width={"6%"}>
                            <span className="custom-text-muted-crm">
                              End Date
                            </span>
                            <br />
                            <span>
                              {task.end_date_formatted
                                ? task.end_date_formatted
                                : "N/A"}
                            </span>
                          </td>
                          <td width={"12%"}>
                            <span className="custom-text-muted-crm ms-2">
                              Status
                            </span>
                            <br />
                            <div>
                              {updateTaskStatusPermission ? (
                                <DropdownButton
                                  id="dropdown-basic-button"
                                  className="custom-dropdown-button custom-transperent-common-button"
                                  variant="light"
                                  title={
                                    <span className="text-capitalize">
                                      <FaCircle
                                        color={getTaskStatusWithProps(
                                          task.status
                                        )}
                                        size={10}
                                      />
                                      <span className="ms-1">
                                        {task.status.replace("_", " ")}
                                        <span className="ms-2">
                                          <FaAngleDown />
                                        </span>
                                      </span>
                                    </span>
                                  }
                                  size="sm"
                                >
                                  {statusArray
                                    .filter((item) => item !== task.status)
                                    .map((status, key) => (
                                      <Dropdown.Item
                                        key={key}
                                        onClick={(e) => {
                                          handleStatusUpdate(
                                            e,
                                            status,
                                            task.id
                                          );
                                        }}
                                        className="text-capitalize"
                                      >
                                        {status.replace("_", " ")}
                                      </Dropdown.Item>
                                    ))}
                                </DropdownButton>
                              ) : (
                                <span className="text-capitalize">
                                  <FaCircle
                                    color={getTaskStatusWithProps(task.status)}
                                    size={10}
                                  />
                                  <span className="ms-1">
                                    {task.status.replace("_", " ")}
                                  </span>
                                </span>
                              )}
                            </div>
                          </td>

                          {loggedUsCustomer ? null : (
                            <>
                              <td>
                                <span className="custom-text-muted-crm">
                                  Priority
                                </span>
                                <br />
                                <span>
                                  {task.priority ? task.priority : "N/A"}
                                </span>
                              </td>

                              <td>
                                <span className="custom-text-muted-crm">
                                  Created by
                                </span>
                                <br />
                                <span className="text-capitalize">
                                  {task.created_user_full_name ?? "N/A"}
                                </span>
                              </td>
                            </>
                          )}

                          {actionsPermission && (
                            <td width={"1%"}>
                              <div className="d-flex justify-content-center gap-2">
                                {task.show_timer && (
                                  <TimerActionButton
                                    onClick={() => handleTimerConfirm(task)}
                                    timerStatus={task.running_status}
                                  />
                                )}
                                {updatePermission && (
                                  <EditButton
                                    route={
                                      renderFrom === "task_list"
                                        ? `edit/${task.id}?${commonSearchParams}`
                                        : renderFrom === "project"
                                        ? `/project/info/${itemInfoId}/task/edit/${task.id}?${commonSearchParams}`
                                        : `/sprints/info/${itemInfoId}/task/edit/${task.id}?${commonSearchParams}`
                                    }
                                  />
                                )}
                                {deletePermission && (
                                  <DeleteButton
                                    onClick={() => handleDelete(task.id)}
                                  />
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr className="">
                        <td
                          colSpan={12}
                          className="p-0 border-0 shadow-none border"
                          style={{ height: "72.5vh", overflow: "hidden" }}
                        >
                          {isLoading ? (
                            <LoadingScreenCover className="h-100 overflow-hidden">
                              <LoadingCommon loadingText="Looking for Tasks" />
                            </LoadingScreenCover>
                          ) : createPermission ? (
                            <h5 className="text-center text-secondary">
                              Tasks not found! Let's add it &#128578;
                            </h5>
                          ) : (
                            <h5 className="text-center text-secondary">
                              Tasks not found! &#128578;
                            </h5>
                          )}
                          {!isLoading && (
                            <div className="d-flex flex-fill justify-content-center align-items-center gap-2">
                              {createPermission && (
                                <AddButton
                                  route={
                                    renderFrom === "sprint"
                                      ? `/sprints/info/${itemInfoId}/task/add?${commonSearchParams}&project=${renderFromId}`
                                      : renderFrom === "project"
                                      ? `/project/info/${itemInfoId}/task/add?${commonSearchParams}`
                                      : `/tasks/list/add?${commonSearchParams}`
                                  }
                                  variant="primary"
                                  title="Add new task"
                                  label={"Add Task"}
                                />
                              )}

                              {filterItems && !itemInfoId ? (
                                <FilterButton
                                  showText
                                  filters={filters}
                                  url="tasks/list"
                                  variant="warning"
                                />
                              ) : null}
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <UserProfile
                  show={showUserProfileArea}
                  handleClose={() => setShowUserProfileArea(false)}
                  id={userProfile}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1">
              {tasks && tasks.links && !isLoading && (
                <AppPagination
                  data={tasks}
                  url={
                    renderFrom === "sprint"
                      ? `/sprints/info/${itemInfoId}`
                      : renderFrom === "project"
                      ? `/project/info/${itemInfoId}`
                      : "/tasks/list"
                  }
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </>
        </Card>
      )}
    </>
  );
};

export default TaskTable;
