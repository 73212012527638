import { React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import SelectSalesman from "../Search/SelectSalesman";
import FormErrorPopover from "components/form-error-popover/FormErrorPopover";
import useAxisproTranslate from "hooks/useAxisproTranslate";

function SalesmanInputField({
  formData,
  // uiDesign,
  // validations,
  handleSalesman,
  formError,
  setFormData,
}) {
  const Translate = useAxisproTranslate();

  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-3">
      <Row>
        <Col xl={4} md={4} sm={12} className="pe-0">
          <Form.Label className="m-0 d-flex align-items-center justify-content-start h-100">
            {Translate("Salesman")}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="flex-2">
              {/* {validations?.fromSd ||
              validations?.fromSo ||
              uiDesign?.editScreen ? (
                <Form.Control value={formData.salesman_id_ref} disabled />
              ) : ( */}
              <SelectSalesman
                value={formData.salesman_id_ref}
                name={formData.salesman_id_ref}
                handleFieldChange={handleSalesman}
                setFormData={setFormData}
              />
              {/* )} */}
            </Col>
            <Col
              xl={1}
              md={1}
              sm={1}
              className="text-danger d-flex align-items-center flex-1 p-sm-0 m-0 ms-1"
            >
              {formError.salesman_id ? (
                <FormErrorPopover
                  id="salesman_id"
                  totalErrorCount={Object.keys(formError).length}
                  errorMessage={formError.salesman_id}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

SalesmanInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  uiDesign: PropTypes.any,
  validations: PropTypes.any,
  setFormData: PropTypes.func,
  handleSalesman: PropTypes.func,
};

export default SalesmanInputField;
