import React from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import PropTypes from "prop-types";

import Flex from "components/common/Flex";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import Background from "components/common/Background";

import background from "assets/img/illustrations/authentication-corner.png";

function CountTile({ isLoading, label, icon, color, route, data }) {
  return (
    <Card
      className="h-md-100 p-2 card-main"
      style={{ minHeight: "112px", maxHeight: "112px" }}
    >
      {!isLoading ? (
        <>
          <Background image={background} className="p-card bg-card" />
          <Card.Body style={{ cursor: "pointer" }}>
            <div className="mt-2 mb-0">
              <Row className="g-2 h-100 align-items-end">
                <Col sm={12} md={12}>
                  <Flex className=" align-items-center justify-content-between">
                    <div
                      className="icon-item icon-item-sm border rounded-4 shadow-none me-3"
                      style={{ backgroundColor: `${color}` }}
                    >
                      {icon}
                    </div>
                    <div className="flex-1">
                      <Link to={route} className="stretched-link">
                        <p
                          className="mb-0 text-dark"
                          style={{
                            fontSize: "16px",
                            fontWeight: "600",
                            letterSpacing: "0.7px",
                          }}
                        >
                          {label}
                        </p>
                      </Link>
                    </div>
                    <div className="fs-4 mb-2 fw-bold text-dark">
                      <CountUp
                        start={0}
                        end={data}
                        duration={2.75}
                        suffix={""}
                        prefix={""}
                        separator=","
                        decimals={""}
                        decimal="."
                      />
                    </div>
                  </Flex>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </>
      ) : (
        <Card.Body className="p-2">
          <DashboardTileLoader column={1} />
        </Card.Body>
      )}
    </Card>
  );
}

CountTile.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  label: PropTypes.string,
  icon: PropTypes.any,
  color: PropTypes.string,
  route: PropTypes.string,
  data: PropTypes.any,
};

export default CountTile;
