import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import axios from "axios";

const SelectStock = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
}) => {
  const [loading, setloading] = useState(false);
  const [optionsArray, setoptionsArray] = useState([]);
  const portalTarget = document.createElement("div");
  document.body.appendChild(portalTarget);
  useEffect(() => {
    setloading(true);
    async function fetchData() {
      let response = await axios.get("inventory/items-auto");
      let data = response && response.data ? response.data.data : [];
      setoptionsArray(
        data.map((item) => ({
          label: item.item_name,
          value: item.stock_id,
        }))
      );
      setloading(false);
    }
    fetchData();
  }, []);

  return (
    <Select
      isClearable
      isLoading={loading}
      placeholder={placeholder ?? ""}
      options={optionsArray}
      menuPortalTarget={portalTarget}
      value={value}
      onChange={handleFieldChange}
      name={name ? name : "stock_id"}
      className={error && "form-control ps-0 py-0 is-invalid"}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          height: 32,
          minHeight: 32,
          // zIndex: 9999,
          ...style,
        }),
      }}
    />
  );
};

SelectStock.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  value: PropTypes.object,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectStock;
