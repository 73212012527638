import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SprintInfo from "./SupportingComponents/SprintInfo";
import { BsCalendar2Range } from "react-icons/bs";
import TaskTable from "module/Task/TaskTable/TaskTable";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import { apiCall } from "helpers/apiCalls";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FaBan } from "react-icons/fa";
import { BiArrowBack } from "react-icons/bi";

const SprintOverview = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const activeTab = queryParams.get("tab");
  const { itemInfoId } = useParams();
  const [sprintData, setSprintData] = useState({});
  const [loading, setLoading] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [showTasksPermission] = ["list-task"].map((permission) =>
    axisProPermission(permission)
  );

  const getSprintData = async () => {
    setLoading(true);
    try {
      const data = await apiCall({
        url: `crm/sprint/${itemInfoId}`,
      });
      setSprintData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (itemInfoId) {
      getSprintData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  const handleTabSwitch = (tab) => {
    let navigateUrl;
    if (tab === "tasklist") {
      navigateUrl = `?tab=${tab}&${createSearchParams({
        status_filter: defaultTaskStatusFilter.join("/"),
      })}`;
    } else {
      navigateUrl = `?tab=${tab}`;
    }
    activeTab !== tab && navigate(navigateUrl);
  };

  return (
    <Container className="p-0 mt-3 mb-3" fluid>
      <Row className="g-3">
        <Col xl={2} lg={3} md={3}>
          <Card className="shadow-none h-100 border-dashed">
            <Card.Header className="border-bottom">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-2">
                  <BsCalendar2Range size={20} />
                  <span className="fw-bold mt-1">SPRINT OVERVIEW</span>
                </div>
                <div
                  className="text-light p-1 rounded-5 ps-2 pe-1 cursor-pointer"
                  style={{ backgroundColor: "#E8EFFE" }}
                  as={Button}
                  title="Back"
                  onClick={() => {
                    navigate("/sprints");
                  }}
                >
                  <BiArrowBack size={18} style={{ color: "#3F74E4" }} />
                </div>
              </div>
            </Card.Header>
            <Card.Body style={{ minHeight: "83vh", overflow: "auto" }}>
              <SprintInfo sprintData={sprintData} loading={loading} />
            </Card.Body>
          </Card>
        </Col>
        <Col xl={10} lg={9} md={9}>
          <Card className="h-100 border-dashed shadow-none p-0">
            <Card.Body className="">
              {showTasksPermission ? (
                <Tab.Container>
                  <Tabs
                    defaultActiveKey="tasklist"
                    id="custom-sprint-tabs"
                    className="custom-sprint-tabs"
                    variant="tabs"
                    activeKey={activeTab}
                    onSelect={handleTabSwitch}
                  >
                    <Tab
                      eventKey="tasklist"
                      title={<span className="fw-bold">Tasks</span>}
                      className="custom-sprint-tab"
                      disabled
                    >
                      <TaskTable
                        mainCardClassName={`shadow-none`}
                        showHeader={false}
                        renderFrom={"sprint"}
                        renderFromId={sprintData.project_id}
                      />
                    </Tab>
                  </Tabs>
                </Tab.Container>
              ) : (
                <div className="d-flex justify-content-center align-items-center h-100 gap-2">
                  <FaBan size={30} color="#ff6e6e" />
                  <span className="fs-0">
                    No permissions are available to view this section.
                  </span>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SprintOverview;
