import { RiTwitterXLine } from "react-icons/ri";
const {
  FaFacebookF,
  FaLinkedinIn,
  FaInstagram,
  FaWhatsapp,
  FaTelegramPlane,
  FaGithub,
} = require("react-icons/fa");
const { SiIcon } = require("react-icons/si");
const { TbBrandYoutube } = require("react-icons/tb");

const getSocialIcon = (type, size = 14) => {
  switch (type) {
    case "Facebook":
      return <FaFacebookF size={size} color="#0862F6" />;
    case "Twitter":
      return <RiTwitterXLine size={size - 2} color="#000000" />;
    case "Linkedin":
      return <FaLinkedinIn size={size} color="#0A66C2" />;
    case "Instagram":
      return <FaInstagram size={size} color="#DD2A7B" />;
    case "Whatsapp":
      return <FaWhatsapp size={size} color="#075E54" />;
    case "Telegram":
      return <FaTelegramPlane size={size} color="#2AABEE" />;
    case "Youtube":
      return <TbBrandYoutube size={size} color="#CD201F" />;
    case "Github":
      return <FaGithub size={size} color="#000000" />;
    default:
      return <SiIcon size={size} />;
  }
};

export default getSocialIcon;
