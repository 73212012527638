import React from "react";
import { Card } from "react-bootstrap";
import PropTypes from "prop-types";
import BarChart from "module/common/Charts/BarChart";
import DashboardTileLoader from "module/common/Loading/DashboardTileLoader";
import getTaskStatusWithProps from "module/common/helpers/getTaskStatusWithProps";

function OverviewChart({ dashboardData, loading }) {
  const hasDashboardData = Object.keys(dashboardData).length > 0;
  const hasWeeklyStatusCount =
    hasDashboardData &&
    Object.keys(dashboardData.weekly_status_count).length > 0;

  const labelArray = hasWeeklyStatusCount
    ? Object.keys(dashboardData.weekly_status_count)
    : [];

  const dataBar = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: labelArray.map((label) => {
      const singleDataset =
        hasDashboardData && dashboardData.weekly_status_count[label];

      return {
        label: label.replace("_", " "),
        data: singleDataset ? singleDataset.map((item) => item.count) : [],
        backgroundColor: getTaskStatusWithProps(label.toLowerCase()),
        borderColor: getTaskStatusWithProps(label.toLowerCase()),
        borderWidth: 2,
      };
    }),
  };

  const optionsBar = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    elements: {
      bar: {
        borderRadius: 0,
      },
    },
  };

  return (
    <Card className="card-main">
      {!loading ? (
        <Card.Body>
          <Card.Title className="d-flex justify-content-start fw-bold">
            Overview (Last Week)
          </Card.Title>
          <div>
            <BarChart
              options={optionsBar}
              data={dataBar}
              height={300}
              width={"100%"}
            />
          </div>
        </Card.Body>
      ) : (
        <Card.Body className="p-4 pb-2 pt-3">
          <DashboardTileLoader column={4} />
        </Card.Body>
      )}
    </Card>
  );
}

OverviewChart.propTypes = {
  dashboardData: PropTypes.any,
  loading: PropTypes.bool,
};

export default OverviewChart;
