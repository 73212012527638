import { React } from "react";
import { Col, Form, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import useAxisproTranslate from "hooks/useAxisproTranslate";

function CustomerInputField({
  formData,
  uiDesign,
  validations,
  handleCustomer,
  formError,
  editDisable,
  setFormData,
  id,
}) {
  const Translate = useAxisproTranslate();
  return (
    <Form.Group as={Col} xxl={4} xl={6} md={6} sm={12} className="mb-3">
      <Row>
        <Col xl={4} md={4} sm={12}>
          <Form.Label
            className={
              "require-data m-0 d-flex align-items-center justify-content-start h-100"
            }
          >
            {Translate("Account")}
          </Form.Label>
        </Col>
        <Col xl={8} md={8} sm={12}>
          <Row className="d-flex flex-row">
            <Col xl={11} md={11} sm={11} className="flex-2">
              <Form.Label className="w-100 text-capitalize border h-100 d-flex align-items-center ps-3 bg-light">
                {typeof formData?.customer_id_ref === "object"
                  ? formData?.customer_id_ref?.label
                    ? formData?.customer_id_ref?.label
                    : "N/A"
                  : typeof formData?.customer_id_ref === "string"
                  ? formData?.customer_id_ref
                  : "N/A"}
              </Form.Label>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form.Group>
  );
}

CustomerInputField.propTypes = {
  formData: PropTypes.any,
  formError: PropTypes.any,
  uiDesign: PropTypes.any,
  editDisable: PropTypes.bool,
  validations: PropTypes.any,
  handleCustomer: PropTypes.func,
  setFormData: PropTypes.func,
  id: PropTypes.any,
};

export default CustomerInputField;
