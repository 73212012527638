import { React, useEffect, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import Overview from "./Overview/Overview";
import IconButton from "components/common/IconButton";
import ConvertModal from "./Overview/Modals/ConvertModal";
import Activity from "./Overview/Activity";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import Contacts from "module/common/ViewPages/Common/Contacts/Contacts";
import Calls from "module/common/ViewPages/Common/Calls/Calls";
import Meetings from "module/common/ViewPages/Common/Meetings/Meetings";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import { SiGoogleadmob } from "react-icons/si";
import useAxisproPermission from "hooks/useAxisproPermission";
import RibbonButtons from "module/common/Buttons/RibbonButtons";
import { leadStatusEditFormKeys } from "helpers/formKeys";
import { apiCall } from "helpers/apiCalls";
import qs from "qs";
import { TbStatusChange } from "react-icons/tb";

function LeadInfo() {
  const navigate = useNavigate();
  const { overviewId } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const activeTab = queryParams.get("tab");
  const axisProPermission = useAxisproPermission();
  const [isLoadingNeeded, setIsLoadingNeeded] = useState(false);
  const [triggerActivityLog, setTriggerActivityLog] = useState(false);

  const [showStatusModal, setShowStatusModal] = useState(false);
  const [formData, setFormData] = useState(leadStatusEditFormKeys);

  const [isLoading, setIsLoading] = useState(false);
  const [ribbonData, setRibbonData] = useState({});

  const fetchData = () => {
    if (!isLoadingNeeded) {
      setLoading(true);
    }
    axios
      .get("crm/lead/" + overviewId)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setFormData((prevData) => ({
            ...prevData,
            lead_id: res.data.data?.id,
            status_id: res.data.data?.lead_status_id,
            status_id_ref: {
              value: res.data.data?.lead_status_id,
              label: res.data.data?.lead_status,
            },
            description: "",
          }));
          setLoading(false);
          setTriggerActivityLog(!triggerActivityLog);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overviewId, isLoadingNeeded]);

  const handleSelect = (status) => {
    setFormData((prevData) => ({
      ...prevData,
      lead_id: data?.id,
      status_id: status?.value,
      status_id_ref: {
        value: status?.value,
        label: status?.label,
      },
      description: "",
    }));
    setShowStatusModal(true);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleModalClose = () => {
    setFormData(leadStatusEditFormKeys);
    setShowStatusModal(false);
  };

  const getData = async () => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/lead-status",
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    const items = data?.data?.map((item) => ({
      label: item?.status,
      value: item?.id,
    }));
    setRibbonData(items);
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const dataToRibbonButton = {
    id: data?.id,
    data: formData,
    getData: fetchData,
    isLoadingNeeded: isLoadingNeeded,
    setIsLoadingNeeded: setIsLoadingNeeded,
    apiUrl: "lead-update-status",
    heading: "UPDATE STATUS",
    showStatusModal: showStatusModal,
    handleSelect: handleSelect,
    handleFieldChange: handleFieldChange,
    close: handleModalClose,
    isLoading: isLoading,
    ribbonData: ribbonData,
    itemRef: {
      value: data?.lead_status_id,
      label: data?.lead_status,
    },
    IconComponent: TbStatusChange,
  };

  return (
    <Card className="flex-fill mb-3 mt-3 h-100 shadow-none">
      {!loading ? (
        data && Object.keys(data).length > 0 ? (
          <>
            <ConvertModal data={data} />
            <Card.Header className="d-flex justify-content-between border-bottom">
              <div>
                <div className="ms-1 text-dark d-flex align-items-center">
                  <SiGoogleadmob size={20} className="me-2 text-mute" />
                  <span className="fs-1 text-uppercase mt-1">
                    {data && data.title}
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {axisProPermission("convert-lead-to-opportunity") && (
                  <IconButton
                    className="me-2"
                    variant={`${
                      data.is_converted ? "success" : "falcon-default"
                    }`}
                    size="sm"
                    icon="shuffle"
                    transform="shrink-2"
                    disabled={data.is_converted ? true : false}
                    onClick={() =>
                      navigate(
                        `?${createSearchParams({
                          ...allQueryParams,
                          convert: true,
                        })}`
                      )
                    }
                  >
                    <span
                      className="d-none d-md-inline-block ms-1"
                      title="convert to client"
                    >
                      {data.is_converted ? "Converted" : "Convert"}
                    </span>
                  </IconButton>
                )}
                <IconButton
                  className="me-2"
                  variant="falcon-default"
                  size="sm"
                  icon="close"
                  transform="shrink-2"
                  onClick={() => navigate(`/leads/lead`)}
                >
                  <span
                    className="d-none d-md-inline-block ms-1"
                    title="convert to client"
                  >
                    Close
                  </span>
                </IconButton>
              </div>
            </Card.Header>

            <Card.Header
              className="border-bottom"
              style={{ minHeight: "67px" }}
            >
              <RibbonButtons dataToRibbonButton={dataToRibbonButton} />
            </Card.Header>

            <Card.Body
              style={{ backgroundColor: "#F9F9F9" }}
              className="p-0 d-flex flex-column"
            >
              <Row className="g-2">
                <Col md={9}>
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Tabs
                      style={{ backgroundColor: "#FFFFFF" }}
                      id="custom-empl-header-tabs"
                      className="custom-empl-header-tabs p-2  "
                      variant="pills"
                      justify
                      defaultActiveKey={activeTab ? activeTab : "overview"}
                      onSelect={(tabname) => {
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            tab: tabname,
                          })}`
                        );
                      }}
                    >
                      <Tab
                        eventKey="overview"
                        title={<span className="fw-bold">Overview</span>}
                        className="custom-empl-header-tab min-vh-75"
                      >
                        <div className="mt-2">
                          <Overview data={data} />
                        </div>
                      </Tab>
                      {axisProPermission("list-lead-contact") && (
                        <Tab
                          eventKey="contacts"
                          title={<span className="fw-bold">Contacts</span>}
                          className="custom-empl-header-tab "
                        >
                          <div className="mt-2">
                            <Contacts type="LEAD" itemId={overviewId} />
                          </div>
                        </Tab>
                      )}
                      {axisProPermission("list-lead-call") && (
                        <Tab
                          eventKey="calls"
                          title={<span className="fw-bold">Calls</span>}
                          className="custom-empl-header-tab"
                        >
                          <div className="mt-2">
                            <Calls type="LEAD" itemId={overviewId} />
                          </div>
                        </Tab>
                      )}
                      {axisProPermission("list-lead-meeting") && (
                        <Tab
                          eventKey="meeting"
                          title={<span className="fw-bold">Meeting</span>}
                          className="custom-empl-header-tab"
                        >
                          <div className="mt-2">
                            <Meetings type="LEAD" itemId={overviewId} />
                          </div>
                        </Tab>
                      )}

                      <Tab
                        eventKey="attachment"
                        title={<span className="fw-bold">Attachment</span>}
                        className="custom-empl-header-tab"
                      >
                        <div className="mt-2">
                          <CommonAttachments itemId={overviewId} type="LEAD" />
                        </div>
                      </Tab>
                    </Tabs>
                  </Tab.Container>
                </Col>
                <Col md={3} className="flex-fill">
                  <Activity
                    triggerActivityLog={triggerActivityLog}
                    itemId={overviewId}
                  />
                </Col>
              </Row>
            </Card.Body>
          </>
        ) : (
          <Card.Body className="h-100 d-flex align-items-center justify-content-center">
            Nothing to display !
          </Card.Body>
        )
      ) : (
        <Card.Body className="h-100 d-flex align-items-center justify-content-center">
          <LoadingCommon loadingText="Loading Lead Overview" />
        </Card.Body>
      )}
    </Card>
  );
}

export default LeadInfo;
