import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsHandbagFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function KitLineItem({
  entry,
  index,
  handleEditItem,
  handleDeleteItem,
  batch,
  readOnly,
  clickOnItem,
}) {
  return (
    <tr>
      <td className="fs--1 text-dark p-1 text-center">{index + 1}</td>
      <td className="fs--1 text-dark p-1 text-center">
        <Link
          onClick={() => {
            clickOnItem(entry.item_id);
          }}
        >
          {entry.stock_id}
        </Link>
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        <div className="d-flex flex-row justify-content-between align-items-center">
          {entry.item_display_name}
          {parseInt(entry.is_kit) && !entry.kit_id ? (
            <BsHandbagFill style={{ fill: "red" }} size={11} className="mx-2" />
          ) : null}
        </div>
      </td>
      {batch ? (
        ""
      ) : (
        <td className="fs--1 text-dark p-1 text-center">
          {entry.batch_number_ref
            ? entry.batch_number_ref
            : entry.batch_number_display
            ? entry.batch_number_display
            : "-"}
        </td>
      )}
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_ref
          ? entry.unit_ref
          : entry.unit_name
          ? entry.unit_name
          : "-"}
      </td>
      {readOnly === false && (
        <td className="fs--1 text-dark p-1 text-center">
          {entry?.base_quantity_ref
            ? entry?.base_quantity_ref
            : entry?.initial_quantity
            ? entry?.initial_quantity
            : null}
        </td>
      )}
      <td className="fs--1 text-dark p-1 text-center">
        {entry?.kit_quantity && entry?.initial_quantity
          ? entry?.kit_quantity * entry?.initial_quantity
          : entry.quantity}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_price_formatted ?? "-"}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.unit_tax_formatted ?? "-"}
      </td>
      <td className="fs--1 text-dark p-1 text-center">
        {entry.discount_amount < 1 ? (
          "-"
        ) : entry.discount_unit_ref === "%" ? (
          <>{entry.discount_percent}%</>
        ) : (
          <>{entry.discount_amount_formatted ?? "-"}</>
        )}
      </td>
      <td className="fs--1 text-dark p-1 fw-bolder text-center">
        {entry.line_total_formatted}
      </td>
      {readOnly === false && (
        <td style={{ display: "none" }}>
          <div className="d-flex flex-row align-items-center justify-content-center p-1">
            <Button
              size="sm"
              variant="transparent"
              className={`p-0 mx-1 text-info`}
              style={{ boxShadow: "none" }}
              onClick={() => handleEditItem(entry, index)}
              disabled={!entry.has_batch_inventory}
            >
              <FontAwesomeIcon icon="edit" size="sm" />
            </Button>
            <Button
              size="sm"
              variant="transparent"
              className="p-0 mx-1 text-danger"
              style={{ boxShadow: "none" }}
              onClick={() => handleDeleteItem(entry, index)}
              disabled
            >
              <FontAwesomeIcon icon="trash" size="sm" />
            </Button>
          </div>
        </td>
      )}
    </tr>
  );
}

KitLineItem.propTypes = {
  index: PropTypes.number,
  entry: PropTypes.object,
  handleEditItem: PropTypes.func,
  handleDeleteItem: PropTypes.any,
  batch: PropTypes.bool,
  readOnly: PropTypes.bool,
  clickOnItem: PropTypes.func,
};

export default KitLineItem;
