import React, { useRef } from "react";
import { Alert, Button, Card, Form, Spinner } from "react-bootstrap";
import Flex from "components/common/Flex";
import IconButton from "components/common/IconButton";
import TextEditor from "module/common/TextEditor/TextEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiOutlineCamera } from "react-icons/ai";
import { FaPaperPlane } from "react-icons/fa";

const CommentInputFeild = ({
  handleFieldChange,
  formData,
  handleSubmit,
  onComment,
  attachments,
  handleDetachAttachment,
  handleCommentDistroy,
  formError,
  onEdit,
}) => {
  const refFileControl = useRef();
  const uploadFile = () => {
    refFileControl.current.click();
  };
  const getIcon = (type) => {
    const icon = ["far"];
    if (type.includes("image")) {
      icon.push("file-image");
    }
    if (type.includes("video")) {
      icon.push("file-video");
    }
    if (type.includes("audio")) {
      icon.push("file-audio");
    }
    if (type.includes("zip")) {
      icon.push("file-archive");
    }
    if (type.includes("pdf")) {
      icon.push("file-pdf");
    }
    if (
      type.includes("html") ||
      type.includes("css") ||
      type.includes("json") ||
      type.includes("javascript")
    ) {
      icon.push("file-code");
    }
    if (icon.length === 1) {
      icon.push("file");
    }
    return icon;
  };

  const hasImgTag = (string) => {
    var imgTagRegex = /<img\b[^>]*>/i;
    return imgTagRegex.test(string);
  };

  return (
    <Card as={Form} className="shadow-none mb-3">
      <Card.Body className="p-0">
        <div className="border border-0 border-200">
          {Object.keys(formError).length > 0 && (
            <Alert variant="danger" show={formError.comment ? true : false}>
              <Alert.Heading> {formError.comment}</Alert.Heading>
            </Alert>
          )}

          <Form.Group>
            <TextEditor
              name="comment"
              placeholder="Leave a comment..."
              handleFieldChange={handleFieldChange}
              value={formData.comment}
            />
          </Form.Group>
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <Form.Control
                ref={refFileControl}
                type="file"
                multiple
                className="d-none"
                name="files"
                onChange={handleFieldChange}
              />
              <Button
                className=" p-1 pb-1 small d-flex  align-items-center ps-2 border-0 rounded-4 mt-1 pe-2 "
                style={{ backgroundColor: "#EDF2F9" }}
                onClick={uploadFile}
              >
                <AiOutlineCamera
                  className="common-comments-header-username text-500"
                  size={17}
                />
                <div className="common-comments-header-username ms-1 small text-500">
                  Upload File
                </div>
              </Button>
            </div>
            <div className="d-flex">
              {onEdit && (
                <IconButton
                  className="me-2  mb-0 rounded-3"
                  variant="light"
                  size="sm"
                  icon="xmark"
                  onClick={handleCommentDistroy}
                >
                  <span className="d-none d-md-inline-block ms-1">Close</span>
                </IconButton>
              )}
              <Button
                size="sm"
                variant="dark"
                className="p-0 custom-transperent-common-button border-0 d-flex align-items-center justify-content-center p-1 rounded-1 mt-1 pe-1 cursor-pointer"
                onClick={handleSubmit}
                disabled={
                  !formData?.comment.replace(/<[^>]*>/g, "").trim() &&
                  !hasImgTag(formData?.comment) &&
                  attachments.length === 0
                }
              >
                {onComment ? (
                  <Spinner animation="border" size="sm" variant="light" />
                ) : (
                  <>
                    <FaPaperPlane size={16} className="text-light" />
                  </>
                )}
                <div className="text-muted small ps-1 text-uppercase">
                  Post Comment
                </div>
              </Button>
            </div>
          </div>
        </div>
        {attachments.length > 0 && (
          <div className=" px-x1 mt-2 ">
            <Flex direction="column" inline alignItems="start" className=" ">
              {attachments.map((attachment, key) => (
                <Flex
                  alignItems="center"
                  className="border px-2 rounded-3 bg-white dark__bg-1000 my-1 fs--1"
                  key={key}
                >
                  <FontAwesomeIcon
                    icon={getIcon(attachment.type)}
                    className="fs-1"
                  />
                  <span className="mx-2 flex-1">
                    {attachment.name} ({(attachment.size / 1024).toFixed(2)}
                    kb)
                  </span>
                  <span
                    className="text-300 p-1 ml-auto cursor-pointer"
                    id={`attachmentTooltip${key}`}
                    onClick={() => handleDetachAttachment(attachment.name)}
                  >
                    <FontAwesomeIcon icon="times" />
                  </span>
                </Flex>
              ))}
            </Flex>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default CommentInputFeild;
