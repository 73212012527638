import axios from "axios";

const handlePrint = async (url, title) => {
  const printWindow = window.open(
    "",
    "_blank",
    "toolbar=yes,scrollbars=yes,resizable=yes"
  );

  let mainContent = (content) => {
    return `<head>
    <title>${title ? title : ""}</title>
   </head>
   <body>
   <div style="width: 100vw; height: 100vh; display: flex; justify-content: center; align-items: center;" id="content_section">
   ${content}
   <div>
   </body>`;
  };

  printWindow.document.write(mainContent("<span>Creating print view...<span>"));
  await axios({
    method: "post",
    url: url,
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf",
      "Content-Type": "application/octet-stream",
    },
  })
    .then((response) => {
      const pdfFile = new Blob([response.data], { type: "application/pdf" });
      if (pdfFile instanceof Blob) {
        printWindow.location.replace(window.URL.createObjectURL(pdfFile));
      } else {
        printWindow.document.querySelector("html").innerHTML = mainContent(
          '<span style="color: red;">Print request failed<span>'
        );
      }
    })
    .catch((error) => {
      printWindow.document.querySelector("html").innerHTML = mainContent(
        '<span style="color: red;">Print request failed<span>'
      );
      console.error(error);
    });
};

export default handlePrint;
