import { React, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
import { BsHandbagFill } from "react-icons/bs";
import ItemAdvanceSearch from "components/item-advance-search/ItemAdvanceSearch";

function SearchAndSelectItem({
  handleFieldChange,
  name,
  error,
  value,
  advanceSearch,
  advanceSearchType,
  keyName,
  stockItemList,
  type,
}) {
  const [defaultOptions, setDefaultOptions] = useState([]);

  const setItems = async (name) => {
    let response = await axios.get("inventory/items-auto", {
      params: {
        item_name: name,
        exclude_kits: stockItemList ? 1 : type ? 1 : 0,
        product_type: type ? "govt_service" : "",
      },
    });
    let data = response && response.data.data ? response.data.data : [];
    return data.map((item) => ({
      label: item.item_name,
      value: item.stock_id,
      id: item.id,
      type: item.type,
      unit_name: item.unit_name,
      price: item.sale_rate,
    }));
  };

  const getOptionLabel = (option) => {
    return (
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ color: "rgb(52,69,85)" }}
      >
        <span>{option.label}</span>
        {option.type === "kit" ? (
          <BsHandbagFill
            style={{ fill: "red" }}
            size={13}
            className="text-dark"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  const handleFocus = async () => {
    if (defaultOptions.length === 0) {
      const defaultOptionsData = await setItems("");
      setDefaultOptions(defaultOptionsData);
    }
  };

  const skipTabFunctionality = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      let nextElement = document.getElementById("item_description");
      if (nextElement) {
        nextElement.focus();
      }
    }
  };

  return (
    <div className="d-flex flrex-row align-items-center">
      <AsyncSelect
        cacheOptions
        loadOptions={setItems}
        defaultOptions={defaultOptions}
        onFocus={handleFocus}
        isClearable
        getOptionLabel={getOptionLabel}
        isOptionSelected={(item) => item.value === value?.value}
        name={name?.label ? name?.label : "stock"}
        value={value}
        placeholder=""
        onKeyDown={(e) => skipTabFunctionality(e)}
        onChange={handleFieldChange}
        className={`w-100  ${
          error ? " form-control ps-0 py-0 is-invalid" : ""
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            fontFamily: "sans-serif",
            fontSize: "12px",
            borderRadius: "0px",
            textTransform: "capitalize",
            overflow: "hidden",
          }),
          control: (base, state) => ({
            ...base,
            boxShadow: state.isFocused ? 0 : 0,
            borderRadius: "0px",
            border: "1px solid #b5b5b5",
            height: "10px",
          }),
        }}
        menuPortalTarget={document.body}
      />
      {advanceSearch && (
        <ItemAdvanceSearch
          setItem={handleFieldChange}
          name={name ?? "item"}
          keyName={keyName}
          advanceSearchType={advanceSearchType}
        />
      )}
    </div>
  );
}

SearchAndSelectItem.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.any,
  error: PropTypes.any,
  value: PropTypes.any,
  advanceSearch: PropTypes.bool,
  advanceSearchType: PropTypes.string,
  keyName: PropTypes.string,
  stockItemList: PropTypes.bool,
  type: PropTypes.bool,
};

export default SearchAndSelectItem;
