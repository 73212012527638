import { React, useEffect, useState, useContext } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { FaEdit, FaTimesCircle } from "react-icons/fa";
import { AuthWizardContext, StoreContext } from "context/Context";
import generateFormDataHelper from "helpers/generateFormDataHelper";
import removeRefData from "helpers/removeRefData";
import setNewFormData from "helpers/setNewFormData";
import { showToast } from "module/common/Toast/toast";
import { CancelButton, SaveButton } from "module/common/Buttons/CommonButton";
import { removeKitDetails } from "module/common/Functions/SalesModuleSupportingFunctions";
import { DateFormatVaildation } from "module/common/Functions/DateFormatVaildation";
import { removeEmptyCustomField } from "module/common/Functions/CustomFieldFunction";
import Total from "module/common/Total";
import CustomerInputField from "module/common/InputFields/CustomerInputField";
import CostCenterInputField from "module/common/InputFields/CostCenterInputField";
import EmailInputField from "module/common/InputFields/EmailInputField";
import ContactNumberInputField from "module/common/InputFields/ContactNumberInputField";
import DateInputField from "module/common/InputFields/Common/DateInputField";
import CustomerRef from "module/common/InputFields/Client/CustomerRef";
import SalesTypeInputField from "module/common/InputFields/SalesTypeInputField";
import PaymentTermInputField from "module/common/InputFields/PaymentTermInputField";
import MemoInputField from "module/common/InputFields/MemoInputField";
import TextEditor from "module/common/TextEditor/TextEditor";
import SelectTermsAndCondition from "module/common/Search/SelectTermsAndCondition";
import SalesmanInputField from "module/common/InputFields/SalesmanInputField";
import ErrorAlert from "module/common/ErrorAlert";
import { BiMoneyWithdraw } from "react-icons/bi";
import QuotationItemTable from "../QuotationTable/QuotationItemTable";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { quotationFormKeys } from "helpers/formKeys";
import DataProcessing from "module/common/DataProcessing";

const QuotationForm = ({ requiredData, fetchData }) => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { user } = useContext(AuthWizardContext);
  const { store } = useContext(StoreContext);
  const salesQuotationId = queryParams.get("quotation_id");
  const formMode = queryParams.get("mode");
  const { quoteId } = useParams();
  const {
    opportunity_id: opportunityId = "",
    opportunity_name: opportunityName = "",
    account_id: accountId = "",
    account_name: accountName = "",
  } = requiredData || {};
  const [formData, setFormData] = useState({
    ...quotationFormKeys,
    customer_id: accountId ? accountId : "",
    customer_id_ref: {
      label: accountName ? accountName : "",
      value: accountId ? accountId : "",
    },
    trans_date: store?.currentDate,
    opportunity_id: opportunityId ? opportunityId : "",
    opportunity_id_ref: {
      label: opportunityName ? opportunityName : "",
      value: opportunityId ? opportunityId : "",
    },
  });
  const [editItemData, setEditItemData] = useState({});
  const [onSave, setOnSave] = useState(false);
  const [formError, setFormError] = useState({});
  const [transDate, setTransDate] = useState();
  const [subTotal, setSubTotal] = useState("");
  const [netDiscount, setNetDiscount] = useState("");
  const [tax, setTax] = useState("");
  const [draft, setDraft] = useState(false);
  const [loading, setLoading] = useState(false);
  const [taxType, setTaxType] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const [editDisplay, setEditDisplay] = useState(false);
  const [salesType, setSalesType] = useState([]);
  const [kitItems, setKitItems] = useState([]);
  const [kitFullDetails, setKitFullDetails] = useState([]);
  const [termsFormData, setTermsFormData] = useState({
    terms: "",
    terms_ref: "",
  });

  //Sales Quotation data fetching for editing
  useEffect(() => {
    if (salesQuotationId) {
      setLoading(true);
      setEditDisplay(true);
      axios
        .get(`sales/sales-quote/${salesQuotationId}`)
        .then((res) => {
          axios
            .get(`settings/sales-types/${res.data.data.sales_type_id}`)
            .then((res) => {
              if (res.data.data.tax_included > 0) {
                setTaxType(true);
              } else {
                setTaxType(false);
              }
            })
            .catch((error) => {
              console.log(error);
            });
          setSalesType([res.data.data.sales_type_id]);
          setEditItemData(res.data.data);
          setTransDate(res.data.data.trans_date);
          if (res.data.data && res.data.data.details) {
            let editKitDetails = res.data.data.details.filter(
              (item) => item.is_kit === 1
            );
            setKitFullDetails([...editKitDetails]);
            const allItems = editKitDetails
              .map((obj) => obj.kit_items)
              .reduce((acc, arr) => acc.concat(arr), []);
            setKitItems([...allItems]);
          }
          setTimeout(() => setLoading(false), 1000);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      resetFormData();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesQuotationId]);

  const handleFieldChange = (e, action) => {
    if (action && action.action === "text-editor") {
      setFormData({
        ...formData,
        [action.name]: action.value,
      });
    } else {
      setFormData(generateFormDataHelper(e, action, formData));
    }
  };

  const resetFormData = () => {
    setFormData({
      ...quotationFormKeys,
      customer_id: accountId ? accountId : "",
      customer_id_ref: {
        label: accountName ? accountName : "",
        value: accountId ? accountId : "",
      },
      trans_date: store?.currentDate,
      opportunity_id: opportunityId ? opportunityId : "",
      opportunity_id_ref: {
        label: opportunityName ? opportunityName : "",
        value: opportunityId ? opportunityId : "",
      },
    });
    setFormError({});
    setEditItemData({});
  };

  const handleFormVisibility = () => {
    resetFormData();
    navigate(
      `/opportunity/overview/${opportunityId}${quoteId ? "/quotation/" + quoteId : ""
      }?${quoteId
        ? ""
        : createSearchParams({
          tab: "quotation",
        })
      }`
    );
  };

  const handleQuotationTerms = (e, action) => {
    setTermsFormData(generateFormDataHelper(e, action, termsFormData));
  };

  const addQuotationTerms = async () => {
    if (termsFormData.terms) {
      try {
        const { data } = await axios.get(
          `settings/terms-and-conditions/${termsFormData.terms}`
        );
        const description = data.data;

        setFormData((prev) => ({
          ...prev,
          terms_and_conditions: `${prev?.terms_and_conditions}<br/>${description.description}`,
        }));
        setTermsFormData({ terms: "", terms_ref: "" });
      } catch (error) {
        console.log(error);
      }
    }
  };

  let newStockKitItems = [...formData.details, ...kitItems];

  let newDetails = removeKitDetails(newStockKitItems);

  let formDataWithoutRefkeys = {
    ...removeRefData({ ...formData }, "object", {
      removeValue: [undefined, null],
    }),
    details: removeRefData(newDetails, "array", {
      removeValue: ["null", null, undefined, true, false, "true", "false"],
    }),
  };

  //If custom field is empty remove that object other wise showing 500 from background
  let formDataWithoutEmptyCustomField = removeEmptyCustomField(
    formDataWithoutRefkeys
  );

  let newFormData = setNewFormData(formDataWithoutEmptyCustomField);

  //Form Submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (formData.trans_date && !DateFormatVaildation(formData.trans_date)) {
      showToast(
        "You have selected an invalid date. Please select a valid date",
        "error"
      );
    } else {
      setFormError({});
      setOnSave(true);
      axios({
        method: salesQuotationId ? "put" : "post",
        url: `sales/sales-quote/${salesQuotationId ? salesQuotationId : ""}`,
        data: salesQuotationId ? formDataWithoutRefkeys : newFormData,
      })
        .then((response) => {
          if (response.data.success === true) {
            setSalesType([]);
            showToast(response.data.message, "success");
            navigate(
              quoteId
                ? `/opportunity/overview/${opportunityId}/quotation/${quoteId}`
                : `/opportunity/overview/${opportunityId}?tab=quotation`,
              {
                replace: true,
              }
            );
            fetchData();
            handleFormVisibility(false);
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again",
              "error"
            );
          }
          setOnSave(false);
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            const validation_error =
              error.response.data &&
                error.response.data.data &&
                error.response.data.data.errors
                ? error.response.data.data.errors
                : null;
            validation_error && setFormError({ ...validation_error });
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again",
              "error"
            );
          }
          setOnSave(false);
        });
    }
  };

  useEffect(() => {
    if (salesQuotationId) {
      const {
        draft_id,
        customer_id,
        id,
        customer_name,
        cost_center_id,
        cost_center_name,
        payment_term_id,
        payment_term_name,
        memo,
        email,
        mobile,
        trans_date,
        sales_type_id,
        sales_type_name,
        expected_delivery_date,
        details = [],
        terms_and_conditions,
        customer_reference,
        salesman_id,
        salesman_name,
        custom_field_values = [],
        opportunity_id,
        opportunity_title,
      } = editItemData;
      setFormData({
        draft_id: draft_id,
        trans_type: "SQ",
        trans_no: id,
        terms_and_conditions: terms_and_conditions,
        customer_id: customer_id,
        customer_id_ref: customer_name,
        cost_center_id: cost_center_id,
        cost_center_id_ref: cost_center_id
          ? { label: cost_center_name, value: cost_center_id }
          : "",
        payment_term_id: payment_term_id,
        payment_term_id_ref: payment_term_id
          ? {
            label: payment_term_name,
            value: payment_term_id,
          }
          : "",
        memo: memo,
        email: email,
        mobile: mobile,
        trans_date: trans_date,
        sales_type_id: sales_type_id,
        sales_type_id_ref: sales_type_id
          ? {
            label: sales_type_name,
            value: sales_type_id,
          }
          : "",
        expected_delivery_date: expected_delivery_date,
        details: details,
        customer_reference: customer_reference,
        salesman_id: salesman_id,
        salesman_id_ref: salesman_id
          ? { label: salesman_name, value: salesman_id }
          : "",
        custom_fields:
          custom_field_values?.length > 0
            ? Object.fromEntries(
              custom_field_values.map(({ custom_field_id, value }) => [
                custom_field_id,
                value,
              ])
            )
            : {},
        opportunity_id: opportunity_id,
        opportunity_id_ref: { label: opportunity_title, value: opportunity_id },
        is_from_crm: 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editItemData]);

  //To select customer
  const handleCustomer = (valueObject, action) => {
    let customerId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (customerId && action.action === "select-option") {
      setDataProcessing(true);
      axios
        .get(`sales/customers/${customerId}`)
        .then((res) => {
          if (res.data.data.sales_type_id) {
            setSalesType([res?.data?.data?.sales_type_id]);
          }
          setFormData((prev) => ({
            ...prev,
            customer_id: customerId ?? "",
            customer_id_ref: valueObject ?? "",
            payment_term_id: res?.data?.data?.payment_term_id ?? "",
            payment_term_id_ref: res?.data?.payment_term_id
              ? {
                label: res?.data?.data?.payment_term_name ?? "",
                value: res?.data?.data?.payment_term_id ?? "",
              }
              : "",
            sales_type_id: res?.data?.data?.sales_type_id
              ? res?.data?.data?.sales_type_id
              : formData?.sales_type_id
                ? formData?.sales_type_id
                : "",
            sales_type_id_ref: res?.data?.data?.sales_type_id
              ? {
                label: res?.data?.data?.sales_type_name ?? "",
                value: res?.data?.data?.sales_type_id ?? "",
              }
              : formData?.sales_type_id_ref
                ? formData?.sales_type_id_ref
                : "",
            salesman_id: res?.data?.data?.salesman_id,
            salesman_id_ref: res?.data?.data?.salesman_id
              ? {
                label: res?.data?.data?.salesman_name,
                value: res?.data?.data?.salesman_id,
              }
              : "",
            email: res?.data?.data?.email ?? "",
            mobile: res?.data?.data?.phone ?? "",
            details: [],
          }));
          setKitFullDetails([]);
          setDraft(!draft);
          setKitItems([]);
          setDataProcessing(false);
        })
        .catch((error) => {
          console.log(error);
          setDataProcessing(false);
        });
    } else {
      setFormData((prev) => ({
        ...prev,
        customer_id: "",
        customer_id_ref: "",
        payment_term_id: "",
        payment_term_id_ref: "",
        salesman_id: "",
        salesman_id_ref: "",
        sales_type_id: user?.branch?.sales_settings?.sales_type_id ?? "",
        sales_type_id_ref: user?.branch?.sales_settings?.sales_type_id
          ? {
            label: user?.branch?.sales_settings?.sales_type_name ?? "",
            value: user?.branch?.sales_settings?.sales_type_id ?? "",
          }
          : "",
        email: "",
        mobile: "",
        details: [],
      }));
      setKitFullDetails([]);
      setDraft(!draft);
      setKitItems([]);
    }
    setFormError({});
  };

  //To select cost center
  const handleCostCenter = (valueObject, action) => {
    setDraft(!draft);
    let costCenterId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (costCenterId && action.action === "select-option") {
      setFormData((prev) => ({
        ...prev,
        cost_center_id: costCenterId,
        cost_center_id_ref: valueObject,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        cost_center_id: "",
        cost_center_id_ref: "",
      }));
    }
    setFormError({});
  };

  //To select sales type
  const handleSalesType = (valueObject, action) => {
    setDraft(!draft);
    if (valueObject && valueObject.taxType > 0) {
      setTaxType(true);
    } else {
      setTaxType(false);
    }
    let salesTypeId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (salesTypeId && action.action === "select-option") {
      setSalesType([salesTypeId]);
      setFormData((prev) => ({
        ...prev,
        sales_type_id: salesTypeId,
        sales_type_id_ref: valueObject,
      }));
    } else {
      setSalesType([]);
      setFormData((prev) => ({
        ...prev,
        sales_type_id: "",
        sales_type_id_ref: "",
        details: [],
      }));
    }
    setFormError({});
  };

  //To select payment terms
  const handlePaymentTerms = (valueObject, action) => {
    setDraft(!draft);
    let paymentTermsId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (paymentTermsId && action.action === "select-option") {
      setFormData((prev) => ({
        ...prev,
        payment_term_id: paymentTermsId,
        payment_term_id_ref: valueObject,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        payment_term_id: "",
        payment_term_id_ref: "",
      }));
    }
    setFormError({});
  };

  const handleSalesman = (valueObject, action) => {
    setDraft(!draft);
    let salesmanId =
      valueObject && valueObject.value ? valueObject.value : null;
    if (salesmanId && action.action === "select-option") {
      setFormData((prev) => ({
        ...prev,
        salesman_id: salesmanId,
        salesman_id_ref: valueObject,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        salesman_id: "",
        salesman_id_ref: "",
      }));
    }
    setFormError({});
  };

  useEffect(() => {
    if (salesQuotationId) return;
    if (user?.branch?.sales_settings?.sales_type_id) {
      setSalesType([user?.branch?.sales_settings?.sales_type_id]);
      setFormData((prev) => ({
        ...prev,
        sales_type_id: user?.branch?.sales_settings?.sales_type_id ?? "",
        sales_type_id_ref: user?.branch?.sales_settings?.sales_type_id
          ? {
            label: user?.branch?.sales_settings?.sales_type_name ?? "",
            value: user?.branch?.sales_settings?.sales_type_id ?? "",
          }
          : "",
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <>
      <Modal show={formMode} fullscreen>
        <DataProcessing show={dataProcessing} />
        <Modal.Header className="d-flex align-items-center justify-content-between border-bottom">
          <div>
            <h5 className="form-heading">
              {salesQuotationId ? (
                <FaEdit size={25} className="text-dark me-1" />
              ) : (
                <BiMoneyWithdraw size={25} className="text-dark me-1" />
              )}
              {salesQuotationId ? (
                <>
                  {`Edit Quotation`} -{" "}
                  <Badge className="ms-2 bg-warning">
                    {editItemData.reference}
                  </Badge>
                </>
              ) : (
                "Add Quotation"
              )}
            </h5>
          </div>
          <div>
            <Button
              size="sm"
              className="custom-transperent-common-button"
              variant="transparent"
              onClick={(e) => handleFormVisibility(false)}
            >
              <FaTimesCircle size={20} color="#f15454" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            {!loading ? (
              <Form>
                <SimpleBar className="simplebar-style-view-page">
                  {Object.keys(formError).length > 0 ? (
                    <ErrorAlert
                      setFormError={setFormError}
                      formError={formError}
                    />
                  ) : (
                    ""
                  )}
                  <Row>
                    <CustomerInputField
                      formData={formData}
                      handleCustomer={handleCustomer}
                      formError={formError}
                      editDisable={editDisplay}
                      setFormData={setFormData}
                      id={salesQuotationId}
                    />
                    <PaymentTermInputField
                      formData={formData}
                      handlePaymentTerms={handlePaymentTerms}
                      formError={formError}
                      setFormData={setFormData}
                    />
                    <CostCenterInputField
                      formData={formData}
                      handleCostCenter={handleCostCenter}
                      formError={formError}
                      setFormData={setFormData}
                    />
                    <DateInputField
                      title="Date"
                      name="trans_date"
                      value={formData.trans_date}
                      error={formError.trans_date}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                      required
                    />
                    <DateInputField
                      title="Expected Delivery Date"
                      name="expected_delivery_date"
                      value={formData.expected_delivery_date}
                      error={formError.expected_delivery_date}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                    />
                    <EmailInputField
                      formData={formData}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                    />
                    <ContactNumberInputField
                      formData={formData}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                    />
                    <CustomerRef
                      formData={formData}
                      formError={formError}
                      handleFieldChange={handleFieldChange}
                    />
                    <SalesmanInputField
                      formData={formData}
                      handleSalesman={handleSalesman}
                      formError={formError}
                      setFormData={setFormData}
                    />
                    <SalesTypeInputField
                      formData={formData}
                      handleSalesType={handleSalesType}
                      formError={formError}
                      setFormData={setFormData}
                      setSalesType={setSalesType}
                      title="Sales Type"
                    />
                  </Row>
                  <QuotationItemTable
                    entries={formData.details}
                    setFormData={setFormData}
                    formError={formError}
                    setSubTotal={setSubTotal}
                    setNetDiscount={setNetDiscount}
                    setTax={setTax}
                    salesQuotationId={salesQuotationId}
                    salesType={salesType}
                    setFormError={setFormError}
                    kitItems={kitItems}
                    setKitItems={setKitItems}
                    kitFullDetails={kitFullDetails}
                    setKitFullDetails={setKitFullDetails}
                    formData={formData}
                    transDate={transDate}
                    setUpdateEnable={true}
                    setDataProcessing={setDataProcessing}
                    taxType={taxType}
                  />
                  <Row className="d-flex align-items-end">
                    <Col xs={6}>
                      <Form.Group>
                        <Form.Label>{"Terms & Conditions"}</Form.Label>
                        <div className="d-flex">
                          <div style={{ width: "20rem" }}>
                            <SelectTermsAndCondition
                              name="terms"
                              value={termsFormData.terms_ref}
                              handleFieldChange={handleQuotationTerms}
                              placeholder="Select Terms & Conditions"
                            />
                          </div>
                          <div
                            className=" bg-primary d-flex align-items-center justify-content-center ps-2 pe-2 cursor-pointer text-light"
                            onClick={addQuotationTerms}
                          >
                            {"ADD"}
                          </div>
                        </div>
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <Total
                        subTotal={subTotal}
                        netDiscount={netDiscount}
                        tax={tax}
                        taxType={taxType}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6} className="pt-4">
                      <Form.Group>
                        <TextEditor
                          name="terms_and_conditions"
                          handleFieldChange={handleFieldChange}
                          placeholder=""
                          value={formData.terms_and_conditions}
                          HideMedia={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={6}>
                      <MemoInputField
                        value={formData.memo}
                        formError={formError}
                        handleFieldChange={handleFieldChange}
                        name="memo"
                        title="Notes"
                        error={formError.memo}
                      />
                    </Col>
                  </Row>
                </SimpleBar>

              </Form>
            ) : (
              <LoadingScreenCover className="mt-5 min-vh-75 p-0 shadow-none">
                <LoadingCommon loadingText={"Looking for Quotation..."} />
              </LoadingScreenCover>
            )}
          </Container>

        </Modal.Body>
        <Card.Footer className="border-top d-flex gap-2">
          <SaveButton
            onSave={onSave}
            handleSubmit={handleSubmit}
            id={salesQuotationId}
          />
          <CancelButton
            onSave={onSave}
            handleCancel={() => handleFormVisibility(false)}
          />
        </Card.Footer>
      </Modal>
    </>
  );
};

export default QuotationForm;
