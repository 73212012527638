import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import PropTypes from "prop-types";

import { apiCall } from "helpers/apiCalls";
import QuickInfoTile from "./QuickInfoTile/QuickInfoTile";
import ReportForThisWeek from "./ReportForThisWeek/ReportForThisWeek";
import RecentActivity from "./RecentActivity";
import ProjectChart from "./ProjectChart";
import ProjectInfo from "./ProjectInfo";
import TotalWorkHours from "./TotalWorkHours";
import ProjectCost from "./ProjectCost";
import ProjectDescription from "./ProjectDescription";
import ToDoList from "./TodoList";
import ProjectMembers from "./ProjectMembers";
import useAxisproPermission from "hooks/useAxisproPermission";

function OverViewTab({
  project,
  todo,
  loading,
  taskApiCalling,
  membersList,
  setMembersList,
}) {
  const { itemInfoId } = useParams();
  const [dashboardData, setDashboardData] = useState({});
  const [dashboardLoding, setDashboardLoding] = useState(false);
  const [dataProcessing, setDataProcessing] = useState(false);
  const axisProPermission = useAxisproPermission();
  const getDashboardData = async () => {
    setDashboardLoding(true);
    try {
      const data = await apiCall({
        url: `crm/project-dashboard-summary/${itemInfoId}`,
      });
      setDashboardData(data);
      setDashboardLoding(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemInfoId]);

  return (
    <>
      <Row className="g-2 mb-2 mt-2 d-flex flex-wrap">
        <Col xs={12} md={4}>
          <ProjectChart
            taskAnalytics={dashboardData?.task_analytics}
            loading={dashboardLoding}
          />
        </Col>
        <Col xs={12} md={4}>
          <ProjectInfo
            projectAnalytics={dashboardData?.project_analytics}
            loading={dashboardLoding}
          />
        </Col>
        <Col xs={12} md={4} className="d-flex flex-column gap-2">
          <Row className="g-2">
            <Col xs={6}>
              <TotalWorkHours
                hour={project?.total_working_hr}
                loading={loading}
              />
            </Col>
            <Col xs={6}>
              <ProjectCost price={project?.price} loading={loading} />
            </Col>
            <Col lg={12}>
              <QuickInfoTile
                project={project}
                loading={dataProcessing}
                taskApiCalling={taskApiCalling}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="g-2 mb-2">
        <Col xs={12} md={4}>
          <ProjectDescription
            description={project?.description}
            loading={loading}
          />
        </Col>
        <Col xs={12} md={6} sm={12}>
          <Row className="g-2 h-100">
            <Col lg={12} className="h-100">
              <ReportForThisWeek
                reportData={dashboardData?.last_two_weeks_project_time_tracker}
                loading={dashboardLoding}
              />
            </Col>
          </Row>
        </Col>
        {axisProPermission("list-project-members") && (
          <Col xs={12} md={2}>
            <ProjectMembers
              projectId={project.id}
              setDataProcessing={setDataProcessing}
              dataProcessing={dataProcessing}
              membersList={membersList}
              setMembersList={setMembersList}
            />
          </Col>
        )}
      </Row>
      <Row className="g-2">
        {axisProPermission("show-log-history") && (
          <Col xs={12} md={6}>
            <RecentActivity projectId={project.id} loading={dashboardLoding} />
          </Col>
        )}
        {axisProPermission("list-task") && (
          <Col xs={12} md={axisProPermission("show-log-history") ? 6 : 12}>
            <ToDoList todo={todo} loading={taskApiCalling} />
          </Col>
        )}
      </Row>
    </>
  );
}

OverViewTab.propTypes = {
  loading: PropTypes.bool,
};

export default OverViewTab;
