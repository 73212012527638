import { React, useEffect, useState } from "react";
import axios from "axios";
import {
  Badge,
  Button,
  Dropdown,
  Form,
  InputGroup,
  Spinner,
} from "react-bootstrap";
import PropTypes from "prop-types";

import { MdAddCircle } from "react-icons/md";
import { AiFillCloseCircle, AiOutlinePercentage } from "react-icons/ai";
import { BsCashCoin, BsFillCheckCircleFill } from "react-icons/bs";
import useAxisproTranslate from "hooks/useAxisproTranslate";
import { showToast } from "module/common/Toast/toast";
import { fetchSalesTypePrice } from "module/common/Api/SalesTypePrice";
import { handleEnterButton } from "module/common/Functions/enterKeyFunctionality";
import {
  checkKitAlreadySelectedOrNot,
  descriptionField,
  discountValidations,
  quantityValidations,
  unitPriceValidations,
} from "module/common/Validators/SalesModuleValidatores";
import SearchAndSelectItem from "module/common/Search/SearchAndSelectItem";
import QuantityInputField from "module/common/InputFields/Common/QuantityInputField";

const QuotationItemTableForm = ({
  handleSubmit,
  formData,
  editItemData,
  onCancel,
  index,
  setDiscountMethod,
  discountMethod,
  onUpdate,
  // salesType,
  setItems,
  kitItems,
  setKitFullDetails,
  setDataProcessing,
}) => {
  const Translate = useAxisproTranslate();
  const [tableData, setTableData] = useState(
    editItemData
      ? editItemData
      : {
          stock_id: "",
          stock_id_ref: "",
          quantity: "",
          discount_amount: "",
          discount_percent: "",
          item_display_name: null,
          item_display_name_ref: null,
          item_description: "",
          unit_price: "",
          sales_type_id: "",
          unit_name: "",
          unit_tax: "",
          trans_date: "",
        }
  );
  const handleFieldChange = (e, action) => {
    let name, value, itemsWithNewData;

    if (formData?.sales_type_id) {
      if (
        e &&
        e.target &&
        e.target.name === "stock_id_ref" &&
        e?.target?.value?.length > 2
      ) {
        //When entering the stock id need to fetch the item details
        // let id = salesType;
        // const addSalesType = { sales_type_id: salesType[0] };
        // let tableData = Object.assign(addSalesType)
        //Currently there is no option to fetch kit by entering kit id so is_kit is set to 0 - Need to find a solution for this
        axios
          .get(
            `inventory/sales-type-price/${formData.sales_type_id}/${e.target.value}`
          )
          .then((response) => {
            if (response?.data?.success) {
              setTableData({
                ...tableData,
                item_display_name: response.data.data.item_name,
                item_display_name_ref: {
                  label: response.data.data.item_name,
                  value: response.data.data.item_id,
                },
                stock_id_ref: e.target.value,
                item_description: response.data.data.item_description
                  ? response.data.data.item_description
                  : "",
                stock_id: response.data.data.stock_id,
                sales_type_id: formData.sales_type_id,
                unit_price: response.data.data.price,
                unit_name: response.data.data.unit_name,
                unit_tax: "0",
                qoh: response.data.data.qoh,
                line_total: "0",
                quantity: 1,
                trans_date: formData.trans_date,
                is_kit: 0,
                discount_amount: 0,
                discount_percent: 0,
              });
            }
          })
          .catch((error) => {
            if (error) {
              setTableData({
                ...tableData,
                stock_id_ref: e.target.value,
              });
            }
          });
      }
    } else {
      if (!formData?.sales_type_id) {
        showToast(Translate("Please select sales type"), "error");
      }
    }
    if (e && e.target && e.target.name === "discount_amount") {
      name = e.target.name;
      if (e.target.value < 0) {
        value = 0;
      } else {
        value = e.target.value;
      }
      itemsWithNewData = {
        ...tableData,
        [name]: value,
      };
    } else if (e && e.target && e.target.name !== "discount_amount") {
      name = e.target.name;
      value = e.target.value;
      itemsWithNewData = {
        ...tableData,
        [name]: value,
      };
    } else if (action) {
      name = action.name;
      value = e;
      itemsWithNewData = {
        ...tableData,
        [name]: value.value,
        [name + "_ref"]: value,
      };
    }
    if (itemsWithNewData) {
      setTableData(itemsWithNewData);
    }
  };

  const handleAddTableData = (e) => {
    e.preventDefault();
    if (
      discountValidations(
        tableData.discount_amount,
        tableData.unit_price,
        tableData.discount_percent,
        discountMethod,
        Translate("Unit price should be greater than discount")
      ) &&
      unitPriceValidations(
        tableData.unit_price,
        Translate("Unit price should be greater than zero!")
      ) &&
      quantityValidations(
        tableData.quantity,
        Translate("Quantity should be greater than zero!")
      ) &&
      descriptionField(tableData.item_description)
    ) {
      if (!editItemData) {
        handleSubmit(tableData);
        setTableData({
          stock_id: "",
          quantity: "",
          discount_amount: "",
          discount_percent: "",
          item_display_name: "",
          item_display_name_ref: "",
          item_description: "",
          unit_price: "",
          sales_type_id: "",
          unit_name: "",
          unit_tax: "",
        });
      } else {
        // const addSalesType = { sales_type_id: salesType[0] };
        const addTransDate = { trans_date: formData.trans_date };
        let editData = Object.assign(tableData, addTransDate);
        handleSubmit(editData, index);
      }
    }
  };

  //To select item and to fetch its details
  const handleItem = (valueObject, action) => {
    if (action?.action === "clear") {
      setTableData(() => ({
        item_display_name: "",
        item_display_name_ref: {},
        item_description: "",
        stock_id: "",
        stock_id_ref: "",
        sales_type_id: "",
        trans_date: "",
        unit_price: "",
        unit_name: "",
        unit_tax: "0",
        qoh: "",
        line_total: "0",
        quantity: 1,
        is_kit: 0,
        discount_amount: 0,
        discount_percent: 0,
      }));
    } else {
      setDataProcessing(true);
      if (
        checkKitAlreadySelectedOrNot(
          kitItems,
          valueObject.value,
          Translate("The kit is already selected!")
        )
      ) {
        // let desc = valueObject && valueObject.label ? valueObject.label : null;
        let itemId =
          valueObject && valueObject.item
            ? valueObject.item.stock_id
            : valueObject.value;
        if (formData.sales_type_id) {
          fetchSalesTypePrice(
            valueObject.label,
            formData,
            itemId,
            valueObject.type,
            valueObject.id,
            setTableData,
            setKitFullDetails,
            setItems,
            setDataProcessing,
            Translate
          );
        } else {
          showToast("Sales Type is required to select an Item!", "error");
          setDataProcessing(false);
        }
      } else {
        setDataProcessing(false);
      }
    }
  };

  const changeDependsOnDiscountMethod = () => {
    if (discountMethod) {
      if (!tableData.discount_percent) {
        // Recently addedd
        setTableData({
          ...tableData,
          discount_percent: tableData.discount_amount,
        });
      }
      delete tableData.discount_amount;
    } else if (!discountMethod) {
      if (!tableData.discount_amount) {
        // Recently addedd
        setTableData({
          ...tableData,
          discount_amount: tableData.discount_percent,
        });
      }
      delete tableData.discount_percent;
    }
  };

  useEffect(() => {
    changeDependsOnDiscountMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountMethod]);

  useEffect(() => {
    if (editItemData && Object.keys(editItemData).length > 0) {
      setTableData({
        ...editItemData,
        stock_id_ref: editItemData.stock_id,
        item_display_name_ref: {
          label: editItemData.item_display_name,
          value: editItemData.item_id,
        },
      });
    }
  }, [editItemData]);

  useEffect(() => {
    if (formData?.sales_type_id && tableData?.stock_id && !editItemData) {
      fetchSalesTypePrice(
        tableData.item_display_name,
        formData,
        tableData.stock_id,
        tableData.is_kit === 1 ? "kit" : "item",
        tableData.id,
        setTableData,
        setKitFullDetails,
        setItems,
        setDataProcessing,
        Translate,
        tableData.item_description ? tableData.item_description : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData?.sales_type_id]);

  return (
    <tr style={{ borderBottom: editItemData ? "" : "1.5px dashed #ccc" }}>
      <td className="fs--1 p-1 text-center text-dark">#</td>
      <td className="p-1">
        <Form.Control
          type="text"
          name="stock_id_ref"
          placeholder="Enter item code"
          onChange={handleFieldChange}
          value={
            tableData.stock_id_ref
              ? tableData.stock_id_ref
              : tableData.kit_id
              ? tableData.kit_id
              : ""
          }
          onKeyDown={(e) => handleEnterButton(e, handleAddTableData)}
        />
      </td>
      <td className="p-1">
        <div className="d-flex gap-1 flex-column">
          <SearchAndSelectItem
            value={tableData.item_display_name_ref}
            handleFieldChange={handleItem}
            name={tableData.item_display_name_ref}
            advanceSearch
            keyName="item_name"
            advanceSearchType="sales"
            isClearable={tableData.stock_id}
          />
          <Form.Control
            as="textarea"
            rows={2}
            name="item_description"
            id="item_description"
            placeholder="Enter Description..."
            onChange={handleFieldChange}
            value={tableData.item_description || ""}
          />
        </div>
      </td>
      <td className="fs--1 p-1 text-center text-dark">
        <div className="d-flex gap-1">
          <QuantityInputField
            name="quantity"
            className="text-center me-1"
            onChange={handleFieldChange}
            value={tableData.quantity}
            unit={tableData.unit_name}
            onKeyDown={(e) => handleEnterButton(e, handleAddTableData)}
          />
          <div
            className="d-flex align-items-center justify-content-center w-25"
            style={{ cursor: "pointer" }}
            title={Translate("Quantity in hand")}
          >
            <Badge bg="danger">{tableData.qoh}</Badge>
          </div>
        </div>
      </td>
      <td className="p-1 fs--1 text-uppercase text-center text-dark">
        <span>{tableData.unit_name}</span>
      </td>
      <td className="p-1 fs--1 text-center text-dark">
        {tableData.is_kit === 1 ? (
          parseFloat(tableData.unit_price).toLocaleString(navigator.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        ) : (
          <Form.Control
            type="number"
            name="unit_price"
            onChange={handleFieldChange}
            value={tableData.unit_price || ""}
            onKeyDown={(e) => handleEnterButton(e, handleAddTableData)}
          />
        )}
      </td>
      <td className="p-1 fs--1 text-center text-dark">
        <span>
          {tableData.unit_tax
            ? parseFloat(tableData.unit_tax).toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            : ""}
        </span>
      </td>
      <td className="p-1 fs--1 text-center text-dark">
        {tableData.is_kit === 1 ? (
          "-"
        ) : (
          <InputGroup>
            {!discountMethod ? (
              <Form.Control
                type="number"
                name="discount_amount"
                min="0"
                placeholder="Enter Discount Amount"
                onChange={handleFieldChange}
                value={tableData.discount_amount || ""}
                onKeyDown={(e) =>
                  handleEnterButton(e, handleAddTableData, "submitButton")
                }
              />
            ) : (
              <Form.Control
                type="number"
                name="discount_percent"
                min="0"
                placeholder="Enter Discount Percentage"
                onChange={handleFieldChange}
                value={tableData.discount_percent || ""}
                onKeyDown={(e) =>
                  handleEnterButton(e, handleAddTableData, "submitButton")
                }
              />
            )}
            <Dropdown size="sm" drop="down">
              <Dropdown.Toggle
                variant="outline-secondary"
                id="dropdown-basic"
                className="discount-conversion-button"
              >
                {!discountMethod ? (
                  <BsCashCoin color="black" />
                ) : (
                  <AiOutlinePercentage color="black" />
                )}
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ minWidth: "10px", padding: "5px" }}>
                <Dropdown.Item onClick={() => setDiscountMethod(false)}>
                  <BsCashCoin color="black" />
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setDiscountMethod(true)}>
                  <AiOutlinePercentage color="black" />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup>
        )}
      </td>
      <td className="p-1 fs--1 text-center text-dark">
        {tableData.line_total
          ? parseFloat(tableData.line_total).toLocaleString(
              navigator.language,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )
          : ""}
      </td>
      <td className="p-1 text-center">
        <div className="d-flex gap-1 aling-items-center justify-content-center">
          <Button
            id="submitButton"
            variant="transparent"
            className="text-success p-0"
            disabled={
              (!tableData.stock_id && !tableData.kit_id) ||
              !tableData.quantity ||
              !tableData.item_display_name ||
              onUpdate
            }
            style={{ cursor: "pointer", boxShadow: "none" }}
            onClick={handleAddTableData}
          >
            {editItemData ? (
              onUpdate ? (
                <Spinner
                  animation="border"
                  role="status"
                  variant="dark"
                  size="sm"
                  style={{
                    width: "1rem",
                    height: "1rem",
                    borderWidth: "0.15rem",
                  }}
                />
              ) : (
                <BsFillCheckCircleFill className="mx-1" />
              )
            ) : (
              <MdAddCircle size={20} />
            )}
          </Button>
          {editItemData && onCancel && (
            <Button
              size="sm"
              variant="transparent"
              className="text-danger p-0"
              onClick={onCancel}
              style={{ cursor: "pointer", boxShadow: "none" }}
            >
              <AiFillCloseCircle size={20} />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

QuotationItemTableForm.propTypes = {
  handleSubmit: PropTypes.func,
  editItemData: PropTypes.object,
  onCancel: PropTypes.func,
  index: PropTypes.number,
  type: PropTypes.number,
  setDiscountMethod: PropTypes.func,
  discountMethod: PropTypes.bool,
  onUpdate: PropTypes.bool,
  salesType: PropTypes.array,
  setItems: PropTypes.func,
  setKitFullDetails: PropTypes.func,
  kitItems: PropTypes.any,
  formData: PropTypes.any,
  setDataProcessing: PropTypes.any,
};

export default QuotationItemTableForm;
