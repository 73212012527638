import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Image,
  ListGroup,
  ListGroupItem,
  OverlayTrigger,
  Row,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import ProfileImage from "../../../../../assets/img/Avathar/profile.png";
import { FaPlus, FaTimesCircle } from "react-icons/fa";
import { apiCall } from "helpers/apiCalls";
import MultiSelectCollaborator from "components/form/MultiSelectCollaborator";
import { collaboratorsFormKeys } from "helpers/formKeys";
import { DeleteContext } from "context/Context";
import ListLoading from "module/common/ListLoading";
import { showToast } from "module/common/Toast/toast";
import axios from "axios";
import removeRefData from "helpers/removeRefData";
import useAxisproPermission from "hooks/useAxisproPermission";

const Collaborator = ({ taskId, assignee_id, setCollabList, scroll }) => {
  const [dataProcessing, setDataProcessing] = useState(false);
  const [collabData, setCollabData] = useState({});
  const [formData, setFormData] = useState(collaboratorsFormKeys);
  const { dispatch } = useContext(DeleteContext);
  const [onSave, setOnSave] = useState(false);
  const axisProPermission = useAxisproPermission();
  const [storeTaskCollaboratorPermission, deleteTaskCollaboratorPermission] = [
    "store-task-collaborator",
    "delete-task-collaborator",
  ].map((permission) => axisProPermission(permission));

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/task-collaborator/" + id,
        title: "Remove Collaborator",
        message:
          "Are you sure you want to get that collaborator out of this task?",
        onSuccess: () => {
          const dataWithoutDeletedItem = collabData.data.filter(
            (item) => item.id !== id
          );

          setCollabData({
            ...collabData,
            data: dataWithoutDeletedItem,
          });
          if (setCollabList) {
            setCollabList(dataWithoutDeletedItem);
          }

          // reset deleteContext
          dispatch({
            type: "RESET",
          });
        },
      },
    });
  };

  const handleSubmit = (e) => {
    setOnSave(true);
    e.preventDefault();
    let formDataWithoutRefkeys = removeRefData(formData);
    formDataWithoutRefkeys?.collaborators?.length > 0 &&
      axios({
        method: "post",
        url: `crm/task-collaborator`,
        data: formDataWithoutRefkeys,
      }).then((response) => {
        if (response.data.success === true) {
          setOnSave(false);
          getData();
          setFormData({
            ...formData,
            task_id: taskId,
          });
          showToast(response.data.message, "success");
        } else {
          setOnSave(false);
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
      });
  };

  useEffect(() => {
    taskId && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskId]);

  const getData = async () => {
    setDataProcessing(true);
    const data = await apiCall({
      url: `crm/task-collaborator`,
      params: {
        filters: [{ column: "task_id", operator: "=", value: taskId }],
      },
    });
    if (setCollabList) {
      setCollabList(data.data);
    }
    setCollabData(data);
    setFormData({
      ...formData,
      task_id: taskId,
      collaborators: [],
      collaborators_ref: [],
    });

    setDataProcessing(false);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;

    if (action) {
      if (action.action === "select-option") {
        const labelArray = e.map((item) => item.value);
        value = labelArray;
      }
      if (action.action === "clear") {
        value = null;
      }
    }

    newFormData = {
      ...formData,
      [action.name]: value,
      [action.name + "_ref"]: e,
    };
    setFormData(newFormData);
  };

  return (
    <ListGroup>
      {storeTaskCollaboratorPermission && (
        <ListGroupItem className="border-0 ps-0 pe-0 mt-0">
          <div className="d-flex gap-3">
            <div className="w-100">
              <MultiSelectCollaborator
                name="collaborators"
                handleFieldChange={handleFieldChange}
                value={formData.collaborators_ref}
                excludeUser={collabData.data}
                assignee_id={assignee_id}
              />
            </div>
            <div className="d-flex align-items-center">
              <Button
                size="sm"
                onClick={handleSubmit}
                disabled={formData?.collaborators?.length > 0 ? false : true}
              >
                {onSave ? (
                  <Spinner style={{ width: "18px", height: "18px" }} />
                ) : (
                  <FaPlus size={13} />
                )}
              </Button>
            </div>
          </div>
        </ListGroupItem>
      )}

      <div
        style={{
          maxHeight: `${scroll ? "180px" : ""}`,
          height: `${scroll ? "180px" : ""}`,
          overflow: `${scroll ? "hidden" : ""}`,
          overflowY: `${scroll ? "scroll" : ""}`,
        }}
      >
        {!dataProcessing && collabData?.data?.length > 0 ? (
          collabData.data.map((collab, key) => (
            <ListGroupItem key={key}>
              <Row>
                <Col md={8} sm={8} xs={8} className="d-flex flex-row gap-2">
                  <span>
                    <Image
                      className=""
                      src={collab.user.image ?? ProfileImage}
                      roundedCircle
                      alt="?"
                      height={40}
                      width={40}
                      loading="lazy"
                    />
                  </span>
                  <div>
                    <span className="fw-bold d-block">{collab.user.name}</span>
                    <span className="d-block">
                      {collab.user.roles.length > 0 &&
                        collab.user.roles.map((role) => role.name)}
                    </span>
                  </div>
                </Col>
                {deleteTaskCollaboratorPermission && (
                  <Col
                    md={4}
                    sm={4}
                    xs={4}
                    className="d-flex justify-content-end align-items-center"
                  >
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="button-tooltip-2">Remove</Tooltip>}
                    >
                      <Button
                        variant="transparent"
                        className="custom-transperent-common-button me-2"
                        onClick={() => {
                          handleDelete(collab.id);
                        }}
                      >
                        <FaTimesCircle color="red" />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                )}
              </Row>
            </ListGroupItem>
          ))
        ) : dataProcessing ? (
          <ListLoading style={{ maxHeight: "9vh", overflow: "hidden" }} />
        ) : (
          <ListGroupItem className="border-0">
            <div
              className="d-flex justify-content-center align-items-center h-100"
              style={{ minHeight: "9vh" }}
            >
              <span className="">No Collaborators Yet &#128521;</span>
            </div>
          </ListGroupItem>
        )}
      </div>
    </ListGroup>
  );
};

export default Collaborator;
