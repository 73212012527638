import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import qs from "qs";
import { apiCall } from "helpers/apiCalls";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import AddButton from "components/add-button/AddButton";
import { FaPeopleArrows } from "react-icons/fa";
import { DeleteContext } from "context/Context";
import SimpleBar from "simplebar-react";
import "./CampaignTableView.scss";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";
import { FilterCommonButton } from "module/common/Buttons/CommonButton";
import ExportButton from "components/export-button/ExportButton";
import CommonFilter from "components/filter/CommonFilter";
import { commonCampaignFilterFormKeys } from "helpers/formKeys";

const CampaignTableView = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [campaigns, setCampaigns] = useState({});
  const pageNumber = queryParams.get("page");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [filterFields, setFilterFields] = useState({});
  const [createPermission, updatePermission, deletePermission] = [
    "store-campaign",
    "update-campaign",
    "delete-campaign",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;
  const [filterVisibility, setFilterVisibility] = useState(true);
  const {
    name,
    sponsor,
    type,
    status,
    product_id,
    date_range_for,
    from_date,
    to_date,
    assigned_to,
    location,
  } = allQueryParams;

  const handleDelete = (data) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: data,
        url: "/crm/campaign/" + data,
        title: "Delete Campaign",
        message: "Are you sure you want to delete this campaign?",
        onSuccess: () => {
          const dataWithoutDeletedItem = campaigns.data.filter(
            (account) => account.id !== data
          );
          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/campaign?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setCampaigns((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "active":
        return "success";
      case "inactive":
        return "danger";
      case "planning":
        return "secondary";
      case "upcoming":
        return "info";
      case "pending":
        return "warning";
      case "completed":
        return "primary";
      default:
        return "dark";
    }
  };
  const columns = [
    {
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { name, status, id } = rowData.row.original;
        return (
          <div className="campaign d-flex">
            <OverlayTrigger
              placement="right"
              overlay={<Tooltip id={id}>{`${name} is ${status}`}</Tooltip>}
            >
              <div
                className={`status-circle bg-${getStatusColor(status)}`}
              ></div>
            </OverlayTrigger>

            <h5 className="mb-0 ms-1 fs--1 ">{name}</h5>
          </div>
        );
      },
    },
    {
      accessor: "type",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { type } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{type}</h5>;
      },
    },
    {
      accessor: "sponsor",
      Header: "SPONSOR",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { sponsor } = rowData.row.original;
        return <h5 className="mb-0 fs--1 ">{sponsor}</h5>;
      },
    },
    {
      accessor: "product",
      Header: "PRODUCT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { product } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{product?.name}</h5>;
      },
    },
    {
      accessor: "assigned_user_full_name",
      Header: "ASSIGNED TO",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { assigned_user_full_name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{assigned_user_full_name}</h5>;
      },
    },
    {
      accessor: "location",
      Header: "LOCATION",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { location } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{location}</h5>;
      },
    },
    {
      accessor: "budget",
      Header: "BUDGET",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { budget } = rowData.row.original;
        return <h5 className="mb-0 fs--1 ">{budget}</h5>;
      },
    },
    {
      accessor: "revenue",
      Header: "REVENUE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { revenue } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{revenue}</h5>;
      },
    },
    {
      accessor: "start_date_formatted",
      Header: "START DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3  custom-table-body-text",
      },
      Cell: (rowData) => {
        const { start_date_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1 ">{start_date_formatted}</h5>;
      },
    },
    {
      accessor: "end_date_formatted",
      Header: "END DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3  custom-table-body-text",
      },
      Cell: (rowData) => {
        const { end_date_formatted } = rowData.row.original;
        return <h5 className="mb-0 fs--1 ">{end_date_formatted}</h5>;
      },
    },
    {
      accessor: "status",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { status } = rowData.row.original;
        return (
          <Badge
            bg={
              status === "active"
                ? "success"
                : status === "inactive"
                ? "danger"
                : status === "planning"
                ? "secondary"
                : status === "upcoming"
                ? "info"
                : status === "pending"
                ? "warning"
                : status === "completed"
                ? "primary"
                : "dark"
            }
            className="fs--2 text-capitalize"
          >
            {status}
          </Badge>
        );
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex align-items-center justify-content-center gap-2">
            {updatePermission && (
              <EditButton
                route={`/campaign/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page) => {
    setIsLoading(true);
    setFilterFields({
      name: name,
      sponsor: sponsor,
      type: type,
      status: status,
      product_id: product_id,
      date_range_for: date_range_for,
      from_date: from_date,
      to_date: to_date,
      assigned_to: assigned_to,
      location: location,
    });
    const data = await apiCall({
      url: "crm/campaign",
      params: {
        page: page,
        name: name,
        sponsor: sponsor,
        type: type,
        status: status,
        product_id: product_id,
        date_range_for: date_range_for,
        from_date: from_date,
        to_date: to_date,
        assigned_to: assigned_to,
        location: location,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setCampaigns(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    name,
    sponsor,
    type,
    status,
    product_id,
    date_range_for,
    from_date,
    to_date,
    assigned_to,
    location,
  ]);

  return (
    <>
      <Outlet context={[getData]} />
      <Card className="flex-fill mb-3 mt-3">
        <Card.Header className="p-0">
          <div
            className={`d-flex justify-content-between p-3 ${
              !filterVisibility ? "border-bottom" : ""
            }`}
          >
            <div className="d-flex  align-items-center">
              <FaPeopleArrows size={22} className="me-2 text-dark" />
              <span className="fs-1 text-uppercase">Campaigns List</span>
            </div>
            <div className="d-flex gap-2">
              <ExportButton
                url="crm/campaign-export"
                initialMessage={"Campaign export started"}
                successMessage={"Campaign export completed"}
                file_type={"pdf"}
                variant={"danger"}
                filters={filterFields}
              />
              {createPermission && (
                <AddButton
                  to={`/campaign/add?${createSearchParams({
                    ...allQueryParams,
                  })}`}
                />
              )}
              <FilterCommonButton
                filterVisibility={filterVisibility}
                setFilterVisibility={setFilterVisibility}
              />
            </div>
          </div>
          <div style={{ display: filterVisibility ? "block" : "none" }}>
            <CommonFilter filterKeys={commonCampaignFilterFormKeys} />
          </div>
        </Card.Header>
        {!isLoading &&
        campaigns &&
        campaigns.data &&
        campaigns.data.length > 0 ? (
          <AdvanceTableWrapper
            columns={columns}
            data={campaigns?.data}
            sortable
          >
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {campaigns && campaigns?.links && (
                <AppPagination
                  data={campaigns}
                  url="/campaign"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </AdvanceTableWrapper>
        ) : (
          <LoadingScreenCover>
            {isLoading ? (
              <LoadingCommon loadingText="Looking for Campaigns" />
            ) : (
              <h5 className="text-center text-secondary">
                Campaigns not found! Let's add it &#128578;
              </h5>
            )}
            {!isLoading && (
              <div className="d-flex flex-wrap justify-content-center mt-3">
                {createPermission && (
                  <Button
                    className="m-1"
                    size="sm"
                    as={Link}
                    to={`/campaign/add?${createSearchParams({
                      ...allQueryParams,
                    })}`}
                    variant="success"
                  >
                    Add Campaign
                  </Button>
                )}
              </div>
            )}
          </LoadingScreenCover>
        )}
      </Card>
    </>
  );
};

export default CampaignTableView;
