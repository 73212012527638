import { React, useContext, useEffect, Fragment, useState } from "react";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import classNames from "classnames";
import { capitalize } from "helpers/utils";
import { navbarBreakPoint, topNavbarBreakpoint } from "config";
import AppContext, { AuthWizardContext } from "context/Context";
import Flex from "components/common/Flex";
import Logo from "components/common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
// import routes from "routes/routes";
import NavbarTopDropDownMenus from "components/navbar/top/NavbarTopDropDownMenus";
import bgNavbar from "assets/img/generic/bg-navbar.png";

const NavbarVertical = () => {
  const {
    config: {
      navbarPosition,
      navbarStyle,
      isNavbarVerticalCollapsed,
      showBurgerMenu,
    },
  } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  const { user } = useContext(AuthWizardContext);

  const getNestedChildren = (children, disabled = false) => {
    if (!children) return null;

    return children?.map((child) => {
      const nestedChildren = getNestedChildren(
        child?.children,
        child?.children_disabled
      );
      return {
        name: child?.name,
        pathName: child?.pathName,
        to: child?.to,
        icon: child?.icon,
        children: !disabled ? nestedChildren : null,
      };
    });
  };

  const generateRoutes = (user) => {
    const topLevelRoutes = user?.menu_links?.map((route) => {
      const nestedChildren = getNestedChildren(
        route?.children,
        route?.children_disabled
      );
      return {
        label: route?.name,
        labelDisable: true,
        childDisabled: route?.children_disabled ? true : false,
        children: [
          {
            name: route?.name,
            pathName: route?.pathName,
            to: route?.to,
            icon: route?.icon,
            size: route?.icon_size,
            children: route?.children_disabled ? null : nestedChildren,
          },
        ],
      };
    });

    if (!user.customer_id) {
      topLevelRoutes.unshift({
        label: "Dashboard",
        labelDisable: true,
        children: [
          {
            name: "Dashboard",
            to: "dashboard",
            icon: "chart-pie",
            size: 17,
            children: null, // Assuming no nested children for the Dashboard
          },
        ],
      });
    }

    return topLevelRoutes;
  };

  const [routes] = useState(generateRoutes(user));

  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    } else {
      HTMLClassList.remove("navbar-vertical-collapsed");
    }
    return () => {
      HTMLClassList.remove("navbar-vertical-collapsed-hover");
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add("navbar-vertical-collapsed-hover");
      }, 100);
    }
  };
  const handleMouseLeave = () => {
    clearTimeout(time);
    HTMLClassList.remove("navbar-vertical-collapsed-hover");
  };

  const NavbarLabel = ({ label }) => (
    <Nav.Item as="li">
      <Row className="mt-3 mb-2 navbar-vertical-label-wrapper">
        <Col xs="auto" className="navbar-vertical-label navbar-vertical-label">
          {label}
        </Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
      style={{ backgroundColor: "#191A24" }}
    >
      <Flex alignItems="center">
        <ToggleButton />
        <Logo
          at="navbar-vertical"
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          width={40}
        />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div
          className="navbar-vertical-content scrollbar"
          style={{
            backgroundColor: "#191A24",
          }}
        >
          <div className="">
            <Nav className="flex-column" as="ul">
              {routes?.map((route) => (
                <Fragment key={route.label}>
                  {!route.labelDisable && (
                    <NavbarLabel label={capitalize(route.label)} />
                  )}
                  <NavbarVerticalMenu routes={route.children} />
                </Fragment>
              ))}
            </Nav>

            <>
              {navbarPosition === "combo" && (
                <div className={`d-${topNavbarBreakpoint}-none`}>
                  <div className="navbar-vertical-divider">
                    <hr className="navbar-vertical-hr my-2" />
                  </div>
                  <Nav navbar>
                    <NavbarTopDropDownMenus />
                  </Nav>
                </div>
              )}
            </>
          </div>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

NavbarVertical.propTypes = {
  label: PropTypes.string,
};

export default NavbarVertical;
