import React, { useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import {
    createSearchParams,
    useNavigate,
    useSearchParams,
} from "react-router-dom";
import removeEmptyFields from "helpers/removeEmptyFields";
import { leadSourceSummeryReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import DateRangePicker from "module/common/Filter/DateRangePicker";
import removeRefData from "helpers/removeRefData";
import { FcClearFilters } from "react-icons/fc";
import SelectLeadSource from "components/form/SelectLeadSource";

const LeadSourceSummeryReportFilterSection = ({
    filters,
    setFilters,
}) => {
    const navigate = useNavigate();
    const [queryParams] = useSearchParams();
    const filterItems = queryParams.get("filters");

    const handleFieldChange = (e, action) => {
        let newFormData;
        let value;
        if (!action) {
            newFormData = {
                ...filters,
                [e.target.name]: e.target.value,
            };
        } else {
            if (action.action === "select-option") {
                value = e.code ? e.code : e.value;
            }
            newFormData = {
                ...filters,
                [action.name]: value,
                [action.name + "_ref"]: e,
            };
        }
        setFilters(newFormData);
    };

    const handleFilterApply = () => {
        const filteredObject = removeEmptyFields(filters);
        let formDataWithoutRefKeys = removeRefData(filteredObject);

        navigate(
            `/reports/lead-source-summary-report?page=1&${createSearchParams({
                filters: JSON.stringify({
                    ...formDataWithoutRefKeys,
                }),
            })}`
        );
    };

    useEffect(() => {
        if (filterItems) {
            const parsedFilterItems = JSON.parse(filterItems);
            setFilters({
                ...filters,
                ...parsedFilterItems,
            });
        } else {
            setFilters(leadSourceSummeryReportFilterKeys);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterItems]);

    const handleResetFilter = () => {
        const parsedFilterItems = JSON.parse(filterItems);
        const newLeadSourceSummeryItemReportFilterKeys = {
            ...leadSourceSummeryReportFilterKeys,
            date_range: parsedFilterItems?.date_range ? parsedFilterItems?.date_range : "",
            date_range_for: parsedFilterItems?.date_range_for ? parsedFilterItems?.date_range_for : "",
            from_date: parsedFilterItems?.from_date ? parsedFilterItems?.from_date : "",
            to_date: parsedFilterItems?.to_date ? parsedFilterItems?.to_date : "",
        }

        const filteredObject = removeEmptyFields(removeRefData(newLeadSourceSummeryItemReportFilterKeys));
        const parsedFilteredObject = JSON.stringify(filteredObject);
        navigate(`/reports/lead-source-summary-report?${Object.keys(filteredObject).length > 0 ? createSearchParams({ filters: parsedFilteredObject }) : ''}`)
        setFilters(newLeadSourceSummeryItemReportFilterKeys);
    };

    return (
        <Container fluid>
            <Form>
                <Row className="g-3">
                    <Col md={2}>
                        <Form.Group as={Col}>
                            <Form.Label className="fs--1">Date Range</Form.Label>
                            <DateRangePicker
                                formData={filters}
                                className={"custom-date-range-picker"}
                                setFormData={setFilters}
                                dateRange_form={{
                                    to: "to_date",
                                    from: "from_date",
                                    range: "date_range",
                                    month: "month",
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2}>
                        <Form.Group as={Col}>
                            <Form.Label className="require-data">
                                Source
                            </Form.Label>
                            <Col>
                                <SelectLeadSource
                                    name="lead_source_id"
                                    value={filters.lead_source_id_ref}
                                    handleFieldChange={handleFieldChange}
                                />
                            </Col>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="m-0">
                    <Form.Group
                        as={Col}
                        md={2}
                        className="d-flex justify-content-end w-100 p-0"
                    >
                        <div className="d-flex align-items-end gap-2 mt-3">
                            <Button
                                size="sm"
                                variant="warning"
                                onClick={handleFilterApply}
                                title="Apply Added Filters"
                            >
                                Apply
                            </Button>
                            <Button
                                size="sm"
                                variant="transparent"
                                className="shadow-sm"
                                onClick={handleResetFilter}
                                title="Clear All Filters"
                            >
                                <FcClearFilters size={18} />
                            </Button>
                        </div>
                    </Form.Group>
                </Row>
            </Form>
        </Container>
    );
};

export default LeadSourceSummeryReportFilterSection;
