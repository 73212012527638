import { React, useContext, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Card, Table } from "react-bootstrap";
import qs from "qs";

import { AiOutlineMenu } from "react-icons/ai";

import { oppornunityDetailedReportReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import removeRefData from "helpers/removeRefData";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import OpportunityDeatiledReportFilterSection from "../ReportFilterSection/OpportunityDeatiledReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";

const OpportunityReportView = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const [opportunityReport, setOpportunityReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(
    oppornunityDetailedReportReportFilterKeys
  );

  const columns = [{ key: "closing_date", value: "Closing Date" }];

  const keysToCheck = [
    "date_range_for",
    "lead_id",
    "customer_id",
    "campaign_id",
    "stage",
  ];
  const filterValuesExist = keysToCheck.some((key) => {
    const value = filterFields[key];
    return value !== undefined && value !== null && value !== "";
  });

  const handleCanvas = () => setShow(!show);

  const getProjectReportData = async (page = 1) => {
    setDataProcessing(true);

    const parsedFilterItems = JSON.parse(filterItems ? filterItems : "{}");
    if (!parsedFilterItems.date_range_for) {
      parsedFilterItems.from_date = "";
      parsedFilterItems.to_date = "";
    }
    let additionalRemoveField = "date_range";
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems, additionalRemoveField)
    );
    try {
      const data = await apiCall({
        url: `crm/opportunity-report`,
        params: {
          page,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setOpportunityReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectReportData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}

            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url="reports/opportunity-report"
                filters={filterFields}
                setFilters={setFilterFields}
                columns={columns}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/opportunity-reports/export"}
                  successMessage={"Opportunity Detail Report Export Completed"}
                  initialMessage={"Opportunity Detail Report Export Started"}
                  enableSendMail
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <OpportunityDeatiledReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
              filterValuesExist={filterValuesExist}
              columns={columns}
            />
          </div>
          <div className="d-flex flex-column gap-2">
            <span className="fs--1 align-self-center">{company_name}</span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Opportunity Detailed Report
            </Link>
          </div>
          <div className="mt-4">
            {!dataProcessing ? (
              <Table striped bordered hover responsive size="sm">
                <thead>
                  <tr
                    style={{
                      backgroundColor: "#00185894",
                      color: "whitesmoke",
                    }}
                  >
                    <th className="fw-bold text-light pt-2 pb-2">#</th>
                    <th className="fw-bold text-light pt-2 pb-2">TITLE</th>
                    <th className="fw-bold text-light pt-2 pb-2">LEAD</th>
                    <th className="fw-bold text-light pt-2 pb-2">ACCOUNT</th>
                    <th className="fw-bold text-light pt-2 pb-2">CAMPAIGN</th>
                    <th className="fw-bold text-light pt-2 pb-2">STAGE</th>
                    <th className="fw-bold text-light pt-2 pb-2">AMOUNT</th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      CLOSING DATE
                    </th>
                    <th className="fw-bold text-light pt-2 pb-2">
                      PROBABILITY (%)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(opportunityReport).length > 0 &&
                  opportunityReport.data.length > 0 ? (
                    opportunityReport.data.map((item, key) => (
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td className="text-capitalize">{item.title}</td>
                        <td className="text-capitalize">{item.lead_title}</td>
                        <td className="text-capitalize">{item.account_name}</td>
                        <td className="text-capitalize">
                          {item.campaign_name}
                        </td>
                        <td className="text-capitalize">{item.stage}</td>
                        <td>{item.amount}</td>
                        <td>{item.closing_date}</td>
                        <td>{item.probability_percentage}</td>
                      </tr>
                    ))
                  ) : (
                    <tr height="100px">
                      <td colSpan={13} className="text-center">
                        <span>No Data Found</span>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            ) : (
              <div
                className="d-flex justify-content-center align-item-center"
                style={{ minHeight: "48vh" }}
              >
                <LoadingScreen message="Loading Opportunity Detailed Report.." />
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "7vh" : "" }}
        >
          {opportunityReport && opportunityReport.links && !dataProcessing && (
            <ReportPagination
              data={opportunityReport}
              url="/reports/opportunity-report"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default OpportunityReportView;
