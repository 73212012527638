import { React, useEffect, useState } from "react";
import {
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "axios";
import { Offcanvas, Form } from "react-bootstrap";
import { apiCall } from "helpers/apiCalls";
import { showToast } from "module/common/Toast/toast";
import { SaveButton } from "module/common/Buttons/CommonButton";
import { enquiryFormKeys } from "helpers/formKeys";
import removeRefData from "helpers/removeRefData";
import BackButton from "components/common/BackButton";
import PhoneNumberInput from "components/form/PhoneNumberInput";
import { MdOutlineContactPhone } from "react-icons/md";
import ListLoading from "module/common/ListLoading";

const EnquiryInnerForm = () => {
  const [getData] = useOutletContext();
  let navigate = useNavigate();
  const { enqId } = useParams();
  const [queryParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [onSave, setOnSave] = useState(false);
  const [formData, setFormData] = useState(enquiryFormKeys);
  const [formError, setFormError] = useState({});
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");

  const handleFieldChange = (e, action) => {
    let newFormData;
    newFormData = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormError({});
    setOnSave(true);

    let formDataWithoutRefkeys = removeRefData(formData);

    axios({
      method: "put",
      url: `crm/enquiries/${enqId}`,
      data: formDataWithoutRefkeys,
    })
      .then((response) => {
        if (response.data.success === true) {
          showToast(response.data.message, "success");

          navigate(enqId ? -1 : "/teams");
          (!filterItems || enqId) && getData(pageNumber ?? 1);
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      })
      .catch((error) => {
        if (error.response.data && error.response.data.message) {
          const validation_error =
            error.response.data &&
            error.response.data.data &&
            error.response.data.data.errors
              ? error.response.data.data.errors
              : null;
          validation_error && setFormError({ ...validation_error });
        } else {
          showToast(
            "Something went wrong, please refresh the page and try again.",
            "error"
          );
        }
        setOnSave(false);
      });
  };

  useEffect(() => {
    if (enqId) {
      getDataItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqId]);

  const getDataItem = async (page = 1) => {
    setLoading(true);
    const data = await apiCall({
      url: "crm/enquiries/" + enqId,
      params: {
        page: page,
      },
    });

    setFormData({
      name: data.name,
      phone: data.phone,
      email: data.email,
      message: data.message,
    });
    setLoading(false);
  };

  return (
    <Offcanvas show onHide={() => navigate(-1)} placement="end">
      <Offcanvas.Header
        closeButton
        className="d-flex flex-row justify-content-between border-bottom gap-1"
      >
        <div className="d-flex align-items-center pt-2 pb-2">
          <MdOutlineContactPhone size={23} className="me-2 text-dark" />
          <span className="custom-module-header text-uppercase">
            {"Edit Enquiry "}
          </span>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body
        style={{
          padding: "20px",
          backgroundColor: "#fff",
          borderRadius: "5px",
        }}
      >
        {!loading ? (
          <Form onSubmit={handleSubmit} className="h-100 d-flex flex-column">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                onChange={handleFieldChange}
                value={formData.name}
                placeholder="Enter Name"
                isInvalid={!!formError.name}
              />
              <Form.Control.Feedback type="invalid">
                {formError.name}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Phone</Form.Label>
              <PhoneNumberInput
                name="phone"
                onChange={handleFieldChange}
                value={formData.phone}
              />
              <Form.Control.Feedback type="invalid">
                {formError.phone}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                onChange={handleFieldChange}
                value={formData.email}
                placeholder="Enter Email"
                isInvalid={!!formError.email}
              />
              <Form.Control.Feedback type="invalid">
                {formError.email}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="require-data">Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                onChange={handleFieldChange}
                value={formData.message}
                placeholder="Enter Message"
                isInvalid={!!formError.message}
              />
              <Form.Control.Feedback type="invalid">
                {formError.message}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="d-flex gap-1 mt-auto">
              <SaveButton
                handleSubmit={handleSubmit}
                onSave={onSave}
                id={enqId}
              />
              {!onSave && (
                <BackButton variant={"danger"} size={"sm"}>
                  Cancel
                </BackButton>
              )}
            </div>
          </Form>
        ) : (
          <ListLoading />
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default EnquiryInnerForm;
