import { React, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Outlet,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Card } from "react-bootstrap";
import qs from "qs";
import SimpleBar from "simplebar-react";
import { apiCall } from "helpers/apiCalls";
import { DeleteContext } from "context/Context";
import TableHeader from "helpers/tableHeader";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import AppPagination from "components/common/app-pagination/AppPagination";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import FilterButton from "components/filter/FilterButton";
import createFilterArray from "helpers/filterArrayBuilderHelper";
import removeRefData from "helpers/removeRefData";
import { MdOutlineContactPhone } from "react-icons/md";
import { enquiryFilters } from "module/common/helpers/enquiryCommonFilters";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import { DeleteButton, EditButton } from "module/common/Buttons/AppButtons";
import useAxisproPermission from "hooks/useAxisproPermission";

const Enquiry = () => {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const { dispatch } = useContext(DeleteContext);
  const [isLoading, setIsLoading] = useState(false);
  const [enquiry, setEnquiry] = useState({});
  const [filters] = useState(enquiryFilters);
  const pageNumber = queryParams.get("page");
  const filterItems = queryParams.get("filters");
  const allQueryParams = Object.fromEntries([...queryParams]);
  const axisProPermission = useAxisproPermission();
  const [updatePermission, deletePermission] = [
    "update-enquiry",
    "delete-enquiry",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = updatePermission || deletePermission;

  const handleDelete = (id) => {
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/crm/enquiries/" + id,
        title: "Delete Enquiry",
        message: "Are you sure you want to delete this Enquiry?",
        onSuccess: () => {
          const dataWithoutDeletedItem = enquiry.data.filter(
            (enquiry) => enquiry.id !== id
          );

          if (dataWithoutDeletedItem.length < 1 && pageNumber > 1) {
            // if data length < 1 and page number > 1 then redirect to previous page
            navigate(
              "/enquiries?" +
                createSearchParams({ ...allQueryParams, page: pageNumber - 1 })
            );
          } else if (dataWithoutDeletedItem.length === 0 && pageNumber < 1) {
            getData();
          } else {
            setEnquiry((previous) => ({
              ...previous,
              data: dataWithoutDeletedItem,
            }));
            // reset deleteContext
            dispatch({
              type: "RESET",
            });
          }
        },
      },
    });
  };

  const columns = [
    {
      accessor: "name",
      Header: "NAME",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { name } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{name ? name : "N/A"}</h5>;
      },
    },
    {
      accessor: "phone",
      Header: "CONTACT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { phone } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{phone ? phone : "N/A"}</h5>;
      },
    },
    {
      accessor: "email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{email ? email : "N/A"}</h5>;
      },
    },

    {
      accessor: "message",
      Header: "MESSAGE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { message } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{message ? message : "N/A"}</h5>;
      },
    },
    {
      accessor: "created_at",
      Header: "ENQUIRY DATE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 text-uppercase custom-table-body-text",
      },
      Cell: (rowData) => {
        const { created_at } = rowData.row.original;
        return <h5 className="mb-0 fs--1">{created_at}</h5>;
      },
    },
    {
      accessor: "none",
      Header: "",
      headerProps: {
        className: `py-3 custom-table-head-th-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      disableSortBy: true,
      cellProps: {
        className: `custom-table-body-td-action ${
          actionsPermission ? "" : "d-none"
        }`,
      },
      Cell: (rowData) => {
        const { id } = rowData.row.original;
        return (
          <div className="d-flex gap-2">
            {updatePermission && (
              <EditButton
                route={`/enquiries/edit/${id}?${createSearchParams({
                  ...allQueryParams,
                })}`}
              />
            )}
            {deletePermission && (
              <DeleteButton onClick={() => handleDelete(id)} />
            )}
          </div>
        );
      },
    },
  ];

  const getData = async (page = 1) => {
    setIsLoading(true);
    const data = await apiCall({
      url: "crm/enquiries",
      params: {
        page: page,
        filters: filterItems
          ? removeRefData(createFilterArray(filterItems), "array")
          : null,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    setEnquiry(data);
    setIsLoading(false);
  };

  useEffect(() => {
    getData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filterItems]);

  return (
    <>
      <Outlet context={[getData]} />
      {!isLoading && enquiry?.data?.length > 0 ? (
        <AdvanceTableWrapper columns={columns} data={enquiry.data} sortable>
          <Card className="flex-fill mb-3 mt-3">
            <Card.Header className="d-flex flex-row justify-content-between align-items-center border-bottom gap-1">
              <MdOutlineContactPhone size={22} className="me-2 text-dark" />
              <TableHeader
                title={
                  <span className="custom-module-header">Enquiry List</span>
                }
                table
              />
              <FilterButton
                className={"pt-2 pb-2"}
                filters={filters}
                url="enquiries"
              />
            </Card.Header>
            <SimpleBar className="simplebar-style-with-pagination">
              <Card.Body className="p-0">
                <AdvanceTable
                  table
                  headerClassName="text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tablebodyClassName="custom-table-body"
                  tableProps={{
                    size: "sm",
                    className: "fs--1 mb-0 overflow-hidden custom-table",
                  }}
                />
              </Card.Body>
            </SimpleBar>
            <Card.Footer className="border-top p-1 pt-3">
              {enquiry && enquiry.links && (
                <AppPagination
                  data={enquiry}
                  url="/enquiries"
                  search={allQueryParams}
                />
              )}
            </Card.Footer>
          </Card>
        </AdvanceTableWrapper>
      ) : (
        <LoadingScreenCover className="mb-3 mt-3">
          {isLoading ? (
            <LoadingCommon loadingText="Looking for Enquiries" />
          ) : (
            <h5 className="text-center text-secondary">
              Enquiries not found! &#128578;
            </h5>
          )}
          {!isLoading && (
            <div className="d-flex flex-wrap justify-content-center mt-3">
              {filterItems ? (
                <FilterButton
                  showText
                  filters={filters}
                  url="enquiries"
                  className="m-1"
                  variant="secondary"
                />
              ) : null}
            </div>
          )}
        </LoadingScreenCover>
      )}
    </>
  );
};

export default Enquiry;
