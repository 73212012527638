import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import PropTypes from "prop-types";

import { AiOutlineProject } from "react-icons/ai";
import { FaWpforms } from "react-icons/fa";
import { MdWavingHand } from "react-icons/md";
import { BsFillTicketDetailedFill } from "react-icons/bs";
import { RiMoneyDollarBoxLine } from "react-icons/ri";

import Flex from "components/common/Flex";
import Background from "components/common/Background";
import corner4 from "assets/img/icons/spot-illustrations/corner-1.png";
import useAxisproPermission from "hooks/useAxisproPermission";

const WelcomeTile = ({ className }) => {
  const axisProPermission = useAxisproPermission();
  const [storeLead, storeProject, storeTicket, storeEstimation] = [
    "store-lead",
    "store-project",
    "store-ticket",
    "store-estimation",
  ].map((permission) => axisProPermission(permission));
  const [userName, setUserName] = useState(null);

  const data = [];
  const configurations = [
    {
      condition: storeLead,
      item: {
        title: "Lead",
        text: "Create new lead!",
        color: "primary",
        Icon: RiMoneyDollarBoxLine,
        url: "/leads/lead?mode=add",
      },
    },
    {
      condition: storeProject,
      item: {
        title: "Project",
        text: "Create new project!",
        color: "success",
        Icon: AiOutlineProject,
        url: "/project/add",
      },
    },
    {
      condition: storeTicket,
      item: {
        title: "Tickets",
        text: "Crate new Tickets!",
        color: "danger",
        Icon: BsFillTicketDetailedFill,
        url: "/tickets/list/add?tab=all",
      },
    },
    {
      condition: storeEstimation,
      item: {
        title: "Estimation",
        text: "Create new estimation!",
        color: "info",
        Icon: FaWpforms,
        url: "/estimation/add",
      },
    },
  ];

  configurations.forEach(({ condition, item }) => {
    if (condition) {
      data.push(item);
    }
  });

  useEffect(() => {
    const storedData = localStorage.getItem("AXIS_PRO_CRM_USER");
    if (storedData) {
      setUserName(JSON.parse(storedData));
    }
  }, []);

  return (
    <Card
      className={`overflow-hidden ${className} p-3 pb-2 card-main`}
      style={{ minHeight: "232px", maxHeight: "150px" }}
    >
      <Background image={corner4} className="p-card bg-card" />
      <Card.Body className="position-relative pe-0 ps-1">
        <h5 className="text-warning fs-1">
          Hello <MdWavingHand /> {userName && userName.name}
        </h5>
        <h6 className="text-dark">
          Here are some quick links for you to start
        </h6>
        <Row className="g-2 h-100 align-items-end mt-3">
          {data.map(({ Icon, color, title, text, url }) => {
            return (
              <Col sm={6} md={6} key={title} className="mt-3">
                <Flex className="position-relative">
                  <div className="icon-item icon-item-sm border rounded-3 shadow-none me-2">
                    <Icon className={`text-${color}`} />
                  </div>
                  <div className="flex-1 mt-2">
                    <Link to={url} className="stretched-link">
                      <h6 className="text-dark mb-0">{title}</h6>
                    </Link>
                    <p className="mb-0 fs--2 text-500 ">{text}</p>
                  </div>
                </Flex>
              </Col>
            );
          })}
        </Row>
      </Card.Body>
    </Card>
  );
};

WelcomeTile.propTypes = {
  className: PropTypes.string,
};

export default WelcomeTile;
