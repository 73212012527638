import SelectLeadSource from "components/form/SelectLeadSource";
import SelectLeadStatus from "components/form/SelectLeadStatus";

const commonFilterOperators = [
  { name: "Equal", value: "=" },
  { name: "LIKE", value: "LIKE" },
];

const leadStatusFilters = {
  status: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
};

const leadSourceFilters = {
  source_name: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
};

const leadStageFilters = {
  name: {
    label: "Name",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
};

const leadsFilters = {
  full_name: {
    label: "Title",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
  company_name: {
    label: "Company",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
  email: {
    label: "Email",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
  phone_no: {
    label: "Contact",
    operators: commonFilterOperators,
    active: true,
    Element: "STRING",
  },
  is_company: {
    label: "Type",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) => (item && item.operator === "=" ? "SELECT" : "STRING"),
    options: [
      { label: "Company", value: 1 },
      { label: "Individual", value: 0 },
    ],
  },
  lead_source: {
    label: "Source",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectLeadSource
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
  lead_priority: {
    label: "Priority",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) => (item && item.operator === "=" ? "SELECT" : "STRING"),
    options: [
      { label: "High", value: "high" },
      { label: "Medium", value: "medium" },
      { label: "Low", value: "low" },
    ],
  },
  lead_status: {
    label: "Status",
    operators: [{ name: "Equal", value: "=" }],
    Element: (item) =>
      item && item.operator === "=" ? (
        <div className="w-100">
          <SelectLeadStatus
            value={item.value_ref}
            name={item.name}
            handleFieldChange={item.handleFieldChange}
          />
        </div>
      ) : (
        "STRING"
      ),
  },
};

export { leadStatusFilters, leadSourceFilters, leadStageFilters, leadsFilters };
