import axios from "axios";
import React, { useState } from "react";
import Select from "react-select";

const SelectProjectPriority = ({
  handleFieldChange,
  name,
  error,
  value,
  style,
  placeholder,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (inputValue) => {
    setIsLoading(true);
    try {
      let response = await axios.get("crm/ticket-priority-list");
      let data = response && response.data ? response.data.data : [];
      const formattedOptions = data.map((item) => ({
        label: item,
        value: item,
      }));
      setOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Select
      defaultOptions
      isClearable
      isLoading={isLoading}
      onFocus={() => fetchData("")}
      options={options}
      name={name ? name : "project"}
      value={value}
      placeholder={placeholder ?? ""}
      onChange={handleFieldChange}
      className={`custom-select ${
        error
          ? "form-control ps-0 py-0 is-invalid text-capitalize"
          : "text-capitalize"
      }`}
      styles={{
        menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
        menu: (provided) => ({ ...provided, zIndex: 9999 }),
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "#696c96" : "inherit",
          "&:hover": {
            backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
          },
        }),
        control: (provided) => ({
          ...provided,
          ...style,
        }),
      }}
    />
  );
};

export default SelectProjectPriority;
