import { React, useState } from 'react';
import { Button, Col, Collapse, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OtherDetailsCollapse = ({ data }) => {
  const [open, setOpen] = useState(true);

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="customer-more-info-collapse"
        aria-expanded={open}
        variant="white"
        size="small"
        className="px-0 text-primary d-flex flex-row align-items-center"
        style={{ border: 'none', outLine: 'none', boxShadow: 'none' }}
      >
        <small>Other Details</small>
        <FontAwesomeIcon
          icon={open ? 'caret-down' : 'caret-right'}
          className="ms-1"
        />
      </Button>
      <Collapse in={open}>
        <Row
          id="customer-more-info-collapse"
          className="mt-2"
          style={{ fontSize: '.8rem', fontWeight: '600' }}
        >
          {data.customer_type ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                Client Type <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                Business
              </Col>
            </>
          ) : (
            ''
          )}
          {data.currency_code ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                Currency Code <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.currency_code}
              </Col>
            </>
          ) : (
            ''
          )}
          {data.payment_term_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                Payment Terms <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.payment_term_name}
              </Col>
            </>
          ) : (
            ''
          )}
          {data.credit_status_name ? (
            <>
              <Col
                sm={12}
                lg={5}
                className="text-muted mb-2 d-flex flex-row justify-content-between"
              >
                Credit Status <span className="ms-1 d-none d-lg-block">:</span>
              </Col>
              <Col sm={12} lg={7} className="mb-2 text-dark">
                {data.credit_status_name}
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
      </Collapse>
    </>
  );
};

OtherDetailsCollapse.propTypes = {
  data: PropTypes.any
};

export default OtherDetailsCollapse;
