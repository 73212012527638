import { React, useEffect, useState, useContext } from "react";
import {
  createSearchParams,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Card, Tab, Tabs } from "react-bootstrap";

import { AiOutlineCodeSandbox } from "react-icons/ai";

import { apiCall } from "helpers/apiCalls";
import { CloseButton } from "module/common/Buttons/CommonButton";
import { AuthWizardContext } from "context/Context";
import OverViewTab from "./OverviewTab/OverViewTab";
import CommentTab from "./CommentTab/CommentTab";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import TaskTable from "module/Task/TaskTable/TaskTable";
import useAxisproPermission from "hooks/useAxisproPermission";
import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import SprintTable from "module/Sprints/SprintTable/SprintTable";
import TransactionTab from "./TransactionTab/TransactionTab";
import ContractTab from "./ContractTab/ContractTab";
import AttachmentsTab from "module/common/Attachments/AttachmentsTab";
import TicketTabs from "module/Tickets/TicketTabs/TicketTabs";

function ProjectInfo() {
  const user = useContext(AuthWizardContext);
  const loggedUsCustomer = user?.user?.customer_id ? true : false;
  const [queryParams] = useSearchParams();
  const { itemInfoId } = useParams();
  const activeTab = queryParams.get("tab");
  const [isLoading, setIsLoading] = useState(false);
  const [project, setProject] = useState({});
  const [todo, setTodo] = useState([]);
  const [taskApiCalling, setTaskApiCalling] = useState(false);
  const [membersList, setMembersList] = useState([]);
  const navigate = useNavigate();
  const axisProPermission = useAxisproPermission();

  const getData = async () => {
    setIsLoading(true);
    try {
      const data = await apiCall({
        url: `crm/project/${itemInfoId}`,
      });
      setProject(data);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTask = async () => {
    setTaskApiCalling(true);
    try {
      const data = await apiCall({
        url: `crm/task`,
        params: {
          project_id: itemInfoId,
          "status_filter[0]": "to_do",
        },
      });
      setTodo(data?.data);
      setTaskApiCalling(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
    getTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabSwitch = (tab) => {
    let navigateUrl;
    if (tab === "tasklist") {
      navigateUrl = `?tab=${tab}&${createSearchParams({
        status_filter: defaultTaskStatusFilter.join("/"),
        page: 1,
      })}`;
    } else {
      navigateUrl = `?tab=${tab}`;
    }
    activeTab !== tab && navigate(navigateUrl);
  };

  return (
    <Card className="flex-fill mb-3 mt-3 shadow-none">
      {!isLoading ? (
        project &&
        Object.keys(project).length > 0 && (
          <>
            <Card.Header className="border-bottom">
              <div className="d-flex justify-content-between align-items-center">
                <span className="fs-1 text-uppercase text-dark d-flex align-items-center">
                  <AiOutlineCodeSandbox size={25} className="me-1" />
                  {project.title}
                </span>
                {loggedUsCustomer ? null : (
                  <div>
                    <CloseButton handleClick={() => navigate("/project")} />
                  </div>
                )}
              </div>
            </Card.Header>
            <Card.Body style={{ backgroundColor: "#F9F9F9" }} className="p-0">
              <Tab.Container id="left-tabs-example">
                <Tabs
                  defaultActiveKey="overview"
                  id="custom-empl-header-tabs"
                  className="custom-empl-header-tabs p-2 bg-white border-bottom"
                  variant="pills"
                  activeKey={activeTab}
                  onSelect={handleTabSwitch}
                >
                  <Tab
                    eventKey="overview"
                    title={<span className="fw-bold">Overview</span>}
                    className="custom-empl-header-tab"
                  >
                    <OverViewTab
                      project={project}
                      todo={todo}
                      loading={isLoading}
                      taskApiCalling={taskApiCalling}
                      membersList={membersList}
                      setMembersList={setMembersList}
                    />
                  </Tab>
                  {axisProPermission("list-sprint") ? (
                    <Tab
                      eventKey="sprintlist"
                      title={<span className="fw-bold">Sprint List</span>}
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "sprintlist" && (
                        <SprintTable
                          projectId={itemInfoId}
                          renderFrom={"project"}
                        />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("list-task") ? (
                    <Tab
                      eventKey="tasklist"
                      title={<span className="fw-bold">Task List</span>}
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "tasklist" && (
                        <TaskTable showHeader={false} renderFrom={"project"} />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("list-ticket") ? (
                    <Tab
                      eventKey="tickets"
                      title={<span className="fw-bold">Tickets</span>}
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "tickets" && (
                        <TicketTabs
                          renderFrom={"project"}
                          projectId={project?.id}
                        />
                      )}
                    </Tab>
                  ) : null}
                  <Tab
                    eventKey="comments"
                    title={<span className="fw-bold">Comments</span>}
                    className="custom-empl-header-tab"
                  >
                    {activeTab === "comments" && (
                      <CommentTab project={project} membersList={membersList} />
                    )}
                  </Tab>
                  {axisProPermission("report-customer-balance-statements") ? (
                    <Tab
                      eventKey="transactions"
                      title={<span className="fw-bold">Transactions</span>}
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "transactions" && (
                        <TransactionTab customer_id={project?.customer_id} />
                      )}
                    </Tab>
                  ) : null}
                  {axisProPermission("show-ct") ? (
                    <Tab
                      eventKey="contracts"
                      title={<span className="fw-bold">Contracts</span>}
                      className="custom-empl-header-tab"
                    >
                      {activeTab === "contracts" && (
                        <ContractTab customer_id={project?.customer_id} />
                      )}
                    </Tab>
                  ) : null}
                  <Tab
                    eventKey="attachments"
                    title={<span className="fw-bold">Attachments</span>}
                    className="custom-empl-header-tab"
                  >
                    <AttachmentsTab
                      id={project?.id}
                      fetchData={getData}
                      media={project?.media}
                      type={"PROJECT"}
                    />
                  </Tab>
                </Tabs>
              </Tab.Container>
            </Card.Body>
          </>
        )
      ) : (
        <div
          className="flex-fill d-flex align-items-center justify-content-center"
          style={{ height: "85vh" }}
        >
          <LoadingCommon loadingText="Looking for Project Details" />
        </div>
      )}
    </Card>
  );
}

export default ProjectInfo;
