import { React, useContext, useEffect, useState } from "react";
import {
  Link,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Badge, Button, Card } from "react-bootstrap";
import qs from "qs";
import { AiOutlineMenu } from "react-icons/ai";
import { leadSourceSummeryItemReportFilterKeys } from "module/common/helpers/reportsCommonFilters";
import { AuthWizardContext } from "context/Context";
import { apiCall } from "helpers/apiCalls";
import ReportPagination from "components/common/app-pagination/ReportPagination";
import LoadingScreen from "components/common/loading-screen/LoadingScreen";
import ReportOffcanvas from "../ReportOffcanvas/ReportOffcanvas";
import ReportFilterButton from "../Common/Filter/ReportFilterButton";
import Export from "../Common/Export";
import CloseButton from "../Common/CloseButton";
import useAxisproPermission from "hooks/useAxisproPermission";
import AdvanceTableWrapper from "components/common/advance-table/AdvanceTableWrapper";
import SimpleBar from "simplebar-react";
import AdvanceTable from "components/common/advance-table/AdvanceTable";
import { FaChevronLeft } from "react-icons/fa";
import LeadSourceSummeryItemReportFilterSection from "../ReportFilterSection/LeadSourceSummeryItemReportFilterSection";
import removeEmptyFields from "helpers/removeEmptyFields";
import removeRefData from "helpers/removeRefData";

const LeadSourceSummeryItemReport = () => {
  const { user } = useContext(AuthWizardContext);
  const company_name_to_show = user?.branch?.company_name ?? "";
  const [queryParams] = useSearchParams();
  const { lead_source_id } = useParams();
  const filterItems = queryParams.get("filters");
  const pageNumber = queryParams.get("page");
  const [leadSourceSummeryReport, setLeadSourceSummeryReport] = useState({});
  const allQueryParams = Object.fromEntries([...queryParams]);
  const navigate = useNavigate();
  const [dataProcessing, setDataProcessing] = useState(false);
  const reportPermissions = user?.menu_links?.find(
    (item) => item?.to === "reports"
  );
  const axisProPermission = useAxisproPermission();
  const [show, setShow] = useState(false);
  const [filterFields, setFilterFields] = useState(
    leadSourceSummeryItemReportFilterKeys
  );
  const handleCanvas = () => setShow(!show);
  const getLeadData = async (page = 1) => {
    setDataProcessing(true);
    const parsedFilterItems = JSON.parse(filterItems);
    const filteredObject = removeRefData(
      removeEmptyFields(parsedFilterItems || {})
    );
    try {
      const data = await apiCall({
        url: `crm/lead`,
        params: {
          page: page ? page : 1,
          lead_source: lead_source_id,
          ...filteredObject,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
      });

      setLeadSourceSummeryReport(data);
      setDataProcessing(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLeadData(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lead_source_id, pageNumber, filterItems]);

  const columns = [
    {
      accessor: "title",
      Header: "TITLE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { title } = rowData.row.original;
        return (
          <Link
            to={
              axisProPermission("view-lead")
                ? `/leads/lead/info/${rowData.row.original.id}`
                : "#"
            }
            className={`custom-link-style-crm ${
              axisProPermission("view-lead") ? "" : "disabled-link"
            }`}
          >
            <span className="mb-0 fs--1">{title}</span>
          </Link>
        );
      },
    },
    {
      accessor: "company_name",
      Header: "COMPANY",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { company_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{company_name}</span>;
      },
    },
    {
      accessor: "email",
      Header: "EMAIL",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { email } = rowData.row.original;
        return <span className="mb-0 fs--1">{email}</span>;
      },
    },
    {
      accessor: "phone_no",
      Header: "CONTACT",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-2",
      },
      Cell: (rowData) => {
        const { phone_no } = rowData.row.original;
        return <span className="mb-0 fs--1">{phone_no}</span>;
      },
    },
    {
      accessor: "is_company",
      Header: "TYPE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { is_company } = rowData.row.original;
        return (
          <span className="mb-0 fs--1">
            {is_company ? "Company" : "Individual"}
          </span>
        );
      },
    },
    {
      accessor: "lead_owner_name",
      Header: "LEAD OWNER",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { lead_owner_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_owner_name}</span>;
      },
    },
    {
      accessor: "product_name",
      Header: "PRODUCTS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { product_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{product_name}</span>;
      },
    },
    {
      accessor: "is_converted",
      Header: "CONVERTION STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { is_converted } = rowData.row.original;
        return (
          <div className="mb-0 fs--1 text-uppercase text-center">
            <Badge
              style={{ minWidth: "60px" }}
              bg={is_converted ? "success" : "secondary"}
            >
              {is_converted ? "CONVERTED" : "NOT CONVERTED"}
            </Badge>
          </div>
        );
      },
    },
    {
      accessor: "lead_source",
      Header: "SOURCE",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3 custom-table-body-text",
      },
      Cell: (rowData) => {
        const { lead_source_name } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_source_name}</span>;
      },
    },
    {
      accessor: "lead_priority",
      Header: "PRIORITY",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3",
      },
      Cell: (rowData) => {
        const { lead_priority } = rowData.row.original;
        return (
          <div className="mb-0 fs--1 text-uppercase">
            <Badge style={{ minWidth: "60px" }} bg={"primary"}>
              {lead_priority?.replace("_", " ")}
            </Badge>
          </div>
        );
      },
    },
    {
      accessor: "lead_status_id",
      Header: "STATUS",
      headerProps: { className: "py-3 custom-header-text" },
      cellProps: {
        className: "py-3",
      },
      Cell: (rowData) => {
        const { lead_status } = rowData.row.original;
        return <span className="mb-0 fs--1">{lead_status}</span>;
      },
    },
  ];

  const goBack = () => {
    const parsedFilterItems = JSON.parse(filterItems);
    const newLeadSourceSummeryItemReportFilterKeys = {
      ...leadSourceSummeryItemReportFilterKeys,
      date_range: parsedFilterItems?.date_range
        ? parsedFilterItems?.date_range
        : "",
      date_range_for: parsedFilterItems?.date_range_for
        ? parsedFilterItems?.date_range_for
        : "",
      from_date: parsedFilterItems?.from_date
        ? parsedFilterItems?.from_date
        : "",
      to_date: parsedFilterItems?.to_date ? parsedFilterItems?.to_date : "",
    };

    const filteredObject = removeEmptyFields(
      removeRefData(newLeadSourceSummeryItemReportFilterKeys)
    );
    const parsedFilteredObject = JSON.stringify(filteredObject);
    navigate(
      `/reports/lead-source-summary-report?${
        Object.keys(filteredObject).length > 0
          ? createSearchParams({ filters: parsedFilteredObject })
          : ""
      }`
    );
  };

  return (
    <div>
      <Card className="mt-3 mb-3">
        <Card.Header className="p-0 shadow-sm">
          <div className="d-flex">
            {reportPermissions && (
              <div className="pe-2 border-end">
                <Button
                  className="border-0"
                  size="lg"
                  variant="light"
                  style={{
                    backgroundColor: "white",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onClick={handleCanvas}
                >
                  <AiOutlineMenu className="text-dark" />
                </Button>
              </div>
            )}

            <div className="border-end d-flex align-items-center">
              <ReportFilterButton
                url={`reports/lead-source-summary-report/${lead_source_id}`}
                filters={filterFields}
                setFilters={setFilterFields}
              />
            </div>
            <div className="d-flex ms-auto">
              <div className="py-2  h-100 d-flex border-start">
                <Export
                  url={"crm/lead-export"}
                  successMessage={"Lead Source Summary Report Export Completed"}
                  initialMessage={"Lead Source Summary Report Export Started"}
                />
              </div>
              <CloseButton />
            </div>
          </div>
        </Card.Header>
        <Card.Body
          className="p-0"
          style={{
            maxHeight: "78.5vh",
            minHeight: "78.5vh",
            overflow: "scroll",
          }}
        >
          <div className="border-bottom p-3">
            <LeadSourceSummeryItemReportFilterSection
              filters={filterFields}
              setFilters={setFilterFields}
            />
          </div>

          <Button
            size="sm"
            className="mb-3 fs--1 px-2"
            type="button"
            variant={"primary"}
            onClick={goBack}
          >
            <FaChevronLeft />
          </Button>
          <div className="d-flex flex-column gap-2">
            <span className="fs--1 align-self-center">
              {company_name_to_show}
            </span>
            <Link className="fs--1 text-decoration-none align-self-center text-uppercase fs-0">
              Lead Source Summery Report
            </Link>
          </div>
          {leadSourceSummeryReport?.data?.length > 0 && (
            <div className="d-flex gap-1 align-items-end p-0 px-3 mt-2 ">
              <span className="text-success fs-3">
                {leadSourceSummeryReport?.meta?.total}
              </span>
              <span>Leads</span>
            </div>
          )}
          <div className="mt-2 border-top">
            {!dataProcessing && leadSourceSummeryReport?.data?.length > 0 ? (
              <AdvanceTableWrapper
                columns={columns}
                data={leadSourceSummeryReport.data}
                sortable
              >
                <Card.Body className="p-0">
                  <SimpleBar className="simplebar-style-with-pagination">
                    <AdvanceTable
                      table
                      headerClassName="text-nowrap align-middle"
                      rowClassName="align-middle custom-table-row"
                      tablebodyClassName="custom-table-body"
                      tableProps={{
                        size: "sm",
                        className: "fs--1 mb-0 overflow-hidden custom-table",
                      }}
                    />
                  </SimpleBar>
                </Card.Body>
              </AdvanceTableWrapper>
            ) : (
              <>
                {dataProcessing ? (
                  <div
                    className="d-flex justify-content-center align-item-center"
                    style={{ minHeight: "48vh" }}
                  >
                    <LoadingScreen message="Loading Leads Report" />
                  </div>
                ) : (
                  <>
                    <h5 className="text-center text-secondary mt-2">
                      Leads not found!
                    </h5>
                  </>
                )}
              </>
            )}
          </div>
        </Card.Body>
        <Card.Footer
          className="border-top"
          style={{ height: dataProcessing ? "5.5vh" : "5.5vh" }}
        >
          {leadSourceSummeryReport?.links && !dataProcessing && (
            <ReportPagination
              data={leadSourceSummeryReport}
              url="/leads/lead"
              search={allQueryParams}
            />
          )}
        </Card.Footer>
      </Card>
      <ReportOffcanvas show={show} handleCanvas={handleCanvas} />
    </div>
  );
};

export default LeadSourceSummeryItemReport;
