import IconButton from "components/common/IconButton";
import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Image,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { FaFire } from "react-icons/fa";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import "./EmployeeOverview.scss";
import OverviewTab from "./TabComponents/OverviewTab";
import GeneralInformation from "./TabComponents/GeneralInformation";
import ProfileImage from "../../../assets/img/Avathar/ProfileImage.jpg";
import ProjectsInfo from "./TabComponents/ProjectsInfo";
import TasksInfo from "./TabComponents/TasksInfo";
import TicketInfo from "./TabComponents/TicketInfo";
import { apiCall } from "helpers/apiCalls";
import ListLoading from "module/common/ListLoading";
import EmployeeAttachments from "./TabComponents/EmployeeAttachments";
import axios from "axios";
import { showToast } from "module/common/Toast/toast";
import setNewFormData from "helpers/setNewFormData";
import useAxisproPermission from "hooks/useAxisproPermission";
import ActivityTimeLine from "module/common/ViewPages/Common/Activity/Timeline/ActivityTimeLine";

const EmployeeOverview = () => {
  let navigate = useNavigate();
  const { empId } = useParams();
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [queryParams] = useSearchParams();
  const tab = queryParams.get("tab");
  const [previewURL, setPreviewURL] = useState(null);
  const axisProPermission = useAxisproPermission();

  const getProfileData = async () => {
    setLoading(true);
    const data = await apiCall({
      url: `crm/employee/${empId}`,
    });

    setProfileData(data);
    setLoading(false);
  };

  useEffect(() => {
    getProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (tab) => {
    navigate(`?tab=${tab}`);
  };

  const handleProfileImageUpdate = (e) => {
    e.preventDefault();
    let value = e.target.files[0];
    if (value) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(value);
      axios({
        method: "post",
        url: `crm/employee/${empId}/update-profile-pic`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: setNewFormData({ profile_pic: value }),
      })
        .then((response) => {
          if (response.data.success === true) {
            setProfileData({
              ...profileData,
              profile_pic: response.data.data.profile_pic,
            });
            showToast(response.data.message, "success");
          } else {
            showToast(
              "Something went wrong, please refresh the page and try again.",
              "error"
            );
          }
        })
        .catch((error) => {
          setPreviewURL(profileData?.profile_pic?.file_url);
          showToast(
            error?.response?.data?.data?.errors?.profile_pic[0],
            "error"
          );
        });
    }
  };

  return (
    <Container className="p-0 mt-3 mb-3" fluid>
      <Row className="gx-3 gy-3">
        <Col className="" md={3}>
          <Card body className="border-end-0 shadow-none h-100">
            {!loading && Object.keys(profileData).length > 0 ? (
              <>
                <div className="left-header-emply-overview">
                  <IconButton
                    onClick={() => navigate("/employee")}
                    variant="falcon-default"
                    size="sm"
                    icon="arrow-left"
                  />
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <div className="align-self-center mb-3">
                    <div className="profile-pic-wrapper">
                      <div className="pic-holder">
                        <Image
                          src={previewURL ?? profileData?.profile_pic?.file_url}
                          roundedCircle
                          id="profilePic"
                          className="pic lazy img-responsive"
                        />
                        <input
                          className="uploadProfileInput"
                          type="file"
                          name="profile_pic"
                          onChange={handleProfileImageUpdate}
                          id="newProfilePhoto"
                          accept="image/*"
                          style={{ opacity: "0" }}
                        />
                        <label
                          htmlFor="newProfilePhoto"
                          className="upload-file-block"
                        >
                          <div className="text-center">
                            <div className="mb-2">
                              <i className="fa fa-camera fa-2x"></i>
                            </div>
                            <div className="text-uppercase">
                              Update <br /> Profile Photo
                            </div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <span className="fs-1 align-self-center">{`${profileData.first_name} ${profileData.last_name}`}</span>
                    <span className="fs--1 align-self-center">
                      {`${profileData.designation_name}@${profileData.company_name}`}
                    </span>
                    <span className="align-self-center mt-2">
                      <ListGroup horizontal>
                        <ListGroup.Item className="border-0">
                          <span className="d-flex align-items-center gap-1">
                            <FaFire size={20} color="#F93048" />
                            <span className="fw-bold fs-0 mt-1">
                              {profileData.tasks_done}
                            </span>
                          </span>
                        </ListGroup.Item>
                      </ListGroup>
                    </span>
                  </div>
                  <span className="border-dashed mt-2" />
                  <div className="mt-3 ms-1">
                    <span className="fs--1 fw-bold">Personal Information</span>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Gender</span>
                      <span className=" text-capitalize">
                        {profileData.gender ? profileData.gender : "N/A"}
                      </span>
                    </div>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Date of Birth</span>
                      <span>{profileData.dob_formatted}</span>
                    </div>
                  </div>
                  <span className="border-dashed mt-3" />
                  <div className="mt-3">
                    <span className="fs--1 fw-bold">Contact Information</span>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Email Address</span>
                      <span>{profileData.email}</span>
                    </div>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Phone Number</span>
                      <span>{profileData.phone}</span>
                    </div>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Address</span>
                      <span>{profileData.address}</span>
                    </div>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Official</span>
                      <span>{profileData.office_number}</span>
                    </div>
                    <div className="d-flex flex-column custom-text-muted mt-2">
                      <span className="fw-bold">Fax</span>
                      <span>{profileData.fax}</span>
                    </div>
                  </div>

                  <span className="border-dashed mt-3" />
                </div>
                <Card.Footer className="ps-0 pb-0">
                  <div className="d-flex mt-2">
                    <div className="custom-text-muted ms-1">
                      <span className="fs--2">Created by</span>
                      <div>
                        <span>
                          <Image
                            src={ProfileImage}
                            roundedCircle
                            height={25}
                            width={25}
                          />
                        </span>
                        <span className="ms-1 fs--2">
                          {profileData.created_user_full_name}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex flex-column ms-auto custom-text-muted">
                      <span className="fs--2">Created at</span>
                      <span className="fs--2">{profileData.created_at}</span>
                    </div>
                  </div>
                </Card.Footer>
              </>
            ) : (
              <ListLoading style={{ height: "100%" }} />
            )}
          </Card>
        </Col>
        <Col className="" md={9}>
          <Card className="h-100 border-start-0 shadow-none">
            <Card.Body className="">
              {!loading && Object.keys(profileData).length > 0 ? (
                <Tab.Container id="left-tabs-example">
                  <Tabs
                    defaultActiveKey="gi"
                    id="custom-empl-header-tabs"
                    className="custom-empl-header-tabs"
                    variant="pills"
                    justify
                    activeKey={tab}
                    onSelect={handleTabChange}
                  >
                    <Tab
                      eventKey="gi"
                      title={
                        <span className="fw-bold">General Information</span>
                      }
                      className="custom-empl-header-tab"
                    >
                      <Card className="card-user-profile">
                        <Card.Body className="ps-2 pe-2">
                          <GeneralInformation
                            userId={profileData.user_id}
                            data={profileData}
                          />
                        </Card.Body>
                      </Card>
                    </Tab>
                    <Tab
                      eventKey="overview"
                      title={<span className="fw-bold">Overview</span>}
                      className="custom-empl-header-tab"
                    >
                      <OverviewTab data={profileData} />
                    </Tab>
                    <Tab
                      eventKey="timeline"
                      title={<span className="fw-bold">Timeline</span>}
                      className="custom-empl-header-tab"
                    >
                      <Card body className="card-user-profile">
                        <ActivityTimeLine infoData={profileData} height={"705px"} />
                      </Card>
                    </Tab>
                    {axisProPermission("list-project") && (
                      <Tab
                        eventKey="projects"
                        title={<span className="fw-bold">Projects</span>}
                        className="custom-empl-header-tab"
                      >
                        <ProjectsInfo
                          userId={profileData.user_id}
                          type="employee"
                        />
                      </Tab>
                    )}
                    {axisProPermission("list-task") && (
                      <Tab
                        eventKey="tasks"
                        title={<span className="fw-bold">Tasks</span>}
                        className="custom-empl-header-tab"
                      >
                        <TasksInfo
                          type="employee"
                          userId={profileData.user_id}
                          userName={profileData.name}
                        />
                      </Tab>
                    )}
                    {axisProPermission("list-ticket") && (
                      <Tab
                        eventKey="tickets"
                        title={<span className="fw-bold">Tickets</span>}
                        className="custom-empl-header-tab"
                      >
                        <TicketInfo
                          userId={profileData.user_id}
                          userName={profileData.name}
                        />
                      </Tab>
                    )}
                    <Tab
                      eventKey="attachment"
                      title={<span className="fw-bold">Attachments</span>}
                      className="custom-empl-header-tab"
                    >
                      <EmployeeAttachments />
                    </Tab>
                  </Tabs>
                </Tab.Container>
              ) : (
                <ListLoading style={{ height: "100%" }} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeOverview;
