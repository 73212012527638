import axios from "axios";
import SoftBadge from "components/common/SoftBadge";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row, Tab, Tabs } from "react-bootstrap";
import { AiOutlineMail } from "react-icons/ai";
import { FaAddressCard, FaEye, FaLink, FaPhone, FaUser } from "react-icons/fa";
import {
  Outlet,
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import GeneralInfo from "./Component/GeneralInfo";
import LoadingScreenCover from "components/common/loading-screen/LoadingScreenCover";
import LoadingCommon from "components/common/loading-screen/LoadingCommon";
import Contacts from "module/common/ViewPages/Common/Contacts/Contacts";
import Calls from "module/common/ViewPages/Common/Calls/Calls";
import Meetings from "module/common/ViewPages/Common/Meetings/Meetings";
import CommonAttachments from "module/common/ViewPages/Common/Attachments/CommonAttachments";
import { TbBulbFilled } from "react-icons/tb";
import useAxisproPermission from "hooks/useAxisproPermission";
import { IoMdArrowRoundBack } from "react-icons/io";
import Quotations from "module/common/ViewPages/Common/Quotations/Quotations";
import ClientKyc from "module/common/ViewPages/Common/ClientKYC/ClientKyc";
import Partners from "module/common/ViewPages/Common/Partners/Partners";
import { opportunityStageChangeFormKeys } from "helpers/formKeys";
import { MdMoveUp } from "react-icons/md";
import RibbonButtons from "module/common/Buttons/RibbonButtons";
import { apiCall } from "helpers/apiCalls";
import qs from "qs";
import StageHistory from "module/common/ViewPages/Common/StageHistory/StageHistory";

const OverView = () => {
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { itemId } = useParams();
  const [queryParams] = useSearchParams();
  const allQueryParams = Object.fromEntries([...queryParams]);
  const activeTab = queryParams.get("tab");
  const convertStatus = queryParams.get("converted");
  const axisProPermission = useAxisproPermission();

  const [isLoadingNeeded, setIsLoadingNeeded] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [formData, setFormData] = useState(opportunityStageChangeFormKeys);
  const [ribbonData, setRibbonData] = useState([]);
  const [isRibbonLoading, setIsRibbonLoading] = useState(false);

  const fetchData = () => {
    if (!isLoadingNeeded) {
      setLoading(true);
    }
    axios
      .get("crm/opportunities/" + itemId)
      .then((res) => {
        if (res.data.success) {
          setData(res.data.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, isLoadingNeeded]);

  const getData = async () => {
    setIsRibbonLoading(true);
    const data = await apiCall({
      url: "crm/opportunity-stages",
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
    const items = data?.map((item) => ({
      label: item,
      value: item,
    }));
    setRibbonData(items);
    setIsRibbonLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSelect = (status) => {
    setFormData((prevData) => ({
      ...prevData,
      opportunity_id: data?.id,
      stage_to: status?.value,
      stage_to_ref: {
        value: status?.value,
        label: status?.label,
      },
      description: "",
    }));
    setShowStatusModal(true);
  };

  const handleFieldChange = (e, action) => {
    let newFormData;
    let value;
    if (!action) {
      newFormData = {
        ...formData,
        [e.target.name]: e.target.value,
      };
    } else {
      if (action.action === "select-option") {
        value = e.code ? e.code : e.value;
      }
      newFormData = {
        ...formData,
        [action.name]: value,
        [action.name + "_ref"]: e,
      };
    }
    setFormData(newFormData);
  };

  const handleModalClose = () => {
    setFormData(opportunityStageChangeFormKeys);
    setShowStatusModal(false);
  };

  const dataToRibbonButton = {
    isLoadingNeeded: isLoadingNeeded, //re
    showStatusModal: showStatusModal, //r
    handleSelect: handleSelect, //r
    isLoading: isRibbonLoading, //r
    ribbonData: ribbonData || [], //r
    itemRef: {
      value: data?.stage, //r
      label: data?.stage, //r
    }, //r
    IconComponent: MdMoveUp, //r
    setIsLoadingNeeded: setIsLoadingNeeded,
    handleFieldChange: handleFieldChange,
    close: handleModalClose, //e
    apiUrl: "opportunity/update-stage", //e
    heading: "UPDATE STAGE", //e
    data: formData,
    getData: fetchData,
  };

  return (
    <>
      <Outlet />
      {!isLoading ? (
        <Card className="flex-fill mb-3 mt-3 h-100">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="me-2 d-flex align-items-center flex-wrap">
                <div className="ms-1 text-dark">
                  <TbBulbFilled size={25} className="me-2 text-mute" />
                  <span className="fs-1 mt-1">{data?.title} -</span>
                  <SoftBadge bg="success" className="p-2 ms-2 text-uppercase">
                    {data?.stage}
                  </SoftBadge>
                </div>
              </div>
              <div className="d-flex gap-2">
                {data?.lead_id && axisProPermission("show-lead") && (
                  <Button
                    size="sm"
                    className=""
                    variant="falcon-default"
                    title="Show Lead"
                    onClick={() =>
                      navigate(
                        `lead/${data?.lead_id}?${createSearchParams({
                          ...allQueryParams,
                        })}`
                      )
                    }
                  >
                    <div className="d-flex gap-2 align-items-center">
                      <FaEye size={20} />
                      <span>Lead</span>
                    </div>
                  </Button>
                )}
                <Button
                  className=""
                  variant="falcon-default"
                  size="sm"
                  onClick={() =>
                    navigate(
                      convertStatus === "true"
                        ? `/leads/lead/info/${data?.lead_id}`
                        : -1
                    )
                  }
                >
                  <div className="d-flex gap-2 align-items-center">
                    <span>
                      <IoMdArrowRoundBack size={20} />
                    </span>
                    <span>Back</span>
                  </div>
                </Button>
              </div>
            </div>
          </Card.Header>

          <Card.Header className="border-bottom" style={{ minHeight: "67px" }}>
            <RibbonButtons dataToRibbonButton={dataToRibbonButton} />
          </Card.Header>

          <Card.Body className="pb-2" style={{ backgroundColor: "whitesmoke" }}>
            <Row className="h-100 d-flex gy-3 p-2 pe-0">
              <Col
                md={3}
                className="flex-fill  pb-3 pt-3 pb-0"
                style={{ backgroundColor: "white", height: "78vh" }}
              >
                <div className="text-muted  rounded-0 fw-bold">
                  <div className="pe-3 pb-3 pt-3 ps-1">Client Information</div>
                </div>
                <div className=" text-muted p-2   d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <FaUser size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Full Name</div>
                    <div className="text-dark fw-bold text-capitalize">
                      {data?.customer_name ? data?.customer_name : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <FaPhone size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Phone Number</div>
                    <div className="text-dark fw-bold">
                      {data?.customer_phone ? data?.customer_phone : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <AiOutlineMail size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Email</div>
                    <div className="text-dark fw-bold">
                      {data?.customer_email ? data?.customer_email : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2 d-flex">
                  <div className="icon text-secondary me-3">
                    <FaAddressCard size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Address</div>
                    <div className="text-dark fw-bold">
                      {data?.customer_address ? data?.customer_address : "N/A"}
                    </div>
                  </div>
                </div>
                <div className="text-muted rounded-0  fw-bold pt-3">
                  <div className="pe-3 pb-3 pt-3 ps-1">Lead Information</div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <FaLink size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Title</div>
                    <div className="text-dark fw-bold text-capitalize">
                      {data?.lead_title ? data?.lead_title : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <FaPhone size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Phone Number</div>
                    <div className="text-dark fw-bold">
                      {data?.lead_phone ? data?.lead_phone : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary  me-3 ">
                    <AiOutlineMail size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Email</div>
                    <div className="text-dark fw-bold">
                      {data?.lead_email ? data?.lead_email : "N/A"}
                    </div>
                  </div>
                </div>
                <div className=" text-muted p-2  d-flex">
                  <div className="icon text-secondary me-3">
                    <FaAddressCard size={14} className="text-secondary" />
                  </div>
                  <div className="">
                    <div className="text-secondary">Reference</div>
                    <div className="text-dark fw-bold">
                      {data?.lead_reference ? data?.lead_reference : "N/A"}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={9} className="flex-fill p-0">
                <div
                  className="m-md-3 mt-md-0 mb-md-0 h-100"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="first"
                  >
                    <Tabs
                      id="custom-header-tabs"
                      className="custom-header-tabs mb-2 pt-1"
                      variant="tabs"
                      justify
                      defaultActiveKey={activeTab ? activeTab : "overview"}
                      onSelect={(tabname) => {
                        navigate(
                          `?${createSearchParams({
                            ...allQueryParams,
                            tab: tabname,
                          })}`
                        );
                      }}
                    >
                      <Tab
                        style={{ backgroundColor: "#FFFFFF" }}
                        eventKey="overview"
                        title={<span>Overview</span>}
                        className="ps-3 pe-3"
                      >
                        {activeTab === "overview" && (
                          <GeneralInfo data={data} />
                        )}
                      </Tab>
                      {axisProPermission("list-opportunity-contact") && (
                        <Tab eventKey="contacts" title={<span>Contacts</span>}>
                          {activeTab === "contacts" && (
                            <Contacts type="OPPORTUNITY" itemId={itemId} />
                          )}
                        </Tab>
                      )}
                      {axisProPermission("list-opportunity-call") && (
                        <Tab eventKey="calls" title={<span>Calls</span>}>
                          {activeTab === "calls" && (
                            <Calls type="OPPORTUNITY" itemId={itemId} />
                          )}
                        </Tab>
                      )}
                      {axisProPermission("list-opportunity-meeting") && (
                        <Tab eventKey="meeting" title={<span>Meeting</span>}>
                          {activeTab === "meeting" && (
                            <Meetings type="OPPORTUNITY" itemId={itemId} />
                          )}
                        </Tab>
                      )}
                      {axisProPermission("show-sq") && (
                        <Tab
                          eventKey="quotation"
                          title={<span>Quotations</span>}
                        >
                          {activeTab === "quotation" && (
                            <Quotations opportunityData={data} />
                          )}
                        </Tab>
                      )}
                      <Tab eventKey="partners" title={<span>Partners</span>}>
                        {activeTab === "partners" && (
                          <Partners itemId={itemId} />
                        )}
                      </Tab>

                      <Tab eventKey="kyc" title={<span>KYC</span>}>
                        {activeTab === "kyc" && (
                          <ClientKyc
                            itemId={data?.customer_id}
                            type="CUSTOMER"
                          />
                        )}
                      </Tab>

                      <Tab
                        eventKey="attachment"
                        title={<span>Attachment</span>}
                      >
                        {activeTab === "attachment" && (
                          <CommonAttachments
                            itemId={itemId}
                            type="OPPORTUNITY"
                          />
                        )}
                      </Tab>
                      <Tab
                        eventKey="stageHistory"
                        title={
                          <span
                            style={{ width: "max-content", display: "flex" }}
                          >
                            Stage History
                          </span>
                        }
                      >
                        {activeTab === "stageHistory" && (
                          <StageHistory
                            itemId={itemId}
                            type={"OPPORTUNITY"}
                            stageData={data}
                          />
                        )}
                      </Tab>
                    </Tabs>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      ) : (
        <LoadingScreenCover className="mb-3 mt-3">
          <LoadingCommon loadingText="Loading opportunity" />
        </LoadingScreenCover>
      )}
    </>
  );
};
export default OverView;
