import React from "react";
import { Col, Form } from "react-bootstrap";
import SelectLeadPriority from "components/form/SelectLeadPriority";
import SelectUsers from "components/form/SelectUsers";
import SelectLeadStatus from "components/form/SelectLeadStatus";

const LeadSourceSummeryItemReportForm = ({ filters, setFilters, handleFilterChange }) => {
    return (
        <div>
            <Form.Group as={Col}>
                <Form.Label className="fs--1">Title</Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="title"
                        onChange={handleFilterChange}
                        value={filters.title}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Status</Form.Label>
                <Col>
                    <SelectLeadStatus
                        name="lead_status"
                        value={filters.lead_status_ref}
                        handleFieldChange={handleFilterChange}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Company</Form.Label>
                <Col>
                    <Form.Control
                        type="text"
                        name="company_name"
                        onChange={handleFilterChange}
                        value={filters.company_name}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Type</Form.Label>
                <Col>
                    <Form.Select
                        name="is_company"
                        onChange={handleFilterChange}
                        value={filters.is_company}
                    >
                        <option value="">All</option>
                        <option value={1}>Company</option>
                        <option value={0}>Individual</option>
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Lead Owner</Form.Label>
                <Col>
                    <SelectUsers
                        name="lead_owner"
                        handleFieldChange={handleFilterChange}
                        value={filters.lead_owner_ref}
                    />
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Converted</Form.Label>
                <Col>
                    <Form.Select
                        name="is_converted"
                        onChange={handleFilterChange}
                        value={filters.is_converted}
                    >
                        <option value="">All</option>
                        <option value="1">Converted</option>
                        <option value="0">Not Converted</option>
                    </Form.Select>
                </Col>
            </Form.Group>
            <Form.Group as={Col} className="mt-3">
                <Form.Label className="fs--1">Priority</Form.Label>
                <Col>
                    <SelectLeadPriority
                        name="lead_priority"
                        value={filters.lead_priority_ref}
                        handleFieldChange={handleFilterChange}
                    />
                </Col>
            </Form.Group>
        </div>
    );
};

export default LeadSourceSummeryItemReportForm;
