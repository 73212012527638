import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

import { IoMdClose } from "react-icons/io";

function CloseButton() {
  const navigate = useNavigate();

  return (
    <Button
      className="border-0 border-start"
      size="lg"
      variant="light"
      style={{
        backgroundColor: "white",
        outline: "none",
        boxShadow: "none",
      }}
      onClick={() => {
        navigate("/reports");
      }}
    >
      <IoMdClose color="#6F7275" size={23} />
    </Button>
  );
}

export default CloseButton;
