import defaultTaskStatusFilter from "module/common/helpers/defaultTaskStatusFilterKeys";
import { AiOutlineCodeSandbox } from "react-icons/ai";
import { BiTask } from "react-icons/bi";
import { BsCalendar2Range } from "react-icons/bs";
import { FaCalculator, FaUserTie } from "react-icons/fa";
import { FiPackage } from "react-icons/fi";
import {
  MdAirplaneTicket,
  MdLabelImportant,
  MdOutlineContactPhone,
  MdOutlineStickyNote2,
} from "react-icons/md";
import { RiMailSendFill } from "react-icons/ri";
import { SiGoogleadmob } from "react-icons/si";
import { TbBulbFilled } from "react-icons/tb";
import { createSearchParams } from "react-router-dom";

export const dashboardRoutes = {
  label: "Dashboard",
  labelDisable: true,
  children: [
    {
      name: "Dashboard",
      to: "dashboard",
      active: true,
      icon: "chart-pie",
    },
  ],
};

export const employeeRoutes = {
  label: "Employee",
  labelDisable: true,
  children: [
    {
      name: "Employee",
      to: "employee",
      icon: "users",
      active: true,
    },
  ],
};
export const estimationRoutes = {
  label: "Estimation",
  labelDisable: true,
  children: [
    {
      name: "Estimation",
      to: "estimation",
      icon: <FaCalculator />,
      active: true,
    },
  ],
};
export const todoRoutes = {
  label: "Todo",
  labelDisable: true,
  children: [
    {
      name: "Todo",
      to: "todo",
      icon: "list-check",
      active: true,
    },
  ],
};
export const campaignRoutes = {
  label: "Campaign",
  labelDisable: true,
  children: [
    {
      name: "Campaign",
      to: "/campaign",
      icon: "people-arrows",
      active: true,
    },
  ],
};
export const departmentRoutes = {
  label: "Department",
  labelDisable: true,
  children: [
    {
      name: "Department",
      to: "/department",
      icon: "people-roof",
      active: true,
    },
  ],
};
export const leadRoutes = {
  label: "Leads",
  labelDisable: true,
  children: [
    {
      name: "Leads",
      to: "leads",
      icon: <SiGoogleadmob size={16} />,
      active: true,
      children: [
        {
          name: "Lead",
          to: "leads/lead",
          active: true,
        },
        {
          name: "Lead Status",
          to: "leads/lead-status",
          active: true,
        },
        {
          name: "Lead Source",
          to: "leads/lead-source",
          active: true,
        },
      ],
    },
  ],
};

export const opportunityRoutes = {
  label: "Opportunity",
  labelDisable: true,
  children: [
    {
      name: "Opportunity",
      to: "/opportunity",
      icon: <TbBulbFilled size={19} />,
      active: true,
    },
  ],
};

export const teamRoutes = {
  label: "Teams",
  labelDisable: true,
  children: [
    {
      name: "Teams",
      to: "/teams",
      icon: "people-group",
      active: true,
    },
  ],
};

export const designationRoutes = {
  label: "Designation",
  labelDisable: true,
  children: [
    {
      name: "Designation",
      to: "/designation",
      icon: "user-graduate",
      active: true,
    },
  ],
};

export const clientRoutes = {
  label: "Account",
  labelDisable: true,
  children: [
    {
      name: "Account",
      to: "client",
      icon: <FaUserTie size={15} />,
      active: true,
      validatingName: "client",
      children: [
        {
          name: "Account List",
          to: "client/list",
          active: true,
        },
      ],
    },
  ],
};

export const projectRoutes = {
  label: "Project",
  labelDisable: true,
  children: [
    {
      name: "Project",
      to: "project",
      icon: <AiOutlineCodeSandbox size={19} />,
      active: true,
    },
  ],
};

export const sprintRoutes = {
  label: "Sprints",
  labelDisable: true,
  children: [
    {
      name: "Sprints",
      to: "sprints",
      icon: <BsCalendar2Range size={15} />,
      active: true,
    },
  ],
};

export const taskRoutes = {
  label: "Tasks",
  labelDisable: true,
  children: [
    {
      name: "Tasks",
      to: "tasks",
      icon: <BiTask size={16} />,
      active: true,
      children: [
        {
          name: "Task List",
          to: `tasks/list?${createSearchParams({
            status_filter: defaultTaskStatusFilter.join("/"),
            page: 1,
          })}`,
          active: true,
        },
      ],
    },
  ],
};

export const ticketsRoutes = {
  label: "Tickets",
  labelDisable: true,
  children: [
    {
      name: "Tickets",
      to: "tickets",
      icon: <MdAirplaneTicket size={17} />,
      active: true,
      children: [
        {
          name: "Ticket List",
          to: "tickets/list?tab=all",
          active: true,
        },
        {
          name: "Ticket Type",
          to: "tickets/types",
          active: true,
        },
      ],
    },
  ],
};

export const labelsRoutes = {
  label: "Labels",
  labelDisable: true,
  children: [
    {
      name: "Labels",
      to: "/labels",
      icon: <MdLabelImportant size={20} />,
      active: true,
    },
  ],
};

export const announcementRoutes = {
  label: "Announcement",
  labelDisable: true,
  children: [
    {
      name: "Announcement",
      to: "/announcement",
      icon: "fa-bullhorn",
      active: true,
    },
  ],
};

export const reportRoutes = {
  label: "Reports",
  labelDisable: true,
  children: [
    {
      name: "Reports",
      to: "reports",
      icon: <MdOutlineStickyNote2 size={15} />,
      active: true,
    },
  ],
};

export const enquiryRoutes = {
  label: "Enquiries",
  labelDisable: true,
  children: [
    {
      name: "Enquiries",
      to: "/enquiries",
      icon: <MdOutlineContactPhone size={15} />,
      active: true,
    },
  ],
};

export const invitationsRoutes = {
  label: "Invitations",
  labelDisable: true,
  children: [
    {
      name: "Invitations",
      to: "/invitation",
      icon: <RiMailSendFill size={15} />,
      active: true,
    },
  ],
};

export const productsRoutes = {
  label: "Products",
  labelDisable: true,
  children: [
    {
      name: "Products",
      to: "/products",
      icon: <FiPackage size={15} />,
      active: true,
    },
  ],
};

const routeItems = [
  dashboardRoutes,
  employeeRoutes,
  leadRoutes,
  campaignRoutes,
  opportunityRoutes,
  teamRoutes,
  departmentRoutes,
  designationRoutes,
  clientRoutes,
  projectRoutes,
  sprintRoutes,
  taskRoutes,
  todoRoutes,
  estimationRoutes,
  productsRoutes,
  ticketsRoutes,
  enquiryRoutes,
  labelsRoutes,
  announcementRoutes,
  invitationsRoutes,
  reportRoutes,
];
export default routeItems;
