import { React, useContext, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

import { FaTrash } from "react-icons/fa";

import { DeleteContext } from "context/Context";

const ExportItemClearButton = ({ exportArrayDispatch, item, old }) => {
  const [loading, setLoading] = useState(false);
  const { dispatch } = useContext(DeleteContext);
  const handleClear = () => {
    let id = item[!old ? "dbId" : "id"];
    dispatch({
      type: "CONFIG",
      payload: {
        target: id,
        url: "/user/export-logs/" + id,
        title: "Delete Export Item",
        message: "Are you sure you want to delete this export item?",
        onDelete: () => {
          setLoading(true);
        },
        onSuccess: () => {
          if (!old) {
            exportArrayDispatch({
              type: "DELETE",
              payload: item,
            });
          } else {
            exportArrayDispatch((prev) => [
              ...prev.filter((oldItem) => oldItem.id !== item.id),
            ]);
          }
          setLoading(false);
        },
        onError: () => {
          setLoading(false);
        },
      },
    });
  };
  return (
    <Button
      variant="danger"
      size="sm"
      className="d-flex flex-row justify-content-center align-items-center mt-2"
      disabled={loading}
      onClick={handleClear}
    >
      {loading ? (
        <Spinner animation="border" style={{ width: 12, height: 12 }} />
      ) : (
        <>
          <FaTrash size={10} />
          <span>Clear</span>
        </>
      )}
    </Button>
  );
};

ExportItemClearButton.propTypes = {
  exportArrayDispatch: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  old: PropTypes.bool,
};

export default ExportItemClearButton;
