import { React, useEffect, useRef } from "react";
import { Container, Row, Col, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { GrFormClose } from "react-icons/gr";
import { useBreakpoints } from "../../hooks/useBreakpoints";

const ErrorAlert = ({ setFormError, formError }) => {
  const scrollToTop = useRef(null);
  const { breakpoints } = useBreakpoints();

  let errors = Object.keys(formError).reduce((array, key) => {
    return [...array, { key: formError[key] }];
  }, []);

  useEffect(() => {
    scrollToTop.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <Container
      style={{
        display: breakpoints.down("sm") ? "none" : "",
      }}
      ref={scrollToTop}
    >
      <Alert
        className="pb-1 align-items-center ps-5"
        variant="danger"
        style={{
          borderRadius: "0px",
        }}
      >
        <Row>
          <Col xl={10} md={10}>
            <ul>
              {errors?.map((item, key) => {
                return (
                  <li key={key}>
                    <p className="fs--2 m-0 text-uppercase">{item?.key[0]}</p>
                  </li>
                );
              })}
            </ul>
          </Col>
          <Col xl={2} md={2} className="d-flex justify-content-end">
            <GrFormClose
              size={18}
              className="icon-close"
              onClick={() => setFormError({})}
            />
          </Col>
        </Row>
      </Alert>
    </Container>
  );
};

ErrorAlert.propTypes = {
  setFormError: PropTypes.func,
  formError: PropTypes.object,
};

export default ErrorAlert;
