import React from "react";
import PropTypes from "prop-types";
import { createSearchParams, Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleLeft,
  FaAngleRight,
} from "react-icons/fa";

const AppPagination = ({ data, url, size = "sm", search }) => {
  data = data.meta ? { ...data.meta } : data;
  let limitStart = data && data.current_page ? data.current_page - 1 : 0;
  let limitEnd =
    data && data.current_page
      ? data.current_page + (data.current_page > 1 ? 2 : 3)
      : 0;
  let lastPage = limitEnd - data.last_page;
  limitStart = lastPage > 0 ? limitStart - lastPage : limitStart;
  return data && data.links && data.total > 0 ? (
    <div
      className={
        data.total > data.per_page
          ? "d-flex w-100 flex-column align-items-center"
          : "d-flex w-100 flex-column align-items-center p-3"
      }
    >
      {data.total > data.per_page && (
        <Pagination size={size}>
          {data.current_page > 1 && (
            <>
              <li className="page-item">
                <Link
                  to={`${url}${
                    search
                      ? `?${createSearchParams({ ...search, page: 1 })}`
                      : 1
                  }`}
                  state={{ reload: true }}
                  className="page-link"
                  role="button"
                  tabIndex="0"
                >
                  <FaAngleDoubleLeft />
                </Link>
              </li>
              <li className="page-item">
                <Link
                  to={`${url}${
                    search
                      ? `?${createSearchParams({
                          ...search,
                          page: data.current_page - 1,
                        })}`
                      : data.current_page - 1
                  }`}
                  state={{ reload: true }}
                  className="page-link"
                  role="button"
                  tabIndex="0"
                >
                  <FaAngleLeft />
                </Link>
              </li>
            </>
          )}
          {data.links.map(
            (item, index) =>
              parseInt(item.label) >= limitStart &&
              parseInt(item.label) <= limitEnd &&
              (item.active ? (
                <li className="page-item active" key={index}>
                  <span className="page-link">{item.label}</span>
                </li>
              ) : (
                <li key={index} className="page-item">
                  <Link
                    to={`${url}${
                      search
                        ? `?${createSearchParams({
                            ...search,
                            page: item.label,
                          })}`
                        : item.label
                    }`}
                    state={{ reload: true }}
                    className="page-link fw-bold"
                    role="button"
                    tabIndex="0"
                  >
                    {item.label}
                  </Link>
                </li>
              ))
          )}
          {data.current_page < data.last_page && (
            <>
              <li className="page-item">
                <Link
                  to={`${url}${
                    search
                      ? `?${createSearchParams({
                          ...search,
                          page: data.current_page + 1,
                        })}`
                      : data.current_page + 1
                  }`}
                  state={{ reload: true }}
                  className="page-link"
                  role="button"
                  tabIndex="0"
                >
                  <FaAngleRight />
                </Link>
              </li>
              <li className="page-item">
                <Link
                  to={`${url}${
                    search
                      ? `?${createSearchParams({
                          ...search,
                          page: data.last_page,
                        })}`
                      : data.last_page
                  }`}
                  state={{ reload: true }}
                  className="page-link"
                  role="button"
                  tabIndex="0"
                >
                  <FaAngleDoubleRight />
                </Link>
              </li>
            </>
          )}
        </Pagination>
      )}
      <p className="fs--1 text-center mb-0">
        showing {data.to} / {data.total}
      </p>
    </div>
  ) : (
    ""
  );
};

AppPagination.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string.isRequired,
  onPageChange: PropTypes.func,
  size: PropTypes.string,
  search: PropTypes.object,
};

export default AppPagination;
