import { React, useState } from "react";
import axios from "axios";
import AsyncSelect from "react-select/async";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomerDetails from "components/customer-details/CustomerDetails";
import Invitation from "module/common/Invitation/Invitation";

const SelectCustomer = ({
  handleFieldChange,
  name,
  error,
  value,
  keyName,
  showCustomerDetails,
  style,
  placeholder,
  isDisabled = false,
}) => {
  const [showCustomerDetailsArea, setCustomerDetailsArea] = useState(false);
  const [showInvitation, setInvitation] = useState(false);
  const handleInvite = () => setInvitation(!showInvitation);
  const portalTarget = document.createElement("div");
  document.body.appendChild(portalTarget);
  const setItems = async (value) => {
    let response = await axios.get("sales/customerAutocomplete", {
      params: { name: value },
    });
    let data = response && response.data ? response.data.data : [];
    return data.map((item) => ({
      label: item.name,
      value: keyName ? item[keyName] : item.id,
    }));
  };

  const handleCustomerDetailsArea = () => {
    setCustomerDetailsArea(!showCustomerDetailsArea);
  };

  const NoOptionsMessage = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span>No options</span>
        <Button
          variant="transparant"
          className="p-0 custom-transperent-common-button"
          onClick={handleInvite}
          size="sm"
        >
          <span className="text-primary">Invite</span>
        </Button>
      </div>
    );
  };

  return (
    <>
      <AsyncSelect
        isDisabled={isDisabled}
        cacheOptions
        loadOptions={setItems}
        menuPortalTarget={portalTarget}
        defaultOptions
        isClearable
        name={name ? name : "Client"}
        value={value}
        placeholder={placeholder ?? ""}
        onChange={handleFieldChange}
        className={`custom-select ${
          error && "form-control ps-0 py-0 is-invalid"
        }`}
        styles={{
          menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#696c96" : "inherit",
            "&:hover": {
              backgroundColor: state.isSelected ? "#192E49" : "#e1e2ea",
            },
          }),
          control: (provided) => ({
            ...provided,
            ...style,
          }),
        }}
        noOptionsMessage={NoOptionsMessage}
      />
      {showCustomerDetails && value && (
        <>
          <Button
            className="d-flex flex-row mt-2 align-items-center p-0"
            variant="white"
            onClick={handleCustomerDetailsArea}
          >
            <FontAwesomeIcon icon="user-circle" size="xs" className="me-1" />
            <span className="small text-primary">View Client Details</span>
          </Button>
          <CustomerDetails
            show={showCustomerDetailsArea}
            onHide={handleCustomerDetailsArea}
            value={value}
            showCustomerDetailsArea={showCustomerDetailsArea}
          />
        </>
      )}
      <Invitation showInvitation={showInvitation} handleClose={handleInvite} />
    </>
  );
};

SelectCustomer.propTypes = {
  handleFieldChange: PropTypes.func,
  name: PropTypes.string,
  error: PropTypes.any,
  showCustomerDetails: PropTypes.bool,
  value: PropTypes.any,
  keyName: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
};

export default SelectCustomer;
