import React from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Attachments from "module/common/ViewPages/Common/Attachments/Attachments";

const EmployeeAttachments = () => {
  const { empId } = useParams();
  return (
    <div className="mt-4 ms-3" style={{ minHeight: "80vh" }}>
      <div className="organizational-data">
        <Row className="gx-2 border-top">
          <Col lg={12} md={12} sm={12}>
            <span className="custom-postioning-header-employee-overview fs--1 fw-bold">
              Recent Attachments
            </span>
            <div className="mt-4">
              <Attachments
                itemId={empId}
                type="EMPLOYEE"
                fetchUrl={`documents?subject_type=EMPLOYEE&subject_id=${empId}`}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmployeeAttachments;
