import { Card, Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import ClientTaskSection from "./ClientTaskSection";
import ProjectCard from "./ProjectCard";
import { Link } from "react-router-dom";
import { FaBan, FaListAlt } from "react-icons/fa";
import useAxisproPermission from "hooks/useAxisproPermission";

const ClentOverViewSection = ({ overviewData }) => {
  const axisProPermission = useAxisproPermission();
  const [listTaskPermission, listProjectPermission] = [
    "list-task",
    "list-project",
  ].map((permission) => axisProPermission(permission));
  const actionsPermission = listTaskPermission || listProjectPermission;
  return (
    <Card className="mt-1 flex-fill h-100">
      <Card.Body className="h-100">
        <Row>
          {!actionsPermission && (
            <Col md={12}>
              <div
                className="d-flex justify-content-center align-items-center gap-2 border"
                style={{ height: "25vh" }}
              >
                <FaBan size={30} color="#ff6e6e" />
                <span className="fs-0">
                  No permissions are available to view this page.
                </span>
              </div>
            </Col>
          )}
          {listTaskPermission && (
            <Col md={12}>
              <div className="d-flex justify-content-between align-items-center">
                <span className="client-name h1 fw-bold mt-2 mb-2">
                  Latest Task
                </span>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      <strong className="fs--2">List All Tasks</strong>
                    </Tooltip>
                  }
                >
                  <Link
                    className="m-0 text-primary custom-link-style-crm"
                    to="/tasks/list"
                  >
                    <FaListAlt size={25} className="text-secondary" />
                  </Link>
                </OverlayTrigger>
              </div>
              <div className="mt-2">
                <ClientTaskSection taskData={overviewData?.tasks} />
              </div>
            </Col>
          )}
          {listProjectPermission && (
            <Col md={12}>
              <div className="d-flex justify-content-between align-items-center mt-3">
                <span className="client-name h1 fw-bold mt-2 mb-2">
                  Projects
                </span>
                <OverlayTrigger
                  placement={"bottom"}
                  overlay={
                    <Tooltip>
                      <strong className="fs--2">List All Projects</strong>
                    </Tooltip>
                  }
                >
                  <Link
                    className="m-0 text-primary custom-link-style-crm"
                    to="/project"
                  >
                    <FaListAlt size={25} className="text-secondary" />
                  </Link>
                </OverlayTrigger>
              </div>
              <ProjectCard projectData={overviewData?.projects} />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};
export default ClentOverViewSection;
