import SelectCampaign from "components/form/SelectCampaign";
import SelectCustomer from "components/form/SelectCustomer";
import SelectLead from "components/form/SelectLead";
import SelectOpportunityStage from "components/form/SelectOpportunityStage";
import ColumnSelector from "module/common/Filter/ColumnSelector";
import DateRangeFilter from "module/common/Filter/DateRangeFilter";
import React from "react";
import { Form } from "react-bootstrap";

const OpportunityDetailedReportForm = ({
  columns,
  filters,
  setFilters,
  handleFilterChange,
}) => {
  return (
    <div>
      <div>
        <ColumnSelector
          columns={columns}
          filters={filters}
          setFilters={setFilters}
          defaultOption={true}
          value={filters.date_range_for}
        />
      </div>
      <div className="mt-3">
        <DateRangeFilter filters={filters} setFilters={setFilters} />
      </div>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Lead</Form.Label>
        <SelectLead
          name="lead_id"
          handleFieldChange={handleFilterChange}
          value={filters.lead_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail" className="mt-3">
        <Form.Label>Client</Form.Label>
        <SelectCustomer
          name="customer_id"
          handleFieldChange={handleFilterChange}
          value={filters.customer_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Campaign</Form.Label>
        <SelectCampaign
          name="campaign_id"
          handleFieldChange={handleFilterChange}
          value={filters.campaign_id_ref}
        />
      </Form.Group>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Stage</Form.Label>
        <SelectOpportunityStage
          name="stage"
          handleFieldChange={handleFilterChange}
          value={filters.stage_ref}
        />
      </Form.Group>
    </div>
  );
};

export default OpportunityDetailedReportForm;
